<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">

      <div class="modal-header ">
        <h4 class="modal-title">{{getTitle()}}</h4>
      </div>
      <div class="modal-wrapper">
        <div class="modal-body">
          <div class="type-radio" *ngIf="!isAssociateModal()">
            <div  class="type-item">
              <input class="radio"
                     type="radio"
                     [value]="isStandardProject">
              <img [ngClass]="{'radio-btn': true, 'hidden-btn': isStandardProject}"
                   src="./assets/images/icons/radio.svg"/>
              <img [ngClass]="{'radio-btn': true, 'hidden-btn': !isStandardProject}"
                   (click)="onChangeProjectType()"
                   src="./assets/images/icons/radioUnselected.svg" class="radio-btn"/>
              {{'T_PROJECT_CPQ' | translate}}
            </div>
            <div class="type-item">
              <input class="radio"
                     type="radio"
                     [value]="isStandardProject">
              <img [ngClass]="{'radio-btn': true, 'hidden-btn': !isStandardProject}"
                   src="./assets/images/icons/radio.svg"/>
              <img [ngClass]="{'radio-btn': true, 'hidden-btn': isStandardProject}"
                   (click)="onChangeProjectType()"
                   src="./assets/images/icons/radioUnselected.svg" class="radio-btn"/>
              {{'T_PROJECT_STANDARD' | translate}}
            </div>
          </div>
          <!-- Text area -->
            <div class="cpq-number" *ngIf="!isStandardProject">
              <label for="cpqNumberField" translate>T_PROJECT_CPQ_NUMBER</label>
              <div>
                <input *ngIf="true" id="cpqNumberField"
                       [(ngModel)]="cpqNumber"
                       [ngClass]="{'input-error': isInvalidCpqNumber()}"
                       type="text">
              </div>
              <div *ngIf="isInvalidCpqNumber()">
                <span class="input-error" translate>T_CPQ_NUMBER_REQUIRED</span>
              </div>
            </div>
        </div>
      </div>
      <div class="modal-footer background-white">
        <button class="btn btn-default-white-background" (click)="hide()" translate>T_BUTTON_CANCEL</button>
        <button class="btn btn-default-white-background" [disabled]="!this.isStandardProject && isInvalidCpqNumber()"
                (click)="clickCreateButton(); hide()" translate>
          {{getActionButtonLabel()}}
        </button>
      </div>
    </div>
  </div>
</div>
