<!-- Application splash screen -->

<div class="app-splash" *ngIf="isLoading">
  <div class="shadow splash-container">
    <div class="background"></div>
    <div class="transparent-div">
      <p><progressbar class="loading-bar" max="100" [value]="loadingRate"></progressbar></p>
    </div>
    <div class="menu">
      <div class="loading-resources">Loading resources... {{loadingRate}}%</div>
      <img class="logo" src="assets/images/ecoStruxure_logo_white.png"/>
      <div class="app-name">Power <span class="font-sub">Build - Contractors</span></div>
      <div class="version">Version {{commercialVersion}}</div>
    </div>
    <div class="splash-footer">
      <div class="footer-message">
        This application is protected by copyright law and international treaties<br/>
        &copy; 2020 Schneider Electric Industries SAS. All Rights Reserved.
      </div>
      <img class="footer-logo" src="assets/images/life_is_on_se_white.png"/>
    </div>
  </div>
</div>

<div class="div-app" *ngIf="!isLoading">
  <app-header></app-header>
  <div *ngIf="!isOnAdministrationScreen">
    <app-navigation-bar></app-navigation-bar>

    <section class="app-main"
             [class.max-height-bar]="(!currentItemct && noItemNavigationStep > 1) || (currentItemct && currentItemct?.currentNavigationStep > 1)"
             [class.max-height-nobar]="((!currentItemct && noItemNavigationStep < 2) || (currentItemct?.currentNavigationStep === 1))">
      <div id="main-div" class="relative">
        <router-outlet></router-outlet>
      </div>
    </section>
  </div>
  <div *ngIf="isOnAdministrationScreen">
    <app-navigation-bar-v2 [items]="adminNavItems"></app-navigation-bar-v2>
    <router-outlet></router-outlet>
  </div>
</div>

<p-toast position="bottom-right"></p-toast>

<app-feedback *ngIf="!isLoading"></app-feedback>

<!-- terms of use dialog -->
<app-terms-of-use-modal #childModal [name]="'childModal'" (onClickAcceptButton)="acceptTermsOfUse()" (onClickRefusedButton)="refuseTermsOfUse()">
</app-terms-of-use-modal>


