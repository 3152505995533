import {Contact} from '../partner/contact';

export class Localization {
  country: string;
  langs: Array<Lang>;
  currency: string;
  displayedCurrency: string;
  projectDocumentation?: boolean;
  orderingMode: OrderingMode;
  mail: string;
  supportContact: Contact;
  salesContact: Contact;
  demoMode: DemoMode;
  demoVideoLink: string;
  indexDemoDefaultlanguage: number;
  canUserManagePersonalData: boolean;
}

export enum DemoMode {
  DEMO = 'DEMO',
  EXTENDED_DEMO = 'EXTENDED',
  DEACTIVATED = 'DEACTIVATED'
}

export class Locale {
  language: string;
  available: boolean;
  translations: Array<Translation>;
  // FIXME: delete and manage below status other
  hasBomApplicationChecked = false;
  okStatus: boolean;
  missingStatus: boolean;
  editedStatus: boolean;
}

/**
 * Class for modelizing the button in the tabset (instead of passing a locale with a flag add button)
 */
export class AddButton {}

/**
 * Class for specific language (fr, de, en, nl, etc...) translation
 * In this class we add some attributes from TranslationKey for displaying
 */
export class Translation {
  key: string;
  translation: string;
  // attributes from TranslationKey
  type: TranslationTypeEnum;
  defaultTranslation: string;
  description: string;
  // status
  status: TranslationStatusEnum;
  sortingValue?: SortingValues;
}

export class SortingValues {
  key: string;
  type: TranslationTypeEnum;
}

export enum TranslationTypeEnum {
  application = 'APPLICATION',
  offer = 'OFFER'
}

export enum TranslationStatusEnum {
  OK = 'OK',
  MISSING = 'MISSING',
  EDITED = 'EDITED',
  NO = 'NO'
}

export class Lang {
  language: string;
  available: boolean;
}

export enum OrderingMode {
  STANDARD = 'STANDARD',
  MIXED = 'MIXED'
}
