/*
 * This module imports and re-exports all Bootstrap modules for convenience,
 * so only 1 module import is needed in your feature modules.
 * See https://material.angular.io/guide/getting-started#step-3-import-the-component-modules.
 *
 * To optimize your production builds, you should only import the components used in your app.
 */

import {NgModule} from '@angular/core';
import {
  AccordionModule,
  BsDropdownModule,
  ButtonsModule,
  ModalModule,
  PaginationModule,
  PopoverModule,
  TabsModule,
  TooltipModule
} from 'ngx-bootstrap';

@NgModule({
  imports: [
    AccordionModule.forRoot(),
    PaginationModule.forRoot(),
    PopoverModule.forRoot()
  ],
  exports: [
    AccordionModule,
    BsDropdownModule,
    ButtonsModule,
    ModalModule,
    PaginationModule,
    PopoverModule,
    TabsModule,
    TooltipModule
  ]
})
export class BootstrapModule {
}
