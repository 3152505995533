
<div class="container scrollable scrollSize">

  <ng-template #tooltipTemplate><span translate>T_BACK_OFFICE_FIELD_ERROR_TOOLTIP_PHONE</span><br>
    <div *ngFor="let phone of listPhone">{{phone}}</div>
    <span translate>T_BACK_OFFICE_FIELD_ERROR_TOOLTIP_EMAIL</span>
    <div *ngFor="let email of listEmail">{{email}}</div>
  </ng-template>
  <h2 translate>T_CONTACT_MANAGEMENT_TITLE</h2>

  <!-- Localization default contacts data -->
  <div class="row">
    <ng-container *ngIf="localization">
      <!-- title -->
      <div class="ui-grid-col-4">
        <p class="default-title"><strong translate>Default localization contacts </strong><span>({{localization.country}})</span></p>
      </div>

      <!-- Localization support contact data -->
      <app-contact-details
        *ngIf="localization.supportContact"
        [contactName]="'T_SUPPORT_CCC_TITLE'"
        [contactType]="ContactTypeEnum.SUPPORT"
        [contact]="localization.supportContact"
        (onEditFieldBlur)="updateFieldLocalization($event)"></app-contact-details>

      <!-- Localization sales contact data -->
      <app-contact-details
        *ngIf="localization.salesContact"
        [contactName]="'T_SUPPORT_SALES_TITLE'"
        [contactType]="ContactTypeEnum.SALES"
        [contact]="localization.salesContact"
        (onEditFieldBlur)="updateFieldLocalization($event)"></app-contact-details>

      <!-- Localization save the data -->
      <div class="pull-right save-error-container">
        <span class="align-left error-message" *ngIf="!isLocalizationValid()" translate>T_BACK_OFFICE_FIELD_ERROR
          <em class="fa fa-info-circle fa-lg" [tooltip]="tooltipTemplate" placement="left" containerClass="tooltip-error"></em>
        </span>
        <button class="btn btn-default" type="button" (click)="saveLocalization()" [disabled]="!isLocalizationValid()" translate>T_BUTTON_SAVE</button>
      </div>
    </ng-container>
  </div>

  <!-- table with partners data -->
  <p-table #tt styleClass="express-datatable-style" [columns]="cols" [value]="(partners | async)" [rows]="10" [paginator]="true" dataKey="partnerName">
    <ng-template pTemplate="emptymessage" >
      <tr>
        <td [attr.colspan]="4" translate>T_CONTACT_NO_PARTNER_FOUND</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="width: 2.25em" scope="col"></th>
        <th *ngFor="let col of cols"  [pSortableColumn]="col.field" scope="col">
          {{col.header}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      <tr>
        <th style="width: 2.25em" scope="col"></th>
        <td *ngFor="let col of columns" [ngSwitch]="col.field">
          <input *ngIf="col.field === 'name'" placeholder="Search" pInputText type="text" (input)="tt.filter($event.target.value, col.field, 'contains')">
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-partner let-expanded="expanded" let-columns="columns">
      <tr>
        <td>
          <a href="#" [pRowToggler]="partner">
            <em [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></em>
          </a>
        </td>
        <td>{{partner.name}}</td>
        <td></td>
        <td></td>
      </tr>
    </ng-template>
    <ng-template  let-partner pTemplate="rowexpansion">
      <tr>
        <td></td>
        <td>
          <div class="align-left"><strong translate>{{partner?.name}}</strong></div>
          <div class="align-left">{{partner?.role}}</div>
        </td>
        <td>
          <div class="align-left "><strong translate>T_SUPPORT_CCC_TITLE</strong></div>
          <div class="align-left"><span translate>T_SUPPORT_PHONE_NUMBER</span> :
            <span contenteditable="true" class="phone-field"
                  (blur)="updateFieldContact(partner, ContactTypeEnum.SUPPORT, ContactFieldEnum.PHONE, $event)">{{partner?.supportContact.phone}}</span>
          </div>
          <div class="align-left "><span translate>Fax number</span> :
            <span contenteditable="true" class="fax-field"
                  (blur)="updateFieldContact(partner, ContactTypeEnum.SUPPORT, ContactFieldEnum.FAX, $event)">{{partner?.supportContact.fax}}</span>
          </div>
          <div class="align-left"><span translate>T_SUPPORT_EMAIL</span> :
            <span contenteditable="true" class="email-field"
                  (blur)="updateFieldContact(partner, ContactTypeEnum.SUPPORT, ContactFieldEnum.EMAIL, $event)">{{partner?.supportContact.email}}</span>
          </div>
        </td>
        <td>
          <div class="align-left editableContent"><strong translate>T_SALES_TITLE</strong></div>
          <div class="align-left"><span translate>T_SUPPORT_PHONE_NUMBER</span> :
            <span contenteditable="true" class="phone-field"
                  (blur)="updateFieldContact(partner, ContactTypeEnum.SALES, ContactFieldEnum.PHONE, $event)">{{partner?.salesContact.phone}}</span>
          </div>
          <div class="align-left"><span translate>Fax number</span> :
            <span contenteditable="true" class="fax-field"
                  (blur)="updateFieldContact(partner, ContactTypeEnum.SALES, ContactFieldEnum.FAX, $event)">{{partner?.salesContact.fax}}</span>
          </div>
          <div class="align-left"><span translate>T_SUPPORT_EMAIL</span> :
            <span contenteditable="true" class="email-field"
                  (blur)="updateFieldContact(partner, ContactTypeEnum.SALES, ContactFieldEnum.EMAIL, $event)">{{partner?.salesContact.email}}</span>
          </div>
          <div class="align-right" *ngIf="partner">
                <span class="align-left error-message" *ngIf="!isValid(partner)" translate>T_BACK_OFFICE_FIELD_ERROR
                <em class="fa fa-info-circle fa-lg" [tooltip]="tooltipTemplate" placement="left"
                   containerClass="tooltip-error"></em>
                 </span>
            <button class="btn btn-default" type="button" (click)="save(partner)" [disabled]="!isValid(partner)"
                    translate>T_BUTTON_SAVE
            </button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
