<app-spinner *ngIf="loading" [sizeClass]="'medium'"></app-spinner>
<div *ngIf="!loading">
  <div *ngIf="!packageOffer" class="no-offer-warning">
    <p translate>T_PACKAGE_NO_OFFER_YET</p>
    <p>
      <button (click)="onAddPackageOfferButton()" class="btn btn-create-offer" translate>
        <span class="se-icons">new_project</span>
        T_PACKAGE_CREATE_OFFER_BUTTON
      </button>
    </p>
  </div>
  <div *ngIf="packageOffer">
    <div>
      <p *ngIf="packageOffer.packages.length === 0" translate>T_PACKAGE_NO_PACKAGE_YET</p>
      <div class="package-add">
        <input type="text" [(ngModel)]="newPackageName" [placeholder]="getInputNamePlaceholder()" pInputText/>
        <button [disabled]="!isValidName(newPackageName)" (click)="onAddPackageButton()" class="btn btn-create-offer" translate>
          <span class="se-icons">new_project</span>
          T_PACKAGE_ADD_PACKAGE_BUTTON
        </button>
      </div>
      <div *ngIf="packageOffer.packages.length > 0" class="package-list">
        <p-card *ngFor="let pack of packages">
          <h4 class="package-title" (click)="onClickingPackage(pack.id)">{{pack.characteristics["T_NAME"]}}
            <span class="se-icons open-arrow">{{showPackageContent(pack.id) ? 'arrow2_down': 'arrow2_right'}}</span>
          </h4>
          <div *ngIf="showPackageContent(pack.id)">
            <div class="package-actions">
              <p><a (click)="onOpeningActionSection(pack.id, 'RENAME')" translate>T_PACKAGE_RENAME_PACKAGE_BUTTON</a><span class="se-icons open-arrow">{{showSection(pack.id, 'RENAME') ? 'arrow2_down': 'arrow2_right'}}</span></p>
              <div class="package-input-actions" *ngIf="showSection(pack.id, 'RENAME')">
                <input type="text" [(ngModel)]="renamePackageName" pInputText/>
                <button [disabled]="!isValidName(renamePackageName)" (click)="onRenamePackageButton()" class="btn btn-create-offer" translate>
                  <span class="se-icons">new_project</span>
                  T_PACKAGE_RENAME_PACKAGE_BUTTON
                </button>
              </div>
              <p>
                <a (click)="onOpeningActionSection(pack.id, 'ADD')"translate>T_PACKAGE_ADD_COMPONENT_BUTTON</a>
                <span class="se-icons open-arrow">{{showSection(pack.id, 'ADD') ? 'arrow2_down': 'arrow2_right'}}</span>
                <span class="se-icons package-helper" container="body" tooltip="{{getAddComponentHelpMessage()}}" *ngIf="showSection(pack.id, 'ADD')">information_circle</span>
              </p>
              <div class="package-input-actions" *ngIf="showSection(pack.id, 'ADD')">
                <div>
                  <p-dropdown [options]="selectItemsRange" [(ngModel)]="selectedRange" (ngModelChange)="onSelectedRangeChange()"></p-dropdown>
                </div>
                <div class="package-add">
                  <div>
                    <p-dropdown [disabled]="!selectedRange" [options]="selectItemsSwitchboards" [(ngModel)]="selectedSwitchboard" (ngModelChange)="onSelectedSwitchboardChange()"></p-dropdown>
                    <button [disabled]="!selectedSwitchboard" (click)="onAddSwitchboardComponentButton(pack.id, selectedSwitchboard)" class="btn btn-create-offer" translate>
                      <span class="se-icons">new_project</span>
                      T_PACKAGE_ADD_SWITCHBOARD_BUTTON
                    </button>
                  </div>
                  <div>
                    <p-dropdown [disabled]="!selectedSwitchboard" [options]="selectItemsAccessoriesReferences" [(ngModel)]="selectedAccessoryReference"></p-dropdown>
                    <button [disabled]="!selectedAccessoryReference" (click)="onAddAccessoryReferenceButton(pack.id, selectedSwitchboard, selectedAccessoryReference)" class="btn btn-create-offer" translate>
                      <span class="se-icons">new_project</span>
                      T_PACKAGE_ADD_ACCESSORY_REFERENCE_BUTTON
                    </button>
                  </div>
                  <div>
                    <p-dropdown [disabled]="!selectedSwitchboard" [options]="selectItemsOptionalAccessoriesReferences" [(ngModel)]="selectedOptionalAccessoryReference"></p-dropdown>
                    <button [disabled]="!selectedOptionalAccessoryReference" (click)="onAddOptionalAccessoryButton(pack.id, selectedSwitchboard, selectedOptionalAccessoryReference)" class="btn btn-create-offer" translate>
                      <span class="se-icons">new_project</span>
                      T_PACKAGE_ADD_ACCESSORY_OPTION_BUTTON
                    </button>
                  </div>
                </div>
              </div>
              <p><a (click)="onOpeningActionSection(pack.id, 'PUBLISH')" translate>T_PACKAGE_PUBLISH_PACKAGE_BUTTON</a><span class="se-icons open-arrow">{{showSection(pack.id, 'PUBLISH') ? 'arrow2_down': 'arrow2_right'}}</span></p>
              <div class="package-input-actions" *ngIf="showSection(pack.id, 'PUBLISH')">
                <p-inputSwitch [disabled]="!canPublish()" [(ngModel)]="pack.published" (onChange)="onPublishSwitchButton()"></p-inputSwitch>
                <span *ngIf="pack.published" translate>T_PACKAGE_PUBLISHED_STATUS</span>
                <span *ngIf="!pack.published" translate>T_PACKAGE_NOT_PUBLISHED_STATUS</span>
              </div>
              <p><a (click)="onOpeningActionSection(pack.id, 'DUPLICATE')" translate>T_PACKAGE_DUPLICATE_PACKAGE_BUTTON</a><span class="se-icons open-arrow">{{showSection(pack.id, 'DUPLICATE') ? 'arrow2_down': 'arrow2_right'}}</span></p>
              <div class="package-input-actions" *ngIf="showSection(pack.id, 'DUPLICATE')">
                <button (click)="onDuplicatePackageButton()" class="btn btn-create-offer">
                  <span class="se-icons">new_project</span>
                  <span translate>T_PACKAGE_DUPLICATE_PACKAGE_BUTTON</span> {{pack.characteristics["T_NAME"]}}
                </button>
              </div>
              <p><a (click)="onOpeningActionSection(pack.id, 'DELETE')" translate>T_PACKAGE_DELETE_PACKAGE_BUTTON</a><span class="se-icons open-arrow">{{showSection(pack.id, 'DELETE') ? 'arrow2_down': 'arrow2_right'}}</span></p>
              <div class="package-input-actions" *ngIf="showSection(pack.id, 'DELETE')">
                <button (click)="onDeletePackageButton()" class="btn btn-create-offer">
                  <span class="se-icons">new_project</span>
                  <span translate>T_PACKAGE_DELETE_PACKAGE_BUTTON</span> {{pack.characteristics["T_NAME"]}}
                </button>
              </div>
            </div>
            <div class="package-content">
              <div class="package-content-item">
                <p-card>
                  <h5 translate>T_PACKAGE_SWITCHBOARD_LIST_TITLE</h5>
                  <ul>
                    <li *ngFor="let switchboardComponent of pack.switchboardComponents; let index = index;">
                      {{switchboardComponent.reference.ref}}
                      <span class="se-icons delete-ref-button" (click)="onDeleteSwitchboardRefButton(index)">action_delete</span>
                    </li>
                  </ul>
                </p-card>
              </div>
              <div class="package-content-item">
                <p-card>
                  <h5 translate>T_PACKAGE_ACCESSORY_REFERENCE_LIST_TITLE</h5>
                  <ul *ngFor="let entry of pack.accessoriesReferencesMap | keyvalue">
                    <li *ngFor="let accessoryReference of entry.value; let index = index">
                      {{accessoryReference.ref}}
                      <span class="se-icons delete-ref-button" (click)="onDeleteAccessoryRefButton(entry.key, index)">action_delete</span>
                    </li>
                  </ul>
                </p-card>
              </div>
              <div class="package-content-item">
                <p-card>
                  <h5 translate>T_PACKAGE_ACCESSORY_OPTION_LIST_TITLE</h5>
                  <ul *ngFor="let entry of pack.optionalAccessoriesReferencesMap | keyvalue">
                    <li *ngFor="let optionalAccessory of entry.value; let index = index" translate>
                      {{optionalAccessory.ref}}
                      <span class="se-icons delete-ref-button" (click)="onDeleteOptionalAccessoryRefButton(entry.key, index)">action_delete</span>
                    </li>
                  </ul>
                </p-card>
              </div>
            </div>
          </div>
          </p-card>
      </div>
    </div>
  </div>
</div>
<app-common-modal #deletePackageConfirmationModal
                  [title]="'T_PACKAGE_MANAGEMENT_PACKAGE_DELETE_MODAL_TITLE' | translate"
                  [name]="'T_PACKAGE_MANAGEMENT_PACKAGE_DELETE_MODAL_TITLE'"
                  [description]="'T_PACKAGE_MANAGEMENT_PACKAGE_DELETE_MODAL_DESCRIPTION' | translate"
                  [acceptInnerHtml] = "true" (onClickYesButton)="deletePackage()">
</app-common-modal>
