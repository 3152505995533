<div class="div-demo-message">
  <h4 class="demo-message" [innerHTML]="demoMessage"></h4>
</div>
<div *ngIf="embededLink !== null && embededLink !== undefined && embededLink !== ''" class="video-demo">
  <iframe width="784"
          height="441"
          [src]="sanitizer.bypassSecurityTrustResourceUrl(embededLink)"
          title="Power Build contractor demo"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="iframe-video-demo"></iframe>
</div>
