import {DocumentationCategory} from './documentation-constant.enum';
import {DocumentStamp} from '../../export/shared';
import {OrderInformation} from '../../project/shared/project-model';

export class Documentation {

  id: string;

  title: string;
  titleTranslated?: string;
  category: DocumentationCategory;
  language: string;

  fileName: string;
  link?: string;
  size: string;
  extension: string;
  range: string;
  references: string[];
  downloadInProgress: boolean;

  bslDocumentation: boolean;

  stamperInformation: DocumentStamp;
  orderInformation?: OrderInformation;


  lastUpdateDate: number;
  localFileId: string;
}

export class EditDocumentationForm {
  id?: string;
  name?: {
    [key: string]: string;
  };
  fileName?: string;
  category?: string;
  localFileId?: string;
  extension?: string;
  references?: string[];
}
