import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SwitchBoardComponent} from '../../../../../configuration/shared/model/component';
import {AddingTransformerInfosWrapper} from '../../../../../configuration/shared/model/AddingTransformerInfosWrapper';
import {Characteristic} from '../../../../../configuration/shared/model/IProduct';
import {ComponentType} from '../../../../../configuration/shared/model/component-type';
import {
  BslRangeCharacteristicsSummary,
  BslRangeCharacteristicsSummaryMap
} from '../../../../shared/bsl-reference/offer-data-management-model';
import {Range} from '../../../../../shared/model/range-model';
import {OfferDataManagementHelper} from '../../../../shared/util/offer-data-management-helper';
import * as _ from 'lodash';

@Component({
  selector: 'app-odm-add-offer-main-characteristics',
  templateUrl: './odm-add-offer-main-characteristics.component.html',
  styleUrls: ['./odm-add-offer-main-characteristics.component.less']
})
export class OdmAddOfferMainCharacteristicsComponent implements OnInit {

  @Input()
  bslCharacteristicsMap: BslRangeCharacteristicsSummaryMap;

  @Input()
  selectedRange: Range;

  @Output()
  nextActiveChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  component: SwitchBoardComponent;
  maxNumberOfChoices = 2;
  addingTransformerInfos = new AddingTransformerInfosWrapper(1, 1, []);
  nonMainCharac: string[] = [];

  constructor() {
  }

  ngOnInit() {
    this.bslCharacteristicsMap = OfferDataManagementHelper.initBslCharacteristicsMap(this.bslCharacteristicsMap, this.selectedRange.mainCharacteristics);
    this.nonMainCharac = Object.values(this.bslCharacteristicsMap).filter(char => !char.presentOnAllReferences).map(char => char.key);
    // mock up the data for Component Card Preview
    this.component = OfferDataManagementHelper.getSwitchboardComponentForCardDemo();
    this.component.characteristics = this.getCharacteristics();
  }

  /**
   * Updates model
   *
   * @param characteristics
   */
  chosenCategoriesChanged(characteristics: BslRangeCharacteristicsSummary[]) {
    const chosenCharacteristics = [] as unknown as [Characteristic];
    for (const characteristic of characteristics) {
      chosenCharacteristics.push(OfferDataManagementHelper.getCharacteristic(characteristic));
    }
    this.component = new SwitchBoardComponent('fakeId', 'fakeName', 'fakeProductId', this.copyAndReplaceKeyByName(chosenCharacteristics), this.component.dimensions,
      this.component.options, this.component.reference, this.component.documents, 1, ['fakeRangeId'],
      1, 1, 'fakeQuantityId', ComponentType.CUBICLE, 'fakeExtensible',
      this.component.functionalUnits, 1, 1, this.component.incompatibilities, 1);

    this.selectedRange.mainCharacteristics = chosenCharacteristics.map(charac => this.selectedRange.nameKey + '_' + charac.key);
    this.nextActiveChange.emit(chosenCharacteristics.length as number === 2);
  }

  /**
   * Return true if no selection was made
   */
  isNothingSelected() {
    return this.selectedRange.mainCharacteristics && this.selectedRange.mainCharacteristics.length === 0;
  }

  /**
   *  Mocked function for the Card Component
   */
  mockedFunction() {
    return false;
  }

  /**
   * Copy and replace key by name to show the label in preview
   * At this moment we do not have the translation
   *
   * @param characteristics
   */
  private copyAndReplaceKeyByName(characteristics: [Characteristic]) {
    let characteristicsCopy = _.cloneDeep(characteristics);
    if (characteristicsCopy.length) {
      characteristicsCopy = characteristicsCopy.map(
        charac => {
          const key = charac.key;
          charac.key = charac.name;
          charac.name = key;
          return charac;
        }
      ) as [Characteristic];
    }
    return characteristicsCopy;
  }

  /**
   * Get characteristics
   * Returns chosen characteristics for this range either mocked if nothing chosen
   */
  private getCharacteristics() {
    if (this.selectedRange.mainCharacteristics && this.selectedRange.mainCharacteristics.length) {
      return this.copyAndReplaceKeyByName(this.extractCharacteristics());
    } else {
      return OfferDataManagementHelper.getMockedCharacteristics();
    }
  }

  /**
   * Extract characteristics
   */
  private extractCharacteristics() {
    const characteristics: [Characteristic] = [] as unknown as [Characteristic];
    for (const props in this.bslCharacteristicsMap) {
      if (this.bslCharacteristicsMap.hasOwnProperty(props)
        && this.selectedRange.mainCharacteristics.map(charac => charac.substring(this.selectedRange.nameKey.length + 1)).includes(this.bslCharacteristicsMap[props].key)) {

        this.bslCharacteristicsMap[props].checked = true;
        characteristics.push(OfferDataManagementHelper.getCharacteristic(this.bslCharacteristicsMap[props]));
      } else {
        this.bslCharacteristicsMap[props].checked = false;
        if (this.selectedRange.mainCharacteristics.length === 2) {
          this.bslCharacteristicsMap[props].disabled = true;
        }
      }
    }
    return characteristics;
  }

}
