<div class="container scrollable">

  <h2 translate>T_UP_SELLING_TRANSFORMER_TITLE</h2>



  <p-table #tt styleClass="express-datatable-style" [value]="mappingTransformerSwitchboardList" [rows]="20" [paginator]="true"
           [globalFilterFields]="[cols[0].field, cols[1].field, cols[2].field]">
    <ng-template pTemplate="caption">
      <div class="row">
        <div style="text-align: left" class="col-md-6">
          <em class="fa fa-search" style="margin:4px 4px 0 0"></em>
          <input type="text" pInputText size="50" placeholder="Global Filter"
                 (input)="tt.filterGlobal($event.target.value, 'contains')" style="width:auto">
        </div>
        <div class="col-md-6 right-buttons">
          <button type="button" class="btn btn-default-gray-background" (click)="fileInput.click()" translate>
            T_UP_SELLING_TRANSFORMER_IMPORT
            <input #fileInput type="file" (change)="fileImport($event)" accept=".xlsx, .xls" class="import-button" />
          </button>
          <button type="button" class="btn btn-default-gray-background" (click)="fileExport(tt.filteredValue)" translate>
            T_UP_SELLING_TRANSFORMER_EXPORT
          </button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="3" translate>T_UP_SELLING_TRANSFORMER_NO_MAPPING</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols"  [pSortableColumn]="col.field" [ngClass]="col.style" scope="col">
          {{col.header}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      <tr>
        <th *ngFor="let col of cols" [ngSwitch]="col.field" scope="col">
          <input *ngIf="col.field !== 'cubicleRange'" placeholder="Search" pInputText type="text" (input)="tt.filter($event.target.value, col.field, col.filterMatchMode)">
          <p-multiSelect *ngIf="col.field === 'cubicleRange'" [options]="rangeSelectItems" defaultLabel="All Ranges" (onChange)="tt.filter($event.value, col.field,'in')" styleClass="ui-column-filter"></p-multiSelect>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-mappingTransformerSwitchboard>
      <tr>
        <td>{{mappingTransformerSwitchboard.cubicleRange}}</td>
        <td>{{mappingTransformerSwitchboard.cubicleReference}}</td>
        <td>{{mappingTransformerSwitchboard.transformerReference}}</td>
      </tr>
    </ng-template>
  </p-table>
  <app-spinner *ngIf="importInProgress" [sizeClass]="'large'"></app-spinner>
</div>

