import {Component, Input} from '@angular/core';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
import {UtilService} from '../../shared/util/util.service';
/* Application */
import {Localization} from '../../shared/model/localization.model';
import {User} from '../../shared/user/user';
import {MySEStatusEnum} from '../../shared/model/mySEStatusEnum';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.less']
})
export class PriceComponent {
  @Input()
  price: number;
  @Input()
  mySEStatus: MySEStatusEnum;
  @Input()
  priceLabel: string;
  @SessionStorage()
  localization: Localization;
  @SessionStorage()
  user: User;

  public constructor(public utilService: UtilService) { }

  isValidMySEPriceFromStatus(): boolean {
    return UtilService.isValidMySEPriceFromStatus(this.mySEStatus);
  }
}
