import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CubicleSld} from '../../../shared/bsl-reference/cubicle-sld-model';
import {OfferDataManagementHelper} from '../../../shared/util/offer-data-management-helper';
import sldJson from '../../../../../assets/sld.json';

@Component({
    selector: 'app-odm-offer-sld',
    templateUrl: './odm-offer-sld.component.html',
    styleUrls: ['./odm-offer-sld.component.less']
})
export class OdmOfferSldComponent implements OnInit {

    @Input() sldList: CubicleSld[];
    @Input() loading: boolean;
    @Output() onClickAddButton = new EventEmitter<CubicleSld[]>();
    @Output() onClickCancelButton = new EventEmitter<string>();

    hasSelectedSld: boolean;
    selectedSld: CubicleSld;
    numberOfFunction: number;
    currentFunction: number;
    isSettingNumberOfFunction = true;
    listOfSelectedSlds: CubicleSld[];
    allSLD;
    allSLDCheck = false;
    currentPage = 1;
    pageTotal;
    perPage = 12;

    constructor() {
    }

    ngOnInit() {
        this.initParameters();
    }

    nextButton() {
        if (this.isSettingNumberOfFunction) {
            this.isSettingNumberOfFunction = false;
        } else {
            this.listOfSelectedSlds.push(this.selectedSld);
            this.hasSelectedSld = false;
            this.currentFunction = this.currentFunction + 1;
        }
    }

    addButton() {
        this.listOfSelectedSlds.push(this.selectedSld);
        this.onClickAddButton.emit(this.listOfSelectedSlds);
        this.initParameters();
    }

    cancelButton() {
        this.initParameters();
        this.onClickCancelButton.emit('skip');
    }

    getSldImagePath(sldFileName: string): string {
        let pathSld: string;
        pathSld = './assets/sld/' + sldFileName;
        return pathSld;
    }

    setSldValue(sld: CubicleSld) {
        this.hasSelectedSld = true;
        this.selectedSld = sld;
    }

    isSelectedImage(sld): boolean {
        return this.hasSelectedSld && sld === this.selectedSld;
    }

    /**
     * Function to check the coherency of the number of function field
     */
    onChangeFunctionNumber() {
        if (!this.isValidNumber()) {
            this.numberOfFunction = 1;
        }

        if (this.numberOfFunction < 1) {
            this.numberOfFunction = 1;
        } else if (this.numberOfFunction > 10) {
            this.numberOfFunction = 10;
        }
    }

    /**
     * When user set all SLD or not
     */
    onChangeAllSld() {
        this.allSLDCheck = !this.allSLDCheck;
    }

    isValidNumber() {
        if (!this.numberOfFunction) {
            return false;
        }
        const regex = new RegExp('^[0-9]*$');
        return regex.test(this.numberOfFunction.toString());
    }

    pageChange(event: any) {
        this.getSldList(event.detail.value, event.detail.perPage);
    }

    getSldList(page: number, perPage: number) {
        this.allSLD = new Set<CubicleSld>();
        this.pageTotal = Math.ceil(sldJson.allSldsNames.length / perPage);

        const fromIndex = (page * perPage) - perPage;
        const toIndex = fromIndex + perPage;

        for (let i = fromIndex; i < toIndex; i++) {
            if (sldJson.allSldsNames[i]) {
                this.allSLD.add(new CubicleSld(sldJson.allSldsNames[i], OfferDataManagementHelper.getSvgFileNameWithoutExtension(sldJson.allSldsNames[i])));
            }
        }
    }


    /**
     * Reset the component to initial state
     */
    private initParameters() {
        this.isSettingNumberOfFunction = true;
        this.numberOfFunction = 1;
        this.currentFunction = 1;
        this.hasSelectedSld = false;
        this.listOfSelectedSlds = [];
        this.allSLDCheck = false;
        this.getSldList(1, this.perPage);
    }
}
