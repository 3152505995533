/* Angular modules */
import {Component, OnInit} from '@angular/core';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
import {TranslateService} from '@ngx-translate/core';
/* app modules */
import {Partner} from '../../shared/partner/partner';
import {PartnerService} from '../../shared/partner/partner.service';
import {Contact, ContactFieldEnum, ContactTypeEnum} from '../../shared/partner/contact';
import {LoggerService} from '../../shared/logging/logger.service';
import {MessageService} from 'primeng/api';
import {Observable} from 'rxjs/Observable';
import {Localization} from '../../shared/model/localization.model';
import {LocaleService} from '../../shared/localization/locale.service';
import {UtilService} from '../../shared/util/util.service';

@Component({
  selector: 'app-contact-management',
  templateUrl: './contact-management.component.html',
  styleUrls: ['./contact-management.component.less'],
})

export class ContactManagementComponent implements OnInit {

  @SessionStorage()
  isOnAdministrationScreen = true;

  @SessionStorage()
  localization: Localization;

  partners: Observable<Partner[]>;

  listPhone: string[] = ['(123) 456-7890',
    '+(123) 456-7890',
    '+(123)-456-7890',
    '+(123) - 456-7890',
    '+(123) - 456-78-90',
    '123-456-7890',
    '123.456.7890',
    '1234567890',
    '+31636363634',
    '+316 36 36 36 34',
    '06 36 36 36 34',
    '075-63546725'];

  listEmail: string[] = [
    'my.name-role@server.dom'];

  // assign Enums to variables so they are visible in template
  ContactTypeEnum: typeof ContactTypeEnum = ContactTypeEnum;
  ContactFieldEnum: typeof ContactFieldEnum = ContactFieldEnum;
  cols: any[];

  constructor(private readonly logger: LoggerService,
              private readonly partnerService: PartnerService,
              private readonly localeService: LocaleService,
              private readonly translateService: TranslateService,
              private messageService: MessageService) {
  }

  /**
   * Set contact field value.
   *
   * @param contact
   * @param indexField
   * @param fieldValue
   */
  static setContactFieldValue(contact: Contact, indexField: number, fieldValue) {
    switch (indexField) {
      case ContactFieldEnum.EMAIL:
        contact.email = fieldValue;
        break;
      case ContactFieldEnum.PHONE:
        contact.phone = fieldValue;
        break;
      case ContactFieldEnum.FAX:
        contact.fax = fieldValue;
        break;

      default:
        break;
    }
  }

  ngOnInit() {
    this.partners = this.partnerService.getResellerPartners();
    this.cols = [
      { field: 'name', header: (this.translateService.instant('T_CONTACT_PARTNER')) },
      { field: 'support', header: (this.translateService.instant('T_CONTACT_SUPPORT'))  },
      { field: 'sales', header: (this.translateService.instant('T_CONTACT_SALES'))}
    ];

  }

  /**
   * Update localization data.
   * tuple consist of: [ContactTypeEnum, ContactFieldEnum, event]
   *
   * @param tuple
   */
  public updateFieldLocalization(tuple: [number, number, any]) {
    const indexContact = tuple[0];
    const indexField = tuple[1];
    const event = tuple[2];
    let modifiedContact: Contact;
    if (indexContact === ContactTypeEnum.SUPPORT) {
      modifiedContact = this.localization.supportContact;
    } else if (indexContact === ContactTypeEnum.SALES) {
      modifiedContact = this.localization.salesContact;
    }
    ContactManagementComponent.setContactFieldValue(modifiedContact, indexField, event.target.textContent);
    if (indexContact === ContactTypeEnum.SUPPORT) {
      this.localization.supportContact = modifiedContact;
    } else if (indexContact === ContactTypeEnum.SALES) {
      this.localization.salesContact = modifiedContact;
    }
  }

  /**
   * Update partner data.
   *
   * @param partner
   * @param indexContact
   * @param indexField
   * @param field
   */
  public updateFieldContact(partner: Partner, indexContact: number, indexField: number, field) {
    const fieldValue = field.target.textContent.replace(/(\r\n|\n|\r)/gm, '');
    field.target.innerText = fieldValue;

    let modifiedContact: Contact;
    if (indexContact === ContactTypeEnum.SUPPORT) {
      modifiedContact = partner.supportContact;
    } else if (indexContact === ContactTypeEnum.SALES) {
      modifiedContact = partner.salesContact;
    }
    ContactManagementComponent.setContactFieldValue(modifiedContact, indexField, fieldValue);
    if (indexContact === ContactTypeEnum.SUPPORT) {
      partner.supportContact = modifiedContact;
    } else if (indexContact === ContactTypeEnum.SALES) {
      partner.salesContact = modifiedContact;
    }
  }

  /**
   * Checks if localization's contact data is valid.
   */
  public isLocalizationValid() {
    return this.isValidEmailPhoneFax(this.localization.supportContact, false) && this.isValidEmailPhoneFax(this.localization.salesContact, true);
  }

  /**
   * Save localization.
   */
  public saveLocalization() {
    this.localeService.updateLocalization(this.localization)
      .subscribe((localization) => {
        this.localization = localization;
      },
          error => {
          this.logger.error(error);
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant('T_SAVE_CONTACT_ERROR_MESSAGE'),
          });
        },
        () => this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_SAVE_CONTACT_SUCCESS_MESSAGE'),
        })
      )
  }

  /**
   * Save partner.
   *
   * @param partner
   */
  public save(partner: Partner) {
    this.partnerService.updatePartner(partner)
      .subscribe(() => {
        } // Do nothing
        , error => {
          this.logger.error(error);
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant('T_SAVE_CONTACT_ERROR_MESSAGE'),
          });
        },
        () => this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_SAVE_CONTACT_SUCCESS_MESSAGE'),
        })
      )
  }

  /**
   * Checks if partner's contact data is valid.
   * email can be null for sales contact but not for support contact
   * @param partner
   */
  public isValid(partner: Partner): boolean {
    return this.isValidEmailPhoneFax(partner.supportContact, false) && this.isValidEmailPhoneFax(partner.salesContact, true);
  }


  /**
   * Check all constraints on Contact.
   *
   * @param contact
   * @param emailNullable if email can be null
   */
  private isValidEmailPhoneFax(contact: Contact, isEmailNullable: boolean) {
    return ((isEmailNullable && !contact.email) || UtilService.isValidEmailAdress(contact.email)) &&
      !UtilService.isPhoneFaxValueInvalid(contact.phone) && !UtilService.isPhoneFaxValueInvalid(contact.fax);
  }

}
