/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
/* app modules */
import {Partner} from './partner';
import {LoggerService} from '../logging/logger.service';
import {Role} from '../../core/access-control/enum';
import {Contact} from './contact';

@Injectable()
export class PartnerService {

  @SessionStorage()
  user;

  partner: Partner;

  constructor(private httpClient: HttpClient,
              private logger: LoggerService) {
  }

  getPartner(): Observable<Partner> {
    this.logger.debug('PartnerService getPartner()');
    return this.httpClient.get<Partner>(`/partner`);
  }

  /**
   * Get all partner name for country
   * used for netPrice by partner
   * @param country
   * @return all name of partner
   */
  getAllPartnerNameForCountry(country: string): Observable<string[]> {
    this.logger.debug('PartnerService getPartner()');
    return this.httpClient.get<string[]>(`/partner/all/name/` + country);
  }

  /**
   * Get reseller partners.
   * Adds default contacts values if they dont exist
   */
  getResellerPartners(): Observable<Array<Partner>> {
    this.logger.debug('PartnerService getResellerPartners()');
    return this.httpClient.get<Array<Partner>>('/partner/resellers/' + this.user.partnerCountry)
      .map((partners: Partner[]) => {
        const partnersWithContacts: Partner[] = [];
        for (const partner of partners) {
          if (!partner.salesContact) {
            partner.salesContact = new Contact();
          }
          if (!partner.supportContact) {
            partner.supportContact = new Contact();
          }
          partnersWithContacts.push(partner);
        }
        return partnersWithContacts;
      });
  }

  updatePartner(partner: Partner): Observable<Partner> {
    this.logger.info('PartnerService updatePartner()');
    return this.httpClient.put<Partner>('/partner', partner);
  }

  /**
   * Retrieves all partners for specific country and having one of the given role
   * @param {Role[]} roles list of needed roles
   * @returns {Observable<Array<Partner>>} list of partners
   */
  getPartnersByCountryAndRoles(roles: Role []): Observable<Array<Partner>> {
    this.logger.debug('PartnerService getResellersAndContractors()');
    const partnersNeededProfiles = {'country': this.user.partnerCountry, 'partnersRoles': roles};
    return this.httpClient.post<Array<Partner>>('/partner/defined-roles/', partnersNeededProfiles);
  }
}
