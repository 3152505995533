<div class="col-md-5">
  <label class="control-label">
    <span translate>{{key}}</span>
    <span *ngIf="unit" translate> ({{unit}})</span>
  </label>
</div>
<div class="col-xs-5">
  <select [disabled]="isOneValueFilter(values) || isReadOnlyProject()" class="form-control pointer" [ngClass]="{'schneider-style': schneiderStyle}"
          (change)="changeSelect($event.target.value)">

    <option [selected]="!isOneValueFilter(values)" disabled></option>

    <ng-container *ngFor="let value of values">
      <option *ngIf="!value.disabled" [selected]="value.checked" value="{{value.value}}" translate>{{value.value}}</option>
    </ng-container>

  </select>
</div>
<div class="col-md-2">
  <button class="btn btn-default-white-background" *ngIf="isSelected(values) && !isReadOnlyProject()" (click)="changeSelect(null)" translate>T_RESET_BUTTON</button>
</div>
