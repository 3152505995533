<div class="price-management-container">

  <app-navigation-side-panel class="nav-side-panel" [items]="priceManagementMenuItems"></app-navigation-side-panel>
  <se-divider option="vertical"></se-divider>

  <div class="price-management-content">
    <div *ngIf="showTab(1)">
      <app-public-price-upload></app-public-price-upload>
    </div>

    <div *ngIf="showTab(2)">
      <app-quotation-setup></app-quotation-setup>
    </div>

    <div *ngIf="showTab(3)">
      <app-net-price-form
        (netPricesUpdatesStart)="netPricesUpdatesStart($event)"
        (netPricesUpdatesDone)="netPricesUpdatesDone()"
        (netPricesUpdatesFail)="netPricesUpdatesFail($event)">
      </app-net-price-form>
    </div>

  </div>
</div>
