import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SwitchBoardComponent} from '../../shared/model/component';
import {FunctionalUnit} from '../../../shared/model/functionalUnit-model';
import {WrapperModalContent} from '../../shared/model/wrapperModalContent';
import {ActiveTabType} from '../../shared/model/activeTab_Type';
import {ModalType} from '../../shared/model/modal_type';
import {Item, Status} from '../../../project/shared/project-model';
import {SessionStorage} from 'ngx-webstorage';
import {AddingTransformerInfosWrapper} from '../../shared/model/AddingTransformerInfosWrapper';
import {OptionsPanelService} from '../../shared/services/options-panel.service';
import {ComponentType} from '../../shared/model/component-type';
import {PanelOptionWrapper} from '../../shared/model/panelOptionWrapper';
import {User} from 'app/shared/user/user';
import {Documentation} from '../../../shared/documentation/documentation.model';
import {StaticDocumentationService} from '../../../export/shared';
import {CubicleService} from '../../shared/services/cubicle.service';
import {FuseService} from '../../shared/services/fuse.service';
import {TransformerService} from '../../shared/services/transformer.service';
import {UpSellingTransformerService} from '../../../admin/shared/up-selling/up.selling.transformer.service';

@Component({
  selector: 'app-sld-component-viewer',
  templateUrl: './sld-component-viewer.component.html',
  styleUrls: ['./sld-component-viewer.component.less']
})
export class SldComponentViewerComponent implements OnInit {

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  user: User;

  @Input()
  component: SwitchBoardComponent;

  @Input()
  showCompleteSld: boolean;

  @Input()
  onCardsPanel: boolean;

  @Input()
  switchboardPosition: number;

  @Input()
  isSelected: boolean;
  @Input()
  cubicleSelected: boolean;
  @Input()
  transformerSelected: boolean;
  @Input()
  selectedFunction: number;
  isLastComponent: boolean;
  @Output()
  addTransformerEvent = new EventEmitter<AddingTransformerInfosWrapper>();
  @Output()
  selectedComponent = new EventEmitter<WrapperModalContent>();
  plansDocuments: Documentation [] = [];
  modalType: ModalType = ModalType.INFORMATION_MODAL;
  hasTransformerCompatible: boolean;

  constructor(private optionsPanelService: OptionsPanelService,
              private  upSellingTransformerService: UpSellingTransformerService) {
  }

  ngOnInit() {
    // Search if we have transformer compatible on the cubicle
    let transformerCompatible = false;
    this.component.functionalUnits.forEach(functionalUnit => {
      if (functionalUnit.transformerCompatible) {
        transformerCompatible = true;
      }
      if (functionalUnit.transformer) {
        this.modalType = ModalType.TRANSFORMER_MODAL;
      }
    });
    this.hasTransformerCompatible = transformerCompatible;
    if (this.switchboardPosition !== null && this.switchboardPosition !== undefined) {
      this.component.position = this.switchboardPosition;
    }
    this.switchboardPosition === (this.currentItemct === null || this.currentItemct.components.length - 1) ? this.isLastComponent = true :
      this.isLastComponent = false;
    this.plansDocuments = this.component.documents;
  }

  openCharacteristicModal() {
    const wrapperModalContent = new WrapperModalContent(this.component, this.plansDocuments, ActiveTabType.CHARACTERISTICS, this.modalType);
    this.selectedComponent.emit(wrapperModalContent);
  }

  addTransformer(functionalUnitNumber: number) {
    this.upSellingTransformerService.getCompatibleTransformerListByRangeAndReference(this.component.reference.ref, this.currentItemct.range.nameKey).subscribe(
      compatibleTransformerList => {
        this.addTransformerEvent.emit(new AddingTransformerInfosWrapper(this.component.position, functionalUnitNumber, compatibleTransformerList));
      });
  }
  showPanelCubicleOptions() {
    this.currentItemct.selectedComponentIndex = this.switchboardPosition;
    this.optionsPanelService.setSelectedComponent(this.component);
    this.optionsPanelService.updateSelectedComponentInfos(new PanelOptionWrapper(ComponentType.CUBICLE, this.switchboardPosition, null));
  }
  showPanelTransformerOptions(functionalUnitNumber: number) {
    this.currentItemct.selectedComponentIndex = this.switchboardPosition;
    this.optionsPanelService.setSelectedComponent(this.component.functionalUnits[functionalUnitNumber].transformer);
    this.optionsPanelService.updateSelectedComponentInfos(
      new PanelOptionWrapper(ComponentType.TRANSFORMER, this.switchboardPosition, functionalUnitNumber
      ));
  }
  getSldImagePath(functionalUnit: FunctionalUnit, positionIndex: number, functionalUnitsCount: number): string {
    let pathSld: string;
    pathSld = './assets/sld/' + functionalUnit.sldKey;
    return pathSld;
  }
  cannotBeLast() {
    return this.isLastComponent && this.component && this.component.ordering && this.component.ordering[0]
      && this.component.ordering[0].notLast;
  }

  showTransformerIncompatibilityWarning() {
    // at this moment warning is shown only for INDONESIA offer
    if (this.currentItemct.range.nameKey !== 'T_SM6_ID') {
      return false;
    }
    const cubicleTypeConcerned = CubicleService.isQMorQMCorPM(this.component, 'T_SM6_ID_SM6R_');
    if (cubicleTypeConcerned) {
      const fuseSelected = FuseService.getSelectedFuseOptionFromCubicle(this.component);
      if (fuseSelected && fuseSelected.value !== 'T_TRANSFORMER_ID_WITHOUT') {
        return !TransformerService.isCompatibleWithFuse(this.component, TransformerService.getTransformerFromCubicle(this.component), fuseSelected);
      }
    }
    return false;
  }

  getFuWidth(): number {
    return 100 / this.component.functionalUnits.length;
  }

  getMarginLeftFuTransformer(position): number {
    return this.getFuWidth() * position;
  }

  /**
   * Return true if we have to show "Add" icon to add a new Transformer
   */
  showAddTransformerIcon(functionalUnit): boolean {
    return this.showCompleteSld && functionalUnit.transformerCompatible && !functionalUnit.transformer &&
      this.currentItemct.status !== Status.ordered;
  }
}
