<app-common-modal #childModal name="T_BOM_ORDER_REFERENCE_MODAL_MESSAGE_TITLE"
                  title="{{referenceOrderModalTitle | translate}}" [withFooter]="false">
  <div modal-body>
    <section>
      <!-- message -->
      <div class="message-margin">
        {{'T_BOM_ORDER_REFERENCE_MODAL_MESSAGE' | translate}}
      </div>
      <!--table-->
      <p-table [value]="referencesToOrder">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" class="reference-column" translate>T_BOM_ORDER_REFERENCE_MODAL_REFERENCE_COLUMN</th>
            <th scope="col" class="offer-column" translate>T_BOM_ORDER_REFERENCE_MODAL_OFFER_COLUMN</th>
            <th scope="col" class="quantity-column" translate>T_BOM_ORDER_REFERENCE_MODAL_QUANTITY_COLUMN</th>
            <th scope="col" class="price-column" translate [translateParams]="{currency: localization.displayedCurrency}" >T_BOM_ORDER_REFERENCE_MODAL_PRICE_COLUMN</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-referencesToOrder>
          <tr>
            <td>{{referencesToOrder.reference}}</td>
            <td>{{referencesToOrder.offer}}</td>
            <td>{{referencesToOrder.quantity}}</td>
            <td>{{referencesToOrder.price}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer" class="footer-margin">
          <tr>
            <td colspan="2" translate>T_BOM_TOTAL</td>
            <td>{{quantity}}</td>
            <td>{{totalPrice}}</td>
          </tr>
        </ng-template>
      </p-table>
      <!--footer-->
      <div class="footer-margin">
        <button class="btn cancel-button" (click)="clickCancelButton(); hide()" translate>T_BUTTON_CANCEL</button>
        <button class="btn validate-button" (click)="clickValidateButton(); hide()" translate>T_BOM_ORDER_PROCEED
        </button>
      </div>
    </section>
  </div>
</app-common-modal>

