import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap';
import {User} from '../../shared/user/user';
import {SessionStorage} from 'ngx-webstorage';
import {UtilService} from '../../shared/util/util.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-cpq-project-choice-modal',
  templateUrl: './cpq-project-choice-modal.component.html',
  styleUrls: ['./cpq-project-choice-modal.component.less']
})
export class CpqProjectChoiceModalComponent implements AfterViewInit {
  @ViewChild('childModal') public childModal: ModalDirective;
  @Input() cpqNumber: string;
  @Output() onCreateCpqProject = new EventEmitter<string>();
  @Output() onCreateStandardProject = new EventEmitter<void>();
  @Output() onDuplicateCpqProject = new EventEmitter<string>();
  @Output() onDuplicateCpqProjectToStandard = new EventEmitter<string>();
  @Output() onAssociateCpqProject = new EventEmitter<string>();

  @SessionStorage()
  user: User;

  isStandardProject = false;
  type: string;

  constructor(private translateService: TranslateService) {
  }

  ngAfterViewInit() {
    const modalOptions = this.childModal.config;
    modalOptions.backdrop = 'static';
    this.childModal.config = modalOptions;

  }

  show(defaultCpqNumber, type) {
    this.cpqNumber = defaultCpqNumber;
    this.type = type;
    if (this.isDuplicateModal() || this.isAssociateModal()) {
      // Come from a CPQ project
      this.isStandardProject = false;
    }
    this.childModal.show();
  }

  hide() {
    this.childModal.hide();
  }

  clickCreateButton() {
    if (this.isStandardProject) {
      if (this.isDuplicateModal()) {
        this.onDuplicateCpqProjectToStandard.emit();
      } else {
        this.onCreateStandardProject.emit();
      }
    } else {
      if (this.isDuplicateModal()) {
        this.onDuplicateCpqProject.emit(this.cpqNumber);
      } else if (this.isAssociateModal()) {
        this.onAssociateCpqProject.emit(this.cpqNumber);
      } else {
        this.onCreateCpqProject.emit(this.cpqNumber);
      }
    }
  }

  onChangeProjectType() {
    this.isStandardProject = !this.isStandardProject;
  }

  isInvalidCpqNumber(): boolean {
    return !(this.cpqNumber !== null &&
      this.cpqNumber !== undefined &&
      this.cpqNumber.length > 0 &&
      UtilService.regAlphaNumeric.test(this.cpqNumber));
  }

  /**
   * Return title of the modal
   */
  getTitle(): string {
    if (this.isDuplicateModal()) {
      return this.translateService.instant('T_DUPLICATE_PROJECT_TITLE');
    } else if (this.isAssociateModal()) {
      return this.translateService.instant('T_ASSOCIATE_PROJECT_TITLE');
    } else {
     return this.translateService.instant('T_ADD_NEW_PROJECT_TITLE');
    }
  }

  /**
   * If modal is displayed to duplicate project
   */
  isDuplicateModal() {
    return this.type === 'duplicate';
  }

  /**
   * If modal is displayed to associate project
   */
  isAssociateModal() {
    return this.type === 'associate';
  }

  /**
   * Return label of action button to create or associate a project
   */
  getActionButtonLabel() {
    if (this.isAssociateModal()) {
      return this.translateService.instant('T_BUTTON_ASSOCIATE');
    } else {
      return this.translateService.instant('T_BUTTON_CREATE');
    }
  }
}
