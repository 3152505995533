<section class="container-fluid">


  <!-- SPINNER -->
  <div *ngIf="!initialized" class="odm-left-column">
    <app-spinner [sizeClass]="'large'">
      <span class="waiting-message" translate>T_OFFER_DATA_MANAGEMENT_LOADING_TIME</span>
    </app-spinner>
  </div>

  <!-- LEFT COLUMN -->
  <div *ngIf="initialized" class="odm-left-column scrollable scrollable-max-height">

    <div class="files-list-container form-group">

      <!-- LIST OF DOCUMENT -->
      <ul *ngIf="initialized" class="list-group">
        <li *ngFor="let key of modelKeys">

          <!-- CHECKBOX AND LABEL -->
          <div class="col-md-8">
            <label for="{{key}}" class="custom-checkbox-container">
              <input id="{{key}}" type="checkbox" class="checkbox" [ngModel]="model[key].fileCheckbox.checked" (change)="documentCheckChange($event.target)"/>
              <span class="checkmark"></span>
              <span class="file-description">{{model[key].fileCheckbox.description}}</span>
            </label>
          </div>

          <!-- CATEGORY SELECTOR -->
          <div class="col-md-4 files-category">
            <select class="col-md-11"
                    *ngIf="model[key].fileCheckbox.checked"
                    (change)="documentCategoryChange($event.target.value, key)">
              <ng-container *ngFor="let option of fileCategories">
                <option [value]="option"
                        [selected]="option === model[key].category"
                        translate>{{'T_DOC_' + option | uppercase}}
                </option>
              </ng-container>
            </select>
          </div>

        </li>
      </ul>
    </div>
  </div>

  <!-- RIGHT COLUMN -->
  <div class="odm-right-column scrollable scrollable-max-height">
    <div class="right-column-title"><span translate>T_OFFER_DATA_MANAGEMENT_SELECT_FILTERS_PREVIEW</span></div>
    <div class="right-column-detail">
      <div class="right-column-label"><span translate>T_OFFER_DATA_MANAGEMENT_DOCUMENTATION_PREVIEW</span></div>
      <ng-container *ngFor="let docKey of documentsMapKeys">
        <app-odm-documents-preview
          [title]="getTitle(docKey)"
          [documents]="documentsMap[docKey]"></app-odm-documents-preview>
      </ng-container>
      <p class="app-card-info-center" *ngIf="!selectedDocuments" translate>T_OFFER_DATA_MANAGEMENT_DOCUMENTS_SELECTION_INFO</p>
    </div>
  </div>
</section>
