<div class="odm-characteristics">
  <ul>
    <li *ngFor="let key of characteristicsKeys">
      <label class="custom-checkbox-container" for="{{bslCharacteristicsMap[key].key}}"
             [ngClass]="{disabled: bslCharacteristicsMap[key].disabled}">
        <input type="checkbox" class="checkbox" id="{{bslCharacteristicsMap[key].key}}"
               [checked]="bslCharacteristicsMap[key].checked"
               [disabled]="bslCharacteristicsMap[key].disabled"
               (change)="characteristicChange($event.target)">
        <span class="checkmark"></span>

        <!-- TOOLTIP TEMPLATE-->
        <ng-template #tooltipTemplate>
          <span class="line-break"> {{getTooltipMessage(bslCharacteristicsMap[key].missingOnRef)}} </span>
        </ng-template>

        <!-- ELEMENT WITH TOOLTIP -->
        <span *ngIf="bslCharacteristicsMap[key].disabled" [tooltip]="tooltipTemplate" placement="right">{{bslCharacteristicsMap[key][displayProperty]}}</span>
        <!-- ELEMENT WITHOUT TOOLTIP -->
        <span *ngIf="!bslCharacteristicsMap[key].disabled">{{bslCharacteristicsMap[key][displayProperty]}}</span>

      </label>
    </li>
  </ul>
</div>
