import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Project} from '../shared/project-model';
import {SessionStorage} from 'ngx-webstorage';
import {Role} from '../../core/access-control/enum';
import {User} from '../../shared/user/user';
import {RightsService} from '../../shared/rights/rights.service';

@Component({
  selector: 'app-project-filter',
  templateUrl: './project-filter.component.html',
  styleUrls: ['./project-filter.component.less']
})
export class ProjectFilterComponent {

  @Input()
  hasProjectLength: boolean;

  @Input()
  loadedProjectCount: any;

  @Input()
  statusFilters: any;

  @Input()
  disableFilters: any;

  @Input()
  onMyLastProjectsTab: boolean;

  @Input()
  hasNoProject: boolean;

  @Input()
  resultFiltredProjects: Array<Project>;

  @Output()
  filterInputKeyUp = new EventEmitter<string>();

  @Output()
  updateListStatusFilter = new EventEmitter<void>();

  @Output()
  createAProject = new EventEmitter<void>();

  @SessionStorage()
  user: User;

  readonly STATUS_CONFIGURED = 'configured';
  readonly STATUS_QUOTED = 'quoted';
  readonly STATUS_ORDERED = 'ordered';
  readonly STATUS_OWNED = 'owned'; // An owned project is a project that is created by the current user or the last updater of it.
  readonly CPQ_PROJECTS = 'cpq';
  readonly CPQ_PROJECTS_WITH_DEFAULT_QUOTE_ID = 'cpqProjectsWithDefaultQuoteId';

  nameFilterContent;

  constructor(private translateService: TranslateService,
              private rightService: RightsService) {
  }

  /**
   * Method to get the tooltip message of a status filter
   *
   * @param {string} status
   */
  getCountProjects(status: string): string {
    let count: number;
    switch (status) {
      case this.STATUS_CONFIGURED :
      case this.STATUS_QUOTED :
        count = this.loadedProjectCount.configured;
        break;
      case this.STATUS_ORDERED :
        count = this.loadedProjectCount.ordered;
        break;
      case this.STATUS_OWNED :
        count = this.loadedProjectCount.owned;
        break;
      case this.CPQ_PROJECTS :
        count = this.loadedProjectCount.cpqProjects;
        break;
      case this.CPQ_PROJECTS_WITH_DEFAULT_QUOTE_ID :
        count = this.loadedProjectCount.cpqProjectsWithDefaultQuoteId;
        break;
      default:
        return '';
    }
    if (count > 1 ) {
      return count + ' ' + this.translateService.instant('T_PROJECTS');
    } else {
      return count + ' ' + this.translateService.instant('T_PROJECT');
    }
  }

  /**
   * Emit event for new value of search input
   */
  updateProjectsList() {
    this.filterInputKeyUp.emit(this.nameFilterContent);
  }

  /**
   * Emit event for new status filter
   */
  updateStatusFilter() {
    this.updateListStatusFilter.emit();
  }

  switchCpqFilter(filterName: string) {
    if (filterName === 'ALL') {
      if (this.statusFilters.cpqProjects) {
        this.statusFilters.cpqProjectsWithDefaultQuoteId = false;
      }
    } else if (filterName === 'QUOTE_ID') {
      if (this.statusFilters.cpqProjectsWithDefaultQuoteId) {
        this.statusFilters.cpqProjects = false;
      }
    }

    this.updateStatusFilter();
  }

  /**
   * Emit event for create a project button
   */
  addProject() {
    this.createAProject.emit();
  }
}
