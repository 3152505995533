import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CommonModalComponent} from '../../../../shared/common-modal/common-modal.component';
import {AccessoryCategory, AccessoryCharacteristics} from '../../../../shared/model/accessory.model';
import {Range} from '../../../../shared/model/range-model';
import {TranslateService} from '@ngx-translate/core';
import {TranslationService} from '../../../shared';
import {CubicleOption, EditOptionPayload} from '../../../../shared/model/cubicleOption-model';

export type EditOptionForm = {
  name: {
    [key: string]: string;
  };
  description: {
    [key: string]: string;
  };
  reference: string;
  oldReference: string;
  rangeId: string;
  onPopUp: boolean;
}

@Component({
  selector: 'app-odm-edit-option-modal',
  templateUrl: './odm-edit-option-modal.component.html',
  styleUrls: ['./odm-edit-option-modal.component.less']
})
export class OdmEditOptionModalComponent {

  @Input()
  references: string[];
  @Output()
  saveOption: EventEmitter<EditOptionPayload> = new EventEmitter<EditOptionPayload>();

  @ViewChild('editOptionModal', {static: false}) editOptionModal: CommonModalComponent;

  languages: string[];
  editOptionForm: EditOptionForm = {name: {}, description: {},reference: '', oldReference: '', rangeId: '', onPopUp: false };
  option: CubicleOption;
  showOnPopupManagement = false;

  constructor(private translationService: TranslationService, private translateService: TranslateService) {
  }

  showAddMode(languages: string[], showOnPopupManagement: boolean){
    this.showOnPopupManagement = showOnPopupManagement;
    if(!showOnPopupManagement){
      this.editOptionForm.onPopUp = true;
    }
    this.languages = languages;
    this.languages.forEach(lang => {
      this.editOptionForm.name[lang] = null;
      this.editOptionForm.description[lang] = null;
    });
    this.editOptionModal.show();
  }

  showEditMode(option: CubicleOption, reference: string, showOnPopupManagement: boolean){
    this.showOnPopupManagement = showOnPopupManagement;
    this.option = option;
    this.editOptionForm.reference = reference;
    this.editOptionForm.oldReference = reference;
    this.editOptionForm.onPopUp = option.onPopUp;
    this.translationService.getLocalesOfCountryOfKey(option.name).subscribe((locale => {
      locale.forEach(loc => {
        this.editOptionForm.name[loc.language] = loc.translations[0].translation;
      })
    }));

    this.translationService.getLocalesOfCountryOfKey(option.description).subscribe((locale => {
      locale.forEach(loc => {
        this.editOptionForm.description[loc.language] = loc.translations[0].translation;
      })
    }));

    this.editOptionModal.show();
  }

  hide() {
    this.editOptionModal.hide();
  }

  isValidForm(): boolean {
    for (let key in this.editOptionForm.name) {
      if(!this.editOptionForm.name[key]){
        return false;
      }
    }
    return true;
  }

  clickOnSaveOption() {
    const editPayload: EditOptionPayload = {name: this.editOptionForm.name, description: this.editOptionForm.description, rangeId : this.editOptionForm.rangeId, reference : this.editOptionForm.reference, oldReference : this.editOptionForm.oldReference, nameKey: '', onPopUp: this.editOptionForm.onPopUp};
    if (this.option != null) {
      editPayload.nameKey = this.option.name;
    }
    this.saveOption.emit(editPayload);
  }

  // function for the ngfor + ngmodel, so it doesn't lose focus
  trackByFn(index, item): number {   return index; }

}
