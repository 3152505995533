import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '@angular/common/http';
import {Range} from '../../../shared/model/range-model';
import {BslReference} from '../bsl-reference/bsl-reference-model';
import {Service} from './services.model';

@Injectable()
export class ServicesService {

  constructor(private readonly httpClient: HttpClient) {
  }

  /**
   * Get all bsl service references by the given bsl range id
   * @param bslRangeId
   */
  getBslServiceReferencesForRangeId(bslRangeId: number): Observable<BslReference[]> {
    return this.httpClient.cache().get<BslReference[]>('/services/references/' + bslRangeId );
  }

  /**
   * Add a new service to selected range
   * @param rangeId
   * @param service
   */
  addNewServiceToRange(rangeId: string, service: Service): Observable<Range> {
    return this.httpClient.post<Range>('/services/range/' + rangeId, service);
  }

}
