export class Pair<K, V> {
  private readonly _key: K;
  private readonly _value: V;


  constructor(key: K, value: V) {
    this._key = key;
    this._value = value;
  }

  getKey(): K {
    return this._key;
  }

  getValue(): V {
    return this._value;
  }


}
