import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProjectDetailsComponent} from './project-details/project-details.component';
import {TranslateModule} from '@ngx-translate/core';
import {ProjectSharingNavbarComponent} from './project-sharing-navbar/project-sharing-navbar.component';
import {EcorealCtSharedModule} from '../shared/shared.module';
import {ShareProjectModalComponent} from '../project/share-project-modal/share-project-modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TooltipModule} from 'ngx-bootstrap';
import {ProjectFilesComponent} from './project-files/project-files.component';
import {UploadService} from '../admin/shared';
import {FilesService} from './shared/files.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    EcorealCtSharedModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule
  ],
  declarations: [ProjectDetailsComponent, ProjectSharingNavbarComponent, ShareProjectModalComponent, ProjectFilesComponent],
  providers: [UploadService, FilesService]
})
export class ProjectSharingModule { }
