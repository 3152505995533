/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';
/* app modules */
import {ComponentSearchModeEnum, SwitchBoardComponent} from '../model/component';
import {LoggerService} from '../../../shared/logging/logger.service';

/* other sources */

@Injectable()
export class ComponentService {

  constructor(private logger: LoggerService,
              private httpClient: HttpClient) {
  }

  /**
   * Get components for item
   *
   * @param {string} itemId
   * @param {ComponentSearchModeEnum} mode what to get
   * @returns {Observable<Array<SwitchBoardComponent>>}
   */
  public getComponents(itemId: string, mode: ComponentSearchModeEnum = ComponentSearchModeEnum.ALL): Observable<Array<SwitchBoardComponent>> {
    this.logger.debug('ComponentService getComponents()');

    // TODO : return last switchboard components but could return well version if many version exist
    return this.httpClient
      .post<Array<SwitchBoardComponent>>('/switchboardComponents/search/' + mode,
        {
          itemId: itemId,
          startDate: Date.now(),
          endDate: null,
        }).pipe(
        map(components =>
          components.map(component => {
            component.characteristics.map(ch => {
              ch.value = ch.value.toString().match(/\D/) === null ?
                Number(ch.value) : ch.value.toString();
              return ch;
            });
            return component;
          })
        ));
  }

  public canBeLastComponent(component: SwitchBoardComponent) {
    return !(component && component.ordering && component.ordering[0] && component.ordering[0].notLast === true);
  }

  public getComponent(rangeId: string, reference: string): Observable<SwitchBoardComponent> {
    return this.httpClient.get<SwitchBoardComponent>('/switchboardComponents/' + rangeId + '/' + reference);
  }
}
