/* Angular modules */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
/* ngx modules */
import {TranslateModule} from '@ngx-translate/core';
/* app modules */
import {EcorealCtSharedModule} from '../shared/shared.module';
import {ConfigureParentComponent} from './configure-parent/configure-parent.component';
import {ComponentService} from './shared/services/component.service';
import {LayoutComponent} from './layout/layout.component';
import {HeaderComponent} from './header/header.component';
import {SldComponent} from './sld/sld.component';
import {UtilService} from '../shared/util/util.service';
import {MessageService} from './shared/services/message.service';
import {DocumentationHelperService} from '../export/shared';
import {SldOptionsComponentComponent} from './sld/sld-options-component/sld-options-component.component';
import {OptionsPanelService} from './shared/services/options-panel.service';
import {ItemNavigationService} from './shared/services/itemNavigation.service';
import {CubicleService} from './shared/services/cubicle.service';
import {TransformerService} from './shared/services/transformer.service';
import {FuseService} from './shared/services/fuse.service';
import {ConfigurationHelperService} from './shared/services/configuration.helper.service';
import {FilterService} from './shared/services/filter.service';


@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    EcorealCtSharedModule
  ],
  declarations: [ConfigureParentComponent, HeaderComponent, SldComponent, LayoutComponent, SldOptionsComponentComponent],
  providers: [ComponentService, CubicleService, TransformerService, FuseService, ItemNavigationService, UtilService, MessageService,
              DocumentationHelperService, OptionsPanelService, ConfigurationHelperService, FilterService],
  exports: []
})
export class ConfigurationModule {
}
