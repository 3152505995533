<nav *ngIf="(currentItemct && (currentItemct.currentNavigationStep > NavigationStep.PROJECTS) || noItemNavigationStep > NavigationStep.PROJECTS)
|| (currentItemct === null && currentProject !== null && noItemNavigationStep >= NavigationStep.PROJECT_BILL_OF_MATERIALS)" class="navigation-tabs">
  <ul>
    <li *ngFor="let step of navSteps" [ngClass]="{'nav-disabled': currentItemct && step.id > currentItemct.maxNavigationStep}">
      <a (click)="changeTab(step)" *ngIf="step.activate === 'true'" [ngClass]="{'bc-after': step.id > 1, 'bc': true}">
        <span [ngClass]="{'currentStep' : (!currentItemct && step.id === NavigationStep.OFFERS_CT)
        || (currentItemct  && step.id.toString() === currentItemct.currentNavigationStep?.toString())
        || (currentItemct === null && currentProject !== null && step.id === noItemNavigationStep)}"
          translate>{{step.title}}</span>
      </a>
    </li>
  </ul>
  <div *ngIf="(currentItemct || (currentItemct === null && currentProject !== null))"
    class="item-last-save" align="right">
    <span class="span-icon se-icons">notification_ok</span>
    <span translate [translateParams]="{updateDate: getLastUpdateDate()}">T_LAST_UPDATE</span>
  </div>
</nav>

