<fieldset *ngIf="displayAccessories()" class="characteristicsFieldset">
  <legend class="shGreenTitle">
    <div class="col-xs-7"><span translate>{{title}}</span></div>
    <div class="col-xs-5">
      <ng-template #accessInfoTemplate><div [innerHtml]="accessInfo | translate"></div></ng-template>
      <span *ngIf="accessInfo" class="se-icons font-param access-info" [tooltip]="accessInfoTemplate"
            placement="left" container="body" aria-hidden="true">information_circle</span>
      <div (click)="changeDisplay()">
        <span [tooltip]="'T_COLLAPSE_TOOLTIP' | translate" *ngIf="display" class="se-icons arrow" translate>arrow2_up</span>
        <span [tooltip]="'T_EXPAND_TOOLTIP' | translate" *ngIf="!display" class="se-icons arrow">arrow2_down</span>
      </div>
    </div>
  </legend>
  <div *ngIf="display" [@fadeInOut] class="form-horizontal">
    <ng-container *ngFor="let element of getElementsSorted(); let isLast = last;" >
      <div class="form-group" *ngIf="!noValueOrHidden(element)" (click)="isClickOnLast(isLast)">
        <div class="col-xs-5">
          <!-- LABEL -->
          <label [for]="element.id" class="control-label"
                 [ngClass]="{ 'required-red-asterisk' : element.required && (!element.options || element.options.length >= 2)}"
                 translate>{{element.label}}
          </label>

          <!-- TOOLTIP TEMPLATE -->
          <ng-template #tooltipTemplate>
            <div [innerHTML]="element.information | translate" class="tooltip-field"></div>
          </ng-template>

          <!-- INFO PELLET WITH TOOLTIP -->
          <span *ngIf="element.information" class="se-icons brand-information" container="body"
                [tooltip]="tooltipTemplate" placement="right">information_circle</span>
        </div>
        <div class="col-xs-5">
          <div [tooltip]="element.tooltip | translate" placement="{{isHtmlElement(element, 'multiSelect')?'top':'bottom'}}" container="body">

            <!-- SELECT -->
            <div *ngIf="!isOnlyOneAvailableOption(element)">
              <select *ngIf="isHtmlElement(element, 'select') || !!element.packageOptions" class="form-control" [id]="element.id"
                      [ngClass]="{'hide-select-arrow': element.chooseValue}"
                      [required]="element.required" [disabled]="element.disabled || readOnly || element.chooseValue"
                      (change)="onChangeValue($event.target.value, element)">
                <option *ngIf="!valueAlreadyExists(element)" disabled selected
                        [value]=" "></option>
                <ng-container *ngFor="let val of getElementOptionsSorted(element)">
                  <option [selected]="val.selected" *ngIf="!val.disabled" [value]="val.value" [ngClass]="{'highlighted-selected-option': val.selected}" translate>{{val.value}}</option>
                </ng-container>
                <ng-container *ngFor="let val of element.packageOptions">
                  <option [selected]="val.selected" [value]="val.id" [ngClass]="{'highlighted-selected-option': val.selected}">{{val.name}}</option>
                </ng-container>
              </select>

              <!-- MULTISELECT -->
              <ss-multiselect-dropdown *ngIf="isHtmlElement(element, 'multiSelect')"
                                       [disabled]="readOnly"
                                       [options]="element.multiOptions"
                                       [texts]="myTexts"
                                       [settings]="mySettings"
                                       name="{{element.id}}"
                                       [(ngModel)]="chosenOptionsMultiselectModel[element.id]"
                                       (dropdownClosed)="onChangeValue($event, element)"
                                       (onAdded)="onSelectValue($event, element)"
                                       (onRemoved)="onUnselectValue($event, element)">
              </ss-multiselect-dropdown>


              <div id="alert_placeholder_{{element.label}}" class="alert-div">
                <div *ngIf="element.isOnAlert" id="alertDiv_{{element.label}}" class="alert  alert-danger">
                  <span translate>{{element.alertMessage}}</span></div>
              </div>
            </div>

            <input *ngIf="isOnlyOneAvailableOption(element)"
                   type="text" disabled class="form-control" [id]="element.id"
                   [value]="getOnlyAvailableOption(element).value.toString() | translate">
          </div>
        </div>

        <!-- UNIT -->
        <div class="col-xs-1">
          <span *ngIf="element.unit" translate>{{element.unit}}</span>
        </div>

        <!-- RESET -->
        <div class="col-xs-1" *ngIf="currentItemct?.status !== Status.ordered">
          <button class="btn btn-default-grey-background" *ngIf="element.chooseValue" (click)="resetValue(element)" translate>T_RESET_BUTTON</button>
        </div>

      </div>
    </ng-container>

  </div>
</fieldset>
