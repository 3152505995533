/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {BslReference} from '../shared/bsl-reference/bsl-reference-model';
/* ngx modules */
import {SwitchBoardComponent} from '../../configuration/shared/model/component';
import {LoggerService} from '../../shared/logging/logger.service';
import {CubicleSld} from '../shared/bsl-reference/cubicle-sld-model';
import {Range} from '../../shared/model/range-model';
import {BslRangeCharacteristicsSummary, RangeBslDocument} from '../shared/bsl-reference/offer-data-management-model';
import {Documentation} from '../../shared/documentation/documentation.model';
import {CubicleLayout} from '../shared/bsl-reference/cubicle-layout-model';
import {EditRangeInfoPayload} from './shared/odm-range-management-modal/odm-range-management-modal.component';
import {ReferenceRangePayload} from "../../shared/model/referenceRangePayload.model";

@Injectable()
export class OfferDataManagementService {

  constructor(private readonly httpClient: HttpClient,
              private readonly logger: LoggerService) {
  }

  /**
   * Get components list in range id
   *
   * @returns {Observable<SwitchBoardComponent[]>}
   */
  getComponentsReferences(rangeId: string): Observable<SwitchBoardComponent[]> {
    this.logger.debug('SwitchBoardComponents getComponents');
    return this.httpClient.get<SwitchBoardComponent[]>('/offer-data-management/componentByRangeId/' + rangeId);
  }

  getCommercialReferencesForRangeId(rangeId: number): Observable<BslReference[]> {
    return this.httpClient.get<BslReference[]>('/offer-data-management/range/' + rangeId + '/bsl/references');
  }

  getAllSLDByRangeId(bslRangeId: number): Observable<CubicleSld[]> {
    return this.httpClient.get<CubicleSld[]>('/offer-data-management/range/' + bslRangeId + '/sld');
  }

  getAllLayoutByRangeId(bslRangeId: number): Observable<CubicleLayout[]> {
    return this.httpClient.get<CubicleLayout[]>('/offer-data-management/range/' + bslRangeId + '/layout');
  }

  addNewComponentToRange(rangeId: string, reference: string, sld: string[], layout: string[]): Observable<SwitchBoardComponent> {
    return this.httpClient.post<SwitchBoardComponent>('/offer-data-management/range/' + rangeId + '/component/' + reference, { sld: sld, layout: layout });
  }



  deleteComponentToRange(reference: string): Observable<void> {
    return this.httpClient.delete<void>('/offer-data-management/component/' + reference);
  }


  updateComponentToRange(rangeId: string, component: SwitchBoardComponent): Observable<SwitchBoardComponent> {
    return this.httpClient.post<SwitchBoardComponent>('/offer-data-management/range/' + rangeId + '/component', component);
  }

  /**
   * Service to get all available range is bsl (minus the already present in express)
   * Cached because slow request
   * @returns {Observable<Range[]>} list of bsl range at express format
   */
  getAllBslAvailableRanges(): Observable<Range[]> {
    return this.httpClient.get<Range[]>('/offer-data-management/rangesByCountry');
  }

  /**
   * Service to get all available range is express (active/inactive, all status)
   * Not cached because change when a new offer/range is added
   * @returns {Observable<Range[]>} list of bsl range at express format
   */
  getAllExpressRanges(): Observable<Range[]> {
    return this.httpClient.get<Range[]>('/offer-data-management/rangesByOffer');
  }

  /**
   * Service to create a new range on express or update an existing range
   * @param range
   */
  updateRange(range: Range): Observable<Range> {
    return this.httpClient.put<Range>('/offer-data-management/range', range);
  }

  /**
   * Service to get all characteristics for a bslRange by id.
   * @param bslRangeId
   */
  getAllCharacteristicsForRange(bslRangeId: number): Observable<HttpEvent<BslRangeCharacteristicsSummary[]>> {
    return this.httpClient.get<BslRangeCharacteristicsSummary[]>('/offer-data-management/characteristicsByBslRangeId/' + bslRangeId,
      { reportProgress: true, observe: 'events' });
  }

  /**
   * Service to get range on express
   * @param rangeId
   */
  getRange(rangeId: String): Observable<Range> {
    return this.httpClient.get<Range>('/ranges/' + rangeId);
  }

  /**
   * Service to delete component by rangeId
   * @param rangeId
   */
  removeComponentsReferences(rangeId: string): Observable<any> {
    return this.httpClient.delete('/offer-data-management/removeComponent/' + rangeId);
  }


  /**
   * Retrieve documents by range
   * @param range range name
   * @returns {Observable<R>}
   */
  retrieveDocumentationByRange(range: string): Observable<Array<Documentation>> {
    return this.httpClient.get<Array<Documentation>>('/offer-data-management/documents/' + range);
  }

  /**
   * Service to get all documents for a bslRange by its id.
   * @param bslRangeId
   */
  getDocumentsForRange(bslRangeId: number): Observable<RangeBslDocument[]> {
    return this.httpClient.cache().get<RangeBslDocument[]>('/offer-data-management/documentsByBslRangeId/' + bslRangeId);
  }

  /**
   * Service to update all documents for a range
   * @param rangeName
   * @param documents
   */
  updateDocumentForRange(rangeName: string, documents: Documentation[]): Observable<void> {
    return this.httpClient.post<void>('/offer-data-management/documentation/' + rangeName, documents);
  }

  /**
   * Service to update range order
   * @param list of range
   */
  updateRangeOrder(rangeList: Range[]): Observable<void> {
    return this.httpClient.post<void>('/offer-data-management/rangeOrder', rangeList);
  }

  decommissionRange(rangeId: string): Observable<void> {
    return this.httpClient.post<void>('/offer-data-management/decommissionRange/' + rangeId, '');
  }

  exportRanges(rangeId: string): Observable<any> {
    return this.httpClient.post('/offer-data-management/ranges/export/'+ rangeId, '',{responseType: 'blob', observe: 'response'});
  }

  updateRangeInfo(rangeInfo: EditRangeInfoPayload): Observable<Range>{
    return this.httpClient.post<Range>('/ranges/editRangeInfo/', rangeInfo);
  }

  getAllReferenceRangePairs(): Observable<ReferenceRangePayload[]>{
    return this.httpClient.get<ReferenceRangePayload[]>('/offer-data-management/allReferenceRangePairs')
  }

}
