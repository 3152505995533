/* Angular modules */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
/* ngx modules */
import { TranslateService } from '@ngx-translate/core';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { from } from 'rxjs/observable/from';
/* app modules */
import { Dimension, GlobalInformations, Item, Project, Status, Url } from './project-model';
import { SimplifiedRange } from '../../shared/model/simplifiedRange-model';
import { NavigationStep } from '../../shared/guards/navigationStep-enum';
import { UtilService } from '../../shared/util/util.service';
import { Range } from '../../shared/model/range-model';
import { LoggerService } from '../../shared/logging/logger.service';
import { User } from '../../shared/user/user';
import { SwitchboardLimitType } from '../../configuration/shared/model/switchboardLimit-type';
import { RangeType } from '../../shared/model/range-type';
import { map } from 'rxjs/operators';
import { SwitchBoardComponent } from '../../configuration/shared/model/component';
import { ProjectService } from './project.service';
import {Subject} from 'rxjs/Rx';
import {MessageService} from 'primeng/api';
import {PackageOffer} from '../../admin/shared/package/package.model';

@Injectable()
export class ItemService {

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  currentProject: Project;

  constructor(private httpClient: HttpClient,
              private sessionStorageService: SessionStorageService,
              private logger: LoggerService,
              private projectService: ProjectService,
              private translateService: TranslateService,
              private messageService: MessageService) {
  }

  /**
   * Function to get
   * @param {Range} range
   * @returns {any}
   */
  private static getMaxStep(range: Range): NavigationStep | undefined {
    switch (range.rangeType) {
      case RangeType.TRANSFORMER:
        if (range.useTransformerSelector) {
          return NavigationStep.TRANSFORMER_SELECTOR;
        } else {
          return NavigationStep.TRANSFORMER;
        }
      case RangeType.SWITCHBOARD:
        return NavigationStep.CHARACTERISTICS;
    }
  }

  /**
   * Get items for a project
   *
   * @returns {Observable<Item[]>}
   */
  getItems(projectId: string): Observable<Item[]> {
    this.logger.debug('ItemService getItems()');

    const options = {params: new HttpParams().set('projectId', projectId)};

    return this.httpClient.get<Item[]>('/items',
      options);
  }

  /**
   * Get an item
   *
   * @param itemId item id
   * @returns {Observable<Item>}
   */
  getItem(itemId): Observable<Item> {
    this.logger.debug('ItemService getItem()');

    return this.httpClient.get<Item>('/items/' + itemId);
  }

  /**
   * Add an item of range's type to a project
   *
   * @param projectId project id
   * @param newItem range or package offer
   * @param user author of the add item
   * @returns {Observable<Item>}
   */
  addItem(projectId: string, newItem: Range | PackageOffer, user: User): Observable<Item> {
    this.logger.info('ItemService addItem()');

    if (!newItem) {
      throw new Error('addItem: range or packageOffer must be defined');
    }

    // to determine that newItem is instance of Range or PackageOffer
    const isRange = ('rangeType' in newItem) ? true : false;

    let itemName;
    return from(this.translateService.get(isRange ? (<Range>newItem).nameKey : 'T_PACKAGE_OFFER_NAME').toPromise()
      .then(lItemName => itemName = lItemName)
      .then(() => this.getItems(projectId).toPromise())
      .then(items => {
        const item = new Item();
        item.name = UtilService.getDefaultName(items, itemName);
        if (isRange) {
          item.range = new SimplifiedRange(<Range>newItem);
        } else {
          item.packageOfferId = (<PackageOffer>newItem).id;
        }
        item.currentNavigationStep = NavigationStep.OFFERS_CT;
        item.maxNavigationStep = isRange ? ItemService.getMaxStep(<Range>newItem) : NavigationStep.PACKAGE_OFFER;
        item.author = user;
        item.lastUpdator = user;
        item.creationDate = new Date().getTime();
        item.updateDate = new Date().getTime();
        this.logger.logStatus(item);
        return this.httpClient.post<Item>(`/projects/${projectId}/item`, item).toPromise();
      }));
  }

  /**
   * Update an item
   *
   * @param item item
   * @param user author of the update
   * @param updateItemInStorage to update the currentItemCt in session storage (default true)
   * @param keepSelectedItem keep the selected item
   * @returns {Observable<Item>}
   */
  updateItem(item: Item, user: User, updateItemInStorage: boolean = true, keepSelectedItem: boolean = false): Observable<Item> {
    this.logger.info('ItemService updateItem()');

    // Don't update item if user have not enough rights
    if (this.projectService.isReadOnlyProject(this.currentProject, user)) {
      return Observable.of(item);
    }

    const options = {params: new HttpParams().set('projectId', this.currentProject.id)};

    if (user !== null) {
      item.lastUpdator = user;
      item.updateDate = new Date().getTime();
    }

    return this.httpClient.put<Item>('/items', item, options).pipe(
      map((updatedItem: Item) => {
        if (keepSelectedItem) {
          updatedItem.selectedComponentIndex = this.currentItemct.selectedComponentIndex;
        }
        if (updateItemInStorage) {
          this.currentItemct = updatedItem;
        }
        return updatedItem;
      })
    );
  }

  /**
   * Updates the discount of the item
   * @param {string} projectId the id of the project
   * @param {string} itemId the id of the item
   * @param {number} discount the discount to apply to the item
   * @returns {Observable<Item>} the updated item
   */
  updateDiscountItem(projectId: string, itemId: string, discount: number): Observable<Item> {
    this.logger.info('ItemService updateDiscountItem()');
    return this.httpClient.put<Item>('/items/discount', {
      'projectId': projectId,
      'itemId': itemId,
      'discount': discount
    });
  }

  updateItemName(item: Item, user: User, updateItemInStorage: boolean = true): Observable<Item> {
    return Observable.create(observer => {
      this.updateItem(item, user, updateItemInStorage)
        .subscribe(p => {
            observer.next(p);
          },
          error => {
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('T_ERROR'),
              detail: this.translateService.instant('T_RENAME_ITEM_ERROR_MESSAGE'),
            });
            observer.error(new Error(error));
          },
          () => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('T_INFO'),
              detail: this.translateService.instant('T_RENAME_ITEM_SUCCESS_MESSAGE'),
            });
            observer.complete();
          });
    });
  }

  updateItemWithStatusChange(item: Item, status: Status, user: User, keepSelectedItem: boolean = false): Observable<Item> {
    this.logger.info('ItemService updateItemWithStatusChange()');

    item.status = status;
    this.logger.logStatus(item);

    return this.updateItem(item, user, true, keepSelectedItem);
  }

  addSwitchboardTransformerItem(item: Item, cubicleId: number, functionalUnitId: number, transformer: SwitchBoardComponent,
                                status: Status, user: User) {
    this.logger.info('ItemService updateItemWithStatusChange()');

    const options = {params: new HttpParams().set('projectId', this.currentProject.id).set('quantityId', transformer.quantityId)};

    item.status = status;
    this.logger.logStatus(item);

    if (user != null) {
      item.lastUpdator = user;
      item.updateDate = new Date().getTime();
    }

    return this.httpClient.post<Item>('/items/addTransformer/' + item.id + '/' + cubicleId + '/' + functionalUnitId, item, options);
  }

  deleteItemFromProject(projectId, itemId): Observable<any> {
    this.logger.info('ItemService deleteItemFromProject()');

    return this.httpClient.delete('/projects/' + projectId + '/item/' + itemId + '/delete',
      {responseType: 'text'});
  }

  /**
   * Set item's navigation step
   *
   * @param navigationStep navigation step
   * @param doNotUpdate to not update the item to the backend
   * @param user current user
   * @param unsubscribe$
   */
  setItemNavigationStep(navigationStep: NavigationStep, user: User, unsubscribe$: Subject<void>, doNotUpdate?: boolean) {
    if (this.currentItemct) {
      this.currentItemct.currentNavigationStep = navigationStep;
      this.currentItemct.maxNavigationStep = Math.max(this.currentItemct.maxNavigationStep, navigationStep);
      if (!doNotUpdate) {
        this.updateItem(this.currentItemct, user)
          .takeUntil(unsubscribe$)
          .subscribe(
            item => this.currentItemct = item,
            error => this.manageUpdateError(error)
          );
      }
    }
  }

  /**
   * Set item's max navigation step
   *
   * @param maxNavigationStep new max navigation step
   * @param user current user
   * @param goBackward allow to overwrite a max step with a previous step
   * @param forceUpdate: to force the update of the itemct
   */
  setItemMaxNavigationStep(maxNavigationStep: NavigationStep, user: User, goBackward: boolean, unsubscribe$: Subject<void>, forceUpdate?: boolean) {
    if (this.currentItemct) {
      const newStep = goBackward ? maxNavigationStep : Math.max(this.currentItemct.maxNavigationStep, maxNavigationStep);
      if (newStep !== this.currentItemct.maxNavigationStep || (forceUpdate)) {
        this.currentItemct.maxNavigationStep = newStep;
        this.updateItem(this.currentItemct, user)
          .takeUntil(unsubscribe$)
          .subscribe(
            item => this.currentItemct = item,
            error => this.manageUpdateError(error)
          );
      }
    }
  }

  /**
   * Get dimensions and total price of an item
   *
   * @param projectId ID of the project
   * @param itemId ID of the item
   * @returns {Observable<Dimension>}
   */
  getGlobalInformations(projectId: string, itemId: string): Observable<GlobalInformations> {
    this.logger.info('ItemService getGlobalInformations()');
    return this.httpClient.get<GlobalInformations>('/items/' + projectId + '/' + itemId + '/globalInformations');
  }

  /**
   * Observable method to update the currentItemCT in session
   * with information provided by the globalInformations service
   */
  updateTotalPriceAndDimensionsObserv(): Observable<Item> {
    return this.getGlobalInformations(this.currentProject.id, this.currentItemct.id)
      .mergeMap(globalInformations => {
        this.currentItemct.bom.totalPrice = globalInformations.totalPrice;
        this.currentItemct.bom.packagingPrice = globalInformations.packingPrice;
        this.currentItemct.mySeNetPriceStatus = globalInformations.mySeNetPriceStatus;
        this.currentItemct.width = globalInformations.width;
        this.currentItemct.weight = globalInformations.weight;
        this.currentItemct.height = globalInformations.height;
        this.currentItemct.depth = globalInformations.depth;
        return Observable.of(this.currentItemct);
      });
  }

  /**
   * Method to update the currentItemCT in session
   * with information provided by the globalInformations service
   */
  updateTotalPriceAndDimensions(unsubscribe$: Subject<void>) {
    this.updateTotalPriceAndDimensionsObserv()
      .takeUntil(unsubscribe$)
      .subscribe();
  }

  getRangePicture(rangeId): Observable<Url> {
    return this.httpClient.cache().get<Url>('/bsl/' + rangeId + '/picture');
  }

  /**
   * order With My SE
   *
   * @param projectId ID of the project
   * @param itemId ID of the item
   * @returns {Observable<Item>}
   */
  orderWithMySE(projectId: string, itemId: string): Observable<Item> {
    this.logger.info('ItemService orderWithMySE()');
    return this.httpClient.get<Item>('/items/' + projectId + '/' + itemId + '/orderMySE');
  }

  /**
   * Order Project With My SE
   * @param projectId
   */
  orderProjectWithMySE(projectId): Observable<Project> {
    this.logger.info('ItemService orderProjectWithMySE()');
    return this.httpClient.get<Project>('/items/' + projectId + '/orderProjectMySE');
  }

  /**
   * Function to manage length control on the item name field
   * Triggered on key press, ignore ENTER, DELETE and text navigation
   * @param $event
   */
  controlMaxLengthOnItem($event) {
    if ($event.key !== 'Enter' && $event.key !== 'Backspace' && $event.key !== 'ArrowLeft' && $event.key !== 'ArrowRight'
      && $event.target.textContent.replace(/(\r\n|\n|\r)/gm, '').trim().length === 20) {
      $event.preventDefault();
    }
  }

  /**
   * Fonction to manage update of name from in place editing both in header and item carousel
   * @param project project containing item to rename
   * @param item the item to rename
   * @param inputItemName the editable field
   * @param user current user
   */
  updateSwitchboardName(project, item, inputItemName, user) {

    const oldItemName = item.name;
    const newItemName = inputItemName.target.textContent.replace(/(\r\n|\n|\r)/gm, '');
    inputItemName.target.innerText = newItemName;

    if (oldItemName.trim() !== newItemName.trim()) {
      this.currentProject = project;
      this.getItem(item.id).subscribe(itemFull => {
        itemFull.name = newItemName.trim();
        this.updateItemName(itemFull, user, false)
          .subscribe(
            () => {
              item.name = newItemName;
            }, // Do nothing
            () =>  {
              inputItemName.target.innerText = oldItemName;
              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('T_ERROR'),
                detail: this.translateService.instant('T_ITEMS_RENAMING_ERROR')
              });
            }
          );
      });
    }
  }

  /**
   * Function to manage focus on the item name field
   * Triggered on key press, remove focus on ENTER
   * @param $event
   */
  removeFocusOnItem($event) {
    if ($event.key === 'Enter') {
      $event.target.blur();
    }
  }

  /**
   * determine if adding a component is allowed,
   * The parameter is the number of the functional unit is maybe better for performance than the component himself
   * @param {number} numberOfFunctionalUnit the number of the functional unit of the component to add
   */
  public canAddComponentToItem(numberOfFunctionalUnit: number): boolean {
    if (!this.currentItemct.range) {
      return false;
    }
    const switchboardLimitUnit = this.currentItemct.range.switchboardLimit.unit;
    const switchboardLimitMax = this.currentItemct.range.switchboardLimit.max;
    if (switchboardLimitUnit === SwitchboardLimitType.CUBICLE_LIMIT_TYPE.toString()) {
      return this.currentItemct.components.length < switchboardLimitMax;
    }
    if (switchboardLimitUnit === SwitchboardLimitType.FUNCTIONAL_UNIT_LIMIT_TYPE.toString()) {
      return numberOfFunctionalUnit + this.getTotalFunctionCount() <= switchboardLimitMax;
    }
  }


  /**
   * give the total number of functional unit of all the components
   * @returns {number} total number of fu
   */
  public getTotalFunctionCount(): number {
    return this.currentItemct.components.reduce(function (a, b) {
      return a + b.functionalUnits.length;
    }, 0);
  }

  /**
   * Creates a list of items having same given status
   * @param {Item[]} items list of items
   * @param {Status[]} statusList list of status
   * @returns {Item[]} items with some of status on status list
   */
  getItemsListByStatus(items: Item[], statusList: Status[]): Item[] {
    return items.filter(item => statusList.includes(item.status));
  }

  /**
   * Display an error message to the user and print a log of the error
   * @param error the update error
   */
  private manageUpdateError(error) {
    this.logger.error(error);
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_ERROR'),
      detail: this.translateService.instant('T_ITEM_UPDATE_ERROR'),
    });
  }
}
