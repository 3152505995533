/**
 * Class to contain object to manage file events
 */
export class FileWrapper {
  event: any;
  fileId: string;

  constructor (event: any, fileId: string) {
    this.event = event;
    this.fileId = fileId;
  }
}
