export class AddingTransformerInfosWrapper {
  cubicleNumber: number;
  functionalUnitNumber: number;
  compatibleTransformerList: string[] = [];
  constructor(cubicleNumber: number, functionalUnitNumber: number, compatibleTransformerList: string[]) {
    this.cubicleNumber = cubicleNumber;
    this.functionalUnitNumber = functionalUnitNumber;
    this.compatibleTransformerList = compatibleTransformerList;
  }
}
