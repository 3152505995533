<div class="accessory-management-container">

  <h3><span class="back-link" (click)="returnOnOfferSelection()" translate>T_OFFER_DATA_MANAGEMENT_BACK_BUTTON</span></h3>

  <div>
    <div class="titles">
      <p class="range-key" translate><span translate>T_OFFER_DATA_MANAGEMENT_LIST_OF_ACCESSORIES_OF_RANGE </span>{{selectedRange.nameKey}}</p>
    </div>
  </div>

  <div *ngIf="!isLoading">
    <button class="btn btn-smart" (click)="onClickAddCategoryButton()"  translate>
      <span class="se-icons">action_add_stroke</span>T_ADD_ACCESSOR_CATEGORY
    </button>
    <button  class="btn btn-smart"  (click)="onClickAddAccessoryButton()" translate>
      <span class="se-icons">action_add_stroke</span>T_ACCESSORY_ADD_BUTTON
    </button>
    <button  class="btn btn-smart"  (click)="onClickAddAccessoryValueButton()" translate>
      <span class="se-icons">action_add_stroke</span>T_ACCESSORY_VALUE_ADD_BUTTON
    </button>

    <div class="category" *ngFor="let category of accessoryCategories">
      <app-odm-manage-category
        [category]="category"
        [accessories]="accessories"
        [isExpanded]="category.id === activeCategory"
        [activeAccessory]="activeAccessory"
        (onDeleteCategory)="deleteCategory($event)"
        (clickEditCategory)= "onClickEditCategory($event)"
        (clickEditAccessoryButton)="onClickEditAccessoryButton($event)"
        (clickDeleteAccessoryButton)="onClickDeleteAccessoryButton($event)"
        (clickEditAccessoryValueButton)="onClickEditAccessoryValueButton($event)"
        (clickDeleteAccessoryValueButton)="onClickDeleteAccessoryValueButton($event)"
        (changeAccessoryOrderEvent)="onChangeAccessoryOrder($event)"
        (clickMoveDownAccessoryValueEvent)="onClickMoveDownAccessoryValue($event)"
        (clickMoveUpAccessoryValueEvent)="onClickMoveUpAccessoryValue($event)"
      >
      </app-odm-manage-category>
    </div>
  </div>

  <div *ngIf="isLoading" class="odm-left-column">
    <app-spinner [sizeClass]="'large'"></app-spinner>
  </div>
</div>


<app-odm-modal-category-name #addCategoryModal
                                               [isAddedCategory] = "true"
                                               (onConfirmButton)="addCategory($event)" >
</app-odm-modal-category-name>

<app-odm-edit-accessory-modal #addAccessoryModal
                                                (saveAccessory)="addAccessory($event)">
</app-odm-edit-accessory-modal>

<app-odm-edit-accessory-modal #editAccessoryModal
                                                (saveAccessory)="editAccessory($event)">
</app-odm-edit-accessory-modal>

<app-common-modal #deleteAccessoryModal [name]="'deleteAccessoryModal'" (onClickYesButton)="deleteAccessory()"
                  [description]="'T_DELETE_ACCESSORY_MODAL_MESSAGE' | translate" >
</app-common-modal>

<app-odm-edit-accessory-value-modal #addAccessoryValueModal
                                                    (saveAccessoryValue)="addAccessoryValue($event)">
</app-odm-edit-accessory-value-modal>

<app-odm-edit-accessory-value-modal #editAccessoryValueModal
                                                      (saveAccessoryValue)="editAccessoryValue($event)">
</app-odm-edit-accessory-value-modal>

<app-common-modal #deleteAccessoryValueModal [name]="'deleteAccessoryValueModal'" (onClickYesButton)="deleteAccessoryValue()"
                  [description]="'T_DELETE_ACCESSORY_VALUE_MODAL_MESSAGE' | translate" >
</app-common-modal>
