import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Post} from '../model/post';
import {User} from '../user/user';

@Injectable({
  providedIn: 'root'
})
export class ComToolsService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Get count of posts corresponding to category, about the user's country.
   * @param category category of wanted posts
   */
  getCountUnreadPost(category: string): Observable<number> {
    return this.httpClient.get<number>('/com-tools/' + category + '/count/unread');
  }

  /**
   * Get all the posts corresponding to category, about the user's country.
   * @param category category of wanted posts
   */
  getPosts(category: string): Observable<Post[]> {
    return this.httpClient.get<Post[]>('/com-tools/' + category + '/posts');
  }

  /**
   * Update the check date of the category of post for the user
   * @param category the category of post read
   */
  updateReadingDate(category: string): Observable<User> {
    return this.httpClient.post<User>('/com-tools/' + category + '/refreshCheck', {});
  }

  /**
   * Get url for Communication tools management
   */
  getManagementComToolsUrl(): Observable<string> {
    return this.httpClient.get('/com-tools/managementUrl', {responseType: 'text'});
  }
}
