import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {PackageOffer, PackageOfferSelect} from './package.model';
import {Pair} from "../../../shared/util/pair";

@Injectable()
export class PackageOfferService {

  constructor(private readonly httpClient: HttpClient) {
  }

  getAll(): Observable<PackageOffer[]> {
    return this.httpClient.get<PackageOffer[]>('/package-offer');
  }

  getOfferSelectById(packageOfferId): Observable<PackageOfferSelect> {
    return this.httpClient.get<PackageOfferSelect>('/package-offer/offerSelect/' + packageOfferId);
  }

  create(): Observable<PackageOffer> {
    return this.httpClient.post<PackageOffer>('/package-offer', {});
  }

  addOffer(translationByLanguage:  Pair<string, string>[], groupId: string): Observable<PackageOffer> {
    const payload = [];
    translationByLanguage.forEach(t => payload.push({first: t.getKey(), second:t.getValue()}));
    return this.httpClient.post<PackageOffer>('/package-offer/' + groupId, payload);
  }

  update(packageOffer: PackageOffer): Observable<PackageOffer> {
    return this.httpClient.put<PackageOffer>('/package-offer', packageOffer);
  }

  delete(offerId: string): Observable<any>{
    return this.httpClient.delete<any>('/package-offer/'+offerId);
  }

  deleteOfferContent(offerId: string): Observable<any>{
    return this.httpClient.delete<any>('/package-offer/delete-content/'+offerId);
  }

  updateOrder(idOrderPairs:  {first: string, second:number}[]){
    return this.httpClient.put<any>('/package-offer/updateOrder/', idOrderPairs);
  }

}
