<app-common-modal #aboutModal [name]="'about'" [title]=" " [twoButtonsDialogs]="false" [withFooter]="false">
  <span modal-title class="about-modal-title"><span class="power-build-bold">Power</span> <span class="power-build-light">Build - Contractors</span></span>
  <div modal-body >
    <div class="row">
      <div class="col-md-6">
        <div><strong>Front build ver.</strong> {{frontVersion}}</div>
        <div><strong>Front build env.</strong> {{envType}}</div>
        <div><strong>Back build ver.</strong> {{backVersion}}</div>
        <div><strong>Datamodel ver.</strong> {{dbVersion}}</div>
        <div *ngIf="comToolsVersion && isCommToolAdminUser()"><strong>ComTools ver.</strong> {{comToolsVersion}}</div>
      </div>
      <div class="col-md-6" align="right"><img class="app-header-logo" src="./assets/images/se-header-logo.png" alt="Life is On"/></div>
    </div>
    <div class="row" *ngIf="isExtendedDemo()">
      <div class="col-md-12">
        <br>
        <div>
          <span class="title" [innerHtml]="'T_APPLICATION_TERMS_OF_USE_EXTENDED_DEMO_TITLE' | translate"></span>
        </div>
        <div>
          <span class="message" [innerHtml]="'T_APPLICATION_TERMS_OF_USE_EXTENDED_DEMO_MESSAGE' | translate"></span>
          <span class="message"> {{getContactEmail()}}</span>
        </div>
        <br>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <span class="small" translate [translateParams]="{year: year}">T_APPLICATION_COPYRIGHT</span>
        <span class="data-privacy-policy-link"> <a class="btn btn-primary share-button" href="{{ getDataPrivacyPolicyUrl() }}" target="_blank" translate>T_DATA_PRIVACY_POLICY</a></span>
        <span class="align-right"> <a class="btn btn-primary share-button" href="{{ getUrl() }}" target="_blank" translate>T_TERMS_OF_USE</a></span>
      </div>
    </div>

  </div>
</app-common-modal>
