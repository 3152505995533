/* angular modules */
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
/* ngx modules */
import {TranslateLoader} from '@ngx-translate/core';
import {SessionStorage} from 'ngx-webstorage';
/* application import */
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {

  @SessionStorage()
  user;

  constructor(private httpClient: HttpClient) {
  }

  getTranslation(lang: string, forceResetCache: boolean = false): Observable<any> {
    const options = {params:
        new HttpParams()
        .set('lang', lang + '_' + this.user.partnerCountry)
        .set('withMissingTranslations', environment.showUnknowTranslations.toString())};

    return this.httpClient.cache(forceResetCache).get('/application/translations', options);
  }
}
