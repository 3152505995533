<div class="carousel" id="{{project.id}}" data-interval="false">

  <div class="carousel-inner row">
    <!-- LEFT ARROW -->
    <div class="col-md-2">
      <a class="left" (click)="goToPreviousItem()">
        <span [ngClass]="{'se-icons arrow': true, 'passif':
        (project.itemCT.length < 3 && !readOnly) || (project.itemCT.length < 4 && readOnly)}">arrow2_left</span>
      </a>
    </div>


    <div *ngFor="let itemCt of project.itemCT; let i = index"
         [ngClass]="{'col-md-3 hover item':true, 'actif': i < 3, 'passif': i >= 3}">

      <!-- DELETE ITEM BUTTON-->
      <div class="text-right">
        <button *ngIf="isNotOrdered(itemCt) && !readOnly" type="button" class="modal-close pull-right deleteItem"
                aria-label="Close" (click)="onClickOnDelete(itemCt)">
          <span tooltip="{{'T_TOOLBAR_DELETE_ITEM_TOOLTIP' | translate}}" placement="bottom" container="body" class="fa-stack">
            <span class="se-icons icon se-close-cross-style">test_results_nok</span>
          </span>
        </button>
      </div>

      <div>
          <!-- ITEM PICTURE -->
          <div class="offer-image">
            <div *ngIf="!!getPictureUrl(itemCt); then itemImgTemplate else itemButtonTemplate">
            </div>
            <ng-template #itemImgTemplate>
              <img class="itemImage" (click)="onClickOnItem(itemCt)"
                   [src]="getPictureUrl(itemCt)">
            </ng-template>
            <ng-template #itemButtonTemplate>
              <button class="se-icons noImageFound itemImage"
                      (click)="onClickOnItem(itemCt)">action_picture
              </button>
            </ng-template>

          </div>
          <div class="figCapt">
            <!-- ITEM NAME EDITABLE-->
            <span #itemName *ngIf="!readOnly"
                  class="bold editableContent"
                  [ngClass]="{'isOnError': !isValidName}"
                  contenteditable="true"
                  (blur)="updateItemName(project, itemCt, $event, user)"
                  (keyup)="itemNameCheck($event)">{{itemCt.name}}</span>
            <span #itemName *ngIf="readOnly" class="bold">{{itemCt.name}}</span>
            <br/>
            <!-- ITEM PRICE NOT EDITABLE -->
            <span *ngIf="!isDemoMode && !isCpqProject() && !user.currentMySEAccount || (user.currentMySEAccount && utilService.isValidMySEPrice(itemCt))">
              <strong><span translate>{{getPriceLabel(itemCt)}}</span>: </strong>{{getPriceItem(itemCt)}} {{localization?.displayedCurrency}}
            </span>
            <span *ngIf="!isDemoMode && !isCpqProject() && user.currentMySEAccount && !utilService.isValidMySEPrice(itemCt )" class="fa fa-exclamation-circle brand-warning"
                  tooltip="{{(utilService.getTooltipOnTotalMySEMessage(itemCt.mySeNetPriceStatus))}}" placement="left"
                  container="body"></span>
            <br/>
            <!-- ITEM STATUS -->
            <div *ngIf="!isCpqProject()" [ngClass]="[getStatusColorClass(itemCt), 'surrounded']">{{getStatus(itemCt)}}</div>
          </div>
        </div>
    </div>

    <!-- ADD CONFIGURATION BUTTON -->
    <div *ngIf="!readOnly" [ngClass]="{'col-md-3 hover item':true, 'actif': project.itemCT.length < 3, 'passif': project.itemCT.length >= 3}">
      <button type="button" class="add-component-button btn" (click)="onClickOnAdd()">
        <p class="plus-button"><span class="se-icons plus-icon">electricity_polarity_positive_plus</span></p>
        <p translate>T_ADD_CONFIGURATION</p>
      </button>
    </div>

    <!-- RIGHT ARROW -->
    <div class="col-md-1">
      <a class="right" (click)="goToNextItem()">
        <span [ngClass]="{'se-icons arrow': true, 'passif':
        (project.itemCT.length < 3 && !readOnly) || (project.itemCT.length < 4 && readOnly)}">arrow2_right</span>
      </a>
    </div>
  </div>

</div>
