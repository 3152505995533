import {Component, OnInit, ViewChild} from '@angular/core';
import {SessionStorage} from "ngx-webstorage";
import {User} from "../../shared/user/user";
import {Item} from "../../project/shared/project-model";
import {NavigationStep} from "../../shared/guards/navigationStep-enum";
import {NavigationBarItem} from "../../layout/navigation-bar-v2/navigation-bar-v2.component";
import {TermsOfUseModalComponent} from "../../shared/terms-of-use-modal/terms-of-use-modal.component";
import {ApplicationService} from "../../shared/app/application.service";
import {LoggerService} from "../../shared/logging/logger.service";
import {UserService} from "../../shared/user/user.service";
import {NavigationLinkEnum} from "../../shared/guards/navigationLink-enum";
import {MessageService} from "primeng/api";
import constant from "../../../../constant.json";

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  providers: [MessageService]
})
export class ContainerComponent implements OnInit {
  @SessionStorage()
  user: User;

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  noItemNavigationStep: NavigationStep;

  @SessionStorage()
  isOnAdministrationScreen: boolean;

  isLoading = true;

  commercialVersion: string;

  adminNavItems: NavigationBarItem[] = [
    {title: 'T_NAV_ADMIN_OFFER_DATA_MANAGEMENT', url: 'admin/data-management', active: true},
    {title: 'T_NAV_ADMIN_PRICE_MANAGEMENT', url: 'admin/price-management'},
    {title: 'T_NAV_ADMIN_TRANSLATION', url: 'admin/translation'},
    {title: 'T_NAV_ADMIN_CONTACT_MANAGEMENT', url: 'admin/contact-management'},
    {title: 'T_NAV_ADMIN_USER_MANAGEMENT', url:'admin/user-management'},
    {title: 'T_NAV_ADMIN_DISTRIBUTOR', url: 'admin/distributor'},
    {title: 'T_NAV_ADMIN_TRIAL_CONFIGURATION', url: 'admin/trial-configuration'},
  ];

  @SessionStorage()
  loadingRate: number;


  @ViewChild('childModal') childModal: TermsOfUseModalComponent;

  constructor(private applicationService: ApplicationService,
              private logger: LoggerService,
              private userService: UserService) {
  }

  ngOnInit() {
    this.loadingRate = 5;
    this.isLoading = true;
    this.commercialVersion = constant.version;

    this.applicationService.init(() => {
      this.loadingRate = 99;
      this.isLoading = false;
      // this.router.navigate([NavigationLinkEnum.PROJECTS])
      if (!this.user.termsOfUseAccepted) {
        this.childModal.show();
      }
    }).subscribe();
    this.loadingRate += 5;
  }

  public acceptTermsOfUse() {

    this.logger.info('Accept terms of use' + this.user);
    this.user.termsOfUseAccepted = true;
    this.userService.setTermsOfUseAccepted(true).subscribe(
      user => this.user = user
    );
  }

  public refuseTermsOfUse() {
    window.location.href = NavigationLinkEnum.PARTNER_SCHNEIDER;
  }


}
