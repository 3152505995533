/* Angular modules */
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
/* ngx modules */
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {SessionStorage} from 'ngx-webstorage';
/* app modules */
import {Item, Project, Status} from '../../project/shared/project-model';
import {LoggerService} from '../../shared/logging/logger.service';
import {NavigationStep} from '../../shared/guards/navigationStep-enum';
import {ItemService} from '../../project/shared/item.service';
import {UtilService} from 'app/shared/util/util.service';
import {Router} from '@angular/router';
import {ComponentService} from '../../configuration/shared/services/component.service';
import {ProductsFiltersService} from '../../shared/products-filters/products-filters.service';
import {SwitchBoardComponent} from 'app/configuration/shared/model/component';
import {VisibilityCheck} from 'app/configuration/shared/model/visibilityCheck';
import {ComponentType} from '../../configuration/shared/model/component-type';
import {FilterCategory} from '../../configuration/shared/model/filterCategory';
import {Opt} from 'app/configuration/shared/model/IProduct';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {ProjectService} from '../../project/shared/project.service';
import {DatasheetsService} from '../../shared/datasheets/datasheets.service';
import {NavigationRoute} from '../../shared/guards/route-enum';
import {Subject} from 'rxjs/Rx';
import * as _ from 'lodash';
import {Documentation} from "../../shared/documentation/documentation.model";

export enum ChangeOperation {
  ChangeTransformerCaracteristics = 1,
  ChangeOption = 2
}

@Component({
  selector: 'app-transformer-selector',
  templateUrl: './transformer-selector.component.html',
  styleUrls: ['./transformer-selector.component.less'],
})

export class TransformerSelectorComponent implements OnInit, OnDestroy {
  @SessionStorage()
  currentProject: Project;
  @SessionStorage()
  currentItemct: Item;
  @SessionStorage()
  user;
  @ViewChild('LooseModal')
  LooseModal: CommonModalComponent;

  loading = false;
  isNeededToBeReinitialised = false;
  // Item CT in the DB for the reinitialisation
  databaseItemct: Item;
  allComponents: Array<SwitchBoardComponent>;
  filteredComponents: Array<SwitchBoardComponent>;
  filters: Array<FilterCategory> = [];
  selectedComponent: SwitchBoardComponent;
  settingsEnded = false;
  transformerSelectorStepNumber = NavigationStep.TRANSFORMER_SELECTOR;
  savedValue: any;
  savedKey: string;
  savedOption: Opt;
  modificationOperation: ChangeOperation;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private componentService: ComponentService,
              private productsFiltersService: ProductsFiltersService,
              private logger: LoggerService,
              private router: Router,
              private itemService: ItemService,
              private datasheetsService: DatasheetsService,
              private projectService: ProjectService,
              private translateService: TranslateService,
              private messageService: MessageService) {
  }

  ngOnInit() {
    this.logger.debug('CharacteristicsComponent init()');
    this.loading = true;
    this.itemService.setItemNavigationStep(NavigationStep.TRANSFORMER_SELECTOR, this.user, this.unsubscribe$);

    if (this.currentItemct && this.currentItemct.status === Status.ordered && !this.projectService.isReadOnlyProject(this.currentProject, this.user)) {
      if (this.projectService.isCpqProject(this.currentProject)) {
        // Information Toast
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('T_ORDER_WARNING_TITLE'),
          detail: this.translateService.instant('T_PUSH_TO_CPQ_WARNING')
        });
      } else {
        // Information Toast Ordered
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('T_ORDER_WARNING_TITLE'),
          detail: this.translateService.instant('T_ORDER_WARNING')
        });
      }
    } else {
      if (this.currentItemct && this.currentItemct.status === Status.quoted && !this.projectService.isReadOnlyProject(this.currentProject, this.user)) {
        this.isNeededToBeReinitialised = true;
        this.databaseItemct = UtilService.clone(this.currentItemct);
        // Information Toast Loose data
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('T_LOOSE_DATA_WARNING'),
          detail: this.translateService.instant('T_LOOSE_DATA_WARNING_TITLE')
        });
      }
    }

    this.componentService.getComponents(this.currentItemct.id)
      .takeUntil(this.unsubscribe$)
      .subscribe(components => {
        this.allComponents = components.filter(component => component.type === ComponentType.TRANSFORMER);
        this.filters = this.productsFiltersService.extractFilterSelector(this.allComponents,
          this.currentItemct.range.visibleComponentsFilters, new Map<string, VisibilityCheck>());
        this.filters.forEach(filter => {
          if (filter.values.length === 1) {
            filter.values[0].checked = true;
          }
        });
        if (this.currentItemct.components.length > 0) {
          this.productsFiltersService.initFilters(this.filters, this.currentItemct.components);
          this.settingsEnded = true;
          this.selectedComponent = this.currentItemct.components[0];
        }
        this.filteredComponents = <SwitchBoardComponent[]>this.productsFiltersService.applyFilters(this.filters, this.allComponents);
        this.loading = false;
      });

    this.itemService.updateItem(this.currentItemct, this.user)
      .takeUntil(this.unsubscribe$)
      .subscribe(item => this.currentItemct = item);
  }

  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    // unsubscribe from the subject itself:
    this.unsubscribe$.unsubscribe();
  }

  changeTab(): void {
    this.router.navigate([NavigationRoute.getNextStepByRange(this.currentItemct.range, this.currentItemct.currentNavigationStep).link]);
  }

  /**
   * Just for the onChange from select element
   * Call the modal if need to be reinitialised
   * @param value
   * @param optionSelect
   */
  onChangeCharacteristics(key: string, value: string) {
    if (this.isNeededToBeReinitialised) {
      this.savedValue = value;
      this.savedKey = key;
      this.modificationOperation = ChangeOperation.ChangeTransformerCaracteristics;
      this.LooseModal.show();
    } else {
      this.changeCharacteristics(key, value);
    }
  }

  /**
   * Just for the onChange from select element
   * Call the modal if need to be reinitialised
   * @param value
   * @param optionSelect
   */
  onChangeOption(value, optionSelect: Opt) {
    this.ChangeOption(value, optionSelect);
  }


  confirmLooseData() {
    this.currentItemct.maxNavigationStep = NavigationRoute.getNextStepByRange(this.currentItemct.range, this.currentItemct.currentNavigationStep).id;
    this.currentItemct.status = Status.configured;
    this.logger.logStatus(this.currentItemct);
    this.isNeededToBeReinitialised = false;
    switch (this.modificationOperation) {
      case (ChangeOperation.ChangeOption):
        this.ChangeOption(this.savedValue, this.savedOption);
        break;
      case (ChangeOperation.ChangeTransformerCaracteristics):
        this.changeCharacteristics(this.savedKey, this.savedValue);
        break;

    }
  }

  /**
   * Method called on the loosing data modal if user click on "NO"
   * Use to reinitiate form data
   */

  reinitData() {
    this.selectedComponent = UtilService.clone(this.databaseItemct.components[0]);
  }

  isReadOnlyProject(): boolean {
    return this.projectService.isReadOnlyProject(this.currentProject, this.user) || this.currentItemct.status === Status.ordered;
  }

  /**
   * Update status of filters as checked or disabled
   * @param {boolean} checked
   * @param {string} key
   * @param {string} value
   */
  private changeCharacteristics(key: string, value: string) {
    this.filters.forEach(filterCategory => {
        if (filterCategory.key === key) {
          filterCategory.values.forEach(filterValue => {
            filterValue.checked = filterValue.value.toString() === value;
          });
        }
      }
    );
    this.filteredComponents = <SwitchBoardComponent[]>this.productsFiltersService.applyFilters(this.filters, this.allComponents);
    this.productsFiltersService.updateFilters(this.filters, this.filteredComponents);
    this.settingsEnded = this.filteredComponents.length === 1;
    this.updateTransformer();
  }

  private ChangeOption(value, optionSelect: Opt) {
    this.selectedComponent.options.forEach(option => {
      if (option.name === optionSelect.name) {
        option.values.forEach(optionValue => {
          optionValue.selected = optionValue.value.toString() === value;
        });
      }
    });
    this.updateItem();
  }

  /**
   * used to add a transformer (an item as transformer)
   */
  /**
   * used to add a transformer (an item as transformer)
   */
  private updateTransformer() {
    if (this.settingsEnded) {
      this.selectedComponent = this.filteredComponents[0];

      if (this.currentItemct.components[0] !== this.selectedComponent) {
        this.loading = true;
        const components: [SwitchBoardComponent] = [this.selectedComponent];
        this.datasheetsService
          .getComponentsDatasheetUrl(components, this.user.preferredLanguage)
          .subscribe(payloadDocumentList => {
            this.addTransformerWithDocuments(payloadDocumentList, components);
          });
      }
    } else if (this.currentItemct.components.length > 0) {
      this.currentItemct.components.splice(0, this.currentItemct.components.length);
      this.removeTransformer();
    }
    this.selectedComponent = null;
  }

  private addTransformerWithDocuments(payloadDocumentList: Array<{ [k: string]: Documentation }>, components: [SwitchBoardComponent]) {
    if (payloadDocumentList.length === 0) {
      this.currentItemct.components = [];
      this.currentItemct.components.push(components[0]);
      this.addTransformer();
      return;
    }

    payloadDocumentList.forEach((payload: { [p: string]: Documentation }) => {
      const payloadComponentId = _.keys(payload)[0];
      const findComponent = components.find(c => c.id === payloadComponentId);
      if (findComponent !== undefined) {
        findComponent.documents = _.uniqBy(_.concat(findComponent.documents, payload[payloadComponentId]), 'id');
      }
      this.selectedComponent = findComponent;
    });

    this.currentItemct.components[0] = this.selectedComponent;
    this.addTransformer();
  }

  private addTransformer() {
    this.currentItemct.maxNavigationStep = NavigationRoute.getNextStepByRange(this.currentItemct.range, this.currentItemct.currentNavigationStep).id;
    this.itemService.updateItemWithStatusChange(this.currentItemct, Status.configured, this.user)
      .takeUntil(this.unsubscribe$)
      .subscribe(item => {
          this.currentItemct = item;
          this.selectedComponent = item.components[0];
          this.itemService.updateTotalPriceAndDimensions(this.unsubscribe$);
        },
        () => this.loading = false,
        () => {
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('T_INFORMATION'),
            detail: this.translateService.instant(this.currentItemct.components[0].name) + ' ' + this.translateService.instant('T_SUCCESS_ITEM_ADDED'),
          });
          this.loading = false;
        });

  }


  private removeTransformer() {
    this.itemService.updateItemWithStatusChange(this.currentItemct, Status.configured, this.user)
      .takeUntil(this.unsubscribe$)
      .subscribe(item => {
          this.currentItemct = item;
          this.itemService.updateTotalPriceAndDimensions(this.unsubscribe$);
          this.currentItemct.maxNavigationStep = NavigationStep.TRANSFORMER_SELECTOR;
        },
        error => null,
        () => this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFORMATION'),
          detail: this.translateService.instant('T_SUCCESS_REMOVE_ITEM')
        }));
  }

  private updateItem() {
    this.currentItemct.components[0] = this.selectedComponent;
    this.itemService.updateItemWithStatusChange(this.currentItemct, Status.configured, this.user)
      .takeUntil(this.unsubscribe$)
      .subscribe(item => {
        this.selectedComponent = item.components[0];
        this.currentItemct = item;
        this.itemService.updateTotalPriceAndDimensions(this.unsubscribe$);
      });
  }
}



