import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Item, Project, Status} from '../../project/shared/project-model';
import {BomService} from '../shared/bom.service';
import {isNullOrUndefined} from 'util';
import {SessionStorage} from 'ngx-webstorage';
import {Range} from '../../shared/model/range-model';
import {RangeType} from '../../shared/model/range-type';

@Component({
  selector: 'app-delivery-details',
  templateUrl: './delivery-details.component.html',
  styleUrls: ['./delivery-details.component.less']
})
export class DeliveryDetailsComponent implements OnInit {

  @Input()
  currentItemct: Item;

  @Input()
  currentProjectItems: Item [];

  @Input()
  ranges: Range [];

  deliveryTime = 0;

  @SessionStorage()
  maxDeliveryTime;

  nonDeliverableItem: Array<string> = [];


  constructor(private bomService: BomService) {
  }

  ngOnInit() {
    // used for item BOM
    if (this.currentItemct !== null && this.currentItemct !== undefined) {
        this.deliveryTime = this.calculateDeliveryTimeForSwitchboardComponent(this.currentItemct);
        this.maxDeliveryTime = this.deliveryTime;
    }
    // used for project BOM
    if (this.currentProjectItems && this.currentProjectItems.length > 0) {
      this.deliveryTime = this.calculateDeliveryTimeForEntireProject(this.currentProjectItems);
      if (this.maxDeliveryTime !== null && this.deliveryTime > this.maxDeliveryTime) {
        this.maxDeliveryTime = this.deliveryTime;
      }
    }
    if (this.nonDeliverableItem.length > 0) {
      this.deliveryTime = null;
      this.maxDeliveryTime = null;
    }
  }

  /**
   * Allow to calculate the delivery time for one item and creates an array of components missing delivery time
   * The rule is that the delivery time for an item is the maximum delivery time of all item components like accessories,
   * options...
   * @param {Item} item current item
   */
  private calculateDeliveryTimeForSwitchboardComponent(item: Item): number {
    let maxDeliveryTime = 0;
    // loop on switchboard accessories
    item.accessories.forEach(accessory => {
      if (accessory.reference !== null && accessory.reference !== undefined && accessory.reference.ref) {
        maxDeliveryTime = this.calculateMaxDeliveryTime(accessory.reference.deliveryTime, maxDeliveryTime, accessory.name);
      }
    });
    // loop on switchboard component either cubicle type or transformer type
    item.components.forEach(component => {
      maxDeliveryTime = this.calculateMaxDeliveryTime(component.reference.deliveryTime, maxDeliveryTime, component.name);
      if (component.functionalUnits !== null && component.functionalUnits !== undefined) {
        component.functionalUnits.forEach(fu => {
          if (fu.transformer) {
            maxDeliveryTime = this.calculateMaxDeliveryTime( fu.transformer.reference.deliveryTime, maxDeliveryTime, fu.transformer.name);
          }
        });
      }
      if (component.options !== null && component.options !== undefined) {
        component.options.forEach(option => {
          maxDeliveryTime = this.getMaxDeliveryTimeOption(option, maxDeliveryTime);
        });
      }
    });

    maxDeliveryTime = this.adaptDeliveryTime(item, maxDeliveryTime);
    return maxDeliveryTime;
  }



  /**
   * Countries have two rules regarding the delivery time
   * first one : the delivery can't be under a specific value called 'minimum delivery time' fixed by FO
   * second one : an additional time must be added to the global delivery time, this time is alo fixed by FO
   * @param {Item} item
   * @param deliveryTime
   */
  private adaptDeliveryTime(item: Item, deliveryTime: number): number {
    const deliveryPolicyMinimumTime = this.bomService.getDeliveryPolicyMinimumTime(item, this.ranges);
    if (deliveryTime < deliveryPolicyMinimumTime) {
      deliveryTime = deliveryPolicyMinimumTime;
    } else if (deliveryTime !== 0) {
      deliveryTime += this.bomService.getDeliveryPolicyAdditionalTime(item, this.ranges);
    }
    return deliveryTime;
  }

  /**
   * Calculate delivery Time for a list of items
   * @param {Item[]} itemsList items of the current project
   * @returns {number} delivery time
   */
  private calculateDeliveryTimeForEntireProject(itemsList: Item[]): number {
      let maxDeliveryTime = 0;
      itemsList.forEach((item) => {
        const deliveryTime = this.calculateDeliveryTimeForSwitchboardComponent(item);
        maxDeliveryTime = Math.max(maxDeliveryTime, deliveryTime);
      });
      return maxDeliveryTime;
  }

  private calculateMaxDeliveryTime(deliveryTime, maxDeliveryTime: number, name,) {
    if (deliveryTime === null || deliveryTime === undefined || deliveryTime === 0) {
        this.nonDeliverableItem.push(name);
    } else {
      maxDeliveryTime = Math.max(maxDeliveryTime, deliveryTime);
    }
    return maxDeliveryTime;
  }

  private getMaxDeliveryTimeOption(option, maxDeliveryTime: number) {
    option.values.forEach(value => {
      if (value.selected && value.reference !== null) {
        maxDeliveryTime = this.calculateMaxDeliveryTime( value.reference.deliveryTime, maxDeliveryTime, value.value);
      }
    });
    return maxDeliveryTime;
  }
}
