<app-common-modal #linkRefModal [name]="'linkRefModal'" [title]=" " [twoButtonsDialogs]="true"
                  [withFooter]="false" size="modal-lg">
  <div *ngIf="loading">
    <app-spinner [sizeClass]="'large'"></app-spinner>
  </div>
  <h4 modal-title class="modal-title" translate>T_DOCUMENTATION_LINK_REF_MODAL_TITLE</h4>
  <div modal-body *ngIf="!loading">
    <p-table #dt
             [value]="availableRefs"
             [paginator]="false"
             [rowHover]="true"
             [globalFilterFields]="['reference','type', 'rangeNameTranslated']"
             [scrollable]="true" scrollHeight="400px">
      <ng-template pTemplate="caption">
        <div class="table-caption">
          <div class="table-caption-search-bar p-input-icon-left ml-auto">
            <span class="pi pi-search table-caption-search-bar-search-icon"></span>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                   placeholder="Search"/>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" [pSortableColumn]="'rangeNameTranslated'" translate>T_RANGE
            <p-sortIcon field="rangeNameTranslated"></p-sortIcon>
          </th>
          <th scope="col" [pSortableColumn]="'type'" translate>T_TYPE
            <p-sortIcon field="fileName"></p-sortIcon>
          </th>
          <th scope="col" [pSortableColumn]="'reference'" translate>T_REFERENCE
            <p-sortIcon field="reference"></p-sortIcon>
          </th>
          <th scope="col"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-refRange>
        <tr>
          <td><span>{{refRange.rangeNameTranslated}}</span></td>
          <td><span>{{refRange.type}}</span></td>
          <td><span>{{refRange.reference}}</span></td>
          <td>
                        <span>
                            <input
                              type="checkbox"
                              [checked]="isSelectedRef(refRange)"
                              (click)="clickOnRefCheckbox(refRange)"
                            />
                        </span>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="edit-documentation-modal-footer">
      <button class="btn btn-default-white-background footer-button" (click)="clickOnCancelLinkRef()" translate>
        T_BUTTON_CANCEL
      </button>
      <button class="btn btn-default-white-background footer-button"
              (click)="clickOnSaveLinkRef()"
              translate>T_BUTTON_SAVE
      </button>
    </div>
  </div>
</app-common-modal>
