/* angular modules */
import {Component, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
import {MessageService} from "primeng/api";
import {DocumentationService, DocumentInformations, StaticDocumentationService} from '../../export/shared';
import {ItemService} from '../../project/shared/item.service';
import {Item, Project, Status} from '../../project/shared/project-model';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {DatasheetsService} from '../../shared/datasheets/datasheets.service';
import {UtilService} from '../../shared/util/util.service';
import {CardComponentModalComponent} from '../card-component-modal/card-component-modal.component';
import {AddingTransformerInfosWrapper} from '../shared/model/AddingTransformerInfosWrapper';
/* app modules */
import {SwitchBoardComponent} from '../shared/model/component';
import {ComponentType} from '../shared/model/component-type';
import {PanelOptionWrapper} from '../shared/model/panelOptionWrapper';
import {WrapperModalContent} from '../shared/model/wrapperModalContent';
import {OptionsPanelService} from '../shared/services/options-panel.service';
import {RangeType} from '../../shared/model/range-type';
import {CardOptionModalComponent} from 'app/configuration/card-option-modal/card-option-modal.component';
import {ItemNavigationService} from '../shared/services/itemNavigation.service';
import {FuseService} from '../shared/services/fuse.service';
import {TransformerService} from '../shared/services/transformer.service';
import {CubicleService} from '../shared/services/cubicle.service';
import {UpSellingTransformerService} from '../../admin/shared/up-selling/up.selling.transformer.service';
import {LoggerService} from '../../shared/logging/logger.service';
import * as _ from 'lodash';
import {NavigationRoute} from '../../shared/guards/route-enum';
import {Subject} from 'rxjs/Rx';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.less'],
  providers: [CommonModalComponent, MessageService],
})
export class CardListComponent implements OnChanges, OnDestroy {

  @SessionStorage()
  user;

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  documentInformations: DocumentInformations;

  @Input()
  components: [SwitchBoardComponent];
  componentToAdd: SwitchBoardComponent;
  addedComponent: SwitchBoardComponent;
  @Input()
  componentsLength: number;
  @Input()
  addingTransformerInfos: AddingTransformerInfosWrapper;
  @ViewChild('componentInfosModal') componentInfosModal: CardComponentModalComponent;
  @ViewChild('changeDataModel') confirmChangeDataModal: CommonModalComponent;
  @ViewChild('transformerAddedModal') transformerAddedModal: CommonModalComponent;
  @ViewChild('maxFuModal') public maxFuModal: CommonModalComponent;
  @ViewChild('addOptionModal') public addOptionModal: CardOptionModalComponent;
  @ViewChild('transfoFuseIncompatibilityModal') public transfoFuseIncompatibilityModal: CardOptionModalComponent;

  visibleComponents: SwitchBoardComponent[];
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private staticDocService: StaticDocumentationService,
              private itemService: ItemService,
              private translateService: TranslateService,
              private router: Router,
              private messageService: MessageService,
              private optionPanelService: OptionsPanelService,
              private datasheetsService: DatasheetsService,
              private documentationService: DocumentationService,
              private itemNavigationService: ItemNavigationService,
              private upSellingTransformerService: UpSellingTransformerService,
              private logger: LoggerService,
              private utilService: UtilService) {
  }



  ngOnChanges(changes: SimpleChanges): void {
    this.getDataSheetForComponent();
    this.updateVisiblesComponents();
    this.getDrawingsForComponent();
  }

  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    // unsubscribe from the subject itself:
    this.unsubscribe$.unsubscribe();
  }

  selectedCard(wrapperModalContent: WrapperModalContent) {
    this.componentInfosModal.show(wrapperModalContent);
  }

  /**
   * Manage the component to add on items collection.
   * @param component The component to add.
   * @param updateItem The flag for item update
   */
  addComponentActions(component: SwitchBoardComponent, updateItem: boolean = false) {
    this.componentToAdd = component;
    if (this.currentItemct.status !== Status.quoted) {
      this.addComponentDispacher(updateItem);
    } else {
      this.confirmChangeDataModal.show();
    }
  }

  addComponentActionsWithDialog(component: SwitchBoardComponent) {
    this.componentToAdd = component;
    if (component.type === ComponentType.TRANSFORMER && this.isChosenTransformerNotCompatiblewithTheCubiclesFuse()) {
      this.transfoFuseIncompatibilityModal.show(this.componentToAdd);
    } else {
      this.addComponentActions(component);
    }
  }

  /**
   * Allow to define if we add a cubicle, a transformer to a cubicle or a transformer
   * @param updateItem
   */
  public addComponentDispacher(updateItem: boolean = false) {
    if (this.componentToAdd.type === ComponentType.CUBICLE) {
      this.componentToAdd.quantityId = this.mongoQuantityObjectId();
      // Check transformer compatibility with component
      this.upSellingTransformerService.isTransformerCompatibleReference(this.componentToAdd.reference.ref, this.currentItemct.range.nameKey)
        .takeUntil(this.unsubscribe$)
        .subscribe(
        isCompatible => {
          this.currentItemct.maxNavigationStep = NavigationRoute.getNextStepByRange(this.currentItemct.range, this.currentItemct.currentNavigationStep).id;
          if (isCompatible) {
            this.componentToAdd.functionalUnits.forEach(functionalUnit => {
              // Add compatibility if the function type is not 'I'
              if (functionalUnit.name && this.translateService.instant(functionalUnit.name) !== 'I') {
                functionalUnit.transformerCompatible = true;
              }
            });
          }
          this.addCubicleComponent(this.componentToAdd);
        },
        error => {
          this.logger.error(error);
          this.addCubicleComponent(this.componentToAdd);
        }
      );
    }
    if (this.currentItemct.range.rangeType === RangeType.SWITCHBOARD && this.componentToAdd.type === ComponentType.TRANSFORMER) {
      this.componentToAdd.quantityId = this.mongoQuantityObjectId();
      this.addTransformerComponent(this.componentToAdd, this.addingTransformerInfos.cubicleNumber,
        this.addingTransformerInfos.functionalUnitNumber, updateItem);
    }
    if (this.currentItemct.range.rangeType === RangeType.TRANSFORMER && this.componentToAdd.type === ComponentType.TRANSFORMER) {
      this.componentToAdd.quantityId = this.mongoQuantityObjectId();
      if (this.currentItemct.components.length > 0) {
        this.transformerAddedModal.show();
      } else {
        this.addTransformer();
      }
    }
  }

  public addCubicleComponent(component: SwitchBoardComponent) {
    let needToUpdateItem = true;
    // Add only if not exceeding max fu
    if (this.isSwitchboardUnderLimit(component)) {
      this.currentItemct.components.push(UtilService.clone(component));
      // PopUp a modal for option
      if (this.componentToAdd.options.length > 0 && SwitchBoardComponent.hasPopUpOption(this.componentToAdd)) {
        needToUpdateItem = false;
        this.addedComponent = component;
        this.addOptionModal.show(this.componentToAdd);
      }
      // Managing nav bar
      this.currentItemct = this.itemNavigationService.updateNavigationBar(component, this.currentItemct);
      if (needToUpdateItem) {
        this.updateCurrentItem(this.currentItemct.selectedComponentIndex, null);
      }
    } else {
      // TODO modal not selectable
      this.maxFuModal.show();
    }
  }

  /**
   * Add a transformer to a cubicle
   * @param {SwitchBoardComponent} transformer
   * @param {number} cubicleCompIndex
   * @param {number} functionIndex
   * @param updateItem
   */
  addTransformerComponent(transformer: SwitchBoardComponent, cubicleCompIndex: number, functionIndex: number, updateItem: boolean = false) {
    const functionalUnit = this.currentItemct.components[cubicleCompIndex].functionalUnits[functionIndex];
    if (functionalUnit.transformerCompatible) {
      this.addSwitchboardTransformerCurrentItem(cubicleCompIndex, functionIndex, transformer, updateItem);
    }
  }

  /**
   * used to add a transformer (an item as transformer)
   */
  public addTransformer() {
    this.currentItemct.components[0] = UtilService.clone(this.componentToAdd);

    // Managing nav bar
    this.currentItemct.maxNavigationStep = NavigationRoute.getNextStepByRange(this.currentItemct.range, this.currentItemct.currentNavigationStep).id;

    // Propose option only for transformer and when we have only one option
    if (this.componentToAdd.options.length >= 1) {
      this.addedComponent = this.currentItemct.components[0];
      this.addOptionModal.show(this.componentToAdd);
    } else {
      // Updating immediately if no option
      this.updateCurrentItem(null, null);
    }

    // Resetting docs on update
    if (this.documentInformations != null) {
      this.documentationService.resetDownloadedDoc(this.currentProject.id, this.currentItemct.id)
        .takeUntil(this.unsubscribe$)
        .subscribe(docInfo => this.documentInformations = docInfo);
    }
  }

  updateCurrentItem(cubicleCompIndex: number, functionIndex: number) {
    this.itemService.updateItemWithStatusChange(this.currentItemct, Status.configured, this.user)
      .takeUntil(this.unsubscribe$)
      .subscribe(item => {
          this.currentItemct = item;
          this.itemService.updateTotalPriceAndDimensions(this.unsubscribe$);
          this.clickOnSlideButton();
          this.clickOnCloseParameter();
          this.currentItemct.selectedComponentIndex = this.currentItemct.components.length - 1;
          if (this.currentItemct.range.rangeType === RangeType.TRANSFORMER) {
            this.navigateToBom();
          }
          // inform observers
          if (this.componentToAdd.type === ComponentType.CUBICLE) {
            this.optionPanelService.setSelectedComponent(this.componentToAdd);
            this.optionPanelService.updateSelectedComponentInfos(
              new PanelOptionWrapper(ComponentType.CUBICLE, this.currentItemct.selectedComponentIndex, null));
          }
          if (this.currentItemct.range.rangeType === RangeType.SWITCHBOARD && this.componentToAdd.type === ComponentType.TRANSFORMER) {
            this.optionPanelService.setSelectedComponent(this.componentToAdd);
            this.optionPanelService.updateSelectedComponentInfos(
              new PanelOptionWrapper(ComponentType.TRANSFORMER, cubicleCompIndex, functionIndex
              ));
          }
        },
        error => null,
        () => this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFORMATION'),
          detail: this.translateService.instant('T_SUCCESS_ADD_ITEM')
        })
      );
  }

  /**
   * Add transformer and save the item.
   */
  addTransfoAndupdateCurrentItem() {
    this.addComponentActions(this.componentToAdd, true);
  }

  /**
   * Determine if we can add a component to the item
   * we check component.functionalUnits just to avoid likely mistakes on db
   * @param {SwitchBoardComponent} component to add
   * @returns {boolean}
   */
  isSwitchboardUnderLimit(component: SwitchBoardComponent): boolean {
    if (component.functionalUnits) {
      return this.itemService.canAddComponentToItem(component.functionalUnits.length);
    }
    return false;
  }

  /**
   * Allow to open or close the slide component
   * not used when range is transformer
   */
  clickOnSlideButton() {
    const close_btn = document.getElementById('btn-selection-slide');
    if (close_btn !== null) {
      close_btn.click();
    }
  }

  /**
   * Allow to close the parameter right panel
   * not used when range is transformer
   */
  clickOnCloseParameter() {
    const close_btn = document.getElementById('btn-close-parameter');
    if (close_btn !== null) {
      close_btn.click();
    }
  }

  navigateToBom() {
    this.router.navigate([NavigationRoute.getNextStepByRange(this.currentItemct.range, this.currentItemct.currentNavigationStep).link]);
  }

  private updateVisiblesComponents() {
    this.components.forEach(comp => this.computeVisibility(comp));
    this.visibleComponents = this.components.filter(comp => comp.visible);
  }

  private computeVisibility(comp: SwitchBoardComponent) {
    if (comp.type === ComponentType.CUBICLE) {
      comp.visible = CubicleService.computeVisibilityForCubicle(this.currentItemct, comp);
    } else if (comp.type === ComponentType.TRANSFORMER) {
      comp.visible = TransformerService.computeVisibilityForTransformer(this.currentItemct, comp, this.addingTransformerInfos);
    } else {
      comp.visible = true;
    }
  }

  /**
   * get the datasheet from Ops async for all the component
   */
  private getDataSheetForComponent(): void {
    this.datasheetsService
      .getComponentsDatasheetUrl(this.components, this.user.preferredLanguage)
      .takeUntil(this.unsubscribe$)
      .subscribe(payloadDocumentList => {
        payloadDocumentList.forEach(payload => {
          // we retreve component id of the payload
          const payloadComponentId = _.keys(payload)[0];
          const findComponent = this.components.find(c => c.id === payloadComponentId);
          if (findComponent !== undefined) {
            // we add payload documentation to the component's documentation
            findComponent.documents = _.uniqBy(_.concat(findComponent.documents, payload[payloadComponentId]), 'id');
          }
        });
      });
  }

  /**
   * Retrieve drawings documentations form BSL for components
   */
  private getDrawingsForComponent(): void {
    // we try to retrieve documentation after the first load, it can take a long time
    this.staticDocService.retrieveComponentBslDocumentation(this.currentItemct.range.id).subscribe(bslDocs => {
      if (bslDocs === null || bslDocs === undefined) {
        return;
      }
        // for each payload (ComponentId/Documentation) in the map handle the documentation
      bslDocs.forEach(payload => {
        // get the key of the payload representing the componentId
        const payloadComponentId = _.keys(payload)[0];
        // get the component corresponding to the id in the component collection
        const innerComp = _.findLast(this.components, component => {
          return component.id === payloadComponentId;
        });

        // merge the dynamic document to the static document (if found in components)
        if (innerComp) {
          innerComp.documents = _.uniqBy(_.concat(innerComp.documents, payload[payloadComponentId]), 'id');
        }
      });
    });
  }

  private addSwitchboardTransformerCurrentItem(cubicleCompIndex: number, functionIndex: number, transformer: SwitchBoardComponent, updateItem: boolean = false) {
    this.currentItemct.components[cubicleCompIndex].functionalUnits[functionIndex].transformer = transformer;
    this.itemService.addSwitchboardTransformerItem(this.currentItemct,
      cubicleCompIndex, functionIndex, transformer, Status.configured, this.user)
      .takeUntil(this.unsubscribe$)
      .subscribe(item => {
          this.currentItemct = item;
          this.itemService.updateTotalPriceAndDimensions(this.unsubscribe$);
          if (!updateItem) {
            this.clickOnSlideButton();
          }
          this.currentItemct.selectedComponentIndex = this.currentItemct.components.length - 1;
          if (this.currentItemct.range.rangeType === RangeType.TRANSFORMER) {
            this.navigateToBom();
          }
          // inform observers
          if (this.componentToAdd.type === ComponentType.CUBICLE) {
            this.optionPanelService.setSelectedComponent(this.componentToAdd);
            this.optionPanelService.updateSelectedComponentInfos(
              new PanelOptionWrapper(ComponentType.CUBICLE, this.currentItemct.selectedComponentIndex, null));
          }
          if (this.currentItemct.range.rangeType === RangeType.SWITCHBOARD && this.componentToAdd.type === ComponentType.TRANSFORMER) {
            this.itemService.setItemMaxNavigationStep(NavigationRoute.getNextStepByRange(this.currentItemct.range, this.currentItemct.currentNavigationStep).id,
              this.user, true, this.unsubscribe$);
            this.optionPanelService.setSelectedComponent(this.componentToAdd);
            this.optionPanelService.updateSelectedComponentInfos(
              new PanelOptionWrapper(ComponentType.TRANSFORMER, cubicleCompIndex, functionIndex
              ));
          }
          if (updateItem) {
            this.updateCurrentItem(this.currentItemct.selectedComponentIndex, null);
          }
        },
        error => null,
        () => this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFORMATION'),
          detail: this.translateService.instant('T_SUCCESS_ADD_ITEM')
        })
      );
  }

  private mongoQuantityObjectId(): string {
    return this.utilService.generateObjectId();
  }

  private isChosenTransformerNotCompatiblewithTheCubiclesFuse() {
    // at this moment warning is shown only for INDONESIA offer
    if (this.currentItemct.range.nameKey !== 'T_SM6_ID') {
      return false;
    }
    const component: SwitchBoardComponent = this.currentItemct.components[this.addingTransformerInfos.cubicleNumber];
    const cubicleTypeConcerned = CubicleService.isQMorQMCorPM(component, 'T_SM6_ID_SM6R_');
    if (cubicleTypeConcerned) {
      const fuseSelected = FuseService.getSelectedFuseOptionFromCubicle(component);
      if (fuseSelected && fuseSelected.value !== 'T_TRANSFORMER_ID_WITHOUT') {
        return !TransformerService.isCompatibleWithFuse(component, this.componentToAdd, fuseSelected);
      }
    }
    return false;
  }

}
