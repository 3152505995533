import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs/Observable";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  connexionInProgress = false;
  disconnectionUrl: URL;

  private readonly restRoutes = {
    userToken: '/user/idms',
    userDisconnect: '/user'
  };

  constructor(private httpClient: HttpClient) {
    this.disconnectionUrl = new URL(environment.idmsDisconnectionUrl);
    this.disconnectionUrl.searchParams.append("post_logout_redirect_uri", environment.idmsDisconnectionRedirectionUrl);
  }

  login() {
    this.removeToken();
    const connexionUrl = new URL(environment.idmsAuthorizeUrl);
    connexionUrl.searchParams.append("scope", "openid refresh_token");
    connexionUrl.searchParams.append("response_type", "code");
    connexionUrl.searchParams.append("redirect_uri", environment.idmsRedirectUri);
    connexionUrl.searchParams.append("state", environment.platform);
    connexionUrl.searchParams.append("client_id", environment.idmsClientId);

    window.location.href = connexionUrl.href;
  }

  public getAccessToken(tempCode: string): Observable<unknown> {
    return this.httpClient.post(this.restRoutes.userToken, tempCode, {responseType: "text"});
  }
  public logout(): void {
    this.httpClient.delete(this.restRoutes.userDisconnect,
      {responseType: 'text'})
      .subscribe(
        () => {
          window.location.href = this.disconnectionUrl.href;
        },
        () => {
          // In case of 401 error
          window.location.href = this.disconnectionUrl.href;
        }
      );
    this.removeToken();
  }

  public isLogged() {
    const connected = environment.authenticationSkipped || !!this.getToken();
    if (!connected) {
      this.login();
    }
    return connected;
  }

  public getToken() {
    return localStorage.getItem(environment.ecorealToken)!;
  }

  public saveToken(token: any) {
    localStorage.setItem(environment.ecorealToken, token);
  }

  public removeToken() {
    localStorage.removeItem(environment.ecorealToken);
  }

}
