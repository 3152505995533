import {Component, Input} from '@angular/core';
import {RangeBslDocument} from '../../../../shared/bsl-reference/offer-data-management-model';

@Component({
  selector: 'app-odm-documents-preview',
  templateUrl: './odm-add-offer-documents-preview.component.html',
  styleUrls: ['./odm-add-offer-documents-preview.component.less']
})
export class OdmAddOfferDocumentsPreviewComponent {

  @Input()
  title: string;

  @Input()
  documents: RangeBslDocument[];

  constructor() {
  }
}
