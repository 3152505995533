/* Angular modules */
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
import {TranslateService} from '@ngx-translate/core';
import {TreeNode} from "primeng/api";
import {MessageService} from 'primeng/api';
/* app modules */
import {NavigationStep} from '../../shared/guards/navigationStep-enum';
import {BomService} from '../shared';
import {ItemService} from '../../project/shared/item.service';
import {Item, Project, Status} from '../../project/shared/project-model';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {UtilService} from '../../shared/util/util.service';
import {LoggerService} from '../../shared/logging/logger.service';
import {RightsService} from '../../shared/rights/rights.service';
import {ReferenceBom} from '../shared/bom-model';
import {User} from '../../shared/user/user';
import {BomHelper} from '../shared';
import {ProjectService} from '../../project/shared/project.service';
import {BomTreeDataHelper} from '../shared/bom-tree-data-helper.service';
import {Subject} from 'rxjs/Rx';
import {MySEStatusEnum} from '../../shared/model/mySEStatusEnum';
import {NavigationRoute} from '../../shared/guards/route-enum';
import * as moment from 'moment';
import {Range} from '../../shared/model/range-model';
import {OfferService} from '../../offers/shared/offer.service';
import {Role} from '../../core/access-control/enum';
import {FeedbackService} from '../../feedback/shared/service/feedback.service';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-bom-page',
  templateUrl: './bom-page.component.html',
  styleUrls: ['./bom-page.component.less'],
})
export class BomPageComponent implements OnInit, OnDestroy, AfterViewInit {

  @SessionStorage()
  currentItemct: Item;


  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  localization;

  @SessionStorage()
  user: User;

  // FIXME: Find a logic to have a single parameter (either isDemoUser or isDemoMode)
  @SessionStorage()
  isDemoMode: boolean;

  @SessionStorage()
  hasRegister: boolean;

  loadingBom = false;
  loadingQuotationBom = false;
  expandedNodes: string[] = [];
  bomString: string = null;
  status = Status;
  unsubscribe$: Subject<void> = new Subject<void>();
  validityPeriod: number;
  ranges: Range[];

  @ViewChild('quoteModal') quoteModal: CommonModalComponent;
  @ViewChild('updateQuoteModal') updateQuoteModal: CommonModalComponent;

  public cols: Array<any>;

  treeData: TreeNode[] = [];

  public constructor(private bomService: BomService,
                     private bomHelper: BomHelper,
                     private bomTreeDataHelper: BomTreeDataHelper,
                     private itemService: ItemService,
                     public utilService: UtilService,
                     private logger: LoggerService,
                     private projectService: ProjectService,
                     private translateService: TranslateService,
                     private rightsService: RightsService,
                     private messageService: MessageService,
                     private offerService: OfferService,
                     private feedbackService: FeedbackService) {
  }

  public ngOnInit(): void {
    this.logger.debug('BomPageComponent init()');

    this.offerService.getRangesBackOffice()
      .takeUntil(this.unsubscribe$)
      .subscribe(ranges => {
        this.ranges = ranges;

        this.currentItemct.currentNavigationStep = NavigationStep.BILL_OF_MATERIALS;
        this.bomService.extractReferencesToArray(this.currentItemct, false);

        // In case of CPQ user, we allow Documentation step
        if (this.projectService.isCpqProject(this.currentProject)) {
          this.showFinalizedConfigurationFeedback();
          this.currentItemct.maxNavigationStep = this.currentItemct.range ?
            NavigationRoute.getNextStepByRange(this.currentItemct.range, this.currentItemct.currentNavigationStep).id :
            NavigationRoute.getNextStepByPackageOffer(this.currentItemct.currentNavigationStep).id;
          if (this.currentItemct.status === Status.ordered) {
            // If CPQ project is already ordered, we do not update status
            this.itemService.updateItem(this.currentItemct, this.user)
              .takeUntil(this.unsubscribe$)
              .subscribe(res => {
                this.currentItemct = res;
              });
          } else {
            this.generateBomInformation(this.currentItemct);
            // Otherwise, we update status
            this.itemService.updateItemWithStatusChange(this.currentItemct, Status.quoted, this.user)
              .takeUntil(this.unsubscribe$)
              .subscribe(res => {
                this.currentItemct = res;
              });
          }
        }

        this.cols = this.bomHelper.getBOMColumns(this.isDemoMode, this.isCpqProject(),
          this.rightsService.canApplyDiscount(), this.isMySEUser(),
          this.isPartnerNetPrice(),
          this.displayAvailability());

        if (this.currentItemct.status === Status.configured) {
          this.updateBom();
        } else {
          this.itemService.setItemNavigationStep(NavigationStep.BILL_OF_MATERIALS, this.user, this.unsubscribe$);
          this.generateBomInformation(this.currentItemct);
        }
        this.validityPeriod = this.bomService.getValidityPeriod(this.currentItemct, this.ranges);
      });
  }

  ngAfterViewInit(): void {
    if (this.currentItemct.status === Status.quoted && !this.currentProject.readOnly && !this.projectService.isCpqProject(this.currentProject)) {
      this.offerService.getRangesBackOffice()
        .takeUntil(this.unsubscribe$)
        .subscribe(ranges => {
          this.ranges = ranges; // make sure the ranges is not null before QuotationValid checking
          this.isQuotationValid();
        });
    }
  }

  checkQuotation(): void {
    if (!this.projectService.isCpqProject(this.currentProject)) {
      if (this.currentItemct.status === Status.configured && !this.currentProject.readOnly) {
        this.quoteModal.show();
      }
    }
  }

  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    // unsubscribe from the subject itself:
    this.unsubscribe$.unsubscribe();
  }

  public isAdminUser(): boolean {
    return this.rightsService.isNationalSales();
  }

  isDemoUser(): boolean {
    return this.rightsService.isActivatedDemo();
  }


  /**
   * Return true if the user is a mySE user
   * @returns {boolean}
   */
  isMySEUser(): boolean {
    return !!this.user.currentMySEAccount;
  }

  isReadOnlyProject(): boolean {
    return this.projectService.isReadOnlyProject(this.currentProject, this.user);
  }

  /**
   * Get BOM Tree Data from the current item
   * @param item
   * @param quoteItem
   */
  generateBomInformation(item: Item, quoteItem?: boolean) {
    if (this.currentItemct.status === Status.configured && (!this.currentItemct.range || this.currentItemct.range.refs !== null || this.user.currentMySEAccount)) {
      // We log finalize configuration for configured items
      this.logBomInfo(item);
    }

    // deep clone of the item
    const myItem = UtilService.clone(item);

    this.treeData = this.bomTreeDataHelper.extractReferencesToTree(myItem, this.ranges);
    this.treeData.forEach(value => {
      // restore expanded state
      value.expanded = true;
      // first category : accessories, cubicles, transformers
      value.data.totalPrice = UtilService.formatPrice(value.data.totalPrice);
      value.data.totalNetPrice = UtilService.formatPrice(value.data.totalNetPrice);
      value.data.discountedNetPrice = UtilService.formatPrice(value.data.discountedNetPrice);
      value.data.discountedPrice = UtilService.formatPrice(value.data.discountedPrice);
      // set price in empty if demo mode
      if (this.isDemoMode) {
        value.data.totalPrice = '';
        value.data.totalNetPrice = '';
        value.data.price = '';
      }

      this.bomHelper.formatTreeNode(value, this.isDemoMode);
    });

    if (quoteItem) {
      this.quoteCurrentItem();
    }
  }

  isContractor(): boolean {
    return this.rightsService.isContractor();
  }

  /**
   * Method to update status item to Quote
   */
  public quoteCurrentItem() {
    this.logger.info('BomPageComponent quoteCurrentItem()');
    this.currentItemct.maxNavigationStep = this.currentItemct.range ?
      NavigationRoute.getNextStepByRange(this.currentItemct.range, this.currentItemct.currentNavigationStep).id :
      NavigationRoute.getNextStepByPackageOffer(this.currentItemct.currentNavigationStep).id;
    const now = new Date();
    this.loadingQuotationBom = true;
    this.currentItemct.date = now.getTime();
    // Set locale date format
    moment.locale(this.user.preferredLanguage);
    // Validity period of quotation
    const validityPeriod = this.bomService.getValidityPeriod(this.currentItemct, this.ranges);
    // Compute expiration date of quotation
    const futureDate: moment.Moment = moment(this.currentItemct.date).add(validityPeriod, 'days');
    // Set quotation validity date
    this.currentItemct.dateValidityQuotation = futureDate.valueOf();
    this.itemService.updateItemWithStatusChange(this.currentItemct, Status.quoted, this.user)
      .takeUntil(this.unsubscribe$)
      .subscribe(res => {
          this.currentItemct = res;
          this.loadingQuotationBom = false;
          this.generateBomInformation(this.currentItemct);
          this.logger.business('Quote configuration',
            {
              total_price: UtilService.getFormattedTotalDiscountPriceFromBOM(this.currentItemct.bom,
                this.user.currentMySEAccount === null && this.currentItemct.totalPrice.validFoNetPrice,
                this.currentItemct.discount),
              currency: this.localization.currency
            });
          // this.updateBom();
        },
        () => {
          this.loadingQuotationBom = false;
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant('T_ITEM_UPDATE_ERROR'),
          });
        });

    this.showFinalizedConfigurationFeedback();
  }

  updateBom(quoteItem?: boolean) {
    this.loadingBom = true;
    if (!!this.currentItemct.range && this.currentItemct.range.refs === null
      && !this.user.currentMySEAccount) {
      this.loadingBom = false;
      // Synchro called to not lose the current navigation step
      this.itemService.setItemNavigationStep(NavigationStep.BILL_OF_MATERIALS, this.user, this.unsubscribe$);
      this.generateBomInformation(this.currentItemct);
    } else {
      this.bomService.getBom(this.currentItemct.id)
        .takeUntil(this.unsubscribe$)
        .subscribe(item => {
            this.currentItemct = item;
            this.cols = this.bomHelper.getBOMColumns(this.isDemoMode, this.isCpqProject(),
              this.rightsService.canApplyDiscount(), this.isMySEUser(),
              this.isPartnerNetPrice(),
              this.displayAvailability());
            this.loadingBom = false;
            if (!quoteItem) {
              this.checkQuotation();
            }
            // Synchro called to not lose the current navigation step
            this.itemService.setItemNavigationStep(NavigationStep.BILL_OF_MATERIALS, this.user, this.unsubscribe$, true);
            this.generateBomInformation(item, quoteItem);
            this.logBomInfo(item);
          },
          () => {
            this.loadingBom = false;
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('T_ERROR'),
              detail: this.translateService.instant('T_ITEM_UPDATE_ERROR'),
            });
          });
    }
  }

  /**
   * Method to know if it is a CPQ project
   * @returns {boolean}
   */
  isCpqProject(): boolean {
    return this.projectService.isCpqProject(this.currentProject);
  }

  isCpqUser(): boolean {
    return this.user.role == Role.CPQ_USER;
  }

  /**
   * Check if it is necessary to display availability on stock column
   */
  private displayAvailability(): boolean {
    let display;
    // Check components
    display = this.currentItemct.components.some(sw => {
      if (sw.reference !== null && sw.reference.availability !== null) {
        return true;
      }
      // Check options of switchboard
      return sw.options.some(opt => {
        return opt.values.some(val => val.selected && val.reference !== null && val.reference.availability !== null);
      }); // opt
    }); // component

    // Check accessories
    display = display || this.currentItemct.accessories.some(acc => {
      return acc.reference !== null && acc.reference.availability !== null;
    });

    // Check Packing
    display = display || this.currentItemct.optionalPacking.filter(packing => packing.values !== null).some(packing => {
      return packing.values.some(val => {
        return (val.reference !== null && val.reference.availability !== null);
      });
    });
    return display;
  }

  bomContainsNullPublicPrices(){
    let bomContainsNullPrices = false;
    this.treeData.forEach(treeNode => {
      if(treeNode.data.totalPrice == 'containsNullPrices'){
        bomContainsNullPrices = true;
      }
    })
    return bomContainsNullPrices;
  }

  bomContainsNullNetPrices(){
    let bomContainsNullPrices = false;
    this.treeData.forEach(treeNode => {
      if(treeNode.data.totalNetPrice == 'containsNullPrices'){
        bomContainsNullPrices = true;
      }
    })
    return bomContainsNullPrices;
  }
  /**
   * Returns true if item has every price > 0
   * Returns false otherwise
   * @param item
   */
  private hasEveryPrices(item: Item): boolean {
    let hasAllPrices;
    // Check components
    hasAllPrices = item.components.every(sw => sw.reference !== null && sw.reference.price > 0 &&
      sw.options.every(opt => {
        const optValues = opt.values.filter(val => val.selected && val.reference != null);
        return optValues.length === 0 || optValues.every(val => val.selected && val.reference !== null && val.reference.price > 0);
      }));

    // Check accessories
    hasAllPrices = hasAllPrices && item.accessories.filter(acc => acc.reference !== null)
      .every(acc => acc.reference !== null && acc.reference.price > 0);

    // Check Packing
    hasAllPrices = hasAllPrices && item.optionalPacking.filter(packing => packing.values != null).every(packing =>
      packing.values.some(val => val.reference !== null && val.reference.price > 0));

    // Check Services
    hasAllPrices = hasAllPrices && item.selectedServices
      .every(service => service.reference !== null && service.reference.price > 0);

    return hasAllPrices;
  }

  /**
   * Returns true if item has at least one price > 0
   * Returns false otherwise
   * @param item
   */
  private hasSomePrices(item: Item): boolean {
    let hasPrice;
    // Check components
    hasPrice = item.components.some(sw => {
      if (sw.reference !== null && sw.reference.price > 0) {
        return true;
      }
      // Check options of switchboard
      return sw.options.some(opt => {
        return opt.values.some(val => val.selected && val.reference !== null && val.reference.price > 0);
      }); // opt
    }); // component

    // Check accessories
    hasPrice = hasPrice || item.accessories.some(acc => {
      return acc.reference !== null && acc.reference.price > 0;
    });

    // Check Packing
    hasPrice = hasPrice || item.optionalPacking.filter(packing => packing.values !== null).some(packing => {
      return packing.values.some(val => {
        return (val.reference !== null && val.reference.price > 0);
      });
    });

    // Check Services
    hasPrice = hasPrice || item.selectedServices.some(service => {
      return service.reference !== null && service.reference.price > 0;
    });
    return hasPrice;
  }


  /**
   * Check if it has at least one reference with net price
   */
  private someNetPrice(): boolean {
    let hasNetPrice;
    // Check components
    hasNetPrice = this.currentItemct.components.some(sw => {
      if (sw.reference !== null && sw.reference.mySeNetPriceStatus === MySEStatusEnum.OK) {
        return true;
      }
      // Check options of switchboard
      return sw.options.some(opt => {
        return opt.values.some(val => val.selected && val.reference !== null && val.reference.mySeNetPriceStatus === MySEStatusEnum.OK);
      }); // opt
    }); // component

    // Check accessories
    hasNetPrice = hasNetPrice || this.currentItemct.accessories.some(acc => {
      return acc.reference !== null && acc.reference.mySeNetPriceStatus === MySEStatusEnum.OK;
    });

    // Check Packing
    hasNetPrice = hasNetPrice || this.currentItemct.optionalPacking.filter(packing => packing.values !== null).some(packing => {
      return packing.values.some(val => {
        return (val.reference !== null && val.reference.mySeNetPriceStatus === MySEStatusEnum.OK);
      });
    });

    // Check Services
    hasNetPrice = hasNetPrice || this.currentItemct.selectedServices.some(service => {
      return service.reference !== null && service.reference.mySeNetPriceStatus === MySEStatusEnum.OK;
    });
    return hasNetPrice;
  }

  private logBomInfo(itemProject: Item) {
    setTimeout(() => {
      let totalPublicPrice = this.currentItemct.totalPrice.foPublicPrice;
      let totalNetPrice = totalPublicPrice;
      const refArray: ReferenceBom[] = this.bomService.extractReferencesToArray(itemProject);
      const refDtoArray = refArray.map(
        (referenceBom: ReferenceBom) =>
          'REFERENCE :' + referenceBom.ref + ' REFERENCE_DESCRIPTION : ' + referenceBom.description + ' QUANTITY ' + referenceBom.count);
      this.bomString = refDtoArray.toString();
      let configPriceStatus: string;
      if (this.isMySEUser()) {
        if (itemProject.mySeNetPriceStatus === MySEStatusEnum.OK && itemProject.totalPrice.mySENetPrice > 0) {
          configPriceStatus = 'Full Price';
          totalNetPrice = this.currentItemct.totalPrice.mySENetPrice;
        } else if (this.someNetPrice() && itemProject.totalPrice.mySENetPrice > 0) {
          configPriceStatus = 'Partial Price';
        } else {
          configPriceStatus = 'No Price';
        }
      } else {
        // Case of database prices
        if (this.hasEveryPrices(itemProject)) {
          configPriceStatus = 'Full Price';
        } else if (this.hasSomePrices(itemProject)) {
          configPriceStatus = 'Partial Price';
        } else {
          configPriceStatus = 'No Price';
        }
      }
      if (totalPublicPrice === null || totalPublicPrice === undefined) {
        totalPublicPrice = 0;
      }
      // We add the discount for net price
      totalNetPrice = UtilService.applyDiscountPrice(totalNetPrice, itemProject.discount);
      this.logger.business('Finalise configuration',
        {
          total_public_price: totalPublicPrice,
          total_net_price: totalNetPrice,
          currency: this.localization.currency,
          bom_references: this.bomString,
          config_price_status: configPriceStatus
        });

    }, 1000);
  }

  private isQuotationValid() {
    if (this.currentItemct) {
      const validityPeriod = this.bomService.getValidityPeriod(this.currentItemct, this.ranges);
      const expirationDate: moment.Moment = moment(this.currentItemct.date).add(validityPeriod, 'days');
      const quoteIsValid = expirationDate.isAfter(moment());
      if (!quoteIsValid) {
        this.updateQuoteModal.show();
      }
    }
  }

  /**
   * Return true if current item has a partner net price
   * If there is no total price, return false
   */
  private isPartnerNetPrice() {
    return this.currentItemct.totalPrice ? this.currentItemct.totalPrice.validFoNetPrice : false;
  }

  /**
   * Show feedback modal if the last feedback is expired for finalized configuration event
   */
  private showFinalizedConfigurationFeedback() {
    this.feedbackService.showEventFeedbackModal(environment.feedbackDelay * 1000); // show feedback after x seconds
  }
}
