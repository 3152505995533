<div class="project-files">
  <div class="project-files-list-container">
    <ul class="project-files-list">
      <li *ngFor="let file of files"  [ngClass]="{'col-xs-2 project-file-item': true, 'item-read-only':readOnly}">
        <div (click)="downloadDocument(file)" class="downloadable project-file-item-preview">
          <app-spinner *ngIf="file.loading" [sizeClass]="'small'"></app-spinner>
          <img *ngIf="!file.loading && file.img" class="figure" [src]="domSanitizer.bypassSecurityTrustUrl(file.img)">
          <span *ngIf="!file.loading && !file.img && file.imgTxt" class="figure downloadable">{{file.imgTxt}}</span>
          <span *ngIf="!file.loading && !file.img && !file.imgTxt" class="figure downloadable file-icon"><span [class]=getIconClass(file)></span></span>
        </div>
        <div class="project-file-item-actions">
          <span (click)="downloadDocument(file)" class="file-name downloadable">{{file.name}}</span>
          <br/>
          <span class="file-description">{{getCreationDetails(file)}}</span>
        </div>

        <div *ngIf="!readOnly" class="panel-heading" style="background-color: white">
          <div class="btn-group  button-div pull-right" dropdown placement="bottom left" *ngIf="user.id === selectedProject.author.id || user.email === selectedProject.sharedProjectUserEmail">
            <button  dropdownToggle type="button" class="btn se-top-btn toggle-dropdown">
              <span class="se-icons font-param dropdown-image" aria-hidden="true">other_vertical</span>
            </button>
            <ul *dropdownMenu class="box-dropdown dropdown-menu dropdown-menu-right" role="menu">
              <li><a (click)="fileInputUpdate.click()" translate>T_FILE_UPDATE</a>
                <input #fileInputUpdate type="file" (change)="updateFile($event, file)" style="display:none" />
              </li>
              <li><a (click)="showModal(file)" translate>T_FILE_DELETE</a></li>
            </ul>
          </div>
        </div>
      </li>

      <div class="col-xs-2 project-file-item"
           tooltip="{{(user.id !== selectedProject.author.id && user.email !== selectedProject.sharedProjectUserEmail ? 'T_COLLABORATIVE_NOT_OWNER_TOOLTIP' : '') | translate}}">
        <figure class="figure">
          <button  type="button" class="add-file-button btn " (click)="fileInput.click()"
                   [disabled]="user.id !== selectedProject.author.id && user.email !== selectedProject.sharedProjectUserEmail">
            <p class="plus-button">
              <span class="se-icons">electricity_polarity_positive_plus</span>
            </p>
            <input #fileInput type="file" (change)="addFile($event)" style="display:none" />
          </button>
        </figure>
        <p translate class="add-file">T_ADD_FILE</p>
      </div>
    </ul>
  </div>
</div>


<!-- confirm dialog -->
<app-common-modal #childModal [name]="'childModal'" (onClickYesButton)="confirmDelete()" [size]="''">
</app-common-modal>
