import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {CommonModalComponent} from "../../../../shared/common-modal/common-modal.component";
import {Documentation, EditDocumentationForm} from "../../../../shared/documentation/documentation.model";
import {FileService, TranslationService} from "../../../shared";
import {TranslateService} from "@ngx-translate/core";
import {SessionStorage} from "ngx-webstorage";
import {Localization} from "../../../../shared/model/localization.model";
import {LoggerService} from "../../../../shared/logging/logger.service";
import {MessageService} from "primeng/api";
import {DocumentationService} from "../../../../shared/documentation/documentation.service";
import {File} from "../../../shared/file/file.model";
import {UtilService} from "../../../../shared/util/util.service";

@Component({
    selector: 'app-documentation-edit-modal',
    templateUrl: './documentation-edit-modal.component.html',
    styleUrls: ['./documentation-edit-modal.component.less']
})
export class DocumentationEditModalComponent {

    constructor(private translationService: TranslationService,
                private translateService: TranslateService,
                private logger: LoggerService,
                private messageService: MessageService,
                private fileService: FileService,
                private documentationService: DocumentationService,
                private utilService: UtilService) {
    }

    @SessionStorage()
    localization: Localization;

    @ViewChild('editDocumentationModal', {static: false}) editDocumentationModal: CommonModalComponent;

    @Output()
    documentationUpdated: EventEmitter<Documentation> = new EventEmitter<Documentation>();

    editDocumentationForm: EditDocumentationForm = {
        id: null,
        name: {},
        fileName: null,
        category: null,
        localFileId: null,
        extension: null
    };

    originalDocumentation: Documentation;

    loading = false;

    localFile: File;
    availableCategories: string[];

    showAddMode() {
        this.editDocumentationForm = {
            id: null,
            name: {},
            fileName: null,
            category: null,
            localFileId: null,
            extension: null
        };
        const languages = this.localization.langs.map(lang => lang.language);
        languages.forEach(lang => {
            this.editDocumentationForm.name[lang] = null;
        })
        this.getAvailableCategories();
        this.editDocumentationModal.show();
    }

    showEditMode(documentation: Documentation) {
        this.editDocumentationForm = {
            id: null,
            name: {},
            fileName: null,
            category: null,
            localFileId: null,
            extension: null
        };
        this.originalDocumentation = documentation;
        this.translationService.getLocalesOfCountryOfKey(this.originalDocumentation.title).subscribe((locale => {
            locale.forEach(loc => {
                this.editDocumentationForm.name[loc.language] = loc.translations[0].translation;
            })
        }));
        this.editDocumentationForm.id = documentation.id;
        this.editDocumentationForm.fileName = documentation.fileName;
        this.editDocumentationForm.category = documentation.category;
        this.editDocumentationForm.localFileId = documentation.localFileId;
        this.editDocumentationForm.extension = documentation.extension;
        this.getAvailableCategories();

        this.editDocumentationModal.show();
    }

    hide() {
        this.editDocumentationModal.hide();
    }

    getAvailableCategories() {
        this.documentationService.getAvailableCategories().subscribe(categories => {
            this.availableCategories = categories;
        });
    }

    clickOnChooseFile(event) {
        const files = event.target.files;
        if (files.length > 0) {
            const allowedFormat = 'application/pdf';
            const maximumSize = 12000 * 1024; // 12mb
            if (files[0].type !== allowedFormat || files[0].size > maximumSize) {
                this.messageService.add({
                    severity: 'error',
                    summary: this.translateService.instant('T_ERROR'),
                    detail: this.translateService.instant('T_UPLOAD_FILE_FORMAT_ERROR'),
                });
                return;
            }
            const formData: FormData = new FormData();
            formData.append('file', files[0]);
            this.editDocumentationForm.fileName = files[0].name.split('.')[0];
            this.loading = true;
            this.fileService.create(formData).subscribe(file => {
                this.logger.info('file created for documentation');
                this.editDocumentationForm.localFileId = file.id;
                this.editDocumentationForm.extension = "pdf";
                this.localFile = file;
                this.loading = false;
                this.messageService.add({
                    severity: 'success',
                    summary: this.translateService.instant('T_INFO'),
                    detail: this.translateService.instant('T_UPLOAD_FILE_SUCCESS'),
                });


            }, () => {
                this.messageService.add({
                    severity: 'error',
                    summary: this.translateService.instant('T_ERROR'),
                    detail: this.translateService.instant('T_UPLOAD_FILE_ERROR'),
                });
                this.loading = false;
            });
        }
        // clean files in event
        event.target.value = null;
    }

    isValidForm(): boolean {
        for (let key in this.editDocumentationForm.name) {
            if (!this.editDocumentationForm.name[key] || !this.isValidString(this.editDocumentationForm.name[key])) {
                return false;
            }
        }

        if (!this.editDocumentationForm.fileName
            || !this.editDocumentationForm.category
            || !this.editDocumentationForm.localFileId
            || !this.editDocumentationForm.extension
            || !this.isValidString(this.editDocumentationForm.fileName)) {
            return false;
        }

        return true;
    }

    isValidString(string: string) {
        return this.utilService.stringDoesNotContainSpecialChars(string);
    }

    // function for the ngfor + ngmodel, so it doesn't lose focus
    trackByFn(index, item): number {
        return index;
    }

    clickOnSaveDocumentation() {
        this.loading = true;
        if (this.editDocumentationForm.id) {
            if (this.originalDocumentation
                && this.originalDocumentation.localFileId
                && this.editDocumentationForm.localFileId
                && this.editDocumentationForm.localFileId !== this.originalDocumentation.localFileId) {
                this.fileService.delete(this.originalDocumentation.localFileId).subscribe(() => {
                    this.logger.info('documentation file deleted (replaced by new one)');
                });
            }
            this.documentationService.updateDocumentation(this.editDocumentationForm).subscribe(
                (document) => {
                    this.logger.info('documentation updated');
                    this.loading = false;
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translateService.instant('T_INFO'),
                        detail: this.translateService.instant('T_DOCUMENTATION_UPDATE_SUCCESS'),
                    });
                    this.documentationUpdated.emit(document);
                    this.hide();
                }, (error) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: this.translateService.instant('T_ERROR'),
                        detail: this.translateService.instant('T_DOCUMENTATION_UPDATE_ERROR'),
                    });
                    this.hide();
                }
            )
        } else {
            this.documentationService.createDocumentation(this.editDocumentationForm).subscribe(
                (document) => {
                    this.logger.info('documentation created');
                    this.loading = false;
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translateService.instant('T_INFO'),
                        detail: this.translateService.instant('T_DOCUMENTATION_UPDATE_SUCCESS'),
                    });
                    this.documentationUpdated.emit(document);
                    this.hide();
                }, (error) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: this.translateService.instant('T_ERROR'),
                        detail: this.translateService.instant('T_DOCUMENTATION_UPDATE_ERROR'),
                    });
                    this.hide();
                }
            );
        }
    }

    clickOnCancelEditDocumentation() {
        // on addMode: if a file has been uploaded and the user cancels the modal, the file should be deleted
        if (!this.editDocumentationForm.id && this.editDocumentationForm.localFileId) {
            this.fileService.delete(this.editDocumentationForm.localFileId).subscribe(() => {
                this.logger.info('obsolete documentation file deleted');
            });
        }
        // on editMode we need to compare previous and current file ids and delete the file only if it's different
        if (this.editDocumentationForm.id && this.editDocumentationForm.localFileId !== this.originalDocumentation.localFileId) {
            this.fileService.delete(this.editDocumentationForm.localFileId).subscribe(() => {
                this.logger.info('obsolete documentation file deleted');
            });
        }
        this.hide();
    }

}
