/* angular modules */
import {Injectable} from '@angular/core';
/* app modules*/
import {Resource, Role} from './enum';
import {customers, schneider} from './permissions-definition/';
import {Permission} from './permission';

/**
 * AccessControl that implements RBAC (Role-Based Access Control) basics
 */
@Injectable()
export class AccessControlService {

  private permissions: Permission[] = [...schneider, ...customers];

  constructor() {
  }

  /**
   * Check if role has access to reource
   * @param {Role} role
   * @param {Resource} resource
   * @returns {boolean} true if role has access to resource
   */
  can(role: Role, resource: Resource): boolean {

    // Check if role exists
    const permission = this.permissions.find(p => p.role === role);
    if (!permission) {
      return false;
    }

    // Check if this role has access
    if (permission.can && permission.can.includes(resource)) {
      return true;
    }

    // Check if there are any parents
    if (!permission.inherits || permission.inherits.length < 1) {
      return false;
    }

    // Check child roles until one returns true or all return false
    return permission.inherits.some(childRole => this.can(childRole, resource));
  }

  /**
   * Get all roles
   */
  getRoles(): Role[] {
    // TODO : role update is not completely implemented in backend and
    // update role to DEMO without logout has many side effects
    return this.permissions
      .filter(permission => permission.role !== Role.DEMO_VERSION)
      .map(permission => permission.role);
  }

}
