<div class="section-margin container" #container>
  <div class="row">

    <div class="col-sm-12 col-md-4 offer-image-column">
      <div class="offer-image-div">
        <h1 translate>T_TRANSFORMER_CHARACTERISTICS_TITLE</h1>
        <div class="offer-image-box">
          <div class="offerImageHeader">
            <span class="offer-name">{{currentItemct.range.nameKey | translate}}</span>
          </div>

          <app-element-tile *ngIf="currentItemct.range.rangePicture"
                            [pictureUrl]="currentItemct.range.rangePicture?.url"
                            [pictureFileId]="currentItemct.range.rangePicture?.imageFileId"
                            [clickable]="false"
                            [boxShadow]="false">
          </app-element-tile>
          <span translate class="required-field" *ngIf="!settingsEnded">
            <span class="se-icons icon icon-required">notification_critical</span>
            T_REQUIRED_FIELD
          </span>
          <span translate class="required-field" *ngIf="settingsEnded">
            <span class="se-icons icon icon-validated">notification_ok_stroke</span>
            T_SETTING_ENDED
          </span>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-8">
      <app-spinner *ngIf="loading" [sizeClass]="'large'"></app-spinner>
      <form *ngIf="!loading" class="form-horizontal" role="form">
        <fieldset class="characteristicsFieldset">
          <legend class="shGreenTitle">
            <div class="col-xs-7"><span translate>T_ELECTRICAL_CHARACTERISTICS</span></div>
          </legend>

          <div class="form-horizontal">

            <div class="form-group" *ngFor="let filter of filters">
                <app-select [key]="filter.key" [values]="filter.values" [unit]="filter.measurementUnit" [readonly]="isReadOnlyProject()"
                            (onChangeSelect)="onChangeCharacteristics(filter.key, $event)"></app-select>
            </div>
          </div>
        </fieldset>

        <fieldset *ngIf="selectedComponent && selectedComponent.options.length > 0" class="characteristicsFieldset">
          <legend class="shGreenTitle">
          <div class="col-xs-5"><span translate>T_OPTIONS_TITLE</span>
          </div>
          <div class="col-xs-2 col-xs-offset-5">

            <ng-template #accessInfoTemplate><div class="tooltip-multiline" [innerHtml]="currentItemct.range.accessoriesInformation | translate"></div></ng-template>
            <span *ngIf="currentItemct && currentItemct.range && currentItemct.range.accessoriesInformation" class="se-icons access-info" [tooltip]="accessInfoTemplate"
                  placement="left" container="body" aria-hidden="true">information_circle</span>
          </div>
          </legend>
          <div class="form-horizontal">
            <div class="form-group" *ngFor="let option of selectedComponent.options">
              <div *ngIf="option.values.length > 1">
                <label  class="col-xs-5 control-label" translate>{{option.name}}</label>

                <div class="col-xs-5"  tooltip="{{(option.description? option.description : option.name ) | translate }}" placement="right" container="body">
                    <select  [disabled]="isReadOnlyProject()" class="form-control" (change)="onChangeOption($event.target.value, option)">
                      <ng-container *ngFor="let val of option.values">
                        <option [selected]="val.selected" [value]="val.value" [ngClass]="{'highlighted-selected-option': val.selected}">{{val.value | translate}}</option>
                      </ng-container>
                    </select>
                </div>
              </div>
            </div>
          </div>
        </fieldset>

        <div class="text-center">
          <button type="button" class="btn btn-default-gray-background btn-characteristics"
                  [disabled]="currentItemct.maxNavigationStep<=(transformerSelectorStepNumber) || isReadOnlyProject()"
                  [ngClass]="{'disabled': currentItemct.maxNavigationStep<(transformerSelectorStepNumber)}"
                  (click)="changeTab()" translate>
            T_VALIDATE
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- confirm dialog -->
<app-common-modal #LooseModal [title]="'T_LOOSE_DATA_TITLE' | translate" [name]="'T_LOOSE_DATA_TITLE'"
                  [description]="'T_LOOSE_DATA' | translate" (onClickYesButton)="confirmLooseData()"
                  (onClickNoButton)="reinitData()" [size]="'modal-sm'">
</app-common-modal>
