import {ReferenceBom} from '../../bom/shared/bom-model';
import {Reference} from '../../configuration/shared/model/reference';

export class Packing {
  name: string;
  values?: Array<PackingValue> = null;
}


export class PackingValue {
  size: number;
  reference: Reference;
  quantity: number;
  referenceBom?: ReferenceBom;
}
