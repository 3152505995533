import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {SessionStorage} from 'ngx-webstorage';
import {Item, Project, Status} from '../../../project/shared/project-model';
import {TranslateService} from '@ngx-translate/core';
import {ProjectService} from '../../../project/shared/project.service';
import {User} from '../../../shared/user/user';
import {UtilService} from '../../../shared/util/util.service';
import * as _ from 'lodash';
import {ItemService} from '../../../project/shared/item.service';
import {TreeNode} from "primeng/api";
import {Role} from '../../../core/access-control/enum';
import {ComponentType} from '../../../configuration/shared/model/component-type';
import {SwitchBoardComponent} from '../../../configuration/shared/model/component';
import {MySEStatusEnum} from '../../../shared/model/mySEStatusEnum';
import {RightsService} from '../../../shared/rights/rights.service';
import {RangeType} from '../../../shared/model/range-type';

@Component({
  selector: 'app-bom-table',
  templateUrl: './bom-table.component.html',
  styleUrls: ['./bom-table.component.less']
})
export class BomTableComponent implements OnInit, OnChanges {

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  localization;

  @SessionStorage()
  user: User;

  @Input()
  cols: Array<any>;

  @Input()
  treeData: TreeNode[];

  @Input()
  tableTitle: string;

  @Input()
  loadingBom: boolean;

  @Input()
  expandedNodes: string[];

  @Output()
  generateBomInfos = new EventEmitter<Item>();

  @Output()
  generateProjectBomInfos = new EventEmitter<Item[]>();

  readonly MIN_QUANTITY = 1;

  deliveryMessage;

  indonesiaKey = 'ID';

  isItemBomConfiguration: boolean;

  isProjectBomConfiguration: boolean;

  configuredOrQuotedItemList: Item [];

  constructor(private translateService: TranslateService,
              private projectService: ProjectService,
              private rightsService: RightsService,
              private itemService: ItemService,
              private utilService: UtilService) {
  }


  /**
   * return if the component has some options or not
   * @param component
   * @return has option
   */
  private static hasOptions(component: SwitchBoardComponent): boolean {
    return component.options.some(opt => opt.values.some(val => val.selected === true && val.reference && val.reference.ref !== null));
  }

  /**
   * Check if the right configuration
   * @param nodeDetails
   * @param configuredItem
   */
  private static isRightConfigurationName(nodeDetails, configuredItem): boolean {
    return nodeDetails.parent && nodeDetails.parent.parent && nodeDetails.parent.parent.data && nodeDetails.parent.parent.data.configuration === configuredItem.name;
  }

  ngOnInit() {
    this.deliveryMessage = this.setDeliveryMessage(this.user.role);
    this.itemService.getItems(this.currentProject.id).subscribe(items => {
      this.configuredOrQuotedItemList = items.filter(item => Status.configured === item.status || Status.quoted === item.status);
      this.expandTable(1, 1, items);
    });
    this.isItemBomConfiguration = this.currentItemct &&
      (this.currentItemct.components.length > 0 ||
        (this.currentItemct.range &&
          (this.currentItemct.range.rangeType === RangeType.ACCESSORIES || this.currentItemct.range.rangeType === RangeType.FAKE)));
    this.isProjectBomConfiguration = this.currentProject && this.currentProject.itemCT.length > 0;
  }


  ngOnChanges(): void {
    this.itemService.getItems(this.currentProject.id).subscribe(items => {
      this.configuredOrQuotedItemList = items.filter(item => Status.configured === item.status || Status.quoted === item.status);
    });

    this.filterPartnerDiscountCols();
  }

  filterPartnerDiscountCols() {
    // filter out public price and partnerDiscount columns when there is no discount
    let hasPartnerDiscount = false;
    this.treeData.forEach(treeNode => {
      treeNode.children.forEach(childNode => {
        if(childNode.data.partnerDiscount && childNode.data.partnerDiscount !== '0.00%'){
          hasPartnerDiscount = true;
        }
        childNode.children.forEach(grandChildNode => {
          if(grandChildNode.data.partnerDiscount && grandChildNode.data.partnerDiscount !== '0.00%'){
            hasPartnerDiscount = true;
          }
        })
      })
    })
    if (!hasPartnerDiscount) {
      this.cols = this.cols?.filter(col => col.field !== 'partnerDiscount');
      if(this.user.currentMySEAccount == null){
        this.cols = this.cols?.filter(col => col.field !== 'netPrice');
      }
    }
  }

  storeExpandedNodes(event) {
    if (event.node.data.category !== null && event.node.data.category !== undefined) {
      this.expandedNodes.push(event.node.data.category);
    } else if (event.node.data.configuration !== null && event.node.data.configuration !== undefined) {
      this.expandedNodes.push(event.node.data.configuration);
    }
  }

  removeExpandedNodes(event) {
    const index = this.expandedNodes.findIndex(node => node === event.node.data.category || node === event.node.data.configuration);
    if (index !== -1) {
      this.expandedNodes.splice(index, 1);
    }
  }

  /**
   * Method used to find the 'delivery' column
   * @param fieldHeader current header of current column
   * @returns {boolean}
   */
  public isDeliveryHeader(fieldHeader): boolean {
    return 'T_BOM_DELIVERY_TIME' === fieldHeader;
  }

  public isTotalPriceColumnAndExtendedDemo(fieldHeader): boolean {
    return 'T_BOM_TOTAL_PRICE' === fieldHeader && this.rightsService.isExtendedDemo();
  }

  /**
   * Check the new data model
   * @param fieldHeader
   * @param nodeDetails
   * @param fieldValue
   */
  public hasQuantityManagement(fieldHeader: string, nodeDetails, fieldValue): boolean {
    // To check that we have a new data model, can't change quantity of options
    /* Condition : on quantity comlumn with not empty quantity, on a SwitchboardComponent during configuration
     and the quantity edition is forbidden for options*/
    return fieldHeader === 'count' && (fieldValue !== null && fieldValue !== undefined)
      && ((this.isItemBomConfiguration && this.currentItemct.status === Status.quoted) || this.isConfiguredItemInCurrentProject(nodeDetails))
      && nodeDetails.parent.data.category !== this.translateService.instant('T_BOM_CUBICLE_OPTIONS')
      && nodeDetails.parent.data.category !== this.translateService.instant('T_BOM_TRANSFORMER_OPTIONS')
      && nodeDetails.parent.data.category !== this.translateService.instant('T_PACKING')
      && !(nodeDetails.parent.data.category === this.translateService.instant('T_BOM_SERVICES') && !this.isEditableQuantityService(nodeDetails))
      && !this.projectService.isReadOnlyProject(this.currentProject, this.user)
      && nodeDetails.data.editableQuantity;
  }

  /**
   * Check the new data model
   * @param fieldHeader
   * @param nodeDetails
   * @param fieldValue
   */
  public isDeletedReference(fieldHeader: string, nodeDetails, fieldValue): boolean {
    return fieldHeader === 'ref' && fieldValue !== null && fieldValue !== undefined
      && nodeDetails.data.businessStatus !== undefined
      && nodeDetails.data.businessStatus === 'DECOMMISSIONED';
  }


  /**
   * Subtract quantity for a component, accessory, ...
   * @param nodeDetails
   */
  public subtractQuantity(nodeDetails) {
    if (nodeDetails.data.count > this.MIN_QUANTITY) {
      nodeDetails.data.count--;
      if (this.isItemBomConfiguration) {
        this.updateItemBom(nodeDetails, this.currentItemct);
      } else if (this.isProjectBomConfiguration) {
        const item: Item = this.findRightItem(nodeDetails, this.configuredOrQuotedItemList);
        if (item != null) {
          this.updateProjectBom(nodeDetails, item);
        }
      }
    }
  }

  /**
   * Get a css for a specific cell
   * @param fieldHeader
   * @param nodeDetails
   * @param fieldValue
   * @param fieldName
   */
  public getCssForCell(fieldHeader, nodeDetails, fieldValue, fieldName): string {
    let cssValue = '';
    // Net and public price
    if ((nodeDetails.data.ref && (fieldHeader === 'T_BOM_UNIT_PRICE' ||  fieldHeader === 'T_BOM_TOTAL_PRICE'|| fieldHeader === 'T_BOM_UNIT_NET_PRICE' ||  fieldHeader === 'T_BOM_TOTAL_NET_PRICE')
      && (!this.isValidMySEPriceFromStatusAndColumn(fieldValue,  nodeDetails.data.mySeNetPriceStatus, fieldHeader)
      || this.isInvalidFoNetPriceColumn(nodeDetails.data.mySeNetPriceStatus, fieldValue, fieldName)))
        || (this.isInvalidDeliveryTimeColumn(fieldHeader, fieldValue, nodeDetails))
        || this.isInvalidPublicPrice(fieldHeader, fieldValue)) {
      return 'se-icons se-icon-notification_critical_stroke brand-warning';
    }

    if (this.isDeliveryHeader(fieldHeader) && !fieldValue) {
      if (nodeDetails.parent !== null && nodeDetails.parent.data.category === this.translateService.instant('T_PACKING')) {
        return cssValue;
      }
      cssValue = 'se-icons se-icon-notification_critical_stroke brand-warning';
    }
    if ((nodeDetails.data.category === this.translateService.instant('T_BOM_ACCESSORIES') && !nodeDetails.parent)
      || nodeDetails.children.length > 0) {
      cssValue = 'font-weight: bold';
    }
    if (fieldHeader === 'T_BOM_CONFIGURATION' && nodeDetails.data.configuration) {
      cssValue = 'configuration-column';
    }
    return cssValue;
  }


  /**
   * Tooltip message for a specific column
   * @param fieldHeader
   * @param nodeDetails
   * @param fieldValue
   */
  public getTooltipMessage(fieldHeader, nodeDetails, fieldValue, fieldName): string {
    if (this.user.currentMySEAccount && this.utilService.displayMySENetPrice() && (fieldHeader === 'T_BOM_UNIT_PRICE' || fieldHeader === 'T_BOM_TOTAL_PRICE') &&
      !this.isValidMySEPriceFromStatusAndColumn(fieldValue, nodeDetails.data.mySeNetPriceStatus, fieldHeader)) {
      return this.utilService.getTooltipOnTotalMySEMessage(nodeDetails.data.mySeNetPriceStatus);
    }
    if (this.isDeliveryHeader(fieldHeader) && !fieldValue) {
        return this.translateService.instant('T_BOM_MORE_INFORMATION');
    }
    if((nodeDetails.data.ref && (fieldHeader === 'T_BOM_UNIT_PRICE' ||  fieldHeader === 'T_BOM_TOTAL_PRICE'|| fieldHeader === 'T_BOM_UNIT_NET_PRICE' ||  fieldHeader === 'T_BOM_TOTAL_NET_PRICE')
        && (!this.isValidMySEPriceFromStatusAndColumn(fieldValue, nodeDetails.data.mySeNetPriceStatus, fieldHeader)
          || this.isInvalidFoNetPriceColumn(nodeDetails.data.mySeNetPriceStatus, fieldValue, fieldName)))
      || (this.isInvalidDeliveryTimeColumn(fieldHeader, fieldValue, nodeDetails))
      || this.isInvalidPublicPrice(fieldHeader, fieldValue)) {
      return this.translateService.instant('T_BOM_MISSING_PRICE');
    }
    return '';
  }

  /**
   * Get cell value
   * @param fieldHeader
   * @param nodeDetails
   * @param fieldValue
   */
  public getCellValue(fieldHeader, nodeDetails, fieldValue): string {
    if(fieldValue == 'containsNullPrices'){
      return '';
    }

    if (this.user.currentMySEAccount && !this.isValidMySEPriceFromStatusAndColumn(fieldValue, nodeDetails.data.mySeNetPriceStatus, fieldHeader)) {
      return '';
    }

    return ['T_BOM_DELIVERY_TIME', 'T_BOM_UNIT_PRICE', 'T_BOM_TOTAL_PRICE'].includes(fieldHeader)
    && (fieldValue === undefined || fieldValue === null || fieldValue === 0 || fieldValue === '0.00') ? '' : fieldValue;
  }
  /**
   * Check if the column is the toggle column
   * We need to place label under the category column
   * @param fieldHeader
   * @param field
   * @param nodeDetails
   */
  public isToggleColumn(field): boolean {
    return field === 'category' && this.isItemBomConfiguration || field === 'configuration' && this.isProjectBomConfiguration;
  }

  /**
   * Check if the configuration column exist (project Bom case)
   * We need to place label under the category column
   * @param fieldHeader
   * @param nodeDetails
   */
  public hasConfigurationColumn(fieldHeader, nodeDetails): boolean {
    return fieldHeader === 'T_BOM_CATEGORY' && nodeDetails && nodeDetails.configuration;
  }

  /**
   * Check if we should display discount label
   * We need to place label under the delivery time column
   * @param fieldHeader
   * @param nodeDetails
   */
  public displayDiscount(fieldHeader, nodeDetails): boolean {
    return fieldHeader === 'T_BOM_DELIVERY_TIME' && nodeDetails && nodeDetails.configuration && nodeDetails.discount && this.rightsService.canApplyDiscount();
  }

  /**
   * Add quantity for a component, accessory, ...
   * @param nodeDetails
   */
  public addQuantity(nodeDetails) {
    nodeDetails.data.count++;
    if (this.isItemBomConfiguration) {
      this.updateItemBom(nodeDetails, this.currentItemct);
    } else if (this.isProjectBomConfiguration) {
      const item: Item = this.findRightItem(nodeDetails, this.configuredOrQuotedItemList);
      if (item != null) {
        this.updateProjectBom(nodeDetails, item);
      }
    }
  }

  public isCPQProject(): boolean {
    return this.projectService.isCpqProject(this.currentProject);
  }

  /**
   * Method to know if we have a valid Fo net price
   * @param status
   * @param fieldValue
   * @param fieldName
   */
  private isInvalidFoNetPriceColumn(status, fieldValue, fieldName) {
      return (fieldName === 'netPrice' || fieldName === 'totalNetPrice')
          && status === MySEStatusEnum.NOT_APPLICABLE
          && (fieldValue === null || fieldValue === 'containsNullPrices');
  }

  /**
   * Method to know if we have a valid deliveryTime
   * @param fieldValue
   * @param fieldHeader
   */
  private isInvalidDeliveryTimeColumn(fieldHeader, fieldValue, nodeDetails) {
    return this.isDeliveryHeader(fieldHeader) && nodeDetails.children == [] && (fieldValue === undefined || fieldValue === null || fieldValue === '0.00' || fieldValue === 0);
  }

  private isInvalidPublicPrice(fieldHeader, fieldValue){
    return (fieldHeader === 'T_BOM_UNIT_PRICE' || fieldHeader === 'T_BOM_TOTAL_PRICE') && (fieldValue === null || fieldValue === 'containsNullPrices');
  }

  /**
   * Check the item status
   * @param nodeDetails
   */
  private isConfiguredItemInCurrentProject(nodeDetails): boolean {
    const item: Item = this.findRightItem(nodeDetails, this.configuredOrQuotedItemList);
    return this.isProjectBomConfiguration && item != null && (Status.configured === item.status || Status.quoted === item.status);
  }

  /**
   * Return true if the price with the MySE status in parameter can be displayed, regarding the column header
   * Else return false
   * @param fieldValue value of current field
   * @param myNetPriceSEItemStatus
   * @param fieldHeader Translation of header
   * @returns {boolean}
   */
  private isValidMySEPriceFromStatusAndColumn(fieldValue, myNetPriceSEItemStatus: MySEStatusEnum, fieldHeader): boolean {
    if (!this.user.currentMySEAccount) {
      return true;
    }
    if (['T_BOM_DISCOUNTED_PRICE', 'T_BOM_UNIT_NET_PRICE', 'T_BOM_TOTAL_NET_PRICE'].includes(fieldHeader) &&
      !UtilService.isValidMySEPriceFromStatus(myNetPriceSEItemStatus) || fieldValue === null || fieldValue === 'containsNullPrices') {
      return false;
    }

    return !(['T_BOM_UNIT_PUBLIC_PRICE', 'T_BOM_UNIT_PRICE', 'T_BOM_TOTAL_PRICE'].includes(fieldHeader)
       || fieldValue === null || fieldValue === 'containsNullPrices');
  }

  /**
   * Update de BOM for the current item
   * @param nodeDetails
   * @param item: current item
   */
  private updateItemBom(nodeDetails, item: Item) {
    this.loadingBom = true;
    this.setQuantityToItem(nodeDetails, item);
    this.itemService.updateItem(item, this.user)
      .subscribe(res => {
        this.currentItemct = res;
        this.generateBomInfos.emit(this.currentItemct);
        this.loadingBom = false;
      });
  }

  /**
   * Update current project BOM
   * @param nodeDetails
   * @param item: item from the current project
   */
  private updateProjectBom(nodeDetails, item: Item) {
    this.loadingBom = true;
    this.setQuantityToItem(nodeDetails, item);
    this.itemService.updateItem(item, this.user, false)
      .subscribe(res => {
        if (res) {
          this.itemService.getItems(this.currentProject.id).subscribe(items => {
            this.generateProjectBomInfos.emit(items);
            this.loadingBom = false;
          });
        }
      });
  }

  /**
   * Set the quantity on the item
   * @param nodeDetails
   * @param item
   */
  private setQuantityToItem(nodeDetails, item: Item) {

    item.components.filter(component => component && component.quantityId === nodeDetails.data.quantityId && (!!item.range || component.reference.ref === nodeDetails.data.ref))
      .forEach(component => component.quantity = nodeDetails.data.count);

    item.accessories.filter(acc => acc.id === nodeDetails.data.id && acc.reference && acc.reference.ref === nodeDetails.data.ref)
      .forEach(acc => acc.quantity = nodeDetails.data.count);

    _.flatMap(item.components,
      component => component.functionalUnits && component.functionalUnits.map(fu => fu.transformer))
      .filter(transformer => transformer && transformer.quantityId === nodeDetails.data.quantityId)
      .forEach(transformer => transformer.quantity = nodeDetails.data.count);

    item.selectedServices.filter(service => service.reference.ref === nodeDetails.data.ref)
      .forEach(service => service.quantity = nodeDetails.data.count);
  }

  /**
   * Set delivery message
   * @param userRole
   */
  private setDeliveryMessage(userRole) {
    let messageKey = 'T_DEFAULT_DELIVERY_MESSAGE';
    if (this.user.partnerCountry === this.indonesiaKey) {
      if (userRole === Role.CONTRACTOR_INDIRECT) {
        messageKey = 'T_CONTRACTOR_INDIRECT_DELIVERY_MESSAGE';
      } else if ((userRole === Role.RESELLER) || (userRole === Role.CONTRACTOR)) {
        messageKey = 'T_RESELLER_CONTRACTOR_DELIVERY_MESSAGE';
      }
    }
    return this.translateService.instant(messageKey);
  }

  /**
   * Find the item that has been modified. The modification concerns the change of quantity
   * @param nodeDetails
   * @param itemList: current project item list
   */
  private findRightItem(nodeDetails, itemList: Item[]): Item {
    let item: Item = null;
    // component case
    if (nodeDetails && itemList && itemList.length > 0) {
      itemList.forEach(configuredItem => {
        if (BomTableComponent.isRightConfigurationName(nodeDetails, configuredItem)) {
          configuredItem.components.forEach(component => {
            if (component.reference && component.reference.ref === nodeDetails.data.ref) {
              item = configuredItem;
            }
            // transformer on component case
            if (component.functionalUnits && component.functionalUnits.length > 0) {
              component.functionalUnits.forEach(functionalUnit => {
                if (functionalUnit.transformer && functionalUnit.transformer.reference
                  && functionalUnit.transformer.reference.ref === nodeDetails.data.ref) {
                  item = configuredItem;
                }
              });
            }
          });
        }
      });
      // accessory case
      itemList.forEach(configuredItem => {
        if (BomTableComponent.isRightConfigurationName(nodeDetails, configuredItem)) {
          configuredItem.accessories.forEach(accessorie => {
            if (accessorie.reference && accessorie.reference.ref === nodeDetails.data.ref) {
              item = configuredItem;
            }
          });
        }
      });

      // catalog services case
      itemList.forEach(configuredItem => {
        if (BomTableComponent.isRightConfigurationName(nodeDetails, configuredItem)) {
          configuredItem.selectedServices.forEach(selectedService => {
            if (selectedService.reference && selectedService.reference.ref === nodeDetails.data.ref) {
              item = configuredItem;
            }
          });
        }
      });
    }
    return item;
  }

  /**
   * Check elements to expand table or not (1 component on 1 item)
   */
  private expandTable(maxItems: number, maxComponents: number, items: Item[]) {
// Expand node if only one item
    if (this.currentItemct && this.currentItemct.components.length <= maxComponents) {
      this.currentItemct.components.forEach((component, index) => this.expandComponent(component, index + 1));
      if (this.isItemBomConfiguration) {
        this.generateBomInfos.emit(this.currentItemct);
      }
    }
    if (this.currentItemct !== null && this.currentItemct !== undefined
      && this.currentProject.itemCT.length <= maxItems
      && items.some(item => item.components.length > maxComponents)) {
      this.currentProject.itemCT.forEach(item => this.expandedNodes.push(item.name));
      items.forEach(item => item.components.forEach((component, index) => this.expandComponent(component, index + 1)));
      if (this.isProjectBomConfiguration) {
        this.generateProjectBomInfos.emit(items);
      }
    }
  }

  /**
   * Add on current expand node the component
   * @param component
   * @param index
   */
  private expandComponent(component: SwitchBoardComponent, index: number) {
    if (component === null) {
      return;
    }
    if (component.type === ComponentType.TRANSFORMER) {
      this.expandedNodes.push(this.translateService.instant('T_BOM_TRANSFORMER'));
      if (BomTableComponent.hasOptions(component)) {
        this.expandedNodes.push(this.translateService.instant('T_BOM_TRANSFORMER_OPTIONS'));
      }
    } else if (component.type === ComponentType.CUBICLE) {
      this.expandedNodes.push(this.translateService.instant(index + '- ' + component.name));
      if (BomTableComponent.hasOptions(component)) {
        this.expandedNodes.push(this.translateService.instant('T_BOM_CUBICLE_OPTIONS'));
      }
    }
  }

  /**
   * return if this service quantity can be edited
   * @param treeNode information about the service
   */
  private isEditableQuantityService(treeNode: TreeNode): boolean {
    let item: Item;
    if (this.isItemBomConfiguration) {
      item = this.currentItemct;
    } else {
      item = this.findRightItem(treeNode, this.configuredOrQuotedItemList);
    }

    return !!item.packageOfferId || item.range.services.find(serv => serv.reference === treeNode.data.ref).editableQuantity;
  }
}
