<div class="container offer-publication">
  <!-- Offer card -->
  <app-element-tile [readOnly]="false"
                    [name]="selectedRange.nameKey"
                    [pictureUrl]="selectedRange.rangePicture?.url"
                    [picturesAssetsPath]="selectedRange.imageKey">
  </app-element-tile>
  <!--Button-->
  <div class="offer-button">
    <div class="button-wrap">
      <div class="label-div" translate>T_OFFER_DATA_MANAGEMENT_RANGE_TEST_INFORMATION</div>
      <div class="button-div">
        <button  type="button" class="btn btn-group test-button" (click)="selectedRange.status === 'CONFIGURED' ? showModal('TEST') : refreshOfferInfo()" [disabled]="selectedRange.status === 'PUBLISHED'" translate>
          {{selectedRange.status === 'TEST' ? 'T_OFFER_DATA_MANAGEMENT_REFRESH_RANGE_INFO' : 'T_OFFER_DATA_MANAGEMENT_RANGE_TEST'}}
        </button>
      </div>
    </div>

    <div class="button-wrap">
      <div class="label-div" translate>T_OFFER_DATA_MANAGEMENT_RANGE_PUBLICATION_INFORMATION</div>
      <div class="button-div">
        <button  type="button" class="btn btn-group publish-button" (click)="showModal('PUBLISH')"
                 [disabled]="selectedRange.status !== 'TEST'"
                 translate>T_OFFER_DATA_MANAGEMENT_RANGE_PUBLICATION</button>
      </div>
    </div>
  </div>
</div>

<app-common-modal #testOfferModal [title]="'T_OFFER_DATA_MANAGEMENT_RANGE_TEST' | translate" [name]="'T_OFFER_DATA_MANAGEMENT_RANGE_TEST'"
                  [description]="'T_OFFER_DATA_MANAGEMENT_TEST_OFFER_CONFIRM' | translate" (onClickYesButton)="testButton()" [size]="'modal-default'">
</app-common-modal>

<app-common-modal #publishOfferModal [title]="'T_OFFER_DATA_MANAGEMENT_RANGE_PUBLICATION' | translate" [name]="'T_OFFER_DATA_MANAGEMENT_RANGE_PUBLICATION'"
                  [description]="'T_OFFER_DATA_MANAGEMENT_PUBLISH_OFFER_CONFIRM' | translate" (onClickYesButton)="publishButton()" [size]="'modal-default'">
</app-common-modal>
