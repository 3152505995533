import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {PartnerDiscount, Range} from '../../../shared/model/range-model';
import {OfferService} from '../../../offers/shared/offer.service';
import {User} from '../../../shared/user/user';
import {SessionStorage, SessionStorageService} from 'ngx-webstorage';
import {PartnerService} from '../../../shared/partner/partner.service';
import {TranslateService} from '@ngx-translate/core';
import {CommonModalComponent} from '../../../shared/common-modal/common-modal.component';
import {PriceManagementService, RangeDiscount, UploadService} from '../../shared';
import {Item, Project} from '../../../project/shared/project-model';
import {SwitchBoardComponent} from '../../../configuration/shared/model/component';
import {LoggerService} from "../../../shared/logging/logger.service";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-net-price-form',
  templateUrl: './net-price-form.component.html',
  styleUrls: ['./net-price-form.component.less']
})
export class NetPriceFormComponent implements OnInit {

  @ViewChild('netPriceDiscountModal') netPriceDiscountModal: CommonModalComponent;

  @ViewChild('exeedDiscountModal') exeedDiscountModal: CommonModalComponent;

  @Output()
  netPricesUpdatesStart = new EventEmitter<number>();

  @Output()
  netPricesUpdatesDone = new EventEmitter();

  @Output()
  netPricesUpdatesFail = new EventEmitter<Error>();

  @SessionStorage()
  user: User;

  @SessionStorage()
  netPriceByPartnerDiscount: RangeDiscount[];

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  filteredComponents: SwitchBoardComponent[];

  @SessionStorage()
  allComponents: SwitchBoardComponent[];

  ranges: Range[] = [];
  partnerOfCountry: string[] = [];

  netPriceSelectedPartner: string;
  netPriceSelectedRange: Range;
  netPricesDiscount: number|string;
  maxDiscountByPartner = 0;

  rangeDiscountList: [Range, string|number][] = [];

  modalSaveButtonEnabled = false;
  maxDiscountAllowed: number;
  loading:boolean;

  readonly MIN_DISCOUNT_ALLOWED = 0;
  readonly MAX_DISCOUNT_ALLOWED = 99;
  readonly IMPORT_OK_RESPONSE = 'IMPORT-OK';

  constructor(private partnerService: PartnerService,
              private offerService: OfferService,
              private translateService: TranslateService,
              private logger: LoggerService,
              private uploadService: UploadService,
              private messageService: MessageService,
              private sessionStorageService: SessionStorageService,
              private priceManagementService: PriceManagementService) { }

  ngOnInit() {
    this.getRanges();
  }

  getRanges() {
    this.loading = true;
    this.offerService.getRangesBackOffice().subscribe(ranges => {
      this.ranges = ranges.filter(range => range.status === 'PUBLISHED');
      this.maxDiscountAllowed = this.ranges[0].priceManagement.discountAllowed;
      this.ranges.sort((a , b) => this.translateService.instant(a.nameKey).localeCompare(this.translateService.instant(b.nameKey)));

      this.updateMaxDiscountByPartner();
      this.partnerService.getAllPartnerNameForCountry(this.user.partnerCountry).subscribe(partners => {
        this.partnerOfCountry = partners;
        this.partnerOfCountry.sort();
        this.refreshNetPriceDiscount();
        this.loading = false;
      });
    });
  }

  /**
   * Method to update all discount for all ranges for a partner
   */
  updateNetPricesDiscountForAllRangesPartner() {
    this.netPricesUpdatesStart.emit(this.rangeDiscountList.length);
    this.rangeDiscountList.forEach(discount => {
      const partnerDiscount = new PartnerDiscount();
      partnerDiscount.partnerName = this.netPriceSelectedPartner;
      partnerDiscount.discount = Number(discount[1]) / 100;

      this.offerService.updateRangePartnerDiscount( discount[0].nameKey, partnerDiscount).subscribe(rangeToUpdate => {
        const index = this.ranges.findIndex(range => range.nameKey === rangeToUpdate.nameKey);
        if (index !== -1) {
          this.ranges[index] = rangeToUpdate;
          this.updateMaxDiscountByPartner();
        }
        this.netPricesUpdatesDone.emit();
      }, (error => {
        this.netPricesUpdatesFail.emit(error);
      }));
    });
    this.netPriceSelectedRange = null;
    this.netPricesDiscount = null;
    this.refreshNetPriceDiscount();
    this.clearWebStorage();
    this.modalSaveButtonEnabled = false;
  }

  /**
   * Method to update the net prices discount of the selected partner for the selected range
   */
  updateNetPricesDiscountForPartner() {
    this.netPricesUpdatesStart.emit(1);

    const partnerDiscount = new PartnerDiscount();
      partnerDiscount.partnerName = this.netPriceSelectedPartner;
      partnerDiscount.discount = (Number(this.netPricesDiscount) / 100).toFixed(4);

      this.offerService.updateRangePartnerDiscount(this.netPriceSelectedRange.nameKey, partnerDiscount).subscribe(rangeToUpdate => {
        const index = this.ranges.findIndex(range => range.nameKey === rangeToUpdate.nameKey);
        if (index !== -1) {
          this.ranges[index] = rangeToUpdate;
          this.netPriceSelectedRange = rangeToUpdate;
          this.updateMaxDiscountByPartner();
        }
        this.clearWebStorage();
        this.netPricesUpdatesDone.emit();
      }, (error => {
        this.netPricesUpdatesFail.emit(error);
      }));
  }

  /**
   * Method on change of Range or Partner for netPrices discount form
   * Refresh the discount field with current value
   */
  refreshNetPriceDiscount() {
    if (!this.invalidPartnerOrRangeSelected()) {
      const partnerDiscount = this.netPriceSelectedRange.partnerDiscounts.find(disc => disc.partnerName === this.netPriceSelectedPartner);

      this.netPricesDiscount = partnerDiscount ? (Number(partnerDiscount.discount) * 100).toFixed(2) : 0;
    }
  }

  /**
   * Discount validation
   * @param value of the new discount
   */
  public onChangePartnerDiscount(value) {
    value = this.processDiscountValue(value);

    this.netPricesDiscount = value;
  }

  /**
   * Discount validation for modal
   * @param discount holding the discount
   * @param value of the new discount
   */
  public onChangePartnerDiscountList(discount: [Range, number], value: number) {

    this.modalSaveButtonEnabled = true;

    value = this.processDiscountValue(value);

    // Set new discount in range
    const rangeDiscount = discount[0].partnerDiscounts.find(disc =>  disc.partnerName === this.netPriceSelectedPartner);
    if (!rangeDiscount) {
      const partnerDiscount = new PartnerDiscount();
      partnerDiscount.partnerName = this.netPriceSelectedPartner;
      partnerDiscount.discount = value / 100;
      discount[0].partnerDiscounts.push(partnerDiscount);
      discount[1] = value;
    } else {
      rangeDiscount.discount = value / 100;
      discount[1] = value;
    }

    // Update discount on selected range
    if (discount[0] === this.netPriceSelectedRange) {
      this.netPricesDiscount = value;
    }
  }

  /**
   * Method to display discount configuration modal
   */
  showNetPriceDiscountModal() {
    this.refreshRange();
    this.netPriceDiscountModal.show();
  }

  /**
   * Method called when the partner in modal is changed or when modal is shown
   */
  refreshRange() {
    this.rangeDiscountList = [];
    this.ranges.forEach(range => {
      const partnerDiscount = range.partnerDiscounts.find(discount => discount.partnerName === this.netPriceSelectedPartner);
      this.rangeDiscountList.push([range, partnerDiscount !== undefined ? (Number(partnerDiscount.discount) * 100).toFixed(2) : 0]);
    });
  }

  fileUploader(event) {
    this.logger.info('upload package file');
    this.loading = true;
    const files = event.target.files;
    if (files.length > 0) {
      if (files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_NET_PRICE_DISCOUNT_IMPORT_FILE_TYPE_TITLE'),
          detail: this.translateService.instant('T_NET_PRICE_DISCOUNT_IMPORT_FILE_TYPE_MESSAGE'),
        });
        this.loading = false;
        return;
      }
      const formData: FormData = new FormData();
      formData.append('file', files[0]);
      const uploadUrl: string = '/price/net-price-partners/upload';
      this.loading = true;
      this.uploadService.uploadFile(uploadUrl, formData).subscribe(importResponse => {
        this.loading = false;
        if (importResponse.includes(this.IMPORT_OK_RESPONSE)) {
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('T_NET_PRICE_DISCOUNT_IMPORT_SUCCESS_TITLE'),
            detail: this.translateService.instant('T_NET_PRICE_DISCOUNT_IMPORT_SUCCESS_MESSAGE'),
          });
          // reload discount
          this.getRanges();
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_NET_PRICE_IMPORT_ERROR_TITLE'),
            detail: this.translateService.instant('T_NET_PRICE_IMPORT_ERROR_MESSAGE'),
          });
          this.logger.info('download discount file report');
          const downloadUrl: string = '/price/report';
          importResponse = importResponse.trim();
          importResponse = importResponse.substring(1,importResponse.length-1);
          this.uploadService.downloadReport(downloadUrl, importResponse).subscribe(
            response => {
              this.uploadService.createXlsFileFromBackendResponse(response);
            }
          );
        }
        this.loading = false;
      }, () => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_PRICE_IMPORT_ERROR_TITLE'),
          detail: this.translateService.instant('T_PRICE_IMPORT_ERROR_MESSAGE'),
        });
        this.loading = false;
      });
    }
    // clean files in event
    event.target.value = null;

  }

  fileExport() {
    this.logger.info('Export net price by partner');
    this.loading = true;
    this.uploadService.exportNetPricePartnersFile().subscribe(
      response => {
        this.loading = false;
        this.uploadService.createXlsFileFromBackendResponse(response);
      }, () => {
        this.loading = false;
      }
    );
  }

  /**
   * Method to close modal
   */
  closeModal() {
    this.netPriceDiscountModal.hide();
    this.getRanges();
  }

  /**
   * Return true if partner selected or range selected is invalid
   */
  invalidPartnerOrRangeSelected() {
    return !this.netPriceSelectedRange || !this.netPriceSelectedPartner;
  }

  /**
   * Displays warning modal if discount is higher than max discount
   */
  private showExceedDiscountModal() {
    this.exeedDiscountModal.title = this.translateService.instant('T_PRICE_MANAGEMENT_DISCOUNT_EXEED_TITLE');
    this.exeedDiscountModal.description = this.translateService.instant('T_PRICE_MANAGEMENT_DISCOUNT_EXEED' , {maxDiscount: this.maxDiscountAllowed});
    this.exeedDiscountModal.show();
  }

  /**
   * Method to clear webstorage and display new price to the current user
   */
  private clearWebStorage() {
    this.currentProject = null;
    this.currentItemct = null;
    this.allComponents = [];
    this.filteredComponents = [];
    this.priceManagementService.getNetPriceDiscountForPartner().subscribe(discounts => {
      this.netPriceByPartnerDiscount = discounts;
      this.sessionStorageService.store('netPriceByPartnerDiscount', discounts);
    });
  }

  /**
   * Method to validate the inputted value of a discount
   * @param value of discount
   */
  private processDiscountValue(value): number {
    if (value > this.MAX_DISCOUNT_ALLOWED) {
      value = this.MAX_DISCOUNT_ALLOWED;
    } else {
      if (value < this.MIN_DISCOUNT_ALLOWED || value === '') {
        value = this.MIN_DISCOUNT_ALLOWED;
      }
    }
    if (value > this.maxDiscountAllowed) {
      value = this.maxDiscountAllowed;
      this.showExceedDiscountModal();
    }
    value = Number(Number(value).toFixed(2));
    return value;
  }

  /**
   * Calculate the maximum discount allowed for all partner for all ranges
   */
  private updateMaxDiscountByPartner() {
    let maxDiscount = 0;
    this.ranges.forEach(
      range => {
        range.partnerDiscounts.forEach(
          partnerDiscount => {
            if (partnerDiscount) {
              const currentDiscount = Number(partnerDiscount.discount);
              if (currentDiscount > maxDiscount) {
                maxDiscount = currentDiscount;
              }
            }
          }
        );
      }
    );
    if (this.maxDiscountByPartner !== maxDiscount) {
      this.maxDiscountByPartner = maxDiscount;
    }
  }
}
