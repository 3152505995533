<div>
    <h1 class="offer-selection-title" translate>T_CHOOSE_AN_OFFER_TO_MODIFY_OR_TO_ADD</h1>
    <div *ngIf="ranges">
        <se-tabbar option="content">
            <nav>
                <span *ngFor="let tab of getTabs()" [ngClass]="{'active':tab.active}" (click)="selectTab(tab)" translate>{{tab.title}}</span>
            </nav>
        </se-tabbar>
        <div class="offer-selection-container" *ngIf="isTabActive(1)">
            <app-odm-list-offers
                    [titleHeader]="'T_MY_CURRENT_RANGES'"
                    [rangesObservable]="getStandardRanges()"
                    [orderList]="true"
                    [showImportExportButtons]="false"
                    (onClickOnComponentManagement)="goToComponentManagement($event)"
                    (onClickOnRangeConfiguration)="goToRangeConfiguration($event)"
                    (onClickOnAccessoriesManagement)="goToAccessoriesManagement($event)"
                    (onClickOnOptionsManagement)="goToOptionsManagement($event)"
                    (onChangeRangeOrder)="updateRangeOrder($event, 'STANDARD')"
                    (onDecommissionRange)="decommissionRange($event)"
                    (saveRangeInfo)="onSaveRangeInfo($event)">
            </app-odm-list-offers>
        </div>

        <div class="offer-selection-container" *ngIf="isTabActive(2)">
            <app-odm-list-offers
                    [titleHeader]="'T_MY_IMPORTED_RANGES'"
                    [rangesObservable]="getImportedRanges()"
                    [orderList]="true"
                    [loadingImportExport]="loadingImportExport"
                    [showImportExportButtons]="true"
                    (onClickOnComponentManagement)="goToComponentManagement($event)"
                    (onClickOnRangeConfiguration)="goToRangeConfiguration($event)"
                    (onClickOnAccessoriesManagement)="goToAccessoriesManagement($event)"
                    (onClickOnOptionsManagement)="goToOptionsManagement($event)"
                    (onChangeRangeOrder)="updateRangeOrder($event, 'IMPORTED')"
                    (onDecommissionRange)="decommissionRange($event)"
                    (onClickRangeImport)="rangeImport($event)"
                    (onClickRangeExport)="rangeExport($event)"
                    (saveRangeInfo)="onSaveRangeInfo($event)">
            </app-odm-list-offers>
        </div>

        <div class="offer-selection-container" *ngIf="isTabActive(3)">
            <app-odm-list-offers
                    [titleHeader]="'T_RANGES_AVAILABLE_FOR_MY_COUNTRY'"
                    [rangesObservable]="availableRanges"
                    [orderList]="false"
                    [showImportExportButtons]="false"
                    (onClickOnRangeAddition)="goToRangeAddition($event)">
            </app-odm-list-offers>
        </div>
    </div>

    <!-- LOADING SPINNER -->
    <app-spinner *ngIf="!ranges" [sizeClass]="'large'"></app-spinner>
</div>
