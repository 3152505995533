import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SessionStorage} from 'ngx-webstorage';
import {User} from '../../../../shared/user/user';
import {Range} from '../../../../shared/model/range-model';
import {OptionsService} from '../../../../shared/services/options.service';
import {CubicleOption, CubicleOptionValue, EditOptionPayload, EditOptionValuePayload,} from '../../../../shared/model/cubicleOption-model';
import {Localization} from '../../../../shared/model/localization.model';
import {OdmEditOptionModalComponent} from '../odm-edit-option-modal/odm-edit-option-modal.component';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {CustomTranslateLoader} from '../../../../shared/localization/CustomTranslateLoader';
import {CommonModalComponent} from '../../../../shared/common-modal/common-modal.component';
import {OdmEditOptionValueModalComponent} from '../odm-edit-option-value-modal/odm-edit-option-value-modal.component';
import {RangeType} from '../../../../shared/model/range-type';

@Component({
  selector: 'app-odm-manage-options',
  templateUrl: './odm-manage-options.component.html',
  styleUrls: ['./odm-manage-options.component.scss']
})
export class OdmManageOptionsComponent implements OnInit {

  @SessionStorage()
  public user: User;

  @SessionStorage()
  localization: Localization;

  @Input()
  selectedRange: Range;

  @Output()
  clickBack: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('addOptionModal') addOptionModal: OdmEditOptionModalComponent;
  @ViewChild('editOptionModal') editOptionModal: OdmEditOptionModalComponent;
  @ViewChild('deleteOptionModal') deleteOptionModal: CommonModalComponent;

  @ViewChild('addOptionValueModal') addOptionValueModal: OdmEditOptionValueModalComponent;
  @ViewChild('editOptionValueModal') editOptionValueModal: OdmEditOptionValueModalComponent;
  @ViewChild('deleteOptionValueModal') deleteOptionValueModal: CommonModalComponent;


  isLoading = false;
  optionsByReference: Map<string, CubicleOption[]>;
  references: string[] = [];
  activeReference: string;
  activeOption: string;
  optionNameToDelete: string;
  optionValueNameToDelete: string;

  constructor (private optionsService: OptionsService,
    private readonly messageService: MessageService,
    private readonly customTranslateLoader: CustomTranslateLoader,
    private translateService: TranslateService) {
  }

  ngOnInit (): void {
    this.getOptions();
  }


  returnOnOfferSelection () {
    this.clickBack.emit();
  }

  getOptions () {
    this.isLoading = true;
    this.optionsService.getOptionsByRangeId(this.selectedRange.id, true).subscribe(payload => {
      this.optionsByReference = payload.optionsByReferences;
      this.references = [];
      for (const key in this.optionsByReference) {
        this.references.push(key);
      }
      this.isLoading = false;
    }, () => this.isLoading = false)
  }

  onSuccessEditingOption () {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('T_INFO'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_EDITING_OPTION'),
    });

    this.customTranslateLoader.getTranslation(this.user.preferredLanguage, true).subscribe(res => {
      this.translateService.setTranslation(this.user.preferredLanguage, res, false);
      this.getOptions();
    });
  }

  onErrorEditingOption (error) {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_ERROR'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_EDITING_OPTION') + ': ' + error,
    });
    this.getOptions();
  }

  onSuccessDeletingOption () {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('T_INFO'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_DELETING_OPTION'),
    });
    this.optionNameToDelete = null;
    this.getOptions();
  }

  onErrorDeletingOption (error) {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_ERROR'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_DELETING_OPTION') + ': ' + error,
    });
    this.optionNameToDelete = null;
    this.getOptions();
  }
  onClickAddOptionButton () {
    const languages = this.localization.langs.map(lang => lang.language);
    this.addOptionModal.showAddMode(languages, this.showOnPopUpManagement());
  }

  onClickAddOptionValueButton () {
    this.addOptionValueModal.showAddMode(this.optionsByReference);
  }


  onClickEditOptionValueButton (event: {option: CubicleOption, optionValue: CubicleOptionValue} , reference: string) {
    this.editOptionValueModal.showEditMode(event.option, event.optionValue, reference);
  }


  addOption (addOptionForm: EditOptionPayload) {
    this.isLoading = true;
    this.activeReference = addOptionForm.reference;
    this.activeOption = null;
    addOptionForm.rangeId = this.selectedRange.id;
    this.optionsService.createOption(addOptionForm).subscribe(() => {
      this.onSuccessEditingOption();
      this.activeOption = addOptionForm.nameKey;
    }, (error) => {
      this.onErrorEditingOption(error);
    });
  }

  editOption (editOptionPayload: EditOptionPayload) {
    this.isLoading = true;
    this.activeReference = editOptionPayload.reference;
    editOptionPayload.rangeId = this.selectedRange.id;
    this.activeOption = null;
    this.optionsService.editOption(editOptionPayload).subscribe( () => {
      this.onSuccessEditingOption();
    }, (error) => {
      this.onErrorEditingOption(error);
    });
  }

  deleteOption() {
    this.isLoading = true;
    this.activeOption = null;
    this.optionsService.deleteOption(this.selectedRange.id, this.activeReference, this.optionNameToDelete).subscribe(() => {
      this.onSuccessDeletingOption();
    }, (error) => {
      this.onErrorDeletingOption(error);
    });
  }

  addOptionValue (editOptionValuePayload: EditOptionValuePayload) {
    this.isLoading = true;
    this.activeReference = editOptionValuePayload.reference;
    this.activeOption = editOptionValuePayload.optionNameKey;
    this.optionsService.addOptionValue(editOptionValuePayload).subscribe(() => {
      this.onSuccessEditingOption();
    }, (error) => {
      this.onErrorEditingOption(error);
    });
  }

  editOptionValue (editOptionValuePayload: EditOptionValuePayload) {
    this.isLoading = true;
    this.isLoading = true;
    this.activeReference = editOptionValuePayload.reference;
    this.activeOption = editOptionValuePayload.optionNameKey;
    this.optionsService.editOptionValue(editOptionValuePayload).subscribe(() => {
      this.onSuccessEditingOption();
    }, (error) => {
      this.onErrorEditingOption(error);
    });
  }

  deleteOptionValue() {
    this.isLoading = true;
    this.activeOption = this.optionNameToDelete;
    this.optionsService.deleteOptionValue(this.selectedRange.id, this.activeReference, this.optionNameToDelete, this.optionValueNameToDelete).subscribe(() => {
      this.onSuccessDeletingOption();
    }, (error) => {
      this.onErrorDeletingOption(error);
    });
  }

  onClickEditOptionButton(option: CubicleOption, reference: string){
    this.editOptionModal.showEditMode(option, reference, this.showOnPopUpManagement());
  }

  onClickDeleteOptionButton(option: CubicleOption, reference: string){
    this.optionNameToDelete = option.name;
    this.activeReference = reference;
    this.deleteOptionModal.show();
  }

  onClickDeleteOptionValueButton(event: {option: CubicleOption, optionValue: CubicleOptionValue}, reference: string){
    this.optionNameToDelete = event.option.name;
    this.optionValueNameToDelete = event.optionValue.value;
    this.activeReference = reference;
    this.deleteOptionValueModal.show();
  }

  showOnPopUpManagement(): boolean {
    return this.selectedRange.rangeType != RangeType.TRANSFORMER;
  }
}
