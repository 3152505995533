<app-common-modal #addOption [name]="'addOption'"
                  [title]="'T_MODAL_TRANSFORMER_ADD_OPTION' | translate"
                  [withFooter]="true"
                  [twoButtonsDialogs]="false"
                  [closable]="false"
                  [description]="'T_ADD_THIS_OPTION' | translate"
                  (onClickOkButton)="clickOkButton()">
  <div modal-body>
    <ng-container *ngFor="let opt of displayedOptions">
      <div *ngIf="opt.visible">
        <div *ngIf="opt.values.length > 1; then select else twoItems"></div>
        <ng-template #select>
          <label class="optlabel" translate>{{opt.name}}</label>
          <select class="form-control" [disabled]="opt.disabled"
                  tooltip="{{(opt.description? opt.description : opt.name ) | translate }}" placement="left"
                  container="body"
                  name="{{opt.name}}" (change)="changeOption($event.target.value, opt)">
            <option *ngIf="noOptionsSelected(opt)" disabled selected [value]=" "></option>
            <option *ngFor="let value of opt.values" [selected]="value.selected"
                    [value]="value.value" translate>{{value.value}}
            </option>
          </select>
          <span *ngIf="opt.disabled">
            <span class="se-icons font-param infoBtn" tooltip="{{'T_RIGHT_PANEL_DISABLED_OPTION_TOOLTIP' | translate}}"
                  placement="top" aria-hidden="true">information_circle</span>
          </span>
        </ng-template>

        <ng-template #twoItems>
          <div *ngIf="opt.values.length < 2; then inputTemplate"></div>
          <div *ngIf="opt.values.length === 2">
            <div *ngIf="opt.htmlElementType === 'toggle'; then toggle else radio"></div>
          </div>
        </ng-template>

        <ng-template #toggle>
          <div container="body" tooltip="{{opt.description?opt.description:opt.name | translate}}" placement="right">

            <!-- if no value selected, display off toggle-->
            <img *ngIf="noOptionsSelected(opt)"
                 (click)="onChangeToggleOption(true, opt)"
                 src="./assets/images/icons/toggleOff.svg" class="toggleBtn right pointer"/>
            <div *ngFor="let val of opt.values">
              <img *ngIf="val.selected && !val.reference"
                   (click)="onChangeToggleOption(true, opt)"
                   src="./assets/images/icons/toggleOff.svg" class="toggleBtn right pointer"/>
              <img *ngIf="val.selected && val.reference"
                   (click)="onChangeToggleOption(false, opt)"
                   src="./assets/images/icons/toggleOn.svg" class="toggleBtn right pointer"/>

            </div>
            <label class="optlabel" translate>{{opt.name}}</label>
          </div>
        </ng-template>


        <ng-template #radio>
          <label class="optlabel" translate>{{opt.name}}</label>
          <div tooltip="{{opt.description?opt.description:opt.name | translate}}" placement="left" container="body">
            <label *ngFor="let value of opt.values" class="radio-inline pointer">
              <input type="radio" [disabled]="opt.disabled"
                     (change)="changeOption(value.value, opt)" class="pointer"
                     name="{{opt.name}}" [value]="value">
              <img *ngIf="value.selected" src="./assets/images/icons/radio.svg" class="radioBtn"/>
              <img *ngIf="!value.selected" src="./assets/images/icons/radioUnselected.svg" class="radioBtn"/>
              {{value.value | translate}}
              <span class="tooltipParent" *ngIf="opt.disabled && reference.selected">
                <span class="se-icons font-param infoBtn" tooltip="{{'T_RIGHT_PANEL_DISABLED_OPTION_TOOLTIP' | translate}}"
                      placement="top" aria-hidden="true">information_circle</span>
              </span>
            </label>
          </div>
        </ng-template>

        <ng-template #inputTemplate>
          <label class="optlabel" translate>{{opt.name}}</label>
          <div>{{opt.values[0].value | translate}}</div>
        </ng-template>

    </div>
  </ng-container>
  </div>
</app-common-modal>
