import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {OfferService} from '../../../../offers/shared/offer.service';
import {Range} from '../../../../shared/model/range-model';
import {OfferDataManagementService} from '../../offer-data-management.service';
import * as _ from 'lodash';
import {BusinessStatus} from '../../../../configuration/shared/model/reference';
import {OdmAddModalComponent} from '../../shared/odm-add-modal/odm-add-modal.component';
import {SwitchBoardComponent} from '../../../../configuration/shared/model/component';
import {CommonModalComponent} from '../../../../shared/common-modal/common-modal.component';
import {ReferenceView} from '../../../shared/bsl-reference/offer-data-management-model';
import {CustomTranslateLoader} from '../../../../shared/localization/CustomTranslateLoader';
import {SessionStorage} from 'ngx-webstorage';
import {UtilService} from '../../../../shared/util/util.service';
import {RangeType} from "../../../../shared/model/range-type";
import {
  CardComponentModalComponent
} from "../../../../configuration/card-component-modal/card-component-modal.component";
import {WrapperModalContent} from "../../../../configuration/shared/model/wrapperModalContent";
import {ActiveTabType} from "../../../../configuration/shared/model/activeTab_Type";
import {ModalType} from "../../../../configuration/shared/model/modal_type";
import {ComponentService} from "../../../../configuration/shared/services/component.service";


@Component({
  selector: './app-offer-data-management-list-references',
  templateUrl: './odm-list-references.component.html',
  styleUrls: ['./odm-list-references.component.less'],
})
export class OdmListReferencesComponent implements OnInit {

  @Input()
  selectedRange: Range;

  @ViewChild('addReferenceModal') addReferenceModal: OdmAddModalComponent;

  @ViewChild('firstDeleteReferenceModal') firstDeleteReferenceModal: CommonModalComponent;

  @ViewChild('secondDeleteReferenceModal') secondDeleteReferenceModal: CommonModalComponent;

  @ViewChild('testReferenceModal') testReferenceModal: CommonModalComponent;

  @ViewChild('publishReferenceModal') publishReferenceModal: CommonModalComponent;

  @ViewChild('componentInfosModal') componentInfosModal: CardComponentModalComponent;

  @SessionStorage()
  user;

  FILTER_PUBLISHED = 'PUBLISHED';
  FILTER_UNPUBLISHED = 'UNPUBLISHED';
  ALL_REFERENCES = 'ALL_REFERENCES';
  IN_TEST = 'IN_TEST';

  existingReference: Array<ReferenceView> = [];
  existingFilteredReference: Array<ReferenceView> = [];

  loadingComponents = false;

  filterValue: string = this.ALL_REFERENCES;

  // Stored object for action (delete, set to in test mode) to use after user answer a confirm modal
  objectForAction: ReferenceView;

  // Generating array from 1 to 25
  maxCubicleSize: number[] = Array(25).fill(0).map((elem, i) => i + 1);

  allowedReferenceCategoriesForComponents: String[] = ["Cubicle", "Transformers", "Switchboard", "Three Functions", "Ring Main Unit", "Commercial reference"];

  @Output()
  clickBack: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  addReferenceToNewRange: EventEmitter<void> = new EventEmitter<void>();

  protected readonly RangeType = RangeType;

  constructor(private readonly offerService: OfferService,
              private readonly offerDataManagementService: OfferDataManagementService,
              private messageService: MessageService,
              private readonly translateService: TranslateService,
              private readonly componentService: ComponentService,
              private readonly utilService: UtilService,
              private readonly customTranslateLoader: CustomTranslateLoader) { }

  /**
   * fucntion to get ReferenceView from component
   * @param component
   * @param selectedRange
   */
  private static getReferenceViewFromComponent(component: SwitchBoardComponent, selectedRange: Range): ReferenceView {
    const result = new ReferenceView();
    result.type = 'switchboardComponent';
    result.reference = component.reference;
    result.mainCharacMissing = selectedRange.mainCharacteristics.filter(charac => component.characteristics.every(compChar => compChar.key !== charac));
    result.mainCharacOk = result.mainCharacMissing.length === 0;
    result.electricalCharacMissing = selectedRange.electricalCharacteristicsFilters.filter(charac => component.characteristics.every(compChar => compChar.key !== charac));
    result.electricalCharacOk = result.electricalCharacMissing.length === 0;
    result.origin = component;
    result.mySEAvailability = component.mySEAvailability;
    result.mySEAvailabilityDate = component.mySEAvailabilityDate;
    return result;
  }

  ngOnInit() {
    this.searchReferences();
  }

  /**
   * Method to search component references
   */
  searchReferences() {
    this.loadingComponents = true;
    this.existingReference = [];
    this.existingFilteredReference = [];
    this.filterValue = this.ALL_REFERENCES;
    this.offerDataManagementService.getComponentsReferences(this.selectedRange.id)
      .subscribe(
        components => {
          this.loadingComponents = false;
          this.existingReference = components.map(comp =>
            OdmListReferencesComponent.getReferenceViewFromComponent(comp, this.selectedRange));
          this.existingFilteredReference = this.existingReference; },
        () => {
          this.loadingComponents = false;
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_RETRIEVING_COMPONENTS'),
          });
        });
  }

  /**
   * Method to return on the range list
   */
  returnOnOfferSelection() {
    this.clickBack.emit();
  }

  /**
   * Method filter of references by status
   */
  public filterReferenceList(event: any) {
    if (event.srcElement.value === this.ALL_REFERENCES) {
      this.existingFilteredReference = this.existingReference;
    } else {
      this.existingFilteredReference = _.filter(this.existingReference, refView => refView.reference.status === event.srcElement.value);
    }
  }

  /**
   * Get status label according to reference status
   * @param status business status of a reference
   */
  getStatusLabel(status: BusinessStatus): string {
    return this.translateService.instant('T_OFFER_DATA_MANAGEMENT_' + status.toString());
  }

  /**
   * Show the modal for action
   * @param modal type of modal to show
   * @param refView the view of the ref
   */
  showModal(modal: string, refView: ReferenceView) {
    switch (modal) {
      case 'add':
        this.addReferenceModal.show();
        break;
      case 'delete':
        this.objectForAction = refView;
        if (this.objectForAction.reference.status !== 'DECOMMISSIONED') {
          this.firstDeleteReferenceModal.show();
        } else {
          this.secondDeleteReferenceModal.show();
        }
        break;
      case 'test':
        this.objectForAction = refView;
        this.testReferenceModal.show();
        break;
      case 'publish':
        this.objectForAction = refView;
        this.publishReferenceModal.show();
        break;
    }
  }

  /**
   * Method called to add a reference when user click add reference on modal
   * @param event the event transmitted by the modal with a reference, sld and layout
   */
  addReference(event) {
    this.loadingComponents = true;
    this.offerDataManagementService.addNewComponentToRange(this.selectedRange.id, event.bslReference.reference, event.sld, event.layout)
      .subscribe(() => { this.postAddSuccess(); },
        () => { this.postAddError(); }
      );


  }

  /**
   * Called on click on delete on a reference
   */
  deleteReference() {
    if (this.objectForAction.type === 'switchboardComponent') {
      this.loadingComponents = true;
      this.offerDataManagementService.deleteComponentToRange(this.objectForAction.origin.id).subscribe(
        () => this.postDeleteSuccess(),
        () => this.postDeleteError());
    }
  }

  /**
   * Called on click on test on a unpublished reference
   */
  changeReferenceStatus(status: string) {
    if (this.objectForAction.type === 'switchboardComponent') {
      this.objectForAction.origin.reference.status = status;
      this.offerDataManagementService.updateComponentToRange(this.selectedRange.id, <SwitchBoardComponent>this.objectForAction.origin).subscribe(
        () => this.postChangeStatusSuccess(status),
        () => this.postChangeStatusError(status)
      );
    } else {
      this.objectForAction.origin.values.forEach(val => {
        if (val.reference) {
          val.reference.status = status;
        }
      });
    }
  }

  /**
   * Function to retrieve the list of missing characteristics on a ref
   * @param errorMessage
   * @param missingCharac
   */
  getMissingCharacteristic(errorMessage: string, missingCharac: string[]): string {
    return this.translateService.instant(errorMessage) + ' ' + missingCharac.map(key => this.translateService.instant(key)).join(', ');
  }

  /**
   * Update the range with the new maximum number of cubicle selected
   * @param max the new value
   */
  updateMaxNumCubicle(max: number) {
    this.loadingComponents = true;
    this.selectedRange.switchboardLimit.max = max;
    this.offerDataManagementService.updateRange(this.selectedRange).subscribe(newRange => {
      this.selectedRange = newRange;
      this.loadingComponents = false;
    });
  }
  /**
   * Return translation for delete reference, regarding status of the reference
   * @param refView
   */
  getDeletionTranslation(refView: ReferenceView): string {
    if (refView.reference.status === 'PUBLISHED') {
      return this.translateService.instant('T_OFFER_DATA_MANAGEMENT_REFERENCE_DECOMMISSIONED');
    } else {
      return this.translateService.instant('T_OFFER_DATA_MANAGEMENT_REFERENCE_DELETE');
    }
  }


  selectedReference(reference: string) {
    this.loadingComponents = true;
    this.componentService.getComponent(this.selectedRange.id, reference).subscribe(comp => {
      this.loadingComponents = false;
      const wrapperModalContent = new WrapperModalContent(comp, [], ActiveTabType.CHARACTERISTICS, ModalType.INFORMATION_MODAL);
      this.componentInfosModal.show(wrapperModalContent);
    },
      () => this.loadingComponents = true);
  }

  /**
   * Method called when the add of a reference is in error
   */
  private postAddError() {
    this.loadingComponents = false;
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_ERROR'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_ADDING_REFERENCE'),
    });
  }

  /**
   * Method called when the add of a reference is in success
   */
  private postAddSuccess() {
    this.searchReferences();
    // Updating translation by forcing cache for the new offer translations key
    this.customTranslateLoader.getTranslation(this.user.preferredLanguage, true).subscribe( res =>
      this.translateService.setTranslation(this.user.preferredLanguage, res, false));

    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('T_INFO'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_ADDING_REFERENCE'),
    });
    if (this.selectedRange.status === 'CONFIGURED' || this.selectedRange.status === 'TEST') {
      this.addReferenceToNewRange.emit();
    }
  }

  /**
   * Method called when the delete of a reference is in error
   */
  private postDeleteError() {
    this.loadingComponents = false;
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_ERROR'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_DELETING_REFERENCE'),
    });
  }

  /**
   * Method called when the delete of a reference is in success
   */
  private postDeleteSuccess() {
    this.searchReferences();
    this.offerService.getRanges().subscribe();
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('T_INFO'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_DELETING_REFERENCE'),
    });
    if (this.selectedRange.status === 'CONFIGURED' || this.selectedRange.status === 'TEST') {
      this.addReferenceToNewRange.emit();
    }
  }

  /**
   * Method called when the test of a reference is in error
   */
  private postChangeStatusError(refStatus: string) {
    this.loadingComponents = false;
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_ERROR'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_ERROR_TEST_REFERENCE'),
    });
  }

  /**
   * Method called when the test of a reference is in success
   */
  private postChangeStatusSuccess(refStatus: string) {
    this.searchReferences();
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('T_INFO'),
      detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_TEST_REFERENCE', {state: refStatus}),
    });
  }

}
