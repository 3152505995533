import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../shared/user/user.service';
import {User} from '../../shared/user/user';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import * as moment from 'moment';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.less']
})
export class UserManagementComponent implements OnInit {

  @ViewChild('deleteUserConfirmationModal') deleteUserConfirmationModal: CommonModalComponent;

  users: User[];
  userToBeDeleted: User;

  constructor(private userService: UserService) {
  }

  ngOnInit(): void {
    this.getAllUsers();
  }

  getLastConnectionDateFormatted(user: User) {
    if (!user.lastConnectionDateTimestamp) {
      return "";
    }
    return moment.unix(user.lastConnectionDateTimestamp).format("DD/MM/YYYY");
  }

  tryDeleteUser(user: User) {
    this.userToBeDeleted = user;
    this.deleteUserConfirmationModal.show();
  }

  deleteUser() {
    this.userService.deleteUser(this.userToBeDeleted).subscribe(() => (this.getAllUsers()));
  }

  private getAllUsers() {
    this.userService.getAllUsers().subscribe((users) => {
      this.users = users;
      this.users.map(user => {
        if (user.lastConnectionDate.length > 0) {
          const date: Date = new Date();
          date.setFullYear(user.lastConnectionDate[0], user.lastConnectionDate[1] - 1, user.lastConnectionDate[2]);
          user.lastConnectionDateTimestamp = moment(date).unix();
        } else {
          user.lastConnectionDateTimestamp = 0;
        }
        return user;
      })
    });
  }

}
