export enum Role {
  DEMO_VERSION = 'DEMO_VERSION',
  CONTRACTOR_INDIRECT = 'CONTRACTOR_INDIRECT',
  CONTRACTOR = 'CONTRACTOR',
  CONTRACTOR_MIXED = 'CONTRACTOR_MIXED',
  RESELLER = 'RESELLER',
  NATIONAL_SALES = 'NATIONAL_SALES',
  FRONT_OFFICE = 'FRONT_OFFICE',
  CPQ_USER = 'CPQ_USER',
  COM_TOOL_MANAGEMENT = 'COM_TOOL_MANAGEMENT'
}
