/* Angular modules */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
/* ngx modules */
import {TranslateModule} from '@ngx-translate/core';
import {MultiselectDropdownModule} from 'angular-2-dropdown-multiselect';
/* app modules */
import {EcorealCtSharedModule} from '../shared/shared.module';
import {PackageSelectorComponent} from './package-selector/package-selector.component';
import {PackageItemsComponent} from './package-items/package-items.component';
import {CharacteristicsModule} from '../characteristics/characteristics.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MultiselectDropdownModule,
    EcorealCtSharedModule,
    CharacteristicsModule
  ],
  declarations: [PackageSelectorComponent, PackageItemsComponent],
  exports: [PackageSelectorComponent]
})
export class PackageOfferModule {
}
