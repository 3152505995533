import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Contact, ContactFieldEnum, ContactTypeEnum} from '../../../shared/partner/contact';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.less']
})
export class ContactDetailsComponent  {

  @Input()
  contactName: string;

  @Input()
  contactType: ContactTypeEnum;

  @Input()
  contact: Contact;

  @Output()
  onEditFieldBlur = new EventEmitter<[number, number, any]>();

  // assign Enums to variables so they are visible in template
  ContactFieldEnum: typeof ContactFieldEnum = ContactFieldEnum;

  constructor() {
  }

  /**
   * Edit field blur.
   * Triggers event on blur event on one of the editable fields: phone, fax, mail
   *
   * @param indexContact
   * @param indexField
   * @param field
   */
  editFieldBlur(indexContact: number, indexField: number, field) {
    field.target.innerText = field.target.textContent.replace(/(\r\n|\n|\r)/gm, '');
    this.onEditFieldBlur.emit([indexContact, indexField, field]);
  }

}
