import {NavigationStep} from '../../shared/guards/navigationStep-enum';
import {SimplifiedRange} from '../../shared/model/simplifiedRange-model';
import {Bom, ReferenceBom} from '../../bom/shared/bom-model';
import {DeliveryPolicy} from '../../shared/model/deliveryPolicy';
import {SwitchBoardComponent} from '../../configuration/shared/model/component';
import {User} from '../../shared/user/user';
import {Packing} from '../../shared/model/packingSelected-model';
import {MySEStatusEnum} from '../../shared/model/mySEStatusEnum';
import {Reference} from '../../configuration/shared/model/reference';
import {CpqStatus} from '../../shared/model/cpqStatus';
import {Package} from '../../admin/shared/package/package.model';

export class Project {
  id: string;
  name: string;
  customerName: string;
  partnerId: number;
  itemCT?: Array<Item>;
  originProjectId?: string;
  sharedProjectCount?: number;
  canEditSharedProject?: boolean;
  spaceName: string;
  creationDate: Date;
  updateDate: Date;
  sharedProject?: boolean;
  validSpimId?: boolean;
  sharedProjectUserEmail: string;
  shareComment: string;
  sharedFromUserEmail: string;
  discount = 0;
  author: User;
  lastUpdator: User;
  readOnly: boolean;
  cpqNumber: string;
  cpqStatus: CpqStatus;
  mySEUrl: string;
  cpqAnswer: CpqAnswer;
}

export class Dimension {
  height: number;
  depth: number;
  width: number;
  weight: number;
}

export class GlobalInformations extends Dimension {
  totalPrice: number;
  packingPrice: number;
  mySePublicPriceStatus?: MySEStatusEnum;
  mySeNetPriceStatus?: MySEStatusEnum;
}
export enum Status {
  configured = 'configured',
  quoted = 'quoted',
  ordered = 'ordered'
}
export class Item extends Dimension {
  id = null;
  name: string;
  currentNavigationStep?: NavigationStep = null;
  maxNavigationStep?: NavigationStep = null;
  range?: SimplifiedRange = null;
  packageOfferId?: string = null;
  selectedPackage?: Package = null;
  accessories?: Array<ItemValue> = [];
  displayAccessories?: Array<string> = [];
  electricalCharacteristics?: Array<ItemValue> = [];
  components?: Array<SwitchBoardComponent> = [];
  selectedComponentIndex = -1;
  bom?: Bom = new Bom();
  date: number;
  dateValidityQuotation?: number;
  status: Status = Status.configured;
  deliveryPolicy?: DeliveryPolicy;
  discount = 0;
  author: User;
  lastUpdator: User;
  creationDate: number;
  updateDate: number;
  optionalPacking?: Array<Packing>;
  mySEUrl: string;
  // mySePublicPriceStatus?: MySEStatusEnum;
  mySeNetPriceStatus?: MySEStatusEnum;
  totalPrice?: TotalPrice;
  orderedReferences?: Array<string> = [];
  orderInformation?: OrderInformation;
  selectedServices: Array<ItemValue> = [];
}


export class ItemValue {
  id: string;
  name?: string;
  value: string|number;
  disabled: boolean;
  referenceBom: ReferenceBom;
  reference: Reference;
  deliveryTime?: number;
  quantity: number;
  chooseValue: boolean;
}
export class ReleaseNote {
  version: string;
  createDate: Date;
  messages: Array<string>;
  countrys: Array<string>;
  roles: Array<string>;
  usersVersion: Array<UserVersion>;
}

export class UserVersion {
  userVersion: string;
  userEmail: string;
}

export class Url {
  value: string;
}

export class ProjectFile {
  fileId: string;
  name: string;
  mimeType: string;
  authorId: string;
  authorName: string;
  dateCreated: number;
  fileSize: number;
  blob?: Blob;
  img?: any;
  imgTxt?: any;
  loading = false;
}

export class TotalPrice {
  mySENetPrice: number;
  foPublicPrice: number;
  foNetPrice: number;
  validMySENetPrice: boolean;
  validFoPublicPrice: boolean;
  validFoNetPrice: boolean;
}

export class ProjectTotalPrice {
  price: number;
  type: TotalPriceEnum;
  myseMessage?: string;
  constructor(price: number, type: TotalPriceEnum, myseMessage?: string) {
    this.price = price;
    this.type = type;
    this.myseMessage = myseMessage;
  }
}

export enum TotalPriceEnum {
  mySeNet = 'mySeNet',
  // mySePublic = 'mySePublic',
  foPublic = 'foPublic',
  foNet = 'foNet'
}

export class OrderInformation {
  mySEAccountOrderBy: string;
  orderReference: string;
  requiredDeliveryDate: string;
  orderDate: number;
  deliveryAddress: string;
  contactName: string;
  contactPhone: string;
  comments: string;
  deliveryTime: number;
}

export class CpqAnswer {
  configurationInfo: CpqConfigurationInfo;
  quoteInfo: CpqQuoteInfo;
  callbackUrl: CpqCallbackUrl;
  message: string;
  quotingSystem: string;
}

export class CpqConfigurationInfo {
  configurationId: string;
}

export class CpqQuoteInfo {
  quoteId: string;
}

export class CpqCallbackUrl {
  rel: string;
  href: string;
  title: string;
}

export class StatusFilter {
  owned: boolean;
  configured: boolean;
  ordered: boolean;
  cpqProjects: boolean;
  cpqProjectsWithDefaultQuoteId: boolean;
}
