import {Component, Input, OnInit} from '@angular/core';
import {NavigationBarItem} from '../navigation-bar-v2/navigation-bar-v2.component';
import {Router} from '@angular/router';

export type NavigationSidePanelItem = {
  id: number;
  title: String;
  description?: String;
  icon?: String;
  active?: boolean;
}

@Component({
  selector: 'app-navigation-side-panel',
  templateUrl: './navigation-side-panel.component.html',
  styleUrls: ['./navigation-side-panel.component.less']
})
export class NavigationSidePanelComponent implements OnInit {

  @Input()
  items: NavigationSidePanelItem[];

  ngOnInit(): void {
    let currentItem = this.items.find(item => item.active === true);
    if(currentItem){
      this.goToItem(currentItem);
    }
  }

  goToItem(item: NavigationSidePanelItem){
    this.items.forEach(i => {
      i.id === item.id ? i.active = true : i.active = false;
    });
  }
}
