export class NavigationLinkEnum {
  public static readonly PROJECTS = '/projects';
  public static readonly SHARED_PROJECTS = '/shared-projects-page';
  public static readonly OFFERS_CT = '/offers';
  public static readonly CHARACTERISTICS = '/characteristics';
  public static readonly CONFIGURATION = '/configure';
  public static readonly TRANSFORMER = '/transformer';
  public static readonly TRANSFORMER_SELECTOR = '/transformer-selector';
  public static readonly SERVICES_CATALOG = '/services-catalog';
  public static readonly BILL_OF_MATERIALS = '/bom-page';
  public static readonly EXPORT = '/documentation';
  public static readonly SETTINGS = '/settings';
  public static readonly ADMIN = '/admin';
  public static readonly TRANSLATION = '/admin/translation';
  public static readonly PRICE_MANAGEMENT = '/admin/price-management';
  public static readonly CONTACT_MANAGEMENT = '/admin/contact-management';
  public static readonly USER_MANAGEMENT = '/admin/user-management';
  public static readonly PRICE_BY_PARTNER = '/admin/price-by-partner';
  public static readonly DISTRIBUTOR_MANAGEMENT = '/admin/distributor';
  public static readonly OFFER_DATA_MANAGEMENT = '/admin/offer-data-management';
  public static readonly PACKAGE_MANAGEMENT = '/admin/package-management';
  public static readonly PROJECT_SHARING = '/project-sharing';
  public static readonly PARTNER_SCHNEIDER = 'https://partner.schneider-electric.com';
  public static readonly PROJECT_BILL_OF_MATERIALS = '/project-bom-page';
  public static readonly PROJECT_DOCUMENTATION = '/project-documentation';
  public static readonly UP_SELLING_TRANSFORMER = '/admin/up-selling-transformer';
  public static readonly SERVICES = '/admin/services';
  public static readonly TRIAL_CONFIGURATION = '/admin/trial-configuration';
  public static readonly PACKAGE_OFFER = '/package-offer';
}
