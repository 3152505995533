export class FilterValue {
  value: string | number | string[];
  checked: boolean;
  disabled: boolean;

  constructor(value: string | number | string[], checked: boolean, disabled: boolean) {
    this.value = value;
    this.checked = checked;
    this.disabled = disabled;
  }
}
