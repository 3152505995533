<div class="quotation-setup-container">
  <!-- VALIDITY PERIOD FORM -->
  <div *ngIf="!isLoading" class="quotation-validity">
    <h3 translate>T_QUOTATION_VALIDITY</h3>
    <div>
      <label class="label-padding" translate>T_RANGE</label>
      <select [(ngModel)]="selectedRange">
        <ng-container *ngFor="let range of ranges">
          <option [ngValue]="range" translate>{{range.nameKey}}</option>
        </ng-container>
      </select>
    </div>
    <div>
      <label class="label-padding" translate>T_VALIDITY_PERIOD</label>
      <input *ngIf="selectedRange" type="number" min="{{MIN_VALIDITY_PERIOD}}" max="{{MAX_VALIDITY_PERIOD}}" step="1"
             [disabled]="!selectedRange" [(ngModel)]="selectedRange.priceManagement.validityPeriod"
             (blur)="onChangeValidityPeriod($event.target.value)">
    </div>

    <button type="button" class="btn btn-group btn-primary button-div" (click)="updateValidityPeriod()" translate>T_PRICE_MANAGEMENT_UPDATE_BUTTON</button>
  </div>

  <!-- RESELLER/NATIONAL SALES DISCOUNT FORM -->
  <div *ngIf="!isLoading" class="max-discount">
    <h3 translate>T_MAX_DISCOUNT_ALLOWED</h3>
    <div>
      <label class="label-padding" for="maxDiscount" translate>T_DISCOUNT</label>
      <input id='maxDiscount' type="number" min="{{MIN_DISCOUNT_ALLOWED}}" max="{{MAX_DISCOUNT_ALLOWED}}" step="0.01"
             [(ngModel)]="discountAllowed"
             (blur)="onChangeDiscountAllowed($event.target)">
    </div>
    <button type="button" class="btn btn-group btn-primary button-div" (click)="updateDiscountAllowed()" translate>T_PRICE_MANAGEMENT_UPDATE_BUTTON</button>
  </div>

  <!-- RESELLER/NATIONAL SALES DISCOUNT FORM -->
  <div *ngIf="!isLoading" class="additional-delivery-time">
    <h3 translate>T_DELIVERY_ADDITIONAL_TIME</h3>
    <div>
      <label class="label-padding" for="deliveryPolicy" translate>T_ADDITIONAL_TIME</label>
      <input id='deliveryPolicy' type="number" min="{{MIN_DELIVERY_ALLOWED}}" max="{{MAX_DELIVERY_ALLOWED}}" step="1"
             [(ngModel)]="addDeliveryTime"
             (blur)="onChangeAdditionalDeliveryTime($event.target)">
    </div>
    <button type="button" class="btn btn-group btn-primary button-div" (click)="updateAdditionDeliveryTime()" translate>T_PRICE_MANAGEMENT_UPDATE_BUTTON</button>
  </div>

  <app-spinner *ngIf="isLoading" class="spinner" [sizeClass]="'medium'"></app-spinner>
</div>
