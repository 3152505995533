<div class="panel panel-default pull-left card-size inside-padding shadowing">
  <div class="row">
    <div class="col-xs-6">
      <h5 translate>{{component.reference.ref}}</h5>
    </div>
    <div *ngIf="!isDemoMode && !isCpqProject() && isAlreadyRequestedPrice()" class="col-xs-6">
      <app-price class="pull-right inlineDisplay"  [price]="getPrice(component)" [mySEStatus]="utilService.getMySEStatusToSend(component)"
                 [priceLabel]="getPriceKeyLabel(component)"></app-price>
    </div>
    <div *ngIf="!isDemoMode && !isCpqProject() && !isAlreadyRequestedPrice()" class="col-xs-6">
      <app-spinner class="pull-right inlineDisplay spinner-top" [sizeClass]="'small'"></app-spinner>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
    <p class="subtitle description-text-overflow"  translate>{{component.reference.description}}</p>
    </div>
  </div>

  <div class="panel-body row" >

    <!-- CUBICLE SLD -->
    <div *ngIf="component.type === cubicleType" class="col-xs-4 fig-container undo-bootstrap-padding" (click)="showModal(characteristicsTab, informationModal)">
      <app-sld-component-viewer *ngIf="component.type === cubicleType" [component] = "component"  [showCompleteSld]="false" [onCardsPanel]="true"></app-sld-component-viewer>
    </div>
    <!-- TRANSFORMER SLD -->
    <div *ngIf="component.type !== cubicleType" class="col-xs-2 undo-bootstrap-padding" (click)="showModal(characteristicsTab, informationModal)">
      <img  src="./assets/sld/Transformer_v2.svg" class="img-transformer">
    </div>

    <!-- MAIN CHARACTERISTICS -->
    <div [ngClass]="{ 'col-xs-9': component.type !== cubicleType, 'col-xs-7': component.type === cubicleType}">
        <div>
          <div *ngFor="let characteristic of mainCharacteristics">
           <div class="characteristics-title" translate>{{characteristic.key}}<span>:</span></div>
           <div class="characteristics-value span-blue" translate>{{characteristic.value}}</div>
          </div>
        </div>
    </div>
    <!-- INFO BUTTON TO DISPLAY CARD DETAIL -->
    <span  (click)="showModal(characteristicsTab, informationModal)" class="info-pellet span-blue se-icons col-xs-1">information_circle</span>
  </div>


  <!-- FOOTER OF CARD -->
  <div class="row">
     <div  class="col-xs-6 col-sm-4"><app-dimensions (click)="showModal(layoutsTab, informationModal)" [dimension]="component.dimensions"></app-dimensions></div>
     <div  class="col-xs-2 col-sm-4"><app-documents (click)="showModal(documentsTab, informationModal)" [documents]="component.documents"></app-documents></div>
     <div  class="col-xs-3 col-sm-4 text-right">
       <button (click)="addComponentDispacher(component)" type="button" [ngClass]="{'btn btn-full-size btn-success':true, 'disabled':isItemOrdered() || isReadOnlyProject()}">
        <span class="button-text text-overflow" translate >T_ADD_SELECTION</span>
       </button>
     </div>
  </div>

</div>
