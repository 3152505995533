/* angular modules */
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
/* ngx modules */
import {SessionStorageService} from 'ngx-webstorage';
import {environment} from "../../../environments/environment";

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {

  configUrl = '/ecorealct/configuration';

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url !== this.configUrl) {
      request = request.clone(
        {
          url: environment.baseUri + request.url
        });
    }
    return next.handle(request);
  }

}
