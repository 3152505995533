import {Component, Input, OnInit} from '@angular/core';
import {OfferDataManagementService} from '../../../offer-data-management.service';
import {Range} from '../../../../../shared/model/range-model';
import {
  BslFileCheckbox,
  BslFileCheckboxMap,
  RangeBslDocument
} from '../../../../shared/bsl-reference/offer-data-management-model';
import * as _ from 'lodash';
import {DocumentationCategory, StaticDocumentationService} from '../../../../../export/shared';
import {TranslateService} from '@ngx-translate/core';
import {Documentation} from '../../../../../shared/documentation/documentation.model';
import {Observable} from 'rxjs/Observable';

@Component({
  selector: 'app-odm-add-offer-documents',
  templateUrl: './odm-add-offer-documents.component.html',
  styleUrls: ['./odm-add-offer-documents.component.less']
})
export class OdmAddOfferDocumentsComponent implements OnInit {

  @Input()
  selectedRange: Range;

  model: BslFileCheckboxMap;
  modelKeys: string[];
  documentsMap: { [key: string]: BslFileCheckbox[] } = {};
  documentsMapKeys: string[];

  documents: RangeBslDocument[];
  selectedDocuments: RangeBslDocument[];
  fileCategories: any[];
  initialized = false;

  constructor(private offerDataManagementService: OfferDataManagementService,
              private staticDocumentationService: StaticDocumentationService,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    this.initializeFileCategories();

    Observable.forkJoin(this.offerDataManagementService.getDocumentsForRange(this.selectedRange.bslRangeId),
      this.offerDataManagementService.retrieveDocumentationByRange(this.selectedRange.nameKey))
      .subscribe( result => {
        this.documents = result[0];
        const previouslySelectedDocument = result[1];

        this.documents.sort((a, b) => a.description.localeCompare(b.description));
        this.selectedDocuments = previouslySelectedDocument.filter(doc => doc.link != null)
          .map(doc => {
            const rangeBslDocument = this.documents.find(bslDoc => doc.id === bslDoc.name);
            rangeBslDocument.expressCategory = doc.category;
            return rangeBslDocument;
        });
        this.createModel(this.documents);
        this.initialized = true;
      });
  }

  /**
   * Create a model for components logic
   * @param documents
   */
  createModel(documents: RangeBslDocument[]) {
    const bslFileCheckboxMap: BslFileCheckboxMap = new BslFileCheckboxMap();
    for (const doc of documents) {
      const fileCheckbox = new BslFileCheckbox(doc);
      const category = this.updateCheckAndGetCategory(fileCheckbox);
      bslFileCheckboxMap[doc.name] = {fileCheckbox: fileCheckbox, category: category};
    }
    this.model = bslFileCheckboxMap;
    this.modelKeys = Object.keys(this.model);

    this.setupDocumentsMap();
    this.documentsMapKeys = Object.keys(this.documentsMap);
  }

  /**
   * Documents - checkbox state changed
   * @param checkbox
   */
  documentCheckChange(checkbox) {
    const docModel = this.findCharacteristicInMap(checkbox.id);
    docModel.fileCheckbox.checked = !docModel.fileCheckbox.checked;
    docModel.category = this.fileCategories[0];
    this.setupDocumentsMap();
    this.setFilesByCategories();
  }

  /**
   * Document category change trigger
   * @param selected
   * @param key
   */
  documentCategoryChange(selected, key) {
    const docModel = this.findCharacteristicInMap(key);
    docModel.category = selected;
    this.setupDocumentsMap();
    this.setFilesByCategories();
  }

  /**
   * Get translation for title
   * @param docKey
   */
  getTitle(docKey) {
    return this.translateService.instant('T_DOC_' + docKey.toUpperCase());
  }

  /**
   * Initialize files categories
   */
  private initializeFileCategories() {
    this.fileCategories = [
      DocumentationCategory.PLANS,
      DocumentationCategory.DATASHEETS,
      DocumentationCategory.USER_GUIDE,
      DocumentationCategory.ENVIRONMENT,
      DocumentationCategory.CATALOGS,
      DocumentationCategory.PROMOTIONAL_MATERIALS,
      DocumentationCategory.TECHNICAL_PUBLICATIONS,
      DocumentationCategory.APPLICATIONS_SOLUTIONS,
      DocumentationCategory.WHITE_PAPERS,
      DocumentationCategory.CERTIFICATES,
      DocumentationCategory.SPECIFICATIONS,
      DocumentationCategory.TRAINING
    ];
  }

  private setupDocumentsMap() {
    this.fileCategories.forEach(cat => {
      this.documentsMap[cat] = this.getChosenFiles(cat);
    });
  }

  /**
   * Update checkbox checked and return category
   * @param fileCheckbox
   */
  private updateCheckAndGetCategory(fileCheckbox: BslFileCheckbox) {
    const chosenFile = this.selectedDocuments.find(doc => doc.name === fileCheckbox.name);
    let category: DocumentationCategory = null;
    if (chosenFile) {
      fileCheckbox.checked = true;
      category = chosenFile.expressCategory;
    }
    return category;
  }

  /**
   * Set the files by categories
   */
  private setFilesByCategories() {
    let docs: BslFileCheckbox[] = [];
    this.fileCategories.forEach(cat => {
      docs = docs.concat(this.getChosenFiles(cat));
    });
    // Convert to documentation
    this.offerDataManagementService.updateDocumentForRange(this.selectedRange.nameKey, docs.map(bslDoc => this.convertBslDocToDocumentation(bslDoc))).subscribe();
  }

  /**
   * Convert a bslDoc to an express documentation
   *
   * @param bslDoc
   */
  private convertBslDocToDocumentation(bslDoc: BslFileCheckbox): Documentation {
    const doc = new Documentation();
    doc.category = bslDoc.expressCategory;
    doc.title = bslDoc.description;
    doc.extension = bslDoc.extensions[0];
    doc.link = bslDoc.downloadUrl;
    doc.id = bslDoc.name;
    doc.range = this.selectedRange.nameKey;
    doc.bslDocumentation = true;
    // TODO fix when docs are not retrieved only in english
    doc.language = 'en';
    return doc;
  }

  /**
   * Get chosen files by category
   */
  private getChosenFiles(category: DocumentationCategory) {
    return this.getCheckedByCategory(category).map(val => {
      val.fileCheckbox.expressCategory = category;
      return val.fileCheckbox;
    });
  }

  /**
   * Get checked files from the model by the category
   * @param category
   */
  private getCheckedByCategory(category: DocumentationCategory) {
    return _.filter(_.values(this.model), (val) => val.fileCheckbox.checked && val.category === category);
  }

  /**
   * Find characteristic in map
   * @param id
   */
  private findCharacteristicInMap(id: string) {
    return _.find(_.values(this.model), (val) => val.fileCheckbox.name === id);
  }
}
