<div class="row button-part">
  <div class="col-xs-4 text-left">

    <!-- TOGGLE -->
    <div>
      <label translate>T_BO_TRANSLATION_ACTIVATE</label> :
      <img (click)="changeToggle(locale)" src="{{locale.available ? './assets/images/icons/toggle_on.svg' : './assets/images/icons/toggle_off.svg'}}" [ngClass]="'toggleBtn'"/>
      <span translate>{{locale.available ? 'T_BO_TRANSLATION_TOGGLE_ON' : 'T_BO_TRANSLATION_TOGGLE_OFF'}}</span>
    </div>

  </div>
  <div class="col-xs-4 text-center">
    <label translate>T_BO_TRANSLATION_FILTER_BY</label> :
    <button type="button" class="btn btn-status" [disabled]= "!hasOkStatus()" [ngClass]="{'status-grey-button': !locale.okStatus}" (click)="onClickOKStatus(locale, dataTableFilter)" translate><em class="fa fa-check ok-status status-in-button"></em>T_BO_TRANSLATION_STATUS_OK</button>
    <button type="button" class="btn btn-status" [disabled]= "!hasMissingStatus()" [ngClass]="{'status-grey-button': !locale.missingStatus}" (click)="onClickMissingStatus(locale, dataTableFilter)" translate><em class="fa fa-times missing-status status-in-button"></em>T_BO_TRANSLATION_STATUS_MISSING</button>
    <button type="button" class="btn btn-status" [disabled]= "!hasEditedStatus()" [ngClass]="{'status-grey-button': !locale.editedStatus}" (click)="onClickEditedStatus(locale, dataTableFilter)" translate><em class="fa fa-repeat edited-status status-in-button"></em>T_BO_TRANSLATION_STATUS_EDITED</button>
  </div>
  <div class="col-xs-4 text-right">
    <button type="button" class="btn btn-save" [disabled]="!hasEditedStatus()" (click)="saveTranslation(locale)" translate>T_BO_TRANSLATION_SAVE_BUTTON</button>
    <button type="button" class="btn btn-im-ex" (click)="fileInput.click()" translate>T_BO_TRANSLATION_IMPORT_BUTTON
      <input #fileInput type="file" (change)="importTranslation($event)" accept=".xlsx, .xls" class="display-none" />
    </button>
    <button type="button" class="btn btn-im-ex" (click)="exportTranslation()" translate>T_BO_TRANSLATION_EXPORT_BUTTON</button>
  </div>
</div>
