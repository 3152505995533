<div class="sld-content">

  <!-- Description of the process -->
  <div>
    <span *ngIf="!isSettingNumberOfFunction" translate>T_OFFER_DATA_MANAGEMENT_ADD_SLD_MESSAGE</span>
    <div *ngIf="!isSettingNumberOfFunction" class="green-circle-emphasis emphasis">{{currentFunction}}</div>
    <span *ngIf="isSettingNumberOfFunction" class="se-icons sld-add-info information-icon">information_circle</span>
    <span *ngIf="isSettingNumberOfFunction" class="text-justify" translate>T_OFFER_DATA_MANAGEMENT_FUNCTION_NUMBER_MESSAGE</span>
  </div>

  <div *ngIf="!isSettingNumberOfFunction" class="checkbox">
    <input type="checkbox" id="checkAllSld" class="check-box" (change)="onChangeAllSld()">
    <label for="checkAllSld"><span class="accept-text align-right" translate>T_DISPLAY_ALL_SLD</span></label>
  </div>

  <!-- SLD List -->
  <div *ngIf="!isSettingNumberOfFunction" class="scrollable sld-content-size">
    <app-spinner *ngIf="loading" [sizeClass]="'medium'"></app-spinner>
    <div *ngIf="!loading" >
      <div *ngIf="!sldList || sldList.length === 0">
        <span translate>T_OFFER_DATA_MANAGEMENT_NO_SLD_AVAILABLE</span>
      </div>
      <div *ngIf="!allSLDCheck">
        <div *ngFor="let sld of sldList" class="sld-column">
          <div [ngClass]="{'imageSelected': isSelectedImage(sld)}">
            <img src="{{getSldImagePath(sld.pictureFileName)}}" (click)="setSldValue(sld)" class="image-hover image-cursor">
            <figcaption class="text-center img-name" translate>{{sld.nameKey}}</figcaption>
          </div>
        </div>
      </div>
      <div *ngIf="allSLDCheck">
        <div *ngFor="let sld of allSLD" class="sld-column">
          <div [ngClass]="{'imageSelected': isSelectedImage(sld)}">
            <img src="{{getSldImagePath(sld.pictureFileName)}}" (click)="setSldValue(sld)" class="image-hover image-cursor">
            <figcaption class="text-center img-name" translate>{{sld.nameKey}}</figcaption>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isSettingNumberOfFunction && allSLDCheck" class="sld-pagination">
    <se-pagination
      labelValue="" labelPrev="" labelPerPage="" labelNext="" labelLast="" labelFirst=""
      [perPage]="perPage" [total]="pageTotal" [value]="currentPage" (didChange)="pageChange($event)">
    </se-pagination>
  </div>

  <!-- SLD Not found message -->
  <div *ngIf="!isSettingNumberOfFunction">
    <span class="se-icons sld-add-info help-icon">help</span>
    <span class="icon-label" translate>T_OFFER_DATA_MANAGEMENT_SLD_NOT_FOUND</span>
  </div>

  <!-- Number of function form -->
  <div *ngIf="isSettingNumberOfFunction" class="form-section">
    <span class="emphasis" translate>T_OFFER_DATA_MANAGEMENT_NUMBER_OF_FUNCTION</span>
    <input  [(ngModel)]="numberOfFunction" type="number" min="1" max="10" maxlength="2" (blur)="onChangeFunctionNumber()" >
  </div>

  <!-- Button footer -->
  <div class="row btn-right button-margin">
      <button type="button" class="btn btn-default-white-background" (click)="cancelButton()" translate>T_BUTTON_CANCEL</button>
      <button type="button" *ngIf="isSettingNumberOfFunction || (currentFunction !== numberOfFunction)" [disabled]="(!isSettingNumberOfFunction && !hasSelectedSld) || !isValidNumber()"
              class="btn btn-default-white-background" (click)="nextButton()" translate>T_BUTTON_NEXT</button>
      <button type="button" *ngIf="!isSettingNumberOfFunction && currentFunction === numberOfFunction"
              [disabled]="!hasSelectedSld" class="btn btn-default-white-background" (click)="addButton()" translate>T_OFFER_DATA_MANAGEMENT_ADD_SLD</button>
  </div>
</div>
