/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
/* ngx modules */
/* 3rd parties libraries */
/* app modules*/
import {ProjectFile} from '../../project/shared/project-model';
import {LoggerService} from '../../shared/logging/logger.service';

@Injectable()
export class FilesService {

  constructor(private httpClient: HttpClient,
              private logger: LoggerService) {
  }

  /**
   * Get files stores on SPIM of a project
   * @param projectId
   * @returns {Observable<Array<String>>}
   */
  getProjectFiles(projectId): Observable<Array<ProjectFile>> {

    this.logger.info('FilesService getProjectFiles()');
    return this.httpClient
      .get<Array<ProjectFile>>('/projects/' + projectId + '/files', {});
  }

  /**
   * Delete files stores on SPIM of a project
   * @param projectId
   * @param fileId
   * @returns {Observable<any>}
   */
  deleteProjectFile(projectId, fileId): Observable<any> {

    this.logger.info('FilesService deleteProjectFiles()');
    return this.httpClient
      .delete('/projects/' + projectId + '/file/' + fileId, {responseType: 'text'});
  }

  /**
   * Update files stores on SPIM of a project
   * @param projectId
   * @param fileId
   * @returns {Observable<any>}
   */
  updateProjectFile(projectId, fileId): Observable<any> {

    this.logger.info('FilesService deleteProjectFiles()');
    return this.httpClient
      .delete('/projects/' + projectId + '/file/' + fileId, {});
  }

  /**
   * download files stores on SPIM of a project
   * @param projectId
   * @param fileId
   * @returns {Observable<any>}
   */
  downloadProjectFile(projectId, fileId): Observable<any> {
    this.logger.info('FilesService downloadProjectFile()');
    return this.httpClient
      .get('/projects/' + projectId + '/file/' + fileId, {responseType: 'blob', observe: 'response'});
  }
}
