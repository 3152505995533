export enum DocumentationCategory {
  COMMERCIAL = 'commercial',
  TECHNICAL = 'technical',
  COMMERCIAL_TECHNICAL = 'com_tech',
  ORDER = 'order',
  COMMERCIAL_TECHNICAL_BSL = 'bsl_com_tech',
  PROJECT_COMMERCIAL = 'project_commercial',
  CGV = 'cgv',
  PLANS = 'plans',
  DATASHEETS = 'datasheets',
  USER_GUIDE = 'user_guide',
  CAD = 'cad',
  // categories for documentation BSL OFFER DATA MANAGEMENT
  ENVIRONMENT = 'environment',
  CATALOGS = 'catalogs',
  PROMOTIONAL_MATERIALS = 'promotional_materials',
  TECHNICAL_PUBLICATIONS = 'technical_publications',
  APPLICATIONS_SOLUTIONS = 'applications_solutions',
  WHITE_PAPERS = 'white_papers',
  CERTIFICATES = 'certificates',
  SPECIFICATIONS = 'specifications',
  TRAINING = 'training'
}

/**
 * list of eventual extension
 */
export enum DocumentExtension {
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  ZIP = 'zip',
  CSV = 'csv',
  LINK = 'link'
}

/**
 * List of language code
 */
export enum DocumentLanguageCode {
  EN = 'en',
  FR = 'fr'
}
