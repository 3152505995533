<app-common-modal #childModal [title]="''" [name]="'configureModal'" [withFooter]="false" [twoButtonsDialogs]="false" [size]="'modal-xl'">
      <div *ngIf="component" modal-body >
          <div class="row">
            <div class="col-xs-8">
              <h5 translate>{{component?.name}}</h5>
            </div>
            <div *ngIf="!isDemoMode && !isCpqProject()" class="col-xs-4">
              <app-price class="pull-right inlineDisplay"
                   [price]="getPriceToSend()"
                   [mySEStatus]="utilService.getMySEStatusToSend(component)"
                   [priceLabel]="getPriceKeyLabel(component)">
              </app-price>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <p translate>{{component?.reference?.description}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-3" *ngIf="component?.type === cubicleType">
              <app-sld-component-viewer [component] = "component" [onCardsPanel]="true"></app-sld-component-viewer>
            </div>
          </div>

          <div class="row">
            <div class="container modal-tabs">
            <tabset>
              <!--to add for other countries-->
              <!-- We have to put ngIf=true  to keep the order of tab, otherwise ngIf tabs are after other tabs-->
              <tab *ngIf="true" heading="{{'T_CHARACTERISTICS' | translate}}" [active]="activeTab === characteristicsTab">
                <div [ngClass]="{ 'scrollable': true, 'tab-content-max-size': component?.type === cubicleType,'tab-content-max-size-transfo': component?.type === transfoType}">
                  <div *ngFor="let characteristic of component?.characteristics" class="margin-bottom">
                    <span class="text-top text-left"><span translate>{{characteristic.key}}</span> :</span>
                    <span class="indent-text span-blue" translate>{{characteristic.value}}</span>
                    <span class="span-blue" translate>{{characteristic.measurementUnit}}</span>
                  </div>
                </div>
              </tab>
              <tab *ngIf="showLayoutTab()" heading="{{'T_LAYOUT' | translate}}" class="tab-layout" [active]="activeTab === layoutsTab" >
                <div [ngClass]="{ 'scrollable': true, 'tab-content-max-size': component?.type === cubicleType,'tab-content-max-size-transfo': component?.type === transfoType}">
                  <div class="global-wrapper">
                    <div *ngFor="let layout of component.dimensions.layout" class="layout-tab-content">
                      <app-layout-component-viewer [layout]="layout"
                                                   [height] = "component.dimensions.height"></app-layout-component-viewer>
                    </div>
                  </div>
                  <div class="component-name">
                    <figcaption class="figcapt centred" translate>{{component.name}}</figcaption>
                  </div>
                </div>
              </tab>
              <tab heading="{{'T_TRANSFORMER' | translate}}" *ngIf="modalType === hasTransformer"
                   [active]="activeTab === transformerTab">
                <div class="scrollable tab-content-max-size">
                  <div *ngFor="let functionalUnit of component?.functionalUnits, let functionNumber = index">
                    <div *ngIf="functionalUnit.transformer">
                      <h5><span translate>T_TRANSFORMER_LINKED_TO_FUNCTION</span> <span translate>{{functionalUnit.name}}</span> <span translate>T_AT_POSITION</span>: <span>{{functionNumber + 1}}</span></h5>
                      <div *ngFor="let characteristic of functionalUnit.transformer.characteristics">
                        <p class="charac-design text-overflow">
                          <span  translate>{{characteristic.key}}</span> : <span class="indent-text span-blue" translate>{{characteristic.value}}</span> <span class="span-blue" translate>{{characteristic.measurementUnit}}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </tab>
              <tab *ngIf="componentDocuments.length > 0" heading="{{'T_DOCUMENTS' | translate}}" [active]="activeTab === documentsTab" >
                <div [ngClass]="{ 'scrollable': true, 'tab-content-max-size': component?.type === cubicleType,'tab-content-max-size-transfo': component?.type === transfoType}">
                  <app-card-documentation [documentations]="componentDocuments"></app-card-documentation>
                </div>
                </tab>
            </tabset>
          </div>
          </div>
        </div>
</app-common-modal>
