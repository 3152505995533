import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Package} from './package.model';

@Injectable()
export class PackageService {

  constructor(private readonly httpClient: HttpClient,) {
  }

  get(packageId): Observable<Package> {
    return this.httpClient.get<Package>('/package/'+ packageId);
  }

  getAllByPackageOffer(packageOfferId): Observable<Package[]> {
    return this.httpClient.get<Package[]>('/package/offerId/'+ packageOfferId);
  }
  create(pack: Package): Observable<Package> {
    return this.httpClient.post<Package>('/package', pack);
  }

  update(pack: Package): Observable<Package> {
    return this.httpClient.put<Package>('/package', pack);
  }

  delete(packageId: string): Observable<any> {
    return this.httpClient.delete<any>('/package/'+ packageId);
  }

}
