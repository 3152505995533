<h2 translate>T_NAV_ADMIN_DOCUMENTATION</h2>

<app-spinner *ngIf="loading || loadingTranslations" [sizeClass]="'large'"></app-spinner>
<div [style.visibility]="loading || loadingTranslations ? 'hidden':'visible'">
    <p-table #dt
             [value]="allDocuments"
             [paginator]="true" [rows]="10"
             [globalFilterFields]="['titleTranslated','fileName','extension', 'category']"
             [rowHover]="true">
        <ng-template pTemplate="caption">
            <div class="table-caption">
                <button class="btn action-button" (click)="onClickAddDocumentation()" translate><span
                        class="pi pi-plus create-icon"></span>T_DOCUMENTATION_CREATE
                </button>
                <div class="table-caption-search-bar p-input-icon-left ml-auto">
                    <span class="pi pi-search table-caption-search-bar-search-icon"></span>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="Search"/>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th scope="col" [pSortableColumn]="'titleTranslated'" translate>T_DOCUMENTATION_DISPLAY_NAME
                    <p-sortIcon field="titleTranslated"></p-sortIcon>
                </th>
                <th scope="col" [pSortableColumn]="'fileName'" translate>T_DOCUMENTATION_FILE_NAME
                    <p-sortIcon field="fileName"></p-sortIcon>
                </th>
                <th scope="col" [pSortableColumn]="'category'" translate>T_DOCUMENTATION_CATEGORY
                    <p-sortIcon field="category"></p-sortIcon>
                </th>
                <th scope="col" [pSortableColumn]="'lastUpdateDate'" translate>T_DOCUMENTATION_UPDATE_DATE
                    <p-sortIcon field="lastUpdateDate"></p-sortIcon>
                </th>
                <th scope="col" [pSortableColumn]="'extension'" translate>T_DOCUMENTATION_TYPE
                    <p-sortIcon field="extension"></p-sortIcon>
                </th>
                <th scope="col" translate>T_DOCUMENTATION_REF_LIST<span class="pi pi-info-circle info-icon" [tooltip]="getTranslation('T_DOCUMENTATION_LINKED_REFS_TOOLTIP')"></span></th>
                <th scope="col"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-doc>
            <tr>
                <td><span translate>{{doc.titleTranslated}}</span></td>
                <td><span>{{doc.localFileId ? doc.fileName+'.'+doc.extension : doc.fileName}}</span></td>
                <td><span>{{doc.category}}</span></td>
                <td><span>{{getLastUpdateDateFormatted(doc.lastUpdateDate)}}</span></td>
                <td><span>{{doc.extension}}</span></td>
                <td>
                    <span class="reference-count" [pTooltip]="getLinkedReferenceListAsString(doc)">{{doc.references? doc.references.length : '0'}}</span>
                    <span translate>T_DOCUMENTATION_LINKED_REFS</span>
                </td>
                <td>
                    <span *ngIf="doc.localFileId"><button class="btn action-button pi pi-download"
                                                          (click)="onClickDownloadDocument(doc)"
                                                          [tooltip]="getTranslation('T_DOWNLOAD')"></button></span>
          <span *ngIf="doc.localFileId"><button class="btn action-button pi pi-pencil"
                                                (click)="onClickEditDocumentation(doc)"
                                                [tooltip]="getTranslation('T_BUTTON_EDIT')"></button></span>
          <span *ngIf="doc.localFileId"><button class="btn action-button pi pi-link"
                                                (click)="onClickLinkRef(doc)"
                                                [tooltip]="getTranslation('T_DOCUMENTATION_LINK_REF')"></button></span>
          <span *ngIf="doc.localFileId"><button class="btn action-button pi pi-trash"
                                                (click)="onClickDeleteDocumentation(doc)"
                                                [tooltip]="getTranslation('T_BUTTON_DELETE')"></button></span>
        </td>
      </tr>
    </ng-template>
  </p-table>

</div>
<app-documentation-edit-modal #addDocumentationModal (documentationUpdated)="onDocumentationUpdated($event)">
</app-documentation-edit-modal>

<app-documentation-edit-modal #editDocumentationModal (documentationUpdated)="onDocumentationUpdated($event)">
</app-documentation-edit-modal>

<app-documentation-link-ref-modal #linkRefModal (clickedSaveLinkedRefs)="onClickSaveLinkedRefs($event)">
</app-documentation-link-ref-modal>

<app-common-modal #deleteDocumentationModal
                  [title]="'T_DOCUMENTATION_DELETE_MODAL_TITLE' | translate"
                  [name]="'T_DOCUMENTATION_DELETE_MODAL_TITLE'  | translate"
                  [description]="'T_DOCUMENTATION_DELETE_MODAL_DESCRIPTION' | translate"
                  [acceptInnerHtml]="true" (onClickYesButton)="deleteDocumentation()">
</app-common-modal>
