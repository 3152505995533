import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Project} from '../../project/shared/project-model';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-project-sharing-navbar',
  templateUrl: './project-sharing-navbar.component.html',
  styleUrls: ['./project-sharing-navbar.component.less', '../../../assets/less/navigation-bar-directive.less']
})
export class ProjectSharingNavbarComponent implements OnChanges {

  @Input()
  sharedProjects: Array<Project>;
  @Input()
  currentProject: Project;
  @Output()
  changeProject: EventEmitter<Project> = new EventEmitter<Project>();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.currentProject.currentValue !== null && changes.currentProject.previousValue &&
        changes.currentProject.currentValue.id !== changes.currentProject.previousValue.id)
      || isNullOrUndefined(changes.currentProject.previousValue)) {
      this.onChangeProject(changes.currentProject.currentValue);
    }
  }

  /**
   * Method call on click of element of the sharing navbar
   * @param {Project} project the new selected project
   */
  onChangeProject(project: Project) {
    // Set project as currentProject
    this.currentProject = project;

    // Raise event for parent component
    this.changeProject.emit(project);

  }
}
