import {Component, OnInit, ViewChild} from '@angular/core';
import {Range} from '../../../shared/model/range-model';
import {TranslateService} from '@ngx-translate/core';
import {PriceManagementService, UploadService} from '../../shared';
import {MessageService} from 'primeng/api';
import {LoggerService} from '../../../shared/logging/logger.service';
import {CommonModalComponent} from '../../../shared/common-modal/common-modal.component';
import {OfferService} from '../../../offers/shared/offer.service';
import {PartnerService} from '../../../shared/partner/partner.service';
import {SessionStorage} from 'ngx-webstorage';
import {User} from '../../../shared/user/user';
import {Localization} from '../../../shared/model/localization.model';

@Component({
  selector: 'app-quotation-setup',
  templateUrl: './quotation-setup.component.html',
  styleUrls: ['./quotation-setup.component.scss']
})
export class QuotationSetupComponent implements OnInit {

  @SessionStorage()
  localization: Localization;

  @SessionStorage()
  user: User;

  @ViewChild('inferiorDiscountModal') inferiorDiscountModal: CommonModalComponent;

  isLoading = false;
  selectedRange: Range;
  ranges: Range[];

  // Validity period expressed in days
  readonly MIN_VALIDITY_PERIOD = 0;
  readonly MAX_VALIDITY_PERIOD = 1000;
  readonly MIN_DISCOUNT_ALLOWED = 0;
  readonly MAX_DISCOUNT_ALLOWED = 100;
  readonly MIN_DELIVERY_ALLOWED = 0;
  readonly MAX_DELIVERY_ALLOWED = 30;

  discountAllowed: number;
  discountAllowedSaved: number;
  addDeliveryTime: number;

  maxDiscountByPartner = 0;

  partnerOfCountry: string[];


  constructor(private translateService: TranslateService,
              private uploadService: UploadService,
              private messageService: MessageService,
              private logger: LoggerService,
              private priceManagementService: PriceManagementService,
              private offerService: OfferService,
              private partnerService: PartnerService) { }

  ngOnInit(): void {
    this.offerService.getRangesBackOffice().subscribe(
      ranges => {
        this.isLoading = true;
        this.ranges = ranges;
        this.selectedRange = this.ranges[0];
        // Actually, discount limit is the same for all ranges
        this.discountAllowed = this.ranges[0].priceManagement?.discountAllowed;
        this.discountAllowedSaved = this.discountAllowed;
        this.addDeliveryTime = this.ranges[0].deliveryPolicy?.additionalTime;
        this.partnerService.getAllPartnerNameForCountry(this.user.partnerCountry).subscribe(partners => {
          this.isLoading = false;
          this.partnerOfCountry = partners
        });
      });
  }



  public onChangeValidityPeriod(value) {
    if (value > this.MAX_VALIDITY_PERIOD) {
      value = this.MAX_VALIDITY_PERIOD;
    } else {
      if (value < this.MIN_VALIDITY_PERIOD || value === '') {
        value = this.MIN_VALIDITY_PERIOD;
      }
    }
    this.selectedRange.priceManagement.validityPeriod = value;
  }

  public onChangeDiscountAllowed(element) {
    let value = element.value;
    if (value > this.MAX_DISCOUNT_ALLOWED) {
      value = this.MAX_DISCOUNT_ALLOWED;
    } else {
      if (value < this.MIN_DISCOUNT_ALLOWED || value === '') {
        value = this.MIN_DISCOUNT_ALLOWED;
      }
    }
    value = parseFloat(parseFloat(value).toFixed(2));
    if (value < (this.maxDiscountByPartner * 100)) {
      this.showInferiorDiscountModal(value);
      this.discountAllowed = 100 * this.maxDiscountByPartner;
    } else {
      this.discountAllowed = value;
    }

  }

  public onChangeAdditionalDeliveryTime(element) {
    let value = element.value;
    if (value > this.MAX_DELIVERY_ALLOWED) {
      value = this.MAX_DELIVERY_ALLOWED;
    } else {
      if (value < this.MIN_DISCOUNT_ALLOWED || value === '') {
        value = this.MIN_DISCOUNT_ALLOWED;
      }
    }
    this.selectedRange.deliveryPolicy ? this.selectedRange.deliveryPolicy.additionalTime = value : this.selectedRange.deliveryPolicy = { additionalTime: value, minimumTime: 0 };
  }


  public updateValidityPeriod() {
    this.isLoading = true;
    this.priceManagementService.updateValidityPeriod(this.selectedRange).subscribe(
      res => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_VAL_SUCC'),
        });
        return res;
      },
      error => {
        this.isLoading = false;
        this.logger.error(error);
        let errorMessage = 'T_VAL_ERR';
        if (error != null && error._body != null && JSON.parse(error._body).message != null) {
          errorMessage = JSON.parse(error._body).message;
        }
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant(errorMessage),
        });
      }
    );
  }

  public updateDiscountAllowed() {
    this.isLoading = true;
    this.priceManagementService.updateDiscountAllowed(this.localization, this.discountAllowed).subscribe(
      res => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_DISCOUNT_ALLOWED_SUCC'),
        });
        this.discountAllowedSaved = this.discountAllowed;
        return res;
      },
      error => {
        this.isLoading = false;
        this.logger.error(error);
        let errorMessage = 'T_DISCOUNT_ALLOWED_ERR';
        if (error != null && error._body != null && JSON.parse(error._body).message != null) {
          errorMessage = JSON.parse(error._body).message;
        }
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant(errorMessage),
        });
      }
    );
  }

  public updateAdditionDeliveryTime() {
    this.isLoading = true;
    this.priceManagementService.updateAdditionalDeliveryTime(this.localization, this.selectedRange.deliveryPolicy.additionalTime).subscribe(
      res => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_ADDITIONAL_DELIVERY_TIME_SUCCESS'),
        });
        return res;
      },
      error => {
        this.isLoading = false;
        this.logger.error(error);
        let errorMessage = 'T_ADDITIONAL_DELIVERY_TIME_ERROR';
        if (error != null && error._body != null && JSON.parse(error._body).message != null) {
          errorMessage = JSON.parse(error._body).message;
        }
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant(errorMessage),
        });
      }
    );
  }



  /**
   * Displays warning modal if discount is inferior than max discount by partner
   */
  private showInferiorDiscountModal(value: any) {
    this.inferiorDiscountModal.title = this.translateService.instant('T_PRICE_MANAGEMENT_DISCOUNT_INFERIOR_TITLE');
    this.inferiorDiscountModal.description = this.translateService.instant('T_PRICE_MANAGEMENT_DISCOUNT_INFERIOR' , {maxDiscount: value});
    this.inferiorDiscountModal.show();
  }
}
