import {Component, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HeaderService} from '../header/header.service';
import {SessionStorage, SessionStorageService} from 'ngx-webstorage';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {StaticDocumentationService} from '../../export/shared';
import {User} from '../../shared/user/user';
import {UtilService} from '../../shared/util/util.service';
import {RightsService} from '../../shared/rights/rights.service';
import {Localization} from '../../shared/model/localization.model';
import constant from '../../../../constant.json';

@Component({
  selector: 'app-about-modal',
  templateUrl: './about-modal.component.html',
  styleUrls: ['./about-modal.component.less']
})
export class AboutModalComponent implements OnInit {

  @SessionStorage()
  user: User;
  @SessionStorage()
  localization: Localization;

  year: string;
  frontVersion: string = constant.version;
  envType: string = environment.envType;
  backVersion;
  comToolsVersion;
  dbVersion;

  @ViewChild('aboutModal') aboutModal: CommonModalComponent;

  constructor(private headerService: HeaderService,
              private rightsService: RightsService,
              private staticDocService: StaticDocumentationService,
              private sessionStorageService: SessionStorageService,
              private utilService: UtilService) { }

  /**
   * Set current year for copyright
   * Retrieve versions when app are updated
   */
  ngOnInit() {
    const currentDate = new Date;
    this.year = currentDate.getFullYear().toString();
    this.loadVersion();
  }

  /**
   * Retrieve version of backend and db from backend
   */
  loadVersion() {
    this.headerService.getApplicationVersion().subscribe(applicationVersion => {
      this.backVersion = applicationVersion.backendBuildVersion;
      this.dbVersion = applicationVersion.dataModelVersion;
      this.comToolsVersion = applicationVersion.comtoolsVersion;
    });
  }

  /**
   * Show the modal
   */
  show() {
    this.aboutModal.show();
  }

  /**
   * Get term of use from doc service
   */
  getUrl() {
    return this.staticDocService.getTermsOfUseUrl(this.user);
  }

  /**
   * Get Schneider Electric Data Privacy Policy data url
   */
  getDataPrivacyPolicyUrl() {
    return this.utilService.getDataPrivacyPolicyUrl(this.user);
  }

  isCommToolAdminUser(): boolean {
    return this.rightsService.isComToolsAdmin();
  }

  isExtendedDemo() {
    return this.rightsService.isExtendedDemo();
  }

  getContactEmail(): string{
    return this.localization.supportContact.email;
  }
}
