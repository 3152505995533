import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TransformerQuickComponent} from './transformer-quick/transformer-quick.component';
import {TransformerModule} from '../transformer/transformer.module';
import {ConfigurationModule} from '../configuration/configuration.module';
import {EcorealCtSharedModule} from '../shared/shared.module';

@NgModule({
  imports: [
    TransformerModule,
    ConfigurationModule,
    CommonModule,
    EcorealCtSharedModule
  ],
  declarations: [TransformerQuickComponent]
})
export class TransformerV2Module { }
