import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SessionStorage} from 'ngx-webstorage';
import {Item, ItemValue, Project, Status} from '../../project/shared/project-model';
import {TreeNode} from "primeng/api";
import {NavigationStep} from '../../shared/guards/navigationStep-enum';
import {Reference} from '../../configuration/shared/model/reference';
import {ItemService} from '../../project/shared/item.service';
import {User} from '../../shared/user/user';
import {NavigationRoute} from '../../shared/guards/route-enum';
import {ProjectService} from '../../project/shared/project.service';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {Service} from '../../admin/shared/services/services.model';
import {Subject} from 'rxjs/Rx';
import {DatasheetsService} from '../../shared/datasheets/datasheets.service';
import {Documentation} from '../../shared/documentation/documentation.model';

@Component({
  selector: 'app-services-catalog-page',
  templateUrl: './services-catalog-page.component.html',
  styleUrls: ['./services-catalog-page.component.less'],
})
export class ServicesCatalogPageComponent implements OnInit, OnDestroy {

  @ViewChild('LooseModal') LooseModal: CommonModalComponent;

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  user: User;

  serviceData: TreeNode[] = [];

  toBeModifiedReference: string;

  status = Status;

  categoryNode: TreeNode;

  cols = [
    {label: 'T_SERVICE_CATALOG_CATEGORY_COLUMN_TITLE', field: 'category', styleClass: 'text-left col-xs-2 col-category'},
    {label: '', field: 'mandatory', styleClass: 'text-left col-xs-1'},
    {label: 'T_SERVICE_CATALOG_REFERENCE_COLUMN_TITLE', field: 'reference', styleClass: 'text-left col-xs-3'},
    {label: 'T_SERVICE_CATALOG_DESCRIPTION_COLUMN_TITLE', field: 'description', styleClass: 'text-left col-xs-5'},
    {label: 'T_DOC_DATASHEETS', field: 'datasheet', styleClass: 'text-center col-xs-1'}
  ];

  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(readonly itemService: ItemService,
              readonly projectService: ProjectService,
              private datasheetsService: DatasheetsService,
              readonly translateService: TranslateService,
              readonly messageService: MessageService) { }

  /**
   * Generate a new service node
   * @param category the category label
   */
  private static createCategoryNode(category: string): TreeNode {
    return {
      data: {category: category},
      type: 'category',
      children: []
    };
  }

  /**
   * Generate a new service node
   * @param service the service to display
   * @param doc datasheet information if exist
   * @param isLoading loading service
   */
  private static createServiceNode(service: Service, doc: Documentation, isLoading: boolean): TreeNode {
    return {
      data: {reference: service.reference, description: service.description, disabled: service.mandatory, doc: doc, isLoadingDoc: isLoading},
      type: 'service',
      children: []
    };
  }

  /**
   * Convert a service to a selectable itemValue
   * @param service th service to convert
   */
  private static convertServiceToItemValue(service: Service): ItemValue {
    const itemValue = new ItemValue();
    itemValue.quantity = 1;
    const reference = new Reference();
    reference.ref = service.reference;
    reference.price = service.price;
    reference.netPrice = service.price;
    reference.description = service.description;
    itemValue.reference = reference;
    itemValue.name = service.description;
    return itemValue;
  }

  ngOnInit() {
    this.currentItemct.currentNavigationStep = NavigationStep.SERVICES_CATALOG;
    if (this.currentItemct && this.currentItemct.status === Status.ordered && !this.projectService.isReadOnlyProject(this.currentProject, this.user)) {
      // Information Toast Ordered
      if (this.projectService.isCpqProject(this.currentProject)) {
        // Information Toast
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('T_ORDER_WARNING_TITLE'),
          detail: this.translateService.instant('T_PUSH_TO_CPQ_WARNING'),
        });
      } else {
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('T_ORDER_WARNING_TITLE'),
          detail: this.translateService.instant('T_ORDER_WARNING'),
        });
      }
    } else if (this.currentItemct.status === Status.quoted) {
    // Information Toast Loose data

      this.messageService.add({
        severity: 'warn',
        summary: this.translateService.instant('T_ORDER_WARNING_TITLE'),
        detail: this.translateService.instant('T_LOOSE_DATA_WARNING'),
      });
    }
    this.loadData();

    this.serviceData.forEach(node => node.expanded = true);

    this.currentItemct.maxNavigationStep =  NavigationRoute.getNextStepWithMaxByRange(this.currentItemct.range,
      this.currentItemct.currentNavigationStep, this.currentItemct.maxNavigationStep).id;

    this.itemService.updateItem(this.currentItemct, this.user)
      .takeUntil(this.unsubscribe$)
      .subscribe(
      item => {
        this.currentItemct = item;
        this.itemService.updateTotalPriceAndDimensions(this.unsubscribe$);
      }
    );
  }

  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    // unsubscribe from the subject itself:
    this.unsubscribe$.unsubscribe();
  }

  /**
   * Load or reload service data from range integrating the already selected services.
   */
  loadData() {
    this.currentItemct.range.services.forEach(service => {
      if (this.serviceData.every(node => node.data.category !== service.category)) {
        this.categoryNode = ServicesCatalogPageComponent.createCategoryNode(service.category);
        this.serviceData.push(this.categoryNode);
      } else {
        this.categoryNode = this.serviceData.find(node => node.data.category === service.category);
      }
      this.datasheetsService.getReferenceDatasheetUrl(service.reference, this.user.preferredLanguage)
        .takeUntil(this.unsubscribe$)
        .toPromise()
        .then(doc => {
            const treeNode = this.serviceData.find(sd => sd.children.some(node => node.data.reference === service.reference));
            if (treeNode === null || treeNode === undefined) {
              return;
            }
            const line = treeNode.children.find(node => node.data.reference === service.reference);
            if (line !== null && line !== undefined) {
              line.data.doc = doc;
              line.data.isLoadingDoc = false;
            }
          }
        )
        .catch(() => this.serviceData.forEach(serviceData => serviceData.children.forEach(node => {
          if (node.data != null) {
            node.data.isLoadingDoc = false;
          }
        })));

      this.categoryNode.children.push(ServicesCatalogPageComponent.createServiceNode(service, null, true));
      if (service.mandatory && this.currentItemct.selectedServices.every(servRef => servRef.reference.ref !== service.reference)) {
        this.currentItemct.selectedServices.push(ServicesCatalogPageComponent.convertServiceToItemValue(service));
      }
    });
  }

  hasDatasheet(doc: Documentation): boolean {
    return doc !== null && doc !== undefined && doc.link !== null && doc.link !== undefined;
  }

  downloadDatasheet(link: string) {
    window.open(link);
  }
  /**
   * Modal for confirming modification
   * @param reference
   */
  modificationConfirmation(reference: string) {
    if (this.currentItemct.status === Status.quoted) {
      this.toBeModifiedReference = reference;
      this.LooseModal.show();
    } else {
      this.toggleService(reference);
    }
  }

  /**
   * Call by loose data modal on user cancel
   */
  reinitData() {
    this.serviceData = this.serviceData.map(categNode => {
      categNode.children = [];
      return categNode;
    });
    this.loadData();
  }
  /**
   * Method called if the user select yes on the loose data popup
   */
  confirmLooseData(reference: string) {
    this.currentItemct.maxNavigationStep =  NavigationRoute.getNextStepByRange(this.currentItemct.range, this.currentItemct.currentNavigationStep).id;
    this.toggleService(reference);
  }

  /**
   * Function that allow to know if a service is currently selected
   * @param reference of the service
   */
  isSelected(reference: string): boolean {
    return this.currentItemct.selectedServices.some(service => reference === service.reference.ref);
  }

  /**
   * Used to engage read only mode for the interface
   */
  isReadonlyProject(): boolean {
    return this.projectService.isReadOnlyProject(this.currentProject, this.user);
  }

  /**
   * Select or deselect service
   * @param reference the reference of the service
   */
  private toggleService(reference: string) {
    const modifiedService = ServicesCatalogPageComponent.convertServiceToItemValue(this.currentItemct.range.services.find(service => service.reference === reference));
    if (this.currentItemct.selectedServices.some(service => reference === service.reference.ref)) {
      // Unselect the service
      this.currentItemct.selectedServices = this.currentItemct.selectedServices.filter(service => reference !== service.reference.ref);
    } else {
      // Select the service
      this.currentItemct.selectedServices.push(modifiedService);
    }
    this.currentItemct.status = Status.configured;
    this.itemService.updateItem(this.currentItemct, this.user)
      .takeUntil(this.unsubscribe$)
      .subscribe(
      item => {
        this.currentItemct = item;
        this.itemService.updateTotalPriceAndDimensions(this.unsubscribe$);
      });
  }

}
