import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Range, RangeStatus} from '../../../../../shared/model/range-model';
import {OfferDataManagementService} from '../../../offer-data-management.service';
import {CommonModalComponent} from '../../../../../shared/common-modal/common-modal.component';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {OfferService} from '../../../../../offers/shared/offer.service';
import {Router} from '@angular/router';
import {NavigationLinkEnum} from '../../../../../shared/guards/navigationLink-enum';
import {AccessoriesService} from '../../../../../shared/services/accessories.service';

@Component({
  selector: 'app-odm-add-offer-publication',
  templateUrl: './odm-add-offer-publication.component.html',
  styleUrls: ['./odm-add-offer-publication.component.less'],
})
export class OdmAddOfferPublicationComponent {

  @Input()
  selectedRange: Range;

  @Output()
  rangeIsPublished: EventEmitter<Range> = new EventEmitter<Range>();

  @ViewChild('testOfferModal') testOfferModal: CommonModalComponent;

  @ViewChild('publishOfferModal') publishOfferModal: CommonModalComponent;

  constructor(private readonly offerDataManagementService: OfferDataManagementService,
              private readonly offerService: OfferService,
              private readonly characteristicsService: AccessoriesService,
              private messageService: MessageService,
              private readonly translate: TranslateService,
              private readonly router: Router) { }

  /**
   * Method that will refresh range info in angular cache
   */
  refreshOfferInfo() {
    this.offerService.getRanges().subscribe(() => {
        this.router.navigate([NavigationLinkEnum.PROJECTS]);
    });
  }

  /**
   * Method to show the modal according to the button which is clicked
   * @param modalType
   */
  showModal(modalType: string) {
    switch (modalType) {
      case 'TEST':
        this.testOfferModal.show();
        break;
      case 'PUBLISH':
        this.publishOfferModal.show();
        break;
    }
  }
  testButton() {
    this.selectedRange.status = RangeStatus.TEST;
    this.offerDataManagementService.updateRange(this.selectedRange).subscribe(range => this.selectedRange = range,
      () => this.postTestError(),
      () => this.postTestSuccess());
  }

  publishButton() {
    this.offerDataManagementService.updateRange({...this.selectedRange, status: RangeStatus.PUBLISHED, active: true, offerDataManagementConfigurationStep: null }).subscribe(range => {
      this.rangeIsPublished.emit(range);
    });
  }

  /**
   * Method called when the test of a reference is in error
   */
  private postTestError() {
    this.messageService.add({
      severity: 'error',
      summary: this.translate.instant('T_ERROR'),
      detail: this.translate.instant('T_OFFER_DATA_MANAGEMENT_ERROR_TEST_OFFER'),
    });
  }

  /**
   * Method called when the test of a reference is in success
   */
  private postTestSuccess() {
    this.offerService.getRanges().subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant('T_INFO'),
        detail: this.translate.instant('T_OFFER_DATA_MANAGEMENT_SUCCESS_TEST_OFFER'),
      });
      this.router.navigate([NavigationLinkEnum.PROJECTS]);
    });
  }
}
