/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
/* ngx modules */
import {Distributor} from '../../../bom/shared/distributor';
import {LoggerService} from '../../../shared/logging/logger.service';

@Injectable()
export class DistributorService {

  constructor(private httpClient: HttpClient,
              private logger: LoggerService) {
  }

  /**
   * Get distributor list in user's country
   *
   * @returns {Observable<Distributor[]>}
   */
  getDistributors(): Observable<Distributor[]> {
    this.logger.debug('DistributorService getDistributors');

    return this.httpClient.get<Distributor[]>('/distributor');
  }

  /**
   * Delete a distributor
   *
   * @param distributorName distributor id
   * @returns {Observable<Response>}
   */
  deleteDistributor(distributorId): Observable<any> {
    this.logger.info('DistributorService deleteDistributors ' + distributorId);

    return this.httpClient.delete('/distributor/delete/' + distributorId, {responseType: 'text'});
  }

  /**
   * Create a distributor
   *
   * @param distributor the distributor to create
   * @returns {Observable<Response>}
   */
  createDistributor(distributor: Distributor): Observable<any> {
    this.logger.info('DistributorService createDistributors ' + distributor.name);

    return this.httpClient.post('/distributor/', distributor);
  }

  /**
   * Update a distributor
   *
   * @param distributor the distributor to update
   * @returns {Observable<Response>}
   */
  updateDistributor(distributor: Distributor): Observable<any> {
    this.logger.info('DistributorService createDistributors ' + distributor.name);
    return this.httpClient.post('/distributor/update/', distributor);
  }
}
