<div  class="modal fade" bsModal #childModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
      ignoreBackdropClick="true" aria-hidden="true" hidden="hidden">
  <div class="modal-dialog modal-lg">

      <section class="register-modal">
        <!--HEADER-->
        <div class="modal-header">
          <button type="button" class="modal-close" (click)="hide()">
            <span class="fa-stack">
              <em class="fa fa-times-thin fa-2x"></em>
            </span>
          </button>
          <h4 class="modal-title">{{'T_DEMO_VERSION_REGISTER_TITLE' | translate}}</h4>
        </div>

        <!--BODY-->
        <div class="body-margin">
          <!-- message -->
          <div class="message-margin">
            {{modalMessage}}
          </div>

          <div *ngIf="isValidateMode && countryNotFound" class="select-country">
            <div class="col-xs-5">
              <select class="form-control" id="localization" required
                      (change)="selectLocalization($event.target.value)">
                <option disabled selected [value]=""></option>
                <option *ngFor="let loc of allLocalizations" [value]="loc.country"
                        translate>{{getLocalizationCountryNameKey(loc.country)}}</option>
              </select>
            </div>
          </div>


          <form *ngIf="isValidateMode" class="form-horizontal message-margin" [formGroup]="form">
            <div class="form-group">
              <label for="email" class="col-xs-4 control-label text-left field-mandatory" translate>T_DEMO_VERSION_REGISTER_EMAIL</label>
              <div class="col-xs-8">
                <input type="text"
                       id="email"
                       class="form-control"
                       formControlName="email"
                       [(ngModel)]="email"
                       [ngClass]="{'red-border-class': form.get('email').hasError('required') || form.get('email').hasError('pattern')}"/>
                <div *ngIf="email !== null && form.get('email').hasError('required') || form.get('email').hasError('pattern')">
                  <span class="input-error" translate>T_DEMO_VERSION_REGISTER_EMAIL_REQUIRED</span>
                </div>
              </div>
            </div>


            <div class="form-group">
              <label for="firstName" class="col-xs-4 control-label text-left field-mandatory" translate>T_DEMO_VERSION_REGISTER_FIRST_NAME</label>
              <div class="col-xs-8">
                <input type="text"
                       id="firstName"
                       class="form-control"
                       formControlName="firstName"
                       [(ngModel)]="firstName"
                       [ngClass]="{'red-border-class': form.get('firstName').hasError('required') || form.get('firstName').hasError('pattern')}"/>
                <div *ngIf="firstName !== null && form.get('firstName').hasError('required')">
                  <span class="input-error" translate>T_DEMO_VERSION_REGISTER_FIRST_NAME_REQUIRED</span>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="lastName" class="col-xs-4 control-label text-left field-mandatory" translate>T_DEMO_VERSION_REGISTER_LAST_NAME</label>
              <div class="col-xs-8">
                <input type="text"
                       id="lastName"
                       class="form-control"
                       formControlName="lastName"
                       [(ngModel)]="lastName"
                       [ngClass]="{'red-border-class': form.get('lastName').hasError('required') || form.get('lastName').hasError('pattern')}"/>
                <div *ngIf="lastName !== null && form.get('lastName').hasError('required')">
                  <span class="input-error" translate>T_DEMO_VERSION_REGISTER_LAST_NAME_REQUIRED</span>
                </div>
              </div>
            </div>


            <div class="form-group">
              <label for="companyName" class="col-xs-4 control-label text-left field-mandatory" translate>T_DEMO_VERSION_REGISTER_COMPANY_NAME</label>
              <div class="col-xs-8">
                <input type="text"
                       id="companyName"
                       class="form-control"
                       formControlName="companyName"
                       [(ngModel)]="companyName"
                       [ngClass]="{'red-border-class': form.get('companyName').hasError('required') || form.get('companyName').hasError('pattern')}"/>
                <div *ngIf="companyName !== null && form.get('companyName').hasError('required')">
                  <span class="input-error" translate>T_DEMO_VERSION_REGISTER_COMPANY_NAME_REQUIRED</span>
                </div>
              </div>
            </div>


            <div class="form-group">
              <label class="col-xs-4 control-label text-left double-stars" translate>T_DEMO_VERSION_REGISTER_ACCOUNT_NUMBER</label>
              <div class="col-xs-8">
                <input type="text"
                       id="accountNumber"
                       class="form-control"
                       formControlName="accountNumber"
                       [(ngModel)]="accountNumber"/>
              </div>
            </div>

            <div class="form-group more-information">
              <div class="message-margin double-stars"></div>
              <label translate>T_DEMO_VERSION_REGISTER_ACCOUNT_NUMBER_MORE_INFORMATION</label>
            </div>


          </form>

        </div>

        <!--FOOTER-->
        <div class="modal-footer">
          <button class="btn register-button" *ngIf="isRegisterMode" (click)="clickRegisterButton()" translate>T_DEMO_VERSION_BUTTON_REGISTER</button>
          <button class="btn register-button" *ngIf="isValidateMode"
                  [disabled]= "!userContactCountry || !form.valid"
                  (click)="clickValidateButton(); hide()" translate>T_DEMO_VERSION_BUTTON_VALIDATE</button>
          <button class="btn register-button" (click)="clickCancelButton(); hide()" translate>T_BUTTON_CANCEL</button>
        </div>
      </section>

  </div>
</div>
