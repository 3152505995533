<p>
  <!-- PRICE LABEL -->
  <span translate>{{priceLabel}}</span>:

  <!-- PRICE VALUE -->
  <span *ngIf="isValidMySEPriceFromStatus()" class="indent">
    {{price | priceFormat}} {{localization?.displayedCurrency}}
  </span>

  <!-- PRICE ERROR ICON -->
  <span *ngIf="!isValidMySEPriceFromStatus()" class="fa fa-exclamation-circle brand-warning"
        tooltip="{{(utilService.getTooltipMySEMessageFromStatus(mySEStatus))}}" placement="left" container="body">
  </span>
</p>
