import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PackageOfferService} from '../shared';
import {Package, PackageOffer} from '../shared/package/package.model';
import {OfferDataManagementService} from '../offer-data-management/offer-data-management.service';
import {Range, RangeStatus} from '../../shared/model/range-model';
import {SwitchBoardComponent} from '../../configuration/shared/model/component';
import {MessageService, SelectItem} from 'primeng/api';
import {BusinessStatus, Reference} from '../../configuration/shared/model/reference';
import {cloneDeep} from 'lodash';
import {UtilService} from '../../shared/util/util.service';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {AccessoriesService} from '../../shared/services/accessories.service';
import {PackageService} from '../shared';

@Component({
  selector: 'app-package-management',
  templateUrl: './package-management.component.html',
  styleUrls: ['./package-management.component.less']
})

export class PackageManagementComponent implements OnInit {
  loading = true;
  packageOffer: PackageOffer;
  packages: Package[] = [];
  newPackageName: string;
  renamePackageName: string;
  availableRanges: Range[] = [];
  availableSwitchboards: SwitchBoardComponent[];
  availableAccessoriesReferences: Reference[];

  selectItemsRange: SelectItem[];
  selectItemsSwitchboards: SelectItem[];
  selectItemsAccessoriesReferences: SelectItem[];
  selectItemsOptionalAccessoriesReferences: SelectItem[];

  selectedRange: string;
  selectedSwitchboard: string;
  selectedAccessoryReference: string;
  selectedOptionalAccessoryReference: string;

  currentPackageId: string;
  currentSection: {
    section: 'RENAME'|'ADD'|'DELETE'|'PUBLISH'|'DUPLICATE';
    packageId: string;
  };

  @ViewChild('deletePackageConfirmationModal') deletePackageConfirmationModal: CommonModalComponent;

  constructor(
    private translateService: TranslateService,
    private packageOfferService: PackageOfferService,
    private packageService: PackageService,
    private offerDataManagementService: OfferDataManagementService,
    private accessoriesService: AccessoriesService,
    private utilService: UtilService,
    private messageService: MessageService) {}

  ngOnInit(): void {
    this.getPackageOffer();
    this.getAvailableRanges();
  }

  // DATA

  getPackageOffer() {
    this.packageOfferService.getAll().subscribe((packageOffers) => {
      this.packageOffer = packageOffers.find(offer => offer.type === 'STANDARD');
      if(this.packageOffer){
        this.getPackages(this.packageOffer);
      }
      this.loading = false;
    });
  }

  getPackages(packageOffer: PackageOffer){
    packageOffer.packages.forEach(packageId => {
      this.packageService.get(packageId).subscribe(pack => {
        this.packages.push(pack);
        pack.switchboardComponents.forEach(sw => {
          console.log(sw.reference.ref);
        })
      })
    })
  }

  getAvailableRanges() {
    this.offerDataManagementService.getAllExpressRanges().subscribe(ranges => {
      this.availableRanges = ranges.filter(range => range.status === RangeStatus.PUBLISHED && range.nameKey !== 'T_ACCESSORIES_FR');
      this.selectItemsRange = [{value: null, label: this.translateService.instant('T_PACKAGE_RANGE_SELECT_PLACEHOLDER'), disabled: true}];
      this.selectItemsRange = this.selectItemsRange.concat(this.availableRanges.map(range => ({value: range.id, label: this.translateService.instant(range.nameKey)})));
    });
  }

  getAvailableSwitchboards(rangeId: string) {
    this.offerDataManagementService.getComponentsReferences(rangeId).subscribe(switchboards => {
      this.availableSwitchboards = switchboards.filter(switchboard => switchboard.reference.status === BusinessStatus.PUBLISHED);
      this.selectItemsSwitchboards = this.selectItemsSwitchboards.concat(
        this.availableSwitchboards.map(
          switchboard => ({value: switchboard.id, label: switchboard.reference.ref})
        )
      );
    });
  }

  getAvailableAccessories(switchboard: SwitchBoardComponent) {
    switchboard.options.forEach(option => {
      option.values.forEach(value => {
        if (value.reference) {
          this.availableAccessoriesReferences.push(value.reference);
        }
      });
    });

    this.accessoriesService.getAccessories(this.selectedRange).subscribe(accessoriesCharacteristics => {
      accessoriesCharacteristics.forEach(accessoryCharacteristic => {
        accessoryCharacteristic.values.forEach(value => {
          if (value.reference) {
            this.availableAccessoriesReferences.push(value.reference);
          }
        });
      });


      this.selectItemsAccessoriesReferences = this.selectItemsAccessoriesReferences
        .concat(this.availableAccessoriesReferences
          .map(reference => ({value: reference.ref, label: reference.ref})));

      this.selectItemsOptionalAccessoriesReferences = cloneDeep(this.selectItemsAccessoriesReferences);

    });
  }

  resetSwitchboards() {
    this.selectItemsSwitchboards = [{value: null, label: this.translateService.instant('T_PACKAGE_SWITCHBOARD_SELECT_PLACEHOLDER'), disabled: true}];
    this.availableSwitchboards = [];
    this.selectedSwitchboard = null;
  }

  resetAccessories() {
    this.selectItemsOptionalAccessoriesReferences = [{value: null, label: this.translateService.instant('T_PACKAGE_ACCESSORY_OPTION_SELECT_PLACEHOLDER'), disabled: true}];
    this.selectItemsAccessoriesReferences = [{value: null, label: this.translateService.instant('T_PACKAGE_ACCESSORY_REFERENCE_SELECT_PLACEHOLDER'), disabled: true}];
    this.availableAccessoriesReferences = [];
    this.selectedAccessoryReference = null;
    this.selectedOptionalAccessoryReference = null;
  }

  deletePackage(){
    this.packageService.delete(this.currentPackageId).subscribe(()=>{
      this.packages = this.packages.filter(pack => pack.id !== this.currentPackageId);
      this.packageOffer.packages = this.packageOffer.packages.filter(packId => packId !== this.currentPackageId);
      this.updatePackageOffer();
      this.loading = false;
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('T_INFO'),
        detail: this.translateService.instant('T_ADMIN_OFFER_DATA_MANAGEMENT_SUCCESS_DELETING_PACKAGE'),
      });
    },
    () => {
      this.loading = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('T_ERROR'),
        detail: this.translateService.instant('T_ADMIN_OFFER_DATA_MANAGEMENT_ERROR_DELETING_PACKAGE'),
      });
    });
  }

  updatePackageOffer(){
    console.log(this.packageOffer);
    this.packageOfferService.update(this.packageOffer).subscribe((packageOffer)=>{
      this.packageOffer = packageOffer;
    });
  }

  // ACTIONS

  onClickingPackage(packageId: string) {
    this.selectedRange = null;
    this.selectedSwitchboard = null;
    this.selectedAccessoryReference = null;
    this.selectedOptionalAccessoryReference = null;
    this.renamePackageName = null;

    if (this.currentPackageId === packageId) {
      this.currentPackageId = null;
    } else {
      this.currentPackageId = packageId;
    }
  }

  onAddPackageOfferButton() {
    this.loading = true;
    this.packageOfferService.create().subscribe((packageOffer) => {
      this.packageOffer = packageOffer;
      this.loading = false;
      this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_ADMIN_OFFER_DATA_MANAGEMENT_SUCCESS_CREATING_PACKAGE'),
        });
      },
      () => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_ADMIN_OFFER_DATA_MANAGEMENT_ERROR_CREATING_PACKAGE'),
        });
      });
  }

  onAddPackageButton() {
    this.loading = true;

    const newPackage: Package = {
      name: this.newPackageName,
      published: false,
      switchboardComponents: [],
      accessoriesReferencesMap: new Map<string, Reference[]>(),
      optionalAccessoriesReferencesMap: new Map<string, any[]>(),
      characteristics: new Map<string, string>(),
      orderIndex: 0
    };
    newPackage.characteristics["T_NAME"] = this.newPackageName;


    this.packageService.create(newPackage).subscribe(
      pack => {
        this.packageOffer.packages.push(pack.id);
        this.updatePackageOffer();
        this.newPackageName = null;

        this.packages.push(pack);
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_ADMIN_OFFER_DATA_MANAGEMENT_SUCCESS_ADDING_PACKAGE'),
        });
        this.loading = false;


      }, () => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_ADMIN_OFFER_DATA_MANAGEMENT_ERROR_ADDING_PACKAGE'),
        });
      }

    );


  }

  onRenamePackageButton() {
    this.packages.forEach(pack => {
      if (pack.id === this.currentPackageId) {
        pack.name = this.renamePackageName;
        pack.characteristics["T_NAME"] = this.renamePackageName;
        this.packageService.update(pack).subscribe(packUpdated => {
          pack = packUpdated;
          this.showSuccessfulUpdateMessage();
        }, () => {
          this.showFailedUpdateMessage();
        });
      }
    });
  }

  onDeletePackageButton() {
    this.deletePackageConfirmationModal.show();
  }

  onDuplicatePackageButton() {
    const packageDupe: Package = cloneDeep(this.packages.find((pack) => pack.id === this.currentPackageId));
    packageDupe.id = null;
    packageDupe.name = packageDupe.name + ' - copy';
    packageDupe.published = false;
    packageDupe.characteristics["T_NAME"] = packageDupe.name;

    this.packageService.create(packageDupe).subscribe(pack => {
      this.packages.push(pack);
      this.packageOffer.packages.push(pack.id);
      this.updatePackageOffer();
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('T_INFO'),
        detail: this.translateService.instant('T_NAV_ADMIN_PACKAGE_DUPLICATE_SUCCESS_MESSAGE'),
      });
    });
  }

  onPublishSwitchButton() {
    this.packages.forEach(pack => {
      if (pack.id === this.currentPackageId) {
        this.packageService.update(pack).subscribe(packUpdated => {
          pack = packUpdated;
          this.showSuccessfulUpdateMessage();
        }, () => {
          this.showFailedUpdateMessage();
        });
      }
    });
  }

  onSelectedRangeChange() {
    this.resetSwitchboards();
    this.resetAccessories();
    this.getAvailableSwitchboards(this.selectedRange);
  }

  onSelectedSwitchboardChange() {
    this.resetAccessories();

    const switchboard: SwitchBoardComponent = this.availableSwitchboards.find(availableSwitchboard => availableSwitchboard.id === this.selectedSwitchboard);
    this.getAvailableAccessories(switchboard);
  }

  onAddSwitchboardComponentButton(packageId: string, switchboardId: string) {
    const switchboardToAdd: SwitchBoardComponent = this.availableSwitchboards.find(switchboard => switchboard.id === switchboardId);
    this.packages.forEach(pack => {
      if(pack.id === packageId){
        pack.switchboardComponents.push(switchboardToAdd);
        this.packageService.update(pack).subscribe(packageUpdated => {
          pack = packageUpdated;
          this.showSuccessfulUpdateMessage();
        }, () => {
          this.showFailedUpdateMessage();
        });
      }
    })
  }

  onAddAccessoryReferenceButton(packageId: string, switchboardId: string, accessoryReferenceId: string) {
    const accessoryReferenceToAdd: Reference = this.availableAccessoriesReferences
      .find(accessoryReference => accessoryReference.ref === accessoryReferenceId);
      this.packages.forEach(pack => {
        if (pack.id === packageId) {
          if (pack.accessoriesReferencesMap[switchboardId]) {
            pack.accessoriesReferencesMap[switchboardId].push(accessoryReferenceToAdd);
          } else {
            pack.accessoriesReferencesMap[switchboardId] = [accessoryReferenceToAdd];
          }
          this.packageService.update(pack).subscribe(packageUpdated => {
            pack = packageUpdated;
            this.showSuccessfulUpdateMessage();
          }, () => {
            this.showFailedUpdateMessage();
          });
        }
      });
  }

  onAddOptionalAccessoryButton(packageId: string, switchboardId: string, accessoryReferenceId: string) {
    const accessoryReferenceToAdd: Reference = this.availableAccessoriesReferences
      .find(accessoryReference => accessoryReference.ref === accessoryReferenceId);
    this.packages.forEach(pack => {
      if (pack.id === packageId) {
        if (pack.optionalAccessoriesReferencesMap[switchboardId]) {
          pack.optionalAccessoriesReferencesMap[switchboardId].push(accessoryReferenceToAdd);
        } else {
          pack.optionalAccessoriesReferencesMap[switchboardId] = [accessoryReferenceToAdd];
        }
        this.packageService.update(pack).subscribe(packageUpdated => {
          pack = packageUpdated;
          this.showSuccessfulUpdateMessage();
        }, () => {
          this.showFailedUpdateMessage();
        });
      }
    });
  }

  onDeleteSwitchboardRefButton(index: number) {
    this.packages.forEach(pack => {
      if (pack.id === this.currentPackageId) {
        pack.switchboardComponents.splice(index, 1);
        if (pack.switchboardComponents.length === 0) {
          pack.published = false;
        }
        this.packageService.update(pack).subscribe(packageUpdated => {
          pack = packageUpdated;
          this.showSuccessfulUpdateMessage();
        }, () => {
          this.showFailedUpdateMessage();
        });
      }
    });
  }

  onDeleteAccessoryRefButton(switchboardId: string , index: number) {
    this.packages.forEach(pack => {
      if (pack.id === this.currentPackageId) {
        pack.accessoriesReferencesMap[switchboardId].splice(index, 1);
        if (pack.accessoriesReferencesMap[switchboardId].length === 0) {
          delete pack.accessoriesReferencesMap[switchboardId];
        }
        this.packageService.update(pack).subscribe(packageUpdated => {
          pack = packageUpdated;
          this.showSuccessfulUpdateMessage();
        }, () => {
          this.showFailedUpdateMessage();
        });
      }
    });
  }

  onDeleteOptionalAccessoryRefButton(switchboardId: string , index: number) {
    this.packages.forEach(pack => {
      if (pack.id === this.currentPackageId) {
        pack.optionalAccessoriesReferencesMap[switchboardId].splice(index, 1);
        if (pack.optionalAccessoriesReferencesMap[switchboardId].length === 0) {
          delete pack.optionalAccessoriesReferencesMap[switchboardId];
        }
        this.packageService.update(pack).subscribe(packageUpdated => {
          pack = packageUpdated;
          this.showSuccessfulUpdateMessage();
        }, () => {
          this.showFailedUpdateMessage();
        });
      }
    });
  }

  onOpeningActionSection(packageId: string, section: 'RENAME'|'ADD'|'DELETE'|'PUBLISH'|'DUPLICATE') {
    if (this.currentSection && this.currentSection.packageId === packageId && this.currentSection.section === section) {
      this.currentSection = null;
    } else {
      this.currentSection = {
        packageId,
        section
      };
    }
  }

  // UI
  showPackageContent(packageId: string) {
    return this.currentPackageId === packageId;
  }

  showSection(packageId: string, section: 'RENAME'|'ADD'|'DELETE'|'PUBLISH'|'DUPLICATE') {
    return this.currentSection && this.currentSection.packageId === packageId && this.currentSection.section === section;
  }

  canPublish() {
    const currentPack = this.packages.find(pack => pack.id === this.currentPackageId);
    return currentPack
      && (currentPack.published
        || currentPack.switchboardComponents.length > 0);
  }

  isValidName(textValue: string) {
    return textValue && textValue.length < 30 && textValue.match('^[a-zA-Z0-9 ]+$') !== null;
  }

  getInputNamePlaceholder() {
    return this.translateService.instant('T_PACKAGE_NAME_PLACEHOLDER');
  }

  getAddComponentHelpMessage() {
    return this.translateService.instant('T_PACKAGE_ADD_COMPONENT_HELP_MESSAGE');
  }

  showSuccessfulUpdateMessage() {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('T_INFO'),
      detail: this.translateService.instant('T_ADMIN_OFFER_DATA_MANAGEMENT_SUCCESS_UPDATING_PACKAGE'),
    });
  }
  showFailedUpdateMessage() {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_ERROR'),
      detail: this.translateService.instant('T_ADMIN_OFFER_DATA_MANAGEMENT_ERROR_UPDATING_PACKAGE'),
    });
  }
}
