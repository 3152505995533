import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap';
import {LoggerService} from '../logging/logger.service';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../user/user';
import {SessionStorage} from 'ngx-webstorage';
import {StaticDocumentationService} from '../../export/shared';
import {Role} from '../../core/access-control/enum';
import {RightsService} from '../rights/rights.service';
import {Localization} from '../model/localization.model';
import {AuthService} from "../auth/auth.service";

@Component({
  selector: 'app-terms-of-use-modal',
  templateUrl: './terms-of-use-modal.component.html',
  styleUrls: ['./terms-of-use-modal.component.less']
})
export class TermsOfUseModalComponent  implements AfterViewInit {
  @ViewChild('childModal') public childModal: ModalDirective;
  showTitle = false;
  accepted = false;
  @Input() name: string;
  @Input() description: string;
  @Output() onClickAcceptButton = new EventEmitter<string>();
  @Output() onClickRefusedButton = new EventEmitter<string>();

  @SessionStorage()
  user: User;
  @SessionStorage()
  localization: Localization;

  constructor(private logger: LoggerService,
              private rightsService: RightsService,
              private staticDocService: StaticDocumentationService,
              private authService: AuthService,
              private translateService: TranslateService) {
  }

  ngAfterViewInit() {
    const modalOptions = this.childModal.config;
    modalOptions.backdrop = 'static';
    this.childModal.config = modalOptions;
  }

  show() {
    if (!this.name) {
      this.logger.debug('Name of common modal is null');
      throw new Error(this.translateService.instant('T_NAME_NOT_EMPTY'));
    }
    this.accepted = this.user.termsOfUseAccepted;
    this.childModal.show();
  }

  hide() {
    this.childModal.hide();
  }

  clickAcceptButton() {
    this.onClickAcceptButton.emit(this.name);
  }

  clickRefusedButton() {
    this.onClickRefusedButton.emit(this.name);
  }

  /**
   * Get term of use from doc service
   */
  getUrl() {
    return this.staticDocService.getTermsOfUseUrl(this.user);
  }

  /**
   * Function to log the logout action
   */
  logout() {
    this.logger.business('Log out');
    this.authService.logout();
  }

  isExtendedDemo() {
    return this.rightsService.isExtendedDemo();
  }

  getContactEmail(): string{
    return this.localization.supportContact.email;
  }
}
