/* Angular modules */
import {Component, Input, OnInit} from '@angular/core';

import {DocumentationListComponent} from "../../export";


@Component({
  selector: 'app-card-documentation-list',
  templateUrl: './card-documentation-list.component.html',
  styleUrls: ['./card-documentation-list.component.less'],
})
export class CardDocumentationListComponent extends DocumentationListComponent {

}
