
<app-common-modal #childModalValidate [name]="'editName'" [title]=" " [twoButtonsDialogs]="true" [withFooter]="false">
  <h4 modal-title class="modal-title" translate>T_NAME</h4>
  <app-spinner *ngIf="isLoading" [sizeClass]="'medium'"></app-spinner>
  <div modal-body *ngIf="!isLoading">
    <div class="row" *ngFor="let kv of translationsByLanguages">
      <div class="content-container" >
        <div  class="input-edit-translation">
          <span class="col-xs-2">{{kv.getKey()}}</span>
          <input id="sld" type="text" [value]="kv.getValue()" (change)="tranlationChange(kv.getKey(), $event)" class="edit-input col-xs-10">
        </div>

      </div>
    </div>

    <!-- Buttons -->
    <div class="modal-edit-category-name-footer form-horizontal">
      <button class="btn btn-default-white-background" (click)="hide()" translate>T_BUTTON_CANCEL</button>
      <button *ngIf="!isAddedModal" class="btn btn-default-white-background" (click)="sendConfirmation(); hide()" translate>T_BUTTON_EDIT</button>
      <button *ngIf="isAddedModal" class="btn btn-default-white-background" (click)="sendConfirmation(); hide()" translate>T_BUTTON_ADD</button>
    </div>
  </div>
</app-common-modal>
