import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CubicleOptionValue} from '../../../../shared/model/cubicleOption-model';

@Component({
  selector: 'app-odm-manage-option-values',
  templateUrl: './odm-manage-option-values.component.html',
  styleUrls: ['./odm-manage-option-values.component.less']
})
export class OdmManageOptionValuesComponent {

  @Input()
  values: CubicleOptionValue[];

  @Output()
  clickEditOptionValueButton: EventEmitter<CubicleOptionValue> = new EventEmitter<CubicleOptionValue>();
  @Output()
  clickDeleteOptionValueButton: EventEmitter<CubicleOptionValue> = new EventEmitter<CubicleOptionValue>();

  constructor() { }

  onClickEditOptionValueButton(optionValue: CubicleOptionValue){
    this.clickEditOptionValueButton.emit(optionValue);
  }
  onClickDeleteOptionValueButton(optionValue: CubicleOptionValue){
    this.clickDeleteOptionValueButton.emit(optionValue);
  }

}
