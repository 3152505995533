import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FeedbackModalComponent} from '../feedback-modal/feedback-modal.component';
import {FeedbackService} from '../shared/service/feedback.service';
import {SessionStorage} from 'ngx-webstorage';
import {Router} from '@angular/router';
import {User} from '../../shared/user/user';
import {DOCUMENT} from '@angular/common';
import {fromEvent, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.less']
})
export class FeedbackComponent implements OnInit, AfterViewInit, OnDestroy {

  @SessionStorage()
  user: User;

  @SessionStorage()
  proposeFeedback = true;

  @ViewChild('feedBackModal', {static: false}) feedBackModal: FeedbackModalComponent;
  @ViewChild('feedBackButton', {static: false}) feedBackButton: ElementRef;

  readonly BOUND_TOP_BORDER_PX = 80;
  readonly BOUND_RIGHT_BORDER_PX = 50;
  readonly BOUND_BOTTOM_BORDER_PX = 0;
  readonly BOUND_LEFT_BORDER_PX = 132;
  readonly SHOW_FEEDBACK_DELAY_MILLISECOND = 120000; // show feedback after 2 minutes

  private element: HTMLElement;
  private subscriptions: Subscription[] = [];
  private cancelShowModal = false;

  constructor(private feedbackService: FeedbackService,
              private router: Router,
              @Inject(DOCUMENT) private document: any) {
    const feedbackSub = this.feedbackService.feedbackObservable().subscribe(item => {
      if (this.user.termsOfUseAccepted) {
        setTimeout(() => {
          const lastFeedbackSub = this.feedbackService.lastUserFeedbackExpired(item.isGeneralFeedback).subscribe(
            lastFeedbackExpired => {
              if (lastFeedbackExpired && this.proposeFeedback) {
                this.showFeedBackModal();
              }
            });
          this.subscriptions.push(lastFeedbackSub);
        }, item.delayMillisecond);
      }
    });

    this.subscriptions.push(feedbackSub);
  }

  ngOnInit() {
    // manage the feedback display
    this.feedbackService.showGeneralFeedbackModal(this.SHOW_FEEDBACK_DELAY_MILLISECOND);
  }

  ngAfterViewInit() {
    this.element = this.feedBackButton.nativeElement as HTMLElement;
    this.initDrag();
  }

  ngOnDestroy() {
    if (!! this.subscriptions) {
      this.subscriptions.forEach((s) => {
        if (s !== null )
        s.unsubscribe();
      });
    }
  }

  public showFeedBackModal() {
    if (this.cancelShowModal) {
      this.cancelShowModal = false;
      return;
    }

    this.proposeFeedback = false;
    this.feedBackModal.show();
  }

  public notFOPage() {
    return !(this.router.url.includes('admin'));
  }

  private initDrag() {
    // create mouse events
    const dragStart$ = fromEvent<MouseEvent>(this.element, 'mousedown');
    const dragEnd$ = fromEvent<MouseEvent>(this.document, 'mouseup');
    const drag$ = fromEvent<MouseEvent>(this.document, 'mousemove').pipe(takeUntil(dragEnd$));

    // initialize X, Y and boundaries
    let initialX: number,
      initialY: number,
      currentX = 0,
      currentY = 0;
    const minBoundX = this.BOUND_LEFT_BORDER_PX - window.innerWidth,
      minBoundY = this.BOUND_TOP_BORDER_PX - window.innerHeight,
      maxBoundX = this.BOUND_RIGHT_BORDER_PX,
      maxBoundY = this.BOUND_BOTTOM_BORDER_PX;

    // create dragStart subscription
    let dragSub: Subscription;
    const dragStartSub = dragStart$.subscribe((event: MouseEvent) => {
      initialX = event.clientX - currentX;
      initialY = event.clientY - currentY;

      // dragging subscription
      dragSub = drag$.subscribe((dragEvent: MouseEvent) => {
        dragEvent.preventDefault();
        const dragX = dragEvent.clientX - initialX;
        const dragY = dragEvent.clientY - initialY;
        currentX = Math.max(minBoundX, Math.min(dragX, maxBoundX));
        currentY = Math.max(minBoundY, Math.min(dragY, maxBoundY));
        this.element.style.transform = 'translate3d(' + currentX + 'px, ' + currentY + 'px, 0)';

        this.cancelShowModal = true;
      });
    });

    // create dragEnd subscription
    const dragEndSub = dragEnd$.subscribe(() => {
      initialX = currentX;
      initialY = currentY;

      if (!! dragSub) {
        dragSub.unsubscribe();
      }
    });

    // add into subscription list
    if (!!dragSub) {
      this.subscriptions.push(dragStartSub, dragSub, dragEndSub);
    }
  }
}
