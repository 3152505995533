/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
import {map} from 'rxjs/operators';
/* app modules */
import {Rights} from './rights';
import {LoggerService} from '../logging/logger.service';
import {isNullOrUndefined} from 'util';
import {DemoMode, Localization} from '../model/localization.model';

@Injectable()
export class RightsService {

  @SessionStorage()
  user;

  @SessionStorage()
  localization: Localization;

  private rights: Rights;
  private logger: LoggerService;


  constructor(logger: LoggerService,
              private httpClient: HttpClient) {
    this.logger = logger;
    this.rights = new Rights();
  }

  hasRight(right: string) {
    return this.rights.listOfRights.includes(right);
  }

  addRight(right: string) {
    if (!this.rights.listOfRights.includes(right)) {
      this.logger.info('add right' + right);
      this.rights.listOfRights.push(right);
      this.logger.debug('list of user right' + this.rights.listOfRights.join());

    }
    return this;
  }

  removeRight(right: string) {
    if (this.rights.listOfRights.includes(right)) {
      this.logger.info('delete the right ' + right);
      this.rights.listOfRights.splice(this.rights.listOfRights.indexOf(right), 1);
      this.logger.debug('list of user right' + this.rights.listOfRights.join());
    }
  }

  isAdmin() {
    return this.hasRight('FRONT_OFFICE');
  }

  isComToolsAdmin() {
    return this.hasRight('COM_TOOL_MANAGEMENT');
  }

  isNationalSales() {
    return this.hasRight('NATIONAL_SALES');
  }

  isReseller() {
    return this.hasRight('RESELLER');
  }

  isContractor() {
    return this.hasRight('CONTRACTOR');
  }

  isDemo() {
    return this.hasRight('DEMO_VERSION');
  }

  isDeactivatedDemo() {
    return this.hasRight('DEMO_VERSION') && (this.localization.demoMode === DemoMode.DEACTIVATED);
  }

  isActivatedDemo() {
    return this.hasRight('DEMO_VERSION') && (this.localization.demoMode === DemoMode.DEMO);
  }

  isExtendedDemo() {
    return this.hasRight('DEMO_VERSION') && this.localization.demoMode === DemoMode.EXTENDED_DEMO;
  }

  /**
   * Return true if the user is a mySE user
   * @returns {boolean}
   */
  hasMyseAccount(): boolean {
    return !isNullOrUndefined(this.user.currentMySEAccount);
  }

  setRole(role) {
    return this.httpClient.post(
       '/application/role', role,
      {responseType: 'text'}).pipe(
      map(() => {
          this.rights = new Rights();
          this.addRight(role);
      })
    );
  }

  /**
   * The discount is displayed only for configurated items, and only Resellers and Admin can show it
   * @returns {boolean} displays discount input
   */
  canApplyDiscount(): boolean {
    return this.isReseller() || this.isNationalSales();
  }

  /**
   * Return true if it is a CPQ user
   */
  isCPQUser(): boolean {
    return this.hasRight('CPQ_USER');
  }
}
