import {MySEStatusEnum} from '../../../shared/model/mySEStatusEnum';

export class Reference {
  ref: string;
  description: string;
  price: number;
  netPrice: number;
  partnerDiscount?: number;
  priceRange: string;
  availability: string;
  mySeNetPriceStatus?: MySEStatusEnum;
  deliveryTime: number;

  status: BusinessStatus;
  editableQuantity = true;
  id?: string; // Only for PackageReferenceAccessory model
}

export enum BusinessStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  IN_TEST = 'IN_TEST',
  DECOMMISSIONED= 'DECOMMISSIONED'
}
