import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SessionStorage} from 'ngx-webstorage';
import {Item, Project} from '../../project/shared/project-model';
import {User} from '../../shared/user/user';
import {Distributor} from '../../bom/shared';
import {UtilService} from '../../shared/util/util.service';

@Component({
  selector: 'app-distributor-form',
  templateUrl: './distributor-form.component.html',
  styleUrls: ['./distributor-form.component.less']
})
export class DistributorFormComponent implements OnInit {
  @Input() description: string;
  @Input() allRegions: Set<string>;
  @Output() onClickSkipButton = new EventEmitter<string>();
  @Output() onClickValidateButton = new EventEmitter<string>();

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  distributorSelected: Distributor;

  @SessionStorage()
  user: User;

  @SessionStorage()
  invalidFields: string[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.invalidFields = [];
  }

  cancelButton() {
    this.onClickSkipButton.emit('skip');
  }

  clickValidateButton() {
    // Save info
    this.onClickValidateButton.emit('validate');
  }

  checkEmailField(field: string, fieldTitle: string) {
    if (!UtilService.isValidEmailAdress(field)) {
      this.invalidFields.push(fieldTitle);
    } else {
      this.removeFieldFromInvalidList(fieldTitle);
    }
  }

  checkPhoneField(field: string, fieldTitle: string) {
    if (!UtilService.isValidDistributorPhoneNumber(field)) {
      this.invalidFields.push(fieldTitle);
    } else {
      this.removeFieldFromInvalidList(fieldTitle);
    }
  }

  checkStringField(field: string, fieldTitle: string) {
    if (UtilService.isStringEmpty(field)) {
      this.invalidFields.push(fieldTitle);
    } else {
      this.removeFieldFromInvalidList(fieldTitle);
    }
  }

  allAlreadyChanged() {
    return !(UtilService.isStringEmpty(this.distributorSelected.name) ||
      UtilService.isStringEmpty(this.distributorSelected.region) ||
      UtilService.isStringEmpty(this.distributorSelected.email) ||
      UtilService.isStringEmpty(this.distributorSelected.phone) ||
      UtilService.isStringEmpty(this.distributorSelected.contactPerson));
  }

  removeFieldFromInvalidList(fieldTitle: string) {
    this.invalidFields = this.invalidFields.filter(invField => invField !== fieldTitle);
  }

  validateButtonDisabled() {
    if (!this.allAlreadyChanged()) {
      return true;
    } else {
      return (this.invalidFields.length !== 0);
    }
  }

  public isInvalidField(field: string): boolean {
    return (this.invalidFields.filter(elem => elem === field).length !== 0);
  }
}
