import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../shared/auth/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {of} from "rxjs";

@Component({
  selector: 'app-connect-idms',
  templateUrl: './connect-idms.component.html',
  styleUrls: ['./connect-idms.component.scss']
})
export class ConnectIdmsComponent implements OnInit {

  isGettingAccessToken = false;
  isInError = false;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['code']) {
        this.isGettingAccessToken = true;
        return this.authService.getAccessToken(params['code']).subscribe({
          next: result => {
            this.authService.saveToken(result);
            this.router.navigateByUrl('/projects');
          },
          error: () => {
            this.isGettingAccessToken = false;
            this.isInError = true;
          }
        });
      } else {
        return of(null);
      }
    });
  }

  public relogin () {
    this.authService.login();
  }
}
