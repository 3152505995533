<app-common-modal #editDocumentationModal [name]="'editDocumentationModal'" [title]=" " [twoButtonsDialogs]="true"
                  [withFooter]="false">

    <h4 modal-title class="modal-title" translate>T_DOCUMENTATION_EDIT_MODAL_TITLE</h4>
    <div modal-body>
        <div *ngIf="loading" class="modal-spinner">
            <app-spinner [sizeClass]="'medium'"></app-spinner>
        </div>
        <div *ngIf="!loading">
            <h5 translate>T_DOCUMENTATION_DISPLAY_NAME</h5>
            <div class="edit-documentation-name"
                 *ngFor="let item of editDocumentationForm.name | keyvalue; trackBy: trackByFn">
                {{item.key}}: <input class="edit-accessory-name-input"
                                     [ngClass]="{'invalid-input' : !isValidString(editDocumentationForm.name[item.key])}"
                                     type="text"
                                     [(ngModel)]="editDocumentationForm.name[item.key]"/>
                <span *ngIf="!isValidString(editDocumentationForm.name[item.key])" class="invalid-input-helper"
                      translate>T_INVALID_INPUT</span>
            </div>


            <h5 translate>T_DOCUMENTATION_CATEGORY</h5>
            <select [(ngModel)]="editDocumentationForm.category" name="selectedRange">
                <ng-container *ngFor="let category of availableCategories">
                    <option [ngValue]="category">{{category}}</option>
                </ng-container>
            </select>

            <h5 translate>T_DOCUMENTATION_UPLOAD_FILE</h5>
            <div>
                <span *ngIf="!editDocumentationForm.localFileId" translate>T_DOCUMENTATION_NO_FILE_YET</span>
                <span *ngIf="editDocumentationForm.localFileId">
        <span *ngIf="editDocumentationForm.extension === 'pdf'" class="pi pi-file-pdf file-icon"></span>
      </span>
            </div>
            <button type="button" class="btn btn-default-gray-background" (click)="fileInput.click()" translate>
                <em class="pi pi-upload">&nbsp;</em>
                T_DOCUMENTATION_UPLOAD_FILE
                <input #fileInput type="file" (change)="clickOnChooseFile($event)" accept=".pdf" style="display:none"/>
            </button>
            <div *ngIf="editDocumentationForm.localFileId">
                <h5 translate>T_DOCUMENTATION_FILE_NAME</h5>
                <input type="text" [(ngModel)]="editDocumentationForm.fileName"
                       [ngClass]="{'invalid-input' : !isValidString(editDocumentationForm.fileName)}">
                <span>.{{editDocumentationForm.extension}}</span>
                <span *ngIf="!isValidString(editDocumentationForm.fileName)" class="invalid-input-helper" translate>T_INVALID_INPUT</span>
            </div>

            <div class="edit-documentation-modal-footer">
                <button class="btn btn-default-white-background footer-button"
                        (click)="clickOnCancelEditDocumentation()" translate>
                    T_BUTTON_CANCEL
                </button>
                <button class="btn btn-default-white-background footer-button" [disabled]="!isValidForm()"
                        (click)="clickOnSaveDocumentation()"
                        translate>T_BUTTON_SAVE
                </button>
            </div>
        </div>
    </div>
</app-common-modal>
