import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class RegisterService {

  constructor(private httpClient: HttpClient) {
  }

  getContactCountry(): Observable<any> {
    return this.httpClient.cache().get( '/localizations/country/contact');
  }

  getContactFromCountry(country: string): Observable<any> {
    return this.httpClient.cache().get( '/localizations/country/contact/' + country);
  }

  sendMail (formData): Observable<any> {
    return this.httpClient.post('/application/contact/sendMail', formData);
  }

}
