/* Angular modules */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
/* ngx modules */
import {TranslateModule} from '@ngx-translate/core';
/* app modules */
import {EcorealCtSharedModule} from '../shared/shared.module';
/* Services */
import {DocumentationHelperService, DocumentationService, StaticDocumentationService} from './shared';
/* Components */
import {
  DocumentationComponent,
  DocumentationInformationFormComponent,
  DocumentationListComponent,
  QuoteDocumentationComponent
} from './';
import {BomModule} from '../bom/bom.module';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    EcorealCtSharedModule,
    FormsModule,
    BomModule
  ],
  declarations: [DocumentationComponent, DocumentationListComponent, QuoteDocumentationComponent,
    DocumentationInformationFormComponent],
  providers: [DocumentationService, DocumentationHelperService, StaticDocumentationService]
})
export class ExportModule {

}
