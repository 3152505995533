import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.less']
})
export class SpinnerComponent implements OnInit {

  possibleClasses: string[] = ['extra-small', 'small', 'medium', 'large'];

  @Input()
  sizeClass: string;

  @Input()
  vCenter = true;

  constructor() {
  }

  ngOnInit(): void {
    if (this.possibleClasses.indexOf(this.sizeClass) === -1) {
      throw new Error('Unauthorized size class {}, valid value are ' + this.possibleClasses);
    }
  }

}
