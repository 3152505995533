<div class="options-management-container">

  <h3><span class="back-link" (click)="returnOnOfferSelection()" translate>T_OFFER_DATA_MANAGEMENT_BACK_BUTTON</span></h3>

  <div>
    <div class="titles">
      <p class="range-key" translate><span translate>T_OFFER_DATA_MANAGEMENT_OPTIONS_LIST_OF_REFERENCES_OF_RANGE </span>{{selectedRange.nameKey}}</p>
    </div>
  </div>

  <div *ngIf="!isLoading">

    <div class="button-container">
      <button  class="btn btn-smart"  (click)="onClickAddOptionButton()" translate>
        <span class="se-icons">action_add_stroke</span>T_OPTION_ADD_BUTTON
      </button>
      <button  class="btn btn-smart"  (click)="onClickAddOptionValueButton()" translate>
        <span class="se-icons">action_add_stroke</span>T_OPTION_VALUE_ADD_BUTTON
      </button>
    </div>


    <div class="category" *ngFor="let reference of references">
      <app-odm-options-list-references
        [reference]="reference"
        [options]="optionsByReference[reference]"
        [isExpanded]="activeReference === reference"
        [activeOption]="activeOption"
        [showOnPopUpStatus]="showOnPopUpManagement()"
        (clickEditOptionButton)="onClickEditOptionButton($event, reference)"
        (clickDeleteOptionButton)="onClickDeleteOptionButton($event, reference)"
        (clickEditOptionValueButton)="onClickEditOptionValueButton($event, reference)"
        (clickDeleteOptionValueButton)="onClickDeleteOptionValueButton($event, reference)"
      >
      </app-odm-options-list-references>
    </div>
  </div>

  <div *ngIf="isLoading" class="odm-left-column">
    <app-spinner [sizeClass]="'large'"></app-spinner>
  </div>
</div>

<app-odm-edit-option-modal #addOptionModal
                           (saveOption)="addOption($event)"
                           [references]="references">
</app-odm-edit-option-modal>

<app-odm-edit-option-modal #editOptionModal
                           (saveOption)="editOption($event)"
                           [references]="references">
</app-odm-edit-option-modal>
<app-common-modal #deleteOptionModal [name]="'deleteOptionModal'" (onClickYesButton)="deleteOption()"
                  [description]="'T_DELETE_OPTION_MODAL_MESSAGE' | translate" >
</app-common-modal>
<app-odm-edit-option-value-modal #addOptionValueModal
                                    (saveOptionValue)="addOptionValue($event)"
                                    [selectedRange]="selectedRange">
</app-odm-edit-option-value-modal>

<app-odm-edit-option-value-modal #editOptionValueModal
                                    (saveOptionValue)="editOptionValue($event)"
                                    [selectedRange]="selectedRange">
</app-odm-edit-option-value-modal>
<app-common-modal #deleteOptionValueModal [name]="'deleteOptionValueModal'" (onClickYesButton)="deleteOptionValue()"
                  [description]="'T_DELETE_OPTION_VALUE_MODAL_MESSAGE' | translate" >
</app-common-modal>
