<div>

  <app-card-documentation-list
                          [title]="'T_DOC_COMMERCIAL'" [documentations]="commercialDocuments"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-card-documentation-list>

    <!-- Plans documents -->
    <app-card-documentation-list [title]="'T_DOC_PLANS'" [documentations]="staticPlans"
                            [documentChosenLanguage]="documentChosenLanguage">
    </app-card-documentation-list>

    <!-- Datasheets documents -->
    <app-card-documentation-list [title]="'T_DOC_DATASHEETS'" [documentations]="staticDatasheets"
                            [documentChosenLanguage]="documentChosenLanguage">
    </app-card-documentation-list>

    <!-- User guide documents -->
    <app-card-documentation-list [title]="'T_DOC_USER_GUIDE'" [documentations]="staticManualDocuments"
                            [documentChosenLanguage]="">
    </app-card-documentation-list>

    <!-- CAD files documents -->
    <app-card-documentation-list [title]="'T_DOC_CAD_FILES'" [documentations]="cadFiles"
                            [documentChosenLanguage]="NO_LANGAGE">
    </app-card-documentation-list>

  <!-- Environement documents -->
  <app-card-documentation-list [title]="'T_DOC_ENVIRONMENT'" [documentations]="environment"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-card-documentation-list>

  <!-- catalogs documents -->
  <app-card-documentation-list [title]="'T_DOC_CATALOGS'" [documentations]="catalogs"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-card-documentation-list>

  <!-- promotionMaterials documents -->
  <app-card-documentation-list [title]="'T_DOC_PROMOTIONAL_MATERIALS'" [documentations]="promotionMaterials"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-card-documentation-list>

  <!-- technicalPublications documents -->
  <app-card-documentation-list [title]="'T_DOC_TECHNICAL_PUBLICATIONS'" [documentations]="technicalPublications"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-card-documentation-list>

  <!-- applicationSolutions documents -->
  <app-card-documentation-list [title]="'T_DOC_APPLICATIONS_SOLUTIONS'" [documentations]="applicationSolutions"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-card-documentation-list>

  <!-- whitePapers documents -->
  <app-card-documentation-list [title]="'T_DOC_WHITE_PAPERS'" [documentations]="whitePapers"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-card-documentation-list>

  <!-- certificates documents -->
  <app-card-documentation-list [title]="'T_DOC_CERTIFICATES'" [documentations]="certificates"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-card-documentation-list>

  <!-- specifications documents -->
  <app-card-documentation-list [title]="'T_DOC_SPECIFICATIONS'" [documentations]="specifications"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-card-documentation-list>

  <!-- training documents -->
  <app-card-documentation-list [title]="'T_DOC_TRAINING'" [documentations]="training"
                          [documentChosenLanguage]="documentChosenLanguage">
  </app-card-documentation-list>

</div>
