import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterValue} from '../../configuration/shared/model/FilterValue';
import {ProjectService} from '../../project/shared/project.service';
import {SessionStorage} from 'ngx-webstorage';
import {Item, Project, Status} from '../../project/shared/project-model';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.less']
})
export class SelectComponent {
  @SessionStorage()
  currentProject: Project;
  @SessionStorage()
  currentItemct: Item;
  @SessionStorage()
  user;

  @Input()
  values: FilterValue[];

  @Input()
  key: string;

  @Input()
  unit: string;

  @Input()
  schneiderStyle: boolean;

  @Input()
  readonly = false;

  @Output()
  onChangeSelect = new EventEmitter<string>();

  constructor(private projectService: ProjectService) { }

  /**
   * Allow to know if a filter has to be disabled
   * @param {FilterValue[]} filterValues the filter to check
   * @returns {boolean} true = filter grayed
   */
  isOneValueFilter(filterValues: FilterValue[]): boolean {
    return filterValues.filter(value => !value.disabled).length === 1 || filterValues.some(value => value.checked);
  }

  /**
   * Allow to know if a value is selected
   * @param {FilterValue[]} filterValues set of values
   * @returns {boolean} true if selected
   */
  isSelected(filterValues: FilterValue[]): boolean {
    return filterValues.some(value => value.checked) && filterValues.length > 1;
  }

  /**
   * Method to manage the onChange
   * @param {string} value selected
   */
  changeSelect(value: string) {
    this.onChangeSelect.emit(value);
  }

  isReadOnlyProject(): boolean {
    return this.projectService.isReadOnlyProject(this.currentProject, this.user) || this.currentItemct.status === Status.ordered;
  }
}
