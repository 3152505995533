import {Component, Input, OnInit} from '@angular/core';
import {BomService} from '../../bom/shared/bom.service';
import {SessionStorage} from 'ngx-webstorage';
import {ItemService} from '../../project/shared/item.service';
import {Item, Project, Status} from '../../project/shared/project-model';
import {DocumentInformations} from '../shared/document-informations.model';
import {RightsService} from '../../shared/rights/rights.service';
import {DocumentationHelperService, DocumentationService} from '../shared';
import {LoggerService} from '../../shared/logging/logger.service';
import {ProjectService} from '../../project/shared/project.service';
import {Localization} from '../../shared/model/localization.model';


@Component({
  selector: 'app-quote-documentation',
  templateUrl: './quote-documentation.component.html',
  styleUrls: ['./quote-documentation.component.less']
})
export class QuoteDocumentationComponent implements OnInit {

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  localization: Localization;

  @SessionStorage()
  user;

  @SessionStorage()
  documentInformations: DocumentInformations;

  @Input()
  isProjectDocumentation: boolean;

  isDownloadInProgress = false;
  isOrderProjectDocumentationDownloadInProgress = false;

  projectItems: Item[];

  constructor(private logger: LoggerService,
              private bomService: BomService,
              private rightsService: RightsService,
              private documentationService: DocumentationService,
              private projectService: ProjectService,
              private documentationHelperService: DocumentationHelperService,
              private itemService: ItemService) {
  }

  ngOnInit() {
    this.itemService.getItems(this.currentProject.id)
      .subscribe(items => this.projectItems = items);
  }

  public exportBom() {
    if (!this.currentItemct) {
      this.bomService.exportBom(this.currentProject.id, this.projectItems, false, this.user, false, true);
    } else {
      this.bomService.exportBom(this.currentProject.id, [this.currentItemct], false, this.user, this.isCpqProject());
    }
  }

  public exportMySEBom() {
    this.bomService.exportMySEBom(this.currentProject.id, this.currentItemct);
  }

  public exportDigiQBom() {
    if (this.currentItemct) {
      this.bomService.exportDigiQBom(this.currentProject.id, this.currentItemct);
    }
  }

  public hasQuoteBeenDownloaded() {
    return this.documentInformations.downloadedDoc.includes('QUOTE');
  }

  public hasQuoteMySEBeenDownloaded() {
    return this.documentInformations.downloadedDoc.includes('QUOTEMYSE');
  }

  public hasQuoteDigiQBeenDownloaded() {
    return this.documentInformations.downloadedDoc.includes('QUOTEDIGIQ');
  }

  public isDigiQCountryUser(): boolean {
    return this.currentItemct && this.rightsService.isReseller() && this.currentItemct.range && this.currentItemct.range.digiQCountry;
  }

  public isMyseUser(): boolean {
    return this.currentItemct && !!this.user.currentMySEAccount;
  }

  /**
   * Method to know if it is a CPQ project
   * @returns {boolean}
   */
  private isCpqProject(): boolean {
    return this.projectService.isCpqProject(this.currentProject);
  }

}
