/* Angular modules */
import {AfterViewInit, Component, ElementRef, HostBinding, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
/* ngx modules */
import {SessionStorage, SessionStorageService} from 'ngx-webstorage';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {PaginationConfig, TabDirective, TabsetComponent} from 'ngx-bootstrap';
import {PageChangedEvent} from 'ngx-bootstrap/pagination/pagination.component';
/* app modules */
import {ProjectService} from './shared/project.service';
import {NavigationStep} from '../shared/guards/navigationStep-enum';
import {ItemService} from './shared/item.service';
import {Item, Project, ReleaseNote, Status, StatusFilter} from './shared/project-model';
import {CommonModalComponent} from '../shared/common-modal/common-modal.component';
import {UtilService} from '../shared/util/util.service';
import {DocumentInformations} from '../export/shared';
import {LoggerService} from '../shared/logging/logger.service';
import {User} from '../shared/user/user';
import {HeaderService} from '../layout/header/header.service';
import {RightsService} from '../shared/rights/rights.service';
import {Subject} from 'rxjs/Rx';
import {Role} from '../core/access-control/enum';
import {CpqProjectChoiceModalComponent} from './cpq-project-choice-modal/cpq-project-choice-modal.component';
import {Post, PostCategory} from '../shared/model/post';
import {ComToolsService} from '../shared/com-tools/com-tools.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Localization} from '../shared/model/localization.model';
import {UserService} from '../shared/user/user.service';
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-project-page',
  templateUrl: './project-page.component.html',
  styleUrls: ['./project-page.component.less'],
})
export class ProjectPageComponent implements OnInit, AfterViewInit {
  @HostBinding('attr.style')
  public get valueAsStyle(): any {
    return this.sanitizer.bypassSecurityTrustStyle(`--news-length: '${this.countNews.toString()}'`);
  }

  @SessionStorage()
  currentProject: Project;
  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  isInProjectSharingMode: boolean;

  @SessionStorage()
  documentInformations: DocumentInformations;

  // Variable for navigation bar when no item is currently selected
  @SessionStorage()
  noItemNavigationStep = NavigationStep.PROJECTS;

  // Variable for navigation bar when not on Administration screen
  @SessionStorage()
  isOnAdministrationScreen = false;

  @SessionStorage()
  isDemoMode;

  @SessionStorage()
  localization: Localization;

  @SessionStorage()
  itemToDelete: Item;

  @SessionStorage()
  user: User;

  @SessionStorage()
  proposeConditionModal = true;

  @SessionStorage()
  isNetPriceDisplayed: boolean;

  @SessionStorage()
  userJustArrivedFromCpq: boolean;

  @SessionStorage()
  cpqQuoteId;

  @SessionStorage()
  cpqRedirectURL;

  projects: Array<Project>;

  sharedProjects: Array<Project>;

  nameFiltredProjects: Array<Project>;
  resultFiltredProjects: Array<Project>;
  displayedProjects: Array<Project>;
  releaseNotes: Array<ReleaseNote>;
  isLastReleaseNoteVersion = false;
  displayReleaseNote = false;
  loading = false;
  loadingNews = false;
  loadingMessage = '';
  currentPage = 1;
  statusFilters: StatusFilter;
  disableFilters: any = {configured: true, ordered: true, owned: true, cpqProjects: true, cpqProjectsWithDefaultQuoteId: true};
  loadedProjectCount: any = {configured: 0, ordered: 0, owned: 0, cpqProjects: 0, cpqProjectsWithDefaultQuoteId: 0};
  defaultNumberOfProjectsToLoad = 500;
  nameFilterContent: string;
  onMyLastProjectsTab = true;
  isNetPriceDisplayedPreviousValue: boolean;
  deleteItemSubject: Subject<void> = new Subject<void>();
  projectTabs = [
    'T_PROJECT_LIST_TITLE',
    'T_GO_TO_SHARED_WITH_ME'];
  news: Array<Post> = [];
  countNews = 0;
  headerNewsTabStyle: string;


  @ViewChild('childModal') childModal: CommonModalComponent;

  @ViewChild('cpqChoiceModal') cpqChoiceModal: CpqProjectChoiceModalComponent;
  @ViewChild('navTabs') navTabs: TabsetComponent;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private projectService: ProjectService,
              private itemService: ItemService,
              private logger: LoggerService,
              private sessionStorageService: SessionStorageService,
              private translateService: TranslateService,
              private headerService: HeaderService,
              private messageService: MessageService,
              private comToolsService: ComToolsService,
              private rightsService: RightsService,
              private sanitizer: DomSanitizer,
              private userService: UserService) {
  }

  ngOnInit() {
    this.logger.debug('Project init');

    this.currentItemct = null;
    this.currentProject = null;
    this.documentInformations = null;

    if (this.user.statusFilter) {
      this.statusFilters = this.user.statusFilter;
    } else {
      this.statusFilters = this.getDefaultStatusFilter();
    }

    this.sessionStorageService.observe('user')
      .subscribe(() => this.loadProjects(this.defaultNumberOfProjectsToLoad));
    this.sessionStorageService.observe('user')
      .subscribe(() => this.loadAllReleaseNotesByUser());
    this.sessionStorageService.observe('isNetPriceDisplayed')
      .subscribe(newValue => this.isNetPriceDisplayedChange(newValue));
    this.route.data.subscribe(data => {
      this.onMyLastProjectsTab = !data.isSharedProject;
      if (this.onMyLastProjectsTab) {
        this.loadProjects(this.defaultNumberOfProjectsToLoad);
      } else {
        this.loadSharedProjects();
      }
    });
    this.loadAllReleaseNotesByUser();
    this.loadNews();
  }

  ngAfterViewInit(): void {
    if (!this.onMyLastProjectsTab) {
      this.navTabs.tabs[1].active = true;
    }
  }

  loadProjects(limit?: number) {
    this.logger.debug('ProjectComponent loadProjects()');
    this.loading = true;
    this.loadingMessage = 'T_LOADING_PROJECTS';

    this.projectService.getProjects(limit)
      .toPromise()
      .then(projects => this.projects = projects)
      .then(
        () => this.initDisplayedProjects(),
        () => {
          this.loading = false;
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant('T_ERROR'),
          });
        });

    if (this.currentItemct != null) {
      this.currentItemct.currentNavigationStep = NavigationStep.WELCOME;
    }
  }

  loadSharedProjects() {
    this.logger.debug('ProjectComponent loadProjects()');
    this.loading = true;
    this.loadingMessage = 'T_LOADING_PROJECTS';

    this.projectService.getSharedProjects()
      .toPromise()
      .then(sharedProjects => this.sharedProjects = sharedProjects)
      .then(
        () => this.initDisplayedProjects(),
        () => {
          this.loading = false;
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant('T_ERROR'),
          });
        });
  }

  deleteItem(tuple: [Project, Item]) {
    this.currentProject = tuple[0];
    this.itemToDelete = tuple[1];
    this.showModal('deleteItem');
  }

  showModal(type) {
    if (type === 'associateToCpq') {
      this.associateProjectToCpq();
      return;
    }
    if (type === 'duplicate') {
      this.childModal.name = 'duplicate';
      this.childModal.description = this.translateService.instant('T_DUPLICATE_WARNING_MESSAGE');
    }
    if (type === 'duplicateCpq') {
      this.childModal.name = 'duplicateCpq';
      this.childModal.description = this.translateService.instant('T_DUPLICATE_WARNING_MESSAGE');
    }
    if (type === 'delete') {
      this.childModal.name = 'delete';
      this.childModal.description = this.translateService.instant('T_DELETE_WARNING_MESSAGE');
    }
    if (type === 'deleteItem') {
      this.childModal.name = 'deleteItem';
      this.childModal.description = this.translateService.instant('T_DELETE_ITEM_WARNING_MESSAGE');
    }
    if (type === 'duplicateOnMyProject') {
      this.childModal.name = 'duplicateOnMyProject';
      this.childModal.description = this.translateService.instant('T_DUPLICATE_WARNING_MESSAGE');
    }
    if (type === 'push') {
      this.childModal.name = 'pushToCpq';
      this.childModal.description = this.translateService.instant('T_PROJECT_PUSH_TO_CPQ_MESSAGE');
    }
    this.childModal.show();
  }

  confirmDialog(event) {
    if (event === 'duplicate') {
      this.duplicateProject();
    }
    if (event === 'duplicateCpq') {
      this.duplicateCpqProject();
    }
    if (event === 'delete') {
      this.deleteProject();
    }
    if (event === 'deleteItem') {
      this.confirmDeleteItem();
    }
    if (event === 'duplicateOnMyProject') {
      this.duplicateProjectOnMyProject();
    }
    if (event === 'pushToCpq') {
      this.pushToCpq();
    }
  }

  confirmDeleteItem() {
    this.itemService.deleteItemFromProject(this.currentProject.id, this.itemToDelete.id).subscribe(
      (projectUpdated) => {
        // Loop on all projects
        for (const project of this.projects) {
          if (project.id === this.currentProject.id) {
            // Remove the item deleted from the list of project
            project.itemCT = project.itemCT.filter((item) => {
              return item.id !== this.itemToDelete.id;
            });
            this.deleteItemSubject.next();
          }
        }
        this.updateProjectsList();
      });
  }

  addProjectAction() {
    if (this.user.role === Role.CPQ_USER) {
      this.cpqChoiceModal.show(this.cpqQuoteId, 'create');
    } else {
      this.addProject();
    }
  }

  addProject(cpqNumber?: string) {
    this.logger.info('ProjectComponent addProject()');
    this.loading = true;
    this.loadingMessage = 'T_PROJECT_ADDING';
    this.projectService.addProject(cpqNumber)
      .subscribe(project => {
          this.onMyLastProjectsTab = true;
          this.currentProject = project;
          this.projects.splice(0, 0, project);
          this.initDisplayedProjects();
          this.currentItemct = null;
          this.scrollToTop();
        },
        error => {
          this.logger.error(error);
          this.loading = false;
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant('T_CREATE_ERROR_PROJECT'),
          });
        },
        () => this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_CREATE_SUCCESS_PROJECT'),
        }));
  }

  duplicateProject(cpqNumber?: string) {
    this.logger.info('ProjectComponent duplicateProject()');
    this.loading = true;
    this.loadingMessage = 'T_PROJECT_DUPLICATING';
    this.projectService.cloneProject(this.currentProject.id, cpqNumber).subscribe(
      project => {
        this.currentProject = project;
        this.projects.unshift(project);
        this.initDisplayedProjects();
        this.scrollToTop();
      },
      error => {
        this.logger.error(error);
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_DUPLICATE_ERROR_MESSAGE'),
        });
      },
      () => this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('T_INFO'),
        detail: this.translateService.instant('T_DUPLICATE_SUCCESS_MESSAGE'),
      })
    );
  }

  duplicateCpqProject() {
    this.cpqChoiceModal.show(this.cpqQuoteId, 'duplicate');
  }

  associateProjectToCpq() {
    this.cpqChoiceModal.show(this.cpqQuoteId, 'associate');
  }

  duplicateProjectOnMyProject() {
    this.logger.info('ProjectComponent duplicateProjectOnMyProject()');
    this.loading = true;
    this.loadingMessage = 'T_PROJECT_DUPLICATING';
    this.projectService.cloneSharedProject(this.currentProject.id).subscribe(
      project => {
        this.currentProject = project;
        this.projects.unshift(project);
        this.onMyLastProjectsTab = true;
        this.initDisplayedProjects();
        this.scrollToTop();
      },
      error => {
        this.logger.error(error);
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_DUPLICATE_ERROR_MESSAGE'),
        });
      },
      () =>
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_DUPLICATE_SUCCESS_MESSAGE'),
        })
    );
  }

  associateProject(cpqNumber: string) {
    this.loading = true;
    this.projectService.associateToCpq(this.currentProject.id, cpqNumber).subscribe(
      project => {
        this.currentProject = project;
        const newProjects = this.projects.filter(p => p.id !== project.id);
        newProjects.unshift(project);
        this.projects = newProjects;
        this.initDisplayedProjects();
      },
      error => {
        this.logger.error(error);
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_ASSOCIATE_ERROR_MESSAGE'),
        });
      },
      () => this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('T_INFO'),
        detail: this.translateService.instant('T_ASSOCIATE_SUCCESS_MESSAGE'),
      })
    );
  }


  deleteProject() {
    this.logger.info('ProjectComponent deleteProject()');
    this.loading = true;
    this.loadingMessage = 'T_PROJECT_DELETING';
    this.projectService.deleteProject(this.currentProject.id).subscribe(
      () => {
        this.projects = this.projects.filter((project) => {
          return project.id !== this.currentProject.id;
        });
        if (this.sharedProjects) {
          this.sharedProjects = this.sharedProjects.filter((project) => {
            return project.id !== this.currentProject.id;
          });
        }
        this.currentProject = null;
        this.initDisplayedProjects();
      },
      error => {
        this.loading = false;
        this.logger.error(error);
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_DELETE_ERROR_MESSAGE'),
        });
      },
      () =>
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_DELETE_SUCCESS_MESSAGE'),
        })
    );
  }

  pushToCpq() {
    this.logger.info('ProjectComponent pushToCpq()');
    this.loading = true;
    this.loadingMessage = 'T_PROJECT_PUSH_CPQ';
    this.projectService.pushToCpqProject(this.currentProject.id).subscribe(
      (project) => {
        if (this.cpqRedirectURL) {
          window.open(this.cpqRedirectURL + project.cpqNumber, '_self');
        } else {
          this.onMyLastProjectsTab = true;
          this.currentProject = project;
          const newProjects = this.projects.filter(p => p.id !== project.id);
          newProjects.unshift(project);
          this.projects = newProjects;
          this.initDisplayedProjects();
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('T_INFO'),
            detail: this.translateService.instant('T_PUSH_TO_CPQ_SUCCESS_MESSAGE'),
          });
        }
      },
      error => {
        this.loading = false;
        this.logger.error(error);
        let errorMessage = 'No message returned by CPQ.';
        if (error) {
          errorMessage = error;
        }
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_PUSH_TO_CPQ_ERROR_MESSAGE' + ': ' + errorMessage),
        });
      }
    );
  }

  loadAllReleaseNotesByUser() {
    // get release notes
    this.headerService.getAllReleaseNotesByUser()
      .subscribe(releaseNotes => {
        this.releaseNotes = releaseNotes;
        // check if the last release note for the current user
        const lastReleaseNote = releaseNotes[0];
        if (lastReleaseNote !== undefined) {
          for (const userVersion of lastReleaseNote.usersVersion) {
            if (this.user.email === userVersion.userEmail && lastReleaseNote.version === userVersion.userVersion) {
              this.isLastReleaseNoteVersion = true;
            }
          }
        }
      });
  }

  getReleaseNoteIconClass(): string {
    return this.isLastReleaseNoteVersion ? 'inline-block fa fa-2x fa-lightbulb-o brand-info button' :
      'inline-block fa fa-2x fa-lightbulb-o brand-info button blink';
  }

  updateUserReleaseNoteVersion(): any {
    this.displayReleaseNote = !this.displayReleaseNote;
    if (!this.isLastReleaseNoteVersion) {
      this.headerService.updateUserVersion()
        .subscribe(userVersion => {
          this.logger.info('user release note version: ' + userVersion);
        });
      this.isLastReleaseNoteVersion = true;
    }
  }

  isDemoUser(): boolean {
    return this.rightsService.isActivatedDemo();
  }

  isDeactivatedDemoUser(): boolean {
    return this.rightsService.isDeactivatedDemo();
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.displayedProjects = this.resultFiltredProjects.slice(startItem, endItem);
    setTimeout(() => {
      const elem = document.getElementById('main-div');
      elem.scrollTo(0, elem.scrollHeight);
    }, 10);

  }

  updateProjectsList() {
    if (this.onMyLastProjectsTab) {
      this.nameFiltredProjects = this.projects.filter(project => project.name.toUpperCase().includes(this.nameFilterContent.toUpperCase()) ||
        project.cpqNumber !== null && project.cpqNumber.toUpperCase().includes(this.nameFilterContent.toUpperCase()));
    } else {
      this.nameFiltredProjects = this.sharedProjects.filter(project => project.name.toUpperCase().includes(this.nameFilterContent.toUpperCase()));
    }

    this.updateFilterStatus();

    // if user is CPQ then only cpqProjectsWithDefaultQuoteId filter should be enabled on arrival
    if (this.user.role === Role.CPQ_USER && this.userJustArrivedFromCpq) {
      if (!this.disableFilters.cpqProjectsWithDefaultQuoteId) {
        this.statusFilters.cpqProjectsWithDefaultQuoteId = true;
      } else if (!this.disableFilters.cpqProjects) {
        this.statusFilters.cpqProjects = true;
      }
      this.userJustArrivedFromCpq = false;
    }

    this.updateListStatusFilter();
    this.countProjectsByStatus();
  }

  changeProjectList(data: TabDirective) {
    this.onMyLastProjectsTab = data.heading === this.translateService.instant('T_PROJECT_LIST_TITLE');

    // No shared projects loaded so load else toggle displayed projects
    if (this.sharedProjects === undefined && !this.onMyLastProjectsTab) {
      this.loadSharedProjects();
    } else if (this.projects === undefined && this.onMyLastProjectsTab) {
      this.loadProjects(this.defaultNumberOfProjectsToLoad);
    } else {
      this.initDisplayedProjects();
    }
  }

  updateListStatusFilter() {
    this.setPage(1);

    if (this.nameFiltredProjects === null) {
      return;
    }

    this.resultFiltredProjects = this.nameFiltredProjects.filter(project => {
      let searchStatus = false;

      if (!this.statusFilters.cpqProjectsWithDefaultQuoteId
        && !this.statusFilters.cpqProjects
        && !this.statusFilters.configured
        && !this.statusFilters.ordered
        && !this.statusFilters.owned) {
        return true;
      }
      if (this.statusFilters.cpqProjectsWithDefaultQuoteId && this.projectService.isCpqProject(project) && this.cpqQuoteId === project.cpqNumber) {
        return true;
      } else if (this.statusFilters.cpqProjects && this.projectService.isCpqProject(project)) {
        return true;
      } else if (this.statusFilters.owned && this.isUserOwnerOrLastUpdator(project)) {
        return true;
      } else {
        project.itemCT.forEach(item => {
          if (this.statusFilters.configured && (item.status.includes(Status.configured) || item.status.includes(Status.quoted))) {
            searchStatus = true;
            return;
          } else if (this.statusFilters.ordered && item.status.includes(Status.ordered)) {
            searchStatus = true;
            return;
          }
        });
      }
      return searchStatus;
    }); // end of filter

    this.displayedProjects = this.resultFiltredProjects.slice(0, 10);
    this.saveStatusFilters();
  }

  setPage(pageNo: number): void {
    // Timout to let some time to change the page, if not, it have a Angular Exception dur to change content
    setTimeout(() => {
      this.currentPage = pageNo;
    }, 100);
  }

  updateFromSearchFilter(filterContent) {
    this.nameFilterContent = filterContent;
    this.updateProjectsList();
  }

  /**
   * Return true if the tab in parameter is the selected one
   * @param tab
   */
  isSelectedTab(tab: string) {
    return ((this.onMyLastProjectsTab && tab === this.projectTabs[0]) ||
      (!this.onMyLastProjectsTab && tab === this.projectTabs[1]));
  }

  /**
   * Method to refresh the news news number
   * @param $event the clicked tab
   */
  refreshUserCheckNews($event: TabDirective) {
    this.countNews = 0;
    this.headerNewsTabStyle = this.getHeaderNewsTabStyle();
    this.comToolsService.updateReadingDate(PostCategory.NEWS).subscribe();
  }

  getHeaderNewsTabStyle(): string {
    if (this.countNews > 0) {
      return 'tab-style tab-news news-bubble';
    }
    return 'tab-style tab-news';
  }

  showLoadMoreProjectsButton(tab: string): boolean {
    if(tab === this.projectTabs[0] && !this.loading && this.projects){
      return this.defaultNumberOfProjectsToLoad == this.projects.length;
    }
    return false;
  }

  private initDisplayedProjects() {
    if (this.onMyLastProjectsTab) {
      this.nameFiltredProjects = this.projects;
      this.resultFiltredProjects = this.projects;
      this.nameFilterContent = '';
      this.updateFilterStatus();
      this.updateProjectsList();
      this.countProjectsByStatus();
    } else {
      this.nameFiltredProjects = this.sharedProjects;
      this.resultFiltredProjects = this.sharedProjects;
    }
    this.displayedProjects = this.resultFiltredProjects.slice(0, 5);
    this.setPage(1);

    // end of projects processing, we remove the spinner
    this.loading = false;
    this.loadingMessage = '';
  }

  private updateFilterStatus() {
    // Update filters status
    this.disableFilters.configured = true;
    this.disableFilters.ordered = true;
    this.disableFilters.cpqProjects = true;
    this.disableFilters.cpqProjectsWithDefaultQuoteId = true;
    this.disableFilters.owned = true;

    this.nameFiltredProjects.forEach(project => {
      if (this.isUserOwnerOrLastUpdator(project)) {
        this.disableFilters.owned = false;
      }
      if (this.projectService.isCpqProject(project)) {
        if (this.cpqQuoteId === project.cpqNumber) {
          this.disableFilters.cpqProjectsWithDefaultQuoteId = false;
        } else {
          this.disableFilters.cpqProjects = false;
        }
      }
      project.itemCT.forEach(item => {
        if (item.status.includes(Status.configured) || item.status.includes(Status.quoted)) {
          this.disableFilters.configured = false;
          }
          if (item.status.includes(Status.ordered)) {
            this.disableFilters.ordered = false;
          }
        });
    });
  }

  private scrollToTop() {
    try {
      this.myScrollContainer.nativeElement.parentElement.parentElement.scrollTop = 0;
    } catch (err) {
    }
  }

  /**
   * Method to count project on each different status
   */
  private countProjectsByStatus() {
    this.loadedProjectCount.ordered = 0;
    this.loadedProjectCount.configured = 0;
    this.loadedProjectCount.cpqProjects = 0;
    this.loadedProjectCount.cpqProjectsWithDefaultQuoteId = 0;
    this.loadedProjectCount.owned = 0;

    this.nameFiltredProjects.forEach(project => {
      let alreadyCountConfigured = false;
      let alreadyCountOrdered = false;
      if (this.isUserOwnerOrLastUpdator(project)) {
        this.loadedProjectCount.owned++;
      }
      if (this.projectService.isCpqProject(project)) {
        if (this.cpqQuoteId === project.cpqNumber) {
          this.loadedProjectCount.cpqProjectsWithDefaultQuoteId++;
        }
        this.loadedProjectCount.cpqProjects++;
      }
      project.itemCT.forEach(item => {
        if ((item.status.includes(Status.configured) || item.status.includes(Status.quoted)) && !alreadyCountConfigured) {
          this.loadedProjectCount.configured++;
          alreadyCountConfigured = true;
        }
        if (item.status.includes(Status.ordered) && !alreadyCountOrdered) {
          this.loadedProjectCount.ordered++;
          alreadyCountOrdered = true;
        }
      });
    });
  }

  /**
   * Method to check if a reload is needed
   * @param newValue
   */
  private isNetPriceDisplayedChange(newValue: boolean) {
    if (this.isNetPriceDisplayedPreviousValue === undefined) {
      this.isNetPriceDisplayedPreviousValue = newValue;
    } else if (this.isNetPriceDisplayedPreviousValue !== newValue) {
      this.loadProjects(this.defaultNumberOfProjectsToLoad);
    }
  }

  private loadNews() {
    // Get news and unread news
    this.loadingNews = true;
    this.loadingMessage = 'T_LOADING_NEWS';
    this.comToolsService.getPosts(PostCategory.NEWS).subscribe(news => this.news = news,
      () => this.loadingNews = false,
      () => this.loadingNews = false);
    this.comToolsService.getCountUnreadPost(PostCategory.NEWS).subscribe(count => {
      this.countNews = count;
      this.headerNewsTabStyle = this.getHeaderNewsTabStyle();
    });
  }

  private isUserOwnerOrLastUpdator(project): boolean {
    return project && (project.author && project.author.id === this.user.id || project.lastUpdator && project.lastUpdator.id === this.user.id);
  }

  /**
   * Saves filters data
   */
  private saveStatusFilters () {
    this.user.statusFilter = this.statusFilters;
    this.userService.updateUserStatusFilter(this.statusFilters).subscribe();
  }

  /**
   * get statusFilter to its default value
   * @StatusFilter default status Filter
   */
  private getDefaultStatusFilter () {
    const defaultStatusFilter = new StatusFilter();
    defaultStatusFilter.configured = false;
    defaultStatusFilter.ordered = false;
    defaultStatusFilter.owned = false;
    defaultStatusFilter.cpqProjects = false;
    defaultStatusFilter.cpqProjectsWithDefaultQuoteId = false;
    return defaultStatusFilter;
  }
}
