/* Angular modules */
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
/* app modules */
import {Item, Project, Status} from '../shared/project-model';
import {LoggerService} from '../../shared/logging/logger.service';
import {ProjectService} from '../shared/project.service';
import {DocumentInformations} from '../../export/shared';
import {UtilService} from '../../shared/util/util.service';
import {NavigationLinkEnum} from '../../shared/guards/navigationLink-enum';
import {NavigationRoute} from '../../shared/guards/route-enum';
import {NavigationStep} from '../../shared/guards/navigationStep-enum';
import {ItemService} from '../shared/item.service';
import {User} from '../../shared/user/user';
import {SwitchBoardComponent} from '../../configuration/shared/model/component';
import {CpqStatus} from '../../shared/model/cpqStatus';
import {Observable} from 'rxjs/Observable';
import {RightsService} from '../../shared/rights/rights.service';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.less']
})
export class ProjectCardComponent implements OnInit {

  public static DAY_IN_MILLISECONDS = 86400000;

  /** used to display message alert for cpq account user when trying to to push
  * unvalidated project
  */
  displayPushCpqAlert: boolean;

  @Input()
  project: Project;

  @Input()
  onMyLastProjectsTab: boolean;

  @Output()
  onClickShowModal = new EventEmitter<string>();

  @Input()
  deleteItemEvent: Observable<void>;

  @SessionStorage()
  user: User;

  @SessionStorage()
  localization;

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  itemToDelete: Item;

  @SessionStorage()
  documentInformations: DocumentInformations;

  // Variable for navigation bar when no item is currently selected
  @SessionStorage()
  noItemNavigationStep;

  @SessionStorage()
  allComponents: SwitchBoardComponent[] = [];

  @SessionStorage()
  filteredComponents: SwitchBoardComponent[] = [];

  isValidName = true;

  constructor(
    private router: Router,
    private logger: LoggerService,
    private projectService: ProjectService,
    private itemService: ItemService,
    public utilService: UtilService,
    private rightService: RightsService,
    private translateService: TranslateService,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.displayPushCpqAlert = false;
  }

  setCurrentIdProject(project: Project) {
    this.logger.info('ProjectComponent setCurrentIdProject()');
    this.currentProject = project;
  }

  displayEditingElement(project: Project): boolean {
    return (this.onMyLastProjectsTab || project.canEditSharedProject) && !project.readOnly;
  }

  displayDeletingElement(project: Project): boolean {
    return (this.onMyLastProjectsTab || project.canEditSharedProject) && !this.isAlreadyPushToCpq();
  }

  displayShareElement(): boolean {
    return !this.onMyLastProjectsTab ;
  }

  isDemoUser(): boolean {
    return this.rightService.isDemo();
  }

  isActivatedDemo(): boolean {
    return this.rightService.isActivatedDemo();
  }

  isPrivateProject(): boolean {
    return this.projectService.isPrivateProject(this.project);
  }

  getPrivateProjectTooltip(): string {
    return this.translateService.instant('T_PROJECTS_PRIVATE_TOOLTIP');
  }

  getPrivateProjectMigrateTooltip(): string {
    return this.translateService.instant('T_PROJECTS_PRIVATE_MIGRATE_TOOLTIP');
  }

  showModal(type: string) {
    this.onClickShowModal.emit(type);
  }

  collaboration(project: Project): void {
    this.logger.info('ProjectComponent collaboration()');
    this.currentProject = project;
    this.router.navigate([NavigationLinkEnum.PROJECT_SHARING, this.currentProject.id]);
  }

  goToItemCt(tuple: [Project, Item]) {
    this.logger.info('ProjectComponent goToItemCt()');

    this.currentProject = tuple[0];

    this.itemService.getItem(tuple[1].id).subscribe(
      item => {
        this.currentItemct = item;

        // If opening a configuration at least one day after its creation log Re-open
        if ((new Date().getTime() - item.creationDate) > ProjectCardComponent.DAY_IN_MILLISECONDS) {
          this.logger.business('Re-open configuration');
        }
        const listNavStep = NavigationRoute.getRouteByRange(this.currentItemct.range);

        if (item.status === Status.ordered || item.status === Status.quoted) {
          // When a "Quoted" or "Ordered" project is re-opened, the app should navigate straight to the BOM page
          this.router.navigate(['/' + NavigationLinkEnum.BILL_OF_MATERIALS]);
        } else {
          this.router.navigate(['/' + listNavStep.find(step =>
            NavigationStep[step.id.valueOf()] === NavigationStep[this.currentItemct.currentNavigationStep.valueOf()]).link]);
        }
      }
    );
  }

  /**
   * Navigate on Project BOM
   */
  goToProjectBom(): void {
    this.logger.info('ProjectCardComponent goToProjectBom()');
    this.currentProject = this.project;
    this.noItemNavigationStep = NavigationStep.PROJECT_BILL_OF_MATERIALS;
    this.router.navigate([NavigationLinkEnum.PROJECT_BILL_OF_MATERIALS]);
  }

  addSwitchboard(project: Project): void {
    this.logger.info('ProjectComponent addSwitchboard()');

    this.currentProject = project;
    this.currentItemct = null;
    this.router.navigate([NavigationLinkEnum.OFFERS_CT]);
  }

  deleteItem(tuple: [Project, Item]) {
    this.currentProject = tuple[0];
    this.itemToDelete = tuple[1];
    this.showModal('deleteItem');
  }

  isCpqProject(): boolean {
    return this.projectService.isCpqProject(this.project);
  }

  canPushToCpq(): boolean {
    let allItemsOnBom = this.project.itemCT.length > 0;
    this.project.itemCT.forEach(item => {
      allItemsOnBom = allItemsOnBom && Number(item.maxNavigationStep) >= NavigationStep.BILL_OF_MATERIALS;
    });
    return allItemsOnBom &&  this.project.cpqStatus === CpqStatus.TO_BE_PUSHED;
  }

  showPushToCpq(): boolean {
    return this.isCpqProject() && this.canPushToCpq();
  }

  showUpdateInCpq(): boolean {
    return this.canPushToCpq()
      && this.project.cpqAnswer != null
      && this.project.cpqAnswer.configurationInfo != null
      && this.project.cpqAnswer.configurationInfo.configurationId != null;
  }

  isAlreadyPushToCpq(): boolean {
    return this.isCpqProject() && this.project.cpqStatus === CpqStatus.PUSHED;
  }

  updateProjectGroup() {
    this.projectService.updateProjectGroup(this.project.id).subscribe(
      updatedProject => {
      this.project.partnerId = updatedProject.partnerId;
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_PROJECTS_PRIVATE_MIGRATE_SUCCESS')
        });
    },
      error => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_PROJECTS_PRIVATE_MIGRATE_ERROR') + error
        });
    });
  }

  updateProjectName (project: Project, documentInformation: DocumentInformations, currentItem: Item, $event){
    if (this.isValidName) {
      this.projectService.updateProjectNameFromEditable(project,documentInformation,currentItem, $event);
    } else {
      $event.target.innerText = this.project.name;
      this.isValidName = UtilService.isValidString($event.target.innerText);
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('T_ERROR'),
        detail: this.translateService.instant('T_PROJECTS_RENAMING_ERROR')
      });
    }
  }

  projectNameCheck ($event: any) {
    if ($event.key === 'Enter') {
      $event.target.blur();
    } else {
      this.projectService.controlMaxLengthOnProject($event);
      this.isValidName = UtilService.isValidString($event.target.textContent);
    }
  }
}
