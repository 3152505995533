/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
/* app modules */
import {Range} from '../../shared/model/range-model';

@Injectable()
export class TransformerService {

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Get all visible filters of all transformers offers
   * @returns {Observable<string[]>} Id of filters (may be empty)
   */
  getFiltersForAllRanges(): Observable<string[]> {
    return this.httpClient.cache().get<string[]>('/ranges/visibleFilters');
  }

  /**
   * Get sort criterion for all transformers offers
   * @returns {Observable<string[]>} Id of filters (may be empty)
   */
  getCardSortCriterionForAllRanges(): Observable<string> {
    return this.httpClient.cache().get('/ranges/sortCriterion', {responseType: 'text'});
  }

  /**
   * Service to get range on express
   * @param rangeId
   */
  getRange(rangeId: String): Observable<Range> {
    return this.httpClient.get<Range>('/ranges/' + rangeId);
  }
}
