/* Angular modules */
import {Injectable} from '@angular/core';
/* app modules */
import {SwitchBoardComponent} from '../model/component';
import {Item} from '../../../project/shared/project-model';

export enum CubicleTypeEnum {
  QM = 'QM',
  QMC = 'QMC',
  PM = 'PM'
}

@Injectable()
export class CubicleService {

  constructor() {
  }

  /**
   * Computes visibility for the cubicle card on selection component.
   *
   * @param item {Item}
   * @param cubicle {SwitchBoardComponent}
   */
  static computeVisibilityForCubicle(item: Item, cubicle: SwitchBoardComponent) {
    // make invisible the cubicles that cannot be placed on 1st position
    if (item.components.length === 0) {
      for (const ordering of cubicle.ordering) {
        if (ordering.notFirst) {
          return false;
        }
      }
      return true;
    } else {
      // reverse previously visibility that has been set previously
      return true;
    }
  }

  /**
   * Checks if the cubicle is type of QM, QMC or PM
   *
   * @param component {SwitchBoardComponent}
   * @param offerPrefix {string}
   * @return {boolean}
   */
  static isQMorQMCorPM(component: SwitchBoardComponent, offerPrefix: string): boolean {
    const type: CubicleTypeEnum = this.getTypeOfCubicle(component, offerPrefix);
    return CubicleService.isOfTypeQM(type) || CubicleService.isOfTypeQMC(type) || CubicleService.isOfTypePM(type);
  }

  /**
   * Determine if the option is fuse by its name.
   *
   * @param option
   */
  static isFuse(option) {
    const fuseRegex = /_FUSE_/;
    return option.name.match(fuseRegex) !== null;
  }

  /**
   * Gets cubicle type by its name.
   *
   * @param component
   * @param offerPrefix {string}
   * @return {CubicleTypeEnum}
   */
  static getTypeOfCubicle(component: SwitchBoardComponent, offerPrefix: string): CubicleTypeEnum {
    // +2 because: +1 is zero-based index and additional +1 for '_' character
    const noPrefix = component.name.substr(offerPrefix.length + 2);
    const cubicleType = noPrefix.split('_')[0];
    switch (cubicleType) {
      case 'QMC':
        return CubicleTypeEnum.QMC;
      case 'QM':
        return CubicleTypeEnum.QM;
      case 'PM':
        return CubicleTypeEnum.PM;
      default:
        return null;
    }
  }

  /**
   * Checks if the cubicle is type of QM
   *
   * @param type {CubicleTypeEnum}
   */
  static isOfTypeQM(type: CubicleTypeEnum) {
    return type === CubicleTypeEnum.QM;
  }

  /**
   * Checks if the cubicle is type of QMC
   *
   * @param type {CubicleTypeEnum}
   */
  static isOfTypeQMC(type: CubicleTypeEnum) {
    return type === CubicleTypeEnum.QMC;
  }

  /**
   * Checks if the cubicle is type of PM
   *
   * @param type {CubicleTypeEnum}
   */
  static isOfTypePM(type: CubicleTypeEnum) {
    return type === CubicleTypeEnum.PM;
  }
}


