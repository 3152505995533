/* angular modules */
import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {ModalDirective} from 'ngx-bootstrap';
import {RegisterService} from './register.service';
import {LoggerService} from '../logging/logger.service';
import {Localization} from '../model/localization.model';
import {LocaleService} from '../localization/locale.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UtilService} from '../util/util.service';

class ContactCountry {
  code: string;
  name: string;
  email: string;
}

@Component({
  selector: 'app-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.less'],
})
export class RegisterModalComponent implements OnInit {

  @SessionStorage()
  user;

  @SessionStorage()
  isDemoMode: boolean;

  @SessionStorage()
  hasRegister: boolean;

  @ViewChild('childModal') public childModal: ModalDirective;
  @Input() name: string;
  @Output() onClickYesButton = new EventEmitter<string>();
  @Output() onClickNoButton = new EventEmitter<string>();

  isRegisterMode = true;
  isValidateMode = false;
  modalMessage: string;
  userContactCountry: ContactCountry;
  countryNotFound = false;

  allLocalizations: Localization[];

  form: FormGroup;
  email: string = null;
  firstName: string = null;
  lastName: string = null;
  companyName: string = null;
  accountNumber: string = null;

  constructor(private translateService: TranslateService,
              private messageService: MessageService,
              private logger: LoggerService,
              private localeService: LocaleService,
              private cdRef: ChangeDetectorRef,
              private registerService: RegisterService,
              private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      email: [this.email, [Validators.required, Validators.pattern(UtilService.emailRegEx)]],
      firstName: [this.firstName, Validators.compose([Validators.required, RegisterModalComponent.checkIfEmptyValue])],
      lastName: [this.firstName, Validators.compose([Validators.required, RegisterModalComponent.checkIfEmptyValue])],
      companyName: [this.companyName, Validators.compose([Validators.required, RegisterModalComponent.checkIfEmptyValue])],
      accountNumber: []
    });
  }

  private static checkIfEmptyValue(fieldControl: FormControl) {
    return fieldControl.value && !fieldControl.value.match('^ +$') ? null : {notEmpty: true};
  }

  public ngOnInit(): void {
    this.logger.debug('Register modal init()');
    this.modalMessage = this.translateService.instant('T_DEMO_VERSION_REGISTER_MESSAGE');
    this.getContactCountry();
    this.cdRef.detectChanges();
  }

  show() {
    this.childModal.show();
  }

  hide() {
    this.childModal.hide();
  }

  clickRegisterButton() {
    if ( this.countryNotFound) {
      // if country not found in application's localisation
      this.modalMessage = this.translateService.instant('T_DEMO_VERSION_COUNTRY_NOT_FOUND_REGISTER_MESSAGE');
    } else {
      const country = this.translateService.instant('T_COUNTRY_' + this.user.paceCountry);
      this.modalMessage = this.translateService.instant('T_DEMO_VERSION_SELECT_COUNTRY_REGISTER_MESSAGE',
        {countryName: country});
    }
    this.isRegisterMode = false;
    this.isValidateMode = true;
  }

  clickValidateButton() {
    const formData = new FormData();
    const toEmail = this.userContactCountry.email;
    const subjectEmail = this.translateService.instant('T_DEMO_VERSION_EMAIL_SUBJECT_REGISTER');
    const bodyEmail = this.translateService.instant('T_DEMO_VERSION_EMAIL_BODY_REGISTER',
      {emailForm: this.email, firstNameForm: this.firstName, lastNameForm: this.lastName, companyNameForm: this.companyName,
        accountNumberForm: this.accountNumber ? this.accountNumber : '', userFirstName: this.user.firstName, userLastName: this.user.lastName, userEmail: this.user.email});
    formData.append('toEmail', toEmail);
    formData.append('subjectEmail', subjectEmail);
    formData.append('bodyEmail', bodyEmail);
    this.registerService.sendMail(formData).subscribe(response => {
      if (response === true) {
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_EMAIL_SUCCESS_TITLE'),
          detail: this.translateService.instant('T_DEMO_VERSION_EMAIL_SUCCESS_MESSAGE'),
        });
        this.hasRegister = true;
        this.isDemoMode = true;
      } else {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_EMAIL_ERROR_TITLE'),
          detail: this.translateService.instant('T_DEMO_VERSION_EMAIL_ERROR_MESSAGE'),
        });
      }
    }, error => {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('T_EMAIL_ERROR_TITLE'),
        detail: this.translateService.instant('T_DEMO_VERSION_EMAIL_ERROR_MESSAGE'),
      });
    });
    this.onClickYesButton.emit(this.name);
  }

  clickCancelButton() {
    this.onClickNoButton.emit(this.name);
  }

  getContactCountry() {
    this.registerService.getContactCountry().subscribe(contactCountry => {
        if (contactCountry && contactCountry.email && contactCountry.country) {
          this.countryNotFound = false;
          this.updateContactCountry(contactCountry);
        } else {
          this.manageNoContactCountryFound();
        }
      },
      error => {
        this.manageNoContactCountryFound();
      });
  }

  /**
   * Method to update global contact country from service answer
   * @param contactCountry
   */
  updateContactCountry(contactCountry: any) {
    this.userContactCountry = new ContactCountry();
    this.userContactCountry.code = contactCountry.country;
    this.userContactCountry.name = this.translateService.instant('T_COUNTRY_' + contactCountry.country);
    this.userContactCountry.email = contactCountry.email;
  }

  /**
   * When a user with unknown country select a country in the list
   * @param country
   */
  selectLocalization(country: string) {
    this.registerService.getContactFromCountry(country).subscribe(contactCountry => {
      this.updateContactCountry(contactCountry);
    });
  }

  /**
   * Generate key for country name
   * @param country
   */
  getLocalizationCountryNameKey(country: string): string {
    return 'T_COUNTRY_' + country + '_NAME';
  }

  /**
   * When no contact country found: user demo from an unknown country
   */
  private manageNoContactCountryFound() {
    this.countryNotFound = true;
    this.localeService.getAllLocalization().subscribe(
      allLoc => {
        this.allLocalizations = allLoc.filter(loc => loc.country != 'DEMO');
      }
    );
  }
}
