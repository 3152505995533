/* angular modules */
import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
/* ngx modules */
import {SessionStorageService} from 'ngx-webstorage';
/* app modules */
import {DocumentationCategory} from './documentation-constant.enum';
import {Documentation} from './documentation.model';
import {DocumentFile} from '../../export/shared';
import {DocumentInformations} from '../../export/shared';
import {DocumentationService} from './documentation.service';
import {Item} from '../../project/shared/project-model';


@Injectable()
export class DocumentationHelperService {

  constructor(private documentationService: DocumentationService,
              private sessionStorageService: SessionStorageService) {
  }

  public static isDynamicDocumentation(document: Documentation) {
    return [DocumentationCategory.TECHNICAL, DocumentationCategory.COMMERCIAL,
      DocumentationCategory.COMMERCIAL_TECHNICAL, DocumentationCategory.ORDER].includes(document.category) &&
      document.localFileId === null;
  }


  private static getTitlesMap(titles, document) {
    if (!titles.has(document.title)) {
      const doc = new Map();
      doc.set(document.language, document);
      titles.set(document.title, doc);
    }
  }

  public createDocumentFromBackendResponse(itemId, projectId, docId, documentationInformations: DocumentInformations,
                                           response: HttpResponse<Blob>) {
    this.openDocumentation(this.getDocumenFile(response));
    if (itemId && documentationInformations && documentationInformations.version && !documentationInformations.downloadedDoc.includes(docId)) {
      documentationInformations.downloadedDoc.push(docId);
      this.documentationService.addDownloadedDoc(projectId, itemId, docId).subscribe(result =>
        this.sessionStorageService.store('documentInformations', result));
    }
  }

  public updateDocumentInformation(itemId, projectId, docId, documentationInformations: DocumentInformations) {
    if (itemId && documentationInformations && documentationInformations.version && !documentationInformations.downloadedDoc.includes(docId)) {
      documentationInformations.downloadedDoc.push(docId);
      this.documentationService.addDownloadedDoc(projectId, itemId, docId).subscribe(result =>
        this.sessionStorageService.store('documentInformations', result));
    }
  }


  /**
   * Get DocumentFile from backend response
   * @param {HttpResponse<Blob>} response
   * @returns {DocumentFile}
   */
  public getDocumenFile(response: HttpResponse<Blob>):  DocumentFile {
    const contentDisposition = response.headers.get('Content-Disposition');
    const fileName = contentDisposition.substring(contentDisposition.lastIndexOf('="') + 2, contentDisposition.lastIndexOf('"'));
    const documentationFile: DocumentFile = new DocumentFile();
    documentationFile.name = fileName;
    documentationFile.blob = response.body;
    documentationFile.size = response.body.size;
    documentationFile.lastUpdate = new Date();
    return documentationFile;
  }


  public mergeOrderDocuments(documentItems: Array<{ item: Item , documentation: Documentation}>): Array<Map<string, Documentation>> {
    const mergedDocuments = new Array<Documentation>();
    documentItems.forEach(
      documentItem => {
        if (documentItem !== null && documentItem !== undefined &&
          documentItem.item !== null && documentItem.item !== undefined  &&
          documentItem.item.orderInformation !== null && documentItem.item.orderInformation !== undefined  &&
          documentItem.item.orderInformation.orderDate !== null && documentItem.item.orderInformation.orderDate !== undefined) {
          if (!this.containsOrderDocument(mergedDocuments, documentItem.item.orderInformation.orderDate)) {
            const documentWithOrderDate = documentItem.documentation;
            documentWithOrderDate.orderInformation = documentItem.item.orderInformation;
            mergedDocuments.push(documentWithOrderDate);
          }
        }
      }
    );
    const mergedDocumentsMap = new Array<Map<string, Documentation>>();
    mergedDocuments.forEach(doc => {
      const map = new Map<string, Documentation>();
      map.set(doc.language, doc);
      mergedDocumentsMap.push(map);
    });
    return mergedDocumentsMap;
  }

  public mergeDocumentsByTitleAndLanguage(documents: Array<Documentation>, existingDocuments: Array<Map<string, Documentation>>): Array<Map<string, Documentation>> {
    const titles = new Map<string, Map<string, Documentation>>();

    // Due to the complexity of structure, we make ourself the concatenation
    existingDocuments.forEach((documentMap => documentMap.forEach(document => {
      DocumentationHelperService.getTitlesMap(titles, document);
    })));
    documents.forEach(document => {
      DocumentationHelperService.getTitlesMap(titles, document);
    });
    return  Array.from(titles.values());
  }

  /**
   * Return true if list of document already contain order at this time
   * @param mergedDocument list of document
   * @param orderDate time to check
   */
  private containsOrderDocument(mergedDocument: Array<Documentation>, orderDate: number): boolean {
    for (const document of mergedDocument) {
      if (document.orderInformation.orderDate === orderDate) {
        return true;
      }
    }
    return false;
  }


  private openDocumentation(df: DocumentFile): void {
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) { // for IE
      (window.navigator as any).msSaveOrOpenBlob(df.blob, df.name);
    } else {// For others navigators
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(df.blob);
      a.href = url;
      a.download = df.name;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }

}
