/* Angular modules */
import {Injectable} from '@angular/core';
/* app modules */
import {SwitchBoardComponent} from '../model/component';
/* other sources */
import {FilterCategory} from '../model/filterCategory';
import * as _ from 'lodash';
import {SimplifiedRange} from '../../../shared/model/simplifiedRange-model';

@Injectable()
export class FilterService {

  constructor() {
  }

  /**
   * Sort component filters regarding range
   */
  public sortFiltersRegardingRange(filters: Array<FilterCategory>, range: SimplifiedRange) {
    const rangeNameKey = range.nameKey;
    // We sort filters with initial order
    const visibleFilters = range.visibleComponentsFilters;
    filters.sort((a, b) => this.getIndex(a.key, visibleFilters)  < this.getIndex(b.key, visibleFilters) ? -1 : 1);
  }

  /**
   * Sort components according to criterion
   * @param {string} criterion the sort criterion
   * @param allComponents
   */
  public doTheSort(criterion: string, allComponents: Array<SwitchBoardComponent>) {
    if (criterion) {
      _.sortBy(allComponents, comp => {
        const value = comp.characteristics.find(charac => charac.key === criterion);
        return value ? value : '';
      });
    } else {
      _.sortBy(allComponents, comp => comp.reference.ref);
    }
  }

  /**
   * Function to compare 2 filter keys
   * @param key
   * @param visibleFilters
   */
  private getIndex(key: string, visibleFilters: string[]): number {
    for (let index = 0 ; index < visibleFilters.length ; index ++ ) {
      if (visibleFilters[index] === key) {
        return index;
      }
    }
    return -1;
  }
}
