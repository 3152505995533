<div class="container section-margin" #container>
  <div class="row">
    <!-- FIRST COLUMN -->
    <div class="col-sm-12 col-md-4 offer-image-column">
      <div class="offer-image-div">
        <h1 translate>T_PACKAGE_OFFER_TITLE</h1>

        <!-- OFFER IMAGE BOX -->
        <div class="offer-image-box">
          <div class="offerImageHeader">
            <span class="offer-name" translate>{{this.packageOffer ? this.packageOffer.name : ''}}</span>
          </div>

          <img *ngIf="pictureUrl" class="offer-image" src="{{pictureUrl}}">

          <!-- SETTING COMPLETE INDICATOR -->
          <span translate class="required-field">
            <span [ngClass]="{'se-icons icon': true, 'se-notification-ok-stroke icon-validated': currentItemct.selectedPackage,
            'se-notification-critical icon-required': !currentItemct.selectedPackage }">
              {{currentItemct.selectedPackage ? 'notification_ok_stroke' : 'notification_critical'}}
            </span>
            {{currentItemct.selectedPackage ? 'T_SETTING_ENDED' : 'T_REQUIRED_FIELD' }}
          </span>
        </div>
      </div>
    </div>

    <!-- SPACER -->
    <div class="col-sm-12 col-md-1"></div>

    <!-- SECOND COLUMN -->
    <div class="col-sm-12 col-md-7">
      <div *ngIf="loading" class="cover-spinner">
        <!-- SPINNER -->
        <app-spinner class="center-spinner" [sizeClass]="'large'">
          <h3 translate>{{spinnerMessage}}</h3>
        </app-spinner>
      </div>

      <form class="form-horizontal" role="form">

        <!-- PACKAGES -->
        <app-characteristic-fieldset
          [title]="'T_PACKAGE_SELECTION_TITLE'"
          [listOfElement]="packagesView"
          [readOnly]="!isAllowedToChange"
          (changeValue)="changePackageOption($event)">
        </app-characteristic-fieldset>
        <!-- SWITCHBOARD/TRANSFORMER LIST of selected package -->
        <app-package-items *ngIf="currentItemct?.selectedPackage"
                           [title]="'T_PACKAGE_SWITCHBOARD_LIST_TITLE'"
                           [listOfElement]="packageSwitchboardsList">
        </app-package-items>

        <!-- ACCESSORIES LIST of selected package -->
        <app-package-items *ngIf="currentItemct?.selectedPackage"
                           [title]="'T_PACKAGE_ACCESSORY_REFERENCE_LIST_TITLE'"
                           [listOfElement]="packageAccessoriesList">
        </app-package-items>

        <!-- ACCESSORIES OPTIONS -->
        <app-characteristic-fieldset *ngIf="accessoriesViews"
          [title]="'T_PACKAGE_ACCESSORY_OPTION_LIST_TITLE'"
          [listOfElement]="accessoriesViewsSorted"
          [readOnly]="!isAllowedToChange"
          (changeValue)="changeOption($event)">
        </app-characteristic-fieldset>

        <!-- VALIDATE BUTTON -->
        <div class="text-center">
          <button type="button" class="btn btn-default-gray-background btn-characteristics"
                  [disabled]="disableValidateButton()"
                  [ngClass]="{'disabled': currentItemct.maxNavigationStep <= characteristicStepNumber}"
                  (click)="goToNextTab()" translate>
            T_CHARACTERISTICS_VALIDATE
          </button>
        </div>

      </form>
    </div>
  </div>
</div>

<!-- confirm dialog -->
<app-common-modal #LooseModal [title]="'T_LOOSE_DATA_TITLE' | translate" [name]="'T_LOOSE_DATA_TITLE'"
                  [description]="'T_LOOSE_DATA' | translate" (onClickYesButton)="confirmLooseData()"
                  (onClickNoButton)="reinitData()" [size]="'modal-sm'">
</app-common-modal>
