import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CubicleLayout} from '../../../shared/bsl-reference/cubicle-layout-model';
import {OfferDataManagementHelper} from '../../../shared/util/offer-data-management-helper';
import layoutJson from '../../../../../assets/layout.json';

@Component({
  selector: 'app-offer-data-management-offer-layout',
  templateUrl: './odm-offer-layout.component.html',
  styleUrls: ['./odm-offer-layout.component.less']
})
export class OdmOfferLayoutComponent implements OnInit {

  @Input() layoutList: CubicleLayout[];
  @Input() loading: boolean;
  @Output() onClickAddButton = new EventEmitter<CubicleLayout[]>();
  @Output() onClickCancelButton = new EventEmitter<string>();

  hasSelectedLayout: boolean;
  selectedLayout: CubicleLayout;
  numberOfLayout: number;
  currentLayout: number;
  isSettingNumberOfLayout = true;
  listOfSelectedLayouts: CubicleLayout[];
  allLayout;
  allLayoutCheck = false;
  currentPage = 1;
  pageTotal;
  perPage = 12;
  OfferDataManagementHelper = OfferDataManagementHelper;

  constructor() {
  }

  ngOnInit() {
    this.initParameters();
  }

  nextButton() {
    if (this.isSettingNumberOfLayout) {
      this.isSettingNumberOfLayout = false;
    } else {
      this.listOfSelectedLayouts.push(this.selectedLayout);
      this.hasSelectedLayout = false;
      this.currentLayout = this.currentLayout + 1;
    }
  }
  addButton() {
    this.listOfSelectedLayouts.push(this.selectedLayout);
    this.onClickAddButton.emit(this.listOfSelectedLayouts);
    this.initParameters();
  }

  cancelButton() {
    this.initParameters();
    this.onClickCancelButton.emit('skip');
  }

  getLayoutImagePath(layoutFileName: string): string {
    let pathLayout: string;
    pathLayout = './assets/layout/' + layoutFileName;
    return pathLayout;
  }

  setLayoutValue(layout: CubicleLayout) {
    this.hasSelectedLayout = true;
    this.selectedLayout = layout;
  }

  isSelectedImage(layout): boolean {
    return this.hasSelectedLayout && layout === this.selectedLayout;
  }

  /**
   * Function to check the coherency of the number of Layout field
   */
  onChangeLayoutNumber() {
    if (!this.isValidNumber()){
      this.numberOfLayout = 1;
    }
    if (this.numberOfLayout < 1) {
      this.numberOfLayout = 1;
    } else if (this.numberOfLayout > 10) {
      this.numberOfLayout = 10;
    }
  }

  /**
   * When user set all Layout or not
   */
  onChangeAllLayout() {
    this.allLayoutCheck = !this.allLayoutCheck;
  }

  isValidNumber(){
    if(!this.numberOfLayout){
      return false;
    }
    const regex = new RegExp('^[0-9]*$');
    return regex.test(this.numberOfLayout.toString());
  }


  pageChange(event: any) {
    this.getLayoutList(event.detail.value, event.detail.perPage);
  }

  getLayoutList(page: number, perPage:number) {
    this.allLayout = new Set<CubicleLayout>();
    this.pageTotal = Math.ceil(layoutJson.allLayoutNames.length / perPage);

    const fromIndex = (page*perPage) - perPage;
    const toIndex = fromIndex+perPage;

    for(let i=fromIndex; i<toIndex; i++){
      if(layoutJson.allLayoutNames[i]){
        this.allLayout.add(new CubicleLayout(layoutJson.allLayoutNames[i]));
      }
    }
  }

  /**
   * Reset the component to initial state
   */
  private initParameters() {
    this.isSettingNumberOfLayout = true;
    this.numberOfLayout = 1;
    this.currentLayout = 1;
    this.hasSelectedLayout = false;
    this.listOfSelectedLayouts = [];
    this.allLayoutCheck = false;
    this.getLayoutList(1, this.perPage);
  }



}
