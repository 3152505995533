<app-spinner *ngIf="loading" [sizeClass]="'large'"></app-spinner>
<div class="net-price-by-partner" *ngIf="!loading">
  <h3 translate>T_PRICE_MANAGEMENT_NET_PRICE_TTTLE</h3>
  <div class="price-partner-actions">
    <button type="button" class="btn btn-default-gray-background" (click)="fileInput.click()" translate>
      <em class="pi pi-download">&nbsp;</em>
      T_PRICE_IMPORT
      <input #fileInput type="file" (change)="fileUploader($event)" accept=".xlsx, .xls" style="display:none" />
    </button>
    <button type="button" class="btn btn-default-gray-background btn-export" (click)="fileExport()" translate>
      <em class="pi pi-upload">&nbsp;</em>
      T_PRICE_EXPORT
    </button>
  </div>
  <div class="net-price-by-partner-form">
    <!-- PARTNER SELECT -->
    <label for="partner" translate>T_PRICE_MANAGEMENT_PARTNER_LABEL</label>
    <select id="partner" class="select" [(ngModel)]="netPriceSelectedPartner" (change)="refreshNetPriceDiscount()">
      <option selected [ngValue]="undefined" translate>T_PRICE_MANAGEMENT_CHOOSE_PARTNER</option>
      <option *ngFor="let partner of partnerOfCountry">{{partner}}</option>
    </select>

    <!-- RANGE SELECT -->
    <label for="range" translate>T_RANGE</label>
    <select id="range" class="select" [(ngModel)]="netPriceSelectedRange" (change)="refreshNetPriceDiscount()">
      <option [ngValue]="undefined" selected translate>T_PRICE_MANAGEMENT_CHOOSE_RANGE</option>
      <option *ngFor="let range of ranges" [ngValue]="range" translate>{{range.nameKey}}</option>
    </select>

    <!-- NET PRICE DISCOUNT FIELD -->
    <label for="netPriceDiscount" translate>T_DISCOUNT</label>
    <input [disabled]="invalidPartnerOrRangeSelected()" id="netPriceDiscount" type="number" min="{{MIN_DISCOUNT_ALLOWED}}" max="{{MAX_DISCOUNT_ALLOWED}}" step="0.01"
           class="form-control discount-number" [(ngModel)]="netPricesDiscount"
           (blur)="onChangePartnerDiscount($event.target.value)">

    <!-- UPDATE BUTTON -->
    <button type="button" [disabled]="invalidPartnerOrRangeSelected()" class="btn btn-group btn-primary button-div" (click)="updateNetPricesDiscountForPartner()" translate>T_PRICE_MANAGEMENT_UPDATE_BUTTON</button>

    <!-- RANGE LIST BUTTON -->
    <button type="button" [disabled]="!netPriceSelectedPartner" class="btn btn-group btn-primary button-div" (click)="showNetPriceDiscountModal()" translate><em class="se-icons">table</em></button>
  </div>
</div>

<app-common-modal #netPriceDiscountModal [name]="'netPriceDiscountModal'" [closable]="false" [withFooter]="false">
  <div modal-body>
    <div class="row net-price-by-partner form-center">
      <label for="partnerModal" translate>T_PRICE_MANAGEMENT_PARTNER_LABEL<span> :</span></label>
      <select id="partnerModal" class="select" [(ngModel)]="netPriceSelectedPartner" (change)="refreshRange()">
        <option *ngFor="let partner of partnerOfCountry">{{partner}}</option>
      </select>
    </div>
    <div class="row net-price-by-partner" *ngFor="let discount of rangeDiscountList">
      <div class="col-md-5 form-right">
        <label [for]="discount[0].nameKey" translate>{{discount[0].nameKey}}<span> :</span></label>
      </div>
      <div class="col-md-7">
        <input [id]="discount[0].nameKey" type="number" min="{{MIN_DISCOUNT_ALLOWED}}" max="{{MAX_DISCOUNT_ALLOWED}}" step="0.01"
               class="form-control discount-number" [value]="discount[1]"
               (blur)="onChangePartnerDiscountList(discount, $event.target.value)">
        <span>%</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 form-center">
        <button type="button" class="btn btn-group btn-default-white-background button-div" [disabled]="!modalSaveButtonEnabled"
                (click)="updateNetPricesDiscountForAllRangesPartner(); closeModal()" translate>T_BUTTON_SAVE</button>
      </div>
      <div class="col-md-6 form-center">
        <button type="button" class="btn btn-group btn-default-white-background button-div"
                [tooltip]="modalSaveButtonEnabled ? 'All unsaved modification will be lost' : ''"
                (click)="closeModal()" translate>
          T_CLOSE</button>
      </div>
    </div>
  </div>
</app-common-modal>

<!-- discount exeed modal -->
<app-common-modal #exeedDiscountModal [name]="'exeedDiscountModal'" [twoButtonsDialogs]="false">
</app-common-modal>

<!-- discount inferior to the max discount by partner modal -->
<app-common-modal #inferiorDiscountModal [name]="'inferiorDiscountModal'" [twoButtonsDialogs]="false">
</app-common-modal>
