/* Angular modules */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {TableModule} from 'primeng/table';
/* ngx modules */
import {TranslateModule} from '@ngx-translate/core';
import {FileUploadModule} from 'primeng/fileupload';
import {MultiSelectModule} from 'primeng/multiselect';
import {TooltipModule} from 'primeng/tooltip';
import {PanelModule} from 'primeng/panel';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ToastModule} from 'primeng/toast';
import {SelectButtonModule} from 'primeng/selectbutton';
/* app modules */
import {EcorealCtSharedModule} from '../shared/shared.module';
/* Services */
import {
  OdmAddOfferCharacteristicsComponent,
  OdmAddOfferDocumentsComponent,
  OdmAddOfferDocumentsPreviewComponent,
  OdmAddOfferFiltersComponent,
  OdmAddOfferMainCharacteristicsComponent,
  OdmAddOfferNavButtonComponent,
  OdmAddOfferPublicationComponent,
  OdmAddOfferReferencesComponent,
  OdmAddOfferStepperComponent,
  PriceManagementService,
  TranslationService,
  UploadService,
  PackageOfferService,
  PackageService,
  FileService,
} from './shared';
/* Components */
import {ContactManagementComponent, PriceManagementComponent, TranslationComponent} from './';
import {DistributorComponent} from './distributor/distributor.component';
import {DistributorFormComponent} from './distributor-form/distributor-form.component';
import {DistributorService} from './shared/distributor/distributor.service';
import {TabsModule} from 'ngx-bootstrap';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {TranslationFilterComponent} from './translate/translation-filter/translation-filter.component';
import {TranslationModalComponent} from './translate/translation-modal/translation-modal.component';
import {TranslationHelperService} from './shared/translation/translation.helper.service';
import {TranslationButtonComponent} from './translate/translation-button/translation-button.component';
import {OfferDataManagementComponent} from './offer-data-management/offer-data-management.component';
import {OfferDataManagementService} from './offer-data-management/offer-data-management.service';
import {OdmAddModalComponent} from './offer-data-management/shared/odm-add-modal/odm-add-modal.component';
import {OdmOfferSldComponent} from './offer-data-management/shared/odm-offer-sld/odm-offer-sld.component';
import {ContactDetailsComponent} from './contact-management/contact-details/contact-details.component';
import {OdmAddOfferComponent} from './offer-data-management/odm-offer/odm-add-offer/odm-add-offer.component';
import {OdmCharacteristicsComponent} from './offer-data-management/odm-offer/odm-add-offer/odm-characteristics/odm-characteristics.component';
import {UpSellingTransformerComponent} from './up-selling-transformer/up-selling-transformer.component';
import {UpSellingTransformerService} from './shared/up-selling/up.selling.transformer.service';
import {ServicesComponent} from './services/services.component';
import {ServicesService} from 'app/admin/shared/services/services.service';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { NetPriceFormComponent } from './price-management/net-price-form/net-price-form.component';
import {TrialConfigurationComponent} from './trial-configuration/trial-configuration.component';
import {PackageManagementComponent} from './package-management/package-management.component';
import {CardModule} from 'primeng/card';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {UtilService} from '../shared/util/util.service';
import {InputSwitchModule} from 'primeng/inputswitch';

import { UserManagementComponent } from './user-management/user-management.component';
import {SeWebModule} from '@se/web-ui-angular';
import {LayoutModule} from '../layout/layout.module';
import { PublicPriceUploadComponent } from './price-management/public-price-upload/public-price-upload.component';
import { QuotationSetupComponent } from './price-management/quotation-setup/quotation-setup.component';
import { DataManagementComponent } from './data-management/data-management.component';
import { OdmManageAccessoriesComponent } from './offer-data-management/odm-accessories-management/odm-manage-accessories/odm-manage-accessories.component';
import { OdmManageCategoryComponent } from './offer-data-management/odm-accessories-management/odm-manage-category/odm-manage-category.component';
import { OdmModalCategoryNameComponent } from './offer-data-management/odm-accessories-management/odm-modal-category-name/odm-modal-category-name.component';
import {OdmManageAccessoryComponent} from './offer-data-management/odm-accessories-management/odm-manage-accessory/odm-manage-accessory.component';
import {
  OdmEditAccessoryModalComponent
} from './offer-data-management/odm-accessories-management/odm-edit-accessory-modal/odm-edit-accessory-modal.component';
import { OdmEditAccessoryValueModalComponent } from './offer-data-management/odm-accessories-management/odm-edit-accessory-value-modal/odm-edit-accessory-value-modal.component';
import { OdmOfferSelectionComponent } from './offer-data-management/odm-offer-selection/odm-offer-selection.component';
import {
  OdmListReferencesComponent
} from './offer-data-management/odm-offer/odm-list-references/odm-list-references.component';
import {OdmListOffersComponent} from './offer-data-management/odm-offer/odm-list-offers/odm-list-offers.component';
import {OdmOfferLayoutComponent} from './offer-data-management/shared/odm-offer-layout/odm-offer-layout.component';
import { OdmManageOptionsComponent } from './offer-data-management/odm-options-management/odm-manage-options/odm-manage-options.component';
import { OdmOptionsListReferencesComponent } from './offer-data-management/odm-options-management/odm-options-list-references/odm-options-list-references.component';
import { OdmManageOptionComponent } from './offer-data-management/odm-options-management/odm-manage-option/odm-manage-option.component';
import { OdmManageOptionValuesComponent } from './offer-data-management/odm-options-management/odm-manage-option-values/odm-manage-option-values.component';
import {
  OdmEditOptionModalComponent
} from './offer-data-management/odm-options-management/odm-edit-option-modal/odm-edit-option-modal.component';
import {
  OdmEditOptionValueModalComponent
} from './offer-data-management/odm-options-management/odm-edit-option-value-modal/odm-edit-option-value-modal.component';
import {PackageManagementByGroupComponent} from "./package-management-by-group/package-management-by-group.component";
import {
  OdmModalNameComponent
} from "./package-management-by-group/odm-modal-name/odm-modal-name.component";
import {PackageOfferGroupService} from "./shared/package/package-offer-group.service";
import { OdmRangeManagementModalComponent } from './offer-data-management/shared/odm-range-management-modal/odm-range-management-modal.component';
import { DocumentationManagementComponent } from './offer-data-management/documentation-management/documentation-management.component';
import { DocumentationEditModalComponent } from './offer-data-management/documentation-management/documentation-edit-modal/documentation-edit-modal.component';
import { DocumentationLinkRefModalComponent } from './offer-data-management/documentation-management/documentation-link-ref-modal/documentation-link-ref-modal.component';
import { OrderManagementModalComponent } from '../shared/modals/order-management-modal/order-management-modal.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    EcorealCtSharedModule,
    TableModule,
    TabsModule,
    NgCircleProgressModule.forRoot(),
    TooltipModule,
    MultiSelectModule,
    FileUploadModule,
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    PanelModule,
    BrowserAnimationsModule,
    AutoCompleteModule,
    DragDropModule,
    SelectButtonModule,
    CardModule,
    DropdownModule,
    InputTextModule,
    InputSwitchModule,
    SeWebModule,
    LayoutModule,
  ],
  declarations: [
    TranslationComponent,
    PriceManagementComponent,
    ContactManagementComponent,
    ContactDetailsComponent,
    DistributorComponent,
    DistributorFormComponent,
    TranslationModalComponent,
    TranslationFilterComponent,
    TranslationButtonComponent,
    OfferDataManagementComponent,
    OdmOfferSelectionComponent,
    OdmAddModalComponent,
    OdmOfferSldComponent,
    OdmListReferencesComponent,
    OdmListOffersComponent,
    OdmAddOfferComponent,
    OdmAddOfferStepperComponent,
    OdmAddOfferNavButtonComponent,
    OdmAddOfferCharacteristicsComponent,
    OdmAddOfferFiltersComponent,
    OdmAddOfferMainCharacteristicsComponent,
    OdmAddOfferDocumentsComponent,
    OdmAddOfferReferencesComponent,
    OdmAddOfferPublicationComponent,
    OdmCharacteristicsComponent,
    OdmAddOfferDocumentsPreviewComponent,
    OdmOfferLayoutComponent,
    UpSellingTransformerComponent,
    ServicesComponent,
    TrialConfigurationComponent,
    NetPriceFormComponent,
    PackageManagementComponent,
    UserManagementComponent,
    PublicPriceUploadComponent,
    QuotationSetupComponent,
    DataManagementComponent,
    OdmManageAccessoriesComponent,
    OdmManageCategoryComponent,
    OdmModalCategoryNameComponent,
    OdmManageAccessoryComponent,
    OdmEditAccessoryModalComponent,
    OdmEditAccessoryValueModalComponent,
    OdmManageOptionsComponent,
    OdmOptionsListReferencesComponent,
    OdmManageOptionComponent,
    OdmManageOptionValuesComponent,
    OdmEditOptionModalComponent,
    OdmEditOptionValueModalComponent,
    PackageManagementByGroupComponent,
    OdmModalNameComponent,
    OdmRangeManagementModalComponent,
    DocumentationManagementComponent,
    DocumentationEditModalComponent,
    DocumentationLinkRefModalComponent,
    OrderManagementModalComponent,
  ],
  exports: [],
  providers: [
    PriceManagementService,
    UploadService,
    TranslationService,
    TranslationHelperService,
    DistributorService,
    OfferDataManagementService,
    UpSellingTransformerService,
    ServicesService,
    PackageOfferService,
    PackageOfferGroupService,
    PackageService,
    UtilService,
    FileService,
  ]
})
export class AdminModule {
}
