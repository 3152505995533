import { CanActivate } from "@angular/router";
import {Injectable} from "@angular/core";
import {AuthService} from "../auth/auth.service";


@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private authService: AuthService) {};
  /**
   * User should be logged
   */
  canActivate (): boolean {
    return this.authService.isLogged();
  }
}
