/* Angular modules */
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
/* ngx modules */
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {BsDropdownModule, TabsModule, TooltipModule} from 'ngx-bootstrap';
/* app modules */
import {CoreModule} from './core/core.module';
import {AppComponent} from './app.component';
import {CustomTranslateLoader} from './shared/localization/CustomTranslateLoader';
import {EcorealCtSharedModule} from './shared/shared.module';
import {LayoutModule} from './layout/layout.module';
import {ProjectModule} from './project/project.module';
import {BomModule} from './bom/bom.module';
import {ExportModule} from './export/export.module';
import {TransformerModule} from './transformer/transformer.module';
import {PackageOfferModule} from './package-offer/package-offer.module';
/* environment */
import {AppRoutingModule} from './app-routing.module';
import {ToastModule} from 'primeng/toast';
import {ConfigurationModule} from './configuration/configuration.module';
import {AdminModule} from './admin/admin.module';
import {TransformerV2Module} from './transformer-v2/transformer-v2.module';
import {FeedbackModule} from './feedback/feedback-module';
import {RatingModule} from 'ngx-bootstrap/rating';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {ProjectSharingModule} from './project-sharing/project-sharing.module';
import {ProjectBomModule} from './project-bom/project-bom.module';
import {OffersModule} from './offers/offers.module';
import {CharacteristicsModule} from './characteristics/characteristics.module';
import {ServicesCatalogModule} from './services-catalog/services-catalog.module';
import {MessageService} from "primeng/api";
import {SeWebModule} from '@se/web-ui-angular';
import {ContainerModule} from "./container/container.module";
import {RouterOutlet} from "@angular/router";
import {LoginModule} from "./login/login.module";


// AoT requires an exported function for factories
export function createTranslateLoader(httpClient: HttpClient) {
  return new CustomTranslateLoader(httpClient);
}

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    CoreModule,
    EcorealCtSharedModule,
    LayoutModule,
    OffersModule,
    CharacteristicsModule,
    ConfigurationModule,
    ProjectModule,
    ProjectSharingModule,
    BomModule,
    ProjectBomModule,
    ExportModule,
    TransformerModule,
    TransformerV2Module,
    PackageOfferModule,
    ServicesCatalogModule,
    AdminModule,
    ToastModule,
    AppRoutingModule,
    FeedbackModule,
    ContainerModule,
    RatingModule.forRoot(),
    ProgressbarModule.forRoot(),
    SeWebModule.forRoot(),
    RouterOutlet,
    LoginModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [MessageService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
