import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FilterCategory} from '../shared/model/filterCategory';
import * as _ from 'lodash';
import {LoggerService} from '../../shared/logging/logger.service';

@Component({
  selector: 'app-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.less']
})
export class FilterListComponent implements OnChanges {


  @Input()
  filters: Array<FilterCategory>;

  @Input()
  resetFilters: boolean;

  @Output()
  selectedFiltersEvent = new EventEmitter<Array<FilterCategory>>();

  @Output()
  removeAllFiltersEvent = new EventEmitter();

  filtersOpened: Array<boolean> = new Array(false);
  filtersGroupOpened: Array<boolean> = new Array(false);

  filtersByRange: any;

  constructor(private logger: LoggerService) {
  }

  ngOnChanges(): void {
    this.filtersByRange = _.groupBy(this.filters, filter => filter.rangeName);
    this.resetOpenedFilters();
  }

  /**
   * emit an event to selection component to remove all filters
   * and display all initial components
   */
  public removeAllFilters() {
    this.removeAllFiltersEvent.emit();
    this.filtersOpened = new Array(false);
    this.filtersGroupOpened = new Array(false);
  }

  /**
   * Update status of filters as checked or disabled
   * @param {boolean} checked
   * @param {string} key
   * @param {string} value
   */
  public updateFiltersStatus(checked: boolean, key: string, value: string) {
    this.logger.business(checked ? 'Filter selected' : 'Filter unselected', {filter_name: key, filter_value: value});
    this.filters.forEach(filterCategory => {
      filterCategory.values.forEach(filterValue => {
        if (filterCategory.key === key && filterValue.value === value) {
          filterValue.checked = checked;
          filterValue.disabled = false;
        }
      });
      }
    );
    this.selectedFiltersEvent.emit(this.filters);
  }

  /**
   * When changing components type need to reset all filters
   */
  public resetOpenedFilters() {
    this.filtersOpened = new Array(false);
    this.filtersGroupOpened = new Array(false);
  }
}


