/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
/* app modules */
import {Item, Status} from '../../project/shared/project-model';
import {DocumentInformations} from '../../export/shared/document-informations.model';
import {LoggerService} from '../logging/logger.service';
import {Documentation, EditDocumentationForm} from './documentation.model';
@Injectable()
export class DocumentationService {

  constructor(private httpClient: HttpClient,
              private logger: LoggerService) {
  }

  generateCommercialBID(projectId: string, item: Item, language, isPDFFormat): Observable<any> {
    if (!item) {
      this.logger.error('DocumentationService generateCommercialBID(): range must be defined');
      throw new Error('DocumentationService generateCommercialBID(): range must be defined');
    }

    return this.httpClient.post('/export/CommercialBID/' + projectId + '/' + item.id,
      {
        language: language,
        isPDFFormat: isPDFFormat
      },
      {responseType: 'blob', observe: 'response'});
  }

  generateTechnicalBID(projectId: string, item: Item, language, isPDFFormat): Observable<any> {
    if (!item) {
      this.logger.error('DocumentationService generateTechnicalBID(): range must be defined');
      throw new Error('DocumentationService generateTechnicalBID(): range must be defined');
    }

    return this.httpClient.post('/export/TechnicalBID/' + projectId + '/' + item.id,
      {
        language: language,
        isPDFFormat: isPDFFormat
      },
      {responseType: 'blob', observe: 'response'});
  }

  generateCommercialAndTechnicalBID(projectId: string, item: Item, language, isPDFFormat): Observable<any> {
    if (!item) {
      this.logger.error('DocumentationService generateTechnicalBID(): range must be defined');
      throw new Error('DocumentationService generateTechnicalBID(): range must be defined');
    }

    return this.httpClient.post('/export/CommercialAndTechnicalBID/' + projectId + '/' + item.id,
      {
        language: language,
        isPDFFormat: isPDFFormat
      },
      {responseType: 'blob', observe: 'response'});
  }


  generateCGV(isPDFFormat): Observable<any> {
    return this.httpClient.post('/export/CGV',
      {
        isPDFFormat: isPDFFormat
      },
      {responseType: 'blob', observe: 'response'});
  }

  /**
   * Generate document of purchase order for item email
   * @param {string} projectId current project Id
   * @param {Item} item current Item
   * @param {string} language user's language
   * @param {boolean} isPDFFormat if export on pdf format
   * @param {number} orderDate date of order in milliseconds
   */
  generatePurchaseOrderForItemEmail(projectId: string, item: Item, language, isPDFFormat, type, orderDate: number): Observable<any> {
    return this.httpClient.post('/export/OrderBID/' + projectId + '/' + item.id,
      {
        language: language,
        isPDFFormat: isPDFFormat
      },
      {responseType: 'blob', observe: 'response'});
  }

  /**
   * Generate document of purchase order
   * @param {string} projectId current project Id
   * @param {Item} item current Item
   * @param {string} language user's language
   * @param {boolean} isPDFFormat if export on pdf format
   * @param {number} orderDate date of order in milliseconds
   */
  generatePurchaseOrder(projectId: string, language, isPDFFormat, type, orderDate: number): Observable<any> {
    return this.httpClient.post('/export/orderProjectDocumentation/' + projectId + '/' + type, orderDate,
      {responseType: 'blob', observe: 'response'});
  }
  /**
   * Create a new document information
   * @param {string} documentationId the id of the documentation: item id (in item documentation) or project id (in project documentation)
   * @returns {Observable<DocumentInformations>} the created object
   */
  getDocumentInformations(documentationId: string): Observable<DocumentInformations> {
    return this.httpClient.get<DocumentInformations>('/documentInformations' + '/' + documentationId);
  }

  /**
   * Update documents informations
   * @param {DocumentInformations} docInfo the document information to update
   * @returns {Observable<DocumentInformations>} the new version of the object
   */
  updateDocumentInformations(docInfo: DocumentInformations): Observable<DocumentInformations> {
    return this.httpClient.put<DocumentInformations>('/documentInformations', docInfo);
  }

  /**
   * Add one doc to the downloaded doc of the item
   * @param {string} projectId the id of the project
   * @param {string} itemId the id of the item
   * @param {string} docId the doc id
   * @returns {Observable<DocumentInformations>} the new version of the document informations
   */
  addDownloadedDoc(projectId: string, itemId: string, docId: string): Observable<DocumentInformations> {
    return this.httpClient.put<DocumentInformations>('/documentInformations/' + projectId + '/' + itemId + '/' + docId, null);
  }

  /**
   * Service for reseting downloaded doc of item (use case example : change transformer in transformer range)
   * @param {string} projectId the id of the project
   * @param {string} itemId the id the item
   * @returns {Observable<DocumentInformations>} the new version of the document informations
   */
  resetDownloadedDoc(projectId: string, itemId: string): Observable<DocumentInformations> {
    return this.httpClient.put<DocumentInformations>('/documentInformations/' + projectId + '/' + itemId + '/reset', null);
  }

  /**
   * Check order documentation list
   * @param itemId current item id
   */
  getOrderDocuments(itemId: string): Observable<Array<Documentation>> {
    return this.httpClient.get<Array<Documentation>>('/documents/order/' + itemId);
  }

  /**
   * Check order documentation for project
   * @param projectId current project id
   * @param itemStatus items status: order for document tab, quoted for sending email
   */
  getProjectOrderDocuments(projectId: string, itemStatus: Status): Observable<Documentation> {
    return this.httpClient.get<Documentation>('/documents/order/project/' + projectId);
  }

  /**
   * Generate a documentation for commercial offer that resume all configurations of project
   * @param projectId
   */
  generateProjectDocumentation(projectId: string): Observable<any> {
    if (!projectId) {
      this.logger.error('DocumentationService generateProjectDocumentation(): projectId must be defined');
      throw new Error('DocumentationService generateProjectDocumentation(): projectId must be defined');
    }
    return this.httpClient.post('/export/projectDocumentation/' + projectId, {}, {responseType: 'blob', observe: 'response'});
  }

  getAllDocumentsOfCountry(): Observable<Documentation[]> {
      return this.httpClient.get<Documentation[]>('/documents');
  }
  createDocumentation(editDocumentationForm: EditDocumentationForm): Observable<Documentation> {
    return this.httpClient.post<Documentation>('/documents', editDocumentationForm);
  }

  updateDocumentation(editDocumentationForm: EditDocumentationForm): Observable<Documentation> {
    return this.httpClient.put<Documentation>('/documents', editDocumentationForm);
  }

  deleteDocumentation(documentationId: string){
    return this.httpClient.delete('/documents/'+documentationId)
  }

  getAvailableCategories(): Observable<string[]> {
    return this.httpClient.get<string[]>('/documents/categories');
  }
}
