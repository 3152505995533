import {Component, Input, OnInit} from '@angular/core';
import {DocumentationHelperService} from '../../shared/documentation/documentation-helper.service';
import {Localization} from '../../shared/model/localization.model';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
/* app modules */
import {Documentation} from '../../shared/documentation/documentation.model';
import {Item, Project} from '../../project/shared/project-model';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.less']
})
export class DocumentsComponent {
  @Input()
  documents:  Array<Documentation>;

  @SessionStorage()
  localization: Localization;

  @SessionStorage()
  user;

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  currentProject: Project;

  constructor() { }
}
