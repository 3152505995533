import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PackageOfferService, UploadService, PackageService} from '../shared';
import {PackageOffer, PackageOfferGroup} from '../shared/package/package.model';
import {MessageService} from 'primeng/api';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {OdmModalNameComponent} from "./odm-modal-name/odm-modal-name.component";
import {Pair} from "../../shared/util/pair";
import {PackageOfferGroupService} from "../shared/package/package-offer-group.service";
import {CustomTranslateLoader} from "../../shared/localization/CustomTranslateLoader";
import {SessionStorage} from "ngx-webstorage";
import {User} from "../../shared/user/user";
import {LoggerService} from "../../shared/logging/logger.service";
import {Router} from '@angular/router';
import {FileService} from "../shared";
import {
  OrderManagementItem,
  OrderManagementModalComponent
} from "../../shared/modals/order-management-modal/order-management-modal.component";

@Component({
  selector: 'app-package-management-by-group',
  templateUrl: './package-management-by-group.component.html',
  styleUrls: ['./package-management-by-group.component.less']
})

export class PackageManagementByGroupComponent implements OnInit {


  @SessionStorage()
  public user: User;

  @ViewChild('addGroupModal') addGroupModal: OdmModalNameComponent;
  @ViewChild('deleteGroupModal') deleteGroupModal: CommonModalComponent;
  @ViewChild('addOfferModal') addOfferModal: OdmModalNameComponent;
  @ViewChild('deleteOfferModal') deleteOfferModal: CommonModalComponent;
  @ViewChild('deleteOfferContentModal') deleteOfferContentModal: CommonModalComponent;
  @ViewChild('packageOfferGroupsReorderModal') packageOfferGroupsReorderModal: OrderManagementModalComponent;
  @ViewChild('packageOffersReorderModal') packageOffersReorderModal: OrderManagementModalComponent;

  loading = true;
  packageOffer: PackageOffer;
  packages: any[] = [];
  characteristicsOfferPackage: string[] = [];

  packageGroups: Array<PackageOfferGroup>;
  packageOffersOfGroup: Array<PackageOffer>;
  selectedGroupId: string;

  IMPORT_OK_RESPONSE = 'IMPORT-OK';

  constructor(
    private translateService: TranslateService,
    private packageOfferService: PackageOfferService,
    private readonly customTranslateLoader: CustomTranslateLoader,
    private packageOfferGroupService: PackageOfferGroupService,
    private uploadService: UploadService,
    private packageService: PackageService,
    private logger: LoggerService,
    private messageService: MessageService,
    private fileService: FileService,
    private readonly router: Router) {
  }

  ngOnInit(): void {
    this.loadPackageGroupsAndOffers();
  }

  // DATA

  loadPackageGroupsAndOffers() {
    this.loading = true;
    this.packageGroups = [];
    this.packageOffersOfGroup = [];
    this.packages = [];
    this.selectedGroupId = null;
    this.packageOffer = null;

    this.packageOfferGroupService.getAll()
      .subscribe(groups => {
        this.packageGroups = groups.sort((groupA, groupB) => {
          if (groupA.order < groupB.order){
            return -1;
          }
          return 1;
        });
        this.loading = false;
      });
  }

  getPackages(packageOffer: PackageOffer) {
    this.loading = true;
    this.packages = [];

    function replacer(key, value) {
      if(value instanceof Map) {
        return {
          dataType: 'Map',
          value: Array.from(value.entries()), // or with spread: value: [...value]
        };
      } else {
        return value;
      }
    }

    this.packageService.getAllByPackageOffer(packageOffer.id).subscribe(packages => {
        this.packages = packages;
        this.packages.sort((a, b) => a.orderIndex - b.orderIndex);
        this.packages.forEach(pack => {
          pack.characteristicsAsString = JSON.stringify(pack.characteristics, replacer);
          pack.referencesAsString = JSON.stringify(pack.switchboardComponents);
          pack.accessoryReferencesAsString = JSON.stringify(pack.accessoriesReferencesMap, replacer);
        })
        this.characteristicsOfferPackage = [];
        if (packages.length === 0) {
          this.loading = false;
        } else {
          for (const entry of Object.entries(this.packages[0].characteristics)) {
            this.characteristicsOfferPackage.push(entry[0]);
          }
          this.loading = false;
        }
      },
      () => this.loading = false);
  }


  selectPackageGroup(value: any) {
    this.selectedGroupId = value.srcElement.value;
    this.packageOffer = null;
    if (this.selectedGroupId === undefined) {
      this.packageOffersOfGroup = [];
    } else {
      this.getPackagesOffersByGroup()
    }
  }

  selectPackageOffer(value: any) {
    this.packageOffer = this.packageOffersOfGroup.find(offer => offer.id === value.srcElement.value);
    this.getPackages(this.packageOffer);
  }

  showAddGroupModal() {
    this.addGroupModal.show();
  }

  showDeleteGroupModal(){
    this.deleteGroupModal.show();
  }

  showPackageOfferGroupsOrderManagementModal() {
    this.packageOfferGroupsReorderModal.show('T_PACKAGE_OFFER_GROUPS_REORDER_MODAL_TITLE', this.packageGroups.map(group => {
      return {
        id: group.id,
        name: group.name,
        order: group.order
      }
    }))
  }

  showPackageOffersOrderManagementModal() {
    this.packageOffersReorderModal.show('T_PACKAGE_OFFERS_REORDER_MODAL_TITLE', this.packageOffersOfGroup.map(offer => {
      return {
        id: offer.id,
        name: offer.name,
        order: offer.order
      }
    }))
  }

  showAddOfferModal() {
    this.addOfferModal.show();
  }

  showDeleteOfferModal(){
    this.deleteOfferModal.show();
  }

  showDeleteOfferContentModal(){
    this.deleteOfferContentModal.show();
  }

  addGroup(translationByLanguage: Pair<string, string>[]) {
    this.loading = true;
    this.packageOfferGroupService.addGroup(translationByLanguage).subscribe(() => {
      this.customTranslateLoader.getTranslation(this.user.preferredLanguage, true).subscribe(res => {
        this.translateService.setTranslation(this.user.preferredLanguage, res, false);
        this.loadPackageGroupsAndOffers();
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_PACKAGE_CREATE_GROUP_SUCCESS_TITLE'),
          detail: this.translateService.instant('T_PACKAGE_CREATE_GROUP_SUCCESS_MESSAGE'),
        });
      }, () => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_PACKAGE_CREATE_GROUP_ERROR_TITLE'),
          detail: this.translateService.instant('T_PACKAGE_CREATE_GROUP_ERROR_MESSAGE'),
        });
        this.loading = false;
      });
    });
  }

  deleteGroup(){
    this.loading = true;
    this.packageOfferGroupService.delete(this.selectedGroupId).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('T_SUCCESS'),
        detail: this.translateService.instant('T_PACKAGE_DELETE_GROUP_SUCCESS_MESSAGE'),
      });
      this.loading = false;
      this.loadPackageGroupsAndOffers();
    }, () => {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('T_ERROR'),
        detail: this.translateService.instant('T_ERROR_MESSAGE'),
      });
      this.loading = false;
    });
  }

  addOffer(translationByLanguage: Pair<string, string>[]) {
    this.loading = true;
    this.packageOfferService.addOffer(translationByLanguage, this.selectedGroupId).subscribe(() => {
      this.customTranslateLoader.getTranslation(this.user.preferredLanguage, true).subscribe(res => {
        this.translateService.setTranslation(this.user.preferredLanguage, res, false);
        this.getPackagesOffersByGroup();
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_PACKAGE_CREATE_OFFER_SUCCESS_TITLE'),
          detail: this.translateService.instant('T_PACKAGE_CREATE_OFFER_SUCCESS_MESSAGE'),
        });
      });
    }, () => this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_PACKAGE_CREATE_OFFER_ERROR_TITLE'),
      detail: this.translateService.instant('T_PACKAGE_CREATE_OFFER_ERROR_MESSAGE'),
    }));
  }

  deleteOffer(){
    this.loading = true;
    this.packageOfferService.delete(this.packageOffer.id).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('T_SUCCESS'),
        detail: this.translateService.instant('T_PACKAGE_DELETE_OFFER_SUCCESS_MESSAGE'),
      });
      this.loading = false;
      this.loadPackageGroupsAndOffers();
    }, () => {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('T_ERROR'),
        detail: this.translateService.instant('T_ERROR_MESSAGE'),
      });
      this.loading = false;
    });
  }

  imageUpload(event) {
    this.logger.info('upload package offer image');
    const files = event.target.files;
    if (files.length > 0) {
      const allowedFormat = 'image/jpeg';
      const maximumSize = 8000*1024; // 8mb
      if (files[0].type !== allowedFormat || files[0].size > maximumSize) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_UPLOAD_IMAGE_FORMAT_ERROR'),
        });
        return;
      }
      const formData: FormData = new FormData();
      formData.append('file', files[0]);
      this.loading = true;
      this.fileService.create(formData).subscribe(file => {
        this.packageOffer.imageFileId = file.id;
        this.packageOffer.image = file.data;
        this.packageOfferService.update(this.packageOffer).subscribe(() => {
          this.loading = false;
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('T_INFO'),
            detail: this.translateService.instant('T_UPLOAD_IMAGE_SUCCESS'),
          });
        });

      }, () => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_UPLOAD_IMAGE_ERROR'),
        });
        this.loading = false;
      });
    }
    // clean files in event
    event.target.value = null;
  }

  fileUploader(event) {
    this.logger.info('upload package file');
    const files = event.target.files;
    if (files.length > 0) {
      if (files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_PACKAGE_IMPORT_FILE_TYPE_TILE'),
          detail: this.translateService.instant('T_PACKAGE_IMPORT_FILE_TYPE_MESSAGE'),
        });
        return;
      }
      const formData: FormData = new FormData();
      formData.append('file', files[0]);
      const uploadUrl = '/package-offer/upload/' + this.packageOffer.id;
      this.loading = true;
      this.uploadService.uploadFile(uploadUrl, formData).subscribe(importResponse => {
        this.loading = false;
        if (importResponse === this.IMPORT_OK_RESPONSE) {
          this.messageService.add({
            severity: 'info',
            summary: this.translateService.instant('T_PACKAGE_IMPORT_SUCCESS_TITLE'),
            detail: this.translateService.instant('T_PACKAGE_IMPORT_SUCCESS_MESSAGE'),
          });
          // reload reference package data
          this.getPackages(this.packageOffer);
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_PACKAGE_IMPORT_ERROR_TITLE'),
            detail: this.translateService.instant('T_PACKAGE_IMPORT_ERROR_MESSAGE'),
          });
        }
      }, (error) => {
        if(error.includes("413")){
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_PRICE_IMPORT_ERROR_TITLE'),
            detail: this.translateService.instant('T_PRICE_IMPORT_ERROR_FILE_TOO_BIG_MESSAGE'),
          });
        } else if(error.includes("423")){
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_PRICE_IMPORT_ERROR_TITLE'),
            detail: this.translateService.instant('T_PRICE_IMPORT_ERROR_PROCESS_LOCKED'),
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_PRICE_IMPORT_ERROR_TITLE'),
            detail: this.translateService.instant('T_PRICE_IMPORT_ERROR_MESSAGE'),
          });
        }

        this.loading = false;
      });
    }
    // clean files in event
    event.target.value = null;

  }

  fileExport() {
    this.logger.info('Export package : ' + this.packageOffer.name + " of " + this.selectedGroupId);
    this.loading = true;
    this.uploadService.exportPackageFile(this.packageOffer.id).subscribe(
      response => {
        this.loading = false;
        this.uploadService.createXlsFileFromBackendResponse(response);
      }, () => {
        this.loading = false;
      }
    );


  }

  deleteOfferContent(){
    this.loading = true;
    this.packageOfferService.deleteOfferContent(this.packageOffer.id).subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('T_SUCCESS'),
        detail: this.translateService.instant('T_PACKAGE_DELETE_ALL_PACKAGES_SUCCESS_MESSAGE'),
      });
      this.loading = false;
      this.loadPackageGroupsAndOffers();
    }, () => {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('T_ERROR'),
        detail: this.translateService.instant('T_ERROR_MESSAGE'),
      });
      this.loading = false;
    });
  }

  onPackageOfferGroupsOrderSaved(items: OrderManagementItem[]){
    this.packageOfferGroupService.updateOrder(items.map(item => {
      return {
        first: item.id,
        second: item.order
      }
    })).subscribe(() => {
      this.loadPackageGroupsAndOffers();
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('T_SUCCESS'),
        detail: this.translateService.instant('T_UPDATE_ORDER_SUCCESS_MESSAGE'),
      });
    }, () => {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('T_ERROR'),
        detail: this.translateService.instant('T_UPDATE_ORDER_ERROR_MESSAGE'),
      });
    });
  }

  onPackageOffersOrderSaved(items: OrderManagementItem[]){
    this.packageOfferService.updateOrder(items.map(item => {
      return {
        first: item.id,
        second: item.order
      }
    })).subscribe(() => {
      this.loadPackageGroupsAndOffers();
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('T_SUCCESS'),
        detail: this.translateService.instant('T_UPDATE_ORDER_SUCCESS_MESSAGE'),
      });
    }, () => {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('T_ERROR'),
        detail: this.translateService.instant('T_UPDATE_ORDER_ERROR_MESSAGE'),
      });
    });
  }

  getLabelTooltip(){
    return this.translateService.instant('T_PACKAGE_OFFER_TOOLTIP');
  }

  goToTranslationPage(translationKey: string) {
    this.router.navigate(['admin/translation'], { queryParams: { translationKey } });
  }

  private getPackagesOffersByGroup() {
    this.loading = true;
    this.packages = [];
    this.packageOffer = null;
    this.packageOfferGroupService.getOffersSelectByGroupId(this.selectedGroupId).subscribe(result => {
      this.packageOffersOfGroup = result.offers.filter(offer => offer.type === 'IMPORTED').sort((a,b)=>{
        if(a.order < b.order){
          return -1;
        }
        return 1;
      });
      this.loading = false;
    });
  }
}
