import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {AccessoryCharacteristics} from '../../../../shared/model/accessory.model';
import {CommonModalComponent} from '../../../../shared/common-modal/common-modal.component';
import {TranslationService} from '../../../shared';
import {TranslateService} from '@ngx-translate/core';
import {SessionStorage} from 'ngx-webstorage';
import {User} from '../../../../shared/user/user';
import {Range} from '../../../../shared/model/range-model';
import {OdmAddModalComponent} from '../../shared/odm-add-modal/odm-add-modal.component';
import {CharacteristicValue} from '../../../../characteristics/shared/characteristics.model';
import {RangeType} from '../../../../shared/model/range-type';

export type EditAccessoryValueForm = {
  accessoryId: string;
  value: {
    [key: string]: string;
  };
  bslReference?: string;
  valueId?: string;
}

@Component({
  selector: 'app-odm-edit-accessory-value-modal',
  templateUrl: './odm-edit-accessory-value-modal.component.html',
  styleUrls: ['./odm-edit-accessory-value-modal.component.less']
})
export class OdmEditAccessoryValueModalComponent {

  @Output()
  saveAccessoryValue: EventEmitter<EditAccessoryValueForm> = new EventEmitter<EditAccessoryValueForm>();

  @ViewChild('editAccessoryValueModal', {static: false}) editAccessoryValueModal: CommonModalComponent;
  @ViewChild('addReferenceModal', {static: false}) addReferenceModal: OdmAddModalComponent;

  @SessionStorage()
  public user: User;

  selectedRange: Range;
  availableAccessories: any[];
  editAccessoryValueForm: EditAccessoryValueForm = {accessoryId: null, value: {}};
  allowedReferenceCategoriesForAccessories: string[];

  constructor(private translationService: TranslationService,
    private translateService: TranslateService) {
  }

  showAddMode(availableAccessories: AccessoryCharacteristics[], selectedRange: Range){
    this.editAccessoryValueForm = {accessoryId: null, value: {}};
    this.selectedRange = selectedRange;
    this.availableAccessories = availableAccessories;

    this.availableAccessories.forEach(accessory => {
      accessory.nameTranslated = this.translateService.instant(accessory.name);
    })

    this.translationService.getAllLanguagesByCountry(this.user.partnerCountry).subscribe(languages => {
      languages.forEach(lang => {
        this.editAccessoryValueForm.value[lang] = '';
      })
    })
    this.setAllowedCategories(selectedRange);

    this.editAccessoryValueModal.show();
  }

  showEditMode(selectedRange: Range, accessory: AccessoryCharacteristics, accessoryValue: CharacteristicValue) {
    this.editAccessoryValueForm = {accessoryId: accessory.id, value: {}, valueId: accessoryValue.id.toString()};
    this.selectedRange = selectedRange;

    if(accessoryValue.reference){
      this.editAccessoryValueForm.bslReference = accessoryValue.reference.ref;
    }

    this.translationService.getLocalesOfCountryOfKey(accessoryValue.value.toString()).subscribe(locale => {
      locale.forEach(loc => {
        this.editAccessoryValueForm.value[loc.language] = loc.translations[0].translation;
      })
    });

    this.setAllowedCategories(selectedRange);

    this.editAccessoryValueModal.show();
  }

  hide() {
    this.editAccessoryValueModal.hide();
  }

  isValidForm(): boolean {
    if(!this.editAccessoryValueForm.accessoryId){
      return false;
    }

    for (let key in this.editAccessoryValueForm.value) {
      if(!this.editAccessoryValueForm.value[key]){
        return false;
      }
    }

    return true;
  }

  clickOnSaveAccessoryValue() {
    this.saveAccessoryValue.emit(this.editAccessoryValueForm);
  }

  clickAddReferenceButton(){
    this.addReferenceModal.show();
  }

  addReference(event: any){
    this.editAccessoryValueForm.bslReference = event.bslReference.reference;
  }

  clickDeleteReferenceButton() {
    this.editAccessoryValueForm.bslReference = null;
  }

  // function for the ngfor + ngmodel, so it doesn't lose focus
  trackByFn(index, item): number {   return index; }

  private setAllowedCategories (selectedRange: Range) {
    if (selectedRange.rangeType === RangeType.FAKE) {
      this.allowedReferenceCategoriesForAccessories = ['Cubicle', 'Switchboard', 'Transformers', 'Accessory', 'Accessories', 'Kit'];
    } else {
      this.allowedReferenceCategoriesForAccessories = ['Accessory', 'Accessories', 'Kit']
    }
  }

}
