import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Localization} from '../model/localization.model';
import {DomSanitizer} from '@angular/platform-browser';
import {UtilService} from '../util/util.service';
import {TranslationService} from '../../admin/shared';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-demo-page',
  templateUrl: './demo-page.component.html',
  styleUrls: ['./demo-page.component.less']
})
export class DemoPageComponent implements OnInit, OnChanges {

  @Input()
  localization: Localization;

  embededLink = '';
  demoMessage = '';

  private DEMO_WELCOME_KEY = 'T_DEMO_VERSION_HOME_MESSAGE';

  constructor(private sanitizer: DomSanitizer,
              private translationService: TranslationService,
              private translateService: TranslateService) {}

  ngOnInit(): void {
    this.initEmbededUrl();
    this.initWelcomeMessageForDemo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  /**
   * Method to format youtube url to be embeded
   */
  initEmbededUrl() {
    if (this.localization !== null && this.localization.demoVideoLink !== null &&
      this.localization.demoVideoLink !== '' ) {
      this.embededLink = UtilService.convertYoutubeUrlToEmbededUrl(this.localization.demoVideoLink);
    }
  }

  /**
   * init welcome Message for demo
   */
  initWelcomeMessageForDemo() {
    if (this.localization && this.localization.langs && this.localization.indexDemoDefaultlanguage !== null &&
      this.localization.indexDemoDefaultlanguage !== undefined) {
      this.translationService.getTranslations(this.DEMO_WELCOME_KEY, this.localization.langs[this.localization.indexDemoDefaultlanguage].language).subscribe(
        translation => {
          this.demoMessage = translation;
        },
        error => {
          this.initDefaultMessageForDemo();
        }
      );
    } else {
      this.initDefaultMessageForDemo();
    }
  }

  /**
   * init welcome Message with current user language
   */
  initDefaultMessageForDemo() {
    this.demoMessage = this.translateService.instant(this.DEMO_WELCOME_KEY);
  }
}
