/* Angular modules */
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule, DatePipe} from '@angular/common';
/* ngx modules */
import {NgxWebstorageModule} from 'ngx-webstorage';
import {BootstrapModule} from '../bootstrap.module';
import {TranslateModule} from '@ngx-translate/core';
/* app modules */
import {ApplicationService} from './app/application.service';
import { ExportSldLayoutService } from './export-sld-layout/export-sld-layout.service';
import {UserService} from './user/user.service';
import {PartnerService} from './partner/partner.service';
import {LocaleService} from './localization/locale.service';
import {UtilService} from './util/util.service';
import {CommonModalComponent} from './common-modal/common-modal.component';
import {NavigationGuardService} from './guards/navigation-guard.service';
import {SpinnerComponent} from './spinner/spinner.component';
import {RightsService} from './rights/rights.service';
import {AdminGuardService} from './guards/admin-guard.service';
import {ToastModule} from "primeng/toast";
import {LoggerService} from './logging/logger.service';
import {DatasheetsService} from './datasheets/datasheets.service';
import {RegisterModalComponent} from 'app/shared/register-modal/register-modal.component';
import {RegisterService} from './register-modal/register.service';
import {DebounceDirective} from './util/debounce';
import {ProductsFiltersService} from './products-filters/products-filters.service';
import {TermsOfUseModalComponent} from './terms-of-use-modal/terms-of-use-modal.component';
import {SelectComponent} from './select/select.component';
import {ItemCarouselComponent} from './item-carousel/item-carousel.component';
import {ElementTileComponent} from './element-tile/element-tile.component';
import {CardComponent} from '../configuration/card/card.component';
import {PriceComponent} from '../configuration/price/price.component';
import {SldComponentViewerComponent} from '../configuration/sld/sld-component-viewer/sld-component-viewer.component';
import {DimensionsComponent} from '../configuration/dimensions/dimensions.component';
import {DocumentsComponent} from '../configuration/documents/documents.component';
import {CardOptionModalComponent} from '../configuration/card-option-modal/card-option-modal.component';
import {PriceFormatPipe} from '../configuration/shared/pipes/price-format.pipe';
import {CardComponentModalComponent} from '../configuration/card-component-modal/card-component-modal.component';
import {LayoutComponentViewerComponent} from '../configuration/layout/layout-component-viewer/layout-component-viewer.component';
import {CardListComponent} from '../configuration/card-list/card-list.component';
import {FilterListComponent} from '../configuration/filter-list/filter-list.component';
import {SelectionComponent} from '../configuration/selection/selection.component';
import {CustomTranslateLoader} from './localization/CustomTranslateLoader';
import {NewsNotificationsCardComponent} from './news-notifications-card/news-notifications-card.component';
import {DemoPageComponent} from './demo-page/demo-page.component';
import {MenuModule} from 'primeng/menu';
import {ButtonModule} from 'primeng/button';
import {AccessoriesService} from './services/accessories.service';
import {AccessoryCategoriesService} from './services/accessoryCategories.service';
import {OptionsService} from './services/options.service';
import {CardDocumentationComponent} from "../configuration/card-documentation/card-documentation.component";
import {DocumentationListComponent} from "../export";
import {
  CardDocumentationListComponent
} from "../configuration/card-documentation-list/card-documentation-list.component";

@NgModule({
  imports: [
    CommonModule,
    NgxWebstorageModule.forRoot(),
    TranslateModule,
    BootstrapModule,
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    MenuModule,
    ButtonModule
  ],
  declarations: [
    CommonModalComponent,
    SpinnerComponent,
    ItemCarouselComponent,
    RegisterModalComponent,
    DebounceDirective,
    TermsOfUseModalComponent,
    SelectComponent,
    ElementTileComponent,
    CardComponent,
    PriceComponent,
    SldComponentViewerComponent,
    DimensionsComponent,
    DocumentsComponent,
    PriceFormatPipe,
    CardComponentModalComponent,
    CardDocumentationComponent,
    CardDocumentationListComponent,
    LayoutComponentViewerComponent,
    CardListComponent,
    CardOptionModalComponent,
    FilterListComponent,
    SelectionComponent,
    NewsNotificationsCardComponent,
    DemoPageComponent
  ],
  providers: [
    ApplicationService,
    UserService,
    RightsService,
    PartnerService,
    DatasheetsService,
    CustomTranslateLoader,
    LocaleService,
    UtilService,
    NavigationGuardService,
    AdminGuardService,
    LoggerService,
    RegisterService,
    ProductsFiltersService,
    ExportSldLayoutService,
    DatePipe,
    AccessoriesService,
    OptionsService,
    AccessoryCategoriesService,
  ],
  exports: [
    BootstrapModule,
    CommonModalComponent,
    TermsOfUseModalComponent,
    SelectComponent,
    SpinnerComponent,
    ElementTileComponent,
    ItemCarouselComponent,
    RegisterModalComponent,
    DebounceDirective,
    CardComponent,
    PriceComponent,
    SldComponentViewerComponent,
    DimensionsComponent,
    DocumentsComponent,
    PriceFormatPipe,
    CardComponentModalComponent,
    CardDocumentationComponent,
    CardDocumentationListComponent,
    LayoutComponentViewerComponent,
    CardListComponent,
    CardOptionModalComponent,
    FilterListComponent,
    SelectionComponent,
    NewsNotificationsCardComponent,
    DemoPageComponent
  ]
})
export class EcorealCtSharedModule {
}
