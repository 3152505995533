/* angular modules */
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
import {TranslateService} from '@ngx-translate/core';
/* app modules */
import {SwitchBoardComponent} from '../shared/model/component';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {CardComponentModalComponent} from '../card-component-modal/card-component-modal.component';
import {WrapperModalContent} from '../shared/model/wrapperModalContent';
import {Item, Project, Status} from '../../project/shared/project-model';
import {ComponentType} from '../shared/model/component-type';
import {AddingTransformerInfosWrapper} from '../shared/model/AddingTransformerInfosWrapper';
import {ItemService} from '../../project/shared/item.service';
import {MessageService} from 'primeng/api';
import {OptionsPanelService} from '../shared/services/options-panel.service';
import {PanelOptionWrapper} from '../shared/model/panelOptionWrapper';
import {ProjectService} from '../../project/shared/project.service';
import {isNullOrUndefined} from 'util';
import {RangeType} from '../../shared/model/range-type';
import {Subject} from 'rxjs/Rx';
import {LoggerService} from '../../shared/logging/logger.service';

@Component({
  selector: 'app-sld',
  templateUrl: './sld.component.html',
  styleUrls: ['./sld.component.less'],
})
export class SldComponent implements OnInit, OnDestroy {

  @Input()
  displayedComponents: Array<SwitchBoardComponent>;
  @Input()
  selectedComponent: SwitchBoardComponent;
  @Output()
  componentTypeEvent = new EventEmitter<ComponentType>();
  @Output()
  transformerAddingEvent = new EventEmitter<AddingTransformerInfosWrapper>();
  transformerToDeleteInfos: AddingTransformerInfosWrapper;
  @SessionStorage()
  currentItemct: Item;
  @SessionStorage()
  currentProject: Project;
  @SessionStorage()
  user;
  @ViewChild('componentInfosModal')
  componentInfosModal: CardComponentModalComponent;
  @ViewChild('deleteModal') confirmDeleteModal: CommonModalComponent;
  warningDeleteMessage: string;
  cubicleSelected: boolean;
  transformerSelected: boolean;
  selectedFunction: number;
  limitCubicleNumber: number;
  panelOptionWapper: PanelOptionWrapper;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private itemService: ItemService,
              private translateService: TranslateService,
              private messageService: MessageService,
              private projectService: ProjectService,
              private optionsPanelService: OptionsPanelService,
              private logger: LoggerService) {
  }

  ngOnInit() {
    $('#zoomSld').append('<script src="assets/scripts/jquery.panzoom_v4.js"></script>')
       .append('<script src="assets/scripts/config.zoom_v4.js"></script>');
    this.optionsPanelService.getSelectedComponentInfos()
      .takeUntil(this.unsubscribe$)
      .subscribe(
      infos => {
        this.panelOptionWapper = infos;
        if (this.panelOptionWapper) {
          this.cubicleSelected = ComponentType.CUBICLE === this.panelOptionWapper.type;
          this.transformerSelected = ComponentType.TRANSFORMER === this.panelOptionWapper.type;
          this.selectedFunction = this.panelOptionWapper.functionPosition;
        }
      }
    );
    if (this.currentItemct.range.rangeType === RangeType.SWITCHBOARD) {
      this.limitCubicleNumber = this.currentItemct.range.switchboardLimit.max;
    }
  }

  public isSelectedCubicle(indexCubicle: number): boolean {
    if (isNullOrUndefined(this.selectedComponent)) {
      this.currentItemct.selectedComponentIndex = -1;
    }
    return this.currentItemct.selectedComponentIndex === indexCubicle;
  }

  selectComponent(wrapperModalContent: WrapperModalContent) {
    this.logger.business('Reference information', {reference_id: wrapperModalContent.component.id, reference_name: wrapperModalContent.component.name});
    this.componentInfosModal.show(wrapperModalContent);
  }

  addTransformerEvent(transfoAddInformation: AddingTransformerInfosWrapper) {
    this.transformerAddingEvent.emit(transfoAddInformation);
    this.componentTypeEvent.emit(ComponentType.TRANSFORMER);
    // open the slide selection panel
    const close_btn = document.getElementById('btn-selection-slide');
    close_btn.click();
  }

  addCubicleEvent() {
    this.componentTypeEvent.emit(ComponentType.CUBICLE);
    // open the slide selection panel
    const close_btn = document.getElementById('btn-selection-slide');
    close_btn.click();
  }

  removeTransformer() {
    const functionalUnit = this.currentItemct.components[this.transformerToDeleteInfos.cubicleNumber]
      .functionalUnits[this.transformerToDeleteInfos.functionalUnitNumber];
    if (functionalUnit.transformer) {
      this.currentItemct.components[this.transformerToDeleteInfos.cubicleNumber]
        .functionalUnits[this.transformerToDeleteInfos.functionalUnitNumber].transformer = null;
      this.itemService.updateItemWithStatusChange(this.currentItemct, Status.configured, this.user)
        .takeUntil(this.unsubscribe$)
        .subscribe(item => {
            this.currentItemct = item;
            this.itemService.updateTotalPriceAndDimensions(this.unsubscribe$);
          },
          error => null,
          () => this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('T_INFORMATION'),
            detail: this.translateService.instant('T_SUCCESS_REMOVE_ITEM'),
          }));
    }
  }

  deleteComponent(event) {
    this.confirmDeleteModal.show();
    this.warningDeleteMessage = this.getDeleteModalDescription();
  }

  getDeleteModalDescription() {
    if (this.currentItemct.status === Status.quoted) {
      return this.translateService.instant('T_QUOTATION_WARNING');
    } else {
      return this.translateService.instant('T_DELETE_ITEM_WARNING');
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.unsubscribe$.next();
    // unsubscribe from the subject itself:
    this.unsubscribe$.unsubscribe();
  }

  isReadOnlyProject(): boolean {
    return this.projectService.isReadOnlyProject(this.currentProject, this.user);
  }

  /**
   * Return true if we have to show "Add" icon to add a new cubicle
   */
  showAddCubicleIcon(): boolean {
    return this.currentItemct.components.length < this.limitCubicleNumber && !this.isReadOnlyProject() &&
      this.currentItemct.status !== Status.ordered;
  }

}
