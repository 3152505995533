export const RestrictedAccessAvailable: RestrictedAccess[] = [
    {key: 'NONE', value: 'T_NONE'},
    {key: 'DP_OFFER', value: 'T_PUBLIC_DISTRIBUTION_FR'},
    {key: 'OFFER_GROUP_1', value: 'T_OFFER_GROUP_1'},
    {key: 'OFFER_GROUP_2', value: 'T_OFFER_GROUP_2'},
    {key: 'OFFER_GROUP_3', value: 'T_OFFER_GROUP_3'},
    {key: 'OFFER_GROUP_4', value: 'T_OFFER_GROUP_4'},
];

export class RestrictedAccess {
    key: string;
    value: string;
}
