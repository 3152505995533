import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PackageOffer, PackageOfferGroup} from '../../admin/shared/package/package.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-package-offer-group-selector',
  templateUrl: './package-offer-group-selector.component.html',
  styleUrls: ['./package-offer-group-selector.component.scss']
})
export class PackageOfferGroupSelectorComponent {

  @Input()
  packageOfferGroups: PackageOfferGroup[];
  @Input()
  packageOffers: PackageOffer[];
  @Input()
  selectedPackageOfferGroup: PackageOfferGroup;

  @Output()
  packageOfferSelected: EventEmitter<PackageOffer> = new EventEmitter<PackageOffer>();

  constructor(private translateService: TranslateService) {
  }

  getFilteredPackagesOffers(){
    return this.packageOffers.filter(packageOffer => packageOffer.packageOfferGroupId === this.selectedPackageOfferGroup.id)
  }

  onPackageOfferSelection(packageOffer: PackageOffer){
    this.packageOfferSelected.emit(packageOffer);
  }
}
