import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
import {Item, Project, Status} from '../../project/shared/project-model';
import {User} from '../../shared/user/user';
import {ActiveTabType} from '../shared/model/activeTab_Type';
/* app modules */
import {SwitchBoardComponent} from '../shared/model/component';
import {ComponentType} from '../shared/model/component-type';
import {ModalType} from '../shared/model/modal_type';
import {WrapperModalContent} from '../shared/model/wrapperModalContent';
import {AddingTransformerInfosWrapper} from '../shared/model/AddingTransformerInfosWrapper';
import {MySEStatusEnum} from '../../shared/model/mySEStatusEnum';
import {ProjectService} from '../../project/shared/project.service';
import {Characteristic} from '../shared/model/IProduct';
import {RangeType} from '../../shared/model/range-type';
import {LoggerService} from '../../shared/logging/logger.service';
import {RangeDiscount} from '../../admin/shared';
import {UtilService} from '../../shared/util/util.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.less']
})
export class CardComponent implements OnInit, OnChanges {
  @SessionStorage()
  netPriceByPartnerDiscount: RangeDiscount[];

  @Input()
  component: SwitchBoardComponent;
  @Input()
  addingTransformerInfos: AddingTransformerInfosWrapper;

  characteristicsTab: ActiveTabType = ActiveTabType.CHARACTERISTICS;
  layoutsTab: ActiveTabType = ActiveTabType.LAYOUTS;
  documentsTab: ActiveTabType = ActiveTabType.DOCUMENTS;
  informationModal: ModalType = ModalType.INFORMATION_MODAL;
  cubicleType: ComponentType = ComponentType.CUBICLE;

  mainCharacteristics: Characteristic[] = [];

  @Output() selectedCard = new EventEmitter<WrapperModalContent>();
  @Output() addComponentEvent = new EventEmitter<SwitchBoardComponent>();

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  user: User;

  @SessionStorage()
  isDemoMode: boolean;
  @ViewChild('maxFuModal') public maxFuModal: ModalDirective;

  constructor(private projectService: ProjectService,
              private logger: LoggerService,
              private utilService: UtilService) {
  }

  ngOnInit(): void {
    this.setMainCharacteristics();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.component) {
      this.setMainCharacteristics();
    }
  }

  showModal(activeTab: ActiveTabType, modalType: ModalType) {
    if (modalType === this.informationModal) {
      this.logger.business('Reference information', {reference_id: this.component.id, reference_name: this.component.name});
    }
    const wrapperObject = new WrapperModalContent(this.component, this.component.documents, activeTab, modalType);
    this.selectedCard.emit(wrapperObject);
  }

  public addComponentDispacher(component: SwitchBoardComponent) {
    this.logger.business('Add reference', {reference_id: this.component.id, reference_name: this.component.name});
    this.addComponentEvent.emit(component);
  }

  isItemOrdered(): boolean {
    return this.currentItemct && this.currentItemct.status === Status.ordered;
  }

  isReadOnlyProject(): boolean {
    return this.projectService.isReadOnlyProject(this.currentProject, this.user);
  }

  /**
   * Return true if the price has already been calculated
   * The price has already be calculated if it is not null
   * Or if its mySE Stratus is not NOT_APPLICABLE
   */
  isAlreadyRequestedPrice() {
    return (this.component.reference && (this.isMySEUser() &&
      !(this.component.reference.mySeNetPriceStatus === MySEStatusEnum.NOT_APPLICABLE) || !this.isMySEUser()));
  }

  /**
   * Return if the card displayed is a transformer for a cubicle
   * @returns {boolean} if the card displayed is a transformer for a cubicle
   */
  isTransformerForCubicle() {
    return this.currentItemct && this.currentItemct.range.rangeType === RangeType.SWITCHBOARD && this.component.type === ComponentType.TRANSFORMER;
  }

  /**
   * Return true if the user is a mySE user
   * @returns {boolean}
   */
  isMySEUser(): boolean {
    return this.user.currentMySEAccount !== null;
  }

  /**
   * Method to know if it is a CPQ project
   * @returns {boolean}
   */
  isCpqProject(): boolean {
    return this.projectService.isCpqProject(this.currentProject);
  }

  /**
   * Method to display fo public price or fo net price
   */
  isNetPriceByPartner() {
    return this.netPriceByPartnerDiscount !== null && this.netPriceByPartnerDiscount.some(disc => disc.discount > 0);
  }

  getPriceKeyLabel(component: SwitchBoardComponent) {
    if (this.isNetPriceByPartner() || this.user.currentMySEAccount !== null) {
      return 'T_NET_PRICE';
    }
    return 'T_PUBLIC_PRICE';
  }

  getPrice(component: SwitchBoardComponent) {
    if (this.isNetPriceByPartner() && this.user.currentMySEAccount === null
      && component !== null && component !== undefined
      && component.rangeId !== null && component.rangeId !== undefined) {
      const rangeDiscount = this.netPriceByPartnerDiscount.find(disc => component.rangeId.length > 0 && disc.rangeId === component.rangeId[0]);
      if (rangeDiscount) {
        return component.reference.price * (1 - rangeDiscount.discount);
      }
    }
    return component.reference.price;
  }

  /**
   * Function that compute the two main characteristics to display on card
   */
  private setMainCharacteristics() {
    // Main carac are defined we retrieve the values of the component
    if (this.currentItemct && this.currentItemct.range.mainCharacteristics && this.currentItemct.range.mainCharacteristics.length === 2) {
      this.mainCharacteristics = [];
      this.currentItemct.range.mainCharacteristics.forEach(mainCharac => {
        const mainCharacteristic = new Characteristic();
        mainCharacteristic.key = mainCharac;
        const componentCharacValue = this.component.characteristics.find(charac => charac.key === mainCharac);
        mainCharacteristic.value = componentCharacValue ? componentCharacValue.value : '-';
        this.mainCharacteristics.push(mainCharacteristic);
      });
    } else {
      // Main carac are not defined we retrieve the first two characteristics
      this.mainCharacteristics = this.component.characteristics.slice(0, 2);
    }
    // Case of transformer displayed for a cubicle range
    if (this.isTransformerForCubicle()) {
      this.mainCharacteristics = this.component.characteristics.slice(0, 2);
    }
  }
}
