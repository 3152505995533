<div class="admin-services-container">
  <h2 translate>T_NAV_ADMIN_SERVICES_TITLE</h2>

  <!--Add new service part-->
  <form (submit)="askAddService()">
    <fieldset>
      <legend translate>T_NAV_ADMIN_SERVICES_ADD_TITLE</legend>
      <div class="add-service">
        <div class="add-service-section">
          <label translate>T_RANGE</label>
          <select class="select" [(ngModel)]="selectedRange" name="selectedRange" (change)="changeSelectedRange()">
            <ng-container *ngFor="let range of ranges">
              <option [ngValue]="range" translate>{{range.nameKey}}</option>
            </ng-container>
          </select>
        </div>
        <div class="add-service-section">
          <label translate>T_NAV_ADMIN_SERVICES_ADD_REFERENCE</label>
          <div class="input-group margin">
            <select class="select services-select" name="selectedBslServiceReference" (change)="onSelectServiceReference($event.target.value)"
                    *ngIf="!isLoadingBsl && bslServiceReferences && bslServiceReferences.length !== 0">
              <ng-container *ngFor="let ref of bslServiceReferences">
                <option [value]="ref.reference" [selected]="selectedBslServiceReference && selectedBslServiceReference.reference === ref.reference">{{ref.reference + ' / ' + ref.category}}</option>
              </ng-container>
             </select>
            <input *ngIf="!isLoadingBsl && (!bslServiceReferences || bslServiceReferences.length === 0)" class="form-control services-select" type="text" disabled
                   [value]="'T_NAV_ADMIN_SERVICES_NO_REFERENCE_FOUND' | translate">
            <input *ngIf="isLoadingBsl" class="form-control services-select select" type="text" disabled [value]="'T_ADMIN_SERVICES_LOADING_SERVICES' | translate">
          </div>
        </div>
        <div class="add-service-section">
          <label translate>T_NAV_ADMIN_SERVICES_ADD_MANDATORY</label>
          <div class="div-center">
            <label class="custom-checkbox-container">
              <input type="checkbox" class="checkbox" [(ngModel)]="mandatoryService" name="mandatoryService">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="add-service-section">
          <label translate>T_NAV_ADMIN_SERVICES_ADD_EDITABLE_QUANTITY</label>
          <div class="div-center">
            <label class="custom-checkbox-container">
              <input type="checkbox" class="checkbox" [(ngModel)]="editableQuantityService" name="editableQuantityService">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div>
          <button type="submit" [disabled]="!selectedBslServiceReference || isLoading" class="btn btn-group btn-primary button-div add-button" translate>
            T_NAV_ADMIN_SERVICES_ADD_BUTTON
          </button>
        </div>
      </div>
    </fieldset>
  </form>

  <!--Existing services part-->
  <div>
    <h3 class="subtitle" translate>T_NAV_ADMIN_SERVICES_TABLE_TITLE</h3>
    <div *ngIf="!isLoading">
      <p-table #dt styleClass="express-datatable-style" [value]="existingServiceList" [rows]="10" [paginator]="true"
               [globalFilterFields]="[cols[0].field, cols[1].field, cols[2].field, cols[3].field]">
        <ng-template pTemplate="caption">
          <div>
            <div style="text-align: left" class="col-md-6">
              <em class="fa fa-search" style="margin:4px 4px 0 0"></em>
              <input type="text" pInputText size="50" placeholder="Global Filter" (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto">
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="4" translate>T_NAV_ADMIN_SERVICES_NO_RESULT</td>
        </tr>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of cols"  [pSortableColumn]="col.field" [ngClass]="{'description-col' : col.field === 'description'}" scope="col">
              {{col.header}}
              <p-sortIcon *ngIf="col.field !== 'mandatory' && col.field !== 'editableQuantity'" [field]="col.field"></p-sortIcon>
            </th>
          <tr>
            <th *ngFor="let col of cols" [ngSwitch]="col.field" scope="col">
              <input *ngIf="col.field !== 'range' && col.field !== 'mandatory' && col.field !== 'editableQuantity'" placeholder="Search" pInputText type="text" (input)="dt.filter($event.target.value, col.field, 'contains')">
              <p-multiSelect *ngIf="col.field === 'range'" [options]="rangeSelectItems" defaultLabel="All Ranges" (onChange)="dt.filter($event.value, col.field,'in')" styleClass="ui-column-filter"></p-multiSelect>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-serciceData>
          <tr>
            <td>{{serciceData.range}}</td>
            <td>{{serciceData.reference}}</td>
            <td>{{serciceData.category}}</td>
            <td class="description-col">{{serciceData.description}}</td>
            <td>
              <div class="div-center">
                <label class="custom-checkbox-container">
                  <input type="checkbox" class="checkbox" [(ngModel)]="serciceData.mandatory" name="mandatoryService" disabled>
                  <span class="checkmark"></span>
                </label>
              </div>
            </td>
            <td>
              <div class="div-center">
                <label class="custom-checkbox-container">
                  <input type="checkbox" class="checkbox" [(ngModel)]="serciceData.editableQuantity" name="editableQuantityService" disabled>
                  <span class="checkmark"></span>
                </label>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div *ngIf="isLoading">
      <app-spinner [sizeClass]="'large'"></app-spinner>
    </div>
  </div>
</div>

<!-- Confirm dialog -->
<app-common-modal #confirmModal [title]="'T_NAV_ADMIN_SERVICES_ADD_CONFIRM_TITLE' | translate" [name]="'T_NAV_ADMIN_SERVICES_ADD_CONFIRM_TITLE'"
                  [description]="'T_NAV_ADMIN_SERVICES_ADD_CONFIRM_TITLE_INFO' | translate" (onClickYesButton)="addService()" [size]="'modal-default'">
</app-common-modal>
