<app-common-modal #manageOrderModal name="manageOrderModal" [withFooter]="false">
  <h4 modal-title class="modal-title" translate>{{title}}</h4>
  <div modal-body>
    <p-table [value]="items" (onRowReorder)="onRowReorder()">
      <ng-template pTemplate="header">
        <tr>
          <th style="width:3rem"></th>
          <th translate>T_NAME</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-index="rowIndex">
        <tr [pReorderableRow]="index">
          <td>
            <span class="pi pi-bars" pReorderableRowHandle></span>
          </td>
          <td translate>
            {{rowData.name}}
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="manage-order-modal-footer">
      <button class="btn btn-default-white-background" (click)="hide()" translate>T_BUTTON_CANCEL</button>
      <button class="btn btn-default-white-background" (click)="clickOnSave(); hide()" translate>T_BUTTON_SAVE</button>
    </div>
  </div>
</app-common-modal>

