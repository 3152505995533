
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  BslRangeCharacteristicsSummary,
  BslRangeCharacteristicsSummaryMap
} from '../../../../shared/bsl-reference/offer-data-management-model';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-odm-characteristics',
  templateUrl: './odm-characteristics.component.html',
  styleUrls: ['./odm-characteristics.component.less']
})
export class OdmCharacteristicsComponent implements OnInit {

  @Input()
  bslCharacteristicsMap: BslRangeCharacteristicsSummaryMap;

  @Input()
  disabledCharacteristicsIds?: string[];

  @Input()
  maxNumberOfChoices?: number;

  @Input()
  tooltipMessage: string;

  @Input()
  displayProperty: string;

  @Output()
  onSelectionChange: EventEmitter<BslRangeCharacteristicsSummary[]> = new EventEmitter<BslRangeCharacteristicsSummary[]>();

  characteristicsKeys: any[];

  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
    this.characteristicsKeys = Object.keys(this.bslCharacteristicsMap);
    if (this.disabledCharacteristicsIds) {
      for (const disabledKey of this.disabledCharacteristicsIds) {
        const charac = this.findCharacteristicInMap(disabledKey);
        if (charac) {
          charac.disabled = true;
        }
      }
    }
  }

  /**
   * Characteristic - checkbox state changed
   * @param checkbox
   */
  characteristicChange(checkbox) {
    this.updateModel(checkbox);
    this.onSelectionChange.emit(Object.values(this.bslCharacteristicsMap).filter((c => c.checked && !c.disabled)));
  }

  /**
   * Method to get the tooltip message
   * @param missingOnRef
   */
  getTooltipMessage(missingOnRef: string[]): string {
    return this.translateService.instant(this.tooltipMessage, { ref_list: missingOnRef.join('\n') });
  }

  /**
   * Update model
   * @param checkbox
   */
  private updateModel(checkbox) {
    const checkedNumber = Object.values(this.bslCharacteristicsMap).filter((c => c.checked)).length;
    this.findCharacteristicInMap(checkbox.id).checked = checkbox.checked;
    if (checkedNumber + 1 === this.maxNumberOfChoices && checkbox.checked) {
      this.disableAllButChecked();
    }
    if (checkedNumber === this.maxNumberOfChoices) {
      this.enableChoices();
    }
  }

  /**
   * Find characteristic in map
   * @param key
   */
  private findCharacteristicInMap(key: string) {
    return _.find(_.values(this.bslCharacteristicsMap), {key: key});
  }

  /**
   * Disable all characteristics except checked
   */
  private disableAllButChecked() {
    for (const prop in this.bslCharacteristicsMap) {
      if (this.bslCharacteristicsMap.hasOwnProperty(prop) && !this.bslCharacteristicsMap[prop].checked) {
        this.bslCharacteristicsMap[prop].disabled = true;
      }
    }
  }

  /**
   * Enable all characteristics
   */
  private enableChoices() {
    for (const prop in this.bslCharacteristicsMap) {
      if (this.bslCharacteristicsMap.hasOwnProperty(prop)
        && this.bslCharacteristicsMap[prop].disabled
        && (!this.disabledCharacteristicsIds || !this.disabledCharacteristicsIds.includes(this.bslCharacteristicsMap[prop].key))) {
        this.bslCharacteristicsMap[prop].disabled = false;
      }
    }
  }
}
