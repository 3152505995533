import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {LoggerService} from '../logging/logger.service';
import {TranslateService} from '@ngx-translate/core';
import {ModalDirective} from 'ngx-bootstrap';

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.less']
})
export class CommonModalComponent implements AfterViewInit {
  @ViewChild('childModal') public childModal: ModalDirective;
  @ViewChild('trancludedTitle') trancludedTitle;
  showTitle = false;
  @Input() name: string;
  @Input() title: string;
  @Input() twoButtonsDialogs = true;
  @Input() withFooter = true;
  @Input() closable = true;
  @Input() size: string;
  @Input() acceptInnerHtml = false;
  @Input() description: string;
  @Output() onClickYesButton = new EventEmitter<string>();
  @Output() onClickNoButton = new EventEmitter<string>();
  @Output() onClickOkButton = new EventEmitter<string>();

  constructor(private logger: LoggerService,
              private translateService: TranslateService,
              private cdRef: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    this.childModal.config.ignoreBackdropClick = true;
    if (this.twoButtonsDialogs) {
      this.childModal.config.backdrop = 'static';
    }
    this.showTitle = this.trancludedTitle.nativeElement && this.trancludedTitle.nativeElement.children.length === 0;
    this.cdRef.detectChanges();
  }

  show() {
    if (this.title === undefined) {
      this.title = this.translateService.instant('T_CONFIRM_TITLE');
    }
    if (!this.name) {
      this.logger.debug('Name of common modal is null');
      throw new Error(this.translateService.instant('T_NAME_NOT_EMPTY'));
    }
    this.childModal.show();
  }

  hide() {
    this.childModal.hide();
  }

  clickYesButton() {
    this.onClickYesButton.emit(this.name);
  }

  clickNoButton() {
    this.onClickNoButton.emit(this.name);
  }

  clickOkButton() {
    this.onClickOkButton.emit(this.name);
  }
}
