import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {UtilService} from '../util/util.service';
import {SessionStorage} from 'ngx-webstorage';
import {User} from '../user/user';
import {Item, Project, Status} from '../../project/shared/project-model';
import {ItemService} from '../../project/shared/item.service';
import {Localization} from '../model/localization.model';
import {RightsService} from '../rights/rights.service';
import {ProjectService} from '../../project/shared/project.service';
import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Rx';
import {MessageService} from 'primeng/api';
import {PackageOfferService} from '../../admin/shared';
import {FileService} from "../../admin/shared";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-item-carousel',
  templateUrl: './item-carousel.component.html',
  styleUrls: ['./item-carousel.component.less']
})
export class ItemCarouselComponent implements OnInit {

  @Input()
  project: Project;

  @Input()
  deleteItemEvent: Observable<void>;

  @Input()
  readOnly: boolean;

  @Output()
  clickOnItem: EventEmitter<[Project, Item]> = new EventEmitter<[Project, Item]>();

  @Output()
  clickOnAdd: EventEmitter<Project> = new EventEmitter<Project>();

  @Output()
  clickOnDeleteItem: EventEmitter<[Project, Item]> = new EventEmitter<[Project, Item]>();

  @SessionStorage()
  user: User;

  @SessionStorage()
  localization: Localization;

  @SessionStorage()
  isDemoMode: boolean;

  @ViewChild('itemName') itemName: string;

  deteItemEventSubscription: Subscription;

  isValidName = true;

  packageOfferImages: Map<string, string> = new Map<string, string>();
  rangeOfferImages: Map<string, string> = new Map<string, string>();

  constructor(private translateService: TranslateService,
              private rightsService: RightsService,
              private projectService: ProjectService,
              private messageService: MessageService,
              private itemService: ItemService,
              private utilService: UtilService, // do not delete, even though it says it's unused, it's used in the html
              private packageOfferService: PackageOfferService,
              private fileService: FileService) {
  }

  /**
   * To display first element when a project is deleted
   */
  ngOnInit(): void {
    this.deteItemEventSubscription = this.deleteItemEvent.subscribe(() => this.goBackFirstItem());
  }

  /**
   * To know if an item is ordered or not
   * @param item
   * @returns {boolean}
   */
  isNotOrdered(item: Item): boolean {
    return !(item.status === Status.ordered);
  }

  /**
   * Return formatting color according to item status
   * @param item
   * @returns {string} color as string
   */
  getStatusColorClass(item: Item): string {
    if (item && item.status) {
      switch (item.status) {
        case (Status.configured) :
          return 'colorConfigured';
        case (Status.quoted) :
          return 'colorQuoted';
        case (Status.ordered) :
          return 'colorOrdered';
      }
    }
    return '';
  }

  /**
   * Function to get the translated item status
   * @param item
   * @returns {string}
   */
  getStatus(item: Item): string {
    if (item && item.status) {
      // The status quoted should not appears on application, for user an item quoted is configured
      if (item.status === 'quoted') {
        return this.translateService.instant('T_configured');
      }
        return this.translateService.instant('T_' + item.status);
    } else {
      return '';
    }
  }

  /**
   * Function used to display the total price of an item
   * @param item
   * @returns {string}
   */
  getPriceItem(item: Item): string {
    if (environment.mySENetPrice && this.user.currentMySEAccount !== null && UtilService.isValidMySEPriceFromStatus(item.mySeNetPriceStatus) && item.totalPrice) {
      // MySE net price
      return UtilService.formatPrice(item.totalPrice.mySENetPrice);
    } else if (item.totalPrice && (item.totalPrice.validFoPublicPrice || item.totalPrice.validFoNetPrice) && !this.user.currentMySEAccount) {
      if (item && item.bom && item.bom.totalPrice && this.rightsService.canApplyDiscount()) {
        // DISCOUNTED NET OR PUBLIC FO PRICE
        return UtilService.getFormattedTotalDiscountPriceFromBOM(item.bom, this.user.currentMySEAccount === null && item.totalPrice.validFoNetPrice, item.discount);
      } else {
        // FO PUBLIC OR NET WITHOUT DISCOUNT
        return UtilService.formatPrice(item.totalPrice.validFoNetPrice ? item.totalPrice.foNetPrice : item.totalPrice.foPublicPrice);
      }
    } else {
      // NO VALID PRICE
      return UtilService.formatPrice(0);
    }
  }

  /**
   * Get label based on MySe price status
   * @param item
   */
  getPriceLabel(item: Item): string {
    if (item.discount && item.discount > 0 && this.rightsService.canApplyDiscount()) {
      return 'T_DISCOUNTED_PRICE';
    } else if (item.totalPrice && item.totalPrice.validMySENetPrice && this.user.currentMySEAccount !== null
      || (item.totalPrice && item.totalPrice.validFoNetPrice && this.user.currentMySEAccount === null)) {
      return 'T_NET_PRICE';
    }
    return 'T_PUBLIC_PRICE';
  }

  /**
   * Function used to navigate the carousel to go to the next item
   */
  goToNextItem() {
    const elements = document.getElementById(this.project.id).getElementsByClassName('item');

    const elementNumber = elements.length;
    for (let i = elementNumber - 1; i > 0; i--) {
      if (i > 0) {
        if (elements[i - 1].classList.contains('actif') && elements[i].classList.contains('passif')) {
          elements[i].classList.remove('passif');
          elements[i].classList.add('actif');
          elements[i - 1].classList.remove('actif');
          elements[i - 1].classList.add('passif');
        }
      }
    }
  }

  /**
   * Function used to navigate the carousel to go to the previous item
   */
  goToPreviousItem() {
    const elements = document.getElementById(this.project.id).getElementsByClassName('item');

    const elementNumber = elements.length;

    for (let i = 0; i < elementNumber; i++) {
      if (i < elementNumber - 1) {
        if (elements[i + 1].classList.contains('actif') && elements[i].classList.contains('passif')) {
          elements[i].classList.remove('passif');
          elements[i].classList.add('actif');
          elements[i + 1].classList.remove('actif');
          elements[i + 1].classList.add('passif');
        }
      }
    }
  }

  /**
   * Function called when click on an item
   * @param {Item} item
   */
  onClickOnItem(item: Item) {
    this.clickOnItem.emit([this.project, item]);
  }

  /**
   * Function called when click on an delete an item
   * @param {Item} item
   */
  onClickOnDelete(item: Item) {
    this.clickOnDeleteItem.emit([this.project, item]);
  }

  /**
   * Function called when clicking on add button
   */
  onClickOnAdd() {
    this.clickOnAdd.emit(this.project);
  }

  isCpqProject(): boolean {
    return this.projectService.isCpqProject(this.project);
  }

  /**
   * Method to activate first item
   */
  goBackFirstItem(): void {
    if (this.project !== null && this.project !== undefined && document.getElementById(this.project.id) !== null) {
      const elements = document.getElementById(this.project.id).getElementsByClassName('item');

      const elementNumber = elements.length;
      for (let i = elementNumber - 1; i >= 0; i--) {
        if (i > 2 && elements[i].classList.contains('actif')) {
          elements[i].classList.remove('actif');
          elements[i].classList.add('passif');
        }
        if (i <= 2) {
          elements[i].classList.remove('passif');
          elements[i].classList.add('actif');
        }
      }
    }
  }

  getPictureUrl(itemCt: Item): string {
    if(itemCt.range && itemCt.range.rangePicture){
      return this.getRangeImage (itemCt);
    } else if(itemCt.packageOfferId){
      return this.getPackageOfferImage(itemCt);
    } else {
      return '';
    }
  }


  private getPackageOfferImage(itemCt: Item) {
    if (!this.packageOfferImages.has(itemCt.packageOfferId)) {
      this.packageOfferImages.set(itemCt.packageOfferId, '');
      this.packageOfferService.getOfferSelectById(itemCt.packageOfferId).subscribe(packOffer => {

        if (packOffer.imageFileId) {
          this.fileService.get(packOffer.imageFileId).subscribe(file => {
            this.packageOfferImages.set(itemCt.packageOfferId, "data:image/JPEG;base64, " + file.data);
          })
        } else {
          this.packageOfferImages.set(itemCt.packageOfferId, './assets/images/package.png');
        }
      })
    } else {
      return this.packageOfferImages.get(itemCt.packageOfferId);
    }
  }

  private getRangeImage(itemCt: Item) {
    if (!this.rangeOfferImages.has(itemCt.range.rangePicture.imageFileId)) {
      if (itemCt.range.rangePicture.imageFileId !== null) {
        this.fileService.get(itemCt.range.rangePicture.imageFileId).subscribe(file => {
          this.rangeOfferImages.set(itemCt.range.rangePicture.imageFileId, "data:image/JPEG;base64, " + file.data);
        });
      } else if (itemCt.range.rangePicture.url !== null) {
        return itemCt.range.rangePicture.url;
      }
    } else {
      return this.rangeOfferImages.get(itemCt.range.rangePicture.imageFileId);
    }
  }

  updateItemName (project: Project, itemCt: Item, $event, user: User){
    if (this.isValidName) {
      this.itemService.updateSwitchboardName(project, itemCt, $event, user);
    } else {
      $event.target.innerText = itemCt.name;
      this.isValidName = UtilService.isValidString($event.target.innerText);
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('T_ERROR'),
        detail: this.translateService.instant('T_ITEMS_RENAMING_ERROR')
      });
    }
  }

  itemNameCheck ($event: any) {
    if ($event.key === 'Enter') {
      $event.target.blur();
    } else {
      this.itemService.controlMaxLengthOnItem($event);
      this.isValidName = UtilService.isValidString($event.target.textContent);
    }
  }
}
