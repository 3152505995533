/* Angular modules */
import {Component, OnDestroy, OnInit} from '@angular/core';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
import {MessageService} from 'primeng/api';
import {LoggerService} from '../../shared/logging/logger.service';
import {TranslateService} from '@ngx-translate/core';
import {ItemService} from '../../project/shared/item.service';
import {ComponentService} from '../../configuration/shared/services/component.service';
import {NavigationStep} from '../../shared/guards/navigationStep-enum';
import {Project, Status} from '../../project/shared/project-model';
import {ProjectService} from '../../project/shared/project.service';
import {ComponentSearchModeEnum, SwitchBoardComponent} from '../../configuration/shared/model/component';
import {VisibilityCheck} from '../../configuration/shared/model/visibilityCheck';
import {ProductsFiltersService} from '../../shared/products-filters/products-filters.service';
import {ComponentType} from '../../configuration/shared/model/component-type';
import {Subject} from 'rxjs/Rx';

@Component({
  selector: 'app-transformer-quick',
  templateUrl: './transformer-quick.component.html',
  styleUrls: ['./transformer-quick.component.less'],
})
export class TransformerQuickComponent implements OnInit, OnDestroy {
  @SessionStorage()
  currentItemct;
  @SessionStorage()
  user;
  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  allComponents: SwitchBoardComponent[] = [];

  @SessionStorage()
  filteredComponents: SwitchBoardComponent[] = [];
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private componentService: ComponentService,
              private itemService: ItemService,
              private messageService: MessageService,
              private projectService: ProjectService,
              private translateService: TranslateService,
              private productsFiltersService: ProductsFiltersService,
              private logger: LoggerService) {
  }

  ngOnInit() {
    this.logger.info('TransformerComponent init()');
    if (this.currentItemct && this.currentItemct.status === Status.ordered && !this.projectService.isReadOnlyProject(this.currentProject, this.user)) {
      // Information Toast Ordered
      if (this.projectService.isCpqProject(this.currentProject)) {
        // Information Toast
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('T_ORDER_WARNING_TITLE'),
          detail: this.translateService.instant('T_PUSH_TO_CPQ_WARNING'),
        });
      } else {
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('T_ORDER_WARNING_TITLE'),
          detail: this.translateService.instant('T_ORDER_WARNING'),
        });
      }
    } else {
      if (this.currentItemct && this.currentItemct.components.length > 0 && this.currentItemct.status === Status.quoted
        && !this.projectService.isReadOnlyProject(this.currentProject, this.user)) {
        // Information Toast Loose data
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('T_LOOSE_DATA_WARNING_TITLE'),
          detail: this.translateService.instant('T_LOOSE_DATA_WARNING'),
        });
      }
    }
    this.itemService.setItemNavigationStep(NavigationStep.TRANSFORMER, this.user, this.unsubscribe$);
    this.componentService.getComponents(this.currentItemct.id, ComponentSearchModeEnum.MAIN)
      .takeUntil(this.unsubscribe$)
      .subscribe(result => {
        this.allComponents = result;
        if (this.currentItemct.range.hideCharacteristicScreen) {
          // No electrical characteristics screen so no pre filter of components
          this.filteredComponents = this.allComponents.filter(comp => comp.type === ComponentType.TRANSFORMER);
        } else {
          // Electrical characteristics screen so application of electrical filter
          const electricalFilters = this.productsFiltersService.extractFiltersFromComponents(this.allComponents,
            this.currentItemct.range.electricalCharacteristicsFilters, true, new Map<string, VisibilityCheck>());
          // Apply previous selection to filters
          this.productsFiltersService.selectElectricalCharacteristicsFilter(this.currentItemct.electricalCharacteristics, electricalFilters);
          // Apply filters
          this.filteredComponents = <SwitchBoardComponent[]>this.productsFiltersService.applyFilters(electricalFilters,
            this.allComponents.filter(comp => comp.type === ComponentType.TRANSFORMER));
        }
      });
  }

  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    // unsubscribe from the subject itself:
    this.unsubscribe$.unsubscribe();
  }
}
