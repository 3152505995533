<div class="ui-grid-col-4">
  <div class="ui-grid ui-grid-responsive ui-grid-pad">
    <div class="ui-grid-row">
      <ul>
        <li class="align-left "><strong translate>{{contactName}}</strong></li>
        <li class="align-left"><span translate>T_SUPPORT_PHONE_NUMBER</span> :
          <span contenteditable="true" class="phone-field"
                (blur)="editFieldBlur(contactType, ContactFieldEnum.PHONE, $event)">{{contact.phone}}</span>
        </li>
        <li class="align-left "><span translate>T_SUPPORT_FAX_NUMBER</span> :
          <span contenteditable="true" class="fax-field"
                (blur)="editFieldBlur(contactType, ContactFieldEnum.FAX, $event)">{{contact.fax}}</span>
        </li>
        <li class="align-left"><span translate>T_SUPPORT_EMAIL</span> :
          <span contenteditable="true" class="email-field"
                (blur)="editFieldBlur(contactType, ContactFieldEnum.EMAIL, $event)">{{contact.email}}</span>
        </li>
      </ul>
    </div>
  </div>
</div>
