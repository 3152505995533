import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectBomPageComponent } from './project-bom-page/project-bom-page.component';
import { BomModule } from '../bom/bom.module';
import { TranslateModule } from '@ngx-translate/core';
import { EcorealCtSharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    BomModule,
    TranslateModule,
    EcorealCtSharedModule
  ],
  declarations: [ProjectBomPageComponent]
})
export class ProjectBomModule {
}
