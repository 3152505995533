<div class="layout-container" id="zoomLayout">
  <div id="layout-components-wrapper">
    <div class="layout-with-arrow">
      <div class="layout-component">
        <div class="arrow-height" *ngIf="showMaxHeight()">
          <div class="height-label">{{currentItemct.height}}</div>
          <div class="height-arrow">
            <div class="point-top"></div>
            <div class="line-height"></div>
            <div class="point-bottom"></div>
          </div>
        </div>
        <div class="global-wrapper">
          <div *ngFor="let displayedComponent of displayedComponents">
            <div class="component-wrapper">
              <div *ngFor="let layout of displayedComponent.dimensions.layout" [ngClass]="{'layout-wrapper': !showMaxHeight()}">
                <app-layout-component-viewer [layout]="layout"
                [height] = "displayedComponent.dimensions.height"></app-layout-component-viewer>
              </div>
            </div>
            <div class="component-name">
              <figcaption class="figcapt centred" [ngClass]="{'rotated': isNeedToRotateLabel()}" translate>{{displayedComponent.name}}</figcaption>
            </div>
          </div>
        </div>
      </div>
      <div class="arrow-width-bottom" *ngIf="showTotalWidth()">
        <div class="arrow-width"  [ngClass]="{'arrow-width-rm6-au': isRm6Au()}">
          <div class="point-left"></div>
          <div class="line-width"></div>
          <div class="point-right"></div>
        </div>
        <div  class="width-label" ><span *ngIf=" isRm6Au()" translate>T_TOTAL_WIDTH</span>{{currentItemct.width}}</div>
      </div>
    </div>
    <div class="contractual-box" translate>
      T_LAYOUT_CONTRACTUAL_MESSAGE
    </div>
  </div>
</div>

