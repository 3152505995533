import {Component, OnInit, ViewChild} from '@angular/core';
import {SessionStorage} from 'ngx-webstorage';
import {Item, Project, ProjectFile, Status} from '../../project/shared/project-model';
import {NavigationLinkEnum} from '../../shared/guards/navigationLink-enum';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {UtilService} from '../../shared/util/util.service';
import {User} from '../../shared/user/user';
import {NavigationRoute} from '../../shared/guards/route-enum';
import {NavigationStep} from '../../shared/guards/navigationStep-enum';
import {ItemService} from '../../project/shared/item.service';
import {TranslateService} from '@ngx-translate/core';
import {ShareOptions} from '../../project/shared/share-option-model';
import {ShareProjectModalComponent} from '../../project/share-project-modal/share-project-modal.component';
import {LoggerService} from 'app/shared/logging/logger.service';
import {ProjectService} from '../../project/shared/project.service';
import {MessageService} from 'primeng/api';
import {UploadService} from '../../admin/shared';
import {FilesService} from '../shared/files.service';
import {isNullOrUndefined} from 'util';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {Subject} from 'rxjs/Rx';
import {Observable} from 'rxjs/Observable';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.less'],
})
export class ProjectDetailsComponent implements OnInit {

  @SessionStorage()
  user: User;

  @SessionStorage()
  isInProjectSharingMode: boolean;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  itemToDelete: Item;

  @SessionStorage()
  currentItemct: Item;

  @ViewChild('shareProjectModal')
  shareProjectModal: ShareProjectModalComponent;
  @ViewChild('childModal')
  childModal: CommonModalComponent;

  loading = false;
  sharedProjects: Array<Project> = [];
  fileListLoading = true;
  fileUploadInProgress = false;
  projectFiles: ProjectFile[];

  @SessionStorage()
  selectedProject: Project;
  deleteItemSubject: Subject<void> = new Subject<void>();
  private IMPORT_OK_RESPONSE = 'FILE_UPLOAD_OK';


  constructor(private utilService: UtilService,
              private itemService: ItemService,
              private projectService: ProjectService,
              private translateService: TranslateService,
              private messageService: MessageService,
              private logger: LoggerService,
              private route: ActivatedRoute,
              private router: Router,
              private uploadService: UploadService,
              private filesService: FilesService) { }

  ngOnInit() {

    this.currentProject = null;
    this.isInProjectSharingMode = true;

    this.route.paramMap.switchMap((params: ParamMap) => this.projectService.getProject(params.get('id')))
      .subscribe((project: Project) => {
        this.currentProject = project;
        this.sharedProjects.push(this.currentProject);
        this.selectedProject = this.sharedProjects[0];
        this.getProjectFiles();

        // Project is an original project : shared project list retrievement
        if (project.originProjectId == null && !project.sharedProject) {
          this.projectService.getSharedProjectsForProject(this.currentProject.id).subscribe(
            projects => this.sharedProjects = this.sharedProjects.concat(projects),
            error => {
              this.logger.error(error);
              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('T_ERROR'),
                detail: this.translateService.instant('T_COLLABORATIVE_SHARE_ERROR_MESSAGE')
              });
            }
          );
        }
        }, error => {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant('T_COLLABORATIVE_CANNOT_ACCESS_PROJECT')
          });
          this.goBackToProject();
        }
      );

  }

  /**
   * Allow to go back to the project page
   */
  goBackToProject() {
    this.router.navigate([NavigationLinkEnum.PROJECTS]);
  }

  /**
   * Allow to go back to the project page
   */
  goBackToSharedProject() {
    this.router.navigate([NavigationLinkEnum.SHARED_PROJECTS]);
  }

  /**
   * Display share project modal
   */
  share(): void {
    this.logger.info('ProjectDetailsComponent share()');
    this.shareProjectModal.show(this.currentProject.id);
  }

  /**
   * Function to share project
   * @param shareOptions sharing options
   */
  shareProject(shareOptions: ShareOptions): void {
    this.logger.info('ProjectDetailsComponent shareProject()');
    this.loading = true;
    this.projectService.shareProject(shareOptions).subscribe(
      project => {
        this.sharedProjects.push(project);
        this.selectedProject = project;
        this.getProjectFiles();
        this.loading = false;
        this.projectService.duplicateSpimProject(project, shareOptions).subscribe(
          spimProject => {
            this.selectedProject.validSpimId = spimProject.validSpimId;
            this.getProjectFiles();
            if (this.selectedProject.validSpimId) {
              this.messageService.add({
                severity: 'success',
                summary: this.translateService.instant('T_INFO'),
                detail: this.translateService.instant('T_CREATE_SUCCESS_PROJECT_SPIM')
              });
            }
          },
          error => {
            this.logger.error(error);
            this.loading = false;
            this.messageService.add({
              severity: 'warn',
              summary: this.translateService.instant('T_WARNING'),
              detail: this.translateService.instant('T_COLLABORATIVE_SOME_FUNCTIONNALITIES_DONT_WORK')
            });
          }
        );

      },
      error => {
        this.logger.error(error);
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_COLLABORATIVE_SHARE_ERROR_MESSAGE')
        });
      },
      () =>
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_COLLABORATIVE_SHARE_SUCCESS_MESSAGE')
        })
    );

  }

  /**
   * To explore the item CT
   * @param {[Project , Item]} tuple
   */
  goToItemCt(tuple: [Project, Item]) {
    this.logger.info('ProjectDetailsComponent goToItemCt()');

    this.currentProject = tuple[0];

    this.itemService.getItem(tuple[1].id).subscribe(
      item => {
        this.currentItemct = item;
        const listNavStep = NavigationRoute.getRouteByRange(this.currentItemct.range);

        if (item.status === Status.ordered || item.status === Status.quoted) {
          // When a "Quoted" or "Ordered" project is re-opened, the app should navigate straight to the BOM page
          this.router.navigate(['/' + NavigationLinkEnum.BILL_OF_MATERIALS]);
        } else {
          this.router.navigate(['/' + listNavStep.find(step =>
            NavigationStep[step.id.valueOf()] === NavigationStep[this.currentItemct.currentNavigationStep.valueOf()]).link]);
        }
      }
    );
  }


  /**
   * To delete the item CT
   * @param {[Project , Item]} tuple
   */
  deleteItem(tuple: [Project, Item]) {
    this.logger.info('ProjectDetailsComponent deleteItem()');

    this.selectedProject = tuple[0];
    this.itemToDelete = tuple[1];
    this.showModal();
  }

  /**
   * To add an item
   * @param {Project} project
   */
  addSwitchboard(project: Project): void {
    this.logger.info('ProjectComponent addSwitchboard()');

    this.currentProject = project;
    this.currentItemct = null;
    this.router.navigate([NavigationLinkEnum.OFFERS_CT]);
  }

  /**
   * Show the confirmation modal
   */
  showModal() {
    this.childModal.description = this.translateService.instant('T_DELETE_ITEM_WARNING_MESSAGE');
    this.childModal.show();
  }

  /**
   * delete item on confirmation
   */
  confirmDialog() {
    this.itemService.deleteItemFromProject(this.selectedProject.id, this.itemToDelete.id).subscribe(
      () => {
        // Loop on all projects
        for (const project of this.sharedProjects) {
          if (project.id === this.selectedProject.id) {
            // Remove the item deleted from the list of project
            project.itemCT = project.itemCT.filter((item) => {
              return item.id !== this.itemToDelete.id;
            });
            this.deleteItemSubject.next();
          }
        }
      });
  }

  /**
   * Method called on shared project change
   * Refresh items carousel
   * @param {Project} newCurrentProject
   */
  changeSharedProjectView(newCurrentProject: Project) {
    if (this.selectedProject.id !== newCurrentProject.id) {
      this.selectedProject = newCurrentProject;
      this.projectService.getProject(this.selectedProject.id).subscribe(project => {
        this.selectedProject = project;
        this.getProjectFiles();
      });
    }
  }
  showShareButton(): boolean {
    return !this.currentProject.sharedProject && this.currentProject.id === this.selectedProject.id;
  }

  isReadOnlyProject(): boolean {
    return this.projectService.isReadOnlyProject(this.currentProject, this.user);
  }

  updateComment(inputComment) {
   this.updateCommentFromEditable(this.selectedProject, inputComment);
  }

  /**
   * Update project comment on a shared project
   * @param {Project} project to be renamed
   * @param {DocumentInformations} documentInformation the be updated with new project name
   * @param {Item} currentItem to acces documentInformation in db
   * @param inputComment the event
   */
  updateCommentFromEditable(project: Project, inputComment) {
    const oldComment = project.shareComment;

    project.shareComment = inputComment.target.innerText.replace(/(\r\n|\r|\n)/gm, '');

    this.projectService.updateComment(project, project.shareComment)
      .subscribe(p => inputComment.target.innerText = p.shareComment,
        () => inputComment.target.innerText = oldComment
      );
  }

  /**
   * Upload file for project SPIM.
   *
   * @param event
   * @param projectId
   * @param fileId if different of null, it is an update
   */
  projectFileUploader(event: any, projectId: string, fileId?: string) {
    this.logger.info('upload project file');
    const files = event.target.files;
    if (files.length > 0) {
      const formData: FormData = new FormData();
      formData.append('fileData', files[0]);
      let uploadUrl = '';
      if (!isNullOrUndefined(fileId)) {
        uploadUrl = '/projects/' + projectId + '/updateFile/' + fileId;
      } else {
        uploadUrl = '/projects/' + projectId + '/uploadFile';
      }
      //Check if we have to create a SPIM project
      this.createSpimProjectIfNeeded().subscribe(canUpload => {
        if (canUpload) {
          this.fileUploadInProgress = true;
          this.uploadService.uploadFile(uploadUrl, formData).subscribe((importResponse) => {
              if (importResponse.includes(this.IMPORT_OK_RESPONSE)) {
                this.messageService.add({
                  severity: 'success',
                  summary: this.translateService.instant('T_SPIM_IMPORT_SUCCESS_TITLE'),
                  detail: this.translateService.instant('T_SPIM_IMPORT_SUCCESS_MESSAGE')
                });
                // refresh list of the files
                this.getProjectFiles();
              } else {
                this.displayFileUploadSpimError(importResponse);
              }
              this.fileUploadInProgress = false;
            },
            () => {
              this.displayFileUploadSpimError();
              this.fileUploadInProgress = false;
            });
        }
      });
    }
    // clean files in event
    event.target.value = null;
  }

  showFiles(): boolean{
    // If it is an original project or Spim project exists
    return !this.selectedProject?.originProjectId || this.selectedProject.validSpimId;
  }

  /**
   * Call back end to try to create a SPIM project
   * @private
   * @return Promise with flag to inform if file can be upload
   */
  private createSpimProjectIfNeeded (): Observable<boolean> {
    return new Observable(subscriber => {
      if (!this.currentProject.validSpimId) {
        this.projectService.needToCreateSpimProjectId(this.currentProject.id).subscribe(callbackProject => {
            if (callbackProject.validSpimId) {
              this.messageService.add({
                severity: 'success',
                summary: this.translateService.instant('T_INFO'),
                detail: this.translateService.instant('T_CREATE_SUCCESS_PROJECT_SPIM')
              });
              this.currentProject = callbackProject;
              this.selectedProject = callbackProject;
              subscriber.next(true);
            } else {
              this.messageService.add({
                severity: 'warn',
                summary: this.translateService.instant('T_WARNING'),
                detail: this.translateService.instant('T_COLLABORATIVE_SOME_FUNCTIONNALITIES_DONT_WORK')
              });
              subscriber.next(false);
            }
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('T_ERROR'),
              detail: this.translateService.instant('T_COLLABORATIVE_SOME_FUNCTIONNALITIES_DONT_WORK')
            });
            subscriber.next(false);
          },
          () => subscriber.complete());
      } else {
        // case where Spim project already exist
        subscriber.next(true);
      }
    });
  }

  private displayFileUploadSpimError(responseMessage?: string) {
    let spimError = 'T_SPIM_IMPORT_ERROR_MESSAGE';
    if (responseMessage) {
      spimError = 'T_' + responseMessage;
    }
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_SPIM_IMPORT_ERROR_TITLE'),
      detail: this.translateService.instant(spimError)
    });
  }

  /**
   * Get files for project.
   */
  private getProjectFiles() {
    this.projectFiles = [];
    this.fileListLoading = true;

    this.filesService.getProjectFiles(this.selectedProject.id).subscribe(
      files => {
        files.forEach(file => {
          this.projectFiles.push(file);
        });
        if (!isNullOrUndefined(files) && files.length > 0) {
          // If files are not null, we copy the array to lunch ngonchange in children component
          this.projectFiles = this.projectFiles.slice();
        }
        this.fileListLoading = false;
      },
      () => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_SPIM_GET_FILES_ERROR_TITLE'),
          detail: this.translateService.instant('T_SPIM_GET_FILES_ERROR_MESSAGE')
        });
        this.fileUploadInProgress = false;
      });
  }
}
