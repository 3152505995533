<div class="configuration-margin">

  <!--Stepper-->
  <div class="row">
    <div  class="col-xs-3 col-sm-3">
      <h2>
        <span translate>T_OFFER_DATA_MANAGEMENT_CONFIGURATION_TITLE </span>
        <span translate>{{selectedRange.nameKey}}</span>
      </h2>
    </div>
    <div  class="col-xs-3 col-sm-3">
      <div class="information-box" *ngIf="helpMessage" translate>{{helpMessage}}</div>
    </div>
    <div  class="col-xs-6 col-sm-6 stepper-style">
      <app-odm-add-offer-stepper [currentStep]="currentStep"
                                                   [stepList]="stepList">
      </app-odm-add-offer-stepper>
    </div>
  </div>
  <!--Nav button-->
  <div>
    <app-odm-add-offer-nav-button [activePrevious]="activePrevious"
                                                    [activeNext]="activeNext"
                                                    [hideNext]="hideNext"
                                                    [modificationPending]="isModificationPending"
                                                    (previousEvent)="loadPreviousStep()"
                                                    (nextEvent)="loadNextStep($event)">
    </app-odm-add-offer-nav-button>
  </div>

  <!--Configuration steps: characteristic, filter, main characteristic, document, reference, publication-->
  <div *ngIf="!isEmptyCharacteristicsMapOnFilterElectricalOrMainCharacteristics(bslCharacteristicsMap)" [ngSwitch]="currentStep">

    <!-- RANGE ELECTRICAL CHARACTERISTICS -->
    <app-odm-add-offer-characteristics *ngSwitchCase="stepEnum.CHARACTERISTIC"
                                                         [bslCharacteristicsMap]="bslCharacteristicsMap"
                                                         [selectedRange]="selectedRange"
                                                         (nextActiveChange)="updateNextButtonState()">
    </app-odm-add-offer-characteristics>

    <!-- RANGE FILTERS -->
    <app-odm-add-offer-filters *ngSwitchCase="stepEnum.FILTER"
                                                 [bslCharacteristicsMap]="bslCharacteristicsMap"
                                                 [selectedRange]="selectedRange"
                                                 (nextActiveChange)="updateNextButtonState()">
    </app-odm-add-offer-filters>

    <!-- RANGE MAIN CHARACTERISTICS -->
    <app-odm-add-offer-main-characteristics *ngSwitchCase="stepEnum.MAIN_CHARACTERISTIC"
                                                              [bslCharacteristicsMap]="bslCharacteristicsMap"
                                                              [selectedRange]="selectedRange"
                                                              (nextActiveChange)="updateNextButtonState()">
    </app-odm-add-offer-main-characteristics>

    <!-- RANGE DOCUMENTS -->
    <app-odm-add-offer-documents *ngSwitchCase="stepEnum.DOCUMENT" [selectedRange]="selectedRange">
    </app-odm-add-offer-documents>

    <!-- RANGE REFERENCES -->
    <app-odm-add-offer-references *ngSwitchCase="stepEnum.REFERENCE"
                                                    [selectedRange]="selectedRange"
                                                    (rangeHasBeenUpdated)="updateCurrentRange($event)"
                                                    (rangeHasReference)="updateNextButton($event)"
                                                    (rangeHasAccessory)="getSelectedRange()">
    </app-odm-add-offer-references>

    <!-- RANGE PUBLICATION -->
    <app-odm-add-offer-publication *ngSwitchCase="stepEnum.PUBLICATION"
                                                     [selectedRange]="selectedRange"
                                                     (rangeIsPublished)="updateCurrentRange($event)">
    </app-odm-add-offer-publication>
  </div>

  <!-- Spinner -->
  <app-spinner *ngIf="bslCharacteristicsLoading && isCharacteristicStep()" [sizeClass]="'large'"><span class="waiting-message" translate>T_OFFER_DATA_MANAGEMENT_LOADING_TIME</span></app-spinner>

  <!--Message error-->
  <div *ngIf="bslCharacteristics && bslCharacteristics.length === 0" class="message-center"><label translate>T_OFFER_DATA_MANAGEMENT_NO_REFERENCES</label></div>

</div>
