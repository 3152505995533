import {FilterValue} from './FilterValue';

export class FilterCategory {
  key: string;
  values: Array<FilterValue>;
  measurementUnit: string;
  category: string;
  isVisible: () => boolean;
  selected: boolean;
  rangeName: string;

  constructor(key: string, values: Array<FilterValue>, measurementUnit: string, category: string,  isVisibleCallback?: () => boolean, rangeName?: string) {
    this.key = key;
    this.values = values;
    this.measurementUnit = measurementUnit;
    this.category = category;
    this.selected = false;
    this.isVisible = isVisibleCallback ? isVisibleCallback : () => true;
    this.rangeName = rangeName;
  }
}
