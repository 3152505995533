import {Role} from '../enum';
import {Permission} from '../permission';

export const schneider: Array<Permission> = [
  {
    role: Role.FRONT_OFFICE,
    inherits: [Role.NATIONAL_SALES]
  },
  {
    role: Role.NATIONAL_SALES,
    inherits: [Role.RESELLER]
  },
  {
    role: Role.COM_TOOL_MANAGEMENT,
    inherits: [Role.COM_TOOL_MANAGEMENT]
  }
];
