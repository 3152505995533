/* Angular modules */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
import {LoggerService} from '../logging/logger.service';
/* app modules */
import {Item} from '../../project/shared/project-model';
import {ProjectPageComponent} from '../../project/project-page.component';
import {OffersPageComponent} from '../../offers/offers-page/offers-page.component';
import {NavigationStep} from './navigationStep-enum';
import {ConfigureParentComponent} from '../../configuration/configure-parent/configure-parent.component';
import {DocumentationComponent} from '../../export';
import {NavigationLinkEnum} from './navigationLink-enum';
import {TransformerSelectorComponent} from '../../transformer/transformer-selector/transformer-selector.component';
import {ProjectDetailsComponent} from '../../project-sharing/project-details/project-details.component';
import {BomPageComponent} from '../../bom';
import {CharacteristicsPageComponent} from '../../characteristics/characteristics-page/characteristics-page.component';
import {ServicesCatalogPageComponent} from '../../services-catalog/services-catalog-page/services-catalog-page.component';
import {PackageSelectorComponent} from '../../package-offer/package-selector/package-selector.component';


@Injectable()
export class NavigationGuardService implements CanActivate {

  @SessionStorage()
  currentItemct: Item;

  constructor(private router: Router, private logger: LoggerService) {
  }

  static getNavigationStep(next: ActivatedRouteSnapshot) {
    let step = 0;
    switch (next.component) {
      case ProjectPageComponent:
        step = NavigationStep.PROJECTS;
        break;
      case OffersPageComponent:
        step = NavigationStep.OFFERS_CT;
        break;
      case CharacteristicsPageComponent:
        step = NavigationStep.CHARACTERISTICS;
        break;
      case ConfigureParentComponent:
        step = NavigationStep.CONFIGURATION;
        break;
      case TransformerSelectorComponent:
        step = NavigationStep.TRANSFORMER_SELECTOR;
        break;
      case ServicesCatalogPageComponent:
        step = NavigationStep.SERVICES_CATALOG;
        break;
      case BomPageComponent:
        step = NavigationStep.BILL_OF_MATERIALS;
        break;
      case DocumentationComponent:
        step = NavigationStep.EXPORT;
        break;
      case ProjectDetailsComponent:
        step = NavigationStep.PROJECT_SHARING;
        break;
      case PackageSelectorComponent:
        step = NavigationStep.PACKAGE_OFFER;
        break;
      default:
        step = NavigationStep.WELCOME;
    }
    return step;
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const nextStep = NavigationGuardService.getNavigationStep(next);
    if (nextStep === NavigationStep.PROJECT_SHARING) {
      return true;
    }
    if (this.currentItemct && this.currentItemct.maxNavigationStep >= nextStep) {
      return true;
    }
    this.logger.trace('NavigationGuardService blocks navigation to : /' + next.url);
    this.router.navigate([NavigationLinkEnum.PROJECTS]);
    return false;
  }

}
