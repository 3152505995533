/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
import {TranslateService} from '@ngx-translate/core';
/* 3rd parties libraries */
import * as _ from 'lodash';
/* app modules*/
import {Item, ProjectTotalPrice, TotalPriceEnum} from '../../project/shared/project-model';
import {BOMFormatedForExcel, ReferenceBom, ReferenceBomForDigiQ, ReferenceBomForMySE} from './bom-model';
import {ReferenceToOrder, SwitchBoardComponent} from '../../configuration/shared/model/component';
import {CsvHelperService} from './csv-helper.service';
import {LoggerService} from '../../shared/logging/logger.service';
import {SimplifiedRange} from '../../shared/model/simplifiedRange-model';
import {DeliveryPolicy} from '../../shared/model/deliveryPolicy';
import {Reference} from '../../configuration/shared/model/reference';
import {IProduct} from '../../configuration/shared/model/IProduct';
import {BomHelper} from './bom.helper';
import {User} from '../../shared/user/user';
import {isNullOrUndefined} from 'util';
import {ComponentType} from '../../configuration/shared/model/component-type';
import {MySEStatusEnum} from '../../shared/model/mySEStatusEnum';
import {UtilService} from '../../shared/util/util.service';
import {RightsService} from '../../shared/rights/rights.service';
import {Range} from '../../shared/model/range-model';

@Injectable()
export class BomService {

  @SessionStorage()
  localization;

  @SessionStorage()
  isDemoMode: boolean;

  readonly MAX_VALIDITY_PERIOD = 1000;
  readonly DEFAULT_VALIDITY_PERIOD = 30;
  readonly MAX_DISCOUNT_ALLOWED = 100;
  readonly MIN_DELIVERY_POLICY_MINIMUM_TIME = 0;
  readonly MIN_DELIVERY_POLICY_ADDITIONAL_TIME = 0;

  constructor(private httpClient: HttpClient,
              private translateService: TranslateService,
              private logger: LoggerService,
              private bomHelper: BomHelper,
              private utilService: UtilService,
              private csvHelperService: CsvHelperService,
              private rightsService: RightsService) {
  }

  /**
   *
   * @param {Item[]} items
   * @returns {MySEStatusEnum}
   */
  public static getNetPriceStatusForProject(items: Item[]): MySEStatusEnum {
    let projectStatus = MySEStatusEnum.OK;

    items.forEach((item) => {
      if (item.mySeNetPriceStatus !== MySEStatusEnum.OK && item.mySeNetPriceStatus !== MySEStatusEnum.NOT_APPLICABLE) {
        projectStatus = item.mySeNetPriceStatus;
      }
    });
    return projectStatus;
  }

  /**
   * Allow to create a row of the bom
   * @param {TranslateService} translateService
   * @param {IProduct} component
   * @param discount
   * @param {SimplifiedRange} range
   * @param item
   * @param ranges
   * @returns {ReferenceBom}
   */
  public static convertComponentToReferenceBom(translateService: TranslateService,
                                               component: IProduct,
                                               discount,
                                               item: Item): ReferenceBom {
    const ref = new ReferenceBom();
    const discountUnitPrice = UtilService.applyDiscountPrice(component.reference.price, discount);
    ref.id = component.id;
    ref.quantityId = component.quantityId;
    ref.ref = component.reference.ref;
    ref.description = component.reference.description ? translateService.instant(component.reference.description) : '';
    ref.value = component.name ? translateService.instant(component.name) : '';
    ref.price = component.reference.price;
    ref.netPrice = component.reference.netPrice;
    ref.count = component.quantity ? component.quantity : 1;
    ref.partnerDiscount = this.formatPartnerDiscount(component.reference.partnerDiscount);
    ref.discountedPrice = discountUnitPrice * ref.count;
    ref.discountedNetPrice = UtilService.applyDiscountPrice(ref.netPrice * ref.count, discount);
    ref.discountUnitPrice = discountUnitPrice;
    ref.availableOnStock = this.getAvailabilityTranslateValue(translateService, component.reference.availability);
    let deliveryPolicy: DeliveryPolicy;
    if (!!item && !!item.range)
      deliveryPolicy = item.range.deliveryPolicy;
    else if (!!item && !!item.deliveryPolicy) {
      deliveryPolicy = item.deliveryPolicy;
    } else {
      deliveryPolicy = null;
    }
    ref.deliveryTime = UtilService.calculateDeliveryTime(component.reference.deliveryTime, deliveryPolicy);
    ref.totalPrice = component.reference.price !== null ? component.reference.price * ref.count : null;
    ref.totalNetPrice = component.reference.netPrice !== null ? component.reference.netPrice * ref.count : null;
    ref.mySeNetPriceStatus = component.reference.mySeNetPriceStatus;
    ref.editableQuantity = component.reference.editableQuantity;
    ref.businessStatus = component.reference.status;
    return ref;
  }

  public static convertReferenceToReferenceBom(translateService: TranslateService,
                                               reference: Reference,
                                               itemValue, discount,
                                               range: SimplifiedRange,
                                               quantity: number): ReferenceBom {
    const ref = new ReferenceBom();

    const discountUnitPrice = UtilService.applyDiscountPrice(reference.price, discount);
    ref.id = itemValue.id;
    ref.ref = reference.ref;
    ref.count = quantity ? quantity : 1;
    ref.description = reference.description ? translateService.instant(reference.description) : '';
    ref.value = itemValue.value ? translateService.instant(itemValue.value) : '';
    ref.price = reference.price;
    ref.netPrice = reference.netPrice;
    ref.partnerDiscount = this.formatPartnerDiscount(reference.partnerDiscount);
    ref.discountUnitPrice = discountUnitPrice;
    ref.discountedNetPrice = UtilService.applyDiscountPrice(ref.netPrice * ref.count, discount);
    ref.discountedPrice = discountUnitPrice * ref.count;
    ref.availableOnStock = this.getAvailabilityTranslateValue(translateService, reference.availability);
    // We consider that a delivery time set to 0 is to identify undeliverable service/option/accessories
    if (reference.deliveryTime === null) {
      ref.deliveryTime = null;
    } else if (reference.deliveryTime === 0) {
      ref.deliveryTime = reference.deliveryTime
    } else {
      ref.deliveryTime = UtilService.calculateDeliveryTime(reference.deliveryTime, !!range ? range.deliveryPolicy : null);
    }
    ref.totalPrice = reference.price !== null ? reference.price * ref.count : null;
    ref.totalNetPrice = reference.netPrice !== null ? reference.netPrice * ref.count : null;
    ref.mySeNetPriceStatus = reference.mySeNetPriceStatus;
    ref.editableQuantity = reference.editableQuantity;
    ref.businessStatus = reference.status;
    return ref;
  }

  /**
   * Check if it is necessary to display availability on stock column
   */
  static displayAvailability(items: Item[]): boolean {

    // Find on items List to find if any item need to display availability
    return items.some(item => {
      let find;
      // Check components
      find = item.components.some(sw => {
        if (sw.reference !== null && sw.reference.availability !== null) {
          return true;
        }
        // Check options of switchboard
        return sw.options.some(opt => {
          return opt.values.some(val => {
            return val.selected && val.reference !== null && val.reference.availability !== null;
          });   // opt.values
        });     // opt
      });       // component

      // Check accessories
      find = find || item.accessories.some(acc => {
        return acc.reference !== null && acc.reference.availability !== null;
      });

      // Check Packing
      find = find || item.optionalPacking.some(packing => {
        return packing.values.some(val => {
          return val.reference !== null && val.reference.availability !== null;
        });
      });
      return find;
    });
  }

  /**
   * Convert availability on translation
   * @param translateService
   * @param availability
   * @return translation of availability value
   */
  private static getAvailabilityTranslateValue(translateService: TranslateService, availability: string): string {
    switch (availability) {
      case 'Yes' :
        return translateService.instant('T_AVAILABILITY_VALUE_YES');
      case 'No' :
        return translateService.instant('T_AVAILABILITY_VALUE_NO');
      default :
        return '';
    }
  }

  /**
   * Allow to generate an Excel file with one item of with all items of a project
   * @param {string} projectId id of the current project
   * @param {Item[]} items list of the items to be displayed on Excel
   * @param {boolean} isEmailAttachment Attach the file to the mail
   * @param {User} user current user
   * @param {boolean} isCpqProject flag to know if it is a CPQ project
   * @param {boolean} forProjectBom excel is for the current project
   * @returns {File} an Excel file
   */
  public exportBom(projectId: string, items: Item[], isEmailAttachment: boolean, user: User, isCpqProject?: boolean, forProjectBom?: boolean): File {
    this.logger.info('BomPageComponent exportBom()');
    if (items && items.length > 0) {
      this.logger.business('BOM Download', {range: (!!items[0].range ? items[0].range.nameKey : 'T_PACKAGE_OFFER_NAME')});
    }

    // the current user has a MySE account and one of references of the BOM doesn't have price
    const hasPrices = UtilService.itemsHaveValidPrices(items);
    // get the columns headers depending if the excel file is for current item or for all project
    const headers = forProjectBom ? this.bomHelper.getExportProjectBOMColumns(this.isDemoMode, hasPrices , this.rightsService.canApplyDiscount(),
      !isNullOrUndefined(user.currentMySEAccount) || items.some(item => item.totalPrice.validFoNetPrice),
      items.some(item => item.totalPrice.validFoNetPrice), this.localization, BomService.displayAvailability(items)) :
      this.bomHelper.getExportBOMColumns(this.isDemoMode, hasPrices && !isCpqProject, this.rightsService.canApplyDiscount(), !isNullOrUndefined(user.currentMySEAccount),
        items.some(item => item.totalPrice.validFoNetPrice), this.localization, BomService.displayAvailability(items));

    // Get all references including on the current item or current project
    const xlsItems = _.cloneDeep(items);
    const allReferences: BOMFormatedForExcel[] =
      this.adaptReferenceBomToExcelFormat(headers, this.extractProjectReferencesToArray(xlsItems, forProjectBom), hasPrices);

    // remove partner discount column when all partner discounts are 0%
    if(headers.partnerDiscount){
      let hasPartnerDiscount = false;
      allReferences.slice(0).forEach(ref => {
        if(ref.partnerDiscount !== "0.00%"){
          hasPartnerDiscount = true;
        }
      });
      if(!hasPartnerDiscount){
        delete headers.partnerDiscount;
        delete headers.unitPrice;
        allReferences.forEach(ref => {
          delete ref.partnerDiscount;
          delete ref.unitPrice;
        });
      }
    }

    if (!isEmailAttachment) {
      this.csvHelperService.exportXlsFile(projectId, xlsItems, headers, allReferences,
        this.translateService.instant('T_BOM_XSLX'), 'QUOTE', true);
    } else {
      return this.csvHelperService.attachXlsFileToMail(headers, allReferences,
        this.translateService.instant('T_BOM_XSLX'), true);
    }
    return null;
  }

  public exportMySEBom(projectId: string, item: Item): File {
    this.logger.info('BomPageComponent exportMySEBom()');
    this.logger.business('MySEBOM Download', {range: (!!item.range ? item.range.nameKey : 'T_PACKAGE_OFFER_NAME')});
    const headers = {
      ref: this.translateService.instant('T_BOM_REFERENCE'),
      count: this.translateService.instant('T_BOM_QUANTITY')
    };
    this.csvHelperService.exportXlsFile(projectId, [item], headers,
      this.convertReferenceBOMToReferenceBomForMySE(this.extractReferencesToArray(item)),
      this.translateService.instant('T_DOC_BOM_MYSE_XSLX'), 'QUOTEMYSE', false);
    return null;
  }

  /**
   * Export Bom for DigiQ country
   * @param {string} projectId
   * @param {Item} item
   * @returns {xlsx File}
   */
  public exportDigiQBom(projectId: string, item: Item): File {
    this.logger.info('BomPageComponent exportDigiQBom()');
    this.logger.business('DigiQBOM Download', {range: (!!item.range ? item.range.nameKey : 'T_PACKAGE_OFFER_NAME')});
    const headers = {
      ref: this.translateService.instant('T_BOM_REFERENCE'),
      count: this.translateService.instant('T_BOM_QUANTITY'),
      discount: this.translateService.instant('T_BOM_DISCOUNT')
    };
    this.csvHelperService.exportXlsFile(projectId, [item], headers,
      this.convertReferenceBOMToReferenceBomForDigiQ(this.extractReferencesToArray(item)),
      this.translateService.instant('T_DOC_BOM_DIGIQ_CSV'), 'QUOTEDIGIQ');
    return null;
  }

  /**
   * Export all references to order as file attach to mail
   * @param referencesToOrder
   */
  public exportReferencesForOrdering(referencesToOrder: ReferenceToOrder[]): File {
    const referencesToExport: ReferenceToOrder[] = [];
    referencesToOrder.forEach(referenceToOrder => referencesToExport.push(referenceToOrder));
    const headers = {
      reference: this.translateService.instant('T_BOM_ORDER_REFERENCE_MODAL_REFERENCE_COLUMN'),
      offer: this.translateService.instant('T_BOM_ORDER_REFERENCE_MODAL_OFFER_COLUMN'),
      quantity: this.translateService.instant('T_BOM_ORDER_REFERENCE_MODAL_QUANTITY_COLUMN'),
      price: this.translateService.instant('T_BOM_ORDER_REFERENCE_MODAL_PRICE_COLUMN', {currency: this.localization.displayedCurrency})
    };
    return this.csvHelperService.attachXlsFileToMail(headers, referencesToExport,
      this.translateService.instant('T_BOM_ORDER_REFERENCES_FILE_XSLX'), true);
  }

  /**
   * Creates a row of the excel table or BOM
   * @param itemValue the component cubicle, transformer, accessory...
   * @param discount the applied discount
   * @param {DeliveryPolicy} deliveryPolicy the rules of the delivery
   * @returns {ReferenceBom} a reference on BOM
   */
  public extractReference(itemValue, discount, deliveryPolicy: DeliveryPolicy): ReferenceBom {
    this.logger.info('BomPageComponent extractReference()');

    const extractedRef = itemValue;
    if (extractedRef.referenceBom && extractedRef.referenceBom.description) {
      extractedRef.referenceBom.description = this.translateService.instant(extractedRef.referenceBom.description);
    }
    extractedRef.id = itemValue.id;
    extractedRef.value = extractedRef.value ? extractedRef.value : extractedRef.referenceBom.value;

    const translatedValue = extractedRef.value !== '' ? this.translateService.instant(extractedRef.value) : extractedRef.value;
    const discountUnitPrice = UtilService.applyDiscountPrice(itemValue.referenceBom.price, discount);
    const totalPriceValue = UtilService.applyDiscountPrice(itemValue.referenceBom.totalPrice, discount);
    const availableOnStock = itemValue.referenceBom.availableOnStock;
    const deliveryTime = UtilService.calculateDeliveryTime(itemValue.referenceBom.deliveryTime, deliveryPolicy);

    _.merge(extractedRef.referenceBom, {
      value: translatedValue,
      discount: discount,
      discountedPrice: totalPriceValue,
      discountUnitPrice: discountUnitPrice,
      availableOnStock: availableOnStock,
      deliveryTime: deliveryTime
    });
    return extractedRef.referenceBom;
  }

  public getBom(itemId): Observable<Item> {
    this.logger.info('BomService getBom()');
    return this.httpClient.get<Item>('/items/' + itemId + '/updateBom');
  }

  /**
   * creates an array with all references of one item or of all project
   * @param {Item[]} items list of items (current item or all item of a project)
   * @param exportProject if we are in the project bom use case
   * @returns {ReferenceBom[]} array of all references
   */
  public extractProjectReferencesToArray(items: Item[], exportProject = false): ReferenceBom[] {
    let references = [];
    items.forEach((item) => {
      references = references.concat(this.extractReferencesToArray(item, exportProject));
    });
    return references;
  }

  public extractReferencesToArray(item: Item, exportProject = false): ReferenceBom[] {
    this.logger.info('BomPageComponent extractReferencesToArray()');
    const references: ReferenceBom[] = [];

    let componentIndex = 1;
    item.components.forEach(component => {
      this.extractComponentReferencesToArray(references, component, componentIndex, item, exportProject);
      componentIndex++;
    });

    item.accessories.forEach(
      accessory => {
        if (accessory.reference) {
          accessory.referenceBom = BomService.convertReferenceToReferenceBom(this.translateService,
            accessory.reference, accessory, item.discount, item.range, accessory.quantity);
          let accessoryRef: ReferenceBom = this.extractReferenceToArray(accessory, item.discount, item.deliveryPolicy);
          if (exportProject) {
            accessoryRef = this.addInfosToReferenceBom(accessoryRef, item, ComponentType.ACCESSORY);
          }
          references.push(accessoryRef);
        }
      });

    // Service Catalog
    item.selectedServices.forEach(service => {
      service.referenceBom = BomService.convertReferenceToReferenceBom(this.translateService, service.reference, service, item.discount, item.range, service.quantity);
      let serviceRef: ReferenceBom = this.extractReferenceToArray(service, item.discount, item.deliveryPolicy);
      if (exportProject) {
        serviceRef = this.addInfosToReferenceBom(serviceRef, item, ComponentType.SERVICE);
      }
      references.push(serviceRef);
    });
    return references;
  }

  /**
   * Determine the myse status and calculate the total price.
   * if net price is not available an error message is returned
   * @param items all items of the project or list of items having same status
   * @param applyDiscount if it necessary to apply discount
   */
  public getProjectPrice(items: Array<Item>, applyDiscount = true): ProjectTotalPrice {
    if (!this.rightsService.hasMyseAccount()) {
      return this.countTotalPriceForItems(items,
        items.filter(item => item.totalPrice != null).every(item => !item.totalPrice.validFoNetPrice) ? TotalPriceEnum.foPublic : TotalPriceEnum.foNet, applyDiscount);
    }
    const itemsAreValidMySeNetPrice = BomService.getNetPriceStatusForProject(items);
    if (MySEStatusEnum.OK === itemsAreValidMySeNetPrice) {
      return this.countTotalPriceForItems(items, TotalPriceEnum.mySeNet, applyDiscount);
    }
    const errorMessage = this.utilService.getTooltipOnTotalMySEMessage(itemsAreValidMySeNetPrice);
    return new ProjectTotalPrice(0, TotalPriceEnum.mySeNet, errorMessage);
  }

  /**
   * Count total price for project depending on the MySE status.
   *
   * @param items
   * @param priceType
   * @param applyDiscount
   */
  public countTotalPriceForItems(items: Array<Item>, priceType: TotalPriceEnum, applyDiscount = false): ProjectTotalPrice {
    let price = 0;
    items.forEach((item: Item) => {
      if (item.totalPrice) {
        switch (priceType) {
          case TotalPriceEnum.mySeNet:
            price += applyDiscount ? item.totalPrice.mySENetPrice * (1 - item.discount / 100) : item.totalPrice.mySENetPrice;
            break;
          case TotalPriceEnum.foPublic:
            price += applyDiscount ?  item.totalPrice.foPublicPrice * (1 - item.discount / 100) : item.totalPrice.foPublicPrice;
            break;
          case TotalPriceEnum.foNet:
            const itemPrice = item.totalPrice.validFoNetPrice ? item.totalPrice.foNetPrice : item.totalPrice.foPublicPrice;
            price += applyDiscount ?  itemPrice * (1 - item.discount / 100) : itemPrice;
            break;
        }
      }
    });
    return new ProjectTotalPrice(price, priceType);
  }

  /**
   * Count total net price for project
   *
   * @param items
   */
  public countTotalNetPriceForItems(items: Array<Item>): number {
    let price = 0;
    items.forEach((item: Item) => {
      price += item.bom.totalNetPrice;
    });
    return price;
  }

  /**
   * Count total price for item depending on the MySE status.
   * (Use countTotalPriceForItems) method
   * @param item
   * @param applyDiscount (optional) false if not exist
   */
  public countTotalPriceForItem(item: Item, applyDiscount = false): number {
    const items = [];
    items.push(item);
    let priceType: TotalPriceEnum = TotalPriceEnum.foPublic;
    if (MySEStatusEnum.OK === item.mySeNetPriceStatus) {
      priceType = TotalPriceEnum.mySeNet;
    } else if (item.totalPrice && item.totalPrice.validFoNetPrice) {
      priceType = TotalPriceEnum.foNet;
    }
    const itemTotalPrice = this.countTotalPriceForItems(items, priceType, applyDiscount);
    return itemTotalPrice !== null ? itemTotalPrice.price : 0;
  }

  /**
   * determine the mesasge if all items have not valid net prices
   * @param {Item[]} items
   * @returns {string} message if all prices are not available
   */
  public  getMessageItemsWithValidNetPrices(items: Item[]): string {
    const status = BomService.getNetPriceStatusForProject(items);

    if (status !== MySEStatusEnum.OK && status !== MySEStatusEnum.NOT_APPLICABLE) {
      return this.utilService.getTooltipOnTotalMySEMessage(status);
    }
    return '';
  }

  /**
   * Get validity period of item (search in all ranges of switchboards/transformers to get the minimum value if it is package offer)
   * @param item itemCT
   * @param ranges all available ranges
   */
  public getValidityPeriod(item: Item, ranges: Range[]): number {
    if (!!item.range) {
      return item.range.priceManagement.validityPeriod;
    }

    let minValidityPeriod = this.MAX_VALIDITY_PERIOD;

    for (const component of item.components) {
      const range = ranges.find(r => r.id === component.rangeId[0]);
      if (range && minValidityPeriod > range.priceManagement.validityPeriod) {
        minValidityPeriod = range.priceManagement.validityPeriod;
      }
    }
    for (const accessory of item.accessories){
      const range = ranges.find(r => r.accessoriesCharacteristics.includes(accessory.id));
      if (range && minValidityPeriod > range.priceManagement.validityPeriod) {
        minValidityPeriod = range.priceManagement.validityPeriod;
      }
    }

    if (minValidityPeriod === this.MAX_VALIDITY_PERIOD) {
      minValidityPeriod = this.DEFAULT_VALIDITY_PERIOD;
    }
    return minValidityPeriod;
  }

  /**
   * Get discount allowed of item (search in all ranges of switchboards/transformers to get the minimum value if it is package offer)
   * @param item itemCT
   * @param ranges all available ranges
   */
  public getDiscountAllowed(item: Item, ranges: Range[]): number {
    if (!!item.range) {
      // if discountAllowed is 0, discountAllowed by default is 100
      return item.range.priceManagement.discountAllowed > 0 ? item.range.priceManagement.discountAllowed : 100;
    }

    let minDiscountAllowed = this.MAX_DISCOUNT_ALLOWED;

    for (const component of item.components) {
      const range = ranges.find(r => r.id.toString() === component.rangeId[0]);
      if (range && range.priceManagement.discountAllowed > 0 && minDiscountAllowed > range.priceManagement.discountAllowed) {
        minDiscountAllowed = range.priceManagement.discountAllowed;
      }
    }

    return minDiscountAllowed;
  }

  /**
   * Get deliveryPolicy.minimumTime of item (search in all ranges of switchboards/transformers to get the maximum value if it is package offer)
   * @param item itemCT
   * @param ranges all available ranges
   */
  public getDeliveryPolicyMinimumTime(item: Item, ranges: Range[]): number {
    if (!!item.range && !!item.range.deliveryPolicy) {
      return item.range.deliveryPolicy.minimumTime;
    }

    let maxDeliveryPolicyMinimumTime = this.MIN_DELIVERY_POLICY_MINIMUM_TIME;

    for (const component of item.components) {
      const range = ranges.find(r => r.id.toString() === component.rangeId[0]);
      if (range && range.deliveryPolicy && maxDeliveryPolicyMinimumTime < range.deliveryPolicy.minimumTime) {
        maxDeliveryPolicyMinimumTime = range.deliveryPolicy.minimumTime;
      }
    }

    return maxDeliveryPolicyMinimumTime;
  }

  /**
   * Get deliveryPolicy.additionalTime of item (search in all ranges of switchboards/transformers to get the maximum value if it is package offer)
   * @param item itemCT
   * @param ranges all available ranges
   */
  public getDeliveryPolicyAdditionalTime(item: Item, ranges: Range[]): number {
    if (!!item.range && !!item.range.deliveryPolicy) {
      return item.range.deliveryPolicy.additionalTime;
    }

    let maxDeliveryPolicyAdditionalTime = this.MIN_DELIVERY_POLICY_ADDITIONAL_TIME;

    for (const component of item.components) {
      const range = ranges.find(r => r.id.toString() === component.rangeId[0]);
      if (range && range.deliveryPolicy && maxDeliveryPolicyAdditionalTime < range.deliveryPolicy.additionalTime) {
        maxDeliveryPolicyAdditionalTime = range.deliveryPolicy.additionalTime;
      }
    }

    return maxDeliveryPolicyAdditionalTime;
  }

  private extractReferenceToArray(itemValue, discount, deliveryPolicy: DeliveryPolicy): ReferenceBom {
    if (itemValue.referenceBom) {
      return this.extractReference(itemValue, discount, deliveryPolicy);
    }
  }

  /**
   * Convert an item (cubicle or switchboard) represented as a Javascript Object to an array
   * @param {ReferenceBom[]} references an array of references of the BOM
   * @param component one component of the current item
   * @param index
   * @param {Item} item current configured item
   * @param extractForAllProject
   */
  private extractComponentReferencesToArray(references: ReferenceBom[], component: SwitchBoardComponent, index, item: Item, extractForAllProject = false) {
    let componentRef: ReferenceBom = BomService.convertComponentToReferenceBom(this.translateService, component,
      item.discount, item);

    if (extractForAllProject) {
      const compCategory = index + '-' + component.reference.ref;
      componentRef = this.addInfosToReferenceBom(componentRef, item, compCategory);
    }
    references.push(componentRef);

    const componentQuantity = component.quantity ? component.quantity : 1;
    component.options.forEach(option => {
      option.values.forEach(
        optValue => {
          let quantity: number;
          if (optValue.selected && optValue.reference) {
            if (!optValue.quantity) {
              quantity = componentQuantity;
            } else {
              quantity = componentQuantity * optValue.quantity;
            }
            optValue.referenceBom = BomService.convertReferenceToReferenceBom(this.translateService,
              optValue.reference, optValue, item.discount, item.range, quantity);

            let optionRef: ReferenceBom = this.extractReferenceToArray(optValue, item.discount, item.deliveryPolicy);
            if (extractForAllProject) {
              const optCategory =  index + '-' + component.reference.ref + ' ' + ComponentType.OPTION;
              optionRef = this.addInfosToReferenceBom(optionRef, item, optCategory);
            }
            references.push(optionRef);
          }
        }
      );
    });
    if (component.functionalUnits !== null) {
      let componentIndex = 1;
      component.functionalUnits.forEach(functionalUnit => {
        if (functionalUnit.transformer) {
          // recursive call
          this.extractComponentReferencesToArray(references, functionalUnit.transformer, componentIndex, item, extractForAllProject);
          componentIndex++;
        }
      });
    }
  }

  private convertReferenceBOMToReferenceBomForMySE(extractReferencesToArray: ReferenceBomForMySE[]) {
    const allReferenceBomForMySE: ReferenceBomForMySE[] = [];
    extractReferencesToArray.forEach(
      ref => {
        const referenceBomForMySE: ReferenceBomForMySE = new ReferenceBomForMySE();
        referenceBomForMySE.ref = ref.ref;
        referenceBomForMySE.count = ref.count;
        allReferenceBomForMySE.push(referenceBomForMySE);
      }
    );
    return allReferenceBomForMySE;
  }

  /**
   * Convert ReferenceBom to ReferenceBomForDigiQ
   * @param {ReferenceBomForDigiQ[]} extractReferencesToArray
   * @returns {ReferenceBomForDigiQ[]}
   */
  private convertReferenceBOMToReferenceBomForDigiQ(extractReferencesToArray: ReferenceBomForDigiQ[]) {
    const referencesBomDigiQ: ReferenceBomForDigiQ[] = [];
    extractReferencesToArray.forEach(
      ref => {
        const referenceBomDigiQ: ReferenceBomForDigiQ = new ReferenceBomForDigiQ();
        referenceBomDigiQ.ref = ref.ref;
        referenceBomDigiQ.count = ref.count;
        referenceBomDigiQ.discount = ref.discount;
        referencesBomDigiQ.push(referenceBomDigiQ);
      }
    );
    return referencesBomDigiQ;
  }


  /**
   * Creates an array with all data that must appear on classic BOM (not MySE and not DigiQ)
   * But if user have A MySE account and one price is missing, price column should not appear
   * @param headers: headers of column
   * @param {ReferenceBom[]} originalReferenceBom
   * @param {boolean} hasPrices user have MySE account but one of the references doesn't have price on MySE
   * @returns {BOMFormatedForExcel[]} an array representing BOM
   */
  private adaptReferenceBomToExcelFormat(headers: any, originalReferenceBom: ReferenceBom[], hasPrices: boolean): BOMFormatedForExcel [] {
    const arrayWithoutNullValue = this.csvHelperService.convertNullValueFromArray(originalReferenceBom);
    const bomFormatedForExcelExport: BOMFormatedForExcel [] = [];
    arrayWithoutNullValue.forEach(
      ref => {
        const bomForOneRef: BOMFormatedForExcel = new BOMFormatedForExcel();
        if (!isNullOrUndefined(headers.ref)) {
          bomForOneRef.ref = ref.ref;
        }
        if (!isNullOrUndefined(headers.description)) {
          bomForOneRef.description = ref.description;
        }
        if (!isNullOrUndefined(headers.value)) {
          bomForOneRef.value = ref.value;
        }
        if (hasPrices) {
          if (!isNullOrUndefined(headers.discountUnitPrice) && ref.discountUnitPrice) {
            bomForOneRef.discountUnitPrice = ref.discountUnitPrice;
          }
          if (!isNullOrUndefined(headers.unitPrice)) {
            bomForOneRef.unitPrice = ref.price;
          }
          if (!isNullOrUndefined(headers.discountedPrice) && ref.discountedPrice) {
            bomForOneRef.discountedPrice = ref.discountedPrice;
          }
          if (!isNullOrUndefined(headers.discountedNetPrice) && ref.discountedNetPrice) {
            bomForOneRef.discountedNetPrice = ref.discountedNetPrice;
          }
          if (!isNullOrUndefined(ref.netPrice) && ref.netPrice && !isNullOrUndefined(headers.netPrice)) {
            bomForOneRef.netPrice = ref.netPrice;
          }
          if (!isNullOrUndefined(ref.partnerDiscount) && ref.partnerDiscount && !isNullOrUndefined(headers.partnerDiscount)) {
            bomForOneRef.partnerDiscount = ref.partnerDiscount;
          }
          if (!isNullOrUndefined(ref.totalNetPrice) && ref.totalNetPrice && !isNullOrUndefined(headers.totalNetPrice)) {
            bomForOneRef.totalNetPrice = ref.totalNetPrice;
          }
          if (!isNullOrUndefined(ref.totalPrice) && !isNullOrUndefined(headers.totalPrice)) {
            bomForOneRef.totalPrice = ref.totalPrice;
          }
        }
        if (!isNullOrUndefined(headers.count)) {
          bomForOneRef.count = ref.count;
        }
        if (!isNullOrUndefined(headers.availableOnStock)) {
          bomForOneRef.availableOnStock = ref.availableOnStock;
        }
        if (!isNullOrUndefined(headers.deliveryTime)) {
          bomForOneRef.deliveryTime = ref.deliveryTime;
        }
        if (!isNullOrUndefined(headers.configuration)) {
          bomForOneRef.configuration = ref.configuration;
        }
        if (!isNullOrUndefined(headers.category)) {
          bomForOneRef.category = ref.category;
        }
        if (!isNullOrUndefined(headers.status)) {
          if (ref.status) {
            bomForOneRef.status = this.translateService.instant('T_' + ref.status);
          } else {
            bomForOneRef.status = '';
          }
        }
        bomFormatedForExcelExport.push(bomForOneRef);
      }
    );
    return bomFormatedForExcelExport;
  }

  /**
   * Add some fields to referenceBOM object to be adapted to the project BOM
   * @param {ReferenceBom} anyRef any reference of accessories, cubicle, option...
   * @param {Item} item one item of the project
   * @param {ComponentType} category type of the reference (accessory, cubicle, option...)
   * @returns {ReferenceBom} reference representation for BOM
   */
  private addInfosToReferenceBom(anyRef: ReferenceBom, item: Item, category: string): ReferenceBom {
    const newdRef: ReferenceBom = anyRef;
    newdRef.configuration = item.name;
    if (category === 'accessory') {
      newdRef.category = this.translateService.instant('T_BOM_ACCESSORIES');
    } else {
      newdRef.category = category;
    }
    newdRef.status = item.status;
    return newdRef;
  }

  private static formatPartnerDiscount(partnerDiscount: number) {
    return (partnerDiscount * 100).toFixed(2) + '%';
  }
}
