import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {File} from "./file.model";

@Injectable()
export class FileService {

    constructor(private readonly httpClient: HttpClient,) {
    }

    get(fileId: string): Observable<File> {
        return this.httpClient.get<File>('/file/' + fileId);
    }

    create(fileData: FormData): Observable<File> {
        return this.httpClient.post<File>('/file', fileData);
    }

    delete(fileId: string): Observable<any> {
        return this.httpClient.delete('/file/' + fileId);
    }

    downloadBase64File(extension, base64Data, fileName) {
        let contentType: string;
        if (extension === 'pdf') {
            contentType = 'application/pdf';
        }
        const linkSource = `data:${contentType};base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
}
