import {Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import {ComToolsService} from '../../../shared/com-tools/com-tools.service';
import {Post, PostCategory} from '../../../shared/model/post';
import {DomSanitizer} from '@angular/platform-browser';
import {CommonModalComponent} from '../../../shared/common-modal/common-modal.component';
import {User} from '../../../shared/user/user';
import {SessionStorage} from 'ngx-webstorage';

@Component({
  selector: 'app-notifications-bell',
  templateUrl: './notifications-bell.component.html',
  styleUrls: ['./notifications-bell.component.less']
})
export class NotificationsBellComponent implements OnInit {

  @ViewChild('notificationsModal') notificationModal: CommonModalComponent;

  @HostBinding('attr.style')
  public get valueAsStyle(): any {
    return this.sanitizer.bypassSecurityTrustStyle(`--notifications-length: '${this.notificationsCounts.toString()}'`);
  }

  @SessionStorage()
  user: User;

  notificationsCounts = 0;
  notifications: Array<Post> = [];

  constructor(private sanitizer: DomSanitizer,
    private comToolsService: ComToolsService
  ) { }

  ngOnInit() {
    this.comToolsService.getCountUnreadPost(PostCategory.NOTIFICATIONS).subscribe(count => this.notificationsCounts = count);
    this.comToolsService.getPosts(PostCategory.NOTIFICATIONS).subscribe(posts => this.notifications = posts);
  }

  showNotificationModal() {
    this.notificationsCounts = 0;
    this.comToolsService.updateReadingDate(PostCategory.NOTIFICATIONS).subscribe(user => this.user.lastNotificationsReadingDate = user.lastNotificationsReadingDate);
    this.notificationModal.show();
  }
}
