import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CommonModalComponent} from '../../../../../shared/common-modal/common-modal.component';

@Component({
  selector: 'app-odm-add-offer-nav-button',
  templateUrl: './odm-add-offer-nav-button.component.html',
  styleUrls: ['./odm-add-offer-nav-button.component.less']
})
export class OdmAddOfferNavButtonComponent {

  @Input()
  activePrevious: boolean;

  @Input()
  activeNext: boolean;

  @Input()
  hideNext: boolean;

  @Input()
  modificationPending: boolean;

  @Output()
  previousEvent = new EventEmitter<void>();

  @Output()
  nextEvent = new EventEmitter<boolean>();

  @ViewChild('saveStepModal') saveStepModal: CommonModalComponent;

  constructor() { }

  /**
   * Go to the previous configuration step
   */
  goToPreviousStep() {
    this.previousEvent.emit();
  }

  /**
   * Go to the next configuration step (with modal saving confirmation)
   */
  goToNextStep() {
    if (this.modificationPending) {
      this.saveStepModal.show();
    } else {
      this.saveStep();
    }
  }

  /**
   * Save step configuration before the next
   */
  saveStep() {
    this.nextEvent.emit(true);
  }

  /**
   * No saving step configuration before the next
   */
  noSaveStep() {
    this.nextEvent.emit(false);
  }

}
