<section *ngIf="bslCharacteristicsMap" class="container-fluid">
  <div class="odm-left-column scrollable scrollable-max-height">
    <app-odm-characteristics [bslCharacteristicsMap]="bslCharacteristicsMap"
                                               [disabledCharacteristicsIds]="electricalCharacteristicIds"
                                               [tooltipMessage]="'T_OFFER_DATA_MANAGEMENT_CHARACTERISTIC_DISABLED_TOOLTIP'"
                                               [displayProperty]="'name'"
                                               (onSelectionChange)="filterCharacteristics($event)">
    </app-odm-characteristics>
  </div>
  <div class="odm-right-column scrollable scrollable-max-height">
    <div class="right-column-title"><span translate>T_OFFER_DATA_MANAGEMENT_SELECT_FILTERS_PREVIEW</span></div>
    <div class="right-column-detail">
      <div class="right-column-label"><span translate>T_OFFER_DATA_MANAGEMENT_SELECT_FILTERS_LABEL</span></div>
      <div *ngIf="!(selectedCharacteristics && selectedCharacteristics.length > 0)" class="right-column-mess"><span translate>T_OFFER_DATA_MANAGEMENT_FILTERS_PREVIEW_MESSAGE</span>
      </div>
      <div cdkDropList (cdkDropListDropped)="drop($event)">
        <div *ngFor="let selectedCharacteristic of selectedCharacteristics" class="card-container" cdkDrag>
          <div class="card">
            <div class="card-name-custom-placeholder" *cdkDragPlaceholder></div>
            <div class="card-name">{{selectedCharacteristic.name}}</div>
            <hr/>
            <div class="card-values">
              <span *ngFor="let value of selectedCharacteristic.values" class="card-value">{{value}}</span>
            </div>
          </div>
        </div>
        <div *ngIf="selectedCharacteristics && selectedCharacteristics.length > 0" class="number-container">
          {{'T_OFFER_DATA_MANAGEMENT_NUMBER' | translate:{number: selectedCharacteristics.length} }}
        </div>
      </div>
    </div>
  </div>
</section>
