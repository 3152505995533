/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/concatAll';
import {TranslateService} from '@ngx-translate/core';
/* app modules */
import {Item, Project, ProjectFile} from './project-model';
import {LoggerService} from '../../shared/logging/logger.service';
import {MessageService} from "primeng/api";
import {ShareOptions} from './share-option-model';
import {DocumentationService, DocumentInformations} from '../../export/shared';
import {SessionStorageService} from 'ngx-webstorage';
import {User} from '../../shared/user/user';

@Injectable()
export class ProjectService {

  constructor(private httpClient: HttpClient,
              private translateService: TranslateService,
              private sessionStorageService: SessionStorageService,
              private documentationService: DocumentationService,
              private logger: LoggerService,
              private messageService: MessageService) {
  }

  /**
   * Get a project by its id
   * @param {string} projectId
   * @returns {Observable<Project>} project from backend
   */
  getProject(projectId: string): Observable<Project> {
    this.logger.debug('ProjectService getProject(' + projectId + ')');

    return this.httpClient.get<Project>('/projects/' + projectId);
  }

  /**
   * Get projects list
   *
   * @returns {Observable<Project[]>}
   */
  getProjects(limit?: number): Observable<Project[]> {
    this.logger.debug('ProjectService getProjects()');

    let params = new HttpParams();

    if (limit) {
      params = params.append('limit', limit);
    }
    return this.httpClient.get<Project[]>('/projects', {params});
  }

  /**
   * Get projects list
   *
   * @returns {Observable<Project[]>}
   */
  getSharedProjects(): Observable<Project[]> {
    this.logger.debug('ProjectService getSharedProjects()');

    return this.httpClient.get<Project[]>('/projects/loadShared');
  }

  /**
   * Get shared projects of a project
   * @param {string} projectId the id of the original project
   * @returns {Observable<Project[]>} the list of shared project
   */
  getSharedProjectsForProject(projectId: string): Observable<Project[]> {
    this.logger.debug('ProjectService getSharedProjectsForProject(' + projectId + ')');

    return this.httpClient.get<Project[]>('/projects/' + projectId + '/sharedProjects');
  }

  /**
   * Check if SpimId Project exist
   *
   * @returns false: SpimIdProject exists, true: a Spim project has been created
   */
  needToCreateSpimProjectId(projectId: string): Observable<Project> {
    this.logger.info('ProjectService checkSpimProjectId()');

    return this.httpClient.get<Project>('/projects/' + projectId + '/checkSpimId');
  }
  /**
   * Add a project
   *
   * @returns {Observable<Project>}
   */
  addProject(cpqNumber?: string): Observable<Project> {
    this.logger.info('ProjectService addProject()');
    if (cpqNumber !== null && cpqNumber !== undefined) {
      return this.httpClient.post<Project>('/projects/cpq/' + cpqNumber, {});
    } else {
      return this.httpClient.post<Project>('/projects', {});
    }
  }

  /**
   * Update project name
   *
   * @param projectId project id
   * @param newName project's name
   * @returns {Observable<Project>}
   */
  updateProjectNameRequest(projectId: string, newName: string): Observable<Project> {
    this.logger.info('ProjectService updateProjectNameRequest()');

    return this.httpClient.post<Project>('/projects' + '/updateProjectName',
      {projectId: projectId, projectName: newName}
    );
  }

  updateProjectName(project, newProjectName): Observable<Project> {
    return Observable.create(observer => {
      this.updateProjectNameRequest(project.id, newProjectName.trim())
        .subscribe(p => {
            observer.next(p);
          },
          error => {
            this.logger.error(error);
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('T_ERROR'),
              detail: this.translateService.instant('T_RENAME_PROJECT_ERROR_MESSAGE')
            });
            observer.error(new Error(error));
          },
          () => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('T_INFO'),
              detail: this.translateService.instant('T_RENAME_PROJECT_SUCCESS_PROJECT')
            });
            observer.complete();
          });
    });
  }

  /**
   * Delete a project
   *
   * @param projectId project id
   * @returns {Observable<Response>}
   */
  deleteProject(projectId): Observable<any> {
    this.logger.info('ProjectService deleteProject()');

    return this.httpClient.delete('/projects/' + projectId,
      {responseType: 'text'});
  }

  /**
   * Update a project's group
   *
   * @param projectId project id
   * @returns {Observable<Project>}
   */
  updateProjectGroup(projectId): Observable<Project> {
    this.logger.info('ProjectService updateProjectGroup()');

    return this.httpClient.post<Project>('/projects/' + projectId + '/updateProjectGroup',
      {responseType: 'text'});
  }

  /**
   * Push a project to CPQ
   *
   * @param projectId project id
   * @returns {Observable<Response>}
   */
  pushToCpqProject(projectId): Observable<Project> {
    this.logger.info('ProjectService pushToCpqProject()');

    return this.httpClient.post<Project>('/projects/pushToCpqProject/' + projectId,
      {responseType: 'text'});
  }

  /**
   * Associate project To CPQ
   *
   * @returns {Observable<Project>}
   */
  associateToCpq(projectId, cpqNumber: string): Observable<Project> {
    this.logger.info('ProjectService associateToCpq()');
    return this.httpClient.post<Project>('/projects/associateCpq/' + projectId + '/' + cpqNumber,
      {responseType: 'text'});
  }

  /**
   * Update the discount of the project
   * @param {string} projectId id of the project
   * @param {number} discount value of the discount
   */
  updateProjectDiscount(projectId: string, discount: number): Observable<any> {
    this.logger.info('ProjectService updateProjectDiscount()');

    return this.httpClient.put('/projects/discount',
      {projectId: projectId, discount: discount},
      {responseType: 'text'}
    );
  }

  /**
   * Clone a project
   *
   * @param projectId project id
   * @returns {Observable<Project>}
   */
  cloneProject(projectId, cpqNumber?: string, documentId?: string, duplicationForUpdate= false): Observable<Project> {
    this.logger.info('ProjectService cloneProject()');
    if (cpqNumber !== null && cpqNumber !== undefined) {
      return this.httpClient.post<Project>('/projects/' + projectId + '/cpq/' + cpqNumber + '/clone/' + documentId, { duplicationForUpdate });
    } else {
      return this.httpClient.post<Project>('/projects/' + projectId + '/clone', {});
    }
  }

  /**
   * Clone a project
   *
   * @param projectId project id
   * @returns {Observable<Project>}
   */
  cloneSharedProject(projectId): Observable<Project> {
    this.logger.info('ProjectService cloneProject()');

    return this.httpClient.post<Project>('/projects/' + projectId + '/cloneShared', {});
  }


  /**
   * Share a project
   *
   * @param shareOptions Selected information from modal
   * @returns {Observable<Project>}
   */
  shareProject(shareOptions: ShareOptions): Observable<Project> {
    this.logger.info('ProjectService shareProject()');
    return this.httpClient.post<Project>('/projects/' + shareOptions.id + '/share', shareOptions);

  }

  /**
   * Share a project
   *
   * @param shareOptions Selected information from modal
   * @returns {Observable<Project>}
   */
  duplicateSpimProject(project: Project, shareOptions: ShareOptions): Observable<Project> {
    this.logger.info('ProjectService duplicateSpimProject()');
    return this.httpClient.post<Project>('/projects/' + project.id + '/duplicateSpim', shareOptions);

  }


  /**
   * Get files stores on SPIM of a project
   * @param projectId
   * @returns {Observable<Array<String>>}
   */
  getProjectFiles(projectId): Observable<Array<ProjectFile>> {

    this.logger.info('ProjectService getProjectFiles()');
    return this.httpClient
      .get<Array<ProjectFile>>('/projects/' + projectId + '/files', {});
  }

  /**
   * Update project name from inplace editing field
   * @param {Project} project to be renamed
   * @param {DocumentInformations} documentInformation the be updated with new project name
   * @param {Item} currentItem to acces documentInformation in db
   * @param inputProjectName the event
   */
  updateProjectNameFromEditable(project: Project, documentInformation: DocumentInformations, currentItem: Item, inputProjectName) {

    const oldProjectName = project.name;
    const newProjectName = inputProjectName.target.textContent.replace(/(\r\n|\n|\r)/gm, '');
    inputProjectName.target.innerText = newProjectName;

    project.name = newProjectName;

    if (newProjectName.trim() !== oldProjectName.trim()) {
      this.updateProjectName(project, newProjectName.trim())
        .subscribe(p => {
            if (documentInformation !== null && currentItem != null) {
              documentInformation.projectName = p.name;
              this.documentationService.updateDocumentInformations(documentInformation).subscribe();
            }
            this.sessionStorageService.store('currentProject', p);
          },
          () => {
          inputProjectName.target.innerText = oldProjectName;
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('T_ERROR'),
              detail: this.translateService.instant('T_PROJECTS_RENAMING_ERROR')
            });
          }
        );
    }

  }

  /**
   * Remove focus on project name in place editable field to trigger name update
   * Trigger on keypress, if enter is pressed remove focus of html element
   * @param $event the keypress event
   */
  removeFocusOnEnter($event) {
    if ($event.key === 'Enter') {
      $event.target.blur();
    }
  }

  /**
   * Manage project name length control in editable field
   * Call on keypress, igonring Enter, Backspace and Arrow press
   * @param $event the keypress event
   */
  controlMaxLengthOnProject($event) {
    if ($event.key !== 'Enter' && $event.key !== 'Backspace' && $event.key !== 'ArrowLeft' && $event.key !== 'ArrowRight'
      && $event.target.textContent.replace(/(\r\n|\n|\r)/gm, '').trim().length === 25) {
      $event.preventDefault();
    }
  }

  isReadOnlyProject(project: Project, user: User): boolean {
    if (project === null || user === null) {
      return true;
    }
    return !(Number(project.partnerId) === user.partnerId || project.canEditSharedProject) || project.readOnly;
  }

  updateComment(project, newComment): Observable<Project> {
    return Observable.create(observer => {
      this.updateCommentRequest(project.id, newComment)
        .subscribe(p => {
            observer.next(p);
          },
          error => {
            this.logger.error(error);
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('T_ERROR'),
              detail: this.translateService.instant('T_UPDATE_COMMENT_PROJECT_ERROR_MESSAGE')
            });
            observer.error(new Error(error));
          },
          () => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('T_INFO'),
              detail: this.translateService.instant('T_UPDATE_COMMENT_PROJECT_SUCCESS_PROJECT')
            });
            observer.complete();
          });
    });
  }

  /**
   * Update comment rest request
   *
   * @param projectId project id
   * @param comment comment
   * @returns {Observable<Project>}
   */
  updateCommentRequest(projectId: string, comment: string): Observable<Project> {
    this.logger.info('ProjectService updateCommentRequest()');

    return this.httpClient.post<Project>('/projects' + '/updateComment',
      {projectId: projectId, comment: comment}
    );
  }

  /**
   * return statement if it is a  CPQ project
   * @param project
   */
  isCpqProject(project: Project): boolean {
    return project !== null && project !== undefined && project.cpqNumber !== null && project.cpqNumber !== undefined;
  }

  isPrivateProject(project: Project): boolean {
    return project !== null && project !== undefined && (project.partnerId == null || project.partnerId < 0);
  }
}
