<div *ngIf="!isDemoUser && !isDemoMode" class="row">
  <div class="col-md-4 pull-right bom-section-margin-top text-right">
    <!--Delivery lead time-->
    <div>
      <app-delivery-details *ngIf="!!ranges" [currentProjectItems]="currentProjectItems" [currentItemct]="currentItemct" [ranges]="ranges"></app-delivery-details>
    </div>

    <!--Discount field-->
    <div class="row" *ngIf="(userCanApplyDiscount && isDiscountAllowedForItems() && itemBomPage)">
      <div class="col-md-8 col-sm-8">
        <span translate>{{keyDiscount}}</span> :
      </div>
      <div class="col-md-4 col-sm-4">
        <span *ngIf="showToolTipDiscount" class="se-icons fa-lg brand-information"
              tooltip="{{'T_BOM_DISCOUNT_INFORMATION' | translate}}" placement="left" container="body"
              containerClass="bom-tooltip-discount">information_circle</span>
        <input class="discountForm" type="number" min="0" max="{{getAllowedDiscountLimitField()}}" step="0.01"
               [disabled]="!isDiscountAllowedForItems()" [(ngModel)]="discount"
               (blur)="onChangeDiscount($event.target.value)">
        <span> %</span>
      </div>
    </div>

    <!--Total net price-->
    <div class="row" *ngIf="(isMySEUser && utilService.displayMySENetPrice()) || displayFoNetPrice">
      <div class="col-md-8 col-sm-8"><span translate>T_NET_PRICE</span> :</div>
      <div class="col-md-4 col-sm-4">
        <span *ngIf="itemBomPage && totalNetPrice && (isValidMySENetPriceFromStatus() || displayFoNetPrice)">{{totalNetPrice}} {{localization.displayedCurrency}}</span>
        <span *ngIf="itemBomPage && currentItemct && !isValidMySENetPriceFromStatus()" class="se-icons brand-warning price-row"
              tooltip="{{(getTooltipForMysePriceStatus())}}" placement="left"
              container="body">notification_critical_stroke
        </span>
        <span *ngIf="projectBomPage && totalNetPrice && (!errorNetPriceMessage || errorNetPriceMessage === '')">{{totalNetPrice}} {{localization.displayedCurrency}}</span>
        <span *ngIf="projectBomPage && errorNetPriceMessage && errorNetPriceMessage !== ''" class="se-icons brand-warning price-row"
              tooltip="{{(errorNetPriceMessage)}}" placement="left"
              container="body">notification_critical_stroke
        </span>
      </div>
    </div>

    <!--Total price if no discount mode activated-->
    <div class="row" *ngIf="!userCanApplyDiscount && !onlyTotalNetPrice">
      <div class="col-md-8 col-sm-8"><span translate>T_PUBLIC_PRICE</span> :</div>
      <div class="col-md-4 col-sm-4">
        <span *ngIf="containsNullPublicPrices" class="se-icons brand-warning price-row" [tooltip]="getContainsNullPricesWarningTooltip()" placement="left">notification_critical_stroke</span>
        <span *ngIf="totalPrice !== null && (!errorMessage || errorMessage === '')">{{totalPrice}} {{localization.displayedCurrency}}</span>
        <span *ngIf="errorMessage && errorMessage !== ''" class="se-icons brand-warning price-row"
              tooltip="{{(errorMessage)}}" placement="left"
              container="body">notification_critical_stroke
        </span>
      </div>
    </div>

    <!--Total price with discount-->
    <div class="row" *ngIf="userCanApplyDiscount && !onlyTotalNetPrice">
      <div class="col-md-8 col-sm-8"><span translate>T_BOM_TOTAL_PRICE_DISCOUNTED</span> :</div>
      <div class="col-md-4 col-sm-4">
        <span *ngIf="containsNullNetPrices" class="se-icons brand-warning price-row" [tooltip]="getContainsNullPricesWarningTooltip()" placement="left">notification_critical_stroke</span>
        <span *ngIf="isAllMysePricesAvailable()">
              {{discountedPrice}} {{localization.displayedCurrency}}
            </span>
        <span *ngIf="!isAllMysePricesAvailable()" class="se-icons brand-warning price-row"
              tooltip="{{(getTooltipForMysePriceStatus())}}" placement="left"
              container="body">notification_critical_stroke</span>
      </div>
    </div>
  </div>
</div>

<!-- order dialog -->
<app-common-modal #exeedDiscountModal [name]="'exeedDiscountModal'" [twoButtonsDialogs]="false">
</app-common-modal>
