<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog {{size}}">
    <div class="modal-content">

      <div class="modal-header">
        <button *ngIf="closable" type="button" class="modal-close" (click)="clickNoButton(); hide()">
            <span class="fa-stack">
              <em class="fa fa-times-thin fa-2x"></em>
            </span>
      </button>
        <h4 *ngIf="showTitle" class="modal-title">{{title}}</h4>
        <div #trancludedTitle><ng-content select="[modal-title]"></ng-content></div>
      </div>

      <div *ngIf="!acceptInnerHtml" class="modal-body background-white">
        {{description}}
        <ng-content select="[modal-body]"></ng-content>
      </div>

      <div *ngIf="acceptInnerHtml" class="modal-body background-white" innerHTML="{{description}}">
        <ng-content select="[modal-body]"></ng-content>
      </div>


      <div class="modal-footer background-white" *ngIf="twoButtonsDialogs && withFooter">
        <button class="btn btn-default-white-background" (click)="clickYesButton(); hide()" translate>T_BUTTON_YES</button>
        <button class="btn btn-default-white-background" (click)="clickNoButton();hide()" translate>T_BUTTON_NO</button>
      </div>
      <div class="modal-footer" *ngIf="!twoButtonsDialogs && withFooter">
        <button class="btn btn-default-white-background" (click)="clickOkButton(); hide()" translate>T_BUTTON_OK</button>
      </div>

    </div>
  </div>
</div>
