/* angular */
import { Injectable } from '@angular/core';
import { SessionStorage } from 'ngx-webstorage';
/* application */
import { FilterCategory } from '../../configuration/shared/model/filterCategory';
import { Characteristic, IProduct } from '../../configuration/shared/model/IProduct';
import {Item, ItemValue} from '../../project/shared/project-model';
import { FilterValue } from '../../configuration/shared/model/FilterValue';
import { LoggerService } from '../logging/logger.service';
/* other sources */
import * as _ from 'lodash';
import { SwitchBoardComponent } from '../../configuration/shared/model/component';
import { VisibilityCheck } from '../../configuration/shared/model/visibilityCheck';
import { CubicleService } from '../../configuration/shared/services/cubicle.service';
import { TransformerService } from '../../configuration/shared/services/transformer.service';

/**
 * Service to manage generation of filter for display of commercial product
 */
@Injectable()
export class ProductsFiltersService {

  @SessionStorage()
  currentItemct: Item;

  constructor(private logger: LoggerService) {
  }

  /**
   * Insert a new filter value for given category
   * @param {Map<string, FilterCategory>} mapFilters filters map
   * @param {Characteristic} characteristic characteristic to insert
   * @param valueToAdd {number | string | Array<string>} the value to add for the characteristic
   * @param {VisibilityCheck} visibilityCheck .isVisible is passed to FilterCategory constructor for its .isVisible method (optional)
   * @returns {Map<string, FilterCategory>}
   */
  private static insertFilter(mapFilters: Map<string, FilterCategory>,
                              characteristic: Characteristic,
                              valueToAdd: number | string | Array<string>,
                              visibilityCheck?: VisibilityCheck): Map<string, FilterCategory> {
    return new Map(
      [...Array.from(mapFilters.entries()),
        [
          characteristic.key,
          new FilterCategory(characteristic.key,
            [
              ...mapFilters.has(characteristic.key) ? mapFilters.get(characteristic.key).values : [],
              ...(valueToAdd instanceof Array ? (<string[]>valueToAdd).map(val => new FilterValue(val, false, false)) :
                [new FilterValue(<string | number>valueToAdd, false, false)])
            ]
              .sort((a, b) => a.value < b.value ? -1 : 1),
            characteristic.measurementUnit,
            characteristic.category,
            visibilityCheck ? visibilityCheck.isVisible.bind(visibilityCheck) : () => true
          )
        ]
      ]
    );
  }

  /**
   * Allow to extract filters from the characteristics of the given components
   * @param {Array<IProduct>} components components of the range
   * @param {string[]} visibleFilters the list of filter to be displayed
   * @param {boolean} showOneValueFilter to show one value filters
   * @param visibilityMap optional visibility conditions for filters
   * @returns {FilterCategory[]} the key is the filter's label
   */
  public extractFiltersFromComponents(components: IProduct[],
                                      visibleFilters: string[],
                                      showOneValueFilter: boolean,
                                      visibilityMap?: Map<string, VisibilityCheck>): FilterCategory[] {
    this.logger.debug('ProductsFiltersService extractFiltersFromComponents()');

    this.fillMissingFilterValuesWithNa(visibleFilters, components);

    return this.getFilters(components, visibleFilters, visibilityMap)
      .filter(value => (showOneValueFilter || value.values.length > 1));
  }

  /**
   * Allow to extract filters from the characteristics witout any filter of the given components
   * @param {Array<IProduct>} components components of the range
   * @param {string[]} visibleFilters the list of filter to be displayed
   * @param visibilityMap optional visibility conditions for filters
   * @returns {FilterCategory[]} the key is the filter's label
   */
  public extractFilterSelector(components: IProduct[],
                               visibleFilters: string[],
                               visibilityMap?: Map<string, VisibilityCheck>): FilterCategory[] {
    this.logger.debug('ProductsFiltersService extractAnyFilter()');

    const filterCategories: FilterCategory[] = this.getFilters(components, visibleFilters, visibilityMap);
    const filterCategoriesSorted: FilterCategory[] = [];
    visibleFilters.forEach(visibleFilter => {
      filterCategoriesSorted.push(filterCategories.find(filterCategory => filterCategory.key === visibleFilter));
    });
    return filterCategoriesSorted;
  }

  /**
   * Allow to extract filters from the characteristics of the given components
   * Takes into account cubicle incompatibility 'CUBICLE_TRANSFO'
   *
   * @param {Array<IProduct>} components components of the range
   * @param {string[]} visibleFilters the list of filter to be displayed
   * @param {boolean} showOneValueFilter to show one value filters
   * @param cubicle cubicle for which we want to chose transformer
   * @param visibilityMap optional visibility conditions for filters
   */
  public extractFiltersWithCubicleIncompatibilities(components: IProduct[],
                                                    visibleFilters: string[],
                                                    showOneValueFilter: boolean,
                                                    cubicle: SwitchBoardComponent,
                                                    visibilityMap?: Map<string, VisibilityCheck>): FilterCategory[] {
    if (cubicle !== undefined && cubicle !== null) {
      const cubicleTypeConcerned = CubicleService.isQMorQMCorPM(cubicle, 'T_SM6_ID_SM6R_');
      if (cubicleTypeConcerned) {
        components = components.filter(transformer => TransformerService.availableForQMandQMCandPM(transformer, cubicle));
      }
    }
    return this.extractFiltersFromComponents(components, visibleFilters, showOneValueFilter, visibilityMap);
  }

  /**
   * filter available components with all selected filters
   * @param {Array<FilterCategory>} filterSelection
   * @param {Array<SwitchBoardComponent>} components
   * @returns {Array<SwitchBoardComponent>}
   */
  public applyFilters(filterSelection: Array<FilterCategory>,
                      components: Array<IProduct>): Array<IProduct> {
    this.logger.info('ProductsFiltersService applyFilters()');

    const selectedFilters = this.onlyCheckedFilters(filterSelection);

    const matchedComponents: IProduct[] = [];
    const checkedFilterNumber = selectedFilters.length;
    components.forEach(component => {
      let numberMatch = 0;
      component.characteristics.forEach(charac => {
        selectedFilters.forEach(filterV => {
          if (filterV.key === charac.key && filterV.values[0].value === charac.value) {
            numberMatch += 1;
          } else if (Array.isArray(charac.value) && filterV.key === charac.key && (<Array<string>>charac.value).includes(<string>filterV.values[0].value)) {
            numberMatch += 1;
          }
        });
      });
      if (numberMatch === checkedFilterNumber) {
        matchedComponents.push(component);
      }
    });

    return matchedComponents.length !== 0 ? matchedComponents : components;
  }

  /**
   * this method updates the filters according to the elements available after a first sorting
   * for example, with 2 objects { a: 'V1', b: 'V2' }, {a: 'V2', c:'V3' } : the available filters are a, b and c if uer
   * choose a = V1; available filters will be a and b.
   */
  public updateFilters(filterSelection: Array<FilterCategory>,
                       displayedcomponents: Array<IProduct>) {
    if (displayedcomponents !== null && displayedcomponents.length !== 0) {
      filterSelection.forEach(filterCat => {
        filterCat.values.forEach(value => {
          value.disabled = true;
          displayedcomponents.forEach(component => {
            if (component.characteristics.filter(char => char.key === filterCat.key)
              .some(char => (char.value === value.value) || (Array.isArray(char.value) && (<Array<string>>char.value).includes(<string>value.value)))) {
              value.disabled = false;
            }
          });
        });
      });
    }
  }

  /**
   * Valorize filters with the selected values from previous session
   */
  public selectElectricalCharacteristicsFilter(electricalCharac: ItemValue[], electricalFilters: FilterCategory[]) {
    electricalCharac.forEach(elec => {
      const selectedValue = electricalFilters.find(filter => filter.key === elec.name);
      if (selectedValue != null) {
        selectedValue.values.forEach(val => val.checked = String(val.value) === elec.value);
        selectedValue.selected = elec.chooseValue;
      }
    });
  }

  /**
   * this method updates the filters according to the elements available after a first sorting
   * for example, with 2 objects { a: 'V1', b: 'V2' }, {a: 'V2', c:'V3' } : the available filters are a, b and c if uer
   * choose a = V1; available filters will be a and b.
   */
  public initFilters(filterSelection: Array<FilterCategory>,
                     displayedcomponents: Array<IProduct>) {
    if (displayedcomponents !== null && displayedcomponents.length !== 0) {
      filterSelection.forEach(filterCat => {
        filterCat.values.forEach(value => {
          value.checked = false;
          displayedcomponents.forEach(component => {
            if (component.characteristics.some(char => (char.value === value.value.toString()) || (Array.isArray(char.value) && char.value.includes(value.value.toString())))) {
              value.checked = true;
            }
          });
        });
      });
    }
  }

  /**
   * For each filter check if every product have a value. If not add a Not Applicable on product missing a value for the filter.
   * @param visibleFilters list of filters
   * @param components list of products
   */
  private fillMissingFilterValuesWithNa(visibleFilters: string[], components: IProduct[]) {
    // Adding N/A value for filters not present on every ref
    visibleFilters.forEach(filter => {
      components.filter(comp => comp.characteristics.every(charac => charac.key !== filter)).forEach(comp => {
        const notApplicableCharac = new Characteristic();
        notApplicableCharac.key = filter;
        notApplicableCharac.value = 'T_CHARACTERISTICS_NOT_APPLICABLE_VALUE';
        comp.characteristics.push(notApplicableCharac);
      });
    });
  }

  /**
   * Return filters
   * @param {IProduct[]} components list of components to extract filter
   * @param {string[]} visibleFilters list of visible filters
   * @param {Map<string, VisibilityCheck>} visibilityMap conditionaly visible filters
   * @returns {FilterCategory[]} the list of filters
   */
  private getFilters(components: IProduct[],
                     visibleFilters: string[],
                     visibilityMap: Map<string, VisibilityCheck>) {
    let mapFilters: Map<string, FilterCategory> = new Map<string, FilterCategory>();
    components.forEach(component => {
      // We apply filter if visibleComponentsFilters is defined
      // otherwise we accept all characteristics if no filters defined or if range is switchboard and components are transformers
      component.characteristics
        .filter(characteristic =>
          visibleFilters.length === 0 || visibleFilters.includes(characteristic.key))
        .forEach(characteristic => {
          if (mapFilters.has(characteristic.key)) {
            // Key already present -> adding value if not present
            if (Array.isArray(characteristic.value)) {
              (<Array<string>>characteristic.value).forEach(val => {
                if (!mapFilters.get(characteristic.key).values.map(value => value.value).includes(val)) {
                  mapFilters = ProductsFiltersService.insertFilter(mapFilters, characteristic, val, visibilityMap.get(characteristic.key));
                }
              });
            } else if (!mapFilters.get(characteristic.key).values.map(value => value.value).includes(characteristic.value)) {
              mapFilters = ProductsFiltersService.insertFilter(mapFilters, characteristic, characteristic.value, visibilityMap.get(characteristic.key));
            }
          } else {
            // Key not present -> adding new key + value
            mapFilters = ProductsFiltersService.insertFilter(mapFilters, characteristic, characteristic.value, visibilityMap.get(characteristic.key));
          }
        });
    });
    return Array.from(mapFilters.values());
  }

  /**
   * get only filter category with a checked value
   * @param {Array<FilterCategory>} filterSelection
   * @returns {Array<FilterCategory>}
   */
  private onlyCheckedFilters(filterSelection: Array<FilterCategory>): Array<FilterCategory> {
    filterSelection.forEach(filter => {
      filter.selected = !!_.find(filter.values, {'checked': true});
    });
    const tempFilters = _.cloneDeep(filterSelection);
    return tempFilters.filter(filtCatg => {
      filtCatg.values = _.filter(filtCatg.values, {'checked': true});
      return filtCatg.values.length > 0;
    });
  }

}
