import {Component} from '@angular/core';
import {SessionStorage} from 'ngx-webstorage';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {NavigationSidePanelItem} from '../../layout/navigation-side-panel/navigation-side-panel.component';

@Component({
  selector: 'app-price-management',
  templateUrl: './price-management.component.html',
  styleUrls: ['./price-management.component.less'],
})

export class PriceManagementComponent {


  @SessionStorage()
  isOnAdministrationScreen = true;


  priceManagementMenuItems: NavigationSidePanelItem[] = [
    {id: 1, title: "T_ADMIN_PUBLIC_PRICE_MANAGEMENT_TITLE", active: true},
    {id: 2, title: "T_ADMIN_QUOTATION_SETUP_TITLE"},
    {id: 3, title: "T_PRICE_MANAGEMENT_NET_PRICE_TTTLE"}
  ];

  updatesLoading = 0;

  public constructor(
    private translateService: TranslateService,
    private messageService: MessageService,) {}

  /**
   * Function called when updates start
   * @param event: number of updates to do
   */
  public netPricesUpdatesStart(numberOfUpdates) {
    this.updatesLoading = numberOfUpdates;
  }

  /**
   * Function called when updates end
   * @param event: number of updates done
   */
  public netPricesUpdatesDone() {
    this.updatesLoading--;
    if (this.updatesLoading === 0) {
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('T_INFO'),
        detail: this.translateService.instant('T_NET_PRICE_UPDATE_SUCCESS'),
      });
    }
  }

  public netPricesUpdatesFail(error: Error) {
    this.updatesLoading = -1;
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('T_ERROR'),
      detail: this.translateService.instant('T_NET_PRICE_UPDATE_FAIL'),
    });
  }


  showTab(id: number) {
    const tab = this.priceManagementMenuItems.find(item => item.id === id);
    return tab && tab.active;
  }
}
