import {Injectable} from '@angular/core';
import {SwitchBoardComponent} from '../model/component';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import {PanelOptionWrapper} from '../model/panelOptionWrapper';

@Injectable()
export class OptionsPanelService {

  private subject = new Subject<SwitchBoardComponent>();
  private infoWrapperSubject = new Subject<PanelOptionWrapper>();

  constructor() {
  }

  setSelectedComponent(component: SwitchBoardComponent) {
    this.subject.next(component);
  }

  getSelectedComponent(): Observable<SwitchBoardComponent> {
    return this.subject.asObservable();
  }

  updateSelectedComponentInfos(selectedComponentInfos: PanelOptionWrapper) {
    this.infoWrapperSubject.next(selectedComponentInfos);
  }

  getSelectedComponentInfos(): Observable<PanelOptionWrapper> {
    return this.infoWrapperSubject.asObservable();
  }
}
