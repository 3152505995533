<div class="sld-container" id="zoomSld">
  <div id="sld-components-wrapper">
    <div class="sld-container-wrapper">
      <div *ngFor="let displayedComponent of displayedComponents, let switchboardPosition = index">
        <app-sld-component-viewer [component]="displayedComponent" [switchboardPosition]="switchboardPosition"
                                  [isSelected]="isSelectedCubicle(switchboardPosition)"
                                  [showCompleteSld]="true" (addTransformerEvent)="addTransformerEvent($event)"
                                  [cubicleSelected]="cubicleSelected"
                                  [selectedFunction]="transformerSelected ? selectedFunction : -1"
                                  [transformerSelected]="transformerSelected"
                                  (selectedComponent)="selectComponent($event)"></app-sld-component-viewer>
      </div>
      <button *ngIf="showAddCubicleIcon()"
              class="btn btn-add-cubicle-comp"
              (click)="addCubicleEvent()" [tooltip]="'T_PROJECT_FIRST_SWITCHBOARD' | translate"
              placement="right">
        <span class="se-icons fa-3x" aria-hidden="true">electricity_polarity_positive_plus</span>
        <p translate>T_ADD_COMPONENT</p>
      </button>
    </div>
    <div class="contractual-box" translate>
      T_SLD_CONTRACTUAL_MESSAGE
    </div>
  </div>
</div>
<div>

  <app-card-component-modal id="componentInfosModal" #componentInfosModal></app-card-component-modal>
</div>
<!-- confirm delete dialog -->
<app-common-modal #deleteModal [name]="'deleteTransformer'" [title]="'T_LOOSE_DATA_TITLE' | translate"
                  [description]="warningDeleteMessage" (onClickYesButton)="removeTransformer()"
                  (onClickNoButton)="deleteModal.hide()"
                  [size]="'modal-sm'">
</app-common-modal>
