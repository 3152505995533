/* Angular modules */
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
/* app modules */
import {Item, Project, ProjectTotalPrice} from '../shared/project-model';
import {UtilService} from '../../shared/util/util.service';
import {ItemService} from '../shared/item.service';
import {BomService} from '../../bom/shared';
import {RightsService} from '../../shared/rights/rights.service';
import {SessionStorage} from 'ngx-webstorage';

@Component({
  selector: 'app-project-bom-button',
  templateUrl: './project-bom-button.component.html',
  styleUrls: ['./project-bom-button.component.less']
})
export class ProjectBomButtonComponent implements  OnInit, OnChanges {

  @Input()
  items: Array<Item>;

  @Input()
  currency: string;

  @Input()
  showPrices: boolean;

  @Output()
  onClickGoToProjectBom = new EventEmitter<void>();

  totalPrice: ProjectTotalPrice;
  formattedProjectPrice: string;

  constructor(private itemService: ItemService,
              private rightService: RightsService,
              private bomService: BomService) {
  }

  ngOnInit(): void {
    this.UpdatePrices();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items != null && !changes.items.firstChange
      && changes.items.currentValue !== changes.items.previousValue) {
      this.totalPrice = this.bomService.getProjectPrice(this.items, this.rightService.canApplyDiscount());
      this.formattedProjectPrice = UtilService.formatPrice(this.totalPrice.price);
    }
  }

  goToProjectBom() {
    this.onClickGoToProjectBom.emit();
  }

  private UpdatePrices() {
    this.totalPrice = this.bomService.getProjectPrice(this.items, this.rightService.canApplyDiscount());
    this.formattedProjectPrice = UtilService.formatPrice(this.totalPrice.price);
  }
}
