import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {SessionStorage, SessionStorageService} from 'ngx-webstorage';
import {Item, Project, ProjectTotalPrice, Status, TotalPriceEnum} from '../../../project/shared/project-model';
import {UtilService} from '../../../shared/util/util.service';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../shared/user/user';
import {ItemService} from '../../../project/shared/item.service';
import {RightsService} from '../../../shared/rights/rights.service';
/* other lib */
import * as _ from 'lodash';
import {BomService} from '../../shared';
import {isNullOrUndefined} from 'util';
import {ProjectService} from '../../../project/shared/project.service';
import {LoggerService} from '../../../shared/logging/logger.service';
import {MessageService} from 'primeng/api';
import {MySEStatusEnum} from '../../../shared/model/mySEStatusEnum';
import {RangeType} from '../../../shared/model/range-type';
import {CommonModalComponent} from '../../../shared/common-modal/common-modal.component';
import {Range} from '../../../shared/model/range-model';

@Component({
  selector: 'app-total-price',
  templateUrl: './total-price.component.html',
  styleUrls: ['./total-price.component.less'],
})
export class TotalPriceComponent implements OnInit, OnChanges {

  @SessionStorage()
  localization;

  @SessionStorage()
  user: User;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  isDemoMode: boolean;

  @Input()
  loadingBom: boolean;

  @Input()
  statusList: Status[];

  @Input()
  currentProjectItems: Item[];

  @Input()
  displayFoNetPrice: boolean;

  @Input()
  ranges: Range[];

  @Input()
  containsNullPublicPrices: boolean;

  @Input()
  containsNullNetPrices: boolean;

  @Output()
  generateBomInfos = new EventEmitter<Item>();

  @Output()
  generateProjectBomInfos = new EventEmitter<Item[]>();

  totalNetPrice: string;

  totalPrice: string;

  discountedPrice: string;

  discountMax: number;

  status: Status;
  userCanApplyDiscount: boolean;
  showToolTipDiscount: boolean;
  discount = 0;
  isMySEUser: boolean;
  keyDiscount: string;
  isDemoUser: boolean;
  itemBomPage: boolean;
  projectBomPage: boolean;
  errorMessage: string;
  errorNetPriceMessage: string;
  onlyTotalNetPrice: boolean;

  @ViewChild('exeedDiscountModal') exeedDiscountModal: CommonModalComponent;

  constructor(private itemService: ItemService,
              private projectService: ProjectService,
              private utilService: UtilService,
              private translateService: TranslateService,
              private messageService: MessageService,
              private rightsService: RightsService,
              private bomService: BomService,
              private sessionStorageService: SessionStorageService,
              private logger: LoggerService) {
  }

  ngOnInit() {
    this.itemBomPage = !isNullOrUndefined(this.currentItemct);
    this.projectBomPage = !isNullOrUndefined(this.statusList);
    this.userCanApplyDiscount = this.rightsService.canApplyDiscount();
    this.isDemoUser = this.rightsService.isActivatedDemo();
    this.isMySEUser = this.rightsService.hasMyseAccount();
    this.showToolTipDiscount = this.displayTooltipForDiscount();
    this.keyDiscount = this.getAdaptedDiscountMessage();
    if (this.itemBomPage) {
      this.getPricesInItemBom();
    }
    if (this.projectBomPage) {
      this.retrieveCurrentProjectItemsByStatus(this.statusList);
      this.getPricesInProjectBom();
    }
    this.onlyTotalNetPrice = this.isMySEUser && this.utilService.displayMySENetPrice() || this.displayFoNetPrice;
  }

  ngOnChanges(): void {
    this.sessionStorageService.observe('currentItemct').subscribe(() => {
      if (!isNullOrUndefined(this.currentItemct)) {
        this.getPricesInItemBom();
      }
    });
    if (this.projectBomPage) {
      this.getPricesInProjectBom();
    }
  }

  public onChangeDiscount(value) {
    const discountLimit = this.getAllowedDiscountLimit();
    if (value > discountLimit || value < 0 || value === '') {
      value = 0;
      this.showExceedDiscountModal();
    }
    this.discount = parseFloat(parseFloat(value).toFixed(2));
    this.applyDiscount();

  }

  /**
   * Allows to store the new discount on item or on project depending the tab
   */
  public applyDiscount() {
    this.loadingBom = true;
    if (this.itemBomPage) {
      this.currentItemct.status = Status.configured;
      this.itemService.updateDiscountItem(this.currentProject.id, this.currentItemct.id, this.discount).subscribe(
        item => {
          this.currentItemct = item;
          this.discountedPrice =  UtilService.formatPrice(this.bomService.countTotalPriceForItem(this.currentItemct, this.isDiscountAllowedForItems()));
          this.loadingBom = false;
        },
        error => {
          this.logger.error(error);
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant('T_APPLICATION_BOM_DISCOUNT_ERROR'),
          });
          if (this.discount !== 0) {
            this.discount = 0;
            this.applyDiscount();
          }
          if (error) {
            this.showExceedDiscountModal();
          }
        },
        () => this.generateBomInfos.emit(this.currentItemct)
      );
    }
    if (this.projectBomPage) {
      this.currentProject.discount = this.discount;
      this.projectService.updateProjectDiscount(this.currentProject.id, this.discount).subscribe(
        () => {
          const projectPrice = this.bomService.countTotalPriceForItems(this.currentProjectItems, TotalPriceEnum.foPublic);
          this.discountedPrice = UtilService.formatPrice(projectPrice.price * (1 - this.currentProject.discount / 100));
          this.itemService.getItems(this.currentProject.id).subscribe(items => {
            this.generateProjectBomInfos.emit(items);
          });
        },
        error => {
          this.logger.error(error);
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant('T_APPLICATION_BOM_DISCOUNT_ERROR'),
          });
        }
      );
    }
  }

  /**
   * Method which return true if we have to display net price
   */
  isValidMySENetPriceFromStatus(): boolean {
    return UtilService.isValidMySEPriceFromStatus(this.currentItemct.mySeNetPriceStatus);
  }

  /**
   * Check if all prices of the project are available on mySE
   * @returns {boolean} true if price come from mySE
   */
  public isAllMysePricesAvailable(): boolean {
    if (this.itemBomPage) {
      return UtilService.isValidMySEPriceFromStatus(this.currentItemct);
    }
    if (this.projectBomPage) {
      return this.utilService.isValidMySENetPriceProject(this.currentProject);
    }
    return false;
  }

  /**
   * Get items of current project having one of wanted status
   * @param {Status[]} statusList wanted status
   * @returns {Item[]} items having one of status on list
   */
  public retrieveCurrentProjectItemsByStatus(statusList: Status[]) {
    this.itemService.getItems(this.currentProject.id).subscribe(items => {
      this.currentProjectItems = this.itemService.getItemsListByStatus(items, statusList);
    });
  }

  /**
   * Retrieves a tooltip for total price  according to mySE status
   * @returns {String} tooltip message
   */
  public getTooltipForMysePriceStatus(): string {
    if (this.currentItemct !== null && this.currentItemct !== undefined) {
      return this.utilService.getTooltipOnTotalMySEMessage( this.currentItemct.mySeNetPriceStatus);
    }
    if (this.currentProjectItems !== null && this.currentProjectItems !== undefined) {
      this.currentProjectItems.forEach((item) => {
        if (item.mySeNetPriceStatus !== MySEStatusEnum.OK) {
          return this.utilService.getTooltipOnTotalMySEMessage(item.mySeNetPriceStatus);
        }
      });
    }
  }

  /**
   * Get the limit of discount allowed
   *
   * @returns {number} Max discount allowed
   */
  getAllowedDiscountLimitField(): number {
    const allowedDiscountLimit = this.getAllowedDiscountLimit();
    if (this.currentItemct.totalPrice.foNetPrice && this.currentItemct.totalPrice.foNetPrice !== 0) {
      const discountLimit =  100 * (1 - (this.currentItemct.totalPrice.foPublicPrice * (allowedDiscountLimit / 100) / this.currentItemct.totalPrice.foNetPrice));
      const discount2Decimals = discountLimit.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      return parseFloat(discount2Decimals);
    } else {
      return allowedDiscountLimit;
    }
  }

  getContainsNullPricesWarningTooltip(){
    return this.translateService.instant('T_BOM_CONTAINS_NULL_PRICES_WARNING');
  }

  /**
   * Set the price and discount in a item Bom
   */
  private getPricesInItemBom() {
    this.discount = this.currentItemct.discount;
    this.totalPrice = UtilService.formatPrice(this.currentItemct.bom.totalPrice);
    this.totalNetPrice = UtilService.formatPrice(this.currentItemct.bom.totalNetPrice);
    this.discountedPrice = UtilService.formatPrice(this.bomService.countTotalPriceForItem(this.currentItemct, this.rightsService.canApplyDiscount()));
    // retrieve the mySE error message
    if (this.isMySEUser && !UtilService.isValidMySEPriceFromStatus(this.currentItemct.mySeNetPriceStatus)) {
      this.errorMessage = this.utilService.getTooltipOnTotalMySEMessage(this.currentItemct.mySeNetPriceStatus);
    }
  }

  /**
   * Displays warning modal if discount is higher than max discount
   */
  private showExceedDiscountModal() {
    this.exeedDiscountModal.name = this.translateService.instant('T_BOM_DISCOUNT');
    this.exeedDiscountModal.description = this.translateService.instant('T_BOM_DISCOUNT_QUESTION' , {maxDiscount: this.getAllowedDiscountLimit()});
    this.exeedDiscountModal.show();
  }

  /**
   * Set the price in the project BOM
   */
  private getPricesInProjectBom() {
    let  calculatedProjectPrice: ProjectTotalPrice;
    if (!this.isMySEUser) {
      calculatedProjectPrice = this.bomService.countTotalPriceForItems(this.currentProjectItems, TotalPriceEnum.foPublic, this.rightsService.canApplyDiscount());
      this.totalPrice = UtilService.formatPrice(calculatedProjectPrice.price);
      this.discountedPrice = this.totalPrice;
      if (this.currentProjectItems.some(item => item.totalPrice.validFoNetPrice)) {
        calculatedProjectPrice = this.bomService.countTotalPriceForItems(this.currentProjectItems, TotalPriceEnum.foNet, this.rightsService.canApplyDiscount());
        this.discountedPrice = UtilService.formatPrice(calculatedProjectPrice.price);
        this.totalNetPrice = UtilService.formatPrice( this.bomService.countTotalNetPriceForItems(this.currentProjectItems));
      }

    } else {
      calculatedProjectPrice = this.bomService.countTotalPriceForItems(this.currentProjectItems, TotalPriceEnum.mySeNet, true);
      this.totalNetPrice = UtilService.formatPrice(calculatedProjectPrice.price);
      this.errorNetPriceMessage = this.bomService.getMessageItemsWithValidNetPrices(this.currentProjectItems);

      if (this.errorNetPriceMessage === '') {
        this.discountedPrice = this.totalNetPrice;
      } else {
        this.discountedPrice = this.totalPrice;
      }
    }

  }


  /**
   * Usr is allowed to discount only configured items
   * If one of items has quoted or ordered status discound is not allowed
   * @param {Item[]} items items of project or current item
   * @returns {boolean} false if ordered or quoted
   */
  private isDiscountAllowedForItems(): boolean {
    let items: Item[] = [];
    if (this.itemBomPage) {
      items.push(this.currentItemct);
    }
    if (this.currentProjectItems) {
      items = items.concat(items, this.currentProjectItems);
    }
    return !isNullOrUndefined(_.find(items, function(item) {
      return (item.status === Status.configured || item.status === Status.quoted);
    }));
  }

  /**
   * Get the limit of discount allowed
   *
   * @returns {number} Max discount allowed
   */
  private getAllowedDiscountLimit(): number {
    let items: Item[] = [];
    if (this.itemBomPage) {
      items.push(this.currentItemct);
    }
    if (this.currentProjectItems) {
      items = items.concat(items, this.currentProjectItems);
    }
    const findItem: Item = items.find(item => this.bomService.getDiscountAllowed(item, this.ranges) > 0);
    return (findItem === null || findItem === undefined) ? 100 : this.bomService.getDiscountAllowed(findItem, this.ranges);
  }


  /**
   * Displays a message on tooltip for discount
   * @returns {boolean} displays or not the tooltip
   */
  private displayTooltipForDiscount(): boolean {
    return this.rightsService.canApplyDiscount();
  }
  private  getAdaptedDiscountMessage(): string {
    return this.rightsService.canApplyDiscount() ? 'T_BOM_TOTAL_DISCOUNT' : 'T_BOM_DISCOUNT';
  }

}
