import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {CommonModalComponent} from "../../common-modal/common-modal.component";

export class OrderManagementItem {
  id: string;
  name: string;
  order: number;
}

@Component({
  selector: 'app-order-management-modal',
  templateUrl: './order-management-modal.component.html',
  styleUrls: ['./order-management-modal.component.scss']
})
export class OrderManagementModalComponent {
  @ViewChild('manageOrderModal', {static: false}) manageOrderModal: CommonModalComponent;

  @Output()
  orderSaved: EventEmitter<OrderManagementItem[]> = new EventEmitter<OrderManagementItem[]>();

  title: string;
  items: OrderManagementItem[];

  show(modalTitle: string, itemsToOrder: OrderManagementItem[]) {
    this.title = modalTitle;
    this.items = itemsToOrder.sort((itemA, itemB) => {
      if (itemA.order) {
        return itemA.order < itemB.order ? -1 : 1;
      } else {
        return 1;
      }
    }).map((item, index) => {
      item.order = index;
      return item;
    })
    this.manageOrderModal.show();
  }

  hide() {
    this.manageOrderModal.hide();
  }

  onRowReorder() {
    this.items = this.items.map((item, index) => {
      item.order = index;
      return item;
    })
  }

  clickOnSave() {
    this.orderSaved.emit(this.items)
  }
}
