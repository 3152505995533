<!-- waiting for the list to be updated -->
<app-spinner *ngIf="!locales" [sizeClass]="'large'"></app-spinner>

<div *ngIf="locales" class="container scrollable scrollSize">
  <tabset #allTabs>
    <tab *ngFor="let locale of locales; let i = index" [active]="i === 0" (selectTab)="!isAddButton(locale) ? onTabChange(locale, $event): showAddLanguageModal()">
      <ng-container *ngTemplateOutlet="localeTemplate; context: {locale: locale}"></ng-container>
      <ng-template tabHeading>
        <div class="title-left-part" *ngIf="!isAddButton(locale)"><span>{{getHeaderName(locale.language)}}</span></div>
        <div class="title-right-part" *ngIf="!isAddButton(locale)">
          <circle-progress [percent]="getTradPercentage(locale)" [radius]="10" [animation]="true"
                           [outerStrokeWidth]="2.5" [titleFontSize]="10" [backgroundStrokeWidth]="-6"
                           [showInnerStroke]="false" [showSubtitle]="false" [showUnits]="false" [outerStrokeColor]="'#c03b65'">
          </circle-progress>
        </div>
        <span *ngIf="isAddButton(locale)" class="se-icons plus-button">electricity_polarity_positive_plus</span>
      </ng-template>
    </tab>
  </tabset>
</div>

<!--Template for one tab panel-->
<ng-template #localeTemplate let-locale="locale">
  <div>

    <!--Buttons and Table datas part-->
    <div>
      <!--Buttons part-->
      <app-translation-button [locale]="locale" [translationLength]="translationLength" [dataTableFilter]="dataTableFilter"
                              (onImportTranslations)="updateLocaleAfterImport()" (isLoadingEvent)="isLoadingEvent($event)">
      </app-translation-button>
      <!--Search part-->
      <div class="filter-search">
        <span class="se-icons search-icon">action_search</span>
        <input type="text" pInputText size="24" placeholder="Global Filter" (input)="dataTableFilter.filterGlobal($event.target.value, 'contains')" style="width:auto">
      </div>
      <!--Table part-->
      <div class="table-margin-bottom">
        <p-table styleClass="express-datatable-style" [value]="locale.translations"  [rows]="15" [paginator]="true" editMode="row" dataKey="key" #dataTableFilter
                 [globalFilterFields]="[cols[0].field, cols[1].field, cols[2].field]" [resizableColumns]="true" [rowHover]="true">
          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let col of cols" [pSortableColumn]="col.field" [ngClass]="col.style"  pResizableColumn scope="col">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            <tr>
              <th *ngFor="let col of cols" scope="col">
                <input *ngIf="col.field !== 'status'" pInputText type="text" [(ngModel)]="col.filterValue" (input)="dataTableFilter.filter($event.target.value, col.field, col.filterMatchMode)">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-editing="editing" let-translation >
            <tr [pEditableRow]="translation">
              <td class = "key-column">{{translation.key}}</td>
              <td class = "translation-column">{{translation.defaultTranslation}}</td>
              <td class = "translation-column" pEditableColumn >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="translation.translation" (change)="translationChange(locale, translation)"
                           (keydown.enter)="translationChange(locale, translation)" >
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{translation.translation}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td class = "status-column">
                <em *ngIf="translation?.status==='OK'" class="fa fa-check ok-status"></em>
                <em *ngIf="translation?.status==='MISSING'" class="fa fa-times missing-status"></em>
                <em *ngIf="translation?.status==='EDITED'" class="fa fa-repeat edited-status"></em>
              </td>
            </tr>
          </ng-template>

        </p-table>
      </div>
    </div>
  </div>
</ng-template>

<!-- Add language modal -->
<app-common-modal #addLanguageModal [name]="'addInfo'" [withFooter]="false" [title]="'T_BO_ADD_LANGUAGE_MODAL_TITLE' | translate">
  <div modal-body class="form-horizontal">
    <app-translation-modal [countryName]="countryNameCode"
                           [languages]="allLanguages"
                           (onClickSkipButton)="addLanguageModal.hide()"
                           (onClickValidateButton)="addNewLanguage()"
                           (onLanguageChange)="setLanguageCode($event)">
    </app-translation-modal>
  </div>
</app-common-modal>

<!--Add spinner when loading data after adding new language, import and export translations-->
<div *ngIf="isLoading">
  <div class="transparency-div"></div>
  <app-spinner class="loader" [sizeClass]="'large'"></app-spinner>
</div>


