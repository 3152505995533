import {Injectable} from '@angular/core';
import {SortingValues, Translation, TranslationStatusEnum} from '../../../shared/model/localization.model';
import {isNullOrUndefined} from 'util';

@Injectable()
export class TranslationHelperService {

  constructor() {}

  mapperDataToUI(locale, translationKeys) {
    // manage locale status
    this.setLocaleStatus(locale);
    // build the translation data with a translate and no translate keys
    translationKeys.forEach(translationKey => {
      const translation = locale.translations.find(trad => translationKey.key === trad.key);

      if (!isNullOrUndefined(translation)) {
        // add existing locale keys
        const sortingValues = new SortingValues();
        sortingValues.type = translationKey.type;
        sortingValues.key = translationKey.key;
        translation.sortingValue = sortingValues;
        translation.type = translationKey.type;
        translation.defaultTranslation = translationKey.defaultTranslation;
        translation.description = translationKey.description;
        if (translationKey.defaultTranslation !== null && translationKey.defaultTranslation.length > 0) {
          translation.status = TranslationStatusEnum.OK;
        }
        if (isNullOrUndefined(translation.translation) || translation.translation.length === 0) {
          translation.status = TranslationStatusEnum.MISSING;
        }
      } else {
        // add no existing locale keys
        const noTranslate = new Translation();
        const sortingValues = new SortingValues();
        sortingValues.type = translationKey.type;
        sortingValues.key = translationKey.key;
        noTranslate.sortingValue = sortingValues;
        noTranslate.key = translationKey.key;
        noTranslate.type = translationKey.type;
        noTranslate.defaultTranslation = translationKey.defaultTranslation;
        noTranslate.description = translationKey.description;
        noTranslate.status = TranslationStatusEnum.MISSING;
        locale.translations.push(noTranslate);
      }
    });
  }

  getTradPercentage(translations, keyNumber): number {
    // calculate translation coverage percentage
    let translateNumber = 0;
    if (!isNullOrUndefined(translations) && translations.length > 0) {
      translations.find(translation => {
        if (!isNullOrUndefined(translation.translation) && translation.translation.length > 0) {
          translateNumber = translateNumber + 1;
        }
      });
    }
    return translateNumber * 100 / keyNumber;
  }

  private setLocaleStatus (locale) {
    locale.hasBomApplicationChecked = false;
    locale.hasApplicationBomSelected = false;
    locale.selectedFilters = [];
    if (locale.translations !== null && locale.translations.length > 0 && this.hasKeyTranslate(locale.translations)) {
      locale.okStatus = true;
      locale.missingStatus = false;
      locale.translations.find(function (translation) {
        if (translation.defaultTranslation === undefined) {
          return locale.missingStatus = true;
        }
      });
      locale.editedStatus = false;
    } else {
      locale.okStatus = false;
      locale.missingStatus = true;
      locale.editedStatus = false;
    }
    locale.selectedStatus = [TranslationStatusEnum.OK, TranslationStatusEnum.MISSING, TranslationStatusEnum.EDITED];
  }

  private hasKeyTranslate(translations) {
    let hasTranslate = false;
    translations.find(translation => {
      if (!isNullOrUndefined(translation.translation) && translation.translation.length > 0) {
        hasTranslate = true;
      }
    });
    return hasTranslate;
  }

}
