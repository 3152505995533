<div>
  <div class="category-title">
      <h3 translate>{{category.nameKey}}</h3>
      <button class="action-button se-icon" (click)="editName()">action_editor</button>
      <button class="action-button se-icon" (click)="deleteCategory()">action_delete</button>
      <button (click)="onClickOpenCategoryButton()" class="se-icon category-open-button">{{isExpanded ? "arrow2_down" : "arrow2_right"}}</button>
  </div>

  <div cdkDropList *ngIf="isExpanded" (cdkDropListDropped)="onDragAndDropAccessory(category.id, $event)">
    <div cdkDrag *ngFor="let accessory of getAccessoriesByCategory()">
      <app-odm-manage-accessory [accessory]="accessory"
                                [isExpanded]="isExpanded && (accessory.id === activeAccessory)"
                                (clickEditAccessoryButton)="onClickEditAccessoryButton($event)"
                                (clickDeleteAccessoryButton)="onClickDeleteAccessoryButton($event)"
                                (clickEditAccessoryValueButton)="onClickEditAccessoryValueButton($event)"
                                (clickDeleteAccessoryValueButton)="onClickDeleteAccessoryValueButton($event)"
                                (clickMoveDownAccessoryValueEvent)="onClickMoveDownAccessoryValue($event)"
                                (clickMoveUpAccessoryValueEvent)="onClickMoveUpAccessoryValue($event)"
      >
      </app-odm-manage-accessory>
    </div>
  </div>
</div>

<app-odm-modal-category-name #changeNameModal (onConfirmButton)="setCategoryName($event)" [categoryNameKey]="category.nameKey" ></app-odm-modal-category-name>

<app-common-modal #deleteModal [name]="'deleteModal'" (onClickYesButton)="confirmDialog()"
                  [description]="'T_DELETE_CATEGORY_MESSAGE' | translate" >
</app-common-modal>

<app-common-modal #cannotDeleteModal [name]="'cannotDeleteModal'" [twoButtonsDialogs]="false"
                   [description]="'T_DELETE_CATEGORY_IMPOSSIBLE_MESSAGE' | translate" >
</app-common-modal>

