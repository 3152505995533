<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">

      <div class="modal-header ">
        <img class="logo" src="assets/images/ecoStruxure_logo_white.png"/>
        <div class="application-name">Power <span class="font-sub">Build</span></div>
      </div>
      <div class="modal-wrapper">
        <div class="modal-body">
          <div *ngIf="isExtendedDemo()">
            <span class="title" [innerHtml]="'T_APPLICATION_TERMS_OF_USE_EXTENDED_DEMO_TITLE' | translate"></span>
          </div>
          <div *ngIf="isExtendedDemo()">
            <span class="message" [innerHtml]="'T_APPLICATION_TERMS_OF_USE_EXTENDED_DEMO_MESSAGE' | translate"></span>
            <span class="message"> {{getContactEmail()}}</span>
          </div>
          <div *ngIf="!isExtendedDemo()">
            <span class="title" [innerHtml]="'T_APPLICATION_TERMS_OF_USE_TITLE' | translate"></span>
          </div>
          <div *ngIf="!isExtendedDemo()">
            <span class="message" [innerHtml]="'T_APPLICATION_TERMS_OF_USE_MESSAGE' | translate"></span>
          </div>
          <div class="footer-message">
            <div class=" checkbox">
              <input [disabled]="!user || user.termsOfUseAccepted" type="checkbox" id="check" class="check-box"
                     [(ngModel)]="accepted">
              <label for="check"><span class="accept-text align-right" translate>T_APPLICATION_TERMS_OF_USE_CONDITION</span></label>
            </div>
          </div>
          <div class="footer-buttons">
            <div>
              <a class="btn btn-terms-of-use btn-default-white-background" (click)="logout()" translate>T_BUTTON_LOGOUT</a>
            </div>
            <div>
              <button [disabled]="!accepted" class="btn btn-terms-of-use btn-login" (click)="clickAcceptButton(); hide()" translate>T_APPLICATION_TERMS_OF_USE_REACH</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <a href="{{ getUrl() }}" target="_blank" translate>T_TERMS_OF_USE</a>
      </div>
    </div>
  </div>
</div>
