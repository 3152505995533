import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {CommonModalComponent} from '../../../../shared/common-modal/common-modal.component';
import {AccessoryCategory, AccessoryCharacteristics} from '../../../../shared/model/accessory.model';
import {Range} from '../../../../shared/model/range-model';
import {TranslateService} from '@ngx-translate/core';
import {TranslationService} from '../../../shared';

export type EditAccessoryForm = {
  name: {
    [key: string]: string;
  };
  description: {
    [key: string]: string;
  };
  htmlElementType: 'select'|'multiSelect';
  categoryId: string;
  rangeId?: string;
  accessoryId?: string;
  required?: boolean;
  defaultValue?: string;
}

@Component({
  selector: 'app-odm-edit-accessory-modal',
  templateUrl: './odm-edit-accessory-modal.component.html',
  styleUrls: ['./odm-edit-accessory-modal.component.less']
})
export class OdmEditAccessoryModalComponent {

  @Output()
  saveAccessory: EventEmitter<EditAccessoryForm> = new EventEmitter<EditAccessoryForm>();

  @ViewChild('editAccessoryModal', {static: false}) editAccessoryModal: CommonModalComponent;


  range: Range;
  category: AccessoryCategory;
  languages: string[];
  editAccessoryForm: EditAccessoryForm = {name: {}, description: {}, categoryId: '', rangeId: '', htmlElementType: 'select'};
  elementTypeOptions = [{name: this.translateService.instant("T_ACCESSORY_HTML_TYPE_SELECT_BUTTON"), code: 'select'}, {name: this.translateService.instant("T_ACCESSORY_HTML_TYPE_MULTISELECT_BUTTON"), code: 'multiSelect'}]
  availableCategories: any[];
  accessoryValues: any[];

  constructor(private translationService: TranslationService, private translateService: TranslateService) {
  }

  showAddMode(range: Range, categories: AccessoryCategory[], languages: string[]){
    this.availableCategories = categories;
    this.availableCategories.forEach(category => {
      category.nameTranslated = this.translateService.instant(category.nameKey);
    });
    this.range = range;
    this.languages = languages;
    this.editAccessoryForm.rangeId = range.id;
    this.languages.forEach(lang => {
      this.editAccessoryForm.name[lang] = null;
      this.editAccessoryForm.description[lang] = null;
    })
    this.editAccessoryModal.show();
  }

  showEditMode(accessory: AccessoryCharacteristics, categories: AccessoryCategory[]){
    this.availableCategories = categories;
    this.availableCategories.forEach(category => {
      category.nameTranslated = this.translateService.instant(category.nameKey);
    });

    this.editAccessoryForm.categoryId = accessory.category;
    this.editAccessoryForm.htmlElementType = accessory.htmlElementType;
    this.editAccessoryForm.accessoryId = accessory.id;
    this.editAccessoryForm.required = accessory.required;
    this.editAccessoryForm.defaultValue = accessory.defaultValue;
    this.accessoryValues = accessory.values;
    this.accessoryValues.forEach(value => value.nameTranslated = this.getTranslation(value.value));

    this.translationService.getLocalesOfCountryOfKey(accessory.name).subscribe((locale => {
      locale.forEach(loc => {
        this.editAccessoryForm.name[loc.language] = loc.translations[0].translation;
      })

    }));
    this.translationService.getLocalesOfCountryOfKey(accessory.description).subscribe(locale => {
      locale.forEach(loc => {
        this.editAccessoryForm.description[loc.language] = loc.translations[0].translation;
      })
    });

    this.editAccessoryModal.show();
  }

  hide() {
    this.editAccessoryModal.hide();
  }

  isValidForm(): boolean {
    if(!this.editAccessoryForm.htmlElementType
      || !this.editAccessoryForm.categoryId){
      return false;
    }
    for (let key in this.editAccessoryForm.name) {
      if(!this.editAccessoryForm.name[key]){
        return false;
      }
    }
    for (let key in this.editAccessoryForm.description) {
      if(!this.editAccessoryForm.description[key]){
        return false;
      }
    }

    return true;
  }

  clickOnSaveAccessory() {
    this.saveAccessory.emit(this.editAccessoryForm);
  }

  // function for the ngfor + ngmodel, so it doesn't lose focus
  trackByFn(index, item): number {   return index; }

  enableDefaultValueManagement(){
    return this.editAccessoryForm.htmlElementType == 'select' && this.accessoryValues && this.accessoryValues.length > 0;
  }

  getTranslation(key: string){
    return this.translateService.instant(key);
  }
}
