export const environment = {
  production: true,
  threshold: 'info',
  feedbackCollector: false,
  frontendVersion: '@frontend.version@',
  backendLoggerUrl: '/frontLog/logToServer' ,
  envType: 'PROD',
  showUnknowTranslations: true,
  // Backend access
  baseUri:"https://ecostruxure-power-build-contractors.se.app/services/rest",
  // Application configuration
  ecorealToken:"SSO_PBCT2",
  platform: "ECOREALExpressPRD2",
  appBaseHref: "/ecorealct",

  // IDMS authentication
  authenticationSkipped: false,
  idmsAuthorizeUrl:"https://secureidentity.schneider-electric.com/identity/services/apexrest/App/EcorealExpress/services/oauth2/authorize",
  idmsClientId:"3MVG9yZ.WNe6byQAvlJ.HwD94PYFgc6.IfGcz0ip_p5mdh0m__rasWScDHwYI8z3x39MttEPwD_KdktZSAZft",
  idmsRedirectUri:"https://ecostruxure-power-build-contractors.se.app/ecorealct/connectIdms",
  idmsDisconnectionUrl:"https://secureidentity.schneider-electric.com/identity/VFP_IDMS_IDPSloInit?app=EcorealExpress",
  idmsDisconnectionRedirectionUrl:"https://ecostruxure-power-build-contractors.se.app/ecorealct/",
  // Comtool
  comToolFrontendHost: "https://ecostruxure-power-build-contractors.se.app",
  // Package
  packageActivation:"ES,GB,DE,BE,CH,PT,AU,NL,EG",
  // Feedback
  feedbackDelay: 10,
  // MySE
  mySENetPrice: true,
  // CPQ
  cpqCookieQuoteId:"CPQ_DEFAULT_ID",
  cpqRedirectUrlCookie:"CPQ_REDIRECT_URL",
  cpqCookieProjectId:"CPQ_PROJECT_ID",
  cpqCookieDocumentId:"CPQ_DOCUMENT_ID",
  cpqParameterQuoteId:"transactionId",
  cpqParameterProcessVar:"processVarName",
  cpqParameterConfigId:"ConfigID",
  cpqParameterOriginalConfigId:"OriginalConfigID",
  cpqParameterDocumentId:"DocumentID",
  cpqParameterBaseUrl:"baseUrl",
  cpqRedirectUrlEnd:"/connectCpq"
};
