<!-- begin project-->
<div *ngIf="(!isDemoUser() && !isDeactivatedDemoUser()) || (isDemoUser() && isDemoMode)" #scrollMe class="container-fluid section-margin tab-margin">

    <!-- Links -->
    <tabset #navTabs>
      <tab *ngFor="let tab of projectTabs" heading="{{tab | translate}}" (selectTab)="changeProjectList($event)" customClass="tab-style">

        <app-spinner *ngIf="loading" [sizeClass]="'large'">
          <div class="loadingMessage">{{loadingMessage | translate}}</div>
        </app-spinner>

        <div>
          <!--projects filtering-->
          <app-project-filter *ngIf="!loading && onMyLastProjectsTab"
                              [hasProjectLength]="projects?.length"
                              [loadedProjectCount]="loadedProjectCount"
                              [statusFilters]="statusFilters"
                              [disableFilters]="disableFilters"
                              [hasNoProject]="!projects?.length && onMyLastProjectsTab || !sharedProjects?.length && !onMyLastProjectsTab"
                              [resultFiltredProjects]="resultFiltredProjects"
                              [onMyLastProjectsTab]="onMyLastProjectsTab"
                              (filterInputKeyUp)="updateFromSearchFilter($event)"
                              (updateListStatusFilter)="updateListStatusFilter($event)"
                              (createAProject)="addProjectAction($event)">
          </app-project-filter>
        </div>

        <!-- projects list -->
        <app-project-list *ngIf="!loading && isSelectedTab(tab)"
                          [displayedProjects]="displayedProjects"
                          [resultFiltredProjects]="resultFiltredProjects"
                          [onMyLastProjectsTab]="onMyLastProjectsTab"
                          [deleteItemEvent]="deleteItemSubject.asObservable()"
                          (onClickShowModal)="showModal($event)"
                          (onPageChanged)="pageChanged($event)"></app-project-list>

        <div class="text-center">
          <button *ngIf="showLoadMoreProjectsButton(tab)" class="btn btn-toggle-white-background" (click)="loadProjects(0)" translate>T_LOAD_ALL_PROJECTS_BUTTON</button>
        </div>
      </tab>
      <tab *ngIf="true" heading="{{'T_PROJECT_NEWS_TAB'| translate}}" (selectTab)="refreshUserCheckNews($event)" customClass="{{headerNewsTabStyle}}"
           [active]="this.router.url === '/news'">
        <div *ngIf="!loadingNews"  class="panel-group">
          <!-- LIST OF NEWS -->
          <ng-container *ngIf="news.length > 0">
            <div *ngFor="let newsCard of news">
              <app-news-notifications-card [newsCard]="newsCard" [type]="'news'">
              </app-news-notifications-card>
            </div>
          </ng-container>
          <h2 *ngIf="news.length === 0" translate>T_PROJECTS_NO_NEWS_TAB</h2>
        </div>
        <app-spinner *ngIf="loadingNews" [sizeClass]="'large'">
          <div class="loadingMessage" translate>T_LOADING_NEWS</div>
        </app-spinner>
      </tab>
    </tabset>
</div>
<!-- end project-->

<!--demo version home message-->
<div *ngIf="(isDemoUser() && !isDemoMode) || isDeactivatedDemoUser()" >
  <app-demo-page [localization]="localization"></app-demo-page>
</div>

<!-- confirm dialog -->
<app-common-modal #childModal [name]="'childModal'" (onClickYesButton)="confirmDialog($event)"
                  [size]="childModal.name!=='deleteItem'?'modal-sm':''">
</app-common-modal>

<!-- terms of use dialog -->
<app-cpq-project-choice-modal #cpqChoiceModal (onCreateStandardProject)="addProject()"
                              (onDuplicateCpqProjectToStandard)="duplicateProject()"
                              (onCreateCpqProject)="addProject($event)"
                              (onDuplicateCpqProject)="duplicateProject($event)"
                              (onAssociateCpqProject)="associateProject($event)">
</app-cpq-project-choice-modal>

