export class Service {
  reference: string;
  category: string;
  description?: string;
  price?: number;
  mandatory?: boolean;
  editableQuantity?: boolean;
}

export class ExistingService extends Service {
  range: string;
}
