import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {PackageOfferGroup, PackageOfferSelect, PackageOffersGroupSelect} from './package.model';
import {Pair} from "../../../shared/util/pair";
import {AccessoryCategory} from "../../../shared/model/accessory.model";
import {LoggerService} from "../../../shared/logging/logger.service";

@Injectable()
export class PackageOfferGroupService {

  constructor(private readonly httpClient: HttpClient,
              private logger: LoggerService) {
  }
  getPackageOfferGroupById(packageOfferGroupId: string): Observable<PackageOfferGroup> {
    return this.httpClient.get<PackageOfferSelect>('/package-offer-group/' + packageOfferGroupId);
  }

  getOffersSelectByGroupId(packageGroupId): Observable<PackageOffersGroupSelect> {
    return this.httpClient.get<PackageOffersGroupSelect>('/package-offer-group/offersGroupSelect/' + packageGroupId);
  }

  getAll(): Observable<PackageOfferGroup[]> {
    return this.httpClient.get<PackageOfferGroup[]>('/package-offer-group');
  }
  addGroup(translationByLanguage:  Pair<string, string>[]): Observable<AccessoryCategory> {
    this.logger.debug('PackageOfferGroupService addGroup()');
    const payload = [];
    translationByLanguage.forEach(t => payload.push({first: t.getKey(), second:t.getValue()}));
    return this.httpClient.post<AccessoryCategory>('/package-offer-group/', payload);
  }

  delete(groupId: string): Observable<any>{
    return this.httpClient.delete<any>('/package-offer-group/'+groupId);
  }

  updateOrder(idOrderPairs:  {first: string, second:number}[]){
    return this.httpClient.put<any>('/package-offer-group/updateOrder/', idOrderPairs);
  }
}
