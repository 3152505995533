import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../shared/auth/auth.service";
import {SessionStorage} from "ngx-webstorage";
import {ProjectService} from "../../project/shared/project.service";
import {LoggerService} from "../../shared/logging/logger.service";

@Component({
  selector: 'app-connect-cpq',
  templateUrl: './connect-cpq.component.html',
  styleUrls: ['./connect-cpq.component.scss']
})
export class ConnectCpqComponent implements OnInit {

  @SessionStorage()
  cpqQuoteId;

  @SessionStorage()
  cpqRedirectURL;

  @SessionStorage()
  cpqProjectToDuplicate;

  @SessionStorage()
  cpqParameterDocumentId;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private projectService: ProjectService,
              private logger: LoggerService) { }

  ngOnInit(): void {
    let cpqDefaultId: string = this.route.snapshot.queryParamMap.get(environment.cpqParameterQuoteId);
    let processVarName: string = this.route.snapshot.queryParamMap.get(environment.cpqParameterProcessVar);
    let baseUrl: string = this.route.snapshot.queryParamMap.get(environment.cpqParameterBaseUrl);
    let cpqProjectToDuplicate: string = this.route.snapshot.queryParamMap.get(environment.cpqParameterConfigId);
    let documentId: string = this.route.snapshot.queryParamMap.get(environment.cpqParameterDocumentId);
    let quoteIdBaseRedirectURL: string = "https://" + baseUrl + "/commerce/transaction/" + processVarName + "/";

    if (cpqDefaultId !== null && cpqDefaultId !== '') {
      //We create CPQ id cookie if exists
      this.cpqQuoteId = cpqDefaultId
      //We store CPQ redirect URL in cookie
      this.cpqRedirectURL = quoteIdBaseRedirectURL;
      //Duplicate requested project
      this.cpqProjectToDuplicate = cpqProjectToDuplicate;
      //We store documentId for copy by document
      this.cpqParameterDocumentId = documentId;
    }

    if (this.cpqQuoteId && this.cpqProjectToDuplicate) {
      this.projectService.cloneProject(this.cpqProjectToDuplicate, this.cpqQuoteId, this.cpqParameterDocumentId, true)
        .subscribe(project => this.logger.debug('Project duplicated on CPQ user arrival', project),
          error => this.logger.error('Error on project duplication on CPQ user arrival', error));
    }
    // we get the user token from the request
    let userAccessToken: string = localStorage.getItem(environment.ecorealToken);

    this.authService.saveToken(userAccessToken);
    this.router.navigateByUrl('/projects');
  }

  public returnCpq () {
    window.location.href = this.cpqRedirectURL + '/' + this.cpqQuoteId;
  }
}
