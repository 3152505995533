import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Range} from '../../../../../shared/model/range-model';
import {OfferDataManagementService} from '../../../offer-data-management.service';
import {AccessoriesService} from '../../../../../shared/services/accessories.service';

@Component({
  selector: 'app-odm-add-offer-references',
  templateUrl: './odm-add-offer-references.component.html',
  styleUrls: ['./odm-add-offer-references.component.less']
})
export class OdmAddOfferReferencesComponent implements OnInit {

  @Input()
  selectedRange: Range;

  @Output()
  rangeHasReference: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  rangeHasAccessory: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  rangeHasBeenUpdated: EventEmitter<Range> = new EventEmitter<Range>();

  constructor(private readonly offerDataManagementService: OfferDataManagementService,
              private accessoriesService: AccessoriesService) { }

  ngOnInit() {
    this.allComponentReferences();
  }

  /**
   * Check the list of references for each addition
   */
  addReference() {
    this.allComponentReferences();
  }

  /**
   * Get all components and accessories for the selected range
   */
  private allComponentReferences() {
    // components
    this.offerDataManagementService.getComponentsReferences(this.selectedRange.id)
      .subscribe(components => {
        if (components && components.length > 0) {
          this.rangeHasReference.emit(true);
        } else {
          this.rangeHasReference.emit(false);
        }
      });
    // accessories
    this.accessoriesService.getAccessories(this.selectedRange.id)
      .subscribe(accessories => {
        if (accessories && accessories.length > 0) {
          this.rangeHasAccessory.emit();
        }
      });
  }
}
