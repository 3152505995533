
<div *ngIf="!isLoading" class="container scrollable scrollSize">

  <h2 translate>T_DISTRIBUTOR_TITLE</h2>

  <div>
    <div class="addButton">
      <button type="button" class="btn btn-group btn-primary button-div" (click)="addADistributor()" translate>
        T_ADD_DISTRIBUTOR
      </button>
    </div>
  </div>
  <p-table #tt styleClass="express-datatable-style" [value]="distributors" [rows]="10" [paginator]="true" expandableRows="true"
           [globalFilterFields]="[cols[0].field, cols[1].field, cols[2].field, cols[3].field, cols[4].field, cols[5].field, cols[6].field]">
    <ng-template pTemplate="caption">
      <div>
        <div style="text-align: left">
          <em class="fa fa-search" style="margin:4px 4px 0 0"></em>
          <input type="text" pInputText size="50" placeholder="Global Filter"
                 (input)="tt.filterGlobal($event.target.value, 'contains')" style="width:auto">
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="8" translate>T_NO_DISTRIBUTOR_FOUND</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols"  [pSortableColumn]="col.field" [ngClass]="col.style" scope="col">
          {{col.header}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      <tr>
        <th *ngFor="let col of cols" [ngSwitch]="col.field" scope="col">
          <input *ngIf="col.field !== 'actions'" pInputText type="text" (input)="tt.filter($event.target.value, col.field, 'contains')">
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-distributor>
      <tr>
        <td>{{distributor.name}}</td>
        <td>{{distributor.region}}</td>
        <td>{{distributor.contactPerson}}</td>
        <td>{{distributor.phone}}</td>
        <td>{{distributor.email}}</td>
        <td>{{distributor.v2Contact}}</td>
        <td>{{distributor.salesContact}}</td>
        <td>
          <a class="fa fa-pencil margin" (click)="updateDistributor(distributor)"></a>
          <a class="fa fa-times margin" (click)="askDeleteDistributor(distributor)"></a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<!-- wainting for the list to be updated -->
<app-spinner *ngIf="isLoading" [sizeClass]="'large'"></app-spinner>

<!-- confirm dialog -->
<app-common-modal #deleteModal [title]="'T_DELETE_DISTRIBUTOR_TITLE' | translate" [name]="'T_DELETE_DISTRIBUTOR_TITLE'"
                  [description]="'T_DELETE_DISTRIBUTOR_WARNING' | translate" (onClickYesButton)="deleteDistributor()" [size]="'modal-default'">
</app-common-modal>


<!-- Creation/Update modal -->
<app-common-modal #createModal [name]="'createInfo'" [withFooter]="false" [title]="getTitleModal()" size="modal-lg">
  <div modal-body class="form-horizontal">
    <app-distributor-form [allRegions]="getAllRegion()"
                          (onClickSkipButton)="createModal.hide()"
                          (onClickValidateButton)="addorUpdateDistributorCreated()">
    </app-distributor-form>
  </div>
</app-common-modal>
