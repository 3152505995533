import {Component, OnInit, ViewChild} from '@angular/core';
import {SessionStorage} from 'ngx-webstorage';
import {LoggerService} from '../../shared/logging/logger.service';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {Distributor} from '../../bom/shared/distributor';
import {DistributorService} from '../shared/distributor/distributor.service';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-distributor',
  templateUrl: './distributor.component.html',
  styleUrls: ['./distributor.component.less'],
})

export class DistributorComponent implements OnInit {

  @ViewChild('deleteModal') deleteModal: CommonModalComponent;
  @ViewChild('createModal') createModal: CommonModalComponent;

  @SessionStorage()
  isOnAdministrationScreen = true;

  @SessionStorage()
  localization;

  distributors: Distributor[];
  isLoading = true;

  @SessionStorage()
  distributorSelected: Distributor;

  previousDistributor: Distributor;

  @SessionStorage()
  invalidFields = new Array<string>();

  isUpdate = false;

  cols: any[];

  constructor(private readonly logger: LoggerService,
              private readonly distributorService: DistributorService,
              private readonly translateService: TranslateService,
              private messageService: MessageService) {
  }

  ngOnInit() {
    this.distributorService.getDistributors().subscribe(
      distributors => this.distributors = distributors,
      null,
      () => this.isLoading = false
    );
    this.cols = [
      { field: 'name', header: (this.translateService.instant('T_DISTRIBUTOR_NAME')) },
      { field: 'region', header: (this.translateService.instant('T_DISTRIBUTOR_REGION'))  },
      { field: 'contactPerson', header: (this.translateService.instant('T_DISTRIBUTOR_CONTACT_PERSON'))},
      { field: 'phone', header: (this.translateService.instant('T_DISTRIBUTOR_PHONE'))  },
      { field: 'email', header: (this.translateService.instant('T_DISTRIBUTOR_EMAIL')),  style: 'email-column'},
      { field: 'v2Contact', header: (this.translateService.instant('T_DISTRIBUTOR_V2_CONTACT'))  },
      { field: 'salesContact', header: (this.translateService.instant('T_DISTRIBUTOR_SALES_CONTACT'))  },
      { field: 'actions', header: (this.translateService.instant('T_DISTRIBUTOR_ACTIONS')), style: 'col-icon' },
    ];
  }

  askDeleteDistributor(distributor) {
    this.distributorSelected = distributor;
    this.deleteModal.show();
  }

  deleteDistributor() {
    this.distributorService.deleteDistributor(this.distributorSelected.id).subscribe(
      isSuccess => {
        this.distributors = this.distributors.filter(distributor => distributor.id !== this.distributorSelected.id);
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_DELETE_DISTRIBUTOR_SUCCESS_MESSAGE'),
        });
      },
      error => {
        this.logger.error(error);
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_DELETE_DISTRIBUTOR_ERROR_MESSAGE'),
        });
      }
    );
  }

  public addADistributor() {
    this.isUpdate = false;
    this.distributorSelected = new Distributor();
    this.invalidFields = new Array<string>();
    this.createModal.show();
  }

  public addorUpdateDistributorCreated() {
    this.createModal.hide();
    if (this.isUpdate) {
      this.distributorService.updateDistributor(this.distributorSelected).subscribe(
        distributor => {
          this.copyFromDistributor(this.previousDistributor, this.distributorSelected);
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('T_INFO'),
            detail: this.translateService.instant('T_UPDATE_DISTRIBUTOR_SUCCESS_MESSAGE'),
          });
        },
        error => {
          this.distributorService.getDistributors().subscribe(
            distributors => this.distributors = distributors
          );
          this.logger.error(error);
          let errorMessage = 'T_UPDATE_DISTRIBUTOR_ERROR_MESSAGE';
          if (error != null && error._body != null && JSON.parse(error._body).message != null) {
            errorMessage = JSON.parse(error._body).message;
          }
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant(errorMessage),
          });
        }
      );
    } else {
      this.distributorService.createDistributor(this.distributorSelected).subscribe(
        distributor => {
          this.distributors = [...this.distributors, distributor]; // We add the distributor
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('T_INFO'),
            detail: this.translateService.instant('T_ADD_DISTRIBUTOR_SUCCESS_MESSAGE'),
          });
        },
        error => {
          this.logger.error(error);
          let errorMessage = 'T_ADD_DISTRIBUTOR_ERROR_MESSAGE';
          if (error != null && error._body != null && JSON.parse(error._body).message != null) {
            errorMessage = JSON.parse(error._body).message;
          }
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant(errorMessage),
          });
        }
      );
    }
  }

  public updateDistributor(distributor: Distributor) {
    this.isUpdate = true;
    this.distributorSelected = JSON.parse(JSON.stringify(distributor));
    this.previousDistributor = distributor;
    this.invalidFields = new Array<string>();
    this.createModal.show();
  }

  public getTitleModal(): string {
    if (this.isUpdate) {
      return this.translateService.instant('T_UPDATE_DISTRIBUTOR_TITLE');
    } else {
      return this.translateService.instant('T_ADD_DISTRIBUTOR_TITLE');
    }
  }

  public getAllRegion(): Set<string> {
    const allRegions = new Set<string>();
    if (!isNullOrUndefined(this.distributors)) {
      this.distributors.forEach(
        distributor => {
          if (!isNullOrUndefined(distributor.region)) {
            allRegions.add(distributor.region);
          }
        }
      );
    }
    return allRegions;
  }



  private copyFromDistributor(distributorToChange: Distributor, distributor: Distributor) {
    distributorToChange.id = distributor.id;
    distributorToChange.countryName = distributor.countryName;
    distributorToChange.region = distributor.region;
    distributorToChange.name = distributor.name;
    distributorToChange.phone = distributor.phone;
    distributorToChange.email = distributor.email;
    distributorToChange.contactPerson = distributor.contactPerson;
    distributorToChange.v2Contact = distributor.v2Contact;
    distributorToChange.salesContact = distributor.salesContact;
  }
}
