import {RatingFeedback} from './ratingFeedback';

/**
 * used to comunicate with the monitoring
 */
export class FeedbackRecord {
  rating: RatingFeedback;
  comment: string;
  page: string;
  acceptContacted: boolean;

  constructor(rating: RatingFeedback, comment: string, page: string, acceptContacted: boolean) {
    this.rating = rating;
    this.comment = comment;
    this.page = page;
    this.acceptContacted = acceptContacted;
  }
}
