<div *ngIf="selectedComponent" class="right-panel scrollable">
  <button id="btn-close-parameter" type="button" class="modal-close pull-right deleteItem"
          aria-label="Close" (click)="closeParameters()">
    <span class="fa-stack">
      <span class="se-icons icon se-close-cross-style">test_results_nok</span>
    </span>
  </button>


  <div class="toolbox-right-side">
    <button *ngIf="showDeleteIcon()" (click)="moveCubicle('left')" type="button" tooltip="{{'T_MOVE_CUBICLE_LEFT_BUTTON_TOOLTIP' | translate}}" placement="bottom"
            class="btn tool-box-right">
      <span class="se-icons font-param tool-icon">arrow2_left</span>
    </button>
    <button *ngIf="showDeleteIcon()" (click)="moveCubicle('right')" type="button" tooltip="{{'T_MOVE_CUBICLE_RIGHT_BUTTON_TOOLTIP' | translate}}" placement="bottom"
            class="btn tool-box-right">
      <span class="se-icons font-param tool-icon">arrow2_right</span>
    </button>
    <button *ngIf="showDeleteIcon()" (click)="fireDeleteModal()" type="button" tooltip="{{'T_DELETE_CUBICLE_BUTTON_TOOLTIP' | translate}}" placement="bottom"
            class="btn tool-box-right">
      <span class="se-icons font-param tool-icon">action_delete</span>
    </button>
  </div>

  <label class="panel-title" translate>T_RIGHT_PANEL_PARAMETERS</label>
  <div class="name" translate>{{selectedComponent.name}}</div>
  <div class="description" translate>{{selectedComponent.reference.description}}</div>
  <ng-container *ngFor="let opt of displayedOptions">
    <div *ngIf="opt.visible">
      <div *ngIf="opt.values.length > 1; then select else twoItems"></div>

      <ng-template #select>
        <label class="optlabel" translate>{{opt.name}}</label>
        <select class="form-control" [disabled]="opt.disabled || !isAllowedToChange"
                tooltip="{{(opt.description? opt.description : opt.name ) | translate }}" placement="left"
                container="body" [adaptivePosition]="false"
                name="{{opt.name}}" (change)="onChangeCubicleOption($event.target.value, opt)">
          <option *ngIf="noOptionsSelected(opt)" disabled selected [value]=" "></option>
          <option *ngFor="let value of opt.values" [selected]="value.selected"
                  [value]="value.value" translate>{{value.value}}
          </option>
        </select>
        <span *ngIf="opt.disabled">
          <span class="se-icons font-param infoBtn" tooltip="{{'T_RIGHT_PANEL_DISABLED_OPTION_TOOLTIP' | translate}}"
                placement="top" aria-hidden="true">information_circle</span>
        </span>
      </ng-template>

      <ng-template #twoItems>
        <div *ngIf="opt.values.length < 2 ; then inputTemplate"></div>
        <div *ngIf="opt.values.length === 2">
          <div *ngIf="opt.htmlElementType === 'toggle'; then toggle else radio"></div>
        </div>
      </ng-template>

      <ng-template #toggle>
        <div container="body" tooltip="{{opt.description?opt.description:opt.name | translate}}" placement="left">
          <!-- if no value selected, display off toggle-->
          <img *ngIf="noOptionsSelected(opt)"
               (click)="(!opt.disabled && isAllowedToChange)?onChangeToggleOption(true, opt):null"
               src="./assets/images/icons/toggleOff.svg" class="toggleBtn right pointer"/>
          <div *ngFor="let val of opt.values">
            <img *ngIf="val.selected && !val.reference"
                 (click)="(!opt.disabled && isAllowedToChange)?onChangeToggleOption(true, opt):null"
                 src="./assets/images/icons/toggleOff.svg" class="toggleBtn right pointer"/>
            <img *ngIf="val.selected && val.reference"
                 (click)="(!opt.disabled && isAllowedToChange)?onChangeToggleOption(false, opt):null"
                 src="./assets/images/icons/toggleOn.svg" class="toggleBtn right pointer"/>
            <span class="tooltipParent right toggleBtn" *ngIf="opt.disabled && val.selected">
              <span class="se-icons font-param infoBtn" tooltip="{{'T_RIGHT_PANEL_DISABLED_OPTION_TOOLTIP' | translate}}"
                    placement="top">information_circle</span>
            </span>
          </div>
          <label class="optlabel" translate>{{opt.name}}</label>
        </div>
      </ng-template>


      <ng-template #radio>
        <label class="optlabel" translate>{{opt.name}}</label>
        <div tooltip="{{opt.description?opt.description:opt.name | translate}}" placement="left" container="body">
          <label *ngFor="let value of opt.values" class="radio-inline pointer">
            <input type="radio" [disabled]="opt.disabled || !isAllowedToChange"
                   (change)="onChangeCubicleOption(value.value, opt)" class="pointer"
                   name="{{opt.name}}" [value]="value">
            <img *ngIf="value.selected" src="./assets/images/icons/radio.svg" class="radioBtn"/>
            <img *ngIf="!value.selected" src="./assets/images/icons/radioUnselected.svg" class="radioBtn"/>
            {{value.value | translate}}
            <span class="tooltipParent" *ngIf="opt.disabled && reference.selected">
              <span class="se-icons font-param infoBtn" tooltip="{{'T_RIGHT_PANEL_DISABLED_OPTION_TOOLTIP' | translate}}"
                    placement="top">information_circle</span>
            </span>
          </label>
        </div>
      </ng-template>

      <ng-template #inputTemplate>
        <label class="optlabel" translate>{{opt.name}}</label>
        <div class="optValue">{{opt.values[0].value | translate}}</div>
      </ng-template>

    </div>
  </ng-container>

  <!--TODO : when we add a cubicle, we update currentItem and put item so there no translation issue
  when we add transformer, we call addSwitchboardTransformerItem with transformer id so we get original component with none translated values
  ideal solution would be to partial put item (for reducing payload with PATCH HTTP or PUT if it is not supported by tomcat/API GW)
  and store only ids in item
  for example something like this : item {rangeId: xx, components : [{id: xx, options: [{id: xx, value: xx}, ...],
  fu: [{pos2: transformerId}, ...]}]} -->
  <div *ngFor="let opt of selectedComponent?.characteristics">
    <label class="optlabel" translate>{{opt.key}}</label>
    <div  class="optValue" translate>{{opt.value}}</div>
  </div>


</div>

<app-common-modal #changeDataModel [name]="'changeDataModel'" [title]="'T_LOOSE_DATA_TITLE' | translate"
                  [twoButtonsDialogs]="true" [description]="'T_QUOTATION_WARNING' | translate"
                  (onClickYesButton)="confirmLooseData()" (onClickNoButton)="changeDataModel.hide()"
                  [size]="'modal-sm'">
</app-common-modal>

