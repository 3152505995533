import {Component, Input} from '@angular/core';
import {Dimension} from '../../project/shared/project-model';

@Component({
  selector: 'app-dimensions',
  templateUrl: './dimensions.component.html',
  styleUrls: ['./dimensions.component.less']
})
export class DimensionsComponent {

  @Input()
  public dimension: Dimension;

  constructor() { }



}
