/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
/* app modules */
import {User} from './user';
import {Localization} from '../model/localization.model';
import {LoggerService} from '../logging/logger.service';
import {StatusFilter} from '../../project/shared/project-model';

@Injectable()
export class UserService {

  constructor(private httpClient: HttpClient,
              private logger: LoggerService) {
  }

  getAllUsers(): Observable<User[]> {
    this.logger.debug('UserService getAllUsers()');
    return this.httpClient.get<User[]>('/user/getAll');
  }

  getUser(): Observable<User> {
    this.logger.debug('UserService getUser()');
    return this.httpClient.get<User>('/user');
  }

  getUserById(id: string): Observable<User> {
    this.logger.debug('UserService getUserById()');
    return this.httpClient.get<User>('/user/' + id);
  }

  getLocalization(): Observable<Localization> {
    this.logger.debug('UserService getLocalization()');
    return this.httpClient.get<Localization>('/user/localization');
  }

  setMySEAccount(id: string): Observable<User> {
    this.logger.info('UserService setMySEAccount()');
    return this.httpClient.post<User>('/user/mySE/' + id, id);
  }

  getUsersByPartner(partnerId: string): Observable<Array<User>> {
    this.logger.debug('UserService getUsersByPartner()');
    return this.httpClient.get<Array<User>>('/user/partner/' + partnerId);
  }

  setTermsOfUseAccepted(accepted: boolean): Observable<User> {
    this.logger.info('UserService setTermsOfUseAccepted()');
    return this.httpClient.post<User>('/user/terms/accept', accepted);
  }

  updateUserStatusFilter(statusFilter: StatusFilter): Observable<User> {
    this.logger.info('UserService updateUserStatusFilter()');
    return this.httpClient.post<User>('/user/statusFilter', statusFilter);
  }

  getUserExist(email: string): Observable<boolean> {
    this.logger.debug('UserService getUserExist()');
    const options = {params: new HttpParams().set('email', email)};
    return this.httpClient.get<boolean>('/user/exist', options);
  }

  requestPersonalDataRemoval(): Observable<boolean> {
    this.logger.debug('UserService requestPersonalDataRemoval()');
    return this.httpClient.post<boolean>('/user/requestPersonalDataRemoval', {});
  }

  deleteUser(user: User): Observable<any> {
    this.logger.debug('UserService deleting User' + user.email);
    return this.httpClient.delete<boolean>('/user/delete/' + user.id);
  }
}
