/* Angular modules */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
/* ngx modules */
import {TranslateModule} from '@ngx-translate/core';
/* app modules */
import {HeaderComponent} from './header/header.component';
import {NavigationBarComponent} from './navigation-bar/navigation-bar.component';
import {SettingsComponent} from './settings/settings.component';
import {EcorealCtSharedModule} from '../shared/shared.module';
import {HeaderService} from './header/header.service';
import {AboutModalComponent} from './about-modal/about-modal.component';
import {MyseStatusComponent} from '../myse-status/myse-status.component';
import { NotificationsBellComponent } from './header/notifications-bell/notifications-bell.component';
import { NavigationBarV2Component } from './navigation-bar-v2/navigation-bar-v2.component';
import {SeWebModule} from '@se/web-ui-angular';
import { NavigationSidePanelComponent } from './navigation-side-panel/navigation-side-panel.component';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    EcorealCtSharedModule,
    SeWebModule
  ],
  declarations: [
    HeaderComponent,
    SettingsComponent,
    NavigationBarComponent,
    SettingsComponent,
    AboutModalComponent,
    MyseStatusComponent,
    NotificationsBellComponent,
    NavigationBarV2Component,
    NavigationSidePanelComponent
  ],
    exports: [
        HeaderComponent,
        SettingsComponent,
        NavigationBarComponent,
        NavigationBarV2Component,
        NavigationSidePanelComponent
    ],
  providers: [HeaderService]
})
export class LayoutModule {
}
