<div>
  <h1 class="group-select-title" translate>T_CHOOSE_PACKAGE_OFFER</h1>
  <div class="group-select">
    <label class="group-select-label" translate>T_PACKAGE_OFFER_GROUP_LABEL</label>
    <select class="group-select-dropdown form-control" [(ngModel)]="selectedPackageOfferGroup" name="selectedRange">
      <ng-container *ngFor="let group of packageOfferGroups">
        <option [ngValue]="group" translate>{{group.name}}</option>
      </ng-container>
    </select>
  </div>
  <div *ngIf="selectedPackageOfferGroup" class="offers-container">
    <app-element-tile *ngFor="let packageOffer of getFilteredPackagesOffers()"
                      [readOnly]="false"
                      [name]="packageOffer.name"
                      [pictureFileId]="packageOffer.imageFileId"
                      (clickOnTile)="onPackageOfferSelection(packageOffer)">
    </app-element-tile>
  </div>
</div>
