import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginGuard} from "./shared/guards/login.guard";
import {ContainerComponent} from "./container/container/container.component";
import {APP_BASE_HREF} from "@angular/common";
import {environment} from "../environments/environment";
import {ProjectPageComponent} from "./project/project-page.component";
import {OffersPageComponent} from "./offers/offers-page/offers-page.component";
import {ContactManagementComponent, PriceManagementComponent, TranslationComponent} from "./admin";
import {AdminGuardService} from "./shared/guards/admin-guard.service";
import {UserManagementComponent} from "./admin/user-management/user-management.component";
import {DataManagementComponent} from "./admin/data-management/data-management.component";
import {DistributorComponent} from "./admin/distributor/distributor.component";
import {TrialConfigurationComponent} from "./admin/trial-configuration/trial-configuration.component";
import {BomPageComponent} from "./bom";
import {NavigationGuardService} from "./shared/guards/navigation-guard.service";
import {CharacteristicsPageComponent} from "./characteristics/characteristics-page/characteristics-page.component";
import {ConfigureParentComponent} from "./configuration/configure-parent/configure-parent.component";
import {DocumentationComponent} from "./export";
import {SettingsComponent} from "./layout/settings/settings.component";
import {PackageSelectorComponent} from "./package-offer/package-selector/package-selector.component";
import {ProjectBomPageComponent} from "./project-bom/project-bom-page/project-bom-page.component";
import {ProjectDetailsComponent} from "./project-sharing/project-details/project-details.component";
import {ServicesCatalogPageComponent} from "./services-catalog/services-catalog-page/services-catalog-page.component";
import {TransformerSelectorComponent} from "./transformer/transformer-selector/transformer-selector.component";
import {TransformerQuickComponent} from "./transformer-v2/transformer-quick/transformer-quick.component";
import {ConnectIdmsComponent} from "./login/connect-idms/connect-idms.component";
import {ConnectCpqComponent} from "./login/connect-cpq/connect-cpq.component";

const routes: Routes = [
  {
    path: '',
    component: ContainerComponent,
    canActivate: [LoginGuard],
    children: [
      {
        path: '',
        redirectTo: 'projects',
        pathMatch: 'full'
      },
      {
        path: 'projects',
        component: ProjectPageComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'projects-page',
        component: ProjectPageComponent,
        canActivate: [LoginGuard]},
      {
        path: 'shared-projects-page',
        component: ProjectPageComponent,
        data: {isSharedProject: true},
        canActivate: [LoginGuard]
      },
      {
        path: 'news',
        component: ProjectPageComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'project-sharing/:id',
        component: ProjectDetailsComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'offers',
        component: OffersPageComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'bom-page',
        component: BomPageComponent,
        canActivate: [NavigationGuardService, LoginGuard]
      },
      {
        path: 'project-bom-page',
        component: ProjectBomPageComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'characteristics',
        component: CharacteristicsPageComponent,
        canActivate: [NavigationGuardService, LoginGuard]
      },
      {
        path: 'configure',
        component: ConfigureParentComponent,
        canActivate: [NavigationGuardService, LoginGuard]
      },
      {path: 'documentation',
        component: DocumentationComponent,
        canActivate: [NavigationGuardService, LoginGuard]
      },

      {
        path: 'project-documentation',
        component: DocumentationComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'package-offer',
        component: PackageSelectorComponent,
        canActivate: [NavigationGuardService, LoginGuard]
      },
      {
        path: 'services-catalog',
        component: ServicesCatalogPageComponent,
        canActivate: [LoginGuard]
      },
      {
        path: 'transformer-selector',
        component: TransformerSelectorComponent,
        canActivate: [NavigationGuardService, LoginGuard]
      },
      {
        path: 'transformer',
        component: TransformerQuickComponent,
        canActivate: [NavigationGuardService, LoginGuard]
      },
      {
        path: 'admin',
        canActivate: [LoginGuard],
        component: PriceManagementComponent,
        pathMatch: 'full'
      },
      {
        path: 'admin/price-management',
        component: PriceManagementComponent,
        canActivate: [AdminGuardService, LoginGuard]
      },
      {
        path: 'admin/contact-management',
        component: ContactManagementComponent,
        canActivate: [AdminGuardService, LoginGuard]
      },
      {
        path: 'admin/user-management',
        component: UserManagementComponent,
        canActivate: [AdminGuardService, LoginGuard]
      },
      {
        path: 'admin/data-management',
        component: DataManagementComponent,
        canActivate: [AdminGuardService, LoginGuard]
      },
      {
        path: 'admin/translation',
        component: TranslationComponent,
        canActivate: [AdminGuardService, LoginGuard]
      },
      {
        path: 'admin/distributor',
        component: DistributorComponent,
        canActivate: [AdminGuardService, LoginGuard]
      },
      {
        path: 'admin/trial-configuration',
        component: TrialConfigurationComponent,
        canActivate: [AdminGuardService, LoginGuard]
      }
    ],
  },
  {
    path: 'connectCpq',
    component: ConnectCpqComponent
  },
  {
    path: 'connectIdms',
    component: ConnectIdmsComponent
  },
  {
    path: '**',
    component: ProjectPageComponent,
    canActivate: [LoginGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {provide: APP_BASE_HREF, useValue: environment.appBaseHref}
  ]
})
export class AppRoutingModule {
}
