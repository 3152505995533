<div class="row">
  <!-- Go to project BOM link -->
  <div *ngIf="currentItemct && currentProject.itemCT.length > 1" class="link-container">
    <a (click)="goToProjectBom()" [title]="'T_BOM_GO_TO_PROJECT_BOM_LINK' | translate" translate>
      T_BOM_GO_TO_PROJECT_BOM_LINK
    </a>
  </div>
</div>
<div class="row">
  <div class="button-container">
    <!--Collaboration-->
    <div *ngIf="!isProjectBom && !isCpqProject && !isReadOnlyProject" class="button-div">
      <button type="button" class="btn btn-primary share-button" (click)="collaboration()" translate>
        <span class="se-icons share-icon">action_share</span>
        T_COLLABORATIVE_PROJECT_COLLABORATION
      </button>
    </div>
    <!--Packing-->
    <div
      *ngIf="!isProjectBom && !isCpqProject && !isDemoMode && !isReadOnlyProject && currentItemct.range && currentItemct.range.optionalPacking && currentItemct.range.optionalPacking.length > 0"
      class="button-div">
      <button type="button" class="btn btn-default-gray-background"
              [disabled]="(currentItemct.status !== status.configured && currentItemct.status !== status.quoted) || isReadOnlyProject"
              (click)="onChangeOptionalPacking(currentItemct.range.optionalPacking[0])">
        <span translate>T_PACKING</span> <span translate>{{currentItemct.range.optionalPacking[0]}}</span>
      </button>
    </div>
    <!--Download-->
    <div *ngIf="!isCpqProject" class="button-div">
      <button type="button" class="btn btn-default-gray-background"
              (click)="isDemoMode?showRegisterModal():exportBom()" translate>
        <span class="pi pi-download">&nbsp;</span>
        T_BOM_EXPORT_BOM
      </button>
    </div>
    <!--Order-->
    <div *ngIf="!isCpqProject && !isCpqUser && !isDemoMode && !isReadOnlyProject && !(!isProjectBom && currentItemct.range && currentItemct.range.status === 'DECOMMISSIONED')" class="btn-group btn-primary button-div" dropdown
         placement="bottom left">
      <button dropdownToggle type="button" class="btn-primary btn se-top-btn" translate>
        T_BOM_ORDER
        <span class="se-icons arrow-icon se-lg">arrow2_down</span>
      </button>

      <!--Direct Contractor, Indirect Contractor, FO, Distributor, National Sales user's case-->
      <ng-container *ngIf="(!isOrderingMixedMode() && !isContractorMixedUser()) || (isOrderingMixedMode() && !isContractorMixedUser() && !isContractorsUser())">
        <ul *dropdownMenu class="box-dropdown dropdown-menu dropdown-menu-right" role="menu">
          <li [ngClass]="{'disabled': disabledOrder()}">
            <a class="dropdown-item" (click)="orderRequest()" translate>T_BOM_ORDER_EMAIL</a>
          </li>
          <li *ngIf="user.currentMySEAccount"
              [ngClass]="{'disabled': disabledMySEOrder()}">
            <a class="dropdown-item" (click)="orderWithMySE()" translate>T_PROCEED_QUOTATION_MYSE</a>
          </li>
          <li *ngIf="user.currentMySEAccount"
              [ngClass]="{'disabled':!hasMySEUrl()}">
            <a class="dropdown-item" (click)="goOnMYSE()" translate>T_MYSE_ACTION</a>
          </li>
        </ul>
      </ng-container>

      <!--Mixed Contractor user's case-->
      <ng-container *ngIf="isOrderingMixedMode() && isContractorMixedUser()">
        <ul *dropdownMenu class="box-dropdown dropdown-menu dropdown-menu-right" role="menu">
          <li *ngIf="hasDirectRestrictedOrdering()" [ngClass]="{'disabled': disabledMultipleOrder('DIRECT')}">
            <a class="dropdown-item" (click)="orderToSEByMail()" translate>T_BOM_ORDER_SE_EMAIL</a>
          </li>
          <li *ngIf="hasIndirectRestrictedOrdering()" [ngClass]="{'disabled': disabledMultipleOrder('INDIRECT')}">
            <a class="dropdown-item" (click)="orderThroughDistributor()" translate>T_BOM_ORDER_DISTRIBUTOR</a>
          </li>
          <li *ngIf="user.currentMySEAccount && hasDirectRestrictedOrdering() && ((isProjectBom && utilService.isValidMySEPriceForProjectBom(currentProject)) ||
          (!isProjectBom && user.currentMySEAccount && utilService.isValidMySEPrice(currentItemct )))"
              [ngClass]="{'disabled': disabledMultipleOrder('DIRECT')}">
            <a class="dropdown-item" (click)="orderByMySE()" translate>T_PROCEED_QUOTATION_MYSE</a>
          </li>
          <li *ngIf="user.currentMySEAccount && hasDirectRestrictedOrdering() && ((isProjectBom && utilService.isValidMySEPriceForProjectBom(currentProject)) ||
          (!isProjectBom && user.currentMySEAccount &&  utilService.isValidMySEPrice(currentItemct )))"
              [ngClass]="{'disabled':!hasMySEUrl()}">
            <a class="dropdown-item" (click)="goOnMYSE()" translate>T_MYSE_ACTION</a>
          </li>
        </ul>
      </ng-container>

      <!--Error case (no right role to order)-->
      <ng-container *ngIf="(!isOrderingMixedMode() && isContractorMixedUser()) ||  (isOrderingMixedMode() && isContractorsUser())">
        <ul *dropdownMenu class="box-dropdown dropdown-menu dropdown-menu-right" role="menu">
          <li [ngClass]="{'disabled': disabledOrder()}">
            <a class="dropdown-item" (click)="orderWithNoRightRole()" translate>T_BOM_ORDER_EMAIL</a>
          </li>
        </ul>
      </ng-container>

    </div>
  </div>
</div>


<!--Register modal-->
<app-register-modal #registerModal>
</app-register-modal>

<!-- mailOrder dialog -->
<app-bom-distributor-modal #distributorChoiceModal [distributors]="allDistributors"
                           (onClickOKButton)="distributorSelected($event)">
</app-bom-distributor-modal>

<!-- mailOrder dialog -->
<app-bom-mail-modal #orderEmailModal [recipientMail]="recipientMail" [subjectEmailInput]="'T_BOM_MAIL_SUBJECT_MESSAGE'" [isProjectBom]="isProjectBom"
                    [bodyEmailInput]="messageMail" [referencesToOrder]="referencesToOrder" (updateProjectBomInfos)="updateProjectBom($event)">
</app-bom-mail-modal>

<!-- mail dialog -->
<app-common-modal #mailModal [name]="'mail'" [title]="'T_INFORMATION_TITLE' | translate" [twoButtonsDialogs]="false"
                  [size]="'modal-sm'">
  <div modal-body translate>
    <span translate>T_EMAIL</span> <a href="mailto:{{ localization.mail }}">{{ localization.mail }}</a>
  </div>
</app-common-modal>

<!--reference list modal-->
<app-bom-references-modal #referenceListModal [referencesToOrder]="referencesToOrder" [orderType]="orderType"
                          (onClickYesButton)="proceedOrder($event)">
</app-bom-references-modal>

<!-- warning dialog -->
<app-common-modal #orderingWarningModal [title]="'T_BOM_MULTIPLE_ORDERING_WARNING_TITLE' | translate" [name]="'T_BOM_MULTIPLE_ORDERING_WARNING_TITLE'"
                  [description]="'T_BOM_MULTIPLE_ORDERING_WARNING_MESSAGE' | translate" [twoButtonsDialogs]="false" [closable]="false" [size]="'modal-default'">
</app-common-modal>
