/* angular modules */
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
/* ngx modules */
import {TranslateService} from '@ngx-translate/core';
import {SessionStorage} from 'ngx-webstorage';
/* app modules */
import {ComponentType} from '../shared/model/component-type';
import {Item, Project, Status} from '../../project/shared/project-model';
import {NavigationStep} from '../../shared/guards/navigationStep-enum';
import {ItemService} from '../../project/shared/item.service';
import {MessageService} from 'primeng/api';
import {AddingTransformerInfosWrapper} from '../shared/model/AddingTransformerInfosWrapper';
import {OptionsPanelService} from '../shared/services/options-panel.service';
import {isNullOrUndefined} from 'util';
import {SwitchBoardComponent} from '../shared/model/component';
import {PanelOptionWrapper} from '../shared/model/panelOptionWrapper';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {ItemNavigationService} from '../shared/services/itemNavigation.service';
import {ProjectService} from '../../project/shared/project.service';
import {NavigationRoute} from '../../shared/guards/route-enum';
import {Subject} from 'rxjs/Rx';

@Component({
  selector: 'app-configure-parent',
  templateUrl: './configure-parent.component.html',
  styleUrls: ['./configure-parent.component.less'],
})
export class ConfigureParentComponent implements OnInit, OnDestroy {

  @SessionStorage()
  user;
  @SessionStorage()
  currentProject: Project;
  onSldTab = true;
  componentType: ComponentType;
  addingTransformerInfos: AddingTransformerInfosWrapper;
  @ViewChild('deleteModal') confirmDeleteModal: CommonModalComponent;
  warningDeleteMessage: string;
  @SessionStorage()
  currentItemct: Item;
  panelOpened: boolean;
  buttonLabel: string;
  // two fields used to manage the panel of options
  openOptionsPanel: boolean;
  selectedComponent: SwitchBoardComponent;
  panelOptionWrapper: PanelOptionWrapper;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private itemService: ItemService,
              private translateService: TranslateService,
              private messageService: MessageService,
              private projectService: ProjectService,
              private optionsPanelService: OptionsPanelService,
              private itemNavigationService: ItemNavigationService) {
    this.optionsPanelService.getSelectedComponent()
      .takeUntil(this.unsubscribe$)
      .subscribe(
        component => {
          this.selectedComponent = component;
          this.openOptionsPanel = !isNullOrUndefined(component);
        });
  }

  ngOnInit() {
    this.componentType = ComponentType.CUBICLE;
    this.panelOpened = this.currentItemct.components.length === 0;
    this.buttonLabel = this.panelOpened ? 'T_CLOSE_SELECTION' : 'T_OPEN_SELECTION';
    if (this.currentItemct && this.currentItemct.status === Status.ordered && !this.projectService.isReadOnlyProject(this.currentProject, this.user)) {
      // Information Toast Ordered
      if (this.projectService.isCpqProject(this.currentProject)) {
        // Information Toast
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('T_ORDER_WARNING_TITLE'),
          detail: this.translateService.instant('T_PUSH_TO_CPQ_WARNING'),
        });
      } else {
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('T_ORDER_WARNING_TITLE'),
          detail: this.translateService.instant('T_ORDER_WARNING'),
        });
      }
    } else {
      if (this.currentItemct && this.currentItemct.components.length > 0 && this.currentItemct.status === Status.quoted &&
        !this.projectService.isReadOnlyProject(this.currentProject, this.user)) {
        // Information Toast Loose data
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('T_LOOSE_DATA_WARNING_TITLE'),
          detail: this.translateService.instant('T_LOOSE_DATA_WARNING'),
        });
      }
    }
    this.itemService.setItemNavigationStep(NavigationStep.CONFIGURATION, this.user, this.unsubscribe$);
  }

  slideSelection() {
    this.panelOpened = !this.panelOpened;
    this.buttonLabel = this.panelOpened ? 'T_CLOSE_SELECTION' : 'T_OPEN_SELECTION';
  }

  onTabEvent(value: boolean) {
    this.onSldTab = value;

    // unselect SLD component when quit SLD tab
    if (!this.onSldTab) {
      this.selectedComponent = null;
      this.openOptionsPanel = false;
    }
  }

  public moveComponent(event) {
    this.panelOptionWrapper = event.panelOptionWrapper;

    if (this.panelOptionWrapper.componentPosition === null) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translateService.instant('T_INFORMATION'),
        detail: this.translateService.instant('T_NO_COMPONENT'),
      });
      return;
    }

    if (this.currentItemct.components.length === 1) {
      return;
    }

    function arrayMove(arr, fromIndex, toIndex) {
      const element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    }

    if (event.direction === 'left') {
      if (this.panelOptionWrapper.componentPosition === 0) {
        return;
      }
      arrayMove(this.currentItemct.components, this.panelOptionWrapper.componentPosition, this.panelOptionWrapper.componentPosition - 1);
      this.panelOptionWrapper.componentPosition--;
      this.currentItemct.selectedComponentIndex--;
    }
    if (event.direction === 'right') {
      if (this.panelOptionWrapper.componentPosition === this.currentItemct.components.length - 1) {
        return;
      }
      arrayMove(this.currentItemct.components, this.panelOptionWrapper.componentPosition, this.panelOptionWrapper.componentPosition + 1);
      this.panelOptionWrapper.componentPosition++;
      this.currentItemct.selectedComponentIndex++;
    }
    this.itemService.updateItemWithStatusChange(this.currentItemct, Status.configured, this.user, true)
      .takeUntil(this.unsubscribe$)
      .subscribe(item => {
          this.currentItemct = item;
          this.itemService.updateTotalPriceAndDimensions(this.unsubscribe$);
        },
        () => null,
        () => {
          const component: SwitchBoardComponent = this.currentItemct.components[this.panelOptionWrapper.componentPosition];
          this.updateNavigation(component);
        }
      );
  }

  public deleteComponentDispatcher() {
    if (this.panelOptionWrapper !== null && ComponentType.TRANSFORMER === this.panelOptionWrapper.type) {
      this.deleteTransformer();
    } else if (this.panelOptionWrapper !== null) {
      this.deleteCubicle(this.panelOptionWrapper.componentPosition);
    }
  }

  deleteCubicle(indexCubicle: number) {
    if (indexCubicle === null) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translateService.instant('T_INFORMATION'),
        detail: this.translateService.instant('T_NO_COMPONENT'),
      });
      return;
    }

    // if last cubicle is deleted, we change max step of navigation bar
    // if the deleted cubicle is not the last, we set the max navigation step on the BOM
    if (this.currentItemct.components.length === 1) {
      this.currentItemct.maxNavigationStep = NavigationStep.CONFIGURATION;
      this.componentType = ComponentType.CUBICLE;
    } else if (this.currentItemct.components.length > 1) {
      this.currentItemct.maxNavigationStep = NavigationRoute.getNextStepByRange(this.currentItemct.range, this.currentItemct.currentNavigationStep).id;
    }

    // update itemCT
    this.currentItemct.components.splice(indexCubicle, 1);
    const lastComponent: SwitchBoardComponent = this.currentItemct.components[this.currentItemct.components.length - 1];
    this.updateNavigation(lastComponent);
    this.openOptionsPanel = false;
    this.itemService.updateItemWithStatusChange(this.currentItemct, Status.configured, this.user)
      .takeUntil(this.unsubscribe$)
      .subscribe(item => {
          this.currentItemct = item;
          this.itemService.updateTotalPriceAndDimensions(this.unsubscribe$);
        },
        error => null,
        () => {
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('T_INFORMATION'),
            detail: this.translateService.instant('T_SUCCESS_REMOVE_ITEM'),
          });
        }
      );
  }

  deleteTransformer() {
    if (this.panelOptionWrapper === null || this.panelOptionWrapper.componentPosition === null ||
      this.panelOptionWrapper.functionPosition === null) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translateService.instant('T_INFORMATION'),
        detail: this.translateService.instant('T_NO_COMPONENT'),
      });
      return;
    }
    this.currentItemct.components[this.panelOptionWrapper.componentPosition]
      .functionalUnits[this.panelOptionWrapper.functionPosition].transformer = null;
    this.optionsPanelService.updateSelectedComponentInfos(new PanelOptionWrapper(null, null, null));
    this.openOptionsPanel = false;
    this.itemService.updateItemWithStatusChange(this.currentItemct, Status.configured, this.user)
      .takeUntil(this.unsubscribe$)
      .subscribe(item => {
          this.currentItemct = item;
          this.itemService.updateTotalPriceAndDimensions(this.unsubscribe$);
          this.itemService.setItemMaxNavigationStep(NavigationRoute.getNextStepByRange(this.currentItemct.range, this.currentItemct.currentNavigationStep).id,
            this.user, true, this.unsubscribe$);
        },
        error => null,
        () => {
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('T_INFORMATION'),
            detail: this.translateService.instant('T_SUCCESS_REMOVE_ITEM'),
          });
        }
      );
  }

  deleteComponent(panelOptionWrapper) {
    this.panelOptionWrapper = panelOptionWrapper;
    this.confirmDeleteModal.show();
    this.warningDeleteMessage = this.getDeleteModalDescription();
  }

  getDeleteModalDescription() {
    if (this.currentItemct.status === Status.quoted) {
      return this.translateService.instant('T_QUOTATION_WARNING');
    } else {
      return this.translateService.instant('T_DELETE_ITEM_WARNING');
    }
  }

  componentTypeEvent(componentTypeToDisplay) {
    this.componentType = componentTypeToDisplay;
  }

  transformerAddingEvent(addingTransformerInfos: AddingTransformerInfosWrapper) {
    this.addingTransformerInfos = addingTransformerInfos;
  }

  unselectComponent(mouseEvent: any) {
    if (!mouseEvent.composedPath().some(path => path.localName === 'app-sld-component-viewer') &&
      !mouseEvent.composedPath().some(path => path.localName === 'app-sld-options-component') &&
      !mouseEvent.composedPath().some(path => path.localName === 'app-card-component-modal')) {
      this.selectedComponent = null;
      this.openOptionsPanel = false;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    // unsubscribe from the subject itself:
    this.unsubscribe$.unsubscribe();
  }

  private updateNavigation(component: SwitchBoardComponent) {
    if (component) {
      this.currentItemct = this.itemNavigationService.updateNavigationBar(component, this.currentItemct);
    }
  }
}
