import {ComponentType} from './component-type';

export class PanelOptionWrapper {
  type: ComponentType;
  componentPosition: number;
  functionPosition: number;
  constructor(type: ComponentType, componentPosition: number, functionPosition: number) {
    this.type = type;
    this.componentPosition = componentPosition;
    this.functionPosition = functionPosition;
  }
}
