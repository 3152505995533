/* Angular modules */
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FeedbackRecord} from '../model/feedbackRecord';
import {LoggerService} from '../../../shared/logging/logger.service';
/* rxjs import */
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs';

@Injectable()
export class FeedbackService {

  private subject = new Subject<any>();

  constructor(private httpClient: HttpClient,
              private logger: LoggerService) {}

  /**
   * Allow to send the feedback of the current user
   * to the back-end and store the date of the feedback on DB
   * @param {FeedbackRecord} feedback
   * @returns {Observable<FeedbackRecord>}
   */
  storeFeedback(feedback: FeedbackRecord): Observable<any> {
    this.logger.info('Store Feedback storeFeedback()');
    return this.httpClient.post('/feedback/store', feedback, {responseType: 'text'});
  }

  /**
   * return true if the user made a feedback more or less a specific period (configurable)
   * allow to know if the general or event feedback modal must be displayed
   * @returns {Observable<boolean>}
   */
  lastUserFeedbackExpired(isGeneralFeedback: boolean): Observable<boolean> {
    this.logger.info('Get last user Feedback lastUserFeedbackExpired()');
    return this.httpClient.get<boolean>('/feedback/' + isGeneralFeedback);
  }

  /**
   * Allow to update the feedback date when user cancel feedback
   * @returns {Observable<boolean>}
   */
  setLastUserFeedBack(): Observable<any> {
    this.logger.info('Set last user Feedback setLastUserFeedBack()');
    return this.httpClient.post('/feedback', {});
  }

  /**
   * Get feedback observable object
   */
  feedbackObservable(): Observable<any> {
    return this.subject.asObservable();
  }

  /**
   * Show general feedback modal
   * @param delayMillisecond modal will open after a delay in millisecond
   */
  showGeneralFeedbackModal(delayMillisecond: number = 0): void {
    this.subject.next({isGeneralFeedback: true, delayMillisecond: delayMillisecond});
  }

  /**
   * Show feedback by event (finalized configuration or cancelled configuration) modal
   * @param delayMillisecond modal will open after a delay in millisecond
   */
  showEventFeedbackModal(delayMillisecond: number = 0): void {
    this.subject.next({isGeneralFeedback: false, delayMillisecond: delayMillisecond});
  }
}
