/* Angular modules */
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
/* ngx modules */
import {SessionStorage, SessionStorageService} from 'ngx-webstorage';
import {TranslateService} from '@ngx-translate/core';
/* app modules */
import {ProjectService} from '../../project/shared/project.service';
import {Item, Project, ProjectTotalPrice} from '../../project/shared/project-model';
import {ItemService} from '../../project/shared/item.service';
import {NavigationStep} from 'app/shared/guards/navigationStep-enum';
import {UtilService} from '../../shared/util/util.service';
import {NavigationLinkEnum} from '../../shared/guards/navigationLink-enum';
import {RightsService} from '../../shared/rights/rights.service';
import {DocumentInformations} from '../../export/shared';
import {Partner} from '../../shared/partner/partner';
import {RegisterModalComponent} from '../../shared/register-modal/register-modal.component';
import {AboutModalComponent} from '../about-modal/about-modal.component';
import {Role} from '../../core/access-control/enum';
import {BomService} from '../../bom/shared';
import {DemoMode, Localization} from '../../shared/model/localization.model';
import {User} from '../../shared/user/user';
import {LoggerService} from '../../shared/logging/logger.service';
import {UploadService} from '../../admin/shared';
import {ReportType} from '../../admin/shared/upload/report-type.enum';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {ComToolsService} from '../../shared/com-tools/com-tools.service';
import {MessageService} from 'primeng/api';
import {FeedbackService} from '../../feedback/shared/service/feedback.service';
import {NavigationRoute} from '../../shared/guards/route-enum';
import {environment} from "../../../environments/environment";
import {AuthService} from "../../shared/auth/auth.service";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit, AfterViewInit {

  @SessionStorage()
  public user: User;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  localization: Localization;

  @SessionStorage()
  partner: Partner;

  @SessionStorage()
  isOnAdministrationScreen: boolean;

  @SessionStorage()
  adminNavigationStep;

  @SessionStorage()
  isDemoMode: boolean;

  @SessionStorage()
  hasRegister: boolean;

  @SessionStorage()
  documentInformations: DocumentInformations;

  @SessionStorage()
  isNetPriceDisplayed: boolean;

  @SessionStorage()
  isNetPriceLoading: boolean;

  @SessionStorage()
  cpqQuoteId;

  @SessionStorage()
  cpqRedirectURL;

  isDownloadingProjectReport = false;
  isDownloadingReferenceReport = false;

  @ViewChild('aboutModal') aboutModal: AboutModalComponent;
  @ViewChild('registerModal') registerModal: RegisterModalComponent;
  @ViewChild('alertModal') alertModal: CommonModalComponent;

  public showEditProjectName = false;

  totalPrice: ProjectTotalPrice;
  formatedProjectPrice: string;


  isValidProjectName = true;
  isValidItemName = true;


  constructor(private router: Router,
              private logger: LoggerService,
              private translateService: TranslateService,
              private projectService: ProjectService,
              private itemService: ItemService,
              private messageService: MessageService,
              private uploadService: UploadService,
              private comToolsService: ComToolsService,
              private rightsService: RightsService,
              private sessionStorageService: SessionStorageService,
              private bomService: BomService,
              private authService: AuthService,
              private feedbackService: FeedbackService) {
  }

  ngOnInit() {
    this.showEditProjectName = false;
    this.sessionStorageService.observe('currentProject').subscribe(() => this.updateTotalPrice());
  }

  ngAfterViewInit(): void {
    if (!this.isCpqUser() && this.isValidCpqQuoteId()) {
      this.showAlertCpqRightModal();
    }
  }

  showCpqSection(): boolean {
    return this.isCpqUser() && this.isValidCpqQuoteId();
  }

  private isValidCpqQuoteId() {
    return this.cpqQuoteId !== null && this.cpqQuoteId !== undefined && this.cpqQuoteId !== '';
  }

  updateItemName (project: Project, itemCt: Item, $event, user: User){
    if (this.isValidItemName) {
      this.itemService.updateSwitchboardName(project, itemCt, $event, user);
    } else {
      $event.target.innerText = itemCt.name;
      this.isValidItemName = UtilService.isValidString($event.target.innerText);
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('T_ERROR'),
        detail: this.translateService.instant('T_ITEMS_RENAMING_ERROR')
      });
    }
  }

  itemNameCheck ($event: any) {
    if ($event.key === 'Enter') {
      $event.target.blur();
    } else {
      this.itemService.controlMaxLengthOnItem($event);
      this.isValidItemName = UtilService.isValidString($event.target.textContent);
    }
  }

  updateProjectName (project: Project, documentInformation: DocumentInformations, currentItem: Item, $event){
    if (this.isValidProjectName) {
      this.projectService.updateProjectNameFromEditable(project,documentInformation,currentItem, $event);
    } else {
      $event.target.innerText = this.currentProject.name;
      this.isValidProjectName = UtilService.isValidString($event.target.innerText);
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('T_ERROR'),
        detail: this.translateService.instant('T_PROJECTS_RENAMING_ERROR')
      });
    }
  }

  projectNameCheck ($event: any) {
    if ($event.key === 'Enter') {
      $event.target.blur();
    } else {
      this.projectService.controlMaxLengthOnProject($event);
      this.isValidProjectName = UtilService.isValidString($event.target.textContent);
    }
  }

  projectNameMustBeDisplayed() {
    return this.currentItemct !== null && this.currentItemct.currentNavigationStep !== NavigationStep.WELCOME
      && !this.isOnAdministrationScreen;
  }

  bomShortCutMustBeDisplay() {
    if (this.currentItemct == null || this.isOnAdministrationScreen) {
      return false;
    } else {
      return this.currentItemct.currentNavigationStep > NavigationStep.WELCOME;
    }
  }

  redirectToSettings() {
    this.showFeedback();
    this.router.navigate([NavigationLinkEnum.SETTINGS]);
  }

  redirectToAdmin() {
    if (!this.isOnAdministrationScreen) {
      this.adminNavigationStep = 1;
      this.router.navigate([NavigationLinkEnum.ADMIN]);
    }
  }

  openComToolsBackOffice() {
   this.comToolsService.getManagementComToolsUrl().subscribe(
     response => window.open(response),
     () => {
       this.messageService.add({
         severity: 'error',
         summary: this.translateService.instant('T_ERROR'),
         detail: this.translateService.instant('T_COM_TOOL_MANAGEMENT_ERROR'),
       });
     });
  }

  /**
   * Method called to open user manual in a new tab
   */
  openUserManual() {
    this.logger.business('Support user manual');
    window.open(UtilService.getUserManualUrl(this.user));
  }

  /**
   * Show the modal about the application
   */
  showAboutModal() {
    this.logger.business('Support about');
    this.aboutModal.show();
  }

  isAdminUser(): boolean {
    return this.rightsService.isAdmin();
  }

  isCommToolAdminUser(): boolean {
    return this.rightsService.isComToolsAdmin();
  }

  isDemoUser(): boolean {
    return this.rightsService.isDemo();
  }

  isActivatedDemoUser(): boolean {
    return this.rightsService.isActivatedDemo();
  }

  isDeactivatedDemoUser(): boolean {
    return this.rightsService.isDeactivatedDemo();
  }

  isCpqUser(): boolean {
    return this.rightsService.isCPQUser();
  }

  isFrUser(): boolean {
    return this.user.partnerCountry && this.user.partnerCountry.toUpperCase() === 'FR';
  }

  goToDemoMode() {
    if (this.localization.demoMode === DemoMode.DEMO) {
      this.logger.business('Demo user connection', {email: this.user.email, country: this.user.paceCountry});
      this.isDemoMode = true;
    }
  }

  showRegisterModal() {
    this.registerModal.show();
  }

  isReadOnlyProject(): boolean {
    if (this.currentProject != null) {
      return this.projectService.isReadOnlyProject(this.currentProject, this.user);
    }
    return false;
  }

  getPhoneSupportContact(): string {
    if (this.isRessellerPartner() && this.partner && this.partner.supportContact) {
      return this.partner.supportContact.phone;
    } else if (this.localization && this.localization.supportContact) {
      return this.localization.supportContact.phone;
    }
    return null;
  }

  getFaxSupportContact(): string {
    if (this.isRessellerPartner() && this.partner && this.partner.supportContact) {
      return this.partner.supportContact.fax;
    } else if (this.localization && this.localization.supportContact) {
      return this.localization.supportContact.fax;
    }
    return null;
  }

  getEmailSupportContact(): string {
    if (this.isRessellerPartner() && this.partner && this.partner.supportContact) {
      return this.partner.supportContact.email;
    } else if (this.localization && this.localization.supportContact) {
      return this.localization.supportContact.email;
    }
    return null;
  }

  /**
   * Function to log the logout action
   */
  logout() {
    this.logger.business('Log out');
    this.authService.logout();
  }

  /**
   * Function to log the click on support dropdown
   */
  logSupportClick() {
    this.logger.business('Support info');
  }

  /**
   * To download project report
   */
  downloadProjectReport() {
    this.isDownloadingProjectReport = true;
    this.downloadReport(ReportType.project);
  }

  /**
   * To download references report
   */
  downloadReferenceReport() {
    this.isDownloadingReferenceReport = true;
    this.downloadReport(ReportType.reference);
  }

  /**
   * To download report
   * @param type
   */
  downloadReport(type: ReportType) {
    this.uploadService.exportReport(type).subscribe(
      response => {
        let dataArray = new Uint8Array(response.body);
        // 32 is the int representation of the space character in 8bit arrays.
        // We remove all the space this way. (zip file don't start with a space).
        while (dataArray[0] && dataArray[0] === 32) {
          dataArray = dataArray.slice(1);
        }
        const report = { body: new Blob([new Uint8Array(dataArray)]), headers: response.headers};
        this.uploadService.createXlsFileFromBackendResponse(report);
      },
      error => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_DOWNLOAD_REPORT_ERROR_MESSAGE'),
        });
        this.endOfReportDownloadRequest(type);
      },
      () => {
        this.endOfReportDownloadRequest(type);
      }
    );
  }

  /**
   * Method call at the end of report download request
   * @param type
   */
  endOfReportDownloadRequest(type: ReportType) {
    if (type === ReportType.project) {
      this.isDownloadingProjectReport = false;
    } else {
      this.isDownloadingReferenceReport = false;
    }
  }

  /**
   * To be redirect to CPQ
   */
  goBackToCpq() {
    if (this.isCpqUser()  && this.cpqRedirectURL && this.cpqQuoteId) {
      window.open(this.cpqRedirectURL + this.cpqQuoteId, '_self');
    }
  }

  /**
   * Redirect to projects page
   * show feedback modal if the last feedback is expired for cancelled configuration event
   */
  redirectToProjects() {
    this.showFeedback();
    this.router.navigate([NavigationLinkEnum.PROJECTS]);
  }

  /**
   * Show feedback modal if the last feedback is expired for cancelled configuration event
   */
  private showFeedback() {
    if (this.currentItemct && this.currentItemct.maxNavigationStep <= NavigationStep.BILL_OF_MATERIALS &&
      NavigationRoute.PROJECT_CONFIGURATION_LINKS_LIST.includes(this.router.url)) {
      this.feedbackService.showEventFeedbackModal(0);
    }
  }

  /**
   * Displays warning modal if discount is higher than max discount
   */
  private showAlertCpqRightModal() {
    this.alertModal.name = this.translateService.instant('T_CPQ_RIGHT_ALERT_TITLE');
    this.alertModal.title = this.translateService.instant('T_CPQ_RIGHT_ALERT_TITLE');
    this.alertModal.description = this.translateService.instant('T_CPQ_RIGHT_ALERT_DESCRIPTION');
    this.alertModal.show();
  }

  /**
   * updates totalPrice field.
   */
  private updateTotalPrice() {
    if (this.currentProject) {
      this.itemService.getItems(this.currentProject.id).subscribe(items => {
        this.totalPrice = this.bomService.getProjectPrice(items, this.rightsService.canApplyDiscount());
        this.formatedProjectPrice = UtilService.formatPrice(this.totalPrice.price);
      });
    }
  }

  private isRessellerPartner(): boolean {
    return this.partner.role === Role.RESELLER;
  }

}
