import {Injectable} from '@angular/core';
import {LoggerService} from '../../../shared/logging/logger.service';
import {HttpClient} from '@angular/common/http';
import {MappingTransformerSwitchboard} from './up.selling.transformer.model';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class UpSellingTransformerService {

  constructor(private logger: LoggerService,
              private httpClient: HttpClient) {
  }

  /**
   * Get the mapping transformer and switchboard list
   */
  getTransformerSwitchboardMappingList(): Observable<MappingTransformerSwitchboard[]> {
    this.logger.info('UpSellingTransformerService getTransformerSwitchboardMappingList()');
    return this.httpClient.get<MappingTransformerSwitchboard[]>('/upselling');
  }

  /**
   * Get the compatible transformer list
   * @param reference
   * @param rangeName
   */
  getCompatibleTransformerListByRangeAndReference(reference: string, rangeName: string): Observable<string[]> {
    this.logger.info('UpSellingTransformerService getCompatibleTransformerListByRangeAndReference()');
    return this.httpClient.get<string[]>('/upselling/transformers/' + reference + '/' + rangeName);
  }

  /**
   * Check if a cubicle reference has a compatible transformers
   * @param reference
   * @param rangeName
   */
  isTransformerCompatibleReference(reference: string, rangeName: string): Observable<boolean> {
    this.logger.info('UpSellingTransformerService isTransformerCompatibleReference()');
    return this.httpClient.get<boolean>('/upselling/compatibility/' + reference + '/' + rangeName);
  }

}
