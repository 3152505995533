import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Locale, Localization, Translation, TranslationStatusEnum} from '../../../shared/model/localization.model';
import {isNullOrUndefined} from 'util';
import {TranslationService} from '../../shared/translation/translation.service';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {LoggerService} from '../../../shared/logging/logger.service';
import {TranslationHelperService} from '../../shared/translation/translation.helper.service';
import {UploadService} from '../../shared/upload/upload.service';
import {TranslationKey} from '../../shared/translation/translation.model';
import {UtilService} from '../../../shared/util/util.service';
import {HttpCacheService} from '../../../core/http/http-cache.service';
import {SessionStorage} from 'ngx-webstorage';
import {Table} from 'primeng/table';

@Component({
  selector: 'app-translation-button',
  templateUrl: './translation-button.component.html',
  styleUrls: ['./translation-button.component.less'],
})
export class TranslationButtonComponent {

  @SessionStorage()
  user;

  @SessionStorage()
  localization: Localization;

  @Input() locale: Locale;

  @Input() translationLength: number;

  @Input() dataTableFilter: Table;

  @Output() onImportTranslations = new EventEmitter<string>();

  @Output() isLoadingEvent = new EventEmitter<boolean>();

  isLanguageDeactivated = true;

  IMPORT_OK_RESPONSE = 'IMPORT-OK';


  constructor(private translationService: TranslationService,
              private messageService: MessageService,
              private translateService: TranslateService,
              private translationHelperService: TranslationHelperService,
              private uploadService: UploadService,
              private utilService: UtilService,
              private logger: LoggerService,
              private httpCacheService: HttpCacheService) {
  }

  hasOkStatus(): boolean {
    return this.locale.translations &&
      this.locale.translations.some(translation => translation.status === TranslationStatusEnum.OK);
  }

  hasMissingStatus(): boolean {
    return this.locale.translations &&
      this.locale.translations.some(translation => translation.status === TranslationStatusEnum.MISSING);
  }

  hasEditedStatus(): boolean {
    return this.locale.translations &&
      this.locale.translations.some(translation => translation.status === TranslationStatusEnum.EDITED);
  }


  changeToggle(locale) {
    if (locale.available && this.localization.langs[this.localization.indexDemoDefaultlanguage].language === locale.language) {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('T_ERROR'),
        detail: this.translateService.instant('T_BO_LANGUAGE_AVAILABILITY_UNAVAILABLE_DISABLE'),
      });
    } else {
      this.translationService.updateLocaleAvailability(locale.language, !locale.available).subscribe(
        () => {
          locale.available = !locale.available;
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('T_INFO'),
            detail: this.translateService.instant('T_BO_LANGUAGE_AVAILABILITY_UPDATE_SUCCESS'),
          });
          // Clear cache
          this.httpCacheService.clearCache('/localizations');
        },
        error => {
          this.logger.error(error);
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant('T_BO_LANGUAGE_AVAILABILITY_UPDATE_ERROR'),
          });
        }
      );
    }
  }

  onClickOKStatus(locale, dataTableFilter) {
    this.dataTableFilter = dataTableFilter;
    if (!locale.okStatus && !this.hasLocaleContainsStatus(locale, TranslationStatusEnum.OK)) {
      locale.okStatus = false;
    } else {
      locale.okStatus = !locale.okStatus;
      const index = locale.selectedStatus.indexOf(TranslationStatusEnum.OK, 0);
      if (!locale.okStatus && index > -1) {
        locale.selectedStatus.splice(index, 1);
      }
      if (locale.okStatus && index === -1) {
        locale.selectedStatus.push(TranslationStatusEnum.OK);
      }
      this.filterByStatus(locale);
    }
  }

  onClickMissingStatus(locale, dataTableFilter) {
    this.dataTableFilter = dataTableFilter;
    if (!locale.missingStatus && !this.hasLocaleContainsStatus(locale, TranslationStatusEnum.MISSING)) {
      locale.missingStatus = false;
    } else {
      locale.missingStatus = !locale.missingStatus;
      const index = locale.selectedStatus.indexOf(TranslationStatusEnum.MISSING, 0);
      if (!locale.missingStatus && index > -1) {
        locale.selectedStatus.splice(index, 1);
      }
      if (locale.missingStatus && index === -1) {
        locale.selectedStatus.push(TranslationStatusEnum.MISSING);
      }
      this.filterByStatus(locale);
    }
  }

  onClickEditedStatus(locale, dataTableFilter) {
    this.dataTableFilter = dataTableFilter;
    if (!locale.editedStatus && !this.hasLocaleContainsStatus(locale, TranslationStatusEnum.EDITED)) {
      locale.editedStatus = false;
    } else {
      locale.editedStatus = !locale.editedStatus;
      const index = locale.selectedStatus.indexOf(TranslationStatusEnum.EDITED, 0);
      if (!locale.editedStatus && index > -1) {
        locale.selectedStatus.splice(index, 1);
      }
      if (locale.editedStatus && index === -1) {
        locale.selectedStatus.push(TranslationStatusEnum.EDITED);
      }
      this.filterByStatus(locale);
    }
  }

  saveTranslation(locale) {
    const translationsToSave = new Array<Translation>();
    locale.translations.forEach(translation => {
      if (TranslationStatusEnum.EDITED === translation.status) {
        translationsToSave.push(translation);
      }
    });
    if (translationsToSave.length > 0) {
      this.translationService.updateTranslations(locale.language, translationsToSave).subscribe(
        isSuccess => {
          locale.translations.forEach(translation => {
            translationsToSave.forEach(translationSave => {
              if (translation.key === translationSave.key) {
                if (!translationSave.translation) {
                  translation.status = TranslationStatusEnum.MISSING;
                } else {
                  translation.status = TranslationStatusEnum.OK;
                }
              }
            });
          });
          locale.editedStatus = false;
          locale.okStatus = true;
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('T_INFO'),
            detail: this.translateService.instant('T_BO_TRANSLATION_SAVE_SUCCESS'),
          });
        },
        error => {
          this.logger.error(error);
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant('T_BO_TRANSLATION_SAVE_ERROR'),
          });
        }
      );
    }
  }

  importTranslation(event) {
    this.logger.info('upload translation file');
    this.isLoadingEvent.emit(true);
    const files = event.target.files;
    if (files.length > 0) {
      if (files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_BO_IMPORT_FILE_TYPE_TILE'),
          detail: this.translateService.instant('T_BO_IMPORT_FILE_TYPE_MESSAGE'),
        });
        return;
      }
      const formData: FormData = new FormData();
      formData.append('file', files[0]);
      const uploadUrl = '/localizations/import/' + this.locale.language;
      this.uploadService.uploadFile(uploadUrl, formData).subscribe(uuid => {
        if (uuid.includes(this.IMPORT_OK_RESPONSE)) {
          this.messageService.add({
            severity: 'info',
            summary: this.translateService.instant('T_BO_IMPORT_SUCCESS_TITLE'),
            detail: this.translateService.instant('T_BO_IMPORT_SUCCESS_MESSAGE'),
          });
          // reload translations datas
          this.onImportTranslations.emit();
          this.isLoadingEvent.emit(false);
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_BO_IMPORT_ERROR_TITLE'),
            detail: this.translateService.instant('T_BO_IMPORT_ERROR_MESSAGE'),
          });
        }
      }, (error) => {
        if (error.includes("423")) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_BO_IMPORT_ERROR_TITLE'),
            detail: this.translateService.instant('T_IMPORT_LOCKED_MESSAGE'),
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_BO_IMPORT_ERROR_TITLE'),
            detail: this.translateService.instant('T_BO_IMPORT_ERROR_MESSAGE'),
          });
        }
        this.isLoadingEvent.emit(false);
      });
    }
    // clean files in event
    event.target.value = null;
  }

  exportTranslation() {
    this.isLoadingEvent.emit(true);
    const translationKeys: TranslationKey[] = [];
    if (!isNullOrUndefined((this.dataTableFilter.filteredValue))) {
      for (let i = 0; i < this.dataTableFilter.filteredValue.length; i++) {
        translationKeys.push(this.dataTableFilter.filteredValue[i]);
      }
    } else if (!isNullOrUndefined((this.dataTableFilter))) {
      for (let i = 0; i < this.dataTableFilter.value.length; i++) {
        translationKeys.push(this.dataTableFilter.value[i]);
      }
    }
    this.uploadService.exportTranslationFile(this.locale.language, translationKeys).subscribe(
      response => {
        this.uploadService.createXlsFileFromBackendResponse(response);
        this.isLoadingEvent.emit(false);
      }
    );
  }

  private filterByStatus(locale) {
    if (locale.selectedStatus.length > 0) {
      this.dataTableFilter.filter(locale.selectedStatus, 'status', 'in');
    } else {
      locale.selectedStatus.push(TranslationStatusEnum.NO);
      this.dataTableFilter.filter(locale.selectedStatus, 'status', 'in');
    }
  }

  private hasLocaleContainsStatus(locale, status) {
    let hasStatus = false;
    if (!isNullOrUndefined(locale.translations) && locale.translations.length > 0) {
      locale.translations.find(function (translation) {
        if (translation.status === status) {
          hasStatus = true;
        }
      });
    }
    return hasStatus;
  }

}
