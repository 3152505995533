/**
 * Generic typed Queue representing a fisrt in, first out collection
 */
export class Queue<T> {
  private _queue: T[];
  private _head: number;
  private _tail: number;
  private _count: number;

  /**
   * Creates an empty queue
   */
  public constructor() {
    this.clear();
  }

  /**
   * Adds an item to the back of the queue
   */
  public enqueue(item: T) {
    if ((this._count + 1) === this._queue.length) {
      this._queue.length *= 2;
    }

    this._queue[this._tail] = item;
    this._tail = (this._tail + 1) % this._queue.length;
    ++this._count;
  }

  /**
   * Removes and returns the item at the front of the queue.
   * If the queue is empty 'undefined' is returned.
   */
  public dequeue(): T {
    if (this._count === 0) {
      return undefined;
    }
    const result: T = this._queue[this._head];
    this._head = (this._head + 1) % this._queue.length;
    --this._count;
    return result;
  }

  /**
   * Returns the item at the front of the queue without removing it.
   * If the queue is empty 'undefined' is returned.
   */
  public peek(): T {
    if (this._count === 0) {
      return undefined;
    }
    return this._queue[this._head];
  }

  /**
   * Removes all the items from the queue.
   */
  public clear() {
    this._queue = [];
    this._head = 0;
    this._tail = 0;
    this._count = 0;
    this._queue.length = 4;
  }

  /**
   * Iterate through each item in the queue and call the callback for each item.
   */
  public forEach(callback: (item: T) => any) {
    for (let i: number = this._head;
         i < this._tail;
         i = (i + 1) % this._queue.length) {
      callback(this._queue[i]);
    }
  }

  /**
   * Dequeue item in the queue until no item left and call the callback for each item.
   */
  public emptyWithAction(callback: (item: T) => any) {
    while (this._count !== 0) {
      callback(this.dequeue());
    }
  }

  /**
   * Returns the number of items in the queue.
   */
  public get count(): number {
    return this._count;
  }
}
