import {Characteristic, Dimension, Opt} from '../../../configuration/shared/model/IProduct';
import {BslRangeCharacteristicsSummary, BslRangeCharacteristicsSummaryMap} from '../bsl-reference/offer-data-management-model';
import {BusinessStatus, Reference} from '../../../configuration/shared/model/reference';
import {DocumentationCategory} from '../../../export/shared';
import {Documentation} from '../../../shared/documentation/documentation.model';
import {FunctionalUnit, Incompatibility, SwitchBoardComponent} from '../../../configuration/shared/model/component';
import {ComponentType} from '../../../configuration/shared/model/component-type';
import * as _ from 'lodash';

export class OfferDataManagementHelper {

  /**
   * Get SwitchboardComponentMockup
   */
  public static getSwitchboardComponentForCardDemo() {
    const characteristics: [Characteristic] = this.getMockedCharacteristics();
    const dimension: Dimension = this.getMockedDimension();
    const reference: Reference = this.getMockedReference();
    const options: [Opt] = this.getMockedOptions(reference);
    const documentation: Documentation[] = this.getMockedDocumentaion();
    const functionalUnits: FunctionalUnit[] = this.getMockedFunctionalUnits();
    const incompatibilities: [Incompatibility] = this.getMockedIncompatibilities();

    return new SwitchBoardComponent('fakeId',
      'fakeName',
      'fakeIdProductId',
      characteristics,
      dimension,
      options,
      reference,
      documentation,
      1,
      ['fakeRangeId'],
      1,
      1,
      'fakeQuantityId',
      ComponentType.CUBICLE,
      'fakeExtensible',
      functionalUnits,
      1,
      1,
      incompatibilities,
      1);
  }

  /**
   * Create Characteristic model from BslRangeCharacteristicsSummary
   * @param characteristicBsl
   */
  public static getCharacteristic(characteristicBsl: BslRangeCharacteristicsSummary) {
    const characteristic = new Characteristic();
    characteristic.key = characteristicBsl.key;
    characteristic.name = characteristicBsl.name;
    characteristic.value = characteristicBsl.values[0];
    characteristic.category = characteristicBsl.category;
    return characteristic;
  }

  /**
   * Get mocked characteristics
   */
  public static getMockedCharacteristics() {
    return [{
      key: '-------------',
      value: '------------',
      category: 'fakeCategory',
      measurementUnit: ''
    }] as [Characteristic];
  }

  /**
   * Reinitialize state of the characteristics according to the range state
   *
   * @param bslCharacteristicsMap
   * @param selected
   */
  public static initBslCharacteristicsMap(bslCharacteristicsMap: BslRangeCharacteristicsSummaryMap, selected: string[]) {
    _.mapValues(bslCharacteristicsMap, function (charac) {
      charac.disabled = false;
      charac.checked = selected.includes(charac.key);
      return charac;
    });
    return bslCharacteristicsMap;
  }

  /**
   * Disable all bsl characteristics
   * @param bslCharacteristicsMap
   */
  public static disableBslCharacteristicsMap(bslCharacteristicsMap: BslRangeCharacteristicsSummaryMap) {
    _.mapValues(bslCharacteristicsMap, function (charac) {
      charac.disabled = true;
      charac.checked = false;
      return charac;
    });
    return bslCharacteristicsMap;
  }

  /**
   * Enable all bsl characteristics
   * @param bslCharacteristicsMap
   */
  public static enableBslCharacteristicsMap(bslCharacteristicsMap: BslRangeCharacteristicsSummaryMap) {
    _.mapValues(bslCharacteristicsMap, function (charac) {
      charac.disabled = false;
      charac.checked = false;
      return charac;
    });
    return bslCharacteristicsMap;
  }

  /**
   * Get SVG file name without extension (.svg)
   * @param svgFileName SVG file name
   * @returns {string}
   */
  public static getSvgFileNameWithoutExtension(svgFileName: string): string {
    if (! svgFileName || svgFileName.length <= 4) {
      return svgFileName;
    }
    return svgFileName.substring(0, svgFileName.length - 4);
  }

  /**
   * Get mocked dimenstion
   */
  private static getMockedDimension() {
    return {
      height: 100,
      depth: 100,
      width: 100,
      weight: 100,
      layout: [{name: 'fakeName', url: 'fakeUrl'}]
    } as Dimension;
  }

  /**
   * Get mocked reference
   */
  private static getMockedReference() {
    return {
      ref: 'REF123456789000XXX123',
      description: 'This is just a mockup of the description',
      price: 0,
      netPrice: 0,
      priceRange: 'fakePriceRange',
      availability: 'fakeAvailability',
      deliveryTime: 1,
      status: BusinessStatus.PUBLISHED,
    } as Reference;
  }

  /**
   * Get mocked options
   * @param reference
   */
  private static getMockedOptions(reference: Reference) {
    return [{
      name: 'fakeName',
      category: 'fakeCategory',
      values: [
        {
          value: 'fakeValue',
          selected: false,
          reference: reference,
          quantity: 1
        }
      ],
      htmlElementType: 'fakeHtmlElementType',
      defaultValue: 1,
      onPopUp: false,
      incompatibilities: [{
        values: ['fakeValue'],
        idEntity: 'fakeIdEntity',
        typeEntity: 'fakeTypeEntity'
      }],
      visible: true
    }] as [Opt];
  }

  /**
   * Get mocked documentation
   */
  private static getMockedDocumentaion() {
    return [{
      id: 'fakeId',
      title: 'fakeTitle',
      category: DocumentationCategory.COMMERCIAL,
      language: 'fakeLanguage',
      fileName: 'fakeFileName',
      link: 'fakeLink',
      size: 'fakeSize',
      extension: 'fakeExtension',
      range: 'fakeRange',
      references: ['fakeReference'],
      downloadInProgress: false,
      stamperInformation: {
        pages: [1],
        xCoordinate: 1,
        yCoordinate: 1,
        fontSize: 1,
        font: 'fakeFont',
        fontColor: 'fakeFontColor',
        lineSpacing: 1
      }
    }] as Documentation[];
  }

  /**
   * Get mocked functional units
   */
  private static getMockedFunctionalUnits() {
    return [{
      name: 'DM2L',
      description: 'fakeDescription',
      sldKey: 'DM2L.svg',
      transformerCompatible: false,
      transformer: null
    }] as FunctionalUnit[];
  }

  /**
   * Get mocked incompatibilities
   */
  private static getMockedIncompatibilities() {
    return [{
      values: ['']
    }] as [Incompatibility];
  }
}
