export enum NavigationStep {
  WELCOME = 0,
  PROJECTS = 1,
  OFFERS_CT = 2,
  CHARACTERISTICS = 3,
  TRANSFORMER_SELECTOR = 4,
  CONFIGURATION = 5,
  TRANSFORMER = 6,
  SERVICES_CATALOG = 7,
  PACKAGE_OFFER = 8,
  BILL_OF_MATERIALS = 9,
  EXPORT = 10,
  PROJECT_SHARING = 11,
  PROJECT_BILL_OF_MATERIALS = 12,
  PROJECT_DOCUMENTATION = 13
}
