<div class="layout-content">

  <!-- Description of the process -->
  <div>
    <span *ngIf="!isSettingNumberOfLayout" translate>T_OFFER_DATA_MANAGEMENT_ADD_LAYOUT_MESSAGE</span>
    <div *ngIf="!isSettingNumberOfLayout" class="green-circle-emphasis emphasis">{{currentLayout}}</div>
    <span *ngIf="isSettingNumberOfLayout" class="se-icons layout-add-info information-icon">information_circle</span>
    <span *ngIf="isSettingNumberOfLayout" class="text-justify" translate>T_OFFER_DATA_MANAGEMENT_LAYOUT_NUMBER_MESSAGE</span>
  </div>

  <div *ngIf="!isSettingNumberOfLayout" class="checkbox">
    <input type="checkbox" id="checkAllLayout" class="check-box" (change)="onChangeAllLayout()">
    <label for="checkAllLayout"><span class="accept-text align-right" translate>T_DISPLAY_ALL_LAYOUT</span></label>
  </div>

  <!-- LAYOUT List -->
  <div *ngIf="!isSettingNumberOfLayout" class="scrollable layout-content-size">
    <app-spinner *ngIf="loading" [sizeClass]="'medium'"></app-spinner>
    <div *ngIf="!loading" >
      <div *ngIf="!layoutList || layoutList.length === 0">
        <span translate>T_OFFER_DATA_MANAGEMENT_NO_LAYOUT_AVAILABLE</span>
      </div>
      <div *ngIf="!allLayoutCheck">
        <div *ngFor="let layout of layoutList" class="layout-column">
          <div [ngClass]="{'imageSelected': isSelectedImage(layout)}">
            <img src="{{getLayoutImagePath(layout.pictureFileName)}}" (click)="setLayoutValue(layout)" class="image-hover image-cursor" alt="my Layout">
            <figcaption class="text-center img-name" translate>{{OfferDataManagementHelper.getSvgFileNameWithoutExtension(layout.pictureFileName)}}</figcaption>
          </div>
        </div>
      </div>
      <div *ngIf="allLayoutCheck">
        <div *ngFor="let layout of allLayout" class="layout-column">
          <div [ngClass]="{'imageSelected': isSelectedImage(layout)}">
            <img src="{{getLayoutImagePath(layout.pictureFileName)}}" (click)="setLayoutValue(layout)" class="image-hover image-cursor" alt="my Layout">
            <figcaption class="text-center img-name" translate>{{OfferDataManagementHelper.getSvgFileNameWithoutExtension(layout.pictureFileName)}}</figcaption>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="!isSettingNumberOfLayout && allLayoutCheck" class="layout-pagination">
    <se-pagination
      labelValue="" labelPrev="" labelPerPage="" labelNext="" labelLast="" labelFirst=""
      [perPage]="perPage" [total]="pageTotal" [value]="currentPage" (didChange)="pageChange($event)">
    </se-pagination>
  </div>

  <!-- LAYOUT Not found message -->
  <div *ngIf="!isSettingNumberOfLayout">
    <span class="se-icons layout-add-info help-icon">help</span>
    <span class="icon-label" translate>T_OFFER_DATA_MANAGEMENT_LAYOUT_NOT_FOUND</span>
  </div>

  <!-- Number of layout form -->
  <div *ngIf="isSettingNumberOfLayout" class="form-section">
    <span class="emphasis" translate>T_OFFER_DATA_MANAGEMENT_NUMBER_OF_LAYOUT</span>
    <input [(ngModel)]="numberOfLayout" type="number" min="1" max="10" maxlength="2" (blur)="onChangeLayoutNumber()" >
  </div>

  <!-- Button footer -->
  <div class="row btn-right button-margin">
      <button type="button" class="btn btn-default-white-background" (click)="cancelButton()" translate>T_BUTTON_CANCEL</button>
      <button type="button" *ngIf="isSettingNumberOfLayout || (currentLayout !== numberOfLayout)" [disabled]="(!isSettingNumberOfLayout && !hasSelectedLayout) || !isValidNumber()"
              class="btn btn-default-white-background" (click)="nextButton()" translate>T_BUTTON_NEXT</button>
      <button type="button" *ngIf="!isSettingNumberOfLayout && currentLayout === numberOfLayout"
              [disabled]="!hasSelectedLayout" class="btn btn-default-white-background" (click)="addButton()" translate>T_OFFER_DATA_MANAGEMENT_ADD_LAYOUT</button>
  </div>
</div>
