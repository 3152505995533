import {Component, Input, OnInit} from '@angular/core';
import {NotificationsType, Post, PostCategory} from '../model/post';
import {User} from '../user/user';
import * as moment from 'moment';
import {SessionStorage} from 'ngx-webstorage';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-news-notifications-card',
  templateUrl: './news-notifications-card.component.html',
  styleUrls: ['./news-notifications-card.component.less']
})
export class NewsNotificationsCardComponent {

  @SessionStorage()
  user: User;

  @Input()
  newsCard: Post;
  @Input()
  type: string;

  constructor(private translateService: TranslateService) { }

  /**
   * Method to get news cards published date according to user locale
   */
  getFormatedDate(date: Date) {
    moment.locale(this.user.preferredLanguage);
    return moment(date).format('LL');
  }

  /**
   * Method to get time display
   * @param time the UTC time
   */
  getFormattedTime(time: number[]): string {
    if (time !== null) {
      return time + ' (UTC)';
    }
    return '';
}

  /**
   * Method to get the translation from a key
   * @param post post where to take the param of translation key
   * @param key to translate
   */
  getTranslationFromKey(post: Post, key: string): string {
    return this.translateService.instant(key, {
      date: this.getFormatedDate(post.mainOrResolDate), start_hour: this.getFormattedTime(post.startTime), end_hour: this.getFormattedTime(post.endTime)
    });
  }
  /**
   * Method to retrieve the icon to display
   * @param post the post of tags for the card
   */
  getCardIcon(post: Post) {
    let icon;
    if (this.type === PostCategory.NEWS) {
      if (post.tags.includes('New offer')) {
        icon = 'switchboard_add';
      } else if (post.tags.includes('New feature')) {
        icon = 'it_screen';
      } else if (post.tags.includes('Administration')) {
        icon = 'it_pc';
      } else {
        icon = 'help';
      }
    } else if (this.type === PostCategory.NOTIFICATIONS) {
      if (post.type === NotificationsType.MAINTENANCE) {
        icon = 'maintenance_scheduled';
      } else if (post.type === NotificationsType.INCIDENT) {
        icon = 'notification_critical';
      } else if (post.type === NotificationsType.RESOLUTION) {
        icon = 'notification_ok_stroke';
      } else {
        icon = 'help';
      }
    }
    return icon;
  }

  /**
   * Method to know if icon is a warning
   * @param post the post of tags for the card
   */
  isWarningIcon(post: Post) {
    return this.type === PostCategory.NOTIFICATIONS && (post.type === NotificationsType.INCIDENT || post.type === NotificationsType.MAINTENANCE);
  }


}
