<div class="nav-section">
  <div class="previous">
    <button type="button" class="btn btn-group nav-button"
            [disabled]="!activePrevious"
            (click)="goToPreviousStep()" translate><em class="fa fa-angle-double-left previous-margin"></em>T_BUTTON_PREVIOUS
    </button>
  </div>
  <div class="next">
    <button *ngIf="!hideNext" type="button" class="btn btn-group nav-button"
            [disabled]="!activeNext"
            (click)="goToNextStep()" translate>T_BUTTON_NEXT<em class="fa fa-angle-double-right next-margin"></em>
    </button>
  </div>
</div>

<!-- Confirm dialog -->
<app-common-modal #saveStepModal [title]="'T_OFFER_DATA_MANAGEMENT_SAVE_STEP_TITLE' | translate" [name]="'T_OFFER_DATA_MANAGEMENT_SAVE_STEP_TITLE'"
                  [description]="'T_OFFER_DATA_MANAGEMENT_SAVE_STEP_MESSAGE' | translate" (onClickYesButton)="saveStep()" (onClickNoButton)="noSaveStep()" [size]="'modal-sm'">
</app-common-modal>
