<div [ngClass]="{'card-body': true, 'card-body-height': newsCard.imageBase64}">

  <!-- CARD HEADER -->
  <div class="card-header row">

    <!-- CARD TITLE -->
    <div class="card-title-icon col-lg-7 col-md-7 col-sm-12 col-xs-12">

      <!-- ICON -->
      <div class="se-icons header-icon" [ngClass]="{'warning-color': isWarningIcon(newsCard)}">{{ getCardIcon(newsCard) }}</div>

      <div class="card-title-part">
        <!-- TITLE -->
        <span class="card-title" translate>{{newsCard.title}}</span>
        <!-- SUBTITLE -->
        <span class="card-subtitle"><span translate>T_PROJECTS_NEWS_PUBLISHED_ON</span> {{getFormatedDate(newsCard.publishedDate)}}</span>
      </div>

    </div>

    <!-- CARD TAGS -->
    <div class="card-tag-list col-lg-5 col-md-5 col-sm-12 col-xs-12">
      <div class="card-tags" *ngFor="let tag of newsCard.tags">{{tag}}</div>
    </div>

  </div>

  <!-- CARD TEXT AND IMAGE -->
  <img *ngIf="newsCard.imageBase64" class="card-subject-image" src="data:image/png;base64,{{newsCard.imageBase64}}" alt="{{newsCard.title | translate}}">

  <div class="card-subject" [innerHTML]="getTranslationFromKey(newsCard, newsCard.description)"></div>

</div>
