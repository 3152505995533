import {Component, OnInit, ViewChild} from '@angular/core';
import {DocumentationService} from "../../../shared/documentation/documentation.service";
import {Documentation} from "../../../shared/documentation/documentation.model";
import {DocumentationEditModalComponent} from "./documentation-edit-modal/documentation-edit-modal.component";
import * as moment from "moment";
import {FileService} from "../../shared";
import {CommonModalComponent} from "../../../shared/common-modal/common-modal.component";
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {SessionStorage} from "ngx-webstorage";
import {User} from "../../../shared/user/user";
import {CustomTranslateLoader} from "../../../shared/localization/CustomTranslateLoader";
import {
  DocumentationLinkRefModalComponent
} from "./documentation-link-ref-modal/documentation-link-ref-modal.component";
import {OfferDataManagementService} from "../offer-data-management.service";
import {ReferenceRangePayload} from "../../../shared/model/referenceRangePayload.model";
import {Status} from "../../../project/shared/project-model";

@Component({
  selector: 'app-documentation-management',
  templateUrl: './documentation-management.component.html',
  styleUrls: ['./documentation-management.component.less']
})
export class DocumentationManagementComponent implements OnInit {

  @SessionStorage()
  user: User;

  @ViewChild('addDocumentationModal') addDocumentationModal: DocumentationEditModalComponent;
  @ViewChild('editDocumentationModal') editDocumentationModal: DocumentationEditModalComponent;
  @ViewChild('linkRefModal') linkRefModal: DocumentationLinkRefModalComponent;
  @ViewChild('deleteDocumentationModal') deleteDocumentationModal: CommonModalComponent;

  allDocuments: Documentation[] = [];

  docToBeDeleted: Documentation;

  loading: boolean;
  loadingTranslations: boolean;

  referencesRangesPairs: ReferenceRangePayload[];

  constructor(private documentationService: DocumentationService,
              private fileService: FileService,
              private messageService: MessageService,
              private translateService: TranslateService,
              private customTranslateLoader: CustomTranslateLoader,
              private odmService: OfferDataManagementService) {
  }

  ngOnInit() {
    this.getAllDocumentsOfCountry()
  }

  getAllDocumentsOfCountry() {
    this.loading = true;
    return this.documentationService.getAllDocumentsOfCountry().subscribe(documents => {
      this.allDocuments = documents;
      this.translateDocsTitle()
      this.loading = false;
    });
  }

  translateDocsTitle() {
    this.allDocuments.forEach(doc => {
      doc.titleTranslated = this.translateService.instant(doc.title);
    })
  }

  getAllLocalFileDocuments() {
    return this.allDocuments.filter(doc => doc.localFileId);
  }

  onClickAddDocumentation() {
    this.addDocumentationModal.showAddMode();
  }

  onClickEditDocumentation(documentation: Documentation) {
    this.editDocumentationModal.showEditMode(documentation);
  }

  onClickLinkRef(documentation: Documentation) {
    if (!this.referencesRangesPairs) {
      this.odmService.getAllReferenceRangePairs().subscribe((referencesRangesPairs) => {
        this.referencesRangesPairs = referencesRangesPairs;
        this.linkRefModal.show(documentation, this.referencesRangesPairs);
      });
    } else {
      this.linkRefModal.show(documentation, this.referencesRangesPairs);
    }
  }

  onClickDownloadDocument(documentation: Documentation) {
    this.fileService.get(documentation.localFileId).subscribe(file => {
      this.fileService.downloadBase64File(documentation.extension, file.data, documentation.fileName + "." + documentation.extension)
    })
  }

  onClickDeleteDocumentation(documentation: Documentation) {
    this.deleteDocumentationModal.show();
    this.docToBeDeleted = documentation;
  }

  onClickSaveLinkedRefs(documentation: Documentation) {
    this.loading = true;
    this.documentationService.updateDocumentation({
      id: documentation.id,
      references: documentation.references
    }).subscribe((document) => {
      this.allDocuments = this.allDocuments.map(doc => doc.id === document.id ? document : doc);
      this.translateDocsTitle();
      this.loading = false;
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('T_INFO'),
        detail: this.translateService.instant('T_DOCUMENTATION_UPDATE_SUCCESS'),
      });
    }, (error) => {
      this.loading = false;
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('T_ERROR'),
        detail: this.translateService.instant('T_DOCUMENTATION_UPDATE_ERROR'),
      });
    })
  }

  deleteDocumentation() {
    this.loading = true;
    this.fileService.delete(this.docToBeDeleted.localFileId).subscribe(() => {
      this.documentationService.deleteDocumentation(this.docToBeDeleted.id).subscribe(() => {
        this.allDocuments = this.allDocuments.filter(doc => doc.id !== this.docToBeDeleted.id);
        this.docToBeDeleted = null;
        this.loading = false;
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_DOCUMENTATION_DELETE_SUCCESS'),
        });
      }, (error) => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_DOCUMENTATION_DELETE_ERROR'),
        });
      })
    })
  }

  onDocumentationUpdated(documentUpdated: Documentation) {
    if(!this.allDocuments.find(doc => doc.id === documentUpdated.id)){
      this.allDocuments.push(documentUpdated);
      // this does nothing but refresh the list by triggering a change detection
      this.allDocuments = this.allDocuments.map(doc => doc);
    }else{
      this.allDocuments = this.allDocuments.map(doc => doc.id === documentUpdated.id ? documentUpdated : doc);
    }
    this.loadingTranslations = true;
    this.customTranslateLoader.getTranslation(this.user.preferredLanguage, true).subscribe(res => {
      this.translateService.setTranslation(this.user.preferredLanguage, res, false);
      this.translateDocsTitle();
      this.loadingTranslations = false;
    });
  }

  getLastUpdateDateFormatted(lastUpdateDate: number) {
    if (!lastUpdateDate) {
      return "";
    }
    return moment(lastUpdateDate).format('DD/MM/YYYY');
  }

  getTranslation(key) {
    return this.translateService.instant(key);
  }

  getLinkedReferenceListAsString(documentation: Documentation) {
    let referencesAsString = '';
    if (documentation.references) {
      documentation.references.forEach(ref => {
        referencesAsString = referencesAsString + ref + ' ';
      })
    }
    return referencesAsString;
  }

  protected readonly Status = Status;
}
