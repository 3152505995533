import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ServicesCatalogPageComponent} from './services-catalog-page/services-catalog-page.component';
import {TranslateModule} from '@ngx-translate/core';
import {TreeTableModule} from "primeng/treetable";
import {EcorealCtSharedModule} from '../shared/shared.module';
import {SharedModule} from "primeng/api";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    TreeTableModule,
    EcorealCtSharedModule,
    SharedModule
  ],
  declarations: [ServicesCatalogPageComponent],
  exports: [ServicesCatalogPageComponent],
  providers: []
})
export class ServicesCatalogModule { }
