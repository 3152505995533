/* angular modules */
import {Component, ViewChild} from '@angular/core';
/* ngx modules */
import {TranslateService} from '@ngx-translate/core';
import {SessionStorage} from 'ngx-webstorage';
/* app modules */
import {ModalType} from '../shared/model/modal_type';
import {ActiveTabType} from '../shared/model/activeTab_Type';
import {SwitchBoardComponent} from '../shared/model/component';
import {WrapperModalContent} from '../shared/model/wrapperModalContent';
import {Documentation} from '../../shared/documentation/documentation.model';
import {DocumentationHelperService} from '../../export/shared';
import {StaticDocumentationService} from '../../export/shared';
import {MessageService} from 'primeng/api';
import {Localization} from '../../shared/model/localization.model';
import {Item, Project} from '../../project/shared/project-model';
import {ComponentType} from '../shared/model/component-type';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {LoggerService} from '../../shared/logging/logger.service';
import {UtilService} from '../../shared/util/util.service';
import {ProjectService} from '../../project/shared/project.service';
import {FileService, RangeDiscount} from '../../admin/shared';


@Component({
  selector: 'app-card-component-modal',
  templateUrl: './card-component-modal.component.html',
  styleUrls: ['./card-component-modal.component.less'],
  exportAs: 'child'
})
export class CardComponentModalComponent {
  @SessionStorage()
  user;
  @SessionStorage()
  localization: Localization;
  @SessionStorage()
  currentItemct: Item;
  @SessionStorage()
  currentProject: Project;
  @SessionStorage()
  isDemoMode: boolean;
  @SessionStorage()
  netPriceByPartnerDiscount: RangeDiscount[];
  @ViewChild('childModal') public childModal: CommonModalComponent;
  component: SwitchBoardComponent = null;
  componentDocuments: Documentation[] = [];
  activeTab;
  modalType: ModalType;
  hasTransformer = ModalType.TRANSFORMER_MODAL;
  characteristicsTab = ActiveTabType.CHARACTERISTICS;
  layoutsTab = ActiveTabType.LAYOUTS;
  documentsTab = ActiveTabType.DOCUMENTS;
  transformerTab = ActiveTabType.TRANSFORMER;
  cubicleType: ComponentType = ComponentType.CUBICLE;
  transfoType: ComponentType = ComponentType.TRANSFORMER;
  constructor(private logger: LoggerService,
              private translateService: TranslateService,
              private staticDocService: StaticDocumentationService,
              private messageService: MessageService,
              private projectService: ProjectService,
              private utilService: UtilService,
              private documentationHelperService: DocumentationHelperService,
              private fileService: FileService) {
  }

  show(wrapperModalContent: WrapperModalContent) {
    this.component = wrapperModalContent.component;
    this.activeTab = wrapperModalContent.activeTab;
    this.modalType = wrapperModalContent.modalType;
    this.componentDocuments = wrapperModalContent.documents;
    this.childModal.show();
  }

  hide() {
    this.component = null;
    this.childModal.hide();
  }

  downloadDocument(document: Documentation) {
    if(document.localFileId){
      this.fileService.get(document.localFileId).subscribe(
        file => {
          this.fileService.downloadBase64File(document.extension, file.data, document.fileName);
        }
      )
    }
    else if (!document.link || document.link === '') {
      document.downloadInProgress = true;
      this.staticDocService.generateStaticDocument(this.currentProject.id, this.currentItemct.id,
        this.localization.country, document.language, document.fileName, document.stamperInformation).subscribe(
        response => {
          this.documentationHelperService.createDocumentFromBackendResponse(this.currentItemct.id, this.currentProject.id,
            document.id, null, response);
          setTimeout(function () {
            document.downloadInProgress = false;
          }, 1000);
        },
        (error) => {
          this.logger.error('download static document ' + document.fileName + error.toString());
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant('T_EXPORT_STATIC_ERROR'),
          });
          document.downloadInProgress = false;
        }
      );
    }
    else if (document.link && document.link !== '') {
      window.open(document.link);
    }
  }

  getPriceToSend() {
    if (this.isNetPriceByPartner() && this.user.currentMySEAccount === null) {
      const rangeDiscount = this.netPriceByPartnerDiscount.find(disc => this.component && this.component.rangeId && disc.rangeId === this.component.rangeId[0]);
      if (rangeDiscount) {
        return this.component.reference.price * (1 - rangeDiscount.discount);
      }
    }
    if (this.component && this.component.reference) {
      if (this.component.reference.netPrice) {
        // For mySE Net price
        return this.component.reference.netPrice;
      }
      if (this.component.reference.price) {
        return this.component.reference.price;
      }
    }
    return null;
  }

  getPriceKeyLabel(component: SwitchBoardComponent) {
    if (this.isNetPriceByPartner() || this.user.currentMySEAccount !== null) {
      return 'T_NET_PRICE';
    }
    return 'T_PUBLIC_PRICE';
  }

  showLayoutTab(): boolean {
    return this.component.type !== ComponentType.TRANSFORMER && this.component.dimensions !== null;
  }

  /**
   * Method to display fo public price or fo net price
   */
  isNetPriceByPartner() {
    return this.netPriceByPartnerDiscount !== null && this.netPriceByPartnerDiscount.some(disc => disc.discount > 0);
  }

  /**
   * Method to know if it is a CPQ project
   * @returns {boolean}
   */
  isCpqProject(): boolean {
    return this.projectService.isCpqProject(this.currentProject);
  }

}
