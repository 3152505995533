import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {SessionStorage} from 'ngx-webstorage';
import {Item, Status} from '../../project/shared/project-model';
import {CharacteristicView, MultiOption, Option} from '../shared/characteristics.view';
import {TranslateService} from '@ngx-translate/core';
import {IMultiSelectSettings, IMultiSelectTexts} from 'angular-2-dropdown-multiselect';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-characteristic-fieldset',
  templateUrl: './characteristic-fieldset.component.html',
  styleUrls: ['./characteristic-fieldset.component.less'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        'height': '0px', opacity: 0
      })),
      transition('void <=> *', animate(150)),
    ])
  ]
})
export class CharacteristicFieldsetComponent implements OnInit, OnChanges {

  @Input()
  listOfElement: CharacteristicView[] = [];

  @Input()
  title: string;

  @Input()
  accessInfo: string;

  @Input()
  readOnly = false;

  @Input()
  display = true;

  @Output()
  displayChange = new EventEmitter<boolean>();

  @SessionStorage()
  currentItemct: Item;

  @Output()
  changeValue = new EventEmitter<CharacteristicView>();

  @Output()
  clickOnLast = new EventEmitter();

  // Multi select settings
  mySettings: IMultiSelectSettings;
  myTexts: IMultiSelectTexts;
  chosenOptionsMultiselectModel: any[] = [];

  // Expose Status enum to the view
  Status = Status;

  constructor(private translateService: TranslateService) {
    // Settings configuration
    this.mySettings = {
      enableSearch: false,
      checkedStyle: 'fontawesome',
      containerClasses: 'dropdown my-dropdown ct-multiselect',
      buttonClasses: 'btn btn-default',
      dynamicTitleMaxItems: 2,
      showUncheckAll: true
    };

    // Text configuration
    this.myTexts = {
      checkAll: this.translateService.instant('T_SELECT_ALL'),
      uncheckAll: this.translateService.instant('T_WITHOUT'),
      checked: 'item selected',
      checkedPlural: 'items selected',
      searchPlaceholder: 'Find',
      defaultTitle: this.translateService.instant('T_WITHOUT'),
      allSelected: 'All selected'
    };


  }

  ngOnInit() {
    this.convertToMultiSelect();
  }

  ngOnChanges() {
    // We must treat the view to be multi select compatible
    this.listOfElement.filter(elem => elem.htmlElementType === 'multiSelect').forEach(elem => {
      // set previously selected options
      this.chosenOptionsMultiselectModel[elem.id] = elem.options.filter(opt => opt.selected).map(a => a.value);
      // generate option to multiselect format
      elem.options = this.getElementOptionsSorted(elem);
      elem.multiOptions = elem.options.map(opt => new MultiOption(this.translateService.instant(<string>opt.value), opt.value, 1));
    });
  }

  getElementsSorted(){
    return this.listOfElement.sort((a, b) => a.orderIndex - b.orderIndex);
  }

  getElementOptionsSorted(element: CharacteristicView) {
    if (this.currentItemct.range === null){
      //case of Package offer
      return element.options;
    }
    return element.options.sort((a, b) => a.orderIndex - b.orderIndex);
  }

  /**
   * Dispatch new selected value to parent component
   * @param event event containing the new selected value
   * @param {CharacteristicView} element the element changed
   */
  onChangeValue(event, element: CharacteristicView) {
    if (element.htmlElementType !== 'multiSelect' && !!element.options) {
      // event is a string
      element.options.forEach(opt => opt.selected = String(opt.value) === event);
    }
    if (!!element.packageOptions) {
      element.packageOptions.forEach(opt => opt.selected = String(opt.id) === event);
    }
    element.chooseValue = true;
    this.changeValue.emit(element);
  }

  /**
   * Select the current element option
   * @param event event containing the new selected value
   * @param {CharacteristicView} element the element changed
   */
  onSelectValue(event, element: CharacteristicView) {
    element.options.find(o => o.value === event).selected = true;
  }

  /**
   *  Unselect the current element option
   * @param event event containing the new selected value
   * @param {CharacteristicView} element the element changed
   */
  onUnselectValue(event, element: CharacteristicView) {
    element.options.find(o => o.value === event).selected = false;
  }

  /**
   * Return true if the caracteristics is of html type
   * @param element
   * @param type input|select|multiselect
   * @returns {boolean}
   */
  isHtmlElement(element: CharacteristicView, type: string): boolean {
    return !!element.options && element.options.length >= 2 && element.htmlElementType === type;
  }

  /**
   * Return true if a value has been already recorded for this element
   * And it update the list of the value for this element sorting
   * first the value already selected
   * @param element
   * @returns {boolean}
   */
  valueAlreadyExists(element: CharacteristicView): boolean {
    if (!!element.options) {
      return element.options.some(opt => opt.selected);
    }
    if (!!element.packageOptions) {
      return element.packageOptions.some(opt => opt.selected);
    }
    return false;
  }

  /**
   * Event when clicking on the last element of the list
   * @param isLast if it is the last element
   */
  isClickOnLast(isLast: boolean) {
    if (isLast) {
      this.clickOnLast.emit();
    }
  }

  isOnlyOneAvailableOption(element: CharacteristicView): boolean {
    return  !!element.options && element.options.filter(opt => !opt.disabled).length === 1;
  }

  /**
   * Function that determine if an element has no value defined in the selection
   * or if it is hidden
   * @param element
   */
  noValueOrHidden(element: CharacteristicView): boolean {
    return !element.chooseValue && !!element.options && element.options.filter(opt => !opt.disabled).length === 0 ||
      !element.chooseValue && !!element.packageOptions && element.packageOptions.length === 0 ||
      element.htmlElementType === 'hidden';
  }

  getOnlyAvailableOption(element: CharacteristicView): Option {
    return !!element.options ? element.options.find(opt => !opt.disabled) : null;
  }

  /**
   * Method to reset the setted value of an electrical filter
   * @param element
   */
  resetValue(element: CharacteristicView) {
    element.chooseValue = false;
    if (!!element.options) {
      element.options.forEach(opt => opt.selected = false);
    }
    if (!!element.packageOptions) {
      element.packageOptions.forEach(opt => opt.selected = false);
    }
    this.changeValue.emit(element);
  }

  /**
   * Determine if we need to display accessories section
   * We do not display this section if there is not accessory to display
   */
  displayAccessories() {
    return (this.listOfElement.length > 0 && this.listOfElement.filter(element => !this.noValueOrHidden(element)).length > 0);
  }

  /**
   * Method call to display or hide accessories
   */
  changeDisplay() {
    this.display = !this.display;
    this.displayChange.emit(this.display);
  }

  /**
   * Function to enrich view object with multiselect data structure
   */
  private convertToMultiSelect() {
    // We must treat the view to be multi select compatible
    this.listOfElement.filter(elem => elem.htmlElementType === 'multiSelect').forEach(elem => {
      // set previously selected options
      this.chosenOptionsMultiselectModel[elem.id] = elem.options.filter(opt => opt.selected).map(a => a.value);
      // generate option to multiselect format
      elem.multiOptions = elem.options.map(opt => new MultiOption(this.translateService.instant(<string>opt.value), opt.value, opt.quantity));
    });
  }

}
