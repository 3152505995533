import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {FileService} from "../../admin/shared";

@Component({
  selector: 'app-element-tile',
  templateUrl: './element-tile.component.html',
  styleUrls: ['./element-tile.component.less']
})
export class ElementTileComponent implements OnInit, OnChanges{

  @Input()
  readOnly: boolean;

  @Input()
  name: string;

  @Input()
  pictureUrl: string;

  @Input()
  picturesAssetsPath: string;

  @Input()
  pictureFileId: string;

  @Input()
  clickable = true;

  @Input()
  customClass = "";

  @Input()
  optionalTooltip?: string;

  @Input()
  status?: string;

  @Input()
  actionsMenu?: MenuItem[];

  @Input()
  boxShadow? = true;

  @Output()
  clickOnTile: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  clickOnActionMenu: EventEmitter<string> = new EventEmitter<string>()

  pictureBase64: string;
  
  constructor(private fileService: FileService) { }

  click() {
    this.clickOnTile.emit();
  }

  actions = [];

  ngOnInit() {
    if(this.actionsMenu){
      this.actionsMenu.forEach((menu) => {
        menu.items.forEach((item) => {
          item.command = () => {
            this.clickOnActionMenu.emit(item.id);
          }
        })
        this.actions.push(menu);
      })
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['pictureFileId'] && this.pictureFileId){
      this.fileService.get(this.pictureFileId).subscribe(file => {
        this.pictureBase64 = file.data;
      });
    }
  }

  getImageFromBase64(imgBase64: string){
    return "data:image/JPEG;base64, "+imgBase64;
  }
}
