export class Distributor {
  id: string;
  countryName: string;
  region: string;
  name: string;
  phone: string;
  email: string;
  contactPerson: string;
  v2Contact: string;
  salesContact: string;
}
