/* Angular modules */
import {Component, EventEmitter, Input, Output} from '@angular/core';
/* ngx modules */
import {PageChangedEvent} from 'ngx-bootstrap/pagination/pagination.component';
/* app modules */
import {Project} from '../shared/project-model';
import {Observable} from 'rxjs/Observable';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.less']
})
export class ProjectListComponent {

  @Input()
  displayedProjects: Array<Project>;

  @Input()
  resultFiltredProjects: Array<Project>;

  @Input()
  onMyLastProjectsTab: boolean;

  @Input()
  currentPage: number;

  @Input()
  deleteItemEvent: Observable<void>;

  @Output()
  onClickShowModal = new EventEmitter<string>();

  @Output()
  onPageChanged = new EventEmitter<PageChangedEvent>();

  constructor() {
  }

  showModal(type: string) {
    this.onClickShowModal.emit(type);
  }

  pageChanged(event: PageChangedEvent) {
    this.onPageChanged.emit(event);
  }
}
