<app-common-modal #modalDistributor [name]="'modalDistributor'" [title]="'T_BOM_CHOOSE_DISTRIBUTOR_TITLE' | translate"
                  [withFooter]="false" [closable]="false">
  <div modal-body>
    <div class="body-margin">
      <form class="form-horizontal">
        <div *ngIf="distributors.length === 0" translate>T_MAIL_DISTRIBUTOR_LIST_ERROR</div>
        <div class="form-group select-region" *ngIf="distributors.length>0">
          <label for="selectRegion" class="col-xs-4 control-label text-left field-mandatory"
                 translate>T_BOM_CHOOSE_REGION</label>
          <div class="col-xs-8">
            <select id="selectRegion" class="select" required
                    (change)="updateSelectedRegion($event.target.value)">
              <option value="" disabled selected translate>T_BOM_CHOOSE</option>
              <ng-container *ngFor="let region of distributorRegions">
                <option [value]="region">{{region}}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="form-group select-distributor" *ngIf="distributorsFilteredByRegions.length>0">
          <label for="selectDistributor" class="col-xs-4 control-label text-left field-mandatory"
                 translate>T_BOM_CHOOSE_DISTRIBUTOR</label>
          <div class="col-xs-8">
            <select id="selectDistributor" class="select" required
                    (change)="updateSelectedDistributor($event.target.value)">
              <option value="" selected translate>T_BOM_CHOOSE</option>
              <ng-container *ngFor="let distributor of distributorsFilteredByRegions">
                <option [value]="distributor.id">{{distributor.name}}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-default-white-background" [disabled]="!selectedDistributor"
              (click)="clickOkButton();hide()" translate>T_BUTTON_OK
      </button>
      <button class="btn btn-default-white-background" (click)="clickCancelButton(); hide()" translate>T_BUTTON_CANCEL
      </button>
    </div>
  </div>
</app-common-modal>
