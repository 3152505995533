import {Component, Input} from '@angular/core';
import {RatingFeedback} from '../shared/model/ratingFeedback';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.less']
})
export class RateComponent {

  @Input()
  questionFeedback: RatingFeedback;

  select(number) {
    this.questionFeedback.rate = number;
  }
}
