<div class="project-bom-button pull-right" (click)="goToProjectBom()">
  <span class="se-icons font-header dark-grey-color">segment_retail</span>
  <div class="project-bom-box">
    <div class="project-bom-box-label" translate>T_PROJECT_BOM</div>
    <div *ngIf="showPrices" class="project-bom-box-sub-label">
      <span *ngIf="totalPrice && !totalPrice.myseMessage">{{formattedProjectPrice}} {{currency}}</span>
      <span *ngIf="totalPrice && totalPrice.myseMessage && totalPrice.myseMessage !==''" class="se-icons brand-warning price-row"
            tooltip="{{(totalPrice.myseMessage)}}" placement="left"
            container="body">notification_critical_wired
        </span>
    </div>
  </div>
</div>
