/* angular modules */
import {Component, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
/* app modules */
import {DistributorService} from '../../admin/shared/distributor/distributor.service';
import {Distributor} from '../shared/distributor';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {AccessControlService} from '../../core';
import {User} from '../../shared/user/user';
import {Resource} from '../../core/access-control/enum';


@Component({
  selector: 'app-bom-distributor-modal',
  templateUrl: './bom-distributor-modal.component.html',
  styleUrls: ['./bom-distributor-modal.component.less']
})
export class BomDistributorModalComponent implements OnChanges {

  @Input()
  distributors: Distributor[];

  @SessionStorage()
  user: User;

  distributorRegions: Set<string> = new Set();
  distributorsFilteredByRegions: Distributor[] = [];
  selectedDistributor: Distributor;

  @ViewChild('modalDistributor') public modalDistributor: CommonModalComponent;
  @Output() onClickOKButton = new EventEmitter<string>();
  @Output() onClickNoButton = new EventEmitter<string>();

  constructor(private distributorService: DistributorService,
              private accessControlService: AccessControlService) {
  }

  public ngOnChanges(): void {
    if (!this.accessControlService.can(this.user.role, Resource.PURCHASE_BY_SCHNEIDER)) {
      this.distributorRegions = new Set([...this.distributors
        .map(distributor => distributor.region).sort()]);
    }
  }

  show() {
    this.modalDistributor.show();
  }

  hide() {
    this.modalDistributor.hide();
  }

  clickCancelButton() {
    this.onClickNoButton.emit();
  }

  clickOkButton() {
    this.onClickOKButton.emit(this.selectedDistributor.email);
  }

  public updateSelectedRegion(selectedRegion: string) {
    this.selectedDistributor = undefined;
    this.distributorsFilteredByRegions = this.distributors
      .filter(distributor => distributor.region === selectedRegion)
      .sort((dist1, dist2) => dist1.name.toLowerCase() < dist2.name.toLowerCase() ? -1 : 1);
  }

  public updateSelectedDistributor(value: string) {
    this.selectedDistributor = this.distributors.find(dist => dist.id === value);
  }

}
