<div class="panel-group">
  <div class="panel panel-default projectPanel" *ngFor="let project of displayedProjects ">
    <app-project-card
      [project]="project"
      [deleteItemEvent]="deleteItemEvent"
      [onMyLastProjectsTab]="onMyLastProjectsTab"
      (onClickShowModal)="showModal($event)"></app-project-card>
  </div>
</div>
<div class="projects-pagination">
  <pagination *ngIf="resultFiltredProjects?.length > 10" [(ngModel)]="currentPage"
              [totalItems]="resultFiltredProjects.length" [itemsPerPage]="10" [maxSize]="5" [boundaryLinks]="true"
              firstText="{{'T_PAGINATION_FIRST_BUTTON' | translate}}" previousText="{{'T_PAGINATION_PREVIOUS_BUTTON' | translate}}" nextText="{{'T_PAGINATION_NEXT_BUTTON' | translate}}" lastText="{{'T_PAGINATION_LAST_BUTTON' | translate}}"
              (pageChanged)="pageChanged($event)"></pagination>
</div>
