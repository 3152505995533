import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {CommonModalComponent} from '../../../../shared/common-modal/common-modal.component';
import {Range} from '../../../../shared/model/range-model';
import {RestrictedAccessAvailable} from "./restricted-access.enum";
import {TranslateService} from "@ngx-translate/core";
import {FileService, UploadService} from "../../../shared";
import {LoggerService} from "../../../../shared/logging/logger.service";
import {MessageService} from "primeng/api";
import {RangeType} from "../../../../shared/model/range-type";

export class EditRangeInfoPayload {
  rangeId: string;
  bslRangeId: number;
  restrictedAccess: string;
  customImageId: string;
}

@Component({
  selector: 'app-odm-range-management-modal',
  templateUrl: './odm-range-management-modal.component.html',
  styleUrls: ['./odm-range-management-modal.component.scss']
})
export class OdmRangeManagementModalComponent {
  @Output()
  saveRangeInfo: EventEmitter<EditRangeInfoPayload> = new EventEmitter<EditRangeInfoPayload>();

  @ViewChild('rangeManagementModal', {static: false}) rangeManagementModal: CommonModalComponent;

  range: Range;

  editRangeInfoPayload: EditRangeInfoPayload = {
    rangeId: null,
    bslRangeId: null,
    restrictedAccess: null,
    customImageId: null
  };

  selectedAccess: string;

  loadingImage = false;

  protected readonly RestrictedAccessAvailable = RestrictedAccessAvailable;

  constructor(
    private translateService: TranslateService,
    private uploadService: UploadService,
    private logger: LoggerService,
    private messageService: MessageService,
    private fileService: FileService) {
  }

  show(range: Range) {
    this.range = range;
    this.editRangeInfoPayload.rangeId = range.id;
    this.editRangeInfoPayload.bslRangeId = range.bslRangeId;
    this.editRangeInfoPayload.customImageId = range.rangePicture.imageFileId;
    this.selectedAccess = RestrictedAccessAvailable.find(access => access.key === range.restrictedAccess) ? range.restrictedAccess : "NONE";
    this.rangeManagementModal.show();
  }

  cancel() {
    this.editRangeInfoPayload = {rangeId: null, bslRangeId: null, restrictedAccess: null, customImageId: null};
    this.hide();
  }

  hide() {
    this.rangeManagementModal.hide();
  }

  onChangeRestrictedAccess(access: string) {
    this.selectedAccess = access;
    this.editRangeInfoPayload.restrictedAccess = access;
  }


  clickOnSaveRangeButton() {
    this.saveRangeInfo.emit(this.editRangeInfoPayload);
  }

  isValidForm() {
    return this.editRangeInfoPayload && this.editRangeInfoPayload.rangeId;
  }

  imageUpload(event) {
    this.logger.info('upload package offer image');
    const files = event.target.files;
    if (files.length > 0) {
      const allowedFormat = 'image/jpeg';
      const maximumSize = 8000 * 1024; // 8mb
      if (files[0].type !== allowedFormat || files[0].size > maximumSize) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_UPLOAD_IMAGE_FORMAT_ERROR'),
        });
        return;
      }
      const formData: FormData = new FormData();
      formData.append('file', files[0]);
      this.loadingImage = true;
      this.fileService.create(formData).subscribe(file => {
        this.editRangeInfoPayload.customImageId = file.id;
        this.loadingImage = false;
      }, () => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_UPLOAD_IMAGE_ERROR'),
        });
        this.loadingImage = false;
      });
    }
    // clean files in event
    event.target.value = null;
  }

  displayAllEditableData() {
    return this.range && this.range.rangeType !== RangeType.IMPORTED;
  }
}
