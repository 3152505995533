<div class="row table-section">
  <p-treeTable [value]="serviceData" [ngClass]="{'bomHeader': true}">
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" [ngStyle]="{'width': col.width}" [ngClass]="col.styleClass">
          <span>{{col.label | translate}}</span>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
      <tr>
        <td *ngFor="let col of cols" [ngClass]="col.styleClass">
          <p-treeTableToggler [rowNode]="rowNode" *ngIf="col.field === 'category'"></p-treeTableToggler>
          <!-- CHECKBOX FOR SELECTION -->
          <label *ngIf="col.label === '' && !rowData['category']"  [for]="rowData.reference" class="custom-checkbox-container">
            <input class="checkbox" type="checkbox" [id]="rowData.reference" [checked]="isSelected(rowData.reference)" [value]="rowData.reference"
                   (click)="modificationConfirmation(rowData.reference)"
                   [disabled]="rowData.disabled || currentItemct.status === status.ordered || isReadonlyProject()">
            <span class="checkmark checkmark-height"></span>
          </label>
          <!-- OTHERS FIELDS -->
          <span *ngIf="col.label !== ''"> {{rowData[col.field] | translate}}</span>
          <app-spinner [sizeClass]="'small'" [vCenter]="false" *ngIf="col.label==='T_DOC_DATASHEETS' && rowData.isLoadingDoc"></app-spinner>
          <span *ngIf="col.label==='T_DOC_DATASHEETS' &&
           hasDatasheet(rowData.doc) &&
            !rowData.isLoadingDoc"
                (click)="downloadDatasheet(rowData.doc.link)"
                class="se-icons download-icon">action_download</span>
          <span *ngIf="col.label==='T_DOC_DATASHEETS' &&
          rowData.reference !== null && rowData.reference !== undefined &&
          !hasDatasheet(rowData.doc) &&
          !rowData.isLoadingDoc"
                tooltip="{{'T_DOC_DATASHEET_MISSING' |translate}}"
                placement="left"
                container="body"
                class="se-icons brand-warning"> information_circle</span>
        </td>
      </tr>
    </ng-template>
  </p-treeTable>
</div>

<!-- confirm dialog -->
<app-common-modal #LooseModal [title]="'T_LOOSE_DATA_TITLE' | translate" [name]="'T_LOOSE_DATA_TITLE'"
                  [description]="'T_LOOSE_DATA' | translate" (onClickYesButton)="confirmLooseData(toBeModifiedReference)"
                  (onClickNoButton)="reinitData()" [size]="'modal-sm'">
</app-common-modal>
