<div class="container">
  <div class="row">
    <div class="question-align col-xs-12">
      <span class="required-red-asterisk feedback-question" [innerHTML]="questionFeedback.question"></span>
    </div>
    <div class="satisfied-not-satisfied">
      <span class="not-satisfied" translate>T_FEEDBACK_NOT_SATISFIED</span>
      <span class="satisfied" translate>T_FEEDBACK_SATISFIED</span>
    </div>
    <div class="question-align col-xs-12">
      <div class="rate-container">
        <span *ngFor="let number of [0,1,2,3,4,5,6,7,8,9,10]"
          [ngClass]="{'selected-element': number === questionFeedback.rate}"
          class="rate-icon" (click)="select(number)">{{number}}</span>
      </div>
    </div>
  </div>
</div>
