/* Angular modules */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
/* ngx modules */
import {LoggerService} from '../logging/logger.service';
/* app modules */
import {NavigationLinkEnum} from './navigationLink-enum';
import {RightsService} from '../rights/rights.service';


@Injectable()
export class AdminGuardService implements CanActivate {

  constructor(private router: Router,
              private logger: LoggerService,
              private rightsService: RightsService) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.rightsService.isAdmin()) {
      return true;
    }
    this.logger.trace('NavigationGuardService blocks navigation to : /' + next.url);
    this.router.navigate([NavigationLinkEnum.PROJECTS]);
    return false;
  }
}
