<div *ngIf="listOfElement && listOfElement.length > 0" class="packageItems">
  <legend class="shGreenTitle">
    <div class="col"><span translate>{{title}}</span></div>
  </legend>

  <div class="row" *ngFor="let element of listOfElement">
    <div class="col-xs-9">
      <label class="control-label" translate>{{element.name}}</label>
    </div>

    <div class="col-xs-3">
      <label  class="control-label"><span translate>T_BOM_QUANTITY</span> : {{ element.quantity }}</label>
    </div>
  </div>
</div>
