import {Component, Input, OnInit} from '@angular/core';
import {SwitchBoardComponent} from '../shared/model/component';
import {SessionStorage} from 'ngx-webstorage';
import {Item} from '../../project/shared/project-model';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less']
})

export class LayoutComponent implements OnInit {

  @SessionStorage()
  currentItemct: Item;

  @Input()
  displayedComponents: Array<SwitchBoardComponent>;

  @Input()
  rangeName: string;

  constructor() {
  }

  ngOnInit(): void {
    $('#zoomLayout').append('<script src="assets/scripts/jquery.panzoom_v4.js"></script>')
      .append('<script src="assets/scripts/config.zoom_v4.js"></script>');
  }

  isRm6Au(): boolean {
    return this.rangeName === 'T_RM6_AU';
  }

  isSm6Au(): boolean {
    return this.rangeName === 'T_SM6_24_AU';
  }

  showMaxHeight(): boolean {
    return this.isSm6Au();
  }

  showTotalWidth(): boolean {
    return (this.isSm6Au() || this.isRm6Au());
  }

  isNeedToRotateLabel(): boolean {
    switch (this.rangeName) {
      case 'T_SM6_24_AU' :
        return true;
      default :
        return false;
    }
  }
}
