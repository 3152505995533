import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OfferDataManagementConfigurationStep, Range, RangeStatus} from '../../../../shared/model/range-model';
import {OfferDataManagementService} from '../../offer-data-management.service';
import {BslRangeCharacteristicsSummary, BslRangeCharacteristicsSummaryMap} from '../../../shared/bsl-reference/offer-data-management-model';
import * as _ from 'lodash';
import {MessageService} from 'primeng/api';
import {HttpResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {SessionStorage} from 'ngx-webstorage';

@Component({
  selector: 'app-odm-add-offer',
  templateUrl: './odm-add-offer.component.html',
  styleUrls: ['./odm-add-offer.component.less'],
})
export class OdmAddOfferComponent implements OnInit {

  @SessionStorage()
  odmLoading: boolean;

  @Input()
  selectedRange: Range;

  @Output()
  clickBackFromConf: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  rangeIsPublished: EventEmitter<Range> = new EventEmitter<Range>();

  stepEnum = OfferDataManagementConfigurationStep;

  currentStep: OfferDataManagementConfigurationStep;

  stepList: any[];

  activePrevious: boolean;

  activeNext: boolean;

  hideNext: boolean;

  bslCharacteristics: BslRangeCharacteristicsSummary[];

  bslCharacteristicsMap = new BslRangeCharacteristicsSummaryMap();

  bslCharacteristicsLoading: boolean;

  isModificationPending = false;

  helpMessage: string;

  constructor(private readonly offerDataManagementService: OfferDataManagementService,
              private messageService: MessageService,
              private readonly translate: TranslateService) {
  }

  ngOnInit() {
    this.odmLoading = false;
    if (this.selectedRange.status === RangeStatus.CONFIGURED ||
      this.selectedRange.status === RangeStatus.TEST) {
      if (this.selectedRange.offerDataManagementConfigurationStep) {
        this.currentStep = parseInt(this.selectedRange.offerDataManagementConfigurationStep.toString(), 10);
      } else {
        this.selectedRange.offerDataManagementConfigurationStep = OfferDataManagementConfigurationStep.CHARACTERISTIC;
        this.currentStep = OfferDataManagementConfigurationStep.CHARACTERISTIC;
      }
    }
    this.getStepList();
    this.activePrevious = true;
    this.hideNext = this.currentStep === OfferDataManagementConfigurationStep.PUBLICATION;
    this.activeNext = this.isNextActive();
    // retrieve all characteristics from a range in bsl
    this.bslCharacteristicsLoading = true;
    this.offerDataManagementService.getAllCharacteristicsForRange(this.selectedRange.bslRangeId)
      .subscribe(event => {
          // We only manage the full response ignoring the previous HttpDownloadEvent : event.type === HttpEventType.DownloadProgress
          if (event instanceof HttpResponse) {
            this.bslCharacteristicsLoading = false;
            this.bslCharacteristics = this.formatCharacteristicValueLineBreak(event.body).sort((a, b) => a.name.localeCompare(b.name));
            this.bslCharacteristicsMap = _.keyBy(this.bslCharacteristics, 'key');
          }
        },
        error => this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('T_ERROR'),
          detail: this.translate.instant(error),
        })
      );
    this.getStepHelpMessage();
  }

  /**
   * Function that format the [RETURN] string to line break
   * @param characList list of characteristics return by bsl
   */
  formatCharacteristicValueLineBreak(characList: BslRangeCharacteristicsSummary[]): BslRangeCharacteristicsSummary[] {
    return characList.map(charac => {
      charac.values = charac.values.map(val => val.replace(new RegExp('\\[RETURN]', 'g'), '\n'));
      return charac;
    });
  }

  /**
   * Set current step to previous step
   */
  loadPreviousStep() {
    this.isModificationPending = false;
    if (this.currentStep > 0) {
      this.currentStep = this.getEnumStep(this.currentStep - 1);
      this.getStepList();
      this.activeNext = this.currentStep !== OfferDataManagementConfigurationStep.PUBLICATION;
      this.hideNext = this.currentStep === OfferDataManagementConfigurationStep.PUBLICATION;
      this.offerDataManagementService.getRange(this.selectedRange.id).subscribe(range => this.selectedRange = range);
    } else if (this.currentStep === 0) {
      this.activeNext = true;
      this.clickBackFromConf.emit();
    }
    this.getStepHelpMessage();
  }

  /**
   * Range must be updated
   * @param range
   */
  updateCurrentRange(range: Range) {
    this.rangeIsPublished.emit(range);
  }

  /**
   * Set current step to next step
   */
  loadNextStep(event) {
    this.isModificationPending = false;
    // update the selectedRange with current step
    if (event) {
      if (OfferDataManagementConfigurationStep.CHARACTERISTIC === this.currentStep) {
        // reset the filters which cannot be the same as the electrical characteristics
        this.selectedRange.visibleComponentsFilters = this.selectedRange.visibleComponentsFilters
          .filter(filters => !this.selectedRange.electricalCharacteristicsFilters.includes(filters));
      }
      this.selectedRange.offerDataManagementConfigurationStep = this.getEnumStep(this.currentStep + 1);
      this.offerDataManagementService.updateRange(this.selectedRange).subscribe(range => this.selectedRange = range);
    } else if (this.currentStep < this.selectedRange.offerDataManagementConfigurationStep) {
      this.offerDataManagementService.getRange(this.selectedRange.id).subscribe(range => this.selectedRange = range);
    } else if (this.currentStep >= this.selectedRange.offerDataManagementConfigurationStep) {
      // stay on the same step if no save and the current step >= on selectedRange step
      return;
    }
    // get step list
    if (this.currentStep < 5) {
      this.currentStep = this.getEnumStep(this.currentStep + 1);
      this.getStepList();
      // update next button state
      this.activeNext = this.isNextActive();
    }
    if (this.currentStep === OfferDataManagementConfigurationStep.PUBLICATION) {
      this.hideNext = true;
    }
    this.getStepHelpMessage();
  }

  /**
   * Check if the CharacteristicsMap is empty
   * @param obj
   */
  isEmptyCharacteristicsMapOnFilterElectricalOrMainCharacteristics(obj) {
    return _.isEmpty(obj) && (this.isCharacteristicStep());
  }

  /**
   * function to know if we are on a characteristics step
   */
  isCharacteristicStep() {
    return this.currentStep === this.stepEnum.MAIN_CHARACTERISTIC || this.currentStep === this.stepEnum.CHARACTERISTIC || this.currentStep === this.stepEnum.FILTER;
  }

  /**
   * Get the selected range from db
   */
  getSelectedRange() {
    this.offerDataManagementService.getRange(this.selectedRange.id).subscribe(range => this.selectedRange = range);
  }

  /**
   * Updates Next button state
   */
  updateNextButtonState() {
    this.isModificationPending = this.currentStep < this.selectedRange.offerDataManagementConfigurationStep;
    this.activeNext = this.isNextActive();
  }

  /**
   * Updates Next button state from reference step
   */
  updateNextButton(event) {
    this.isModificationPending = false;
    this.activeNext = event;
  }

  /**
   * Select the help message according to the new step
   */
  private getStepHelpMessage() {

    switch (this.currentStep) {
      case OfferDataManagementConfigurationStep.CHARACTERISTIC:
        this.helpMessage = 'T_OFFER_DATA_MANAGEMENT_SELECT_CHARACTERISTIC_MESSAGE';
        break;
      case OfferDataManagementConfigurationStep.FILTER:
        this.helpMessage = 'T_OFFER_DATA_MANAGEMENT_SELECT_FILTERS_MESSAGE';
        break;
      case OfferDataManagementConfigurationStep.MAIN_CHARACTERISTIC:
        this.helpMessage = 'T_OFFER_DATA_MANAGEMENT_MAIN_CHARACTERISTICS_TITLE';
        break;
      case OfferDataManagementConfigurationStep.DOCUMENT:
        this.helpMessage = 'T_OFFER_DATA_MANAGEMENT_DOCUMENTS_TITLE';
        break;
      case OfferDataManagementConfigurationStep.REFERENCE:
      case OfferDataManagementConfigurationStep.PUBLICATION:
      default:
        this.helpMessage = null;
        break;
    }
  }
  /**
   * Check if Next button is active
   */
  private isNextActive() {
    switch (this.currentStep) {
      case OfferDataManagementConfigurationStep.CHARACTERISTIC:
        return (this.selectedRange.electricalCharacteristicsFilters && this.selectedRange.electricalCharacteristicsFilters.length > 0) ||
          this.selectedRange.hideCharacteristicScreen;
      case OfferDataManagementConfigurationStep.FILTER:
        return this.selectedRange.visibleComponentsFilters && this.selectedRange.visibleComponentsFilters.length > 0;
      case OfferDataManagementConfigurationStep.MAIN_CHARACTERISTIC:
        return this.selectedRange.mainCharacteristics && this.selectedRange.mainCharacteristics.length === 2;
      case OfferDataManagementConfigurationStep.REFERENCE:
        return this.activeNext;
      default:
        return true;
    }
  }

  /**
   * Get step enum by number
   * @param number
   */
  private getEnumStep(number): OfferDataManagementConfigurationStep {
    switch (number) {
      case 0:
        return OfferDataManagementConfigurationStep.CHARACTERISTIC;
      case 1:
        return OfferDataManagementConfigurationStep.FILTER;
      case 2:
        return OfferDataManagementConfigurationStep.MAIN_CHARACTERISTIC;
      case 3:
        return OfferDataManagementConfigurationStep.DOCUMENT;
      case 4:
        return OfferDataManagementConfigurationStep.REFERENCE;
      case 5:
        return OfferDataManagementConfigurationStep.PUBLICATION;
    }
  }

  /**
   * Build step list for offer configuration
   */
  private getStepList() {
    this.stepList = [
      {label: 'Electrical characteristics', className: this.getClassName(OfferDataManagementConfigurationStep.CHARACTERISTIC)},
      {label: 'Filter', className: this.getClassName(OfferDataManagementConfigurationStep.FILTER)},
      {label: 'Main characteristics', className: this.getClassName(OfferDataManagementConfigurationStep.MAIN_CHARACTERISTIC)},
      {label: 'Documentation', className: this.getClassName(OfferDataManagementConfigurationStep.DOCUMENT)},
      {label: 'References', className: this.getClassName(OfferDataManagementConfigurationStep.REFERENCE)},
      {label: 'Publication', className: this.getClassName(OfferDataManagementConfigurationStep.PUBLICATION)}
    ];
  }

  /**
   * Get the css class for a specific step
   * @param step
   */
  private getClassName(step: OfferDataManagementConfigurationStep): String {
    if (this.currentStep === step) {
      return 'active';
    } else if (this.selectedRange && (parseInt(this.selectedRange.offerDataManagementConfigurationStep.toString(), 10) > step
      || this.selectedRange.status === RangeStatus.PUBLISHED)) {
      return 'complete';
    } else {
      return '';
    }
  }
}
