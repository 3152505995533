import {Injectable} from '@angular/core';
import {DocumentationService, DocumentInformations} from '../../export/shared';
import {SessionStorageService} from 'ngx-webstorage';
import * as XLSX from 'xlsx';
import {UtilService} from '../../shared/util/util.service';
import {Item} from '../../project/shared/project-model';

@Injectable()
export class CsvHelperService {

  constructor( private sessionStorageService: SessionStorageService,
               private documentationService: DocumentationService) {
  }

  /**
   * Allow to download BOM file as xlsx file
   * @param projectId id of the current project
   * @param items Items for which we export BOM
   * @param headers headers of the columns
   * @param referencesList attached to the project
   * @param fileName name of the bom file
   * @param docDownloaded BOM file on Excel format
   * @param notMySEFormat
   */
  public exportXlsFile(projectId, items: Item[], headers, referencesList, fileName, docDownloaded, notMySEFormat = true) {

    const blob: Blob = this.generateXLS(headers, referencesList, fileName, notMySEFormat);
    if (items) {
      this.downloadDocumentAndSaveInfos(blob, fileName, projectId, items, docDownloaded);
    }
  }

  /**
   * Generates file as File Object used as an attachment of an email
   * The file is generated from BLOB and not downloaded
   * @param headers header of bom
   * @param items current configured items
   * @param fileName name of the generated file
   * @param notMySEFormat the user has not a mySE account
   * @returns {File} File object
   */
  public attachXlsFileToMail(headers, items, fileName, notMySEFormat): File {

    const blob: Blob = this.generateXLS( headers, items, fileName, notMySEFormat);
    const exportedFilename = fileName || 'export.xls';

    return new File([blob], exportedFilename);
  }

  /**
   * Generates a BLOB Object used to generate a file, this blob is either downloaded or attached an mail
   * @param headers headers header of bom
   * @param items current configured items
   * @param fileName name of the generated file
   * @param notMySEFormat current user has not MySE account
   * @returns {Blob}
   */
  public generateXLS(headers, items, fileName, notMySEFormat): Blob {
    // add headers
    if (headers && notMySEFormat) {
      items.unshift(headers);
    }

    const sheetName = fileName ? fileName.substring(0, fileName.indexOf('.')) : 'Bom';
    const jsonObject = JSON.stringify(items);
    const jsonArray = typeof jsonObject !== 'object' ? JSON.parse(jsonObject) : jsonObject;

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(jsonArray, {skipHeader: true});
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});

    return new Blob([excelBuffer], {type: 'application/octet-stream'});
  }

  /**
   * For offers without 'reference', set all prices to empty string
   * or when delivery time is null or 0 display empty cell in bom
   * returned array is used to generate bom.xls
   * @param bomAsArray
   * @returns {any} array to be converted on xls file
   */
  public convertNullValueFromArray(bomAsArray: any): any {
    const emptyCell = ' ';
    for (let index = 0; index < bomAsArray.length; index++) {
      if (bomAsArray[index].ref === null) {
        if ( bomAsArray[index].discountUnitPrice === 0) {
          bomAsArray[index].discountUnitPrice = emptyCell;
        }
        if (bomAsArray[index].discountedPrice === 0) {
          bomAsArray[index].discountedPrice = emptyCell;
        }
        bomAsArray[index].ref = emptyCell;
      } else if (bomAsArray[index].deliveryTime === null || bomAsArray[index].deliveryTime === 0) {
        bomAsArray[index].deliveryTime = emptyCell;
      }
    }
    return bomAsArray;
  }

  /**
   * Download and save document infos
   * @param {Blob} blob
   * @param {any | string} excelFileName
   * @param projectId
   * @param items Items for which we export BOM
   * @param docDownloaded
   */
  private downloadDocumentAndSaveInfos(blob: Blob, excelFileName: any | string, projectId, items, docDownloaded) {
    UtilService.downloadDocument(blob, excelFileName);

    const docInfo: DocumentInformations = this.sessionStorageService.retrieve('documentInformations');
    if (docInfo != null && docInfo.version) {
      items.forEach(item => {
        this.documentationService.addDownloadedDoc(projectId, item.id, docDownloaded).subscribe(result => {
          this.sessionStorageService.store('documentInformations', result);
        });
      });
    }
  }
}
