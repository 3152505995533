
<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">

      <div class="modal-header">
        <h4  class="modal-title" translate>T_COLLABORATIVE_SHARE_TITLE</h4>
        <div #trancludedTitle><ng-content select="[modal-title]"></ng-content></div>
      </div>
      <div *ngIf="loading;else bodyForm" class="body-invitation loading-spinner">
        <app-spinner [sizeClass]="'medium'">
          <div class="loadingMessage" translate="">T_COLLABORATIVE_LOADING_INVITATION</div>
        </app-spinner>
      </div>
      <ng-template #bodyForm>
        <div class="modal-body">
          <form class="form-horizontal" [formGroup]="form">
            <!--Share space name-->
            <div class="form-group has-feedback">
              <label for="shareSpaceName" class="col-xs-4 control-label text-left field-mandatory"
                     translate>T_COLLABORATIVE_SHARE_NAME_SPACE</label>
              <div class="col-xs-8">
                <input type="text"
                       id="shareSpaceName"
                       maxlength="30"
                       class="field form-control"
                       formControlName="shareSpaceName"
                       [(ngModel)]="shareSpaceName"
                       [ngClass]="{'red-border-class': form.get('shareSpaceName').hasError('required') || form.get('shareSpaceName').hasError('pattern')}"/>
                <div *ngIf="form.get('shareSpaceName').hasError('required') ">
                  <span class="input-error" translate>T_FIELD_REQUIRED</span>
                </div>
                <div *ngIf="form.get('shareSpaceName').hasError('pattern')">
                  <span class="input-error" translate>T_FIELD_PATTERN_ERROR</span>
                </div>
              </div>
            </div>
            <!--Email-->
            <div class="form-group has-feedback">
              <label for="mailAddress" class="col-xs-4 control-label text-left field-mandatory"
                     translate>T_COLLABORATIVE_SHARE_EMAIL
                <span class="se-icons info" tooltip="{{'T_SHARE_EMAIL_TOOLTIP' | translate}}"
                   container="body">information_circle</span>
              </label>
              <div class="col-xs-8">
                <input type="text"
                       id="mailAddress"
                       class="field form-control"
                       formControlName="mailAddress"
                       [(ngModel)]="mailAddress"
                       [ngClass]="{'red-border-class': form.get('mailAddress').hasError('required') || form.get('mailAddress').hasError('pattern')}"/>
                <div
                  *ngIf="form.get('mailAddress').hasError('required') || form.get('mailAddress').hasError('pattern')">
                  <span class="input-error" *ngIf="form.get('mailAddress').hasError('required')" translate>T_FIELD_REQUIRED</span>
                  <span class="input-error"
                        *ngIf="!form.get('mailAddress').hasError('required') && form.get('mailAddress').hasError('pattern')"
                        translate>T_EMAIL_EMAIL_REQUIRED</span>
                </div>
              </div>
            </div>

            <!--Rights -->
            <div class="form-group has-feedback">
              <label class="col-xs-4 control-label text-left"
                     translate>T_COLLABORATIVE_SHARE_RIGHTS
                <span class="se-icons info" tooltip="{{'T_SHARE_RIGHTS_TOOLTIP' | translate}}"
                   container="body">information_circle</span>
              </label>
              <div class="col-xs-8">
                <label class="radio-inline">
                  <input class="pointer"
                         type="radio"
                         [value]="false"
                         formControlName="rightToEdit"
                         [(ngModel)]="rightToEdit">
                  <img *ngIf="!rightToEdit" src="./assets/images/icons/radio.svg" class="radioBtn"/>
                  <img *ngIf="rightToEdit" src="./assets/images/icons/radioUnselected.svg" class="radioBtn"/>
                  <span translate>T_COLLABORATIVE_SHARE_READ</span>
                  <span class="se-icons info" tooltip="{{'T_SHARE_READ_TOOLTIP' | translate}}"
                     container="body">information_circle</span>
                </label>
              </div>
            </div>
            <!--Files-->
            <div  class="form-group has-feedback">
              <label class="col-xs-4 control-label text-left"
                     translate>T_COLLABORATIVE_ALLOW_SHARING_FILES
              </label>
              <div class="col-xs-8 file-selection">
                <div class="checkbox inline-file-selection">
                  <input type="checkbox"
                         class="check-box-allow"
                         [checked]="allowSharedFiles"
                         (change)="onChangeSharedFiles()"
                         id="allowSharedFiles">
                  <label for="allowSharedFiles"></label>
                </div>
              </div>
            </div>
            <!--Files-->
            <div *ngIf="filesSelections.length > 0" class="form-group has-feedback">
              <label class="col-xs-4 control-label text-left "
                     translate>T_COLLABORATIVE_SHARE_FILES
                <span class="se-icons info" tooltip="{{'T_SHARE_FILES_TOOLTIP' | translate}}"
                   container="body">information_circle</span>
              </label>
              <div class="col-xs-8 file-selection">
                <div class="checkbox inline-file-selection" *ngFor="let file of filesSelections; let i = index">
                  <input type="checkbox" id="file{{file.name}}"
                         [checked]="file.selected"
                         (change)="onChangeFileSelection(file)"
                         [disabled]="!allowSharedFiles">
                  <label for="file{{file.name}}"></label>
                  <span class="accept-text align-right">{{file.name}}</span>
                </div>
              </div>
            </div>
            <!--Email-->
            <div class="form-group has-feedback">
              <label for="comment" class="col-xs-4 control-label text-left"
                     translate>T_COLLABORATIVE_SHARE_COMMENT
              </label>
              <div class="col-xs-8">
                <textarea type="text"
                       id="comment"
                       class="field form-control comment-area"
                       formControlName="comment"
                       [(ngModel)]="comment"
                       [placeholder]="'T_COLLABORATIVE_SHARE_ENTER_YOUR_COMMENT' | translate">
                </textarea>
              </div>
            </div>

          </form>
        </div>
      <div class="modal-footer background-white">
        <button class="btn btn-default-white-background" (click)="hide()" translate>T_BUTTON_CLOSE</button>
        <button class="btn btn-default-white-background" [disabled]="!form.valid" (click)="clickShareButton()" translate>T_COLLABORATIVE_SHARE</button>
      </div>
      </ng-template>
    </div>
  </div>
</div>
