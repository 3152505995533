/* Angular modules */
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
/* ngx modules */
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {SessionStorage} from 'ngx-webstorage';
/* app modules */
import {Item, ItemValue, Project, Status} from '../../project/shared/project-model';
import {LoggerService} from '../../shared/logging/logger.service';
import {NavigationStep} from '../../shared/guards/navigationStep-enum';
import {ItemService} from '../../project/shared/item.service';
import {Router} from '@angular/router';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {ProjectService} from '../../project/shared/project.service';
import {NavigationRoute} from '../../shared/guards/route-enum';
import {Subject} from 'rxjs/Rx';
import {
  CharacteristicType,
  CharacteristicView,
  Option,
} from '../../characteristics/shared/characteristics.view';
import {Package, PackageOfferSelect} from '../../admin/shared/package/package.model';
import {Reference} from '../../configuration/shared/model/reference';
import {PackageOfferService, PackageService} from '../../admin/shared';
import {SwitchBoardComponent} from '../../configuration/shared/model/component';
import * as _ from 'lodash';
import {DatasheetsService} from '../../shared/datasheets/datasheets.service';
import {FileService} from "../../admin/shared";

@Component({
  selector: 'app-package-selector',
  templateUrl: './package-selector.component.html',
  styleUrls: ['./package-selector.component.less']
})

export class PackageSelectorComponent implements OnInit, OnDestroy {
  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  user;

  @ViewChild('LooseModal', {static: false}) LooseModal: CommonModalComponent;
  @ViewChild('container', {static: false}) container: ElementRef;

  loading = true;
  unsubscribe$: Subject<void> = new Subject<void>();
  spinnerMessage = 'T_PACKAGE_LOADING_MESSAGE';
  characteristicStepNumber = NavigationStep.PACKAGE_OFFER;

  isAllowedToChange = true;
  isNeededToBeReinitialised = false;

  packages: Package[];
  packagesInitialList: Package[];
  packageOffer: PackageOfferSelect;
  packagesView: CharacteristicView[] = [];
  accessoriesViews: CharacteristicView[] = [];

  // To store value to change during the modal display
  packageSelectSaved: CharacteristicView;


  constructor(private logger: LoggerService,
              private router: Router,
              private itemService: ItemService,
              private projectService: ProjectService,
              private translateService: TranslateService,
              private messageService: MessageService,
              private packageOfferService: PackageOfferService,
              private packageService: PackageService,
              private datasheetsService: DatasheetsService,
              private fileService: FileService) {
  }

  ngOnInit() {
    this.logger.debug('PackageSelectorComponent init()');
    this.itemService.setItemNavigationStep(NavigationStep.PACKAGE_OFFER, this.user, this.unsubscribe$);

    // Apply readOnly
    this.applyReadOnly();

    this.packageOfferService.getOfferSelectById(this.currentItemct.packageOfferId)
      .takeUntil(this.unsubscribe$)
      .subscribe(
        packageOffer => {
          this.packageOffer = packageOffer;
          this.packages = packageOffer.packages;
          this.packagesInitialList = [...this.packages];

          this.fileService.get(packageOffer.imageFileId).subscribe(file => {
            this.packageOffer.image = file.data;
          })
          // Load item configuration
          this.loadConfigurationData();
          const selectedPackage = this.getSelectedPackage();

          if (this.currentItemct.selectedPackage === null && selectedPackage !== null) {
            this.packageService.get(selectedPackage.id).subscribe(fullPackage => {
              this.packages = [...this.packagesInitialList];
              this.putPackageIntoItem(fullPackage);
              this.validForm();
            });
          }
        }, () => {
          this.loading = false;
        });
  }

  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    // unsubscribe from the subject itself:
    this.unsubscribe$.unsubscribe();
  }

  /**
   * Return picture url of the current Item
   */
  get pictureUrl() {
    return this.packageOffer && this.packageOffer.image ? "data:image/JPEG;base64, " + this.packageOffer.image : './assets/images/package.png';
  }

  /**
   * Go to the next tab
   */
  goToNextTab(): void {
    this.logger.debug('PackageSelectorComponent goOnNextTab');
    this.router.navigate([NavigationRoute.getNextStepByPackageOffer(this.currentItemct.currentNavigationStep).link]);
  }

  /**
   * Called when changing a package
   * @param {CharacteristicView} element
   */
  changePackageOption(element: CharacteristicView) {
    const selectedPackageOption = element.options.find(opt => opt.selected);
    this.logger.business('Set Answer to a question', {
      package_question: element.label,
      package_option_value: selectedPackageOption != null ? selectedPackageOption.value : null
    });
    this.applyPackageChange(element);
  }

  /**
   * Called when changing an accessory option
   * @param {CharacteristicView} element
   */
  changeOption(element: CharacteristicView) {
    const modifications: ItemValue[] = [];
    element.options.filter(opt => opt.selected).forEach(opt => {
      const modif = new ItemValue();
      modif.id = element.id;
      modif.name = element.label;
      modif.reference = opt.reference;
      modif.value = opt.value;
      modif.chooseValue = true;
      modif.quantity = opt.quantity;
      modif.disabled = false;

      if (!!modif.reference) {
        // Quantity of option is editable in package offer
        modif.reference.editableQuantity = false;
      }
      modifications.push(modif);
    });

    // Apply modification to accessory item
    this.currentItemct.accessories = this.currentItemct.accessories
      .filter(acc => acc.name !== element.label)
      .concat(modifications);

    this.saveItemCT(true);
  }

  /**
   * Method called if the user select yes on the loose data popup
   */
  confirmLooseData() {
    this.isNeededToBeReinitialised = false;

    // Apply changes according to action
    this.changePackageOption(this.packageSelectSaved);
  }

  /**
   * Method called on the loosing data modal if user click on "NO"
   * Use to reinitiate form data
   */
  reinitData() {
    this.loadConfigurationData();
  }

  /**
   * get Switchboards name list of selected package
   */
  get packageSwitchboardsList() {
    return this.currentItemct.components ? this.currentItemct.components.map(component => ({
      name: component.reference.description,
      quantity: component.quantity
    })) : [];
  }

  /**
   * get Accessories name list of selected package
   */
  get packageAccessoriesList() {
    return this.currentItemct.accessories ?
      this.currentItemct.accessories
        .filter(accessory => !this.currentItemct.displayAccessories || !this.currentItemct.displayAccessories.includes(accessory.name))
        .map(accessory => ({name: accessory.reference.description, quantity: accessory.quantity})) :
      [];
  }

  /**
   * sort accessories views by label
   */
  get accessoriesViewsSorted() {
    return this.accessoriesViews.sort((a, b) => a.label.localeCompare(b.label));
  }

  /**
   * Load or reload configuration from the item
   */
  private loadConfigurationData() {
    // load package offer data
    if (!this.currentItemct.packageOfferId || !this.packages) {
      return;
    }

    this.packagesView = this.getCharacteristicsFilters();
    this.initFilterSelection();

    // get accessories options list as CharacteristicView
    this.accessoriesViews = this.convertAccessoriesToView();

    this.loading = false;
    // this.validForm();
  }

  private getCharacteristicsFilters(): CharacteristicView[] {
    let mapFilters: Map<string, CharacteristicView> = new Map<string, CharacteristicView>();
    let questions: string[] = [];
    // let questions = this.packages[0].characteristics.keys();
    if (this.packages.length > 0) {
      for (const entry of Object.entries(this.packages[0].characteristics)) {
        questions.push(entry[0])
      }
    }


    this.packages.forEach(loopPackage => {
      questions.forEach(question => {
        let key = question;
        let value = loopPackage.characteristics[question];
        if (mapFilters.has(key)) {
          // Key already present -> adding value if not present
          if (!mapFilters.get(key).options.map(opt => opt.value).includes(value)) {
            mapFilters = PackageSelectorComponent.insertFilter(mapFilters, key, value);
          }
        } else {
          // Key not present -> adding new key + value
          mapFilters = PackageSelectorComponent.insertFilter(mapFilters, key, value);
        }
      })
    });
    return Array.from(mapFilters.values());
  }

  private static insertFilter(mapFilters: Map<string, CharacteristicView>,
                              key: string,
                              valueToAdd: string): Map<string, CharacteristicView> {
    let characteristicView = new CharacteristicView();
    characteristicView.label = key;
    characteristicView.options = [
      ...mapFilters.has(key) ? mapFilters.get(key).options : [],
      ...([new Option(valueToAdd, null, false, 1, null)])
    ]
      .sort((a, b) => a.value < b.value ? -1 : 1);
    characteristicView.htmlElementType = "select";
    characteristicView.required = true;
    return new Map(
      [...Array.from(mapFilters.entries()),
        [
          key,
          characteristicView
        ]
      ]
    );
  }


  private initFilterSelection() {
    if (this.currentItemct.selectedPackage === null) {
      return;
    }
    this.packagesView.forEach(packageView => {
      packageView.chooseValue = false;
      packageView.options.forEach(option => {
        option.selected = this.currentItemct.selectedPackage.characteristics[packageView.label] === option.value;
        if (option.selected) {
          packageView.chooseValue = true;
        }
      })
    })
  }


  /**
   * Convert accessories list of current item to CharacteristicView list
   * @private
   */
  private convertAccessoriesToView(): CharacteristicView[] {
    if (!this.currentItemct || !this.currentItemct.accessories || !this.currentItemct.displayAccessories || !this.currentItemct.selectedPackage) {
      return [];
    }

    return this.currentItemct.accessories
      .filter(accessory => this.currentItemct.displayAccessories.includes(accessory.name))
      .map(accessory => {
        let reference = this.getItemByName(this.currentItemct.selectedPackage.optionalAccessoriesReferencesMap, accessory.name);

        const result = new CharacteristicView();
        result.id = accessory.id;
        result.label = accessory.name;
        result.htmlElementType = 'select';
        result.required = false;
        result.type = CharacteristicType.ACCESSORY;
        result.disabled = false;
        result.options = [
          new Option("T_WITHOUT", null, accessory.value == "T_WITHOUT", 1, 0),
          new Option("T_WITH", reference, accessory.value == "T_WITH", accessory.quantity, 1)
        ];
        return result;
      });
  }

  /**
   * Get characteristic or option in the map by accessory name
   * @param map characteristics map or options map
   * @param name accessory name
   * @private
   */
  private getItemByName(map: Map<string, Reference[]>, name: string): Reference {
    for (const entry of Object.entries(map)) {
      const ref = entry[1].find((item: Reference) => item.description === name);
      if (ref) {
        return ref;
      }
    }
    return;
  }

  /**
   * This function test if package field is completed
   * It sets maxNavigationStep to 9 if field is completed else 8.
   * @returns {boolean}
   */
  private validForm() {
    this.logger.debug('PackageSelectorComponent validForm()');
    this.currentItemct.currentNavigationStep = NavigationStep.PACKAGE_OFFER;

    if (this.currentItemct.selectedPackage) {
      this.itemService.setItemMaxNavigationStep(NavigationStep.BILL_OF_MATERIALS, this.user, false, this.unsubscribe$, true);
    } else {
      this.itemService.setItemMaxNavigationStep(NavigationStep.PACKAGE_OFFER, this.user, true, this.unsubscribe$, true);
    }
  }

  /**
   * Apply user input to package configuration
   * @param {CharacteristicView} element the characteristics changed
   */
  private applyPackageChange(element: CharacteristicView) {
    const selectedOptionPackage = element.options.find(opt => opt.selected);

    if (!!selectedOptionPackage) {
      // a question has an answer

      const selectedPackage = this.getSelectedPackage();

      if (this.currentItemct.selectedPackage !== null && selectedPackage === null) {
        this.removePackageSelection();
        this.updateFilters();
        return;
      }
      if (selectedPackage !== null) {
        this.packageService.get(selectedPackage.id).subscribe(fullPackage => {
          this.packages = [...this.packagesInitialList];
          this.putPackageIntoItem(fullPackage);
          this.validForm();
        });
      } else {
        this.updateFilters();
      }
    } else {
      // Reset packageView
      let packageView = this.packagesView.find(view => view.label === element.label);
      if (packageView !== undefined) {
        this.updateFilters();
        if (this.getSelectedPackage() == null) {
          this.removePackageSelection();
          this.validForm();
        }
      }
    }
  }

  private getSelectedPackage(): Package {
    let filteredPackage = [...this.packages];
    this.packagesView.forEach(filter => {
      let optionSelected = filter.options.find(option => option.selected === true);
      if (!(optionSelected === null || optionSelected === undefined)) {
        filteredPackage = filteredPackage.filter(fPackage => fPackage.characteristics[filter.label] === optionSelected.value);
      }
    });
    if (filteredPackage.length === 1) {
      return filteredPackage[0];
    }
    return null;
  }

  private removePackageSelection() {
    this.currentItemct.selectedPackage = null;
    this.currentItemct.components = [];
    this.currentItemct.accessories = [];
    this.currentItemct.displayAccessories = [];

    this.isNeededToBeReinitialised = false;

    this.saveItemCT(false);
  }

  private updateFilters() {
    let filteredPackage = [...this.packagesInitialList];
    this.packagesView.forEach(view => {
      const optSelected = view.options.find(opt => opt.selected);
      if (optSelected !== undefined) {
        filteredPackage = filteredPackage.filter(pack => pack.characteristics[view.label] === optSelected.value);
      }
    });
    this.packages = filteredPackage;
    const newCharacteristicsFilters = this.getCharacteristicsFilters();
    this.packagesView.forEach(view => {
      const optSelected = view.options.find(opt => opt.selected);
      if (optSelected !== undefined) {
        const indexView = newCharacteristicsFilters.findIndex(newView => newView.label === view.label);
        newCharacteristicsFilters[indexView] = _.cloneDeep(view);
      }
    });
    this.packagesView = newCharacteristicsFilters;

    // get accessories options list as CharacteristicView
    this.accessoriesViews = this.convertAccessoriesToView();
  }

  /**
   * Convert Reference list to ItemValue list and update current item
   * @param key key of map
   * @param references value of map
   * @private
   */
  private accessoriesListToItemValues(key: string, references: Reference[]): void {
    references.forEach(reference => {
      let accessory = this.currentItemct.accessories.find(item => item.name === reference.ref);

      if (!!accessory) {
        accessory.quantity++;

      } else {
        accessory = new ItemValue();
        accessory.id = reference.id !== null ? reference.id : key;
        accessory.name = reference.ref;
        accessory.reference = reference;
        accessory.value = reference.ref;
        accessory.chooseValue = true;
        accessory.quantity = 1;
        accessory.disabled = false;


        if (!!accessory.reference) {
          // Quantity of accessory is not editable in package offer
          accessory.reference.editableQuantity = false;
        }

        this.currentItemct.accessories.push(accessory);
      }
    });
  }

  /**
   * Convert Characteristic list or Opt list to ItemValue list and update current item
   * @param key key of map
   * @param optionalRefs
   * @private
   */
  private optionsListToItemValues(key: string, optionalRefs: Reference[]): void {
    optionalRefs.forEach(option => {
      let accessory = this.currentItemct.accessories.find(item => item.name === option.description);

      if (!!accessory) {
        accessory.quantity++;

      } else {
        accessory = new ItemValue();
        accessory.id = key;
        accessory.name = option.description;
        accessory.value = "T_WITHOUT";
        accessory.reference = null;
        accessory.chooseValue = true;
        accessory.quantity = 1;
        accessory.disabled = false;


        if (!!accessory.reference) {
          // Quantity of option is editable in package offer
          accessory.reference.editableQuantity = false;
        }

        this.currentItemct.accessories.push(accessory);
      }

      if (!this.currentItemct.displayAccessories.includes(accessory.name)) {
        this.currentItemct.displayAccessories.push(accessory.name);
      }
    });
  }

  /**
   * Save the user modifications to the package and send the configuration to the server for business rules
   * application
   */
  private saveItemCT(reloadConfiguration ?: boolean) {
    this.logger.debug('PackageSelectorComponent saveItemCT()');

    // Reset content of view to launch spinner
    this.loading = true;
    this.spinnerMessage = 'T_PACKAGE_UPDATING_MESSAGE';

    this.itemService.updateItemWithStatusChange(this.currentItemct, Status.configured, this.user)
      .mergeMap(item => {
        this.currentItemct = item;
        return this.itemService.updateTotalPriceAndDimensionsObserv();
      })
      .takeUntil(this.unsubscribe$)
      .subscribe((item) => {
          if (reloadConfiguration) {
            this.loadConfigurationData();
          } else {
            this.loading = false;
          }
        },
        error => {
          this.logger.error(error);
          this.loading = false;
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant('T_RENAME_ITEM_ERROR_MESSAGE'),
          });
        });
  }

  /**
   * Function to check if the page must be set in read only and if a toaster for ordered configuration must be displayed
   */
  private applyReadOnly() {
    if ((this.currentItemct && this.currentItemct.status === Status.ordered) ||
      this.projectService.isReadOnlyProject(this.currentProject, this.user)) {
      if (!this.projectService.isReadOnlyProject(this.currentProject, this.user)) {
        if (this.projectService.isCpqProject(this.currentProject)) {
          // Information Toast
          this.messageService.add({
            severity: 'warn',
            summary: this.translateService.instant('T_ORDER_WARNING_TITLE'),
            detail: this.translateService.instant('T_PUSH_TO_CPQ_WARNING'),
          });
        } else {// Information Toast Ordered
          this.messageService.add({
            severity: 'warn',
            summary: this.translateService.instant('T_ORDER_WARNING_TITLE'),
            detail: this.translateService.instant('T_ORDER_WARNING'),
          });
        }
      }
      this.isAllowedToChange = false;
    } else if (this.currentItemct && (this.currentItemct.components && this.currentItemct.components.length > 0) &&
      !this.projectService.isReadOnlyProject(this.currentProject, this.user)) {
      this.isNeededToBeReinitialised = true;
      // Information Toast Loose data
      this.messageService.add({
        severity: 'warn',
        summary: this.translateService.instant('T_LOOSE_DATA_WARNING_TITLE'),
        detail: this.translateService.instant('T_LOOSE_DATA_WARNING'),
      });
    }
  }

  private putPackageIntoItem(selectedPackage: Package) {
    this.currentItemct.selectedPackage = selectedPackage;
    // set components (switchboard/transformer)
    this.currentItemct.components = selectedPackage.switchboardComponents
      .reduce((components: SwitchBoardComponent[], switchboardComponent) => {
        const component = components.find(c => c.reference.ref === switchboardComponent.reference.ref);
        if (!!component) {
          component.quantity++;
        } else {
          // Quantity of component is not editable in package offer
          switchboardComponent.reference.editableQuantity = false;

          components.push(switchboardComponent);
        }
        return components;
      }, []);

    // set accessories
    this.currentItemct.accessories = [];
    this.currentItemct.displayAccessories = [];
    Object.entries(selectedPackage.accessoriesReferencesMap).forEach((entry: [string, Reference[]]) => {
      this.accessoriesListToItemValues(entry[0], entry[1]);
    });
    // add displayAccessories only for optionalAccessoriesReferencesMap
    Object.entries(selectedPackage.optionalAccessoriesReferencesMap).forEach((entry: [string, Reference[]]) => {
      this.optionsListToItemValues(entry[0], entry[1]);
    });

    this.isNeededToBeReinitialised = true;

    // get the datasheet from Ops async for all the component
    this.datasheetsService
      .getComponentsDatasheetUrl(this.currentItemct.components, this.user.preferredLanguage)
      .takeUntil(this.unsubscribe$)
      .subscribe(payloadDocumentList => {
        payloadDocumentList.forEach(payload => {
          // we retreve component id of the payload
          const payloadComponentId = _.keys(payload)[0];
          const findComponent = this.currentItemct.components.find(c => c.id === payloadComponentId);
          if (findComponent !== undefined) {
            // we add payload documentation to the component's documentation
            findComponent.documents = _.uniqBy(_.concat(findComponent.documents, payload[payloadComponentId]), 'id');
          }
        });

        this.saveItemCT(true);
      });
  }

  disableValidateButton(): boolean {
    return !this.currentItemct.selectedPackage;
  }
}



