<h5 class="filter-title" translate>T_FILTERS</h5>
<button (click)="removeAllFilters()" type="button" class="btn btn-full-size btn-success btn-remove-all">
  <span translate >T_REMOVE_ALL</span>
</button>
<div *ngFor="let filterKeyValue of filtersByRange | keyvalue; let i = index">
  <accordion *ngIf="filterKeyValue.key !== 'undefined'; else filtersAccordion">
    <accordion-group (isOpenChange)="filtersGroupOpened[i] = !filtersGroupOpened[i]">
      <div accordion-heading class="row cursor">
        <div class="col-xs-10"><span class="filter-accordion-title">{{filterKeyValue.key | translate}}</span></div>
        <div class="col-xs-2">
          <em class="pull-right accordion-btn fa"
             [ngClass]="{'fas fa-minus': filtersGroupOpened[i], 'fas fa-plus': !filtersGroupOpened[i]}"
             aria-hidden="true"></em>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="filtersAccordion"></ng-container>
    </accordion-group>
  </accordion>

  <ng-template #filtersAccordion>
    <accordion *ngFor="let filter of filterKeyValue.value; let j = index">
      <accordion-group (isOpenChange)="filtersOpened[i*10 + j] = !filtersOpened[i*10 + j]">
        <div accordion-heading class="row cursor">
          <div class="col-xs-10"><span class="filter-key">{{filter.key | translate}}</span></div>
          <div class="col-xs-2">
            <em class="pull-right accordion-btn fa"
               [ngClass]="{'fas fa-minus': filtersOpened[i*10 + j], 'fas fa-plus': !filtersOpened[i*10 + j]}"
               aria-hidden="true"></em>
          </div>
        </div>

        <div *ngFor="let value of filter.values"
             [ngClass]="{'filter-checked-or-disable': value.checked && !value.disabled}">
          <label class="badge-container rounded div-badge"
                 [ngClass]="{'btn-three-states': !value.checked && !value.disabled,
             'filter-checked-three-states': value.checked && !value.disabled,
             'filter-disabled-three-states': !value.checked && value.disabled,
              'checked': value.checked, 'disabled': value.disabled}" *ngIf="value.value!=='N/A'">
            <input type="checkbox" [value]="value"
                   [checked]="value.checked" [disabled]="value.disabled"
                   [ngClass]="{'cursor': true, 'hidden-elt': true}"
                   (change)="updateFiltersStatus($event.target.checked, filter.key, value.value)">
            <span class="checkmark"></span>
            <span *ngIf="value.checked" class="badge">&times;</span>
            <span class="filter-value" translate>{{value.value}}</span>
          </label>
        </div>
      </accordion-group>
    </accordion>
  </ng-template>
</div>
