/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Localization} from '../model/localization.model';
import {LoggerService} from '../logging/logger.service';

@Injectable()
export class LocaleService {

  constructor(private httpClient: HttpClient,
              private logger: LoggerService) {
  }

  /**
   * Set locale.
   *
   * @param locale
   */
  setLocale(locale): Observable<any> {
    // TODO : use put method instead of get to set new locale user
    return this.httpClient.get('/application/setLocale/' + locale,
      {responseType: 'text'});
  }

  /**
   * Get all localization.
   */
  getAllLocalization(): Observable<Localization[]> {
    return this.httpClient.cache().get<Localization[]>('/localizations');
  }

  /**
   * Change localization.
   *
   * @param user
   * @param localization
   */
  changeLocalization(user, localization): Observable<any> {
    return this.httpClient.post('/localizations', localization,
      {responseType: 'text'});
  }

  /**
   * UpdateLocalization.
   *
   * @param localization
   */
  updateLocalization(localization: Localization): Observable<Localization> {
    this.logger.info('UserService updateLocalization()');
    return this.httpClient.put<Localization>('/localizations', localization);
  }
}
