/* angular modules */
import { Injectable } from '@angular/core';
import { SessionStorage } from 'ngx-webstorage';
import { PanelOptionWrapper } from '../../configuration/shared/model/panelOptionWrapper';
import { OptionsPanelService } from '../../configuration/shared/services/options-panel.service';
import { Item, Project } from '../../project/shared/project-model';
import { UtilService } from '../util/util.service';

import * as domtoimage from 'dom-to-image-more';
import JsPDF from 'jspdf';

@Injectable()
export class ExportSldLayoutService {

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  currentProject: Project;

  // Scale/multiplier to scale up the canvas before rendering to reduce fuzzy images
  scaleFactor = 4.5;

  constructor(private utilService: UtilService,
              private optionsPanelService: OptionsPanelService) {
  }

  /**
   * Create and download a PNG image of the currently displayed SLD
   */
  public exportSldAsPng(): Promise<any> {
    // Construct the filename of the generated image
    const filename = this.currentProject.name.toLowerCase().replace(/[^a-z0-9]/, '_') +
      '-' + this.currentItemct.name.toLowerCase().replace(/[^a-z0-9]/, '_') +
      '-sld.png';

    return new Promise((resolve) => {
      const producePng = () => this.produceSldAsPng(domtoimage.toBlob)
        .then(blob => resolve(UtilService.downloadDocument(blob, filename)));

      // Deselect cubicle before converting the SLD to image
      this.deselectSldCubicles();

      // This is a hack to make sure that no cubicle is selected
      setTimeout(producePng, 0);
    });
  }

  /**
   * Create and download a PDF document of the currently displayed SLD
   */
  public exportSldAsPdf(): Promise<any> {
    const filename = this.currentProject.name.toLowerCase().replace(/[^a-z0-9]/, '_') + '-' +
      this.currentItemct.name.toLowerCase().replace(/[^a-z0-9]/, '_') + '-sld.pdf';

    return new Promise((resolve) => {
      const producePdf = () => this.produceSldAsPng(domtoimage.toPng)
        .then(pngDataUrl => resolve(this.pngToPdf(pngDataUrl, filename)));

      // Deselect cubicle before converting the SLD to image
      this.deselectSldCubicles();

      // This is a hack to make sure that no cubicle is selected
      setTimeout(producePdf, 0);
    });
  }

  /**
   * Create and download a PNG image of the currently displayed Layout
   */
  public exportLayoutAsPng(): Promise<any> {
    // Find the dom element containing the layout
    const layoutDomElement = document.getElementById('layout-components-wrapper');

    // Construct the filename of the generated image
    const pngFilename = this.currentProject.name.toLowerCase().replace(/[^a-z0-9]/, '_') + '-' +
      this.currentItemct.name.toLowerCase().replace(/[^a-z0-9]/, '_') + '-layout.png';

    return domtoimage.toBlob(layoutDomElement, {bgcolor: '#fff', scale: this.scaleFactor})
      .then(blob => UtilService.downloadDocument(blob, pngFilename));
  }

  /**
   * Create and download a PDF document of the currently displayed Layout
   */
  public exportLayoutAsPdf(): Promise<any> {
    // Find the dom element containing the layout
    const layoutDomElement = document.getElementById('layout-components-wrapper');

    // Construct the filename of the generated image
    const pdfFilename = this.currentProject.name.toLowerCase().replace(/[^a-z0-9]/, '_') + '-' +
      this.currentItemct.name.toLowerCase().replace(/[^a-z0-9]/, '_') + '-layout.pdf';

    return domtoimage.toPng(layoutDomElement, {scale: this.scaleFactor})
      .then(pngDataUrl => this.pngToPdf(pngDataUrl, pdfFilename));
  }

  /**
   * Deselect any selected cubicle (if any) in the SLD view so that
   * the selection border is not present when we generate the image
   */
  private deselectSldCubicles() {
    this.optionsPanelService.setSelectedComponent(null);
    this.optionsPanelService.updateSelectedComponentInfos(new PanelOptionWrapper(null, null, null));
    this.currentItemct.selectedComponentIndex = -1;
  }

  /**
   * Helper function that removes the selection style, filters the Add and
   * Info buttons, then generate a PNG image of the SLD
   *
   * @param domtoimageFunction Either domtoimage.toPng or domtoimage.toBlob
   */
  private produceSldAsPng(domtoimageFunction): any {
    // Find the dom element containing the SLD
    const sldDomElement = document.getElementById('sld-components-wrapper');

    // Create filter function to remove buttons and overlays from the DOM before any conversion
    const filterButtonsFromSld = node => {
      return !(node && node.tagName && node.tagName.toLowerCase() === 'button') &&
        !(node && node.getAttribute && node.getAttribute('type') === 'button') &&
        !(node && node.classList && node.classList.contains('overlay'));
    };

    const producePng = () => domtoimageFunction(sldDomElement, {
      filter: filterButtonsFromSld,
      bgcolor: '#fff',
      scale: this.scaleFactor
    });
    return producePng();
  }

  /**
   * Take a PNG in dataUrl format, insert it in a PDF with the correct orientation, then make it downloadable.
   *
   * @param pngDataUrl The PNG as a data url
   * @param filename The proposed name for the download
   */
  private pngToPdf(pngDataUrl, filename): Promise<any> {
    let resolvePdfAddImage;
    const pngToPdfPromise = new Promise((resolve => resolvePdfAddImage = resolve));

    // Create an image object in order to determine the width and height of the png blob
    const pngImage = new Image();

    // Use the image onLoad callback to get width and height only after the image is loaded
    pngImage.onload = () => {
      let pngWidth = pngImage.width;
      let pngHeight = pngImage.height;
      const pngRatio = pngWidth / pngHeight;

      const pdf = new JsPDF({orientation: pngWidth > pngHeight ? 'landscape' : 'portrait', unit: 'px', format: 'a4'});
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Compute the destination image size in the pdf in order to maximize used space
      if (pngWidth > pngHeight) {
        // Landscape mode
        pngWidth = pdfWidth;
        pngHeight = pngWidth / pngRatio;
        if (pngHeight > pdfHeight) {
          pngHeight = pdfHeight;
          pngWidth = pngRatio * pngHeight;
        }
      } else {
        // Portrait mode
        pngHeight = pdfHeight;
        pngWidth = pngRatio * pngHeight;
        if (pngWidth > pdfWidth) {
          pngWidth = pdfWidth;
          pngHeight = pngWidth / pngRatio;
        }
      }

      // Add the image to the pdf
      pdf.addImage(pngDataUrl, 'PNG', (pdfWidth - pngWidth) / 2, (pdfHeight - pngHeight) / 2, pngWidth, pngHeight);
      const pdfBlob = pdf.output('blob');

      // Open the download window on the user browser
      UtilService.downloadDocument(pdfBlob, filename);

      resolvePdfAddImage();
    };

    // Set the image source to trigger the onLoad callback
    pngImage.src = pngDataUrl;

    return pngToPdfPromise;
  }

}
