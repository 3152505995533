<app-common-modal #orderEmailModal name="T_BOM_MAIL_TITLE" title="{{'T_BOM_MAIL_TITLE' | translate}}" [closable]="!loading" [withFooter]="false" (onClickYesButton)="clickSendButton(); hide()" [size]="'modal-lg'">
  <div modal-body>
    <div class="body-margin bom-mail-modal">
      <app-spinner class="col-xs-12 relative spinner" *ngIf="loading" [sizeClass]="'medium'"></app-spinner>
      <form class="form-horizontal" *ngIf="!loading" [formGroup]="form">
        <div class="col-xs-12 relative">
          <div>
            <section>
              <!--to-->
              <div class="form-group has-feedback">
                <label for="toMail" class="col-xs-3 control-label text-left field-mandatory" translate>T_BOM_MAIL_TO</label>
                <div class="col-xs-9">
                  <input type="text"
                         id="toMail"
                         class="email form-control"
                         formControlName="toMail"
                         [(ngModel)]="toMail"  (ngModelChange)="parseToList()"
                         [ngClass]="{'red-border-class': form.get('toMail').hasError('required') || form.get('toMail').hasError('pattern')}"/>
                  <span class="se-icons info" tooltip="{{'T_BOM_MAIL_EMAILS' | translate}}" container="body">information_circle</span>
                  <div *ngIf="form.get('toMail').hasError('required') || form.get('toMail').hasError('pattern')">
                    <span class="input-error" *ngIf="form.get('toMail').hasError('required')" translate>T_EMAIL_FIELD_REQUIRED</span>
                    <span class="input-error" *ngIf="!form.get('toMail').hasError('required') && form.get('toMail').hasError('pattern')" translate>T_EMAIL_EMAIL_REQUIRED</span>
                  </div>
                </div>
              </div>

              <!--cc-->
              <div class="form-group has-feedback">
                <label for="ccMail" class="col-xs-3 control-label text-left" translate>T_BOM_MAIL_CC</label>
                <div class="col-xs-9">
                  <input  type="text"
                          id="ccMail"
                          class="email form-control"
                          formControlName="ccMail"
                          [(ngModel)]="ccMail" (ngModelChange)="parseToList()"
                          [ngClass]="{'red-border-class': form.get('ccMail').hasError('pattern')}"/>
                  <span class="se-icons info" tooltip="{{'T_BOM_MAIL_EMAILS' | translate}}" container="body">information_circle</span>
                  <div *ngIf="form.get('ccMail').hasError('pattern')">
                    <span class="input-error" *ngIf="form.get('ccMail').hasError('pattern')" translate>T_EMAIL_EMAIL_REQUIRED</span>
                  </div>
                </div>
              </div>

              <!--mySEAccount-->
              <div *ngIf="mySEAccount" class="form-group has-feedback">
                <label for="mySEAccount" class="col-xs-3 control-label text-left" translate>T_BOM_MAIL_MYSE_ACCOUNT</label>
                <div class="col-xs-9">
                  <input  type="text"
                          id="mySEAccount"
                          disabled="true"
                          class="email form-control"
                          formControlName="mySEAccount"
                          [(ngModel)]="mySEAccount" (ngModelChange)="parseToList()"/>
                </div>
              </div>

              <!--Order reference-->
              <div class="form-group has-feedback">
                <label for="orderReference" class="col-xs-3 control-label text-left field-mandatory" translate>T_BOM_MAIL_ORDER_REFERENCE</label>
                <div class="col-xs-9">
                  <input  type="text"
                          id="orderReference"
                          class="email form-control"
                          formControlName="orderReference"
                          [(ngModel)]="orderReference"
                          [ngClass]="{'red-border-class': form.get('orderReference').hasError('required') || form.get('orderReference').hasError('notEmpty')}"/>
                  <span class="se-icons info" tooltip="{{'T_BOM_MAIL_ORDER_REFERENCE_TOOLTIP' | translate}}" container="body">information_circle</span>
                  <div *ngIf="form.get('orderReference').hasError('required') || form.get('orderReference').hasError('notEmpty')">
                    <span class="input-error" translate>T_EMAIL_ORDER_REFERENCE_REQUIRED</span>
                  </div>
                </div>
              </div>

              <!--Required delivery date-->
              <div class="form-group has-feedback">
                <label for="requiredDate" class="col-xs-3 control-label text-left field-mandatory" translate>T_BOM_MAIL_REQUIRED_DATE</label>
                <div class="col-xs-9">
                  <input  type="date"
                          id="requiredDate"
                          min="{{minDeliveryDate | date:'yyyy-MM-dd'}}"
                          class="email form-control"
                          formControlName="requiredDate"
                          [(ngModel)]="requiredDate"
                          [ngClass]="{'red-border-class': form.get('requiredDate').hasError('required') || form.get('requiredDate').hasError('notEmpty')
                          || form.get('requiredDate').hasError('inTheFuture')}"/>
                  <span class="se-icons info" tooltip="{{'T_EMAIL_REQUIRED_DATE_TOOLTIP' | translate}}" container="body">information_circle</span>
                  <div *ngIf="form.get('requiredDate').hasError('required') || form.get('requiredDate').hasError('notEmpty')">
                    <span class="input-error" translate>T_EMAIL_REQUIRED_DATE_REQUIRED</span>
                  </div>
                  <div *ngIf="!form.get('requiredDate').hasError('required') && !form.get('requiredDate').hasError('notEmpty') &&
                  form.get('requiredDate').hasError('inTheFuture')">
                    <span class="input-error" translate>T_EMAIL_REQUIRED_DATE_IN_FUTURE</span>
                  </div>
                </div>
              </div>

              <!--Delivery address-->
              <div class="form-group has-feedback">
                <label for="deliveryAddress" class="col-xs-3 control-label text-left field-mandatory" translate>T_BOM_MAIL_DELIVERY_ADDRESS</label>
                <div class="col-xs-9">
                  <textarea type="text"
                            id="deliveryAddress"
                            class="form-control address-field"
                            formControlName="deliveryAddress"
                            [(ngModel)]="deliveryAddress"
                            [ngClass]="{'red-border-class': form.get('deliveryAddress').hasError('required') || form.get('deliveryAddress').hasError('notEmpty')}">
                  </textarea>
                  <span class="se-icons info" tooltip="{{'T_BOM_MAIL_DELIVERY_ADDRESS_TOOLTIP' | translate}}" container="body">information_circle</span>
                  <div *ngIf="form.get('deliveryAddress').hasError('required') || form.get('deliveryAddress').hasError('notEmpty')">
                    <span class="input-error" translate>T_EMAIL_DELIVERY_ADDRESS_REQUIRED</span>
                  </div>
                </div>
              </div>

              <!--Contact name-->
              <div class="form-group has-feedback">
                <label for="contactName" class="col-xs-3 control-label text-left field-mandatory" translate>T_BOM_MAIL_ORDER_CONTACT_NAME</label>
                <div class="col-xs-9">
                  <input  type="text"
                          id="contactName"
                          class="email form-control"
                          formControlName="contactName"
                          [(ngModel)]="contactName"
                          [ngClass]="{'red-border-class': form.get('contactName').hasError('required') || form.get('contactName').hasError('notEmpty')}"/>
                  <span class="se-icons info" tooltip="{{'T_BOM_MAIL_ORDER_CONTACT_NAME_TOOLTIP' | translate}}" container="body">information_circle</span>
                  <div *ngIf="form.get('contactName').hasError('required') || form.get('contactName').hasError('notEmpty')">
                    <span class="input-error" translate>T_BOM_MAIL_ORDER_CONTACT_NAME_REQUIRED</span>
                  </div>
                </div>
              </div>

              <!--Contact phone-->
              <div class="form-group has-feedback">
                <label for="contactPhone" class="col-xs-3 control-label text-left field-mandatory" translate>T_BOM_MAIL_ORDER_CONTACT_PHONE</label>
                <div class="col-xs-9">
                  <input  type="text"
                          id="contactPhone"
                          class="email form-control"
                          formControlName="contactPhone"
                          [(ngModel)]="contactPhone"
                          [ngClass]="{'red-border-class': form.get('contactPhone').hasError('required') || form.get('contactPhone').hasError('notEmpty')}"/>
                  <span class="se-icons info" tooltip="{{'T_BOM_MAIL_ORDER_CONTACT_PHONE_TOOLTIP' | translate}}" container="body">information_circle</span>
                  <div *ngIf="form.get('contactPhone').hasError('required') || form.get('contactPhone').hasError('notEmpty')">
                    <span class="input-error" translate>T_BOM_MAIL_ORDER_CONTACT_PHONE_REQUIRED</span>
                  </div>
                </div>
              </div>

              <!--Comments-->
              <div class="form-group has-feedback">
                <label for="comments" class="col-xs-3 control-label text-left" translate>T_BOM_MAIL_COMMENTS</label>
                <div class="col-xs-9">
                  <input  type="text"
                          id="comments"
                          class="email form-control"
                          formControlName="comments"
                          [(ngModel)]="comments"
                          [ngClass]="{'red-border-class': form.get('comments').hasError('required') || form.get('comments').hasError('notEmpty')}"/>
                </div>
              </div>

              <!--subject-->
              <div class="form-group has-feedback">
                <label for="subjectEmail" class="col-xs-3 control-label text-left field-mandatory" translate>T_BOM_MAIL_SUBJECT</label>
                <div class="col-xs-9">
                  <input  type="text"
                          id="subjectEmail"
                          class="email form-control"
                          formControlName="subjectEmail"
                          [(ngModel)]="subjectEmail"
                          [ngClass]="{'red-border-class': form.get('subjectEmail').hasError('required') || form.get('subjectEmail').hasError('notEmpty')}"/>
                  <span class="se-icons info" tooltip="{{'T_BOM_MAIL_SUBJECT_TOOLTIP' | translate}}" container="body">information_circle</span>
                  <div *ngIf="form.get('subjectEmail').hasError('required') || form.get('subjectEmail').hasError('notEmpty')">
                    <span class="input-error" translate>T_EMAIL_SUBJECT_REQUIRED</span>
                  </div>
                </div>
              </div>

              <!--attachments-->
              <div class="form-group has-feedback attachments">
                <label class="col-xs-3 control-label text-left" translate>T_BOM_MAIL_ATTACHMENTS</label>
                <div class="col-xs-9 files">
                  <em class="fa fa-paperclip pointer" style="font-size:20px;" (click)="fileInput.click()"></em>
                  <input #fileInput type="file" (change)="uploadFile($event)" style="display:none">
                  <a class="attachment-file" *ngFor="let attachmentEmail of attachmentsEmail">{{attachmentEmail.file.name}}
                    <em *ngIf="canRemoveFile(attachmentEmail)" class="fa fa-remove pointer" (click)="removeFile(attachmentEmail.file.name)"></em>
                  </a>
                  <div>
                    <span class="input-error" *ngIf="!isSizeOk" translate>{{attachmentsWrongSize}}</span>
                  </div>
                </div>
              </div>

              <!--body-->
              <div class="form-group has-feedback">
                <label for="bodyEmail" class="col-xs-3 control-label text-left field-mandatory" translate>T_BOM_MAIL_BODY</label>
                <div class="col-xs-9">
                          <textarea type="text"
                                    id="bodyEmail"
                                    class="message form-control"
                                    formControlName="bodyEmail"
                                    [(ngModel)]="bodyEmail"
                                    [ngClass]="{'red-border-class': form.get('bodyEmail').hasError('required') || form.get('bodyEmail').hasError('notEmpty')}">
                          </textarea>
                  <div *ngIf="form.get('bodyEmail').hasError('required') || form.get('bodyEmail').hasError('notEmpty')">
                    <span class="input-error" translate>T_EMAIL_BODY_REQUIRED</span>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </form>
    </div>

    <!-- CUSTOM FOOTER -->
    <div class="modal-footer" *ngIf="!loading">
      <button class="btn btn-default-white-background" [disabled]="!form.valid" (click)="clickSendButton()" translate>T_BUTTON_SEND</button>
      <button class="btn btn-default-white-background" (click)="clickCancelButton()" translate>T_BUTTON_CANCEL</button>
    </div>
  </div>
</app-common-modal>
