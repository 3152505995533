/* Angular modules */
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
/* app modules */
import {RangeDiscount, ReferencePrice} from './price.management.model';
import { LoggerService } from '../../../shared/logging/logger.service';
import { Range } from '../../../shared/model/range-model';
import { SwitchBoardComponent } from '../../../configuration/shared/model/component';
import { Price } from '../../../shared/model/price';
import { Localization } from '../../../shared/model/localization.model';

@Injectable()
export class PriceManagementService {

  constructor(private logger: LoggerService,
              private httpClient: HttpClient) {
  }

  getReferencePrices(): Observable<Array<ReferencePrice>> {
    this.logger.debug('PriceManagementService getReferencePrices()');
    return this.httpClient.get<Array<ReferencePrice>>('/price/referencePrices');
  }

  getMySEReferencesByMySEAccount(MySEAccountId: string): Observable<Array<ReferencePrice>> {
    this.logger.debug('PriceManagementService getMySEReferencesByMySEAccount()');
    return this.httpClient.get<Array<ReferencePrice>>('/price/' + MySEAccountId + '/mySEReferences');
  }

  updateValidityPeriod(selectedRange: Range) {
    this.logger.info('PriceManagementService updateValidityPeriod');
    return this.httpClient.put<Range>('/ranges/' + selectedRange.id + '/priceManagement/validityPeriod/' + selectedRange.priceManagement.validityPeriod, null);
  }

  updateAdditionalDeliveryTime(localization: Localization, additionalTime: number) {
    this.logger.info('PriceManagementService updateAdditionalDeliveryTime');
    return this.httpClient.put<Range>('/ranges/' + localization.country + '/priceManagement/additionalTime/' + additionalTime, null);
  }

  updateDiscountAllowed(localization: Localization, discountAllowed: number) {
    this.logger.info('PriceManagementService updateDiscountAllowed');
    return this.httpClient.put<Range>('/ranges/' + localization.country + '/priceManagement/discountAllowed/', discountAllowed);
  }

  /**
   * Get price for a component
   *
   * @returns {Observable<number>}
   * @param components
   */
  public getComponentsPrice(components: Array<SwitchBoardComponent>): Observable<Price[]> {
    this.logger.debug('ComponentService getComponentsPrice()');
    return <Observable<Price[]>>this.httpClient.post<Array<Price>>('/price/components', components, {
        reportProgress: true,
        observe: 'events'
      })
      // Ignore initial whitespaces used to keep the connection busy (API Gateway)
      .filter(event => event instanceof HttpResponse)
      .map(event => (<HttpResponse<Price[]>>event).body);
  }

  public getCountReferenceNetPricesAvailable(): Observable<number[]> {
    return this.httpClient.get<number[]>('/price/getCountReferenceNetPrices');
  }

  public netPricesLoadingInProgress(): Observable<number> {
    return this.httpClient.get<number>('/price/getNetPricesInProgress');
  }

  public getNetPriceDiscountForPartner(): Observable<Array<RangeDiscount>> {
    return this.httpClient.get<Array<RangeDiscount>>('/price/partnerDiscounts');
  }
}
