/* Angular modules */
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
import {TranslateService} from '@ngx-translate/core';
/* app modules */
import {LoggerService} from '../../shared/logging/logger.service';
import {UtilService} from '../../shared/util/util.service';
import {BomMailService} from '../shared';
import {ItemService} from '../../project/shared/item.service';
import {Item, OrderInformation, Project, Status} from '../../project/shared/project-model';
import {MessageService} from 'primeng/api';
import {BomService} from '../shared';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {DocumentationCategory, DocumentationHelperService} from '../../export/shared';
import {DocumentationService} from '../../export/shared';
import {isNullOrUndefined} from 'util';
import {OrderingMode} from '../../shared/model/localization.model';
import {Role} from '../../core/access-control/enum';
import {ReferenceToOrder} from '../../configuration/shared/model/component';
import {BomHelper} from '../shared';
import {RangeStatus} from '../../shared/model/range-model';
import {User} from '../../shared/user/user';
import {NavigationStep} from '../../shared/guards/navigationStep-enum';
import {Documentation} from '../../shared/documentation/documentation.model';

interface Attachment {
  file: File;
  canBeRemoved: boolean;
}

@Component({
  selector: 'app-bom-mail-modal',
  templateUrl: './bom-mail-modal.component.html',
  styleUrls: ['./bom-mail-modal.component.less'],
})
export class BomMailModalComponent implements OnInit, OnChanges {

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  localization;

  @SessionStorage()
  user: User;

  @SessionStorage()
  maxDeliveryTime: number;

  @ViewChild('orderEmailModal') public childModal: CommonModalComponent;
  @Input() name: string;
  @Input() recipientMail: string;
  @Input() subjectEmailInput: any;
  @Input() attachBom = true;
  @Input() documentChosenLanguage: string;
  @Input() bodyEmailInput: any;
  @Input() referencesToOrder: ReferenceToOrder[];
  @Input() isProjectBom: boolean;
  @Output() onClickYesButton = new EventEmitter<string>();
  @Output() onClickNoButton = new EventEmitter<string>();
  @Output() updateProjectBomInfos = new EventEmitter<Item[]>();

  toMail: any;
  ccMail: any;
  mySEAccount: string;
  orderReference: string;
  requiredDate: string;
  minDeliveryDate: Date;
  deliveryAddress: string;
  contactName: string;
  contactPhone: string;
  comments: string;
  toEmail = [];
  ccEmail = [];
  subjectEmail: any;
  bodyEmail: any;
  orderFiles = [];
  attachmentsEmail: Attachment[] = [];
  attachMaxSize = 10;
  isSizeOk = true;
  attachmentsWrongSize: any;
  projectItemsToOrder: Item[];
  // accepted mime types for file upload (both in front-end and back-end control)
  fileTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.text', 'application/vnd.oasis.opendocument.spreadsheet',
    'application/zip', 'application/x-zip', 'application/x-zip-compressed', 'application/octet-stream',
    'application/x-compress', 'application/x-compressed', 'multipart/x-zip', 'application/x-7z-compressed',
    'application/pdf', 'image/gif', 'image/jpeg', 'image/png', 'image/tiff'];
  form: FormGroup;
  loading = false;

  constructor(private itemService: ItemService,
              private bomService: BomService,
              private bomMailService: BomMailService,
              private bomHelper: BomHelper,
              private translateService: TranslateService,
              private messageService: MessageService,
              private documentationService: DocumentationService,
              private documentationHelperService: DocumentationHelperService,
              private logger: LoggerService,
              private changeDetectorRef: ChangeDetectorRef,
              private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      toMail: [this.toMail, [Validators.required, Validators.pattern(UtilService.emailRegEx)]],
      ccMail: [this.ccMail, [Validators.pattern(UtilService.emailRegEx)]],
      mySEAccount: [this.mySEAccount],
      orderReference: [this.orderReference, Validators.compose([Validators.required, BomMailModalComponent.checkIfEmptyValue])],
      requiredDate: [this.requiredDate, Validators.compose([Validators.required, BomMailModalComponent.checkIfEmptyValue, BomMailModalComponent.isValueInTheFuture])],
      deliveryAddress: [this.deliveryAddress, Validators.compose([Validators.required, BomMailModalComponent.checkIfEmptyValue])],
      contactName: [this.contactName, Validators.compose([Validators.required, BomMailModalComponent.checkIfEmptyValue])],
      contactPhone: [this.contactPhone, Validators.compose([Validators.required, BomMailModalComponent.checkIfEmptyValue])],
      subjectEmail: [this.subjectEmail, Validators.compose([Validators.required, BomMailModalComponent.checkIfEmptyValue])],
      comments: [this.comments],
      bodyEmail: [this.bodyEmail, Validators.compose([Validators.required, BomMailModalComponent.checkIfEmptyValue])]
    });
    // The minimum delivery date is tomorrow
    this.minDeliveryDate = new Date();
    this.minDeliveryDate.setDate(this.minDeliveryDate.getDate() + 1);
  }

  private static checkIfEmptyValue(fieldControl: FormControl) {
    return fieldControl.value && !fieldControl.value.match('^ +$') ? null : {notEmpty: true};
  }

  private static isValueInTheFuture(fieldControl: FormControl) {
    return fieldControl.value && Date.parse(fieldControl.value) > new Date().getTime() ? null : {inTheFuture: true};
  }

  public ngOnInit(): void {
    this.toMail = this.recipientMail;
    this.ccMail = this.user.email;
    if (!isNullOrUndefined(this.user.currentMySEAccount)) {
      this.mySEAccount = this.user.currentMySEAccount.name;
    }
    this.subjectEmail = this.translateService.instant(this.subjectEmailInput, {projectName: this.currentProject.name});
    this.bodyEmail = this.translateService.instant(this.bodyEmailInput,
      {
        userFirstName: this.user.firstName,
        userLastName: this.user.lastName,
        userEmail: this.user.email,
        userGroup: this.user.partnerName
      });
    if (!this.isProjectBom) {
      this.documentationService.getOrderDocuments(this.currentItemct.id)
        .toPromise()
        .then(orderFiles => {
          if (!!orderFiles && orderFiles.length > 0) {
            const blob: BlobPart[] = [];
            if (!!this.currentItemct.range) { // get all order files for range itemCT
              orderFiles.forEach(file => this.orderFiles.push(new File(blob, file.fileName)));
            } else { // get only 1 order file for package offer itemCT
              this.orderFiles.push(new File(blob, orderFiles[0].fileName));
            }
          }
        });
    } else {
      this.documentationService.getProjectOrderDocuments(this.currentProject.id, Status.quoted)
        .toPromise()
        .then(file => {
          if (file !== null && file !== undefined) {
            const blob: BlobPart[] = [];
            this.orderFiles.push(new File(blob, file.fileName));
          }
        });
    }
    this.parseToList();
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.recipientMail && changes.recipientMail.currentValue !== '') {
      this.toMail = changes.recipientMail.currentValue;
      this.changeDetectorRef.detectChanges();
    }
  }

  show() {
    // clear attachment list
    this.attachmentsEmail.length = 0;
    const contractorMixedCase: boolean = OrderingMode.MIXED === this.localization.orderingMode && Role.CONTRACTOR_MIXED === this.user.role;
    // attach Bom
    if (this.attachBom) {
      if (!contractorMixedCase) {
        // item Bom case
        if (!this.isProjectBom) {
          this.attachmentsEmail.push({
            file: this.bomService.exportBom(this.currentProject.id, [this.currentItemct], true, this.user),
            canBeRemoved: false
          });
        } else {
          this.itemService.getItems(this.currentProject.id).subscribe((res) => {
            this.projectItemsToOrder = res.filter(item => UtilService.isOrderableItem(item));
            this.attachmentsEmail.push({
              file: this.bomService.exportBom(this.currentProject.id, this.projectItemsToOrder, true, this.user),
              canBeRemoved: false
            });
          });
        }

      } else if (contractorMixedCase && this.referencesToOrder && this.referencesToOrder.length > 0) {
        // Case of Contractor mixed user
        this.attachmentsEmail.push({
          file: this.bomService.exportReferencesForOrdering(this.referencesToOrder),
          canBeRemoved: true
        });
      }
    }
    if (this.orderFiles.length > 0) {
      this.orderFiles.forEach(file => this.attachmentsEmail.push({file: file, canBeRemoved: false}));
    }
    this.childModal.show();
  }

  hide() {
    this.childModal.hide();
  }

  parseToList() {
    this.toEmail = this.toMail ? this.toMail.split(',') : [];
    this.ccEmail = this.ccMail ? this.ccMail.split(',') : [];
  }

  clickSendButton() {
    this.loading = true;
    const formData = new FormData();
    for (let i = 0; i < this.toEmail.length; i++) {
      formData.append('toEmail', this.toEmail[i]);
    }
    for (let i = 0; i < this.ccEmail.length; i++) {
      formData.append('ccEmail', this.ccEmail[i]);
    }

    formData.append('subjectEmail', this.subjectEmail);
    formData.append('bodyEmail', this.enrichEmailBody(this.bodyEmail));

    // Attach purchase order document if exist
    const listFileName = this.attachmentsEmail.map(a => a.file.name);
    // We manage only one purchase order document
    const orderInformation: OrderInformation = new OrderInformation();
    orderInformation.orderReference = this.orderReference;
    orderInformation.mySEAccountOrderBy = this.mySEAccount;
    orderInformation.orderDate = new Date().getTime();
    orderInformation.requiredDeliveryDate = this.requiredDate;
    orderInformation.deliveryAddress = this.deliveryAddress;
    orderInformation.contactName = this.contactName;
    orderInformation.contactPhone = this.contactPhone;
    orderInformation.comments = this.comments;
    orderInformation.deliveryTime = this.maxDeliveryTime;
    let hasOrderFiles = this.orderFiles.length > 0 && listFileName.some(name => name === this.orderFiles[0].name);
    if (this.isProjectBom) {
      const itemsPromise = [];
      this.projectItemsToOrder.forEach(item => {
        item.orderInformation = orderInformation;
        itemsPromise.push(this.itemService.updateItem(item, this.user, false)
          .toPromise()
          .then(() => this.currentItemct = null));
      });
      Promise.all(itemsPromise).then(() => {
        if (hasOrderFiles) {
          this.documentationService
            .generatePurchaseOrder(this.currentProject.id, this.user.preferredLanguage, false, 'order', (new Date()).getTime())
            .subscribe(result => {
                const file = new File([result.body], this.orderFiles[0].name);
                this.attachmentsEmail = this.attachmentsEmail.filter(a => a.file.name !== file.name);
                this.attachmentsEmail.push({
                  file,
                  canBeRemoved: true
                });
                this.sendEmail(formData);
              },
              error => {
                this.messageService.add({
                  severity: 'warn',
                  summary: this.translateService.instant('T_EMAIL_WARNING_TITLE'),
                  detail: this.translateService.instant('T_EMAIL_PURCHARSE_ORDER_ERROR'),
                });
                this.attachmentsEmail = this.attachmentsEmail.filter(a => a.file.name !== this.orderFiles[0].name);
                this.sendEmail(formData);
              });
        } else {
          this.sendEmail(formData);
        }
      });
    } else {
      this.currentItemct.orderInformation = orderInformation;
      this.itemService.updateItem(this.currentItemct, this.user)
        .subscribe(res => {
          this.currentItemct = res;
          if (hasOrderFiles) {
            this.documentationService
              .generatePurchaseOrderForItemEmail(this.currentProject.id, this.currentItemct, this.user.preferredLanguage, false, 'order', (new Date()).getTime())
              .subscribe(result => {
                  const file = new File([result.body], this.orderFiles[0].name);
                  this.attachmentsEmail = this.attachmentsEmail.filter(a => a.file.name !== file.name);
                  this.attachmentsEmail.push({
                    file,
                    canBeRemoved: true
                  });

                  this.sendEmail(formData);
                },
                error => {
                  this.messageService.add({
                    severity: 'warn',
                    summary: this.translateService.instant('T_EMAIL_WARNING_TITLE'),
                    detail: this.translateService.instant('T_EMAIL_PURCHARSE_ORDER_ERROR'),
                  });
                  this.attachmentsEmail = this.attachmentsEmail.filter(a => a.file.name !== this.orderFiles[0].name);

                  this.sendEmail(formData);
                });
          } else {
            this.sendEmail(formData);
          }
        });
    }

  }


  clickCancelButton() {
    this.hide();
    this.onClickNoButton.emit(this.name);
  }

  uploadFile(event) {
    const fileList = event.target.files;
    if (fileList.length > 0) {
      // check if the type of the file to add is allowed
      if (!this.fileTypes.includes(fileList[0].type)) {
        // clean files in event
        event.target.value = null;
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_EMAIL_ATTACHMENTS_TYPE'),
          detail: this.translateService.instant('T_EMAIL_ATTACHMENTS_WRONG_TYPE'),
        });
        return;
      } else {
        const file: File = fileList[0];
        // clean files in event
        event.target.value = null;
        // we check if the total attachments size does not exceed 10MB
        this.isSizeOk = this.bomMailService.canAddAttachementFile(this.attachmentsEmail, file, this.attachMaxSize);
        if (this.isSizeOk) {
          this.attachmentsEmail.push({
            file,
            canBeRemoved: true
          });
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('T_EMAIL_ATTACHMENT_ADDED'),
            detail: this.translateService.instant(file.name),
          });
        } else {
          this.attachmentsWrongSize = this.translateService.instant('T_EMAIL_ATTACHMENTS_WRONG_SIZE',
            {maxSize: this.attachMaxSize});
        }
      }
    }
  }

  removeFile(fileName) {
    this.attachmentsEmail.forEach(attachmentEmail => {
      if (attachmentEmail.file.name === fileName) {
        this.attachmentsEmail.splice(this.attachmentsEmail.indexOf(attachmentEmail), 1);
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_EMAIL_ATTACHMENT_REMOVED'),
          detail: this.translateService.instant(attachmentEmail.file.name),
        });
      }
    });
  }

  canRemoveFile(attachment: Attachment) {
    return attachment.canBeRemoved;
  }

  /**
   * Update the current item after sending mail
   */
  private updateItemBomAfterMailResponse() {
    this.logger.info('BomPageComponent orderCurrentItem()');
    const now = new Date();
    this.currentItemct.date = now.getTime();
    // add references to order in item in the multiple ordering case
    if (this.referencesToOrder && this.referencesToOrder.length > 0) {
      this.referencesToOrder.forEach(referenceToOrder => this.currentItemct.orderedReferences.push(referenceToOrder.reference));
    }
    this.itemService.updateItemWithStatusChange(this.currentItemct, Status.ordered, this.user)
      .subscribe(
        res => {
          this.currentItemct = res;
          this.logger.business('Mail order for item configuration',
            {
              total_price: UtilService.getFormattedTotalDiscountPriceFromBOM(this.currentItemct.bom,
                this.user.currentMySEAccount === null && this.currentItemct.totalPrice.validFoNetPrice,
                this.currentItemct.discount),
              currency: this.localization.currency
            });
        }
      );
  }

  /**
   * Update the current project after sending mail
   */
  private updateProjectBomAfterMailResponse() {
    this.logger.info('ProjectBomComponent orderCurrentProject()');
    const now = new Date();
    let totalPrice = 0;
    this.itemService.getItems(this.currentProject.id).subscribe(items => {
      const itemList: Item[] = this.referencesToOrder && this.referencesToOrder.length > 0 ? this.bomHelper.getItemsToUpdate(items, this.referencesToOrder)
        : items.filter(item => UtilService.isOrderableItem(item));
      let count = 0;
      itemList.forEach(item => {
        item.date = now.getTime();
        item.status = Status.ordered;
        item.maxNavigationStep = NavigationStep.EXPORT;
        this.itemService.updateItem(item, this.user, false)
          .subscribe(
            res => {
              count++;
              totalPrice += UtilService.applyDiscountPrice(res.bom.totalPrice, res.discount);
              // Update the project Bom after ordering process
              if (count === itemList.length) {
                this.itemService.getItems(this.currentProject.id).subscribe(itemsElem => {
                  this.updateProjectBomInfos.emit(itemsElem);
                });
              }
            }
          );
      });
    });
    this.logger.business('Mail order for project configuration',
      {
        total_price: UtilService.formatPrice(totalPrice),
        currency: this.localization.currency
      });
  }

  /**
   * Enrich email body with document link if needed
   * @param bodyEmail
   */
  private enrichEmailBody(bodyEmail: any): any {
    let enrichedBody = '\n\n';
    // for russia, we link specification guide if exists
    if (this.user.partnerCountry === 'RU' &&
      this.currentItemct.components &&
      this.currentItemct.components[0].documents) {
      const specificationGuide = this.currentItemct.components[0].documents.filter(doc => doc.fileName.includes('Spec_guide'));
      if (specificationGuide.length > 0) {
        enrichedBody += this.translateService.instant('T_BOM_MAIL_BODY_MESSAGE_SPECIFICATION_FILE',
          {specification: specificationGuide[0].link});
      }
    }
    return bodyEmail + enrichedBody;
  }

  /**
   * Method to send email with information from form data, update item/project after mail sending
   *
   * @param formData
   */
  private sendEmail(formData: FormData) {
    for (let i = 0; i < this.attachmentsEmail.length; i++) {
      formData.append('files', this.attachmentsEmail[i].file);
    }

    this.bomMailService.sendMail(formData).subscribe(result => {
        if (result === true) {
          if (this.isProjectBom) {
            this.updateProjectBomAfterMailResponse();
          } else {
            this.updateItemBomAfterMailResponse();
          }
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('T_EMAIL_SUCCESS_TITLE'),
            detail: this.translateService.instant('T_EMAIL_SUCCESS_MESSAGE'),
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_EMAIL_ERROR_TITLE'),
            detail: this.translateService.instant('T_EMAIL_ERROR_MESSAGE'),
          });
        }
      },
      error => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_EMAIL_ERROR_TITLE'),
          detail: this.translateService.instant('T_EMAIL_EXCEPTION_ERROR'),
        });
      },
      () => {
        this.loading = false;
        this.hide();
      });
    this.onClickYesButton.emit(this.name);
  }

}
