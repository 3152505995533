<span *ngIf="description" class="explain-text">{{description}}</span>
<form *ngIf="distributorSelected" (submit)="clickValidateButton()">
  <div class="row field">
    <div class="col-xs-4">
      <label class="field-label required-red-asterisk" translate>T_DISTRIBUTOR_NAME</label>
    </div>
    <div class="col-xs-8">
      <input [id]="'distributorName'" maxlength="50" name="distributorName"
             (change)="checkStringField(distributorSelected.name,'T_DISTRIBUTOR_NAME')"
             [ngClass]="{'input-error': isInvalidField('T_DISTRIBUTOR_NAME'), 'style-input form-control': true}"
             [ngModel]="distributorSelected?.name" (ngModelChange)="distributorSelected.name = $event"
             type="text" placeholder="{{distributorSelected.name}}" required/>
    </div>
  </div>
  <div class="row field">
    <div class="col-xs-4">
      <label class="field-label required-red-asterisk" translate>T_DISTRIBUTOR_REGION</label>
    </div>
    <div class="col-xs-8">
      <input maxlength="50" name="distributorRegion"
             (change)="checkStringField(distributorSelected.region,'T_DISTRIBUTOR_REGION')"
             [ngClass]="{'input-error': isInvalidField('T_DISTRIBUTOR_REGION'), 'style-input form-control': true}"
             list="regions"
             [ngModel]="distributorSelected?.region" (ngModelChange)="distributorSelected.region = $event"
             type="text" required>
      <datalist id="regions">
        <option *ngFor="let region of allRegions" value="{{region}}"></option>
      </datalist>
    </div>
  </div>
  <div class="row field">
    <div class="col-xs-4">
      <label class="field-label required-red-asterisk" translate>T_DISTRIBUTOR_CONTACT_PERSON</label>
    </div>
    <div class="col-xs-8">
      <input maxlength="50" name="distributorContactPerson"
             (change)="checkStringField(distributorSelected.contactPerson,'T_DISTRIBUTOR_CONTACT_PERSON')"
             [ngClass]="{'input-error': isInvalidField('T_DISTRIBUTOR_CONTACT_PERSON'), 'style-input form-control': true}"
             [ngModel]="distributorSelected?.contactPerson" (ngModelChange)="distributorSelected.contactPerson = $event"
             type="text"/>
    </div>
  </div>
  <div class="row field">
    <div class="col-xs-4">
      <label class="field-label required-red-asterisk" translate>T_DISTRIBUTOR_PHONE</label>
    </div>
    <div class="col-xs-8">
      <input maxlength="50" name="distributorPhone"
             (change)="checkPhoneField(distributorSelected.phone,'T_DISTRIBUTOR_PHONE')"
             [ngClass]="{'input-error': isInvalidField('T_DISTRIBUTOR_PHONE'), 'style-input form-control': true}"
             [ngModel]="distributorSelected?.phone" (ngModelChange)="distributorSelected.phone = $event"
             type="text" required/>
    </div>
  </div>
  <div class="row field">
    <div class="col-xs-4">
      <label class="field-label required-red-asterisk" translate>T_DISTRIBUTOR_EMAIL</label>
    </div>
    <div class="col-xs-8">
      <input maxlength="80" name="distributorEmail"
             (change)="checkEmailField(distributorSelected.email,'T_DISTRIBUTOR_EMAIL')"
             [ngClass]="{'input-error': isInvalidField('T_DISTRIBUTOR_EMAIL'), 'style-input form-control': true}"
             [ngModel]="distributorSelected?.email" (ngModelChange)="distributorSelected.email = $event"
             type="text" required/>
    </div>
  </div>
  <div class="row field">
    <div class="col-xs-4">
      <label class="field-label" translate>T_DISTRIBUTOR_V2_CONTACT</label>
    </div>
    <div class="col-xs-8">
      <input maxlength="50" name="distributorV2Contact" class="style-input form-control"
             [ngModel]="distributorSelected?.v2Contact" (ngModelChange)="distributorSelected.v2Contact = $event"
             type="text"/>
    </div>
  </div>
  <div class="row field">
    <div class="col-xs-4">
      <label class="field-label" translate>T_DISTRIBUTOR_SALES_CONTACT</label>
    </div>
    <div class="col-xs-8">
      <input maxlength="50" name="distributorSalesContact" class="style-input form-control"
             [ngModel]="distributorSelected?.salesContact" (ngModelChange)="distributorSelected.salesContact = $event"
             type="text"/>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-6 center">
      <button type="submit" [disabled]="validateButtonDisabled()" class="btn btn-default-white-background" translate>T_VALIDATE</button>
    </div>
    <div class="col-xs-6 center">
      <button type="button" class="btn btn-default-white-background" (click)="cancelButton()" translate>T_BUTTON_CANCEL</button>
    </div>
  </div>

</form>
