/* Angular modules */
import {Injectable} from '@angular/core';
/* app modules */
import {CubicleService} from './cubicle.service';
import {AddingTransformerInfosWrapper} from '../model/AddingTransformerInfosWrapper';
import {Incompatibility, SwitchBoardComponent} from '../model/component';
import {Item} from '../../../project/shared/project-model';
import {IProduct, OptValue} from '../model/IProduct';

@Injectable()
export class TransformerService {

  constructor() {
  }

  /**
   * Computes visibility for the transformer card on selection component.
   *
   * @param item {Item}
   * @param transformer {SwitchBoardComponent}
   * @param addingTransformerInfos {AddingTransformerInfosWrapper}
   */
  static computeVisibilityForTransformer(item: Item, transformer: SwitchBoardComponent, addingTransformerInfos: AddingTransformerInfosWrapper) {
    // at this moment warning is shown only for INDONESIA offer
    if (item.range.nameKey !== 'T_SM6_ID') {
      return true;
    }
    const cubicle = item.components[addingTransformerInfos.cubicleNumber];
    const cubicleTypeConcerned = CubicleService.isQMorQMCorPM(cubicle, 'T_SM6_ID_SM6R_');
    if (cubicleTypeConcerned) {
      return TransformerService.availableForQMandQMCandPM(transformer, cubicle);
    }
    return true;
  }

  /**
   * Checks if transformer is available for the CUBICLES: QM, QMC, PM.
   *
   * @param transformer {SwitchBoardComponent|IProduct}
   * @param cubicle {SwitchBoardComponent}
   */
  static availableForQMandQMCandPM(transformer: SwitchBoardComponent|IProduct, cubicle: SwitchBoardComponent) {
    const cubicleTransfoIncompatibility = cubicle.incompatibilities.find(
      incompatibility => incompatibility.typeEntity === 'CUBICLE_TRANSFO');
    if (cubicleTransfoIncompatibility) {
      return cubicleTransfoIncompatibility.values.indexOf(transformer.name) === -1;
    }
    return true;
  }

  /**
   * Gets chosen transformer from cubicle.
   *
   * @param cubicle
   */
  static getTransformerFromCubicle(cubicle: SwitchBoardComponent) {
    return cubicle.functionalUnits[0].transformer;
  }

  /**
   * Checks if the Transformer is compatible with Fuse.
   *
   * @param cubicle
   * @param transformer
   * @param fuse
   */
  static isCompatibleWithFuse(cubicle: SwitchBoardComponent, transformer: SwitchBoardComponent, fuse: OptValue) {
    const fuseReference: string = fuse.reference.ref;
    const fuseIncompatibility: Incompatibility =
      transformer.incompatibilities.find(incompatibility => incompatibility.typeEntity === 'TRANSFO_FUSE');

    if (!fuseIncompatibility) {
      return true;
    } else {
      return fuseIncompatibility.values.indexOf(fuseReference) === -1;
    }
  }
}
