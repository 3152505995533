import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
/* Angular modules */
import {Injectable} from '@angular/core';
/* app modules */
/* ngx modules */
import {environment} from "../../../environments/environment";
import {AuthService} from "../../shared/auth/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.authService.getToken();
    return next.handle(this.addToken(req, token));
  }

  private addToken (request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    if (token) {
      request = request.clone({headers: request.headers.append('Authentication', token)});
    }
    return request;
  }


}
