import {Pipe, PipeTransform} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {SessionStorage} from 'ngx-webstorage';
import {Localization} from '../../../shared/model/localization.model';

/**
 * Allows to format numbers with space as thousands separator
 * example: 12222222 --> 12 222 222
 */
@Pipe({name: 'priceFormat'})
export class PriceFormatPipe implements PipeTransform {

  @SessionStorage()
  localization: Localization;


  transform(value: number): string {
    if (isNullOrUndefined(value)) {
      return '0';
    }
    let price: string;
    switch (this.localization.country) {
      case 'ID': {
        price = value.toLocaleString('en-ID');
        break;
      }
      default: {
        price = this.formatPriceWSpaceAsThousandSeparator(value);
        break;
      }
    }
    return price;
  }

  /**
   * format number with space as thousand separator
   * @param {number} value
   * @returns {string}
   */
  private formatPriceWSpaceAsThousandSeparator(value: number): string {
    const sep = ' ';
    const reg = /(\d+)(\d{3})/;
    let numberFormated = value.toFixed(2);
    numberFormated += '';
    while (reg.test(numberFormated)) {
      numberFormated = numberFormated.replace(reg, '$1' + sep + '$2');
    }
    return numberFormated;
  }
}
