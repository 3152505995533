import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OffersPageComponent } from './offers-page/offers-page.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {TransformerModule} from '../transformer/transformer.module';
import {PackageOfferModule} from '../package-offer/package-offer.module';
import {EcorealCtSharedModule} from '../shared/shared.module';
import {MultiselectDropdownModule} from 'angular-2-dropdown-multiselect';
import {OfferService} from './shared/offer.service';
import {PackageOfferGroupSelectorComponent} from './package-offer-group-selector/package-offer-group-selector.component';
import {DropdownModule} from 'primeng/dropdown';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        MultiselectDropdownModule,
        TransformerModule,
        PackageOfferModule,
        EcorealCtSharedModule,
        DropdownModule
    ],
  declarations: [OffersPageComponent, PackageOfferGroupSelectorComponent],
  exports: [OffersPageComponent],
  providers: [OfferService]
})
export class OffersModule { }
