/* angular modules */
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
/* app modules */
import {Item, Project} from '../../project/shared/project-model';
import {DocumentationService} from '../shared';
import {DocumentInformations} from '../shared';
import {ProjectService} from '../../project/shared/project.service';
import {User} from '../../shared/user/user';
import {isNullOrUndefined} from 'util';
/* 3rd parties libraries */
import * as _ from 'lodash';
import {UtilService} from '../../shared/util/util.service';

@Component({
  selector: 'app-documentation-information-form',
  templateUrl: './documentation-information-form.component.html',
  styleUrls: ['./documentation-information-form.component.less']
})
export class DocumentationInformationFormComponent {

  @Input() description: string;
  @Input() skippable: false;
  @Output() onClickSkipButton = new EventEmitter<string>();
  @Output() onClickValidateButton = new EventEmitter<string>();

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  documentInformations: DocumentInformations;

  @SessionStorage()
  user: User;

  constructor(private documentationService: DocumentationService,
              private utilService: UtilService,
              private projectService: ProjectService) {
  }

  clickSkipButton() {
    this.onClickSkipButton.emit('skip');
  }

  clickValidateButton() {
    // Save info
    if (this.documentInformations.projectName !== this.currentProject.name) {
      this.projectService.updateProjectNameRequest(this.currentProject.id, this.documentInformations.projectName.trim())
        .subscribe((result) => this.currentProject = result);
    }
    // Set item id
    if (this.currentItemct) {
      // Item documentation
      this.documentInformations.itemId = this.currentItemct.id;
    } else {
      // Project documentation
      this.documentInformations.itemId = this.currentProject.id;
    }
    // Set version
    this.documentInformations.version = this.documentInformations.version ? this.documentInformations.version + 1 : 1;
    // Set author
    this.documentInformations.author = this.user.id.toString();
    // Set save date
    this.documentInformations.saveDate = Date.now();
    // Reset downloaded due to info change
    this.documentInformations.downloadedDoc = [];

    this.documentationService.updateDocumentInformations(this.documentInformations)
      .subscribe(result => this.documentInformations = result);

    this.onClickValidateButton.emit('validate');
  }

  /**
   * function to check if the user can validate the form.
   * It can be validated if the project name and the user name
   * are not null and not just whitespaces
   * @returns {boolean}
   */
  isValidForm(): boolean {
    return (
      !isNullOrUndefined(this.documentInformations) &&
      !isNullOrUndefined(this.documentInformations.endUserName) &&
      /([^\s])/.test(this.documentInformations.endUserName) &&
      this.utilService.stringDoesNotContainSpecialChars(this.documentInformations.endUserName) &&
      !isNullOrUndefined(this.documentInformations.projectName) &&
      /([^\s])/.test(this.documentInformations.projectName) &&
        this.utilService.stringDoesNotContainSpecialChars(this.documentInformations.projectName));
  }


}
