import {Component, Input, OnInit} from '@angular/core';
import {OfferDataManagementConfigurationStep} from '../../../../../shared/model/range-model';

@Component({
  selector: 'app-odm-add-offer-stepper',
  templateUrl: './odm-add-offer-stepper.component.html',
  styleUrls: ['./odm-add-offer-stepper.component.less']
})
export class OdmAddOfferStepperComponent {

  @Input()
  currentStep: OfferDataManagementConfigurationStep;

  @Input()
  stepList: any[];

  constructor() { }

}
