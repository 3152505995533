
<app-spinner *ngIf="loadingBom" [sizeClass]="'large'"></app-spinner>
<div *ngIf="!loadingBom" class="container-fluid section-margin">
  <div class="selectable">
    <div *ngIf="!isProjectHasConfiguredQuotedItem() && !isProjectHasOrderedItem()" translate>
      T_BOM_NO_ITEM_CONFIGURATIONS
    </div>
    <div *ngIf="isProjectHasConfiguredQuotedItem() || isProjectHasOrderedItem()">
      <!--Part of bom actions (export, order)-->
      <app-bom-actions *ngIf="!!ranges" [loadingBom]="loadingBom"
                       [isReadOnlyProject]="isReadOnlyProject()"
                       [isContractor]="isContractor()"
                       [isCpqProject]="isCpqProject()"
                       [isCpqUser]="isCpqUser()"
                       [status]="status"
                       [bomString]="bomString"
                       [isProjectBom]="true"
                       [ranges]="ranges"
                       (generateProjectBomInfos)="getBomTreeDataFromProject($event)">
      </app-bom-actions>
      <!-- Project Bom table -->
      <div *ngIf="isProjectHasConfiguredQuotedItem()">
        <app-bom-table [cols]="cols"
                       [treeData]="configuredQuotedTreeData"
                       [tableTitle]="configuredQuotedTableTitle"
                       [loadingBom]="loadingBom"
                       [expandedNodes]="expandedNodes"
                       (generateProjectBomInfos)="getBomTreeDataFromProject($event)">
        </app-bom-table>
        <app-total-price *ngIf="utilService.displayPrice(currentProject) && configuredQuotedItemList && configuredQuotedItemList.length > 0 && !!ranges"
                         [currentProjectItems]="configuredQuotedItemList"
                         [displayFoNetPrice]="someFoNetPricesValid(configuredQuotedItemList)"
                         [statusList]="configuredQuotedStatusList"
                         [loadingBom]="loadingBom"
                         [ranges]="ranges"
                         [containsNullPublicPrices]="bomContainsNullPublicPrices()"
                         [containsNullNetPrices]="bomContainsNullNetPrices()"
                         (generateProjectBomInfos)="getBomTreeDataFromProject($event)">
        </app-total-price>
      </div>
      <div *ngIf="isProjectHasOrderedItem()">
        <app-bom-table [cols]="cols"
                       [treeData]="orderedTreeData"
                       [tableTitle]="orderedTableTitle"
                       [loadingBom]="loadingBom"
                       [expandedNodes]="expandedNodes"
                       (generateProjectBomInfos)="getBomTreeDataFromProject($event)">
        </app-bom-table>
        <app-total-price *ngIf="utilService.displayPrice(currentProject) && orderedItemList && orderedItemList.length > 0 && !!ranges"
                         [currentProjectItems]="orderedItemList"
                         [statusList]="orderedStatusList"
                         [displayFoNetPrice]="someFoNetPricesValid(orderedItemList)"
                         [loadingBom]="loadingBom"
                         [containsNullPublicPrices]="bomContainsNullPublicPrices()"
                         [containsNullNetPrices]="bomContainsNullNetPrices()"
                         [ranges]="ranges">
        </app-total-price>
      </div>
      <!--Demo version message-->
      <div *ngIf="!hasRegister && isDemoUser() && isDemoMode" class="div-demo-message">
        <h5 class="demo-message" translate>T_DEMO_VERSION_BOM_MESSAGE</h5>
      </div>
    </div>
  </div>
</div>
