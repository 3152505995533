<p-table #dt
         [value]="users"
         [paginator]="true" [rows]="10"
         [globalFilterFields]="['lastName','firstName', 'email', 'partnerName', 'role']"
         [rowHover]="true">
  <ng-template pTemplate="caption">
    <div class="flex">
        <span class="p-input-icon-left ml-auto">
            <em class="pi pi-search"></em>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
        </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th scope="col" [pSortableColumn]="'lastName'" translate>T_USER_NAME <p-sortIcon field="lastName"></p-sortIcon></th>
      <th scope="col" [pSortableColumn]="'email'" translate>T_USER_EMAIL <p-sortIcon field="email"></p-sortIcon></th>
      <th scope="col" [pSortableColumn]="'role'" translate>T_USER_ROLE <p-sortIcon field="role"></p-sortIcon></th>
      <th scope="col" [pSortableColumn]="'partnerName'" translate>T_USER_PARTNER <p-sortIcon field="partnerName"></p-sortIcon></th>
      <th scope="col" [pSortableColumn]="'lastConnectionDateTimestamp'" translate>T_USER_LAST_CONNECTION_DATE <p-sortIcon field="lastConnectionDateTimestamp"></p-sortIcon></th>
      <th scope="col"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-user>
    <tr>
      <td>{{user.lastName + ' ' + user.firstName}}</td>
      <td>{{user.email}}</td>
      <td>{{user.role}}</td>
      <td>{{user.partnerName}}</td>
      <td>{{getLastConnectionDateFormatted(user)}}</td>
      <td style="text-align: center">
        <button pButton type="button" class="user-delete-button" icon="pi pi-trash" (click)="tryDeleteUser(user)"></button>
      </td>
    </tr>
  </ng-template>
</p-table>

<app-common-modal #deleteUserConfirmationModal
                  [title]="'T_USER_MANAGEMENT_USER_DELETE_MODAL_TITLE' | translate"
                  [name]="'T_USER_MANAGEMENT_USER_DELETE_MODAL_TITLE'"
                  [description]="'T_USER_MANAGEMENT_USER_DELETE_MODAL_DESCRIPTION' | translate"
                  [acceptInnerHtml] = "true" (onClickYesButton)="deleteUser()" (onClickNoButton)="userToBeDeleted = null" >
</app-common-modal>
