import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Range} from '../../../shared/model/range-model';
import {SessionStorage} from 'ngx-webstorage';
import {User} from '../../../shared/user/user';
import {EditRangeInfoPayload} from '../shared/odm-range-management-modal/odm-range-management-modal.component';
import {RangeType} from '../../../shared/model/range-type';
import {NavigationSidePanelItem} from "../../../layout/navigation-side-panel/navigation-side-panel.component";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-odm-offer-selection',
    templateUrl: './odm-offer-selection.component.html',
    styleUrls: ['./odm-offer-selection.component.less']
})
export class OdmOfferSelectionComponent {


    @SessionStorage()
    user: User;

    @Input()
    ranges: Range[] = [];

    @Input()
    availableRanges: Range[] = [];

    @Input()
    loadingImportExport: boolean;

    @Output()
    clickOnComponentManagement: EventEmitter<Range> = new EventEmitter<Range>();

    @Output()
    clickOnRangeConfiguration: EventEmitter<Range> = new EventEmitter<Range>();

    @Output()
    clickOnRangeAddition: EventEmitter<Range> = new EventEmitter<Range>();

    @Output()
    onChangeRangeOrder: EventEmitter<Range[]> = new EventEmitter<Range[]>();

    @Output()
    onDecommissionRange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    clickOnAccessoriesManagement: EventEmitter<Range> = new EventEmitter<Range>();

    @Output()
    clickOnOptionsManagement: EventEmitter<Range> = new EventEmitter<Range>();

    @Output()
    onClickRangeExport: EventEmitter<String> = new EventEmitter<String>();

    @Output()
    onClickRangeImport: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    saveRangeInfo: EventEmitter<EditRangeInfoPayload> = new EventEmitter<EditRangeInfoPayload>();

    tabs: NavigationSidePanelItem[] = [
        {id: 1, title: 'T_NAV_ADMIN_OFFER_DATA_MANAGEMENT_STANDARD', active: true},
        {id: 2, title: 'T_NAV_ADMIN_OFFER_DATA_MANAGEMENT_IMPORTED'},
        {id: 3, title: 'T_NAV_ADMIN_OFFER_DATA_MANAGEMENT_AVAILABLE'},
    ]

    constructor(private translateService: TranslateService) {
    }

    getTabs() {
        if (!this.importReferencesIsAllowed()) {
            return this.tabs.filter((tab) => {
                return tab.id !== 2
            })
        }
        return this.tabs;
    }

    isTabActive(id: number) {
        return this.tabs.find((tab) => {
            return tab.id === id
        }).active;
    }

    selectTab(tab: NavigationSidePanelItem) {
        this.tabs = this.tabs.map((t) => {
            t.active = t.id === tab.id;
            return t;
        });
    }

    getStandardRangesTooltip() {
        return this.translateService.instant('T_OFFER_DATA_MANAGEMENT_STANDARD_WORKFLOW_TOOLTIP');
    }
    getImportPackageTooltip() {
        return this.translateService.instant('T_OFFER_DATA_MANAGEMENT_IMPORT_PACKAGE_TOOLTIP');
    }

    rangeExport(rangeId: string) {
        this.onClickRangeExport.emit(rangeId)
    }

    rangeImport(event) {
        this.onClickRangeImport.emit(event);
    }

    importReferencesIsAllowed(): boolean {
        return environment.packageActivation.includes(this.user.partnerCountry);
    }

    getStandardRanges() {
        return this.ranges.filter((range) => {
            return range.rangeType !== RangeType.IMPORTED;
        })
    }

    getImportedRanges() {
        return this.ranges.filter((range) => {
            return range.rangeType === RangeType.IMPORTED;
        })
    }

    /**
     * Range components has been selected
     * @param range
     */
    goToComponentManagement(range) {
        this.clickOnComponentManagement.emit(range);
    }

    /**
     * One range has been clicked for its own configuration
     * @param range
     */
    goToRangeConfiguration(range) {
        this.clickOnComponentManagement.emit(range);
    }

    /**
     * Range accessories has been selected
     * @param range
     */
    goToAccessoriesManagement(range) {
        this.clickOnAccessoriesManagement.emit(range);
    }

    /**
     * Range accessories has been selected
     * @param range
     */
    goToOptionsManagement(range) {
        this.clickOnOptionsManagement.emit(range);
    }

    /**
     * Range addition has been selected
     * @param {any} range
     */
    goToRangeAddition(range) {
        this.clickOnRangeAddition.emit(range);
    }

    /**
     * Range order has been changed
     * @param {any} listranges list of range
     */
    updateRangeOrder(listRanges, rangeType) {
        if (rangeType === RangeType.IMPORTED) {
            listRanges = [...this.getStandardRanges(), ...listRanges];
        } else {
            listRanges = [...listRanges, ...this.getImportedRanges()];
        }
        this.ranges = listRanges;
        this.onChangeRangeOrder.emit(listRanges);
    }

    /**
     * Range has been decommissioned
     * @param {string} rangeId range id
     */
    decommissionRange(rangeId: string) {
        this.onDecommissionRange.emit(rangeId);
    }

    onSaveRangeInfo(rangeInfo: EditRangeInfoPayload) {
        this.saveRangeInfo.emit(rangeInfo);
    }
}
