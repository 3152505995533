/* Angular modules */
import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
import {LoggerService} from '../../shared/logging/logger.service';
/* app modules*/
import {ComponentType} from '../shared/model/component-type';
import {ComponentSearchModeEnum, SwitchBoardComponent} from '../shared/model/component';
import {ComponentService} from '../shared/services/component.service';
import {Item} from '../../project/shared/project-model';
import {AddingTransformerInfosWrapper} from '../shared/model/AddingTransformerInfosWrapper';
import {FilterCategory} from '../shared/model/filterCategory';
import {ProductsFiltersService} from '../../shared/products-filters/products-filters.service';
import {VisibilityCheck} from '../shared/model/visibilityCheck';
import {TransformerService} from '../../transformer/shared/transformer-service';
import {RangeType} from '../../shared/model/range-type';
import {User} from '../../shared/user/user';
import {isNullOrUndefined} from 'util';
import {MySEStatusEnum} from '../../shared/model/mySEStatusEnum';
import {FilterService} from '../shared/services/filter.service';
import {PriceManagementService} from '../../admin/shared';
import * as _ from 'lodash';
import {Subject} from 'rxjs/Rx';

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.less']
})
export class SelectionComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
  @Input()
  componentType: ComponentType;

  @Input()
  transformerInfos: AddingTransformerInfosWrapper;

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  user: User;

  @SessionStorage()
  allComponents: SwitchBoardComponent[];

  @SessionStorage()
  filteredComponents: SwitchBoardComponent[];


  rangeComponents: Array<SwitchBoardComponent>;
  displayedComponents: Array<SwitchBoardComponent>;

  filters: Array<FilterCategory> = [];
  resetFilters = false;

  loading = false;
  loadingTransformers = false;

  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private logger: LoggerService,
              private componentService: ComponentService,
              private productsFiltersService: ProductsFiltersService,
              private cdRef: ChangeDetectorRef,
              private transformerService: TransformerService,
              private priceManagementService: PriceManagementService,
              private filterService: FilterService) {
  }

  ngOnInit() {
    // If user arrive directly from the project page we need to reload the components and re-apply electrical characteristics filters if any
    if (this.allComponents.length === 0) {
      this.loading = true;

      // Get all components of ranges from backend
      this.componentService.getComponents(this.currentItemct.id, ComponentSearchModeEnum.ALL)
        .takeUntil(this.unsubscribe$)
        .subscribe(result => {
        this.allComponents = result;
        if (this.currentItemct.range.hideCharacteristicScreen) {
          // No electrical characteristics screen so no pre filter of components
          this.rangeComponents = this.allComponents.filter(component => component.type === ComponentType.CUBICLE);
          this.filteredComponents = this.rangeComponents;
          this.displayedComponents = this.rangeComponents;
        } else {
          this.rangeComponents = this.allComponents.filter(component => component.type === ComponentType.CUBICLE);
          // Electrical characteristics screen so application of electrical filter
          const electricalFilters = this.productsFiltersService.extractFiltersFromComponents(this.rangeComponents,
            this.currentItemct.range.electricalCharacteristicsFilters, true, new Map<string, VisibilityCheck>());
          // Apply previous selection to filters
          this.productsFiltersService.selectElectricalCharacteristicsFilter(this.currentItemct.electricalCharacteristics, electricalFilters);
          // Apply filters
          this.filteredComponents = <SwitchBoardComponent[]>this.productsFiltersService.applyFilters(electricalFilters, this.rangeComponents);
          this.rangeComponents = this.filteredComponents;
          this.displayedComponents = this.filteredComponents;
        }
        // Normal case adding a component for cubicle range or transformer range
        this.filters = this.productsFiltersService.extractFiltersFromComponents(this.displayedComponents,
          this.currentItemct.range.visibleComponentsFilters, false, new Map<string, VisibilityCheck>());
        // Specific sort order for filters
        this.filterService.sortFiltersRegardingRange(this.filters, this.currentItemct.range);

        // Sort components
        this.sortComponents();

        this.loading = false;
      });
    } else if (this.currentItemct.range.rangeType === RangeType.SWITCHBOARD) {
      // Adding only the transformers compatible to the range
      this.loadingTransformers = true;
      this.componentService.getComponents(this.currentItemct.id, ComponentSearchModeEnum.COMPATIBLE)
        .takeUntil(this.unsubscribe$)
        .subscribe(result => {
        this.allComponents.push(...result);
        this.allComponents = _.uniqBy(this.allComponents, comp => comp.id);
        this.loadingTransformers = false;
        if (this.componentType === ComponentType.TRANSFORMER) {
          this.displaySelectionForTransformers();
        }
      });
    }
  }

  /**
   * FIXME: ngOnChanges is not used correctly here. I don't want to change the code too much and do
   * the required code refactoring to fix DYN-6711.
   */
  ngOnChanges(changes: SimpleChanges) {

    this.resetFilters = changes.componentType ? changes.componentType.currentValue !== changes.componentType.previousValue : false;

    this.loading = true;



    const afterComponentsLoading = () => {

      this.rangeComponents = this.filteredComponents.filter(component => component.type === this.componentType);
      // In the case of 'cross selling' filter only compatible transformers reference
      if (this.componentType === ComponentType.TRANSFORMER && this.transformerInfos
        && this.transformerInfos.compatibleTransformerList && this.transformerInfos.compatibleTransformerList.length > 0) {
        this.allComponents = this.allComponents.filter(comp => this.transformerInfos.compatibleTransformerList.includes(comp.reference.ref));
      }
      this.sortComponents();

      if (this.user.currentMySEAccount) {
        this.priceManagementService.getComponentsPrice(this.allComponents)
          .toPromise()
          .then(prices => {
            prices.forEach(price => {
              const component = this.allComponents.find(c => c.reference.ref === price.reference);

              if (!component) {
                return;
              }
              component.reference.mySeNetPriceStatus = price.mySEStatus;

              if (price.totalPrice !== null && price.totalPrice !== undefined ) {
                component.reference.price = price.totalPrice;
              }
            });
          })
          .catch(err => {
            this.logger.error(err);
            // When an error occurs, only populate the status of components without prices
            this.allComponents.forEach(c => {
              if (c.reference.price === null) {
                c.reference.mySeNetPriceStatus = MySEStatusEnum.NOT_REACHABLE;
              }
            });
          });
      }

      // When adding a transformer to a cubicle we must retrieve the visible filters of all the transformer ranges
      if (this.currentItemct.range.rangeType === RangeType.SWITCHBOARD && this.componentType === ComponentType.TRANSFORMER) {
        if (changes.componentType === undefined) {
          // If componentType had not changed, it means we have click on Add transformer 2 times in a row
          this.filters = [];
        }
        this.displaySelectionForTransformers();
      } else {
        // Normal case adding a component for cubicle range or transformer range
        this.filters = this.productsFiltersService.extractFiltersFromComponents(this.rangeComponents,
          this.currentItemct.range.visibleComponentsFilters, false, new Map<string, VisibilityCheck>());
        // Specific sort order for filters
        this.filterService.sortFiltersRegardingRange(this.filters, this.currentItemct.range);

        this.displayedComponents = <SwitchBoardComponent[]>this.productsFiltersService.applyFilters(this.filters, this.rangeComponents);
        this.loading = false;
      }
    };

    if (this.allComponents.length === 0) {
      this.componentService.getComponents(this.currentItemct.id)
        .takeUntil(this.unsubscribe$)
        .subscribe(result => {
        this.allComponents = result;
        this.filteredComponents = result;
        afterComponentsLoading();
      });
    } else {
      afterComponentsLoading();
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    // unsubscribe from the subject itself:
    this.unsubscribe$.unsubscribe();
  }

  public updateComponentList(selectedFilters: Array<FilterCategory>) {
    this.filters = selectedFilters;
    this.displayedComponents = <SwitchBoardComponent[]>this.productsFiltersService.applyFilters(selectedFilters, this.rangeComponents);
    this.productsFiltersService.updateFilters(this.filters, this.displayedComponents);
  }

  /**
   * removes all filters and displays initial components
   */
  public removeAllFilters() {
    this.displayedComponents = this.rangeComponents;
    if (this.currentItemct.range.rangeType === RangeType.SWITCHBOARD && this.componentType === ComponentType.TRANSFORMER) {
      this.transformerService.getFiltersForAllRanges()
        .takeUntil(this.unsubscribe$)
        .subscribe(visibleFilters => {
        this.extractFilterAddingTransformer(visibleFilters);
      });
    } else {
      this.filters = this.productsFiltersService.extractFiltersFromComponents(this.rangeComponents,
        this.currentItemct.range.visibleComponentsFilters, false, new Map<string, VisibilityCheck>());

      // Specific sort order for filters
      this.filterService.sortFiltersRegardingRange(this.filters, this.currentItemct.range);
    }
  }

  private extractFilterAddingTransformer(visibleFilters) {
    let initFilter = true;
    const componentsGroupByRange = _.groupBy(this.rangeComponents, comp => comp.rangeId);
    for (const key of Object.keys(componentsGroupByRange)) {
      this.transformerService.getRange(key)
        .takeUntil(this.unsubscribe$)
        .subscribe(range => {
        // Flag to avoid latency on display
        if (initFilter) {
          this.filters = [];
          initFilter = false;
        }
        const filters: FilterCategory[] = this.productsFiltersService.extractFiltersWithCubicleIncompatibilities(componentsGroupByRange[key], visibleFilters, false,
          (this.transformerInfos === undefined || this.transformerInfos === null) ? null : this.currentItemct.components[this.transformerInfos.cubicleNumber],
          new Map<string, VisibilityCheck>());
        filters.forEach(filter => {
          filter.rangeName = range.nameKey;
        });
        this.filters = this.filters.concat(filters);
      });
    }
  }

  /**
   * Sort components according to the range defined criterion (default sort by reference)
   * When sorting all transformer ranges of the offer for add to a cubicle retrieving from server
   */
  private sortComponents() {
    if (this.currentItemct.range.rangeType === RangeType.SWITCHBOARD && this.componentType === ComponentType.TRANSFORMER) {
      this.transformerService.getCardSortCriterionForAllRanges()
        .takeUntil(this.unsubscribe$)
        .subscribe(criterion => this.filterService.doTheSort(criterion, this.rangeComponents));
    } else {
      this.filterService.doTheSort(this.currentItemct.range.cardSortCriterion, this.rangeComponents);
    }
  }

  /**
   * Display transformer for adding on switchboard
   */
  private displaySelectionForTransformers() {
    this.rangeComponents = this.allComponents.filter(component => component.type === ComponentType.TRANSFORMER);
    // Filters computing
    this.transformerService.getFiltersForAllRanges()
      .takeUntil(this.unsubscribe$)
      .subscribe(visibleFilters => {
      this.extractFilterAddingTransformer(visibleFilters);
      this.displayedComponents = <SwitchBoardComponent[]>this.productsFiltersService.applyFilters(this.filters, this.rangeComponents);
      this.loading = false;
    });
  }
}
