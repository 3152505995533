<form (submit)="clickValidateButton()">
  <div class="row field">
    <div class="col-xs-4">
      <label class="field-label" translate>T_BO_MODAL_COUNTRY_NAME</label>
    </div>
    <div class="col-xs-6 col-xs-offset-2 text-left">
      <span>{{countryName}}</span>
    </div>
  </div>
  <div class="row field">
    <div class="col-xs-4">
      <label class="field-label" translate>T_BO_MODAL_LANGUAGE</label>
    </div>
    <div class="col-xs-6 col-xs-offset-2">
      <select class="form-control language-width" (change)="selectLanguageCode($event.target.value)">
        <option *ngFor="let language of languages" [value]="language.code">
          {{language.language +' ('+language.code.toUpperCase()+')'}}
        </option>
      </select>
    </div>
  </div>
  <div class="row button-margin">
    <div class="col-xs-3 col-xs-offset-6">
      <button type="button" class="btn btn-default-white-background" (click)="cancelButton()" translate>T_BUTTON_CANCEL</button>
    </div>
    <div class="col-xs-3">
      <button type="submit" class="btn btn-default-white-background" translate>T_VALIDATE</button>
    </div>
  </div>
</form>
