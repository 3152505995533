import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {SessionStorage} from 'ngx-webstorage';
import {Item, Project, Status} from '../../../project/shared/project-model';
import {Packing} from '../../../shared/model/packingSelected-model';
import {ItemService} from '../../../project/shared/item.service';
import {User} from '../../../shared/user/user';
import {BomHelper, BomService, Distributor} from '../../shared';
import {RangeType} from '../../../shared/model/range-type';
import {CommonModalComponent} from '../../../shared/common-modal/common-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {RegisterModalComponent} from '../../../shared/register-modal/register-modal.component';
import {UtilService} from '../../../shared/util/util.service';
import {LoggerService} from '../../../shared/logging/logger.service';
import * as moment from 'moment';
import {Resource, Role} from '../../../core/access-control/enum';
import {AccessControlService} from '../../../core';
import {BomDistributorModalComponent, BomMailModalComponent} from '../..';
import {MessageService} from 'primeng/api';
import {isNullOrUndefined} from 'util';
import {Partner} from '../../../shared/partner/partner';
import {DistributorService} from '../../../admin/shared/distributor/distributor.service';
import {NavigationLinkEnum} from '../../../shared/guards/navigationLink-enum';
import {Router} from '@angular/router';
import {Range, RangeStatus, RestrictedOrderingType} from '../../../shared/model/range-model';
import {OrderingMode} from '../../../shared/model/localization.model';
import {BomReferencesModalComponent} from './bom-references-modal/bom-references-modal.component';
import {ReferenceToOrder} from '../../../configuration/shared/model/component';
import {Subject} from 'rxjs/Rx';
import {NavigationStep} from "../../../shared/guards/navigationStep-enum";

@Component({
  selector: 'app-bom-actions',
  templateUrl: './bom-actions.component.html',
  styleUrls: ['./bom-actions.component.less'],
})
export class BomActionsComponent implements OnInit, OnChanges, OnDestroy {

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  localization;

  @SessionStorage()
  user: User;

  @SessionStorage()
  partner: Partner;

  @SessionStorage()
  isDemoMode: boolean;

  // Variable for navigation bar when no item is currently selected
  @SessionStorage()
  noItemNavigationStep;

  @Input()
  loadingBom: boolean;

  @Input()
  isCpqProject: boolean;

  @Input()
  isCpqUser: boolean;

  @Input()
  isReadOnlyProject: boolean;

  @Input()
  isContractor: boolean;

  @Input()
  status = Status;

  @Input()
  bomString: string;

  @Input()
  isProjectBom: boolean;

  @Input()
  ranges: Range[];

  @Output()
  generateBomInfos = new EventEmitter<Item>();

  @Output()
  generateProjectBomInfos = new EventEmitter<Item[]>();


  @ViewChild('registerModal') registerModal: RegisterModalComponent;

  @ViewChild('distributorChoiceModal') distributorChoiceModal: BomDistributorModalComponent;

  @ViewChild('orderEmailModal') orderEmailModal: BomMailModalComponent;

  @ViewChild('mailModal') mailModal: CommonModalComponent;

  @ViewChild('orderingWarningModal') orderingWarningModal: CommonModalComponent;

  @ViewChild('referenceListModal') referenceListModal: BomReferencesModalComponent;

  quoteIsValid = false;

  distributorRecipientMail: string;

  recipientMail = this.getEmailRecipient();

  messageMail: string;

  allDistributors: Distributor[] = [];

  projectItems: Item[];

  referencesToOrder: ReferenceToOrder[] = [];

  orderType: RestrictedOrderingType;

  isMySEOrdering = false;

  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private router: Router,
              private bomService: BomService,
              private bomHelper: BomHelper,
              private itemService: ItemService,
              private translateService: TranslateService,
              private utilService: UtilService,
              private logger: LoggerService,
              private distributorService: DistributorService,
              private messageService: MessageService,
              private accessControlService: AccessControlService) { }

  ngOnInit() {
    if (!this.accessControlService.can(this.user.role, Resource.PURCHASE_BY_SCHNEIDER)) {
      this.distributorService.getDistributors()
        .takeUntil(this.unsubscribe$)
        .subscribe(
        allDistributors => {
          this.allDistributors = allDistributors;
        }
      );
    }
    this.isQuotationValid();
    this.messageMail = this.isProjectBom ? 'T_BOM_PROJECT_MAIL_BODY_MESSAGE' : 'T_BOM_MAIL_BODY_MESSAGE';
    // Case of Contractor mixed user
    if (Role.CONTRACTOR_MIXED === this.user.role) {
      this.messageMail = 'T_BOM_ORDER_REFERENCE_MAIL_BODY_MESSAGE';
    }
  }

  ngOnChanges(): void {
    if (this.isProjectBom) {
      this.itemService.getItems(this.currentProject.id)
        .takeUntil(this.unsubscribe$)
        .subscribe(items => {
          this.projectItems = items.filter(item => !!item.packageOfferId || item.range.status !== RangeStatus.DECOMMISSIONED);
        });
    }
  }

  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    // unsubscribe from the subject itself:
    this.unsubscribe$.unsubscribe();
  }

  onChangeOptionalPacking(value) {
    this.loadingBom = true;
    this.currentItemct.status = Status.configured;
    if (!this.currentItemct.optionalPacking) {
      this.currentItemct.optionalPacking = [];
    }
    // If the package is already setted, we unselect it from the list. Otherwise, we select it.
    // If the package is already setted, we remove it from the list. Otherwise, we add it.
    if (this.currentItemct.optionalPacking.filter(opt => opt.name === value).length > 0) {
      this.currentItemct.optionalPacking = this.currentItemct.optionalPacking.filter(opt => opt.name !== value);
    } else {
      const optionalPacking: Packing = new Packing();
      optionalPacking.name = value;
      this.currentItemct.optionalPacking.push(optionalPacking);
    }

    this.itemService.updateItem(this.currentItemct, this.user)
      .takeUntil(this.unsubscribe$)
      .subscribe(res => {
        this.currentItemct = res;
        this.bomService.getBom(this.currentItemct.id)
          .takeUntil(this.unsubscribe$)
          .subscribe(itemProject => {
          this.currentItemct = itemProject;
          this.loadingBom = false;
          this.generateBomInfos.emit(itemProject);
        });
      });
  }

  showRegisterModal() {
    this.registerModal.show();
  }

  showDistributorChoiceModal() {
    this.distributorChoiceModal.show();
  }

  showOrderEmailModal() {
    this.orderEmailModal.show();
  }

  public distributorSelected(mail: string) {
    this.distributorRecipientMail = mail;
    this.recipientMail = this.getEmailRecipient();
    // Case of Contractor mixed user
    if (Role.CONTRACTOR_MIXED === this.user.role) {
      this.recipientMail = this.distributorRecipientMail;
    }
    this.showOrderEmailModal();
  }

  public exportBom() {
    if (!this.isProjectBom) {
      this.projectItems = [this.currentItemct];
    }
    this.bomService.exportBom(this.currentProject.id, this.projectItems, false, this.user, this.isCpqProject, this.isProjectBom);
  }


  orderRequest() {
    if (!this.disabledOrder()) {
    // ordering by mail is not allowed until item is quoted
    const isItemBom = !this.isProjectBom && this.currentItemct && this.currentItemct.status === Status.quoted && this.quoteIsValid;
    const isProjectBom = this. isProjectBom && this.currentProject
        && this.projectItems.some(item => UtilService.isOrderableItem(item));

    if (isItemBom || isProjectBom) {
      if (!this.accessControlService.can(this.user.role, Resource.PURCHASE_BY_SCHNEIDER)) {
        this.showDistributorChoiceModal();
      } else {
        this.showOrderEmailModal();
      }

        let total_price;
        if (isItemBom) {
          total_price = UtilService.getFormattedTotalDiscountPriceFromBOM(this.currentItemct.bom,
            this.user.currentMySEAccount === null && this.currentItemct.totalPrice.validFoNetPrice,
            this.currentItemct.discount);
        } else  if (isProjectBom) {
          total_price = UtilService.getFormatedTotalDiscountPriceFromProjectBOM(this.currentProject);
        }
        this.logger.business('Order configuration',
          {
            total_price: total_price,
            currency: this.localization.currency,
            bom_references: this.bomString
          });
      }
    }
  }

  orderWithMySE() {
    if (!this.disabledMySEOrder()) {
    if (!this.isProjectBom && this.currentItemct.status !== Status.ordered && this.quoteIsValid) {
      // order item Bom with MySE
      this.orderItemBomWithMySE();
      } else if (this. isProjectBom && this.currentProject && this.projectItems.some(item => UtilService.isOrderableItem(item))) {
      // order project Bom with MySE
      this.orderProjectBomWithMySE();
    }
  }
  }

  goOnMYSE() {
    if (this.hasMySEUrl()) {
      if (!this.isProjectBom && this.currentItemct.mySEUrl) {
        window.open(this.currentItemct.mySEUrl);
      } else if (this.isProjectBom && this.currentProject && this.currentProject.mySEUrl) {
        window.open(this.currentProject.mySEUrl);
      }
    }
  }

  /**
   * Update the project Bom after the ordering process
   * @param itemList
   */
  updateProjectBom(itemList: Item[]) {
    this.projectItems = itemList;
    this.generateProjectBomInfos.emit(itemList);
  }

  collaboration(): void {
    this.logger.info('BomComponent collaboration()');
    this.currentItemct = null;
    this.router.navigate([NavigationLinkEnum.PROJECT_SHARING, this.currentProject.id]);
  }

  disabledOrder (): boolean {
    if (!this.isProjectBom && this.currentItemct) {
      this.isQuotationValid();
      return this.currentItemct.status !== this.status.quoted || !this.quoteIsValid;
    } else if (this.isProjectBom && this.currentProject) {
      return !this.projectItems.some(item => UtilService.isOrderableItem(item));
    } else {
      return true;
    }
  }

  disabledMySEOrder (): boolean {
    if (!this.isProjectBom && this.currentItemct) {
      return this.currentItemct.status !== this.status.quoted || !this.quoteIsValid || !this.utilService.isValidMySEPrice(this.currentItemct);
    } else if (this.isProjectBom && this.currentProject && !this.currentProject.mySEUrl) {
      return !this.projectItems.some(item => UtilService.isOrderableItem(item))
        || !this.utilService.isValidMySENetPriceProject(this.currentProject);
    } else {
      return true;
    }
  }

  hasMySEUrl(): boolean {
    if (!this.isProjectBom && this.currentItemct) {
      return !isNullOrUndefined(this.currentItemct.mySEUrl) && this.currentItemct.mySEUrl !== '';
    } else if (this.isProjectBom && this.currentProject) {
      return !isNullOrUndefined(this.currentProject.mySEUrl) && this.currentProject.mySEUrl !== '';
    } else {
      return false;
    }
  }

  /**
   * Check if the country ordering mode is mixed
   */
  isOrderingMixedMode() {
    return OrderingMode.MIXED === this.localization.orderingMode;
  }

  /**
   * Check if the user is a mixed contractor
   */
  isContractorMixedUser() {
    return Role.CONTRACTOR_MIXED === this.user.role;
  }

  /**
   * Check if the user is a contractor (direct or indirect)
   */
  isContractorsUser() {
    return Role.CONTRACTOR === this.user.role || Role.CONTRACTOR_INDIRECT === this.user.role;
  }

  /**
   * Check if we have a direct restricted ordering
   */
  hasDirectRestrictedOrdering() {
    if (!this.isProjectBom) {
      return this.hasRestrictedOrdering([this.currentItemct], RestrictedOrderingType.DIRECT);
    } else {
      return this.hasRestrictedOrdering(this.projectItems, RestrictedOrderingType.DIRECT);
    }
  }

  /**
   * Check if we have a indirect restricted ordering
   */
  hasIndirectRestrictedOrdering() {
    if (!this.isProjectBom) {
      return this.hasRestrictedOrdering([this.currentItemct], RestrictedOrderingType.INDIRECT);
    } else {
      return this.hasRestrictedOrdering(this.projectItems, RestrictedOrderingType.INDIRECT);
    }
  }

  /**
   * Disabled link in the multiple ordering case
   * @param restrictedOrderingType: restricted ordering type ('DIRECT' || 'INDIRECT')
   */
  disabledMultipleOrder (restrictedOrderingType): boolean {
    const itemReferences: ReferenceToOrder[] = [];
    let orderedReferences = [];
    let confStatus;
    let sameRefInItemsWithDifferentStatus = false;
    if (!this.isProjectBom) {
      this.bomHelper.getOrderingReferenceList(this.currentItemct, restrictedOrderingType, itemReferences);
      if (this.currentItemct.orderedReferences && this.currentItemct.orderedReferences.length > 0) {
        orderedReferences = this.currentItemct.orderedReferences;
      }
      confStatus =  this.currentItemct.status !== this.status.quoted || !this.quoteIsValid;
    } else {
      this.projectItems.forEach(item => {
        this.bomHelper.getOrderingReferenceList(item, restrictedOrderingType, itemReferences);
        if (itemReferences.length > 0 &&
          UtilService.isOrderableItem(item)) {
           sameRefInItemsWithDifferentStatus = true;
        }
        if (item.orderedReferences && item.orderedReferences.length > 0) {
          orderedReferences = orderedReferences.concat(item.orderedReferences);
        }
      });
      confStatus = itemReferences.length === 0;
    }
    if (orderedReferences.length > 0 && itemReferences.length > 0) {
      if (sameRefInItemsWithDifferentStatus) {
        return false;
      } else {
        return itemReferences.every(v => orderedReferences.includes(v.reference));
      }
    } else {
      return confStatus;
    }
  }

  /**
   * Order by SE mail
   */
  orderToSEByMail() {
    if (!this.disabledMultipleOrder('DIRECT')) {
    this.isMySEOrdering = false;
    this.referencesOrder(RestrictedOrderingType.DIRECT);
  }
  }

  /**
   * Order through distributor
   */
  orderThroughDistributor() {
    if (!this.disabledMultipleOrder('INDIRECT')) {
    this.referencesOrder(RestrictedOrderingType.INDIRECT);
  }
  }

  /**
   * Order by mySE
   */
  orderByMySE() {
    if (!this.disabledMultipleOrder('DIRECT')) {
    this.isMySEOrdering = true;
    this.referencesOrder(RestrictedOrderingType.DIRECT);
  }
  }

  /**
   * Proceed the ordering by the given type
   * @param orderType: restricted ordering type
   */
  proceedOrder(orderType) {
    if (RestrictedOrderingType.DIRECT === orderType) {
      if (this.user.currentMySEAccount && this.isMySEOrdering) {
        this.orderWithMySE();
      } else {
        this.recipientMail = this.emailRecipientForContractorMixed();
        this.showOrderEmailModal();
      }
    } else if (RestrictedOrderingType.INDIRECT === orderType) {
      this.showDistributorChoiceModal();
    }
  }

  /**
   * Warning modal if the user does not have the right profile to order
   */
  orderWithNoRightRole() {
    if (!this.disabledOrder()) {
    this.orderingWarningModal.show();
  }
  }

  /**
   * Ordering the item BOM with MySE
   */
  private orderItemBomWithMySE() {
    this.loadingBom = true;
    const mySEWindow = window.open();
    this.itemService.orderWithMySE(this.currentProject.id, this.currentItemct.id)
      .takeUntil(this.unsubscribe$)
      .subscribe(
      item => {
        this.currentItemct = item;
        this.logger.info('BomPageComponent orderCurrentItem()');
        const now = new Date();
        this.currentItemct.date = now.getTime();
        // add references to order in item in the multiple ordering case
        if (this.referencesToOrder && this.referencesToOrder.length > 0) {
          this.referencesToOrder.forEach(referenceToOrder => this.currentItemct.orderedReferences.push(referenceToOrder.reference));
        }

        this.itemService.updateItemWithStatusChange(this.currentItemct, Status.ordered, this.user)
          .takeUntil(this.unsubscribe$)
          .subscribe(
            res => {
              this.currentItemct = res;

              this.messageService.add({
                severity: 'success',
                summary: this.translateService.instant('T_ORDER_MYSE_SUCCEED_TITLE'),
                detail: this.translateService.instant('T_ORDER_MYSE_SUCCEED'),
              });
              this.logger.business('MySe Order configuration',
                {
                  total_price: UtilService.getFormattedTotalDiscountPriceFromBOM(this.currentItemct.bom,
                    this.user.currentMySEAccount === null && this.currentItemct.totalPrice.validFoNetPrice,
                    this.currentItemct.discount),
                  currency: this.localization.currency,
                  bom_references: this.bomString
                });
            },
            error => null,
            () => {
              this.loadingBom = false;
              // Work-around to avoid popup blocking
              if (this.currentItemct.mySEUrl) {
                mySEWindow.location.assign(this.currentItemct.mySEUrl);
              } else {
                mySEWindow.close();
              }
            }
          );
      },
      () => {
        // Error Toast
        this.loadingBom = false;
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ORDER_MYSE_FAILED_TITLE'),
          detail: this.translateService.instant('T_ORDER_MYSE_FAILED'),
        });
      });
  }


  /**
   * Ordering the project BOM with MySE
   */
  private orderProjectBomWithMySE() {
    this.loadingBom = true;
    const mySEWindow = window.open();
    this.itemService.orderProjectWithMySE(this.currentProject.id)
      .takeUntil(this.unsubscribe$)
      .subscribe(project => {
        this.currentProject = project;
        const projectId = project.id;
        this.logger.info('ProjectBomComponent orderCurrentProject(): ' + projectId);
        const now = new Date();
        let totalPrice = 0;
        this.itemService.getItems(this.currentProject.id)
          .takeUntil(this.unsubscribe$)
          .subscribe(items => {
            this.projectItems = items.filter(item => !!item.packageOfferId || item.range.status !== RangeStatus.DECOMMISSIONED);
            const itemList: Item[] = this.referencesToOrder && this.referencesToOrder.length > 0 ? this.bomHelper.getItemsToUpdate(items, this.referencesToOrder)
              : items.filter(item => UtilService.isOrderableItem(item));
            let count = 0;
            itemList.forEach(item => {
              item.date = now.getTime();
              item.status = Status.ordered;
              this.itemService.updateItem(item, this.user, false)
                .takeUntil(this.unsubscribe$)
                .subscribe(
                  res => {
                    count++;
                    totalPrice += UtilService.applyDiscountPrice(res.bom.totalPrice, res.discount);
                    // Update the project Bom after ordering process
                    if (count === itemList.length) {
                      this.itemService.getItems(this.currentProject.id)
                        .takeUntil(this.unsubscribe$)
                        .subscribe(itemsElem => {
                          this.generateProjectBomInfos.emit(itemsElem);
                          this.loadingBom = false;
                          const url = this.currentProject.mySEUrl;
                          if (url) {
                            mySEWindow.location.assign(url);
                          } else {
                            mySEWindow.close();
                          }
                        });
                    }
                  }
                );
            });
          });
        this.logger.business('Mail order for project configuration',
          {
            total_price: UtilService.formatPrice(totalPrice),
            currency: this.localization.currency
          });
      });
  }

  private isQuotationValid() {
    if (this.currentItemct ) {
      const validityPeriod = this.bomService.getValidityPeriod(this.currentItemct, this.ranges);
      const futureDate: moment.Moment = moment(this.currentItemct.date).add(validityPeriod, 'days');
      this.quoteIsValid = futureDate.isAfter(moment());
    }
  }

  /**
   * Retrieve a recipient email at the time of the ordering relative to the user's role.
   * This rule is valid for all countries and all offers.
   * @returns {string}: A recipient email in relation to the user's role
   */
  private getEmailRecipient(): string {
    switch (this.user.role) {
      case Role.CONTRACTOR_INDIRECT:
        return this.distributorRecipientMail;
      case Role.CONTRACTOR:
        return this.emailRecipientForContractor();
      case Role.RESELLER:
        return !isNullOrUndefined(this.partner.salesContact.email) && this.partner.salesContact.email !== '' ?
          this.partner.salesContact.email : this.localization.mail;
      case Role.NATIONAL_SALES:
        return this.localization.mail;
      case Role.FRONT_OFFICE:
        return '';
      default:
        return null;
    }
  }

  /**
   * Recipient for contractor
   */
  private emailRecipientForContractor(): string {
    if (this.currentItemct) {
      return !!this.currentItemct.range && !isNullOrUndefined(this.currentItemct.range.emailOrdering) && this.currentItemct.range.emailOrdering !== '' ?
        this.currentItemct.range.emailOrdering : this.localization.mail;
    } else if (isNullOrUndefined(this.currentItemct) && this.currentProject) {
      const item = this.currentProject.itemCT.find(elem => !!elem.range && !isNullOrUndefined(elem.range.emailOrdering) && elem.range.emailOrdering !== '');
      return !isNullOrUndefined(item) ? item.range.emailOrdering : this.localization.mail;
    }
  }

  /**
   * Recipient for contractor mixed user
   */
  private emailRecipientForContractorMixed(): string {
    if (this.currentItemct && !this.isProjectBom) {
      return !!this.currentItemct.range && !isNullOrUndefined(this.currentItemct.range.emailOrdering) && this.currentItemct.range.emailOrdering !== '' ?
        this.currentItemct.range.emailOrdering : this.localization.mail;
    } else if (this.isProjectBom) {
      const item = this.projectItems.find(elem => !!elem.range && !isNullOrUndefined(elem.range.emailOrdering) && elem.range.emailOrdering !== '');
      return !isNullOrUndefined(item) ? item.range.emailOrdering : this.localization.mail;
    }
  }

  /**
   * Check if we have a specific restricted ordering type
   * @param items
   * @param restrictedOrderingType
   */
  private hasRestrictedOrdering(items: Item [], restrictedOrderingType: RestrictedOrderingType): boolean {
    const  restrictedOrderingTypes: RestrictedOrderingType [] = [];
    items.forEach(item => {
      if (!!item.range && restrictedOrderingType === item.range.restrictedOrderingType) {
        restrictedOrderingTypes.push(restrictedOrderingType);
      } else {
        item.components.forEach(component => {
          if (component.functionalUnits && component.functionalUnits.length > 0) {
            component.functionalUnits.forEach(functionalUnit => {
              if (functionalUnit.transformerCompatible) {
                if (restrictedOrderingType === functionalUnit.restrictedOrderingType) {
                  restrictedOrderingTypes.push(restrictedOrderingType);
                }
              }
            });
          }

          // for package offer ranges
          if (!!item.selectedPackage && !!component.rangeId && !!component.rangeId[0]) {
            const range = this.ranges.find(r => r.id === component.rangeId[0]);
            if (range != null && restrictedOrderingType === range.restrictedOrderingType) {
              restrictedOrderingTypes.push(restrictedOrderingType);
            }
          }
        });
      }
    });
    return restrictedOrderingTypes.length > 0;
  }

  /**
   * Get all references to order
   * @param restrictedOrderingType: RestrictedOrderingType
   */
  private referencesOrder(restrictedOrderingType: RestrictedOrderingType) {
    const isItemBom = !this.isProjectBom && this.currentItemct && this.quoteIsValid;
    const isProjectBom = this.isProjectBom && this.currentProject;
    this.referencesToOrder = [];
    if (isItemBom) {
      this.bomHelper.getOrderingReferenceList(this.currentItemct, restrictedOrderingType, this.referencesToOrder);
    } else if (isProjectBom) {
        this.projectItems.forEach(item => {
          this.bomHelper.getOrderingReferenceList(item, restrictedOrderingType, this.referencesToOrder);
        });
    }
    if (this.referencesToOrder.length > 0) {
      this.orderType = restrictedOrderingType;
      this.referenceListModal.show();
    }
    let total_price;
    if (isItemBom) {
      total_price = UtilService.getFormattedTotalDiscountPriceFromBOM(this.currentItemct.bom,
        this.user.currentMySEAccount === null && this.currentItemct.totalPrice.validFoNetPrice,
        this.currentItemct.discount);
    } else if (isProjectBom) {
      total_price = UtilService.getFormatedTotalDiscountPriceFromProjectBOM(this.currentProject);
    }
    this.logger.business('Order configuration',
      {
        total_price: total_price,
        currency: this.localization.currency,
        bom_references: this.bomString
      });
  }

  /**
   * Navigate on Project BOM
   */
  goToProjectBom(): void {
    this.logger.info('BOMActionsComponent goToProjectBom()');

    this.noItemNavigationStep = NavigationStep.PROJECT_BILL_OF_MATERIALS;
    this.currentItemct = null;
    this.router.navigate([NavigationLinkEnum.PROJECT_BILL_OF_MATERIALS]);
  }

}
