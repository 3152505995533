import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {SessionStorage, SessionStorageService} from 'ngx-webstorage';
import {Component, Input, ViewChild, EventEmitter, Output, OnChanges, SimpleChanges} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Project, ProjectFile} from '../../project/shared/project-model';
import {User} from '../../shared/user/user';
import {UtilService} from '../../shared/util/util.service';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {FilesService} from '../shared/files.service';
import {LoggerService} from '../../shared/logging/logger.service';
import {FileWrapper} from '../shared/FileWrapper';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-project-files',
  templateUrl: './project-files.component.html',
  styleUrls: ['./project-files.component.less'],
})
export class ProjectFilesComponent implements OnChanges {

  @Input()
  inputFiles: ProjectFile[] = [];

  @Input()
  readOnly: boolean;

  @SessionStorage()
  selectedProject: Project;

  @Output()
  addingFileEvent = new EventEmitter<any>();

  @Output()
  updateFileEvent = new EventEmitter<FileWrapper>();

  @ViewChild('childModal') childModal: CommonModalComponent;

  @SessionStorage()
  user: User;

  selectedFile: ProjectFile;

  // files to display
  files: ProjectFile[] = [];

  constructor(private translateService: TranslateService,
              private sessionStorageService: SessionStorageService,
              private domSanitizer: DomSanitizer,
              private utilService: UtilService,
              private filesService: FilesService,
              private messageService: MessageService,
              private logger: LoggerService) {
  }

  /**
   * Method called on change input (files)
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.files = this.inputFiles.slice();
    this.files.forEach(
      file => {
        if (file.fileSize < 1000000) {
          file.loading = true;
          // We download the file for preview just if its size is less than 1Mo
          this.filesService.downloadProjectFile(this.selectedProject.id, file.fileId).subscribe(
            response => {
              file.blob = response.body;
              this.createImageFromBlob(file);
              file.loading = false;
            },
            error => file.loading = false
          );
        }
      }
    );
  }

  /**
   * Method to create image from blob for image blob and put it on img file propertie
   * It also generate a text for text blob and put it on imgTxt file propertie
   * @param file the file to update
   */
  createImageFromBlob(file: ProjectFile) {
    const reader = new FileReader();
    let textType = false;
    let imgType = false;
    reader.addEventListener('load', () => {
      if (textType) {
        file.imgTxt = reader.result;
      }
      if (imgType) {
        file.img = reader.result;
      }
    }, false);
    if (file.blob) {
      if (!isNullOrUndefined(file.blob.type) && file.blob.type.indexOf('image') !== -1
        && file.mimeType.indexOf('image') !== -1) {
        reader.readAsDataURL(file.blob);
        imgType = true;
      } else {
        if (!isNullOrUndefined(file.blob.type) && file.blob.type.indexOf('text') !== -1
          && file.mimeType.indexOf('text') !== -1) {
          textType = true;
          reader.readAsText(file.blob);
        }
      }
    }
  }

  /**
   * Gets icon css class for file's mimeType.
   *
   * @param file
   */
  getIconClass(file: ProjectFile) {
    switch (file.mimeType) {
      case 'application/pdf':
        return 'no-preview se-icons se-icon-action_pdf_report';
      default:
        return 'no-preview se-icons se-icon-action_datasheet';
    }
  }

  /**
   * Gets creation date of a project file
   *
   * @param file
   */
  getCreationDetails(file: ProjectFile): string {
    let result = '';
    if (file.authorId) {
      result += this.translateService.instant('T_UPLOADED_BY');
      result += ' ';
      result += this.utilService.getAuthorName(file, this.user);
      result += ' ';
    }
    if (file.dateCreated) {
      result += this.translateService.instant('T_ON_SHARED');
      result += ' ';
      result += this.utilService.getLocalizedDate(file.dateCreated, this.user.preferredLanguage);
    }
    return result;
  }

  /**
   * Method to show delete modal
   * @param file the file to delete
   */
  showModal(file) {
    this.selectedFile = file;
    this.childModal.description = this.translateService.instant('T_DELETE_FILE_WARNING_MESSAGE');
    this.childModal.show();
  }

  /**
   * Function called to update a file
   * @param file the file to update
   */
  updateFile(event, file: ProjectFile) {
    const fileWrapper = new FileWrapper(event, file.fileId);
    this.updateFileEvent.emit(fileWrapper);
  }

  /**
   * Function called to delete a file.
   */
  confirmDelete() {
    this.filesService.deleteProjectFile(this.selectedProject.id, this.selectedFile.fileId).subscribe(
      response => {
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_DELETE_FILE_SUCCESS_MESSAGE'),
        });
        this.files = this.files.filter(file => file.fileId !== this.selectedFile.fileId);
        this.selectedFile = null;
      }
      , error => {
        this.logger.error(error);
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_DELETE_FILE_ERROR_MESSAGE'),
        });
      }
    );
  }

  /**
   * Function called to add a file. It emits an event
   * @param event
   */
  addFile(event) {
    this.addingFileEvent.emit(event);
  }

  /**
   * Function called to donwload a document
   * @param file
   */
  downloadDocument(file: ProjectFile) {
    file.loading = true;
    if (file.blob) {
      UtilService.downloadDocument(file.blob, file.name);
      file.loading = false;
    } else {
      this.filesService.downloadProjectFile(this.selectedProject.id, file.fileId).subscribe(
        response => {
          file.blob = response.body;
          UtilService.downloadDocument(file.blob, file.name);
          file.loading = false;
        }
        , error => {
          this.logger.error(error);
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant('T_DOWNLOAD_FILE_ERROR_MESSAGE'),
          });
          file.loading = false;
        },
      );
    }
  }
}
