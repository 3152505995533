<div class="scrollable cards-list">
  <div *ngFor="let component of visibleComponents"  class="card-cell">
    <app-card  [component]="component"
               [addingTransformerInfos]="addingTransformerInfos"
               (selectedCard)="selectedCard($event)"
               (addComponentEvent)="addComponentActionsWithDialog($event)"></app-card>
  </div>
</div>

<app-card-component-modal  #componentInfosModal ></app-card-component-modal>

<app-common-modal #changeDataModel [name]="'changeDataModel'" [title]="'T_LOOSE_DATA_TITLE' | translate"
                         [twoButtonsDialogs]="true" [description]="'T_QUOTATION_WARNING' | translate"
                         (onClickYesButton)="addComponentDispacher()" (onClickNoButton)="changeDataModel.hide()"
                         [size]="'modal-sm'">
</app-common-modal>
<app-common-modal #maxFuModal [name]="'maxFuModal'" [title]="'T_MODAL_MAX_FU_TITLE' | translate"
                         [twoButtonsDialogs]="false" [description]="'T_MODAL_MAX_FU_BODY' | translate"
                         [size]="'modal-sm'">
</app-common-modal>
<app-common-modal #transformerAddedModal [name]="'transformerAddeddModal'" [title]="'T_LOOSE_DATA_TITLE' | translate"
                         [twoButtonsDialogs]="true" [description]="'T_MODAL_TRANSFORMER_ADDED' | translate"
                         (onClickYesButton)="addTransformer()" (onClickNoButton)="transformerAddedModal.hide()"
                         [size]="'modal-sm'">
</app-common-modal>

<app-card-option-modal #addOptionModal (onClickOKButton)="updateCurrentItem(null, null)" [componentToAdd]=addedComponent></app-card-option-modal>

<app-common-modal #transfoFuseIncompatibilityModal [name]="'transfoFuseIncompatibilityModal'" [title]="'T_TRANSFO_FUSE_INCOMPATIBLE_MODAL_TITLE' | translate"
                  [twoButtonsDialogs]="true" [description]="'T_TRANSFO_FUSE_INCOMPATIBLE_MODAL_DESCRIPTION' | translate" [size]="'modal-sm'"
                  (onClickYesButton)="addTransfoAndupdateCurrentItem()"></app-common-modal>
