import {AfterViewInit, Component, ViewChild} from '@angular/core';
/* ngx import*/
import {SessionStorage} from 'ngx-webstorage';
/* app import*/
import {RatingFeedback} from '../shared/model/ratingFeedback';
import {FeedbackRecord} from '../shared/model/feedbackRecord';
import {User} from '../../shared/user/user';
import {TranslateService} from '@ngx-translate/core';
import {FeedbackService} from '../shared/service/feedback.service';
import {MessageService} from 'primeng/api';
import {ModalDirective} from 'ngx-bootstrap';
import {Router} from '@angular/router';
import {Item, Project} from '../../project/shared/project-model';


@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.less'],
  exportAs: 'child'
})
export class FeedbackModalComponent implements AfterViewInit {

  @SessionStorage()
  user: User;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  currentItemct: Item;

  @ViewChild('childModal') public childModal: ModalDirective;
  userFeedbackModel: FeedbackRecord;
  sendingFeedback = false;

  constructor(private translateService: TranslateService,
              private feedbackService: FeedbackService,
              private messageService: MessageService,
              private router: Router) {
    this.userFeedbackModel = this.initFeedbackModel();
  }

  ngAfterViewInit() {
    const modalOptions = this.childModal.config;
    modalOptions.backdrop = 'static';
    this.childModal.config = modalOptions;
  }

  /**
   * submit the user feedback to the backend
   */
  public submit() {
    this.sendingFeedback = true;
    this.feedbackService.storeFeedback(this.userFeedbackModel).subscribe(
      () => {
        this.sendingFeedback = false;
        this.userFeedbackModel = this.initFeedbackModel();
        this.childModal.hide();
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_APPLICATION_THANKS'),
          detail: this.translateService.instant('T_FEEDBACK_SUCCESS'),
        });
      },
      error => {
        this.sendingFeedback = false;
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_FEEDBACK_ERROR'),
        });
      });
  }

  public show() {
    this.userFeedbackModel = this.initFeedbackModel();
    this.childModal.show();
  }

  /**
   * close the modal and init feedback date
   */
  public cancel() {
    this.feedbackService.setLastUserFeedBack().subscribe();
    this.childModal.hide();
  }

  /**
   * When user click on radio button to change his answer about accepted to be contacted
   */
  public onChangeAcceptQuestion() {
    this.userFeedbackModel.acceptContacted = !this.userFeedbackModel.acceptContacted;
  }

  /**
   * Initializes all ratings and the comment
   * @returns {FeedbackRecord}
   */
  private initFeedbackModel(): FeedbackRecord {
    return new FeedbackRecord(new RatingFeedback(this.translateService.instant('T_FEEDBACK_QUESTION_ONE'), null),
      '', this.router.url, false);
  }
}
