import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Pair} from '../../../../shared/util/pair';
import {TranslationService} from '../../../shared';
import {SessionStorage} from 'ngx-webstorage';
import {User} from '../../../../shared/user/user';

@Component({
  selector: 'app-odm-modal-category-name',
  templateUrl: './odm-modal-category-name.component.html',
  styleUrls: ['./odm-modal-category-name.component.scss']
})
export class OdmModalCategoryNameComponent implements OnInit {


  @ViewChild('childModalValidate') changeNameModal: OdmModalCategoryNameComponent;

  @SessionStorage()
  public user: User;

  @Input()
  categoryNameKey: string;

  @Input()
  isAddedCategory = false;

  @Output() onConfirmButton = new EventEmitter();


  translationsByLanguages: Pair<string, string>[];
  isLoading = false;

  constructor(private translationService: TranslationService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.translationsByLanguages = [];
    if (!this.isAddedCategory){
      this.translationService.getLocalesOfCountryOfKey(this.categoryNameKey).subscribe( localeKeys => {
        localeKeys.forEach(value => {
          let pair;
          if (value.translations.length === 0) {
            pair = new Pair<string, string>(value.language, '');
          } else {
            pair = new Pair<string, string>(value.language, value.translations[0].translation);
          }
          this.translationsByLanguages.push(pair);
        });
        this.isLoading = false;
      },
        ()=> this.isLoading = false);
    } else {
      this.translationService.getAllLanguagesByCountry(this.user.partnerCountry)
        .subscribe(langs =>
        langs.forEach(lang => this.translationsByLanguages.push(new Pair<string, string>(lang, ""))),
          ()=> this.isLoading = false);
        this.isLoading = false;
    }
  }

  show() {
    this.changeNameModal.show();
  }

  tranlationChange(language: string, event: any) {
    let newTranslationsByLanguages: Pair<string, string>[] = [];
    this.translationsByLanguages.forEach(value => {
      if (value.getKey() === language) {
        newTranslationsByLanguages.push(new Pair<string, string>(language, event.target.value));
      } else {
        newTranslationsByLanguages.push(value);
      }
    });
    this.translationsByLanguages = newTranslationsByLanguages;
  }

  sendConfirmation() {
    this.onConfirmButton.emit(this.translationsByLanguages);
  }

  hide() {
    this.changeNameModal.hide();
  }


}
