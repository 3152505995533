import { Component, OnInit } from '@angular/core';
import {PriceManagementService, ReferencePrice, UploadService} from '../../shared';
import {TranslateService} from '@ngx-translate/core';
import {MessageService, SelectItem} from 'primeng/api';
import {LoggerService} from '../../../shared/logging/logger.service';
import {SessionStorage} from 'ngx-webstorage';
import {Localization} from '../../../shared/model/localization.model';

@Component({
  selector: 'app-public-price-upload',
  templateUrl: './public-price-upload.component.html',
  styleUrls: ['./public-price-upload.component.less']
})
export class PublicPriceUploadComponent implements OnInit {

  @SessionStorage()
  localization: Localization;

  referencePriceDatas: ReferencePrice[];
  cols: any[];
  rangeSelectItems: SelectItem[] = [];
  IMPORT_OK_RESPONSE = 'IMPORT-OK';
  deliveryTimeInfo: any;
  loadingFile = false;


  constructor(private translateService: TranslateService,
              private uploadService: UploadService,
              private messageService: MessageService,
              private logger: LoggerService,
              private priceManagementService: PriceManagementService) { }

  ngOnInit(): void {
    this.priceManagementService.getReferencePrices()
      .subscribe(referencePrices => {
        let additionalTimeRef = referencePrices.find(referencePrice => referencePrice.additionalTime !== null);
        // get additional time
        if (additionalTimeRef) {
          this.deliveryTimeInfo = this.translateService.instant('T_PRICE_DELIVERY_TIME_INFO',
            {additionalDelay: additionalTimeRef.additionalTime});
        }
      });

    this.cols = [
      { field: 'range', header: (this.translateService.instant('T_PRICE_RANGE')) },
      { field: 'ref', header: (this.translateService.instant('T_PRICE_REFERENCE'))  },
      { field: 'description', header: (this.translateService.instant('T_PRICE_DESCRIPTION'))},
      { field: 'price',
        header: (this.translateService.instant('T_PRICE_PUBLIC_PRICE', {currency: this.localization.displayedCurrency}))},
      { field: 'availability', header: (this.translateService.instant('T_PRICE_AVAILABILITY'))  },
      { field: 'deliveryTime', header: (this.translateService.instant('T_PRICE_DELIVERY_TIME'))  },
    ];

    this.getReferencePriceData();

  }


  fileUploader(event) {
    this.logger.info('upload price file');
    const files = event.target.files;
    if (files.length > 0) {
      if (files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_PRICE_IMPORT_FILE_TYPE_TILE'),
          detail: this.translateService.instant('T_PRICE_IMPORT_FILE_TYPE_MESSAGE'),
        });
        return;
      }
      const formData: FormData = new FormData();
      formData.append('file', files[0]);
      const uploadUrl = '/price/upload';
      this.loadingFile = true;
      this.uploadService.uploadFile(uploadUrl, formData).subscribe(importResponse => {
        this.loadingFile = false;
        if (importResponse.includes(this.IMPORT_OK_RESPONSE)) {
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('T_PRICE_IMPORT_SUCCESS_TITLE'),
            detail: this.translateService.instant('T_PRICE_IMPORT_SUCCESS_MESSAGE'),
          });
          this.messageService.add({
            severity: 'info',
            detail: this.translateService.instant('T_OFFER_DATA_MANAGEMENT_PRICE_IMPORT_UPDATE_ITEMS'),
          });
          // reload reference price datas
          this.getReferencePriceData();
        } else {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_PRICE_IMPORT_ERROR_TITLE'),
            detail: this.translateService.instant('T_PRICE_IMPORT_ERROR_MESSAGE'),
          });
          this.logger.info('download price file report');
          const downloadUrl = '/price/report';
          importResponse = importResponse.trim();
          importResponse = importResponse.substring(1,importResponse.length-1);
          this.uploadService.downloadReport(downloadUrl, importResponse).subscribe(
            response => {
              this.uploadService.createXlsFileFromBackendResponse(response);
            }
          );
        }
      },(error) => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_PRICE_IMPORT_ERROR_TITLE'),
          detail: this.translateService.instant('T_PRICE_IMPORT_ERROR_MESSAGE'),
        });
        this.loadingFile = false;
      });
    }
    // clean files in event
    event.target.value = null;
  }

  fileExport(data) {
    this.logger.info('data:' + data);
    let referencePrices: ReferencePrice[] = [];
    if (data !== undefined && data !== null && data.length < this.referencePriceDatas.length) {
      for (let i = 0; i < data.length; i++) {
        referencePrices.push(data[i]);
      }
    } else {
      referencePrices = this.referencePriceDatas;
    }
    this.loadingFile = true;
    this.uploadService.exportFile(referencePrices).subscribe(
      response => {
        this.loadingFile = false;
        this.uploadService.createXlsFileFromBackendResponse(response);
      }, (error) => {
        this.loadingFile = false;
      }
    );
  }


  private getReferencePriceData() {
    this.priceManagementService.getReferencePrices()
      .subscribe(referencePrices => {
        this.referencePriceDatas = referencePrices;
        // translate the range and description key
        this.referencePriceDatas.forEach(refPriceData => {
          refPriceData.range = this.translateService.instant(refPriceData.range);
          refPriceData.description = this.translateService.instant(refPriceData.description);
        });
        // get the range list
        this.computeRangeLabels();

      });
  }

  private computeRangeLabels() {
    this.referencePriceDatas.forEach(refPriceData => {
      const index = this.rangeSelectItems.findIndex(range => range.value === refPriceData.range);
      if (index === -1) {
        this.rangeSelectItems.push({label: refPriceData.range, value: refPriceData.range});
      }
    });
  }

}
