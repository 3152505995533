<div class="configure">
  <div class="header">
    <app-configuration-header class="header"
                              (onTabEvent)="onTabEvent($event)">

    </app-configuration-header>
  </div>

  <div *ngIf="onSldTab" class="tab-sld" (click)="unselectComponent($event)">
    <app-sld class="sld" [displayedComponents]="currentItemct.components" [selectedComponent]="selectedComponent" (componentTypeEvent)=componentTypeEvent($event)
             (transformerAddingEvent)=transformerAddingEvent($event)></app-sld>
    <app-sld-options-component [selectedComponent]="selectedComponent"
                               [ngClass]="{'panel': true, 'right-panel': true, 'hidden' : !openOptionsPanel}"
                               (deleteComponent)="deleteComponent($event)"
                               (moveCubicleEvent)="moveComponent($event)">
    </app-sld-options-component>
  </div>

  <div *ngIf="!onSldTab" class="tab-layout">
    <app-layout [displayedComponents]="currentItemct.components" [rangeName]="currentItemct.range.nameKey"></app-layout>
  </div>
  <div>
    <div id="selection"  class="selection" [ngClass]="{'selectionClosed': !panelOpened}">
      <app-selection [componentType]="componentType" [transformerInfos]="addingTransformerInfos"></app-selection>
      <a id="btn-selection-slide"  (click)="slideSelection()" translate>{{buttonLabel}}</a>
    </div>
  </div>
</div>

<!-- confirm delete dialog -->
<app-common-modal #deleteModal [name]="'deleteComponent'" [title]="'T_LOOSE_DATA_TITLE' | translate"
                  [description]="warningDeleteMessage" (onClickYesButton)="deleteComponentDispatcher()" (onClickNoButton)="deleteModal.hide()"
                  [size]="'modal-sm'">
</app-common-modal>
