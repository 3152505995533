<div class="data-management-container">

  <app-navigation-side-panel class="nav-side-panel" [items]="dataManagementMenuItems"></app-navigation-side-panel>
  <se-divider option="vertical"></se-divider>

  <div class="data-management-content">
    <div *ngIf="showTab(1)">
      <app-offer-data-management></app-offer-data-management>
    </div>

    <div *ngIf="showTab(2) && !managePackageGroup()">
      <app-package-management></app-package-management>
    </div>

    <div *ngIf="showTab(2) && managePackageGroup()">
      <app-package-management-by-group></app-package-management-by-group>
    </div>


    <div *ngIf="showTab(3)">
      <app-up-selling-transformer></app-up-selling-transformer>
    </div>

    <div *ngIf="showTab(4)">
      <app-services></app-services>
    </div>

    <div *ngIf="showTab(5)">
      <app-documentation-management></app-documentation-management>
    </div>
  </div>
</div>
