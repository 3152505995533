<h3 translate>T_ADMIN_PUBLIC_PRICE_MANAGEMENT_TITLE</h3>

<app-spinner *ngIf="loadingFile" [sizeClass]="'large'"></app-spinner>

<div *ngIf="!loadingFile">
  <!-- PRICE TABLE -->
  <div>
    <p-table #tt styleClass="express-datatable-style" [columns]="cols" [value]="referencePriceDatas" [rows]="10" [paginator]="true"
             [globalFilterFields]="[cols[0].field, cols[1].field, cols[2].field]" [rowHover]="true">
      <ng-template pTemplate="caption">
        <div class="price-table-header">
          <!-- FILTER INFO MESSAGE -->
          <div>
            <div class="price-export-info">
              <em class="fa fa-info-circle">&nbsp;</em>
              <label translate>T_PRICE_EXPORT_INFO</label>
            </div>
            <div class="price-export-info" *ngIf="deliveryTimeInfo">
              <em class="fa fa-info-circle">&nbsp;</em>
              <label translate>{{deliveryTimeInfo}}</label>
            </div>
          </div>
          <div>
            <em class="fa fa-search" style="margin:4px 4px 0 0"></em>
            <input type="text" pInputText size="50" placeholder="Global Filter" (input)="tt.filterGlobal($event.target.value, 'contains')" style="width:auto">
          </div>
          <div>
            <button type="button" class="btn btn-default-gray-background" (click)="fileInput.click()" translate>
              <em class="pi pi-download">&nbsp;</em>
              T_PRICE_IMPORT
              <input #fileInput type="file" (change)="fileUploader($event)" accept=".xlsx, .xls" style="display:none" />
            </button>
            <button type="button" class="btn btn-default-gray-background" (click)="fileExport(tt.filteredValue)" translate>
              <em class="pi pi-upload">&nbsp;</em>
              T_PRICE_EXPORT
            </button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="6" translate>T_PRICE_NO_REFERENCES_FOUND</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of cols"  [pSortableColumn]="col.field" scope="col">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        <tr>
          <th *ngFor="let col of cols" [ngSwitch]="col.field" scope="col">
            <input pInputText placeholder="Search" type="text" (input)="tt.filter($event.target.value, col.field, 'contains')">
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-referencePriceData>
        <tr>
          <td>{{referencePriceData.range}}</td>
          <td>{{referencePriceData.ref}}</td>
          <td>{{referencePriceData.description}}</td>
          <td>{{referencePriceData.price}}</td>
          <td>{{referencePriceData.availability}}</td>
          <td>{{referencePriceData.deliveryTime}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
