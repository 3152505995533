import {MySEStatusEnum} from '../../shared/model/mySEStatusEnum';
import {Status} from '../../project/shared/project-model';
import {BusinessStatus} from '../../configuration/shared/model/reference';

export class Bom {
  packagingPrice: number;
  totalPrice: number;
  totalNetPrice: number;
}

export class ReferenceBom {
  id: string;
  configuration?: string;
  category?: string;
  status?: Status;
  quantityId: string;
  ref: string;
  description: string;
  price: number;
  netPrice: number;
  partnerDiscount?: string;
  discount: number;
  discountedPrice: number;
  discountedNetPrice: number;
  discountUnitPrice: number;
  count: number;
  value?: string;
  totalPrice: number;
  totalNetPrice: number;
  availableOnStock: string;
  deliveryTime: number;
  documents?: Array<string>;
  mySeNetPriceStatus?: MySEStatusEnum;
  editableQuantity = true;
  businessStatus: BusinessStatus;
}

export class ReferenceBomForMySE {
  ref: string;
  count: number;
}

export class ReferenceBomForDigiQ {
  ref: string;
  count: number;
  discount: number;
}

/**
 *  Represents a row on Excel for classic BOM or project BOM (not MySE or DigiQ BOM)
 */
export class BOMFormatedForExcel {
  configuration?: string;
  status?: string;
  category?: string;
  ref: string;
  description: string;
  value?: string;
  discountUnitPrice: number;
  unitPrice: number;
  discountedPrice: number;
  discountedNetPrice: number;
  count: number;
  availableOnStock: string;
  deliveryTime: number;
  totalPrice: number;
  netPrice?: number;
  totalNetPrice?: number;
  partnerDiscount?: string;
}
