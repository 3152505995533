import {
  AfterViewInit, ViewChild, Component, Input, Output, EventEmitter, ChangeDetectorRef
} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap';
import {LoggerService} from '../../shared/logging/logger.service';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {ShareOptions} from '../shared/share-option-model';
import {UtilService} from '../../shared/util/util.service';
import {FilesService} from '../../project-sharing/shared/files.service';
import {UserService} from '../../shared/user/user.service';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';

class FileSelection {
  selected: boolean;
  name: string;

  constructor(name: string) {
    this.selected = false;
    this.name = name;
  }
}

@Component({
  selector: 'app-share-project-modal',
  templateUrl: './share-project-modal.component.html',
  styleUrls: ['./share-project-modal.component.less'],
})
export class ShareProjectModalComponent implements AfterViewInit {


  @ViewChild('childModal') public childModal: ModalDirective;
  @ViewChild('trancludedTitle') trancludedTitle;
  @Input() description: string;
  @Output() onClickShareButton = new EventEmitter<ShareOptions>();

  projectId: string;
  form: FormGroup;
  shareSpaceName: string;
  mailAddress: string;
  rightToEdit = false;
  allowSharedFiles = false;
  comment: string;
  loading = false;
  filesSelections: Array<FileSelection> = [];

  constructor(private logger: LoggerService,
              private filesService: FilesService,
              private userService: UserService,
              private translateService: TranslateService,
              private cdRef: ChangeDetectorRef,
              private messageService: MessageService,
              private formBuilder: FormBuilder) {
    this.filesSelections = [];

    this.form = this.formBuilder.group({
      shareSpaceName: [this.shareSpaceName, [Validators.required , Validators.pattern(UtilService.regExNameFiledExcludeCaracters)]],
      mailAddress: [this.mailAddress, [Validators.required, Validators.pattern(UtilService.emailRegEx)]],
      rightToEdit: [this.rightToEdit, []],
      allowSharedFiles: [this.allowSharedFiles, []],
      comment: [this.comment, []]
    });
  }

  ngAfterViewInit() {
    const modalOptions = this.childModal.config;
    modalOptions.backdrop = 'static';
    this.childModal.config = modalOptions;
    this.cdRef.detectChanges();
  }

  show(projectId: string) {
    this.shareSpaceName = '';
    this.mailAddress = '';
    this.rightToEdit = false;
    this.projectId = projectId;
    this.allowSharedFiles = false;
    this.updateFileList();
    this.childModal.show();
  }

  hide() {
    this.childModal.hide();
  }

  clickShareButton() {
    const shareOptions: ShareOptions = new ShareOptions;
    this.loading = true;
    shareOptions.id = this.projectId;
    shareOptions.email = this.mailAddress;
    shareOptions.rightToEdit = this.rightToEdit;
    shareOptions.allowSharedFiles = this.allowSharedFiles;
    shareOptions.name = this.shareSpaceName;
    shareOptions.comment = this.comment;
    shareOptions.files = [];

    this.userService.getUserExist(shareOptions.email).subscribe(
      exist => {
        this.loading = false;
        // if the email exist on Pace
        if (exist) {
          this.logger.business('Invite on SPIM', {user : shareOptions.email});
          this.filesSelections.forEach(fileSelection => {
            if (fileSelection.selected) {
              shareOptions.files.push(fileSelection.name);
            }
          });
          this.onClickShareButton.emit(shareOptions);
          this.hide();
        } else {
          // We stay on modal and toast inform user
          this.messageService.add({
            severity: 'warn',
            summary: this.translateService.instant('T_WARNING'),
            detail: this.translateService.instant('T_COLLABORATIVE_UNKNOWN_USER_ERROR_MESSAGE'),
          });
        }
      },
      error => {
        this.loading = false;
        this.logger.error(error);
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_COLLABORATIVE_SHARE_ERROR_MESSAGE'),
        });
        this.hide();
      }
    );

  }

  updateFileList(): void {
    if (this.filesSelections.length > 0) {
      this.filesSelections.forEach(file => this.form.removeControl(file.name));
    }

    this.filesSelections = [];

    this.filesService.getProjectFiles(this.projectId).subscribe(
      files => {
        files.forEach(file => {
          this.filesSelections.push(new FileSelection(file.name));
        });
      });

    for (const file of this.filesSelections) {
      const control: FormControl = new FormControl();
      this.form.addControl(file.name, control);
    }
  }

  /**
   * Method when user allow file sharing or disable file sharing
   */
  onChangeSharedFiles() {
    this.allowSharedFiles = !this.allowSharedFiles;
    if (!this.allowSharedFiles && this.filesSelections.length > 0) {
      // If user disallow file sharing, we remove files selected
      this.filesSelections.forEach(file => file.selected = false);
    }
  }

  /**
   * Method when user change a file sharing
   */
  onChangeFileSelection(file: FileSelection) {
    file.selected = !file.selected;
  }
}



