<!-- waiting for the bom to be calculated -->
<app-spinner *ngIf="loadingBom || loadingQuotationBom" [sizeClass]="'large'">
  <div *ngIf="loadingQuotationBom" class="loadingMessage" translate>T_BOM_QUOTATION_PROCESSING</div>
</app-spinner>

<div [hidden]="loadingBom || loadingQuotationBom" class="container-fluid section-margin">
  <!--Part of bom actions (quote, order, export etc..)-->
  <app-bom-actions *ngIf="!!ranges" [loadingBom]="loadingBom"
                   [isCpqProject]="isCpqProject()"
                   [isReadOnlyProject]="isReadOnlyProject()"
                   [isContractor]="isContractor()"
                   [isCpqUser]="isCpqUser()"
                   [status]="status"
                   [bomString]="bomString"
                   [isProjectBom]="false"
                   [ranges]="ranges"
                   (generateBomInfos)="generateBomInformation($event, true)">
  </app-bom-actions>
  <div class="selectable">
    <!-- Bom table -->
    <app-bom-table [cols]="cols"
                   [treeData]="treeData"
                   [loadingBom]="loadingBom"
                   [expandedNodes]="expandedNodes"
                   (generateBomInfos)="generateBomInformation($event)">
    </app-bom-table>
    <!--Demo version message-->
    <div *ngIf="!hasRegister && isDemoUser() && isDemoMode" class="div-demo-message">
      <h5 class="demo-message" translate>T_DEMO_VERSION_BOM_MESSAGE</h5>
    </div>

    <!--Demo version message end-->
    <!--Visibility period-->
    <div *ngIf="!isDemoUser() && !isDemoMode" class="row bom-section-margin-top">
      <div class="col-md-10">
        <app-validity-period [style.visibility]="currentItemct.status !== status.configured?'visible':'hidden'">
        </app-validity-period>
      </div>
    </div>
    <!--Part of bom Delivery lead time, Discount field, Total price-->
    <app-total-price *ngIf="utilService.displayPrice(currentProject) && !!ranges"
                     [loadingBom]="loadingBom"
                     [displayFoNetPrice]="currentItemct.totalPrice.validFoNetPrice"
                     [ranges]="ranges"
                     [containsNullPublicPrices]="bomContainsNullPublicPrices()"
                     [containsNullNetPrices]="bomContainsNullNetPrices()"
                     (generateBomInfos)="generateBomInformation($event, true)">
    </app-total-price>
  </div>
</div>

<!-- quotation dialog -->
<app-common-modal #quoteModal [name]="'quoteModal'" [title]="'T_INFORMATION_TITLE' | translate"
                  [acceptInnerHtml]="true" (onClickOkButton)="quoteCurrentItem()"
                  [twoButtonsDialogs]="false" [closable]="false"
                  [description]="'T_BOM_QUOTE_QUESTION' | translate: {nbDays: validityPeriod} ">
</app-common-modal>
<!-- Modal when quotation is expired -->
<app-common-modal #updateQuoteModal [name]="'updateQuoteModal'" [title]="'T_CONFIRM_TITLE' | translate"
                  (onClickYesButton)="updateBom(true)"
                  (onClickNoButton)="updateQuoteModal.hide()" [closable]="false"
                  [description]="'T_BOM_UPDATE_QUOTATION_QUESTION' | translate">
</app-common-modal>
