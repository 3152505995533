export class Contact {
  email: string;
  phone: string;
  fax: string;
}

export enum ContactTypeEnum {
  SUPPORT,
  SALES
}

export enum ContactFieldEnum {
  EMAIL,
  PHONE,
  FAX
}
