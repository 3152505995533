import {Resource, Role} from '../enum';
import {Permission} from '../permission';

export const customers: Array<Permission> = [
  {
    role: Role.RESELLER,
    inherits: [Role.CONTRACTOR]
  },
  {
    role: Role.CONTRACTOR,
    can: [
      Resource.PURCHASE_BY_SCHNEIDER
    ]
  },
  {
    role: Role.CONTRACTOR_INDIRECT,
  },
  {
    role: Role.CONTRACTOR_MIXED,
  },
  {
    role: Role.CPQ_USER,
  },
  {
    role: Role.DEMO_VERSION,
    can: [
      Resource.PURCHASE_BY_SCHNEIDER
    ]
  }
];
