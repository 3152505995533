import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {catchError} from 'rxjs/operators';
import {LoggerService} from '../../shared/logging/logger.service';
import {NavigationLinkEnum} from '../../shared/guards/navigationLink-enum';
import {AuthService} from "../../shared/auth/auth.service";
import {Router} from "@angular/router";
import {of, throwError} from "rxjs";
import {environment} from "../../../environments/environment";


/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(private logger: LoggerService,
              private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  // Customize the default error handler here
  private errorHandler(httpErrorResponse: HttpErrorResponse): Observable<HttpEvent<any>> {

    if (httpErrorResponse?.status != 401) {
      const error = httpErrorResponse.error;
      if (error.code === '1011') {
        // session expired
        return;
      }
      if (error.message) {
        this.logger.error(error.message);
        return Observable.throwError(error.message || 'backend server error');
      } else {
        return Observable.throwError(error.toString() || 'backend server error');
      }
    } else if (httpErrorResponse?.status == 401 && !environment.authenticationSkipped) {
      this.authService.login();
      return of();
    }
    return throwError(() => httpErrorResponse);
  }

}
