<span *ngIf="description" class="explain-text">{{description}}</span>
<form (submit)="clickValidateButton()">
  <div class="row field">
    <div class="col-xs-4">
      <label class="field-label required-red-asterisk" translate>T_END_USER_PROJECT</label>
    </div>
    <div class="col-xs-8">
      <input [id]="'projectName'" maxlength="25" name="projectName" class="underline-input"
             [ngModel]="documentInformations?.projectName"
             (ngModelChange)="documentInformations.projectName = $event"
             type="text" required/>
    </div>
  </div>
  <div class="row field">
    <div class="col-xs-4">
      <label class="field-label required-red-asterisk" translate>T_END_USER</label>
    </div>
    <div class="col-xs-8">
      <input maxlength="25" name="endUserName" class="underline-input"
             [ngModel]="documentInformations?.endUserName"
             (ngModelChange)="documentInformations.endUserName = $event"
             type="text" required/>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-6">
      <button type="submit"
              [disabled]= "!isValidForm()"
              class="btn btn-default-white-background" translate>T_VALIDATE</button>
    </div>
    <div class="col-xs-6">
      <button *ngIf="skippable" type="button" class="btn btn-default-white-background" (click)="clickSkipButton()" translate>T_SKIP</button>
    </div>
  </div>

</form>
