/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
/* app modules */
import {LoggerService} from '../logging/logger.service';
import {AccessoryCategory, AccessoryCharacteristics} from '../model/accessory.model';
import {Characteristic} from '../../characteristics/shared/characteristics.model';
import {Pair} from '../util/pair';

@Injectable()
export class AccessoryCategoriesService {

  constructor(private httpClient: HttpClient,
              private logger: LoggerService) {
  }

  getAccessoryCategory(rangeId, forceUpdate: boolean = false): Observable<[AccessoryCategory]> {
    this.logger.debug('AccessoryCategoriesService getAccessoryCategory()');
    return this.httpClient.cache(forceUpdate).get<[AccessoryCategory]>('/category/' + rangeId );
  }

  addAccessoryCategory(rangeId, translationByLanguage:  Pair<string, string>[]): Observable<AccessoryCategory> {
    this.logger.debug('AccessoryCategoriesService addAccessoryCategory()');
    const payload = [];
    translationByLanguage.forEach(t => payload.push({first: t.getKey(), second:t.getValue()}));
    return this.httpClient.post<AccessoryCategory>('/category/' + rangeId, payload);
  }

  deleteAccessoryCategory(categoryId): Observable<any> {
    this.logger.debug('AccessoryCategoriesService deleteAccessoryCategory()');
    return this.httpClient.post<[AccessoryCategory]>('/category/delete/' + categoryId, {});
  }


}
