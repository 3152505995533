/* Angular modules */
import {Injectable} from '@angular/core';
/* app modules */
import {SwitchBoardComponent} from '../model/component';

@Injectable()
export class FuseService {

  constructor() {
  }

  /**
   * Gets the FUSE option from cubicle.
   *
   * @param cubicle
   */
  static getFuseOptionFromCubicle(cubicle: SwitchBoardComponent) {
    return cubicle.options.find(option => option.name === 'T_TRANSFORMER_ID_FUSES_SELECT');
  }

  /**
   * Gets selected FUSE.
   *
   * @param cubicle
   */
  static getSelectedFuseOptionFromCubicle(cubicle: SwitchBoardComponent) {
    const fuseOption = FuseService.getFuseOptionFromCubicle(cubicle);
    if (fuseOption) {
      return fuseOption.values.find(fuse => fuse.selected === true);
    }
    return null;
  }
}
