<div *ngIf="isToDisplay">
  <div *ngIf="quoteIsValid;then validQuotation else invalidQuotation" [ngClass]="">
  </div>
</div>
<ng-template #validQuotation>
      <span>
        {{'T_BOM_QUOTATION_DATE' | translate}} : <strong>{{localeDate}}</strong>, {{'T_BOM_QUOTATION_VALID' | translate}} <strong>{{validityDate}}</strong>
      </span>
</ng-template>
<ng-template #invalidQuotation>
  <div>
    <span class="text-warning" translate [translateParams]="{date: validityDate}">T_BOM_QUOTATION_INVALID</span>
  </div>
</ng-template>
