<div [hidden]="!notFOPage()" class="button-feedback" (click)="showFeedBackModal()" #feedBackButton>

  <div class="icon-comment-stars feedback-button feedback-button-3-stars">
    <span class="span-icon se-icons">rating_3stars</span>
  </div>

  <div class="icon-comment-improve feedback-button feedback-button-1-star">
    <div class="text-feedback">
      <span class="text-improve" [innerHTML]="'T_FEEDBACK_IMPROVE' | translate"></span>
      <span class="text-ecoreal">Power <span class="text-ecoreal-light">Build</span></span>
    </div>
  </div>
</div>
<app-feedback-modal #feedBackModal></app-feedback-modal>
