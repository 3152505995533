<div *ngIf="isGettingAccessToken; else errorConnection" class="waiting" >
  <app-spinner [sizeClass]="'large'"><span  class="waiting-message" >Waiting to end of connection</span></app-spinner>
</div>

<ng-template #errorConnection>
  <div class="waiting">
    <h2>Connection error. If the problem persists, please contact the administrator</h2>
    <button class="btn btn-warning" (click)="relogin()">Retry</button>
  </div>
</ng-template>
