/* Angular modules */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
/* ngx modules */
import {TranslateModule} from '@ngx-translate/core';
import {MultiselectDropdownModule} from 'angular-2-dropdown-multiselect';
/* app modules */
import {TransformerService} from './shared/transformer-service';
import {EcorealCtSharedModule} from '../shared/shared.module';
import {TransformerSelectorComponent} from './transformer-selector/transformer-selector.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MultiselectDropdownModule,
    EcorealCtSharedModule
  ],
  declarations: [TransformerSelectorComponent],
  providers: [TransformerService],
  exports: [TransformerSelectorComponent]
})
export class TransformerModule {
}
