/* Angular modules */
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
/* ngx modules */
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
/* app modules */
import { NavigationStep } from '../../shared/guards/navigationStep-enum';
import { Item, Project } from '../../project/shared/project-model';
import { CommonModalComponent } from '../../shared/common-modal/common-modal.component';
import { RangeType } from '../../shared/model/range-type';
/* 3rd parties libraries */
import * as moment from 'moment';
import { NavigationRoute } from '../../shared/guards/route-enum';
import { User } from '../../shared/user/user';
import {FeedbackService} from '../../feedback/shared/service/feedback.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.less', '../../../assets/less/navigation-bar-directive.less']
})
export class NavigationBarComponent implements OnInit {
  @ViewChild('childModal') childModal: CommonModalComponent;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  noItemNavigationStep;

  @SessionStorage()
  adminNavigationStep;

  @SessionStorage()
  user: User;

  navSteps;

  // to make the enum accessible to the html template
  RangeType: any = RangeType;
  NavigationStep: any = NavigationStep;

  constructor(private router: Router,
              private sessionStorageService: SessionStorageService,
              private feedbackService: FeedbackService) {
  }


  ngOnInit() {
    if (this.currentItemct == null && this.currentProject == null) {
      this.noItemNavigationStep = NavigationStep.WELCOME;
    }
    if (this.currentItemct === null && this.currentProject !== null && this.noItemNavigationStep === NavigationStep.PROJECT_BILL_OF_MATERIALS) {
      if (this.currentProject.readOnly) {
        this.navSteps = NavigationRoute.READ_ONLY_PROJECT_BOM_STEPS;
      } else {
        this.navSteps = NavigationRoute.PROJECT_BOM_STEPS;
      }
    }

    // Observers to update the nav bar with session changes

    this.sessionStorageService.observe('noItemNavigationStep').subscribe(() => this.updateNavBar());

    this.sessionStorageService.observe('currentItemct').subscribe(() => this.updateNavBar());
  }

  /**
   * Manage update of the navbar steps
   */
  updateNavBar() {
    if (!this.currentItemct && this.noItemNavigationStep === NavigationStep.OFFERS_CT) {
      this.navSteps = NavigationRoute.NO_ITEM_STEPS;
    } else if (!this.currentItemct &&
      (this.noItemNavigationStep === NavigationStep.PROJECT_BILL_OF_MATERIALS || this.noItemNavigationStep === NavigationStep.PROJECT_DOCUMENTATION)) {
      if (this.currentProject.readOnly) {
        this.navSteps = NavigationRoute.READ_ONLY_PROJECT_BOM_STEPS;
      } else {
        this.navSteps = NavigationRoute.PROJECT_BOM_STEPS;
      }
    } else if (this.currentItemct) {
      this.navSteps = NavigationRoute.getRouteByRange(this.currentItemct.range, this.currentProject.readOnly);
    }
  }

  changeTab(item): void {
    this.router.navigate(['/' + item.link]);
    this.showFeedback(item);
  }

  getLastUpdateDate() {
    moment.locale(this.user.preferredLanguage);
    if (this.currentItemct === null && this.currentProject !== null) {
      return moment(this.currentProject.updateDate).format('LLL');
    }
    return moment(this.currentItemct.updateDate).format('LLL');
  }

  /**
   * Show feedback modal if the last feedback is expired for cancelled configuration event
   * @param nextStep next page of the navigation
   */
  private showFeedback(nextStep) {
    if (this.isCancelledConfigurationEvent(nextStep)) {
      this.feedbackService.showEventFeedbackModal(0);
    }
  }

  /**
   * Check if it is the event of cancelled configuration
   * @param nextStep next page of the navigation
   */
  private isCancelledConfigurationEvent(nextStep): boolean {
    // show feedback only if has currentItemct and currentItemct is not finalized
    if (!this.currentItemct || this.currentItemct.maxNavigationStep > NavigationStep.BILL_OF_MATERIALS) {
      return false;
    }

    // show feedback only if next step is PROJECTS or OFFERS_CT
    if (nextStep.id !== NavigationStep.PROJECTS && nextStep.id !== NavigationStep.OFFERS_CT) {
      return false;
    }

    // show feedback only if current url is in list
    if (!NavigationRoute.PROJECT_CONFIGURATION_LINKS_LIST.includes(this.router.url)) {
      return false;
    }

    return true;
  }
}
