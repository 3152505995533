/* angular modules */
import {Component, OnInit, ViewChild} from '@angular/core';
/* ngx modules */
import {SessionStorage} from 'ngx-webstorage';
/* app modules */
import {ApplicationService} from './shared/app/application.service';
import {Item} from './project/shared/project-model';
import {NavigationStep} from './shared/guards/navigationStep-enum';
import {UserService} from './shared/user/user.service';
import {User} from './shared/user/user';
import {LoggerService} from './shared/logging/logger.service';
import {NavigationLinkEnum} from './shared/guards/navigationLink-enum';
import {TermsOfUseModalComponent} from './shared/terms-of-use-modal/terms-of-use-modal.component';
import {environment} from 'environments/environment';
import {CommonModalComponent} from './shared/common-modal/common-modal.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from "primeng/api";
import {NavigationBarItem} from './layout/navigation-bar-v2/navigation-bar-v2.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  providers: [MessageService]
})
export class AppComponent {


}
