import {Component, OnInit} from '@angular/core';
import {SessionStorage} from 'ngx-webstorage';
import {SelectItem, MessageService} from 'primeng/api';
import {MappingTransformerSwitchboard} from '../shared/up-selling/up.selling.transformer.model';
import {TranslateService} from '@ngx-translate/core';
import {LoggerService} from '../../shared/logging/logger.service';
import {UpSellingTransformerService} from '../shared/up-selling/up.selling.transformer.service';
import {UploadService} from '../shared';
import {OfferService} from '../../offers/shared/offer.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-up-selling-transformer',
  templateUrl: './up-selling-transformer.component.html',
  styleUrls: ['./up-selling-transformer.component.less'],
})
export class UpSellingTransformerComponent implements OnInit {

  @SessionStorage()
  isOnAdministrationScreen = true;

  @SessionStorage()
  localization;

  mappingTransformerSwitchboardList: MappingTransformerSwitchboard[];

  rangeSelectItems: SelectItem[] = [];

  IMPORT_OK_RESPONSE = 'IMPORT-OK';

  cols: any[];

  importInProgress = false;

  constructor(private logger: LoggerService,
              private translateService: TranslateService,
              private upSellingTransformerService: UpSellingTransformerService,
              private offerService: OfferService,
              private uploadService: UploadService,
              private messageService: MessageService) {
  }

  ngOnInit() {
    this.getMappingTransformerSwitchboardList();
    this.cols = [
      { field: 'cubicleRange', header: (this.translateService.instant('T_UP_SELLING_TRANSFORMER_RANGE')), style: 'range-column'},
      { field: 'cubicleReference', header: (this.translateService.instant('T_UP_SELLING_TRANSFORMER_SWITCHBOARD')), style: 'switchboard-column'},
      { field: 'transformerReference', header: (this.translateService.instant('T_UP_SELLING_TRANSFORMER_TRANSFORMER')), style: 'transformer-column'}
    ];
  }

  /**
   * Import the xlsx file for transformer and switchboard mapping
   * @param event
   */
  fileImport(event) {
    this.logger.debug('Import mapping file');
    const files = event.target.files;
    if (files.length > 0) {
      if (files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_UP_SELLING_IMPORT_FILE_TYPE_TILE'),
          detail: this.translateService.instant('T_UP_SELLING_IMPORT_FILE_TYPE_MESSAGE'),
        });
        return;
      }
      const formData: FormData = new FormData();
      formData.append('file', files[0]);
      const uploadUrl = '/upselling/import';
      this.importInProgress = true;
      this.uploadService.uploadFile(uploadUrl, formData).toPromise()
        .then(importResponse => {
          if (importResponse.includes(this.IMPORT_OK_RESPONSE)) {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('T_UP_SELLING_IMPORT_SUCCESS_TITLE'),
              detail: this.translateService.instant('T_UP_SELLING_IMPORT_SUCCESS_MESSAGE'),
            });
            // reload mapping transformer switchboard datas
            this.getMappingTransformerSwitchboardList();
          } else {
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant('T_UP_SELLING_IMPORT_ERROR_TITLE'),
              detail: this.translateService.instant('T_UP_SELLING_IMPORT_ERROR_MESSAGE'),
            });
            this.logger.info('download price file report');
            const downloadUrl = '/upselling/report';
            this.uploadService.downloadReport(downloadUrl, importResponse).subscribe(
              response => {
                this.uploadService.createXlsFileFromBackendResponse(response);
              }
            );
          }
        })
        .then(() => this.importInProgress = false)
        .catch(err => {
          this.logger.error('Cannot import cross selling excel file', err);
          this.importInProgress = false;
        });
    }
    // clean files in event
    event.target.value = null;
  }

  /**
   * Export the transformer and switchboard mapping table as xlsx file
   * @param data
   */
  fileExport(data) {
    this.logger.info('Data:' + data);
    // Filters not use in export case
    this.uploadService.exportMappingFile(this.mappingTransformerSwitchboardList).subscribe(
      response => {
        this.uploadService.createXlsFileFromBackendResponse(response);
      }
    );
  }

  /**
   * Get mapping list
   */
  private getMappingTransformerSwitchboardList() {
    this.upSellingTransformerService.getTransformerSwitchboardMappingList().subscribe(transformerSwitchboardMappingList => {
      this.mappingTransformerSwitchboardList = transformerSwitchboardMappingList;
      // translate the range name key
      this.mappingTransformerSwitchboardList.forEach(transformerSwitchboard => {
        transformerSwitchboard.cubicleRange = this.translateService.instant(transformerSwitchboard.cubicleRange);
      });
      // get the range list
      this.computeRangeNameKeys();
    });
  }

  /**
   * Manage range name key list
   */
  private computeRangeNameKeys() {
    this.rangeSelectItems = [];
    _.uniqBy(this.mappingTransformerSwitchboardList, elem => elem.cubicleRange).forEach(transformerSwitchboard => {
      this.rangeSelectItems.push({
        label: transformerSwitchboard.cubicleRange,
        value: transformerSwitchboard.cubicleRange
      });
    });
  }

}
