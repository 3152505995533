<app-spinner *ngIf="loading" [sizeClass]="'large'"></app-spinner>
<div *ngIf="!loading" class="container">

  <h2 translate>T_TRIAL_CONFIGURATION_TITLE</h2>
  <div class="row">

    <div class="row">
      <div class="col-xs-3">
        <label translate>T_TRIAL_CONFIGURATION_CHOSE_DEMO_MODE</label>
      </div>
      <div class="col-xs-9">
        <p-selectButton [options]="demoOptions" [(ngModel)]="localizationToUpdate.demoMode"></p-selectButton>
      </div>
    </div>

    <div *ngIf="localizationToUpdate.demoMode !== 'EXTENDED'">
      <div class="row">
        <div class="col-xs-3">
          <label translate>T_TRIAL_CONFIGURATION_VIDEO_LINK</label>
          <ng-template #videoLinkInfoTemplate><div [innerHtml]="'T_TRIAL_CONFIGURATION_VIDEO_LINK_TOOLTIP' | translate"></div></ng-template>
          <span class="se-icons font-param video-link-info" [tooltip]="videoLinkInfoTemplate"
                placement="left" container="body" aria-hidden="true">information_circle</span>
        </div>
        <div class="col-xs-6">
          <input type="text"
                 id="videoLink"
                 class="form-control"
                 [(ngModel)]="localizationToUpdate.demoVideoLink"/>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-3">
          <label translate>T_TRIAL_CONFIGURATION_DEFAULT_LANGUAGE</label>
        </div>
        <div class="col-xs-9">
          <select class="select" [(ngModel)]="localizationToUpdate.indexDemoDefaultlanguage">
            <ng-container *ngFor="let lang of localizationToUpdate.langs; let i = index">
              <option *ngIf="lang.available" [ngValue]="i" translate>{{lang.language}}</option>
            </ng-container>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-3">
          <label translate>T_TRIAL_CONFIGURATION_DEMO_PREVIEW</label>
        </div>
        <div class="col-xs-9">
          <app-demo-page [localization]="localization"></app-demo-page>
        </div>
      </div>

    </div>
    <div class="row">
      <button type="button" class="btn btn-group btn-primary button-div update-button" (click)="updateLocalization()" translate>T_TRIAL_CONFIGURATION_UPDATE_BUTTON</button>
    </div>
  </div>

</div>
