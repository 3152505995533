<div class="row bom-section-margin-top">
  <span class="table-title" *ngIf="isProjectBomConfiguration && !isItemBomConfiguration">{{tableTitle}}</span>
  <p-treeTable [value]="treeData" [ngClass]="{'bomHeader': true}" (onNodeExpand)="storeExpandedNodes($event)" (onNodeCollapse)="removeExpandedNodes($event)">
    <ng-template pTemplate="header">
      <tr>
        <th scope="col" *ngFor="let col of cols" [ngStyle]="{'width': col.width}" [ngClass]="col.className">
          <span>{{col.header | translate:{currency: localization.displayedCurrency} }}</span>
          <span class="se-icons brand-information" container="body" tooltip="{{deliveryMessage}}" *ngIf="isDeliveryHeader(col.header)">
                information_circle
          </span>
          <!--Diffuse user message tooltip-->
          <span class="se-icons brand-information" container="body"  tooltip="{{'T_DIFFUSE_BOM_MESSAGE' | translate}}" *ngIf="isTotalPriceColumnAndExtendedDemo(col.header)">
                information_circle
          </span>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
      <tr>
        <td *ngFor="let col of cols" [ngClass]="col.className">
          <p-treeTableToggler [rowNode]="rowNode" *ngIf="isToggleColumn(col.field)"></p-treeTableToggler>

          <span *ngIf="isDeletedReference(col.field, rowNode.node, rowData[col.field])"
                class="se-icons brand-warning exclamation-circle-margin read-only-warning"
                tooltip="{{'T_BOM_UNAVAILABLE_REFERENCE' | translate}}"
                container="body">
            general_danger_wired
          </span>

          <!-- SUBTRACT QUANTITY BUTTON -->
          <span *ngIf="hasQuantityManagement(col.field, rowNode.node, rowData[col.field])">
            <button type="button" class="btn btn-default-gray-background" (click)="subtractQuantity(rowNode.node)">-</button>
          </span>

          <!-- CELL CONTENT -->
          <span [id]="{'background-color':'red'}" class="{{getCssForCell(col.header, rowNode.node, rowData[col.field], col.field)}}"
                tooltip="{{(getTooltipMessage(col.header, rowNode.node, rowData[col.field], col.field))}}" positionStyle="fixed"
                container="body" placement="left">
                        {{getCellValue(col.header, rowNode.node, rowData[col.field])}}
          </span>

          <!-- CONFIGURATION LABEL -->
          <label *ngIf="hasConfigurationColumn(col.header, rowData) && !isCPQProject()" class="quotation-discount-label">{{rowData.quotationDate}}</label>

          <!-- DISCOUNT LABEL -->
          <label *ngIf="displayDiscount(col.header, rowData)" class="quotation-discount-label">{{rowData.discount}}</label>

          <!-- ADD QUANTITY BUTTON -->
          <span *ngIf="hasQuantityManagement(col.field, rowNode.node, rowData[col.field])">
            <button type="button" class="btn btn-default-gray-background" (click)="addQuantity(rowNode.node)">+</button>
          </span>
        </td>
      </tr>
    </ng-template>
  </p-treeTable>
</div>
