<div class="section-margin data-management">

  <h3 *ngIf="selectedRange.status === 'PUBLISHED'"><span class="back-link" (click)="returnOnOfferSelection()" translate>T_OFFER_DATA_MANAGEMENT_BACK_BUTTON</span>
  </h3>

  <div class="reference-list">
    <div class="titles">
      <p class="range-key" translate><span
        translate>T_OFFER_DATA_MANAGEMENT_LIST_OF_REFERENCES_OF_RANGE </span>{{selectedRange.nameKey}}</p>
      <div class="button-wrap">

        <!-- FILTER PUBLISHED/UNPUBLISHED -->
        <div class="data-management-button">
          <label *ngIf="selectedRange.status !== 'PUBLISHED' && selectedRange.rangeType === 'SWITCHBOARD'"
                 for="numCubicles">
            <span translate>T_OFFER_DATA_MANAGEMENT_MAX_NUMBER_OF_CUBICLE</span> :
          </label>
          <select *ngIf="selectedRange.status !== 'PUBLISHED' && selectedRange.rangeType === 'SWITCHBOARD'"
                  id="numCubicles" class="select-button margin-right"
                  (change)="updateMaxNumCubicle($event.target.value)">
            <option *ngFor="let number of maxCubicleSize"
                    [selected]="number === selectedRange.switchboardLimit.max">{{ number }}</option>
          </select>
          <select id="selectStatus" class="select-button margin-right" [(ngModel)]="filterValue"
                  (change)="filterReferenceList($event)">
            <option [value]="ALL_REFERENCES" translate>T_OFFER_DATA_MANAGEMENT_ALL_REFERENCES</option>
            <option [value]="FILTER_PUBLISHED" translate>T_OFFER_DATA_MANAGEMENT_PUBLISHED</option>
            <option [value]="FILTER_UNPUBLISHED" translate>T_OFFER_DATA_MANAGEMENT_UNPUBLISHED</option>
            <option [value]="IN_TEST" translate>T_OFFER_DATA_MANAGEMENT_IN_TEST</option>
          </select>

          <!-- ADD A REFERENCE BUTTON -->
          <button class="btn btn-group btn-primary button-div margin-right" [disabled]="loadingComponents"
                  (click)="showModal('add', null)" translate>T_OFFER_DATA_MANAGEMENT_REF_ADD
          </button>
        </div>
      </div>
    </div>

    <!-- OFFER CARD -->
    <app-element-tile [readOnly]="false"
                      [name]="selectedRange.nameKey"
                      [pictureUrl]="selectedRange.rangePicture?.url"
                      [picturesAssetsPath]="selectedRange.imageKey"
                      [pictureFileId]="selectedRange.rangePicture?.imageFileId">
    </app-element-tile>

    <div class="box-ref-wrapper">
      <app-spinner *ngIf="loadingComponents" [sizeClass]="'medium'"></app-spinner>

      <!-- LIST OF REFERENCES -->
      <div *ngIf="!loadingComponents">
        <div *ngFor="let refView of existingFilteredReference" class="row box-list">

          <div class="col-md-6 box-element" translate>{{refView.reference.ref}}</div>

          <div *ngIf="selectedRange.rangeType !== RangeType.IMPORTED && selectedRange.rangeType !== RangeType.FAKE">

            <div class="col-md-1 box-element">
            <span *ngIf="refView.type === 'switchboardComponent'"
                  [ngClass]="{'se-icons icon': true, 'se-ok': refView.electricalCharacOk, 'se-error': !refView.electricalCharacOk}"
                  tooltip="{{refView.electricalCharacOk ? ('T_OFFER_DATA_MANAGEMENT_REF_ELECTRICAL_CHARAC_OK' | translate) : getMissingCharacteristic('T_OFFER_DATA_MANAGEMENT_REF_ELECTRICAL_CHARAC_KO', refView.electricalCharacMissing)}}"
                  container="body">
              {{ refView.electricalCharacOk ? 'notification_ok_stroke' : 'notification_error_plain' }}
            </span>
            </div>

            <div class="col-md-1 box-element">
            <span *ngIf="refView.type === 'switchboardComponent'"
                  [ngClass]="{'se-icons icon': true, 'se-ok': refView.mainCharacOk, 'se-error': !refView.mainCharacOk}"
                  tooltip="{{refView.mainCharacOk ? ('T_OFFER_DATA_MANAGEMENT_REF_MAIN_CHARAC_OK' | translate) : getMissingCharacteristic('T_OFFER_DATA_MANAGEMENT_REF_MAIN_CHARAC_KO', refView.mainCharacMissing)}}"
                  container="body">
              {{ refView.mainCharacOk ? 'notification_ok_stroke' : 'notification_error_plain' }}
            </span>
            </div>
          </div>
          <div *ngIf="selectedRange.rangeType === RangeType.IMPORTED" class="col-md-2 box-element"></div>

          <div class="col-md-2 box-element align-right">{{getStatusLabel(refView.reference.status)}}</div>

          <div class="col-md-1 box-element align-right">
            <span *ngIf="refView.type === 'switchboardComponent'"
                  type="button" class="se-icons btn info-pellet"
                  (click)="selectedReference(refView.reference.ref)">information_circle</span>
          </div>
          <div class="col-md-1 box-element btn-group  button-div pull-right" dropdown placement="bottom left">
            <button dropdownToggle type="button" class="btn toggle-dropdown dropdown-toggle">
              <span class="se-icon burger-icon" aria-hidden="true">other_vertical</span>
            </button>
            <ul *dropdownMenu class="box-dropdown dropdown-menu dropdown-menu-right" role="menu">
              <li
                *ngIf="selectedRange.status === 'PUBLISHED' && !selectedRange.offerDataManagementConfigurationStep && refView.reference.status === 'UNPUBLISHED'">
                <a (click)="showModal('test', refView)" translate>T_OFFER_DATA_MANAGEMENT_REFERENCE_TEST</a>
              </li>
              <li
                *ngIf="selectedRange.status === 'PUBLISHED' && !selectedRange.offerDataManagementConfigurationStep && refView.reference.status === 'IN_TEST'">
                <a (click)="showModal('publish', refView)" translate>T_OFFER_DATA_MANAGEMENT_REFERENCE_PUBLICATION</a>
              </li>
              <li><a (click)="showModal('delete', refView)">{{ getDeletionTranslation(refView) }}</a></li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>


<!--Modal add reference-->
<app-odm-add-modal #addReferenceModal
                   (onAddReferenceButton)="addReference($event)"
                   [selectedRange]="selectedRange"
                   [allowedReferenceCategories]="allowedReferenceCategoriesForComponents"
>

</app-odm-add-modal>
<!-- Modal delete reference -->
<app-common-modal #firstDeleteReferenceModal
                  [title]="'T_OFFER_DATA_MANAGEMENT_DELETE_REFERENCE_INFORMATION_TITLE' | translate"
                  [name]="'T_OFFER_DATA_MANAGEMENT_DELETE_REFERENCE_INFORMATION_TITLE'"
                  [description]="'T_OFFER_DATA_MANAGEMENT_DELETE_REFERENCE_INFORMATION_MESSAGE' | translate"
                  [acceptInnerHtml]="true" (onClickYesButton)="secondDeleteReferenceModal.show()">
</app-common-modal>
<!-- Modal delete reference -->
<app-common-modal #secondDeleteReferenceModal [title]="'T_OFFER_DATA_MANAGEMENT_DELETE_REFERENCE_TITLE' | translate"
                  [name]="'T_OFFER_DATA_MANAGEMENT_DELETE_REFERENCE_TITLE'"
                  [description]="'T_OFFER_DATA_MANAGEMENT_DELETE_REFERENCE_MESSAGE' | translate"
                  (onClickYesButton)="deleteReference()" [size]="'modal-sm'">
</app-common-modal>

<!-- Modal test reference -->
<app-common-modal #testReferenceModal [title]="'T_OFFER_DATA_MANAGEMENT_TEST_REFERENCE_TITLE' | translate"
                  [name]="'T_OFFER_DATA_MANAGEMENT_TEST_REFERENCE_TITLE'"
                  [description]="'T_OFFER_DATA_MANAGEMENT_TEST_REFERENCE_MESSAGE' | translate"
                  (onClickYesButton)="changeReferenceStatus('IN_TEST')" [size]="'modal-sm'">
</app-common-modal>

<!-- Modal publish reference -->
<app-common-modal #publishReferenceModal [title]="'T_OFFER_DATA_MANAGEMENT_PUBLISH_REFERENCE_TITLE' | translate"
                  [name]="'T_OFFER_DATA_MANAGEMENT_PUBLISH_REFERENCE_TITLE'"
                  [description]="'T_OFFER_DATA_MANAGEMENT_PUBLISH_REFERENCE_MESSAGE' | translate"
                  (onClickYesButton)="changeReferenceStatus('PUBLISHED')" [size]="'modal-sm'">
</app-common-modal>
<app-card-component-modal #componentInfosModal></app-card-component-modal>
