import {Component, Input} from '@angular/core';
import {Layout} from '../../shared/model/IProduct';

@Component({
  selector: 'app-layout-component-viewer',
  templateUrl: './layout-component-viewer.component.html',
  styleUrls: ['./layout-component-viewer.component.less']
})
export class LayoutComponentViewerComponent {

  @Input()
  layout: Layout;

  @Input()
  height: number;

  getLayoutImagePath(): string {
    let path = this.getComponentUrl();
    if (path === null) {
      path = Math.trunc(this.height) + '-';
    }
    return './assets/layout/' + path;
  }

  private getComponentUrl(): string {
      return this.layout && this.layout.url != null ? this.layout.url : null;
    }
}
