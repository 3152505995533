import {Injectable} from '@angular/core';
import {Incompatibility, SwitchBoardComponent} from '../model/component';
import {Opt} from '../model/IProduct';
import {isNullOrUndefined} from 'util';

@Injectable()
export class  ConfigurationHelperService {

  constructor() {}

  private static findIncompatibility(option: Opt, name: string): Incompatibility {
    let incompatibilityFound: Incompatibility;
    if (option.incompatibilities) {
      for (const incompatibility of option.incompatibilities) {
        if (incompatibility.typeEntity === 'C_OPTION_DEPENDENT' && incompatibility.values[0].indexOf(name) === 0) {
          incompatibilityFound = incompatibility;
        }
      }
    }
    return incompatibilityFound;
  }

  /**
   * Manage dependencies between the options of same cubicle.
   *
   * @param value
   * @param changedOption
   * @param {SwitchBoardComponent} component
   * @param {boolean} returnPopupOnly
   */
  public manageDependentOptionsVisibility(value,
                                          changedOption,
                                          component: SwitchBoardComponent): void {
    // manage visibility(options dependent on other options values)
    component.options.forEach(opt => {
        if (opt.name !== changedOption.name) {
          const incompatibility: Incompatibility = ConfigurationHelperService.findIncompatibility(opt, changedOption.name);
          if (incompatibility && incompatibility.values) {
            opt.visible = !(incompatibility.values.includes(changedOption.name + '.' + value)) && value !== null;
          }

          if (!opt.visible) {
            opt.values.forEach(val => {val.selected = false; });
          } else if (opt.visible && opt.values.every(val => val.selected === false) && !isNullOrUndefined(opt.defaultValue)) {
            opt.values[opt.defaultValue].selected = true;
          }

        }
      });
  }

  /**
   * Sets visibility for the options with no dependencies.
   *
   * @param options
   */
  public setVisibilityForIndependentOptions(options: Opt[]): Opt[] {
    options.forEach(opt =>
      opt.visible = (!opt.incompatibilities || (opt.incompatibilities && opt.incompatibilities.length === 0)) && opt.values.length > 1
    );
    return options;
  }

  /**
   * Sets visibility for the options with incompatibilities.
   *
   * @param options
   * @param component
   */
  public setVisibilityForDependentOptions(options: Opt[], component: SwitchBoardComponent): Opt[] {
    // Reset of visibility options
    options.forEach(opt => {
      opt.visible = opt.values.length > 1;
    });
    let hasSelectedValue: boolean;
     options.forEach(opt => {
       hasSelectedValue = false;
       opt.values.forEach(value => {
          if (value.selected) {
            hasSelectedValue = true;
            this.manageDependentOptionsVisibility(value.value, opt, component);
          }
       });
       if (!hasSelectedValue) {
         // Treat incompatibilities also without selected value
        this.manageDependentOptionsVisibility(null, opt, component);
       }
    });

    return component.options;
  }
  /**
   * reset all selection of option.
   *
   * @param options
   */
  public resetOptionsSelection(options: Opt[]): Opt[] {
    options.forEach(opt => {
        opt.values.forEach(optValues => {
          optValues.selected = false;
        });
    });
    return options;
  }

}
