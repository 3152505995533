import {Component, OnInit, ViewChild} from '@angular/core';
import {NavigationLinkEnum} from '../shared/guards/navigationLink-enum';
import {interval} from 'rxjs';
import {startWith, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ApplicationService} from '../shared/app/application.service';
import {LoggerService} from '../shared/logging/logger.service';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../shared/user/user.service';
import {MessageService} from 'primeng/api';
import {PriceManagementService} from '../admin/shared';
import {SessionStorage, SessionStorageService} from 'ngx-webstorage';
import {CommonModalComponent} from '../shared/common-modal/common-modal.component';
import {User} from '../shared/user/user';
import {Item} from '../project/shared/project-model';

@Component({
  selector: 'app-myse-status',
  templateUrl: './myse-status.component.html',
  styleUrls: ['./myse-status.component.less'],
})
export class MyseStatusComponent implements OnInit {
  @SessionStorage()
  isNetPriceLoading: boolean;

  @SessionStorage()
  isNetPriceDisplayed: boolean;

  @SessionStorage()
  currentItemCT: Item;

  @SessionStorage()
  user: User;

  @ViewChild('reloadNetPriceModal')
  reloadNetPriceModal: CommonModalComponent;

  netPriceModalMessage: string;

  @SessionStorage()
  netPriceByPartnerDiscount = [];

  constructor(private router: Router,
              private translateService: TranslateService,
              private messageService: MessageService,
              private sessionStorageService: SessionStorageService,
              private priceManagementService: PriceManagementService) {
  }

  ngOnInit(): void {
    // Check if it is necessary to load net price
    this.priceManagementService.netPricesLoadingInProgress()
      .subscribe(inProgress => {
        if (inProgress) {
          this.pollingNetPrice();
        } else {
          this.afterLoadingNetPrices(null);
        }
      });

    this.priceManagementService.getNetPriceDiscountForPartner().subscribe(discounts => {
      this.netPriceByPartnerDiscount = discounts;
      this.sessionStorageService.store('netPriceByPartnerDiscount', discounts);
    });
  }

  public goToProjectPage() {
    this.router.navigate([NavigationLinkEnum.PROJECTS]);
  }

  public isAlreadyOnProjectPage(): boolean {
    return this.router.isActive(NavigationLinkEnum.PROJECTS, true);
  }


  private getMessageKeyNetPriceModal(count: number[]) {
    this.netPriceModalMessage = this.translateService.instant('T_PRICE_NET_PRICE_LOADED', {allReferenceCount: count[1], netPriceReferenceCount: count[0]});

    if (!this.isAlreadyOnProjectPage()) {
      this.netPriceModalMessage = this.netPriceModalMessage
        + '<br>'
        + this.translateService.instant('T_PRICE_NET_PRICE_LOADED_REDIRECT');
    }
  }

  private pollingNetPrice() {
    this.messageService.add({
      severity: 'info',
      summary: this.translateService.instant('T_INFO'),
      detail: this.translateService.instant('T_PRICE_NET_PRICE_LOADED_INFO'),
    });
    this.isNetPriceLoading = true;
    const intervalSubscription = interval(5000)
      .pipe(
        startWith(0),
        switchMap(() => this.priceManagementService.netPricesLoadingInProgress()),
      )
      .subscribe(inProgress => {
        if (!inProgress) {
          this.isNetPriceLoading = false;
          this.afterLoadingNetPrices(intervalSubscription);
        } else {
          this.isNetPriceDisplayed = false;
        }
      }, () => this.isNetPriceLoading = false);
  }

  private afterLoadingNetPrices(intervalSubscription) {
    this.priceManagementService.getCountReferenceNetPricesAvailable()
      .subscribe(counts => {
        this.isNetPriceDisplayed = counts[0] > 0;
        if (this.isNetPriceDisplayed) {
          // When subscription is null, it means that net price come from cache and we don't want to show the modal
          if (intervalSubscription !== null) {
            this.getMessageKeyNetPriceModal(counts);
            this.reloadNetPriceModal.show();
          }
        } else if (this.netPriceByPartnerDiscount.length === 0){
          this.messageService.add({
            severity: 'warn',
            summary: this.translateService.instant('T_INFO'),
            detail: this.translateService.instant('T_PRICE_NET_PRICE_NOT_AVAILABLE'),
          });
        }
        if (intervalSubscription !== null) {
          intervalSubscription.unsubscribe();
        }
      });
  }
}
