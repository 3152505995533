/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
/* ngx modules */
import {XlsFile} from './xls-file.model';
import {ReferencePrice} from '../price/price.management.model';
import {LoggerService} from '../../../shared/logging/logger.service';
import {TranslationKey} from 'app/admin/shared';
import {MappingTransformerSwitchboard} from '../up-selling/up.selling.transformer.model';
import {ReportType} from './report-type.enum';

@Injectable()
export class UploadService {

  constructor(private httpClient: HttpClient,
              private logger: LoggerService) {
  }

  /**
   * Upload file to url.
   *
   * @param uploadUrl
   * @param formData
   */
  uploadFile(uploadUrl, formData): Observable<string> {
    this.logger.info('UploadService uploadFile()');
    return this.httpClient
      .post(uploadUrl, formData, {responseType: 'text'});
  }

  downloadReport(downloadUrl, reportId) {
    this.logger.info('UploadService downloadReport()');
    return this.httpClient.get(downloadUrl + '/' + reportId,
      {responseType: 'blob', observe: 'response'});
  }

  exportFile(referencePrices: ReferencePrice[]) {
    this.logger.info('exportFile()');
    return this.httpClient.post('/price/export', referencePrices,
      {responseType: 'blob', observe: 'response'});
  }

  /**
   * Export the mapping file between transformers and switchboards
   * @param mappingTransformerSwitchboards
   */
  exportMappingFile(mappingTransformerSwitchboards: MappingTransformerSwitchboard[]) {
    this.logger.info('exportMappingFile()');
    return this.httpClient.post('/upselling/export', mappingTransformerSwitchboards,
      {responseType: 'blob', observe: 'response'});
  }

  exportTranslationFile(language: string, translationKeys: TranslationKey[]) {
    this.logger.info('exportTranslationFile()');
    return this.httpClient.post('/localizations/export/' + language, translationKeys,
      {responseType: 'blob', observe: 'response'});
  }

  exportPackageFile(packageOfferId: string) {
    this.logger.info('exportPackageFile()');
    return this.httpClient.post('/package-offer/export/' + packageOfferId,"",
      {responseType: 'blob', observe: 'response'});
  }

  exportNetPricePartnersFile() {
    this.logger.info('exportPackageFile()');
    return this.httpClient.post('/price/net-price-partners/export/',"",
      {responseType: 'blob', observe: 'response'});
  }
  /**
   * Service to export report for FO: project report, reference report
   * @param type
   */
  exportReport(type: ReportType) {
    this.logger.info('UploadService exportReport()');
    return this.httpClient.get('/report/' + type,
      {responseType: 'arraybuffer', observe: 'response'});
  }

  createXlsFileFromBackendResponse(response) {
    const contentDisposition = response.headers.get('Content-Disposition');
    const fileName = contentDisposition.substring(contentDisposition.lastIndexOf('="') + 2, contentDisposition.lastIndexOf('"'));
    const xlsFile: XlsFile = new XlsFile();
    xlsFile.name = fileName;
    xlsFile.blob = response.body;
    xlsFile.size = response.body.size;
    xlsFile.lastUpdate = new Date();
    this.openXlsFile(xlsFile);
  }

  private openXlsFile(xlsFile: XlsFile): void {
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) { // for IE
      (window.navigator as any).msSaveOrOpenBlob(xlsFile.blob, xlsFile.name);
    } else {// For others navigators
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(xlsFile.blob);
      a.href = url;
      a.download = xlsFile.name;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }
}
