import {HttpClient} from '@angular/common/http';
/* Angular modules */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {SwitchBoardComponent} from '../../configuration/shared/model/component';
/* ngx modules */
import {Documentation} from '../documentation/documentation.model';
import {StaticDocumentationService} from '../../export/shared';
import * as _ from 'lodash';



@Injectable()
export class DatasheetsService {
  constructor(private httpClient: HttpClient) {
  }

  /**
   * Get a datasheet for a reference from the backend bsl datasheet cache
   * @param reference the reference to get the datasheet from
   * @param language the language wished for the datasheet
   * @return the datasheet as documentation
   */
  getReferenceDatasheetUrl(reference: string, language): Observable<Documentation> {
    return this.httpClient.get<Documentation>('/datasheets/reference/' + reference + '/' + language);
  }

  /**
   * get the datasheet from Ops async for all the component
   */

  /**
   * Return a payload composed of component id and its datasheet document associated
   * @param components
   * @param language
   */
  public getComponentsDatasheetUrl(components: SwitchBoardComponent[], language): Observable<Array<{ [k: string]: Documentation }>> {
    const mapRef: { [k: string]: string } = {};
    components.forEach(component => {
      mapRef[component.id] = component.reference.ref;
    });
    return this.httpClient.post<Array<{ [k: string]: Documentation }>>('/datasheets/components/' + language, mapRef);
  }


}
