<div class="projects-header-filter row">
  <!-- ADD PROJECT BUTTON -->
  <div class="col-md-4">
    <button class="btn btn-smart" (click)="addProject()" *ngIf="onMyLastProjectsTab" translate>
      <span class="se-icons">new_project</span>T_ADD_PROJECT
    </button>
  </div>

  <!-- FILTER BUTTON -->
  <div *ngIf="hasProjectLength" class="col-md-4">
    <div class="btn-group-filter">
      <!--owned or last modified projects-->
      <span class="button-span" tooltip="{{getCountProjects(STATUS_OWNED)}}" container="body">
                <label class="btn btn-toggle-white-background " [(ngModel)]="statusFilters.owned"
                       [ngClass]="{'disabled': disableFilters.owned && !statusFilters.owned,
                       'selected':statusFilters.owned}"
                       btnCheckbox tabindex="0" role="button" translate
                       (click)="updateStatusFilter()">T_owned</label>
      </span>
      <span class="button-span" tooltip="{{getCountProjects(STATUS_CONFIGURED)}}" container="body">
        <label class="btn btn-toggle-white-background" [(ngModel)]="statusFilters.configured"
               [ngClass]="{'disabled': disableFilters.configured && !statusFilters.configured,
                'selected': statusFilters.configured}"
               btnCheckbox tabindex="0" role="button" translate
               (click)="updateStatusFilter()">T_configured</label>
      </span>
      <span class="button-span" tooltip="{{getCountProjects(STATUS_ORDERED)}}" container="body">
                <label class="btn btn-toggle-white-background " [(ngModel)]="statusFilters.ordered"
                       [ngClass]="{'disabled': disableFilters.ordered && !statusFilters.ordered,
                        'selected':statusFilters.ordered}"
                       btnCheckbox tabindex="0" role="button" translate
                       (click)="updateStatusFilter()">T_ordered</label>
      </span>
      <!-- CPQ user -->
      <span *ngIf="rightService.isCPQUser()" class="button-span" tooltip="{{getCountProjects(CPQ_PROJECTS)}}" container="body">
              <label class="btn btn-toggle-white-background " [(ngModel)]="statusFilters.cpqProjects"
                     [ngClass]="{'disabled': disableFilters.cpqProjects && !statusFilters.cpqProjects,
                      'selected':statusFilters.cpqProjects}"
                     btnCheckbox tabindex="0" role="button" translate
                     (click)="switchCpqFilter('ALL');">T_CPQ_PROJECTS</label>
      </span>
      <span *ngIf="rightService.isCPQUser()" class="button-span" tooltip="{{getCountProjects(CPQ_PROJECTS_WITH_DEFAULT_QUOTE_ID)}}" container="body">
              <label class="btn btn-toggle-white-background " [(ngModel)]="statusFilters.cpqProjectsWithDefaultQuoteId"
                     [ngClass]="{'disabled': disableFilters.cpqProjectsWithDefaultQuoteId && !statusFilters.cpqProjectsWithDefaultQuoteId,
                      'selected':statusFilters.cpqProjectsWithDefaultQuoteId}"
                     btnCheckbox tabindex="0" role="button" translate
                     (click)="switchCpqFilter('QUOTE_ID')">T_CPQ_PROJECTS_WITH_DEFAULT_QUOTE_ID</label>
      </span>
    </div>
  </div>

  <!-- SEARCH FIELD -->
  <div *ngIf="hasProjectLength" class="col-md-4 btn-group-filter">
    <input type="text"
           pInputText
           size="50"
           class="search-input-text"
           placeholder="{{'T_PROJECT_SEARCH_TITLE' | translate}}"
           (keyup)="updateProjectsList()"
           [(ngModel)]="nameFilterContent">
    <em class="fa fa-search search-icon"></em>
  </div>
</div>

<div *ngIf="hasNoProject">
  <h3 class="page-title" translate>T_PROJECT_NO_PROJECT</h3>
</div>

<div>
  <h6 class="page-subtitle" *ngIf="resultFiltredProjects?.length > 1">
    {{resultFiltredProjects.length}}
    <span translate>T_PROJECTS</span></h6>
  <h6 class="page-subtitle" *ngIf="resultFiltredProjects?.length === 1" translate>
    {{resultFiltredProjects.length}}
    <span translate>T_PROJECT</span></h6>
</div>
