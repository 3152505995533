import { Injectable } from '@angular/core';
import {Subject} from 'rxjs/Subject';

@Injectable()
export class MessageService {

  // Observable string sources
  public myMessageSource = new Subject<string>();

  // Observable string streams
  public myMessage$ = this.myMessageSource.asObservable();

  constructor() { }

  // Service message commands
  sendMessage(message: any) {
    this.myMessageSource.next(message);
  }
}
