import {Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {SwitchBoardComponent} from '../shared/model/component';
import {isNullOrUndefined} from 'util';
import {ItemService} from '../../project/shared/item.service';
import {Item, Status} from '../../project/shared/project-model';
import {SessionStorage} from 'ngx-webstorage';
import {TranslateService} from '@ngx-translate/core';
import {Opt} from '../shared/model/IProduct';
import {ConfigurationHelperService} from '../shared/services/configuration.helper.service';
import {NavigationRoute} from '../../shared/guards/route-enum';
import {Subject} from 'rxjs/Rx';

@Component({
  selector: 'app-card-option-modal',
  templateUrl: './card-option-modal.component.html',
  styleUrls: ['./card-option-modal.component.less']
})
export class CardOptionModalComponent implements OnDestroy {

  @SessionStorage()
  currentItemct: Item;
  @SessionStorage()
  user;

  displayedOptions: Opt[];

  title: string;

  @ViewChild('addOption') public addOption: CommonModalComponent;

  @Input() componentToAdd: SwitchBoardComponent;
  @Output() onClickOKButton = new EventEmitter<string>();
  @Output() onClickNoButton = new EventEmitter<string>();

  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private itemService: ItemService,
              private translateService: TranslateService,
              private configurationHelperService: ConfigurationHelperService) {
  }

  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    // unsubscribe from the subject itself:
    this.unsubscribe$.unsubscribe();
  }

  show(component: SwitchBoardComponent) {
    this.componentToAdd = component;
    this.displayedOptions = this.configurationHelperService.resetOptionsSelection(
      this.configurationHelperService.setVisibilityForIndependentOptions(this.getAllOptions(this.componentToAdd))
    );
    if ( !isNullOrUndefined(this.displayedOptions)  && this.displayedOptions.length > 1) {
      this.title = this.translateService.instant('T_ADD_THESE_OPTIONS');
    } else {
      this.title = this.translateService.instant('T_ADD_THIS_OPTION');
    }
    this.addOption.show();
  }

  hide() {
    this.addOption.hide();
  }

  clickOkButton() {
    // FIXME USELESS call to navigation, step does not change after option selection ??
    // Managing nav bar
    this.currentItemct.maxNavigationStep = NavigationRoute.getNextStepByRange(this.currentItemct.range, this.currentItemct.currentNavigationStep).id;

    this.updateOption();
    this.onClickOKButton.emit();
  }

  /**
   * Change cubicle option method
   */
  async changeOption(value, option) {
    // manage the dependencies between options
    if (value && option) {
      this.configurationHelperService.manageDependentOptionsVisibility(value, option, this.componentToAdd);
    }
    // set selected value
    this.componentToAdd.options.forEach(
      opt => {
        if (opt.name === option.name) {
          opt.values.forEach( val => {
            val.selected = val.value === value;
          });
        }
      });
  }

  async onChangeToggleOption(isToAdd, option) {
    if ((isToAdd && option.values[0].reference) || (!isToAdd && !option.values[0].reference)) {
      option.values[0].selected = true;
      option.values[1].selected = false;
    } else {
      option.values[0].selected = false;
      option.values[1].selected = true;
    }
    const index = this.componentToAdd.options.findIndex(
      opt => opt.name === option.name);
    if (index !== -1) {
      this.componentToAdd.options.splice(index, 1, option);
    }
  }

  // FIXME throw the async it is useless, add a spinner during item update
  async updateOption() {
    this.currentItemct.components[this.currentItemct.components.length - 1] = this.componentToAdd;
    await new Promise(resolve =>
      this.itemService.updateItemWithStatusChange(this.currentItemct, Status.configured, this.user)
        .takeUntil(this.unsubscribe$)
        .subscribe(updatedItem => {
            updatedItem.selectedComponentIndex = this.currentItemct.selectedComponentIndex;
            this.currentItemct = updatedItem;
          }
        ));
    this.itemService.updateTotalPriceAndDimensions(this.unsubscribe$);
  }

  noOptionsSelected(option: Opt) {
    return ( option.values.findIndex( opt =>  opt.selected === true) === -1 || (option.defaultValue === undefined));
  }

  private getAllOptions(component: SwitchBoardComponent): Opt[] {
    if (!isNullOrUndefined(component.options)) {
      return SwitchBoardComponent.getPopUpOptions(component);
    }
  }

}
