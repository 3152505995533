<!--</app-common-modal>-->
<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">

      <div class="modal-header">
        <!-- cancel button -->
        <div class="close-stack" (click)="cancel()">
          <span class="se-icons close-circle">action_delete_cross
          </span>
        </div>
      </div>

      <div class="rating-body">
        <form>
          <!-- rating title  -->
          <div class="row"><p class="header-rate header-interline" translate>T_FEEDBACK_FIRST_SCREEN_TITLE</p>
          </div>
          <!-- first question -->
          <app-rate [questionFeedback]="userFeedbackModel.rating"></app-rate>

          <!-- Text area -->
          <div class="feedback-comment">
            <p  translate>T_FEEDBACK_ANY_COMMENTS</p>
            <textarea class="form-control" rows="7" [(ngModel)]="userFeedbackModel.comment" name="comment"
                      [maxlength]="480" placeholder="{{'T_FEEDBACK_COMMENT' | translate}}">
            </textarea>
            <small><em>{{'T_FEEDBACK_NOTICE' | translate}}</em></small>
          </div>

          <!-- accept question -->
          <div class="feedback-comment">
            <p  translate>T_FEEDBACK_ACCEPT_CONTACT</p>
            <div class="feedback-response">
              <input class="radio"
                     type="radio"
                     [value]="userFeedbackModel.acceptContacted">
              <img [ngClass]="{'radio-btn': true, 'hidden-btn': !userFeedbackModel.acceptContacted}"
                   src="./assets/images/icons/radio.svg"/>
              <img [ngClass]="{'radio-btn': true, 'hidden-btn': userFeedbackModel.acceptContacted}"
                   (click)="onChangeAcceptQuestion()"
                   src="./assets/images/icons/radioUnselected.svg" class="radio-btn"/>
              {{'T_FEEDBACK_YES' | translate}}
            </div>
            <div class="feedback-response">
              <input class="radio"
                     type="radio"
                     [value]="!userFeedbackModel.acceptContacted">
              <img [ngClass]="{'radio-btn': true, 'hidden-btn': userFeedbackModel.acceptContacted}"
                   src="./assets/images/icons/radio.svg"/>
              <img [ngClass]="{'radio-btn': true, 'hidden-btn': !userFeedbackModel.acceptContacted}"
                   (click)="onChangeAcceptQuestion()"
                   src="./assets/images/icons/radioUnselected.svg" class="radio-btn"/>
              {{'T_FEEDBACK_NO' | translate}}
            </div>
          </div>

          <!-- more information -->
          <div class="feedback-comment">
            <p [innerHTML]="'T_FEEDBACK_MORE_INFORMATION' | translate"></p>
          </div>

        </form>
      </div>

      <div class="row rating-footer">
          <button [disabled]="userFeedbackModel.rating.rate === null || sendingFeedback" type="submit" class="btn btn-success btn-center" (click)="submit()">
            <span translate>T_APPLICATION_SEND</span>
          </button>
        <!-- SPINNER -->
        <app-spinner [ngClass]="{'hidden-spinner': !sendingFeedback, 'send-feedback-spinner': true}" [sizeClass]="'small'">
        </app-spinner>
      </div>

    </div>
  </div>
</div>
