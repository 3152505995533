import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-package-items',
  templateUrl: './package-items.component.html',
  styleUrls: ['./package-items.component.less']
})
export class PackageItemsComponent {

  @Input()
  listOfElement: any[] = [];

  @Input()
  title: string;
}
