<div class="tool-container box-shadow">
  <div class="toolbox-left-side">
    <tabset type="pills">
      <tab heading="{{'T_CONFIGURATION_SLD' | translate}}" (selectTab)="toSldTab()">
      </tab>
      <tab heading="{{'T_CONFIGURATION_LAYOUT' | translate}}" (selectTab)="toLayoutTab()">
      </tab>
    </tabset>

    <div class="zoom-tools">
      <span class="se-icons tool-icon zoom-out">action_zoom_minus</span>
      <span class="se-icons tool-icon zoom-in">action_zoom_plus</span>
      <span class="se-icons se-move tool-icon zoom-reset"
            tooltip="{{'T_TOOLBAR_RESET_ZOOM_TOOLTIP' | translate}}" placement="right">action_zoom_100</span>
    </div>

    <div *ngIf="onSldTab" class="export-sld btn-group btn-primary button-div" dropdown>
      <button dropdownToggle type="button" class="btn-primary btn se-top-btn" translate>
        T_APPLICATION_EXPORT_SLD_DROPDOWN
        <span class="se-icons">action_download</span>
      </button>

      <ul *dropdownMenu class="box-dropdown dropdown-menu dropdown-menu-right" role="menu">
        <li><a class="dropdown-item" (click)="exportSldAsPng()" translate>T_APPLICATION_EXPORT_SLD_DROPDOWN_PNG</a></li>
        <li><a class="dropdown-item" (click)="exportSldAsPdf()" translate>T_APPLICATION_EXPORT_SLD_DROPDOWN_PDF</a></li>
      </ul>
    </div>

    <div *ngIf="!onSldTab" class="export-sld btn-group btn-primary button-div" dropdown>
      <button dropdownToggle type="button" class="btn-primary btn se-top-btn" translate>T_APPLICATION_EXPORT_LAYOUT_DROPDOWN
        <span class="se-icons">action_download</span>
      </button>

      <ul *dropdownMenu class="box-dropdown dropdown-menu dropdown-menu-right" role="menu">
        <li><a class="dropdown-item" (click)="exportLayoutAsPng()" translate>T_APPLICATION_EXPORT_LAYOUT_DROPDOWN_PNG</a></li>
        <li><a class="dropdown-item" (click)="exportLayoutAsPdf()" translate>T_APPLICATION_EXPORT_LAYOUT_DROPDOWN_PDF</a></li>
      </ul>
    </div>

    <app-spinner *ngIf="isExporting" [vCenter]="false" [sizeClass]="'small'"></app-spinner>

  </div>

</div>


