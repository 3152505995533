<div *ngIf="documentations && documentations.length > 0 || isQuoteSegment" class="row">
  <div class="col-xs-9 col-sm-offset-1 col-sm-10">
    <h3  translate>{{title}}</h3>
    <div>
      <table class="table table-striped table-hover">
        <tbody>
        <tr *ngFor="let doc of documentations"
            (click)="downloadFile(doc)"
            class="cursorOnRow">
          <td [ngClass]="hasExtensionName()?'fixedWidthSm icon-col': 'fixedWidth icon-col'">
            <span [ngClass]="{'se-action_pdf_report': isPdf(doc), 'se-word' : isWord(doc),'se-action-generate-symbols': isZip(doc),
            'se-action-datasheet': isUnknown(doc), 'se-action-export-opened': isExternalLink(doc), 'iconClass se-icons': true}"
               aria-hidden="true"></span>
            <span class="iconClass se-icons" *ngIf="isPdf(doc)" aria-hidden="true">action_pdf_report</span>
            <span class="iconClass se-icons" *ngIf="isWord(doc)" aria-hidden="true">file_docx</span>
            <span class="iconClass se-icons" *ngIf="isZip(doc)" aria-hidden="true">action_generate_symbols</span>
            <span class="iconClass se-icons" *ngIf="isUnknown(doc)" aria-hidden="true">action_datasheet</span>
            <span class="iconClass se-icons" *ngIf="isExternalLink(doc)" aria-hidden="true">action_doc_export</span>
          </td>
          <td *ngIf="hasExtensionName()"  class="fixedWidthSm extensionIconClass">{{getExtension(doc)}}</td>
          <td translate>{{getTitle(doc)}}</td>
          <td class="fixedWidth" [ngClass]="{'icon-col': !isDownloadInProgress(doc)}">
            <span *ngIf="!isDownloadInProgress(doc)" class="se-icons download-icon">action_download</span>
            <app-spinner *ngIf="isDownloadInProgress(doc)" [sizeClass]="'small'" [vCenter]="false"></app-spinner>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
