/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
/* app modules */
import {PartnerDiscount, Range} from '../../shared/model/range-model';

@Injectable()
export class OfferService {

  constructor(private httpClient: HttpClient) {
  }

  getRanges(): Observable<Range[]> {
    return this.httpClient.get<Range[]>('/ranges');
  }

  getRangesBackOffice(): Observable<Range[]> {
    return this.httpClient.get<Range[]>('/ranges');
  }

  /**
   * Service used to update the partner discount of a range
   * @param rangeName the range to update
   * @param partnerDiscount the partner discount for this range
   */
  updateRangePartnerDiscount(rangeName: string, partnerDiscount: PartnerDiscount): Observable<Range> {
    return this.httpClient.post<Range>('/ranges/' + rangeName, partnerDiscount);
  }
}
