<div>
  <app-spinner *ngIf="loading || (loadingTransformers && componentType === 'transformer')" [sizeClass]="'large'"></app-spinner>

  <div *ngIf="!loading && !(loadingTransformers && componentType === 'transformer')">
    <app-filter-list *ngIf="filters && filters.length > 0"
                     id="filters_id" #filterList
                     class="col-xs-2 scrollable scrollFilterListSize"
                     [filters]="filters"
                     [resetFilters]="resetFilters"
                     (selectedFiltersEvent)="updateComponentList($event)"
                     (removeAllFiltersEvent)="removeAllFilters()">
    </app-filter-list>
    <app-card-list id="cards" [components]="displayedComponents" [addingTransformerInfos]="transformerInfos"
                   [componentsLength]="currentItemct.components.length"
                   class="col-xs-10 undo-bootstrap-padding scrollable scrollListSize">
    </app-card-list>
  </div>
</div>



