export class Post {
  id: string;
  publishedDate: Date;
  type: string;
  tags: string[];
  title: string;
  description: string;
  imageBase64: string;
  mainOrResolDate: Date;
  creationDate: Date;
  startTime: Array<number>;
  endTime: Array<number>;
}

export enum PostCategory {
  NEWS = 'news',
  NOTIFICATIONS = 'notifications'
}

export enum NotificationsType {
  MAINTENANCE = 'Maintenance',
  INCIDENT = 'Incident',
  RESOLUTION = 'Resolution'
}
