<span>Values : </span>
<ng-template #withoutTooltipTemplate>
  <span [innerHTML]="'T_OPTION_VALUE_WITHOUT_TOOLTIP' | translate"></span>
</ng-template>

<span class="se-icons brand-information" container="body"
      [tooltip]="withoutTooltipTemplate" placement="right">information_circle</span>
<p *ngIf="values.length < 2" class="warning-tooltip">
  <span class="se-icon">notification_critical_stroke</span>
  <span translate="T_OPTION_VALUE_NOT_ENOUGH_VALUES_WARNING"></span>
</p>
<p-table [value]="values">
  <ng-template pTemplate="header">
    <tr>
      <th scope="col" translate="T_OPTION_VALUE_NAME"></th>
      <th scope="col" translate="T_OPTION_VALUE_REFERENCE"></th>
      <th scope="col" class="option-value-actions-column" translate="T_OPTION_VALUE_ACTIONS"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-optionValue let-index="rowIndex">
    <tr>
      <td translate>{{optionValue.value}}</td>
      <td>{{optionValue.reference ? optionValue.reference.ref : ""}}</td>
      <td class="option-value-actions-column">
        <button
          *ngIf="optionValue.reference"
          class="option-value-actions-buttons se-icon"
          (click)="onClickEditOptionValueButton(optionValue)">
          action_editor
        </button>
        <button
          *ngIf="optionValue.reference"
          class="option-value-actions-buttons se-icon"
          (click)="onClickDeleteOptionValueButton(optionValue)">
          action_delete
        </button>
      </td>
    </tr>
  </ng-template>
</p-table>
