/* Angular modules */
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
/* ngx modules */
import {TranslateService} from '@ngx-translate/core';
import {SessionStorage} from 'ngx-webstorage';
import {MessageService} from 'primeng/api';
/* app modules */
import {Item, ItemValue, Project, Status} from '../../project/shared/project-model';
import {ItemService} from '../../project/shared/item.service';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {NavigationStep} from '../../shared/guards/navigationStep-enum';
import {AccessoriesFamily, CharacteristicType, CharacteristicView, Option} from '../shared/characteristics.view';
import {LoggerService} from '../../shared/logging/logger.service';
import {ProjectService} from '../../project/shared/project.service';
import {ComponentService} from '../../configuration/shared/services/component.service';
import {ComponentSearchModeEnum, SwitchBoardComponent} from '../../configuration/shared/model/component';
import {ProductsFiltersService} from '../../shared/products-filters/products-filters.service';
import {FilterCategory} from '../../configuration/shared/model/filterCategory';
import {VisibilityCheck} from '../../configuration/shared/model/visibilityCheck';
import {Observable} from 'rxjs/Observable';
import {RangeType} from '../../shared/model/range-type';
import * as _ from 'lodash';
import {NavigationRoute} from '../../shared/guards/route-enum';
import {Subject} from 'rxjs/Rx';
import {AccessoriesService} from '../../shared/services/accessories.service';
import {Characteristic} from '../shared/characteristics.model';
import {AccessoryCategory, AccessoryCharacteristics} from '../../shared/model/accessory.model';
import {AccessoryCategoriesService} from '../../shared/services/accessoryCategories.service';

@Component({
  selector: 'app-electrical-characteristics',
  templateUrl: './characteristics-page.component.html',
  styleUrls: ['./characteristics-page.component.less'],
})

export class CharacteristicsPageComponent implements OnInit, OnDestroy {

  @ViewChild('LooseModal') LooseModal: CommonModalComponent;

  @ViewChild('container') container: ElementRef;

  @SessionStorage()
  user;

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  currentProject: Project;

  @SessionStorage()
  allComponents: SwitchBoardComponent[] = [];

  @SessionStorage()
  filteredComponents: SwitchBoardComponent[] = [];

  electricalCharacteristicsValues: CharacteristicView[] = [];


  electricalFilters: FilterCategory[] = [];
  allAccessories: AccessoriesFamily[] = [];
  accessoriesCharacteristicsValues: CharacteristicView[] = [];

  isAllowedToChange = true;
  isNeededToBeReinitialised = false;

  // To store value to change during the modal display
  characteristicSelectSaved: CharacteristicView;
  characteristicStepNumber = NavigationStep.CHARACTERISTICS;

  waitingForServer = true;
  spinnerMessage = 'T_CHARACTERISTICS_LOADING_MESSAGE';
  defaultCategory = 'T_ACCESSORIES_TITLE';
  settingsEnded = false;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private characteristicsService: AccessoriesService,
              private categoryService: AccessoryCategoriesService,
              private router: Router,
              private logger: LoggerService,
              private projectService: ProjectService,
              private componentService: ComponentService,
              private productsFiltersService: ProductsFiltersService,
              private itemService: ItemService,
              private translateService: TranslateService,
              private messageService: MessageService) {
  }

  ngOnInit() {
    this.logger.debug('CharacteristicsPageComponent init()');
    this.itemService.setItemNavigationStep(NavigationStep.CHARACTERISTICS, this.user, this.unsubscribe$, true);
    // Apply readOnly
    this.applyReadOnly();

    // Load item configuration
    this.loadConfigurationData();
  }

  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    // unsubscribe from the subject itself:
    this.unsubscribe$.unsubscribe();
  }


  /**
   * Get characteristics from model and selected value to create view
   * @param {AccessoryCharacteristics} accessoryCharacteristics the model
   * @param {CharacteristicType} type electrical|accessory
   * @param {ItemValue[]} selectedValues the selected value(s)
   * @returns {CharacteristicView} the view
   */
  convertCharacteristicToView(accessoryCharacteristics: AccessoryCharacteristics, type: CharacteristicType, selectedValues: ItemValue[]): CharacteristicView {
    const result = new CharacteristicView();
    result.id = accessoryCharacteristics.id;
    result.label = accessoryCharacteristics.name;
    result.tooltip = accessoryCharacteristics.description;
    result.htmlElementType = accessoryCharacteristics.htmlElementType;
    result.required = accessoryCharacteristics.required;
    result.information = accessoryCharacteristics.information;
    result.type = type;
    result.disabled = selectedValues.some(val => val.disabled);
    result.options = accessoryCharacteristics.values.map((val) => {
      const selectItemValues = selectedValues.filter(selVal => selVal.value === val.value);
      return new Option(val.value, val.reference,
        selectedValues.map(selVal => selVal.value).includes(val.value) || (selectedValues.length === 0 && val.id === accessoryCharacteristics.defaultValue),
        selectItemValues.length > 0 ? selectItemValues[0].quantity : 1, val.orderIndex);
    });
    result.category = accessoryCharacteristics.category;
    result.orderIndex = accessoryCharacteristics.orderIndex;
    return result;
  }

  /**
   * Called when changing a value in the child component
   * @param {CharacteristicView} element
   */
  changeValue(element: CharacteristicView, type: 'accessory'|'electrical') {
    const selectedOption = element.options.find(opt => opt.selected);
    this.logger.business('Set characteristic', {characteristic_name: element.label, characteristic_value: selectedOption != null ? selectedOption.value : null});
    if (this.isNeededToBeReinitialised && type === 'electrical') {

      this.characteristicSelectSaved = element;

      this.LooseModal.show();
    } else {
      this.applyChange(element);
    }
  }

  /**
   * Method called if the user select yes on the loose data popup
   */
  confirmLooseData() {
    // Reinit item to a configured state
    this.currentItemct.components = [];
    this.currentItemct.status = Status.configured;
    this.logger.logStatus(this.currentItemct);
    this.currentItemct.maxNavigationStep = NavigationStep.CONFIGURATION;
    this.settingsEnded = this.currentItemct.maxNavigationStep >= NavigationStep.CONFIGURATION;

    // Disable characteristics screen warning mode for quoted items
    this.isNeededToBeReinitialised = false;

    // Apply changes according to action
    this.changeValue(this.characteristicSelectSaved, 'electrical');
  }

  /**
   * This function test is all fields are completed by the user
   * It sets maxNavigationStep to 4 if all fields are completed else 3.
   * @returns {boolean}
   */
  isValidForm() {
    this.logger.debug('CharacteristicsPageComponent isValidForm()');
    this.currentItemct.currentNavigationStep = NavigationStep.CHARACTERISTICS;
    let nextStep;
    switch (this.currentItemct.range.rangeType) {
    case RangeType.FAKE:
    case RangeType.ACCESSORIES:
        nextStep = NavigationStep.BILL_OF_MATERIALS;
        break;
      case RangeType.SWITCHBOARD:
        nextStep = NavigationStep.CONFIGURATION;
        break;
      case RangeType.TRANSFORMER:
        nextStep = NavigationStep.TRANSFORMER;
        break;
    }
    // All required electrical characteristics and accessories have been set
    // For accessories range at least one reference has been selected
    if (this.electricalCharacteristicsValues.filter(elec => elec.required && elec.options.filter(opt => !opt.disabled).length > 1)
        .concat(this.accessoriesCharacteristicsValues.filter(acc => acc.required))
        .every(charac => charac.options.some(opt => opt.selected)) &&
      ((this.currentItemct.range.rangeType !== RangeType.FAKE && this.currentItemct.range.rangeType !== RangeType.ACCESSORIES) ||
        this.accessoriesCharacteristicsValues.filter(acc => acc.options.filter(option =>
          option.selected && option.reference !== null && option.reference !== undefined && option.reference.ref !== null && option.reference.ref !== undefined
        ).length > 0 ).length > 0 )) {
      this.itemService.setItemMaxNavigationStep(nextStep, this.user, false, this.unsubscribe$, true);
    } else {
      this.itemService.setItemMaxNavigationStep(NavigationStep.CHARACTERISTICS, this.user, true, this.unsubscribe$, true);
    }

    this.settingsEnded = this.currentItemct.maxNavigationStep > NavigationStep.CHARACTERISTICS;
  }

  /**
   * Go to the next tab
   */
  goToNextTab(): void {
    this.logger.debug('CharacteristicsPageComponent goOnNextTab');
    this.router.navigate([NavigationRoute.getNextStepByRange(this.currentItemct.range, this.currentItemct.currentNavigationStep).link]);
  }

  /**
   * Save the user modifications to the characteristics and send the configuration to the server for business rules
   * application
   */
  saveItemCT() {
    this.logger.debug('CharacteristicsPageComponent saveItemCT()');

    // Reset content of view to launch spinner
    this.waitingForServer = true;
    this.spinnerMessage = 'T_CHARACTERISTICS_UPDATING_MESSAGE';
    this.itemService.updateItemWithStatusChange(this.currentItemct, Status.configured, this.user)
      .takeUntil(this.unsubscribe$)
      .subscribe(item => {
          this.currentItemct = item;
          this.loadConfigurationData();
          this.itemService.updateTotalPriceAndDimensions(this.unsubscribe$);
        },
        error => {
          this.logger.error(error);
          this.waitingForServer = false;
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('T_ERROR'),
            detail: this.translateService.instant('T_RENAME_ITEM_ERROR_MESSAGE'),
          });
        }
      );
  }

  /**
   * Method called on the loosing data modal if user click on "NO"
   * Use to reinitiate form data
   */
  reinitData() {
    this.loadConfigurationData();
  }

  /**
   * Scroll to the bottom of the page
   */
  scrollDown() {
    this.container.nativeElement.scrollIntoView(false);
  }

  /**
   * Return true if range of the current Item is an accessories range
   */
  isAccessoriesRange() {
    return (this.currentItemct.range.rangeType === RangeType.ACCESSORIES || this.currentItemct.range.rangeType === RangeType.FAKE);
  }

  getAccessoryTitle(){
    return this.currentItemct.range.nameKey === "T_ACCESSORIES_FR" ? "T_ACCESSORIES_CHARACTERISTICS_TITLE" : "T_DISTRIBUTION_OFFER_TITLE";
  }

  openAccessoryCategoryChanged(accessoriesFamilyIndex: number, isOpen: boolean){
    this.allAccessories[accessoriesFamilyIndex].display = isOpen;
  }

  /**
   * Load or reload configuration from the item
   */
  private loadConfigurationData() {
    Observable.forkJoin(
      this.characteristicsService.getPublishedAccessoriesCharacteristics(this.currentItemct.range.id, true),
      this.componentService.getComponents(this.currentItemct.id, ComponentSearchModeEnum.MAIN),
      this.itemService.getItem(this.currentItemct.id),
      this.categoryService.getAccessoryCategory(this.currentItemct.range.id, true))
      .takeUntil(this.unsubscribe$)
      .subscribe(results => {
        // Update item from backend
        this.currentItemct = results[2];
        // Accessories values
        this.accessoriesCharacteristicsValues = results[0].filter(carac => this.currentItemct.displayAccessories.includes(carac.id))
          .map(carac => this.convertCharacteristicToView(carac, CharacteristicType.ACCESSORY, this.currentItemct.accessories.filter(ev => carac.id === ev.id)));
        // Add accessories to current item
        if (this.accessoriesCharacteristicsValues &&
          this.accessoriesCharacteristicsValues.length > 0 &&
          this.currentItemct.status === Status.configured) {
          this.accessoriesCharacteristicsValues.forEach(charac => {
            this.characteristicViewToItemValue(charac);
          });
        }
        this.sortAccessoriesByCategory(results[3]);

        // Generate electrical characteristics
        this.allComponents = results[1];
        // Extract filters
        this.electricalFilters = this.productsFiltersService.extractFiltersFromComponents(this.allComponents,
          this.currentItemct.range.electricalCharacteristicsFilters, true, new Map<string, VisibilityCheck>());
        // Apply previous selection to filters
        this.productsFiltersService.selectElectricalCharacteristicsFilter(this.currentItemct.electricalCharacteristics, this.electricalFilters);
        // Apply filters
        this.filteredComponents = <SwitchBoardComponent[]>this.productsFiltersService.applyFilters(this.electricalFilters, this.allComponents);
        // Update filters
        this.productsFiltersService.updateFilters(this.electricalFilters, this.filteredComponents);
        // Convert filters to view
        this.electricalCharacteristicsValues = _.sortBy(this.electricalFilters.map(elec => this.convertFilterToView(elec)),
          elem => this.currentItemct.range.electricalCharacteristicsFilters.indexOf(elem.id));

        this.waitingForServer = false;
        this.isValidForm();
      });
  }

  /**
   * Function that generate the view for electrical filters
   * @param {FilterCategory} filter
   * @returns {CharacteristicView}
   */
  private convertFilterToView(filter: FilterCategory): CharacteristicView {
    const result = new CharacteristicView();
    result.id = filter.key;
    result.required = true;
    result.htmlElementType = 'select';
    result.label = filter.key;
    // Manage electrical characteristis tooltip.
    // The tooltip key must be Electrical characteristic+_TOOLTIP.
    const tooltip = this.translateService.instant(filter.key + '_TOOLTIP');
    if (tooltip && tooltip !== filter.key + '_TOOLTIP') {
      result.tooltip = tooltip;
    }
    result.unit = filter.measurementUnit;
    result.options = filter.values.map(val => new Option(<string | number>val.value, null, val.checked, 1, 0, val.disabled));
    result.type = CharacteristicType.ELECTRICAL;
    result.chooseValue = filter.selected;
    return result;
  }

  /**
   * Apply user input to configuration
   * @param {CharacteristicView} element the characteristics changed
   */
  private applyChange(element: CharacteristicView) {
    if (element.options.every(opt => !opt.selected)) {
      this.currentItemct.electricalCharacteristics = this.currentItemct.electricalCharacteristics.filter(elec => elec.id !== element.id);
      this.currentItemct.accessories = this.currentItemct.accessories.filter(acc => acc.id !== element.id);
    } else {
      // Convert all selected options to ItemValue
      this.characteristicViewToItemValue(element);
    }
    this.saveItemCT();
  }

  /**
   * Convert CharacteristicView to ItemValue and update current item if it is configured
   * @param element
   * @constructor
   */
  private characteristicViewToItemValue(element: CharacteristicView) {
    if (this.currentItemct.status === Status.configured || element.type === CharacteristicType.ACCESSORY) {
      const modifications: ItemValue[] = [];
      element.options.filter(opt => opt.selected).forEach(opt => {
        const modif = new ItemValue();
        modif.id = element.id;
        modif.name = element.label;
        modif.reference = opt.reference;
        modif.value = opt.value;
        modif.chooseValue = true;
        modif.quantity = opt.quantity;
        modif.disabled = false;
        modifications.push(modif);
      });

      // Apply modification to item
      if (element.type === CharacteristicType.ACCESSORY) {
        this.currentItemct.accessories = this.currentItemct.accessories
          .filter(acc => acc.id !== element.id)
          .concat(modifications);
      } else if (element.type === CharacteristicType.ELECTRICAL) {
        this.currentItemct.electricalCharacteristics = this.currentItemct.electricalCharacteristics
          .filter(elec => elec.id !== element.id)
          .concat(modifications);
      }
    }
  }

  /**
   * Function to check if the page must be set in read only and if a toaster for ordered configuration must be displayed
   */
  private applyReadOnly() {
    if ((this.currentItemct && this.currentItemct.status === Status.ordered) ||
      this.projectService.isReadOnlyProject(this.currentProject, this.user)) {

      if (!this.projectService.isReadOnlyProject(this.currentProject, this.user)) {
        if (this.projectService.isCpqProject(this.currentProject)) {
          // Information Toast
          this.messageService.add({
            severity: 'warn',
            summary: this.translateService.instant('T_ORDER_WARNING_TITLE'),
            detail: this.translateService.instant('T_PUSH_TO_CPQ_WARNING'),
          });
        } else {// Information Toast Ordered
          this.messageService.add({
            severity: 'warn',
            summary: this.translateService.instant('T_ORDER_WARNING_TITLE'),
            detail: this.translateService.instant('T_ORDER_WARNING'),
          });
        }
      }
      this.isAllowedToChange = false;
    } else {
      if (this.currentItemct && (this.currentItemct.components && this.currentItemct.components.length > 0) &&
        !this.projectService.isReadOnlyProject(this.currentProject, this.user)) {
        this.isNeededToBeReinitialised = true;
        // Information Toast Loose data
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('T_LOOSE_DATA_WARNING_TITLE'),
          detail: this.translateService.instant('T_LOOSE_DATA_WARNING'),
        });
      }
    }
  }

  /**
   * Method to sort accessoriesCharacteristics by category
   */
  private sortAccessoriesByCategory(knownCategory: AccessoryCategory[]) {
    const currentlyOpenedCategories: string[] = this.allAccessories.map((accessory) => { if(accessory.display){return accessory.category}});

    this.allAccessories = [];

    knownCategory.forEach(category => this.allAccessories.push(new AccessoriesFamily(category.nameKey, [], currentlyOpenedCategories.includes(category.nameKey))));
    this.accessoriesCharacteristicsValues.forEach(
      accessory => {
        // Category is just for Accessories range. If category is not defined, we display it in a generic category
        let category = (accessory.category !== null && accessory.category !== undefined) ?
          accessory.category : this.defaultCategory;
        let findCategory = knownCategory.filter(c => category === c.id);
        if (findCategory !== null && findCategory !== undefined && findCategory[0] !== null && findCategory[0].nameKey !== null) {
          category = findCategory[0].nameKey;
        } else {
          category = 'T_ACCESSORIES_CHARACTERISTICS_TITLE';
        }
        this.addAccessoryToAllAccessories(accessory, category, currentlyOpenedCategories.includes(category));
      }
    );
    this.allAccessories = this.allAccessories.filter(value => value.accessories.length > 0);
  }

  /**
   * Method to add an accessory with its category to all accessories
   * @param accessory
   * @param category
   * @param displayByDefault
   */
  private addAccessoryToAllAccessories(accessory: CharacteristicView, category: string, displayByDefault: boolean) {
    const groupAccessory = this.allAccessories.filter(group => group.category === category);

    // Do not integrate into accessories list, the ones which have less than 2 references.
    if (accessory.options.length > 1) {
      if (groupAccessory.length > 0) {
        groupAccessory[0].accessories.push(accessory);
      } else {
        this.allAccessories.push(new AccessoriesFamily(category, [accessory], displayByDefault));
      }
    }
  }
}
