<se-block class="navigation-side-panel" divider>
  <se-list>
    <se-list-item *ngFor="let item of items" (click)="goToItem(item)" [selected]="item.active">
      <div slot="item" translate>{{item.title}}</div>
      <small *ngIf="item.description" slot="description" translate>{{item.description}}</small>
      <se-icon *ngIf="item.icon" slot="icon" translate>{{item.icon}}</se-icon>
    </se-list-item>
  </se-list>
</se-block>

