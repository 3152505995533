/* Angular modules */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
/* ngx modules */
import {TranslateModule} from '@ngx-translate/core';
import { RatingModule } from 'ngx-bootstrap/rating';
import {AlertModule} from 'ngx-bootstrap/alert';
/* app module */
import {FeedbackModalComponent} from './feedback-modal/feedback-modal.component';
import {EcorealCtSharedModule} from 'app/shared/shared.module';
import {FormsModule} from '@angular/forms';
import {RateComponent} from 'app/feedback/rate/rate.component';
import { FeedbackComponent } from './feedback/feedback.component';


@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    EcorealCtSharedModule,
    FormsModule,
    RatingModule.forRoot(),
    AlertModule.forRoot()
  ],
  declarations: [ FeedbackModalComponent, RateComponent, FeedbackComponent ],
  providers: [],
  exports: [ FeedbackComponent ]
})
export class FeedbackModule {
}
