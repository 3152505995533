<section *ngIf="bslCharacteristicsMap" class="container-fluid">

  <!-- LEFT COLUMN-->
  <div class="odm-left-column scrollable scrollable-max-height">

    <!-- NO CHARACTERISTICS SCREEN CHECKBOX-->
    <div class="left-column-hide">
      <label for="check" class="custom-checkbox-container">
        <input type="checkbox" id="check" class="checkbox"  (change)="hideScreen($event.target)" [checked]="hideSreenValue">
        <span class="checkmark"></span>
        <span translate>T_OFFER_DATA_MANAGEMENT_SELECT_CHARACTERISTIC_CHECKBOX_HIDE</span>
      </label>
    </div>

    <!-- LIST OF CHARACTERISTICS -->
    <app-odm-characteristics [bslCharacteristicsMap]="bslCharacteristicsMap"
                                               [displayProperty]="'name'"
                                               (onSelectionChange)="selectedElectricalCharacteristics($event)">
    </app-odm-characteristics>
  </div>

  <!-- RIGHT COLUMN-->
  <div class="odm-right-column scrollable scrollable-max-height">

    <div class="right-column-title">
      <span translate>T_OFFER_DATA_MANAGEMENT_SELECT_FILTERS_PREVIEW</span>
    </div>

    <div class="right-column-detail">
      <div class="right-column-label">
        <span translate>T_OFFER_DATA_MANAGEMENT_SELECT_CHARACTERISTIC_LABEL</span>
      </div>

      <!-- NO SELECTED CHARACTERISTICS MESSAGE -->
      <div *ngIf="!(selectedCharacteristics && selectedCharacteristics.length > 0)" class="right-column-mess">
        <span translate>T_OFFER_DATA_MANAGEMENT_FILTERS_PREVIEW_MESSAGE</span>
      </div>

      <!-- LIST OF PREVIEW OF SELECTED CHARACTERISTICS -->
      <div cdkDropList (cdkDropListDropped)="drop($event)">
        <div class="charac-detail" *ngFor="let selectedCharacteristic of selectedCharacteristics" cdkDrag>
          <div class="charac-name-custom-placeholder" *cdkDragPlaceholder></div>
          <div class="charac-name">
            <span class="cursor-move">{{selectedCharacteristic.name}}</span>
          </div>
          <div class="charac-value">
            <input *ngIf="selectedCharacteristic.values.length < 2" [value]="selectedCharacteristic.values[0]" type="text" disabled class="input-value">
            <select *ngIf="selectedCharacteristic.values.length >= 2" class="select-value">
              <ng-container *ngFor="let val of selectedCharacteristic.values">
                <option class="cursor-move">{{val}}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <div *ngIf="selectedCharacteristics && selectedCharacteristics.length > 0" class="number-container">
          {{'T_OFFER_DATA_MANAGEMENT_NUMBER' | translate:{number: selectedCharacteristics.length} }}
        </div>
      </div>

    </div>
  </div>
</section>
