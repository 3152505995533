/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class BomMailService {

  constructor( private httpClient: HttpClient) {
  }

  sendMail (formData): Observable<any> {
    return this.httpClient.post('/order/sendMail', formData);
  }

  /**
   * Check if we add file the total files size does not exceed the max size
   * @param files already added files
   * @param file file to add
   * @param maxSize files size allowed
   * @returns {boolean}
   */
  canAddAttachementFile(attachments, file, maxSize) {
    let filesSize = file.size;
    attachments.forEach((attachment) => {
      filesSize += attachment.file.size;
    });
    return filesSize / (1024 * 1024) <= maxSize ;
  }

}
