import {Component, OnInit} from '@angular/core';
import {SessionStorage} from 'ngx-webstorage';
import {LocaleService} from '../../shared/localization/locale.service';
import {DemoMode, Localization} from '../../shared/model/localization.model';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-trial-configuration',
  templateUrl: './trial-configuration.component.html',
  styleUrls: ['./trial-configuration.component.less'],
})
export class TrialConfigurationComponent implements OnInit {

  @SessionStorage()
  localization: Localization;

  localizationToUpdate: Localization;

  loading = false;

  demoOptions = [
    { label: this.translateService.instant('T_TRIAL_CONFIGURATION_DEACTIVATED_MODE_LABEL'), value: DemoMode.DEACTIVATED },
    { label: this.translateService.instant('T_TRIAL_CONFIGURATION_DEMO_MODE_LABEL'), value: DemoMode.DEMO },
    { label: this.translateService.instant('T_TRIAL_CONFIGURATION_EXTENDED_DEMO_MODE_LABEL'), value: DemoMode.EXTENDED_DEMO },
  ];

  constructor(private readonly localeService: LocaleService,
              private translateService: TranslateService,
              private messageService: MessageService) {
  }

  ngOnInit() {
    this.localizationToUpdate = JSON.parse(JSON.stringify(this.localization));
  }


  /**
   * Method to update localization when user click on update button
   */
  updateLocalization() {
    this.loading = true;
    this.localeService.updateLocalization(this.localizationToUpdate).subscribe(
      loc => {
        this.localization = JSON.parse(JSON.stringify(loc));
      },
      error => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_TRIAL_CONFIGURATION_UPDATE_ERROR_MESSAGE'),
        });
        this.loading = false;
      },
      () => {
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('T_INFO'),
          detail: this.translateService.instant('T_TRIAL_CONFIGURATION_UPDATE_SUCCESS_MESSAGE'),
        });
        this.loading = false;
      });
  }


}
