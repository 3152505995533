import {Component, OnInit, ViewChild} from '@angular/core';
import {Range} from '../../shared/model/range-model';
import {OfferService} from '../../offers/shared/offer.service';
import {ExistingService, Service} from '../shared/services/services.model';
import {ServicesService} from '../shared/services/services.service';
import {BslReference} from '../shared/bsl-reference/bsl-reference-model';
import {isNullOrUndefined} from 'util';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {LoggerService} from '../../shared/logging/logger.service';
import {SelectItem} from "primeng/api";
import {CustomTranslateLoader} from '../../shared/localization/CustomTranslateLoader';
import {SessionStorage} from 'ngx-webstorage';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.less'],
})
export class ServicesComponent implements OnInit {

  @SessionStorage()
  user;

  @ViewChild('confirmModal') confirmModal: CommonModalComponent;

  ranges: Range[];

  selectedRange: Range;

  bslServiceReferences: BslReference[] = [];

  selectedBslServiceReference: BslReference;

  mandatoryService: boolean;

  editableQuantityService: boolean;

  existingServiceList: ExistingService[];

  rangeSelectItems: SelectItem[];

  isLoading = true;
  isLoadingBsl = true;

  cols: any[];

  constructor(private offerService: OfferService,
              private servicesService: ServicesService,
              private translateService: TranslateService,
              private customTranslateLoader: CustomTranslateLoader,
              private messageService: MessageService,
              private logger: LoggerService) {
  }

  ngOnInit() {
    this.getRangesAndExistingServiceList();

    this.cols = [
      { field: 'range', header: (this.translateService.instant('T_NAV_ADMIN_SERVICES_RANGE')) },
      { field: 'reference', header: (this.translateService.instant('T_NAV_ADMIN_SERVICES_REFERENCE'))  },
      { field: 'category', header: (this.translateService.instant('T_NAV_ADMIN_SERVICES_CATEGORY'))},
      { field: 'description', header: (this.translateService.instant('T_NAV_ADMIN_SERVICES_DESCRIPTION'))  },
      { field: 'mandatory', header: (this.translateService.instant('T_NAV_ADMIN_SERVICES_MANDATORY'))  },
      { field: 'editableQuantity', header: (this.translateService.instant('T_NAV_ADMIN_SERVICES_EDITABLE_QUANTITY'))  }
    ];
  }

  /**
   * Method to re-init service reference list for selected range
   */
  changeSelectedRange() {
    this.bslServiceReferencesByRange();
    this.mandatoryService = false;
    this.editableQuantityService = false;
  }

  /**
   * Display the user's selected reference category
   * @param event: selected bsl reference
   */
  onSelectServiceReference(event: string) {
    this.selectedBslServiceReference = this.bslServiceReferences.find(serv => serv.reference === event);
  }

  /**
   * Show the modal confirmation before adding a new service
   */
  askAddService() {
    this.confirmModal.show();
  }

  /**
   * Add a new service in a selected range
   */
  addService() {
    const newService: Service = new Service();
    newService.reference = this.selectedBslServiceReference.reference;
    newService.category = this.selectedBslServiceReference.category;
    newService.description = this.selectedBslServiceReference.description;
    newService.mandatory = this.mandatoryService ? this.mandatoryService : false;
    newService.editableQuantity = this.editableQuantityService ? this.editableQuantityService : false;
    this.isLoading = true;
    this.servicesService.addNewServiceToRange(this.selectedRange.id, newService).subscribe(range => {
        if (range) {
          // Updating translation by forcing cache for the new service translations key
          this.customTranslateLoader.getTranslation(this.user.preferredLanguage, true).subscribe(res => {
            this.translateService.setTranslation(this.user.preferredLanguage, res, false);
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('T_INFO'),
              detail: this.translateService.instant('T_NAV_ADMIN_SERVICES_ADD_SUCCESS_MESSAGE'),
            });
            this.getRangesAndExistingServiceList();
          });
        }
      },
      error => {
        this.logger.error(error);
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('T_ERROR'),
          detail: this.translateService.instant('T_NAV_ADMIN_SERVICES_ADD_ERROR_MESSAGE'),
        });
      });
  }

  /**
   * Get the existing service list in the different offers of a country
   */
  private getRangesAndExistingServiceList() {
    this.rangeSelectItems = [];
    this.offerService.getRanges().subscribe(
      ranges => {
        this.ranges = ranges;
        this.selectedRange = this.ranges[0];
        this.existingServiceList = [];
        this.ranges.forEach(range => {
          this.rangeSelectItems.push({
            label: this.translateService.instant(range.nameKey),
            value: this.translateService.instant(range.nameKey)
          });
          range.services.forEach(service => {
            this.existingServiceList.push({
              range: this.translateService.instant(range.nameKey),
              reference: service.reference,
              category: this.translateService.instant(service.category),
              description: this.translateService.instant(service.description),
              mandatory: service.mandatory,
              editableQuantity: service.editableQuantity
            });
          });
        });
        this.bslServiceReferencesByRange();
        this.mandatoryService = false;
        this.editableQuantityService = false;
        this.isLoading = false;
      });
  }

  /**
   * Get the bsl service reference list by range
   */
  private bslServiceReferencesByRange() {
    if (!isNullOrUndefined(this.selectedRange)) {
      this.isLoadingBsl = true;
      this.selectedBslServiceReference = null;
      this.bslServiceReferences = [];
      this.servicesService.getBslServiceReferencesForRangeId(this.selectedRange.bslRangeId).subscribe(
        bslReferences => {
          this.bslServiceReferences = bslReferences.filter(bslRef => !this.selectedRange.services.some(service => service.reference === bslRef.reference));
          if (this.bslServiceReferences && this.bslServiceReferences.length > 0) {
            this.selectedBslServiceReference = this.bslServiceReferences[0];
          }
          this.isLoadingBsl = false;
        }
      );
    }
  }

}
