/* Angular modules */
import {Injectable} from '@angular/core';
/* app modules */
import {SwitchBoardComponent} from '../model/component';
/* other sources */
import {NavigationStep} from '../../../shared/guards/navigationStep-enum';
import {Item} from '../../../project/shared/project-model';
import {ComponentService} from './component.service';
import {NavigationRoute} from '../../../shared/guards/route-enum';

@Injectable()
export class ItemNavigationService {

  constructor(private componentService: ComponentService) {
  }

  public updateNavigationBar(component: SwitchBoardComponent, currentItemct: Item): Item {
    if (this.componentService.canBeLastComponent(component)) {
      currentItemct.maxNavigationStep = NavigationRoute.getNextStepByRange(currentItemct.range, currentItemct.currentNavigationStep).id;
    } else {
      currentItemct.maxNavigationStep = NavigationStep.CONFIGURATION;
    }
    return currentItemct;
  }
}
