import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

export type NavigationBarItem = {
  title: String;
  url: String;
  active?: boolean;
}

@Component({
  selector: 'app-navigation-bar-v2',
  templateUrl: './navigation-bar-v2.component.html',
  styleUrls: ['./navigation-bar-v2.component.scss']
})
export class NavigationBarV2Component implements OnInit {

  @Input()
  items: NavigationBarItem[];

  constructor(private router: Router,
              private translateService: TranslateService,
              private route: ActivatedRoute){};

  ngOnInit(): void {
    let currentItem = this.items.find(item => item.active === true);
    if(currentItem){
      this.goToItem(currentItem);
    }
  }

  goToItem(item: NavigationBarItem){
    this.router.navigate(['/' + item.url]);
  }

  isActive(item: NavigationBarItem){
    return this.router.url.includes(item.url.toString());
  }

  getItemTitle(title: string) {
    return this.translateService.instant(title);
  }

}
