import { Component } from '@angular/core';
import {NavigationSidePanelItem} from '../../layout/navigation-side-panel/navigation-side-panel.component';
import {SessionStorage} from "ngx-webstorage";
import {Localization} from "../../shared/model/localization.model";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-data-management',
  templateUrl: './data-management.component.html',
  styleUrls: ['./data-management.component.scss']
})
export class DataManagementComponent {
  @SessionStorage()
  localization: Localization;


  dataManagementMenuItems: NavigationSidePanelItem[] = [
    {id:1, title: 'T_NAV_ADMIN_OFFER', active: true},
    {id:2, title: 'T_NAV_ADMIN_PACKAGE_MANAGEMENT'},
    {id:5, title: 'T_NAV_ADMIN_DOCUMENTATION'},
    {id:3, title: 'T_NAV_ADMIN_UP_SELLING_TRANSFORMER'},
    {id:4, title: 'T_NAV_ADMIN_SERVICES'},
  ];

  showTab(id: number) {
    const tab = this.dataManagementMenuItems.find(item => item.id === id);
    return tab && tab.active;
  }

  managePackageGroup(): boolean {
    return environment.packageActivation.includes(this.localization.country);
  }
}
