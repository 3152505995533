/* Angular modules */
import {Component, OnInit} from '@angular/core';
/* ngx modules */
import {SessionStorage, SessionStorageService} from 'ngx-webstorage';
/* 3rd parties libraries */
import * as moment from 'moment';
/* app modules */
import {Item, Status} from '../../project/shared/project-model';
import {User} from '../../shared/user/user';
import {LoggerService} from '../../shared/logging/logger.service';

@Component({
  selector: 'app-validity-period',
  templateUrl: './validity-period.component.html',
  styleUrls: ['./validity-period.component.less']
})
export class ValidityPeriodComponent implements OnInit {

  @SessionStorage()
  currentItemct: Item;

  @SessionStorage()
  user: User;

  localeDate: string;
  validityDate: string;
  quoteIsValid: boolean;
  isToDisplay = false;

  constructor(private logger: LoggerService,
              private sessionStorageService: SessionStorageService) {
  }

  ngOnInit() {
    this.logger.debug('ValidityPeriodComponent init()');

    this.sessionStorageService.observe('currentItemct')
        .subscribe(() => {
        if (this.currentItemct && this.currentItemct.status === Status.quoted) {
          if (this.currentItemct && this.currentItemct.date) {
            // Set locale date format
            moment.locale(this.user.preferredLanguage);
            this.localeDate = moment(this.currentItemct.date).format('LL'); // Month dd, yyyy format for US
            const futureDate: moment.Moment = moment(this.currentItemct.dateValidityQuotation);
            this.validityDate = futureDate.format('LL'); // Month dd, yyyy format for US

            // Is current date after validity of bom
            this.quoteIsValid = futureDate.isAfter(moment());
            this.isToDisplay = true;
          }
        } else {
          this.isToDisplay = false;
        }
      });
  }

}
