import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CharacteristicsPageComponent} from './characteristics-page/characteristics-page.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {EcorealCtSharedModule} from '../shared/shared.module';
import {MultiselectDropdownModule} from 'angular-2-dropdown-multiselect';
import {CharacteristicFieldsetComponent} from './characteristic-fieldset/characteristic-fieldset.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    MultiselectDropdownModule,
    EcorealCtSharedModule,
    BrowserAnimationsModule
  ],
  declarations: [CharacteristicsPageComponent, CharacteristicFieldsetComponent],
  exports: [CharacteristicFieldsetComponent],
})
export class CharacteristicsModule { }
