/* Angular modules */
import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
/* ngx modules */
import {SessionStorage, SessionStorageService} from 'ngx-webstorage';
import {TranslateService} from '@ngx-translate/core';
/* app modules */
import {LocaleService} from '../../shared/localization/locale.service';
import {environment} from '../../../environments/environment';
import {LoggerService} from '../../shared/logging/logger.service';
import {RightsService} from '../../shared/rights/rights.service';
import {UserService} from '../../shared/user/user.service';
import {NavigationLinkEnum} from '../../shared/guards/navigationLink-enum';
import {HttpCacheService} from 'app/core/http/http-cache.service';
import {Lang, Localization} from '../../shared/model/localization.model';
import {Role} from '../../core/access-control/enum';
import {AccessControlService} from '../../core';
import {MessageService} from 'primeng/api';
import {UtilService} from '../../shared/util/util.service';
import {CommonModalComponent} from '../../shared/common-modal/common-modal.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less'],
})
export class SettingsComponent implements OnInit {

  @SessionStorage()
  user;

  @SessionStorage()
  public localization: Localization;

  @SessionStorage()
  isOnAdministrationScreen = false;

  allLocalizations: Localization[];

  roles: Role[];
  ADVANCED_USER_TYPE = 'ADVANCED';
  PPR_ENV_TYPE = 'PPR';
  showButton = true;

  @ViewChild('requestPersonalDataRemovalModal') requestPersonalDataRemovalModal: CommonModalComponent;

  constructor(private sessionStorageService: SessionStorageService,
              private translate: TranslateService,
              private localeService: LocaleService,
              private rightsService: RightsService,
              private accessControlService: AccessControlService,
              private userService: UserService,
              private messageService: MessageService,
              private router: Router,
              private logger: LoggerService,
              private httpCacheService: HttpCacheService,
              private utilService: UtilService) {
  }

  public isAllowedToChange(): boolean {
    const isPPRandAdvancedUser = environment.envType === this.PPR_ENV_TYPE && this.user.type === this.ADVANCED_USER_TYPE;
    const isPRODenvironment = environment.production;
    return !isPRODenvironment || isPPRandAdvancedUser;
  }

  async ngOnInit() {
    if (this.redirectIfDemoMode()) {
      this.localeService.getAllLocalization().subscribe(
        allLoc => {
          this.allLocalizations = allLoc;
          if (this.allLocalizations) {
            this.localization = this.allLocalizations.find(localization => localization.country === this.user.partnerCountry);
          }
          const langs = this.getAvailableLangages();
          if (langs.length === 1) {
            this.selectLanguage(langs[0].language);
          }
        }
      );
      this.roles = this.accessControlService.getRoles();
    }
  }

  selectLanguage(language) {
    this.logger.info('SettingsComponent selectLanguage()');
    this.logger.debug(language || 'language is null');

    this.localeService.setLocale(language)
      .subscribe(() => {
          if (this.allLocalizations) {
            this.localization = this.allLocalizations.find(localization => localization.country === this.user.partnerCountry);
            this.sessionStorageService.store('localization', this.localization);
          }
          this.user.preferredLanguage = language;
          this.sessionStorageService.store('user', this.user);
        },
        error => this.logger.error(error),
        () => this.translate.use(language).subscribe(() => this.refreshButtons())
      );
  }

  selectLocalization(country: string) {
    this.logger.info('SettingsComponent selectLocalization()');
    this.localeService.changeLocalization(this.user, country)
      .subscribe(
        () => {
          this.localization = this.allLocalizations.find(localization => localization.country === country);
          // remove cache when localization changes
          this.httpCacheService.cleanCache();
          this.user.partnerCountry = country;
          // Set user preferred language if this language is used for the current user's localisation
          let myLang = this.localization.langs[0].language;
          this.localization.langs.forEach(lang => {
            if (this.user.preferredLanguage === lang.language) {
              myLang = lang.language;
            }
          });
          this.user.preferredLanguage = myLang;
          this.sessionStorageService.store('user', this.user);
          this.translate.reloadLang(myLang);
          this.translate.use(myLang).subscribe(() => this.refreshButtons());
        }, error => {
          this.logger.error(error);
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('T_ERROR'),
            detail: this.translate.instant('T_CHANGE_LOCALIZATION_ERROR'),
          });
        }
      );
  }

  selectRole(role) {
    this.logger.info('SettingsComponent selectRole()');
    this.logger.debug(role || 'role is null');

    this.rightsService.setRole(role).subscribe(() => {
        this.user.role = role;
        this.sessionStorageService.store('user', this.user);
      }, error => {
        this.logger.error(error);
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('T_ERROR'),
          detail: this.translate.instant('T_CHANGE_ROLE_ERROR'),
        });
      }
    );
  }

  changeMySEAccount(value) {
    this.logger.info('change MySE Account to ' + value);
    this.userService.setMySEAccount(value).subscribe(
      user => this.user = user
    );
  }

  requestPersonalDataRemoval() {
    this.userService.requestPersonalDataRemoval().subscribe(() => {
      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant('T_EMAIL_SUCCESS_TITLE'),
        detail: this.translate.instant('T_EMAIL_SUCCESS_MESSAGE'),
      });
    }, error => {
      this.messageService.add({
        severity: 'error',
        summary: this.translate.instant('T_EMAIL_ERROR_TITLE'),
        detail: this.translate.instant('T_EMAIL_ERROR_MESSAGE'),
      });
    })
  }

  onRequestPersonalDataRemovalButton() {
    this.requestPersonalDataRemovalModal.show();
  }

  goToProjectPage(): void {
    this.router.navigate([NavigationLinkEnum.PROJECTS]);
  }

  canManagePersonalData(): boolean{
    return this.localization.canUserManagePersonalData;
  }

  getPrivacyProtectionLink(): string {
    return this.utilService.getDataPrivacyPolicyUrl(this.user);
  }

  private getAvailableLangages(): Array<Lang> {
    const langages: Array<Lang> = [];
    this.localization.langs.forEach(lang => {
      if (lang.available) {
        langages.push(lang);
      }
    });
    return langages;
  }

  /**
   * Redirects to projects (if demo user)
   */
  private redirectIfDemoMode() {
    if (this.rightsService.isActivatedDemo() || this.rightsService.isDeactivatedDemo()) {
      this.goToProjectPage();
      return false;
    }
    return true;
  }

  /**
   * Refresh button text when changing language
   */
  private refreshButtons() {
    this.showButton = false;
    setTimeout(() => this.showButton = true, 1);
  }
}
