/* Angular modules */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
/* ngx modules */
import {TranslateModule} from '@ngx-translate/core';
import {TreeTableModule} from 'primeng/treetable';
/* app modules */
import {EcorealCtSharedModule} from '../shared/shared.module';
/* Services */
import {BomHelper, BomMailService, BomService, CsvHelperService} from './shared';
/* Components */
import {
  BomDistributorModalComponent,
  BomMailModalComponent,
  DeliveryDetailsComponent,
  ValidityPeriodComponent
} from './';
import {BomPageComponent} from './bom-page/bom-page.component';
import {BomActionsComponent} from './bom-page/bom-actions/bom-actions.component';
import {BomTableComponent} from './bom-page/bom-table/bom-table.component';
import {TotalPriceComponent} from './bom-page/total-price/total-price.component';
import {BomTreeDataHelper} from './shared/bom-tree-data-helper.service';
/* Check why it does not work from bootstrap module */
import {PopoverModule} from 'ngx-bootstrap';
import {BomReferencesModalComponent} from './bom-page/bom-actions/bom-references-modal/bom-references-modal.component';
import {TableModule} from 'primeng/table';

@NgModule({
  imports: [
    PopoverModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TreeTableModule,
    TableModule,
    EcorealCtSharedModule
  ],
  declarations: [BomPageComponent, ValidityPeriodComponent, DeliveryDetailsComponent, BomMailModalComponent, BomDistributorModalComponent,
    BomActionsComponent, BomTableComponent, TotalPriceComponent, BomReferencesModalComponent],
  providers: [BomService, BomMailService, CsvHelperService, BomHelper, BomTreeDataHelper],
  exports: [BomMailModalComponent, BomActionsComponent, BomTableComponent, TotalPriceComponent]
})
export class BomModule {
}
