/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
/* app modules */
import {LoggerService} from '../logging/logger.service';
import {AccessoryCharacteristics} from '../model/accessory.model';
import {EditAccessoryForm} from '../../admin/offer-data-management/odm-accessories-management/odm-edit-accessory-modal/odm-edit-accessory-modal.component';
import {
  EditAccessoryValueForm
} from '../../admin/offer-data-management/odm-accessories-management/odm-edit-accessory-value-modal/odm-edit-accessory-value-modal.component';

@Injectable()
export class AccessoriesService {

  constructor(private httpClient: HttpClient,
              private logger: LoggerService) {
  }


  getAccessories(rangeId: string): Observable<AccessoryCharacteristics[]> {
    this.logger.debug('AccessoriesCharacteristics getAccessories');
    return this.httpClient.get<AccessoryCharacteristics[]>('/offer-data-management/accessoriesByRangeId/' + rangeId);
  }

  getPublishedAccessoriesCharacteristics(rangeId, forceUpdate: boolean = false): Observable<[AccessoryCharacteristics]> {
    this.logger.debug('CharacteristicsService getAccessoriesCharacteristics()');

    return this.httpClient.cache(forceUpdate).get<[AccessoryCharacteristics]>('/ranges/' + rangeId + '/accessoriesCharacteristics');
  }

  createAccessory(editAccessoryForm: EditAccessoryForm): Observable<AccessoryCharacteristics> {
    return this.httpClient.post<AccessoryCharacteristics>('/accessory', editAccessoryForm);
  }

  editAccessory(editAccessoryForm: EditAccessoryForm): Observable<AccessoryCharacteristics> {
    return this.httpClient.put<AccessoryCharacteristics>('/accessory', editAccessoryForm);
  }

  deleteAccessory(rangeId: string, accessoryId: string): Observable<any> {
    return this.httpClient.delete<AccessoryCharacteristics>('/accessory/' + accessoryId + "/range/" + rangeId);
  }

  addAccessoryValue(editAccessoryValueForm: EditAccessoryValueForm) {
    return this.httpClient.post<AccessoryCharacteristics>('/accessory/addAccessoryValue', editAccessoryValueForm);
  }

  editAccessoryValue(editAccessoryValueForm: EditAccessoryValueForm) {
    return this.httpClient.put<AccessoryCharacteristics>('/accessory/editAccessoryValue', editAccessoryValueForm);
  }

  deleteAccessoryValue(accessoryId: string, accessoryValueId: string) {
    return this.httpClient.delete<AccessoryCharacteristics>('/accessory/' + accessoryId + '/accessoryValueId/' + accessoryValueId);
  }

  editAccessoryOrder(accessoryId: string, orderIndex: number){
    return this.httpClient.post<AccessoryCharacteristics>('/accessory/' + accessoryId + '/orderIndex/' + orderIndex, {});
  }

  editAccessoryValueOrder(accessoryId: string, accessoryValueId: string, orderIndex: number){
    return this.httpClient.post<AccessoryCharacteristics>('/accessory/' + accessoryId + '/accessoryValueId/' + accessoryValueId + '/orderIndex/' + orderIndex, {});
  }
}
