<div *ngIf="currentProject && !loading" class="section-margin">
  <h6 class="title-margin">
    <span *ngIf="!currentProject.sharedProject" class="project-link" (click)="goBackToProject()" translate>T_MY_LAST_PROJECTS</span>
    <span *ngIf="currentProject.sharedProject" class="project-link" (click)="goBackToSharedProject()" translate>T_SHARED_WITH_ME</span>
    <span> > </span><span>{{ currentProject.name }}</span></h6>

  <div class="container">
    <div class="row">
      <div class="col-md-12 shared-project-display section-margin">

        <div class="row">

          <div class="col-md-10">
              <!-- Project title -->
              <h1>{{currentProject.name}}</h1>
          </div>
          <!-- Share button -->
          <div class="col-md-2" *ngIf="showShareButton()">
            <button type="button" class="btn btn-primary share-button pull-right"
                    tooltip="{{'T_SHARE_TOOLTIP' | translate}}" container="body" placement="left"
                    (click)="share()" translate>
              <span class="se-icons">action_share</span>
              T_COLLABORATIVE_SHARE
            </button>
          </div>

        </div>
        <!-- Project dates -->
        <div class="page-margin">
          <div><span translate>T_PROJECT_CREATION_DATE</span> <span class="project-date">{{utilService.getLocalizedDate(currentProject.creationDate, user.preferredLanguage)}}</span></div>
          <div><span translate>T_PROJECT_LAST_UPDATE</span> <span class="project-date">{{utilService.getLocalizedDate(currentProject.updateDate, user.preferredLanguage)}}</span></div>
        </div>

        <!-- Shared project navbar -->
        <div class="page-margin" *ngIf="sharedProjects.length>1">
          <app-project-sharing-navbar [sharedProjects]="sharedProjects" [currentProject]="selectedProject" (changeProject)="changeSharedProjectView($event)"></app-project-sharing-navbar>
        </div>

        <div class="page-margin min-height">
          <div *ngIf="selectedProject.sharedProjectUserEmail !== null && sharedProjects.length > 1">
            <span translate>T_COLLABORATIVE_SHARED_WITH</span> <span class="project-shared-name">{{selectedProject.sharedProjectUserEmail}}</span>
          </div>
          <div *ngIf="selectedProject.sharedFromUserEmail !== null && sharedProjects.length === 1">
            <span translate>T_COLLABORATIVE_SHARED_BY</span> <span class="project-shared-name">{{selectedProject.sharedFromUserEmail}}</span>
          </div>
          <div *ngIf="selectedProject.sharedProject">
            <span translate>T_COLLABORATIVE_SHARE_COMMENT</span> :
            <!-- PROJECT NAME EDITABLE -->
            <div *ngIf="!isReadOnlyProject()" class="editableContent project-comment" contenteditable="true"
                (blur)="updateComment($event)">
              {{selectedProject.shareComment}}</div>
            <!-- PROJECT NAME NOT EDITABLE -->
            <div class="project-comment"*ngIf="isReadOnlyProject()">{{selectedProject.shareComment}}</div>
          </div>
        </div>
        <!-- Current project item carousel -->
        <div class="page-margin">
          <!-- CAROUSEL OF ITEMS -->
          <app-item-carousel *ngIf="selectedProject.itemCT.length !== 0 || !isReadOnlyProject()"
                             [project]="selectedProject"
                             [readOnly]="isReadOnlyProject()"
                             [deleteItemEvent]="deleteItemSubject.asObservable()"
                             (clickOnItem)="goToItemCt($event)"
                             (clickOnAdd)="addSwitchboard($event)"
                             (clickOnDeleteItem)="deleteItem($event)"></app-item-carousel>
          <!-- NO ITEMS MESSAGES -->
          <div *ngIf="selectedProject.itemCT.length === 0 && isReadOnlyProject()" class="text-center no-item-margin" translate>T_COLLABORATIVE_NO_ITEM_SPACE</div>
        </div>
        <div *ngIf="showFiles()">
          <div class="files">
            <span class="subtitle"  translate>T_FILES</span>
            <span tooltip="{{(user.id !== selectedProject.author.id && user.email !== selectedProject.sharedProjectUserEmail ? 'T_COLLABORATIVE_NOT_OWNER_TOOLTIP' : '') | translate}}">
              <button type="button" class="btn btn-add-file" (click)="fileInput.click()" [disabled]="user.id !== selectedProject.author.id && user.email !== selectedProject.sharedProjectUserEmail" translate>
                T_ADD_FILE
                <input #fileInput type="file" (change)="projectFileUploader($event, selectedProject.id)" style="display:none" />
              </button>
            </span>
            <div class="upload-button-spinner">
              <app-spinner *ngIf="fileUploadInProgress" [sizeClass]="'small'"></app-spinner>
            </div>
          </div>
          <app-spinner *ngIf="fileListLoading && selectedProject.validSpimId" [sizeClass]="'medium'"></app-spinner>
          <app-project-files *ngIf="!fileListLoading || !selectedProject.validSpimId"
                             (addingFileEvent)="projectFileUploader($event, selectedProject.id)"
                             (updateFileEvent)="projectFileUploader($event.event, selectedProject.id, $event.fileId)"
                             [inputFiles]=projectFiles
                             [readOnly]="isReadOnlyProject()"></app-project-files>
        </div>
      </div>
    </div>

  </div>
</div>

<app-spinner *ngIf="!currentProject || loading" sizeClass="large">T_COLLABORATIVE_LOADING_SPACE</app-spinner>

<app-share-project-modal #shareProjectModal (onClickShareButton)="shareProject($event)"></app-share-project-modal>

<!-- confirm dialog -->
<app-common-modal #childModal [name]="'childModal'" (onClickYesButton)="confirmDialog()"
                  [size]="childModal.name!=='deleteItem'?'modal-sm':''">
</app-common-modal>

