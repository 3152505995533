import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ContainerComponent} from "./container/container.component";
import {EcorealCtSharedModule} from "../shared/shared.module";
import {FeedbackModule} from "../feedback/feedback-module";
import {LayoutModule} from "../layout/layout.module";
import {ProgressbarModule} from "ngx-bootstrap";
import {ToastModule} from "primeng/toast";
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [ContainerComponent],
  imports: [
    CommonModule,
    EcorealCtSharedModule,
    FeedbackModule,
    RouterModule,
    LayoutModule,
    ProgressbarModule,
    ToastModule
  ]
})
export class ContainerModule { }
