/* Angular modules */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
/* ngx modules */
import {TranslateModule} from '@ngx-translate/core';
/* app modules */
import {ProjectService} from './shared/project.service';
import {ItemService} from './shared/item.service';
import {ProjectPageComponent} from './project-page.component';
import {EcorealCtSharedModule} from '../shared/shared.module';
import {FeedbackModule} from '../feedback/feedback-module';
import {FeedbackService} from '../feedback/shared/service/feedback.service';
import { ProjectBomButtonComponent } from './project-bom-button/project-bom-button.component';
import { ProjectCardComponent } from './project-card/project-card.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectFilterComponent } from './project-filter/project-filter.component';
import { CpqProjectChoiceModalComponent } from './cpq-project-choice-modal/cpq-project-choice-modal.component';
import {ToastModule} from "primeng/toast";
import {MessageService} from "primeng/api";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ToastModule,
    EcorealCtSharedModule,
    FeedbackModule,
    ReactiveFormsModule
  ],
  declarations: [
    ProjectPageComponent,
    ProjectBomButtonComponent,
    ProjectCardComponent,
    ProjectListComponent,
    ProjectFilterComponent,
    CpqProjectChoiceModalComponent
  ],
  providers: [ProjectService, ItemService, FeedbackService,MessageService]
})
export class ProjectModule {

}
