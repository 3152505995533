<div>
  <div class="drawing">
    <div class="figureContainer" [ngClass]="{'imageWrapperSelected': isSelected}">

      <!-- ERROR: not last cubicle -->
      <span class="cubicle-error-indicator" *ngIf="cannotBeLast()" [tooltip]="'T_ERRORS_LAST_CUBICLE' | translate" placement="bottom" containerClass="error-indicator-container">
        <em class="fa fa-exclamation-circle"></em>
      </span>
      <div class="above-positionning text-center" *ngIf="showCompleteSld"
           [tooltip]="'T_SHOW_CHARACTERISTICS' | translate" placement="bottom" container="body">
        <span type="button" class="se-icons btn info-pellet" (click)="openCharacteristicModal()">information_circle</span>
      </div>
      <figure *ngFor="let functionalUnit of component.functionalUnits, let f = index" [ngClass]="{'marginCard': onCardsPanel, 'marginSld': !onCardsPanel }">
        <img class="img-functional-unit-cards" *ngIf="onCardsPanel" src="{{getSldImagePath(functionalUnit, f, component.functionalUnits.length)}}" name="{{functionalUnit.name}}">
        <img class="img-functional-unit" *ngIf="!onCardsPanel" src="{{getSldImagePath(functionalUnit, f, component.functionalUnits.length)}}" name="{{functionalUnit.name}}">
        <figcaption class="text-center fu-name" translate>{{functionalUnit.name}}</figcaption>
        <div>
          <button *ngIf="showAddTransformerIcon(functionalUnit)"
                  [ngClass]="{'btn': true, 'btn-add-cubicle-comp': true}"
                  (click)="!functionalUnit.transformer; addTransformer(f)"
                  [tooltip]="'T_ADD_TRANSFORMER' | translate" placement="right">
            <span class="se-icons fa-2x" aria-hidden="true">electricity_polarity_positive_plus</span>
            <p class="p-add-transformer" translate>T_ADD_COMPONENT</p>
          </button>
          <ng-container *ngIf="showCompleteSld && functionalUnit.transformer">
              <img
                [ngClass]="{'img-transformer-added': true, 'selected': isSelected && functionalUnit.transformer && transformerSelected && (f === selectedFunction)}"
                (click)="functionalUnit.transformer; showPanelTransformerOptions(f)"
                src="./assets/sld/Transformer_v2.svg" aria-hidden="true">

            <!-- WARNING for cub QM, QMC, PM: if transformer not compatible with the chosen fuse -->
            <div class="cubicle-transformer-warning-indicator" *ngIf="showTransformerIncompatibilityWarning()">
              <em class="fa fa-exclamation-triangle" [tooltip]="'T_ERRORS_TRANSFO_FUSE_INCOMPATIBLE' | translate" placement="bottom"
                 containerClass="cubicle-transformer-warning-indicator-container"></em>
            </div>
          </ng-container>
        </div>
      </figure>
      <div class="hasTransformer" *ngIf="hasTransformerCompatible"></div>
      <ng-template #descriptionTemplate>
        <div  class="tooltip-name" translate>{{component.name}}</div>
        <div  class="tooltip-description" translate>{{component.reference.description}}</div>
      </ng-template>

      <div [ngClass]="{'overlay': true, 'selected': cubicleSelected && isSelected}" *ngIf="showCompleteSld"
           (click)="showPanelCubicleOptions()" [tooltip]="descriptionTemplate" placement="bottom" container="body">
      </div>
      <ng-container *ngFor="let functionalUnit of component.functionalUnits, let f = index" >
        <div [ngClass]="{'overlay-transformer': functionalUnit.transformer !== null}" *ngIf="showCompleteSld"
             (click)="showPanelTransformerOptions(f)" [tooltip]="descriptionTransformerTemplate" placement="bottom"
             [style.width.%]="getFuWidth()" [style.marginLeft.%]="getMarginLeftFuTransformer(f)" container="body">
          <ng-template #descriptionTransformerTemplate >
            <div  *ngIf="functionalUnit.transformer !== null" class="tooltip-name" translate>{{functionalUnit.transformer.name}}</div>
            <div  *ngIf="functionalUnit.transformer !== null" class="tooltip-description" translate>{{functionalUnit.transformer.reference.description}}</div>
            <div *ngIf="functionalUnit.transformer !== null && showTransformerIncompatibilityWarning()" translate>T_ERRORS_TRANSFO_FUSE_INCOMPATIBLE</div>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</div>


