export class DocumentInformations {

  id: string;
  itemId: string;
  endUserName: string;
  projectName: string;
  author: string;
  version: number;
  downloadedDoc: string[] = [];
  saveDate: number;
}
