import {Reference} from '../../configuration/shared/model/reference';

export class Option {
  value: string|number;
  reference: Reference;
  selected: boolean;
  orderIndex: number;
  disabled = false;
  quantity = 1;

  constructor(value: string|number, reference: Reference, selected: boolean, quantity: number, orderIndex: number, disabled?: boolean) {
    this.value = value;
    this.reference = reference;
    this.selected = selected;
    this.disabled = disabled;
    this.quantity = quantity;
    this.orderIndex = orderIndex;
  }
}

export class MultiOption {
  name: string;
  id: string|number;
  quantity = 1;

  constructor(name: string, id: string|number, quantity: number) {
    this.name = name;
    this.id = id;
    this.quantity = quantity;
  }
}

export class PackageOption {
  id: string;
  name: string;
  selected: boolean;

  constructor(id: string, name: string, selected: boolean) {
    this.id = id;
    this.name = name;
    this.selected = selected;
  }
}

// Interface for electricalCharacteristicsValues and accessoriesCharacteristicsValues
export class CharacteristicView {
  disabled: boolean;
  htmlElementType: string;
  id: string;
  label: string;
  quantity: number;
  required: boolean;
  tooltip: string;
  type: CharacteristicType;
  unit: string;
  options: Option[];
  multiOptions: MultiOption[];
  packageOptions: PackageOption[];
  chooseValue: boolean;
  isOnAlert: boolean;
  alertMessage: string;
  information: string;
  category: string;
  orderIndex: number;
}


export class AccessoriesFamily {
  category: string;
  accessories: CharacteristicView[];
  display: boolean;

  constructor(category: string, accessories: CharacteristicView[], display: boolean) {
    this.category = category;
    this.accessories = accessories;
    this.display = display;
  }
}

export enum CharacteristicType {
  ACCESSORY,
  ELECTRICAL
}
