import {SwitchBoardComponent} from './component';
import {ActiveTabType} from './activeTab_Type';
import {ModalType} from './modal_type';
import {Documentation} from '../../../shared/documentation/documentation.model';
export class WrapperModalContent {
   component: SwitchBoardComponent;
   documents: Documentation[];
   activeTab: ActiveTabType;
   modalType: ModalType;

  constructor(component: SwitchBoardComponent, documents: Documentation[], activeTab: ActiveTabType, modalType: ModalType) {
    this.component = component;
    this.activeTab = activeTab;
    this.modalType = modalType;
    this.documents = documents;
  }
}
