import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Language} from '../../../shared/model/language-model';

@Component({
  selector: 'app-translation-modal',
  templateUrl: './translation-modal.component.html',
  styleUrls: ['./translation-modal.component.less']
})
export class TranslationModalComponent {

  @Input() countryName: string;
  @Input() languages: Language[];
  @Output() onClickSkipButton = new EventEmitter<string>();
  @Output() onClickValidateButton = new EventEmitter<string>();
  @Output() onLanguageChange = new EventEmitter<string>();

  constructor() {
  }

  cancelButton() {
    this.onClickSkipButton.emit('skip');
  }

  clickValidateButton() {
    this.onClickValidateButton.emit('validate');
  }

  selectLanguageCode(language) {
    this.onLanguageChange.emit(language);
  }

}
