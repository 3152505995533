/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
/* ngx modules */
import {Locale, Translation} from '../../../shared/model/localization.model';
import {TranslationKey} from './translation.model';
import {LoggerService} from '../../../shared/logging/logger.service';
import {Language} from '../../../shared/model/language-model';
import {Pair} from '../../../shared/util/pair';

@Injectable()
export class TranslationService {

  constructor(private httpClient: HttpClient,
              private logger: LoggerService) {
  }

  getTranslations(translationKey: string, language: string): Observable<string> {
    this.logger.debug('TranslationService get Translation');
    return this.httpClient.get('/localizations/translation/' + translationKey + '/' + language, {responseType: 'text'});
  }

  getTranslationKeys(forceUpdate?: boolean): Observable<Array<TranslationKey>> {
    this.logger.debug('TranslationService get TranslationKeys()');
    return this.httpClient.cache(forceUpdate).get<Array<TranslationKey>>('/localizations/translationsKeys');
  }

  getLocalesOfCountry(): Observable<Array<Locale>> {
    this.logger.debug('TranslationService get locales of country()');
    return this.httpClient.get<Array<Locale>>('/localizations/country/locales');
  }

  getLocalesOfCountryOfKey(translationKey: string): Observable<Array<Locale>> {
    this.logger.debug('TranslationService get locales of country of key');
    return this.httpClient.get<Array<Locale>>('/localizations/country/locales/'+ translationKey);
  }

  setLocalesOfCountryOfKey(translationKey: string, translationByLanguage:  Pair<string, string>[]): Observable<boolean> {
    this.logger.debug('TranslationService set locales of country of key');
    const payload = [];
    translationByLanguage.forEach(t => payload.push({first: t.getKey(), second:t.getValue()}));
    return this.httpClient.post<boolean>('/localizations/country/locales/'+ translationKey, payload);
  }

  updateTranslations(language: string, translations: Array<Translation>): Observable<any> {
    this.logger.info('TranslationService update translations');
    return this.httpClient.post( '/localizations/update/translation/' + language, translations, {responseType: 'text'});
  }

  updateLocaleAvailability(language: string, available: boolean): Observable<any> {
    this.logger.info('TranslationService update locale availability');
    return this.httpClient.post( '/localizations/update/availability/' + language + '/' + available, {}, {responseType: 'text'});
  }

  getAllLanguages(): Observable<Array<Language>>  {
    this.logger.debug('TranslationService get all languages');
    return this.httpClient.get<Array<Language>>('/localizations/languages');
  }

  getAllLanguagesByCountry(country: string): Observable<Array<string>>  {
    this.logger.debug('TranslationService get all languages');
    return this.httpClient.get<Array<string>>('/localizations/languages/country/'+ country);
  }


  getCountryNameByCode(code: string): Observable<any> {
    this.logger.debug('TranslationService get country name');
    return this.httpClient.get('/localizations/country/name/' + code, {responseType: 'text'});
  }

  addNewLanguage(language: string): Observable<any> {
    this.logger.info('TranslationService add new language');
    return this.httpClient.post( '/localizations/add/language/' + language, {}, {responseType: 'text'});
  }

}
