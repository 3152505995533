/* Angular modules */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
/* app modules */
import {ReleaseNote} from '../../project/shared/project-model';
import {ApplicationVersion} from '../../shared/model/applicationVersion-model';


@Injectable()
export class HeaderService {

  constructor(private httpClient: HttpClient) {
  }

  getApplicationVersion(): Observable<ApplicationVersion> {
    return this.httpClient.cache().get<ApplicationVersion>('/application/applicationVersion');
  }

  getAllReleaseNotesByUser(): Observable<ReleaseNote[]> {
    return this.httpClient.get<ReleaseNote[]>('/application/userReleaseNotes');
  }

  updateUserVersion(): Observable<any> {
    return this.httpClient.put('/application/userReleaseNoteUpdate', '');
  }
}
