<div class="row">
  <div class="col-xs-9 col-sm-offset-1 col-sm-10">
    <div>
      <table class="table table-striped table-hover">
        <tbody>
          <tr (click)="exportBom()" class="cursorOnRow">
            <td class="icon-col fixedWidth"><span class="se-icons iconClass">file_bom</span></td>
            <td translate>T_BOM_XSLX</td>
            <td class="fixedWidth"><span>&lt;&nbsp;1Ko</span></td>
            <td class="fixedWidth2x"></td>
            <td class="icon-col fixedWidth">
              <span class="se-icons download-icon">action_download</span>
            </td>
          </tr>
          <tr *ngIf="isMyseUser()" (click)="exportMySEBom()" class="cursorOnRow">
            <td class="icon-col fixedWidth"><span class="se-icons iconClass">file_bom</span></td>
            <td translate>T_DOC_BOM_MYSE_XSLX</td>
            <td class="fixedWidth"><span>&lt;&nbsp;1Ko</span></td>
            <td class="fixedWidth2x"></td>
            <td class="icon-col fixedWidth">
              <span class="se-icons download-icon">action_download</span>
            </td>
          </tr>
          <tr *ngIf="isDigiQCountryUser()" (click)="exportDigiQBom()" class="cursorOnRow">
            <td class="icon-col fixedWidth"><span class="se-icons iconClass">file_bom</span></td>
            <td translate>T_DOC_BOM_DIGIQ_CSV</td>
            <td class="fixedWidth"><span>&lt;&nbsp;1Ko</span></td>
            <td class="fixedWidth2x"></td>
            <td class="icon-col fixedWidth">
              <span class="se-icons download-icon">action_download</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
