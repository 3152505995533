import {Component, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {ReferenceToOrder} from '../../../../configuration/shared/model/component';
import {CommonModalComponent} from '../../../../shared/common-modal/common-modal.component';
import {RestrictedOrderingType} from '../../../../shared/model/range-model';
import {SessionStorage} from 'ngx-webstorage';

@Component({
  selector: 'app-bom-references-modal',
  templateUrl: './bom-references-modal.component.html',
  styleUrls: ['./bom-references-modal.component.less']
})
export class BomReferencesModalComponent implements OnChanges {

  @SessionStorage()
  localization;

  @ViewChild('childModal') public childModal: CommonModalComponent;

  @Input()
  referencesToOrder: ReferenceToOrder[];

  @Input()
  orderType: RestrictedOrderingType;

  @Output() onClickYesButton = new EventEmitter<string>();

  @Output() onClickNoButton = new EventEmitter<string>();

  referenceOrderModalTitle: string;

  quantity: number;

  totalPrice: number;

  constructor() { }

  ngOnChanges(): void {
    if (RestrictedOrderingType.DIRECT === this.orderType) {
      this.referenceOrderModalTitle = 'T_BOM_ORDER_REFERENCE_MODAL_DIRECT_TITLE';
    } else if (RestrictedOrderingType.INDIRECT === this.orderType) {
      this.referenceOrderModalTitle = 'T_BOM_ORDER_REFERENCE_MODAL_INDIRECT_TITLE';
    }
    this.quantity = 0;
    this.totalPrice = 0;
    this.referencesToOrder.forEach(referenceToOrder => {
      if (referenceToOrder.quantity) {
        this.quantity = this.quantity + referenceToOrder.quantity;
      }
      if (referenceToOrder.price) {
        this.totalPrice = Math.round((this.totalPrice + referenceToOrder.price) * 100) / 100;
      }
    });
  }

  show() {
    this.childModal.show();
  }

  hide() {
    this.childModal.hide();
  }

  clickCancelButton() {
    this.onClickNoButton.emit();
  }

  clickValidateButton() {
    this.onClickYesButton.emit(this.orderType);
  }

}
